import { FLOWN_SUSPENSION_API, HTTP_REQUEST_TYPE } from '../constants/api';
import en from '../translations/en';

import { sendHttpRequest } from './api';

const flownSuspensionChangeAcknowledged = async (userId: string, startDate: string, endDate: string, type: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: FLOWN_SUSPENSION_API.changeAcknowledged,
    apiTitle: en.errorAlert.changeAcknowledged,
    payload: { userId, startDate, endDate, type },
  });

  return resp;
};

export { flownSuspensionChangeAcknowledged };
