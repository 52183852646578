import React from "react";
import { Box, useTheme } from "@mui/material";

import { LeftArrowIcon } from "../../assets/images";

type Props = {
  buttonText: string;
  onClick: () => void;
};

const GoBackButton = (props: Props) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        height: "20px",
        margin: "34px auto 0",
      }}
    >
      <Box
        component="span"
        sx={{
          cursor: "pointer",
          color: theme.status.selected,
        }}
        onClick={props.onClick}
      >
        <LeftArrowIcon
          style={{ marginRight: "8px" }}
          fill={theme.status.selected}
        />
        {props.buttonText}
      </Box>
    </Box>
  );
};

export default GoBackButton;
