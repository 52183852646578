import React, { createContext, useState } from 'react';

import { IRouteToProps } from '../interfaces';
import { Frontend } from 'booking';

interface IBookingSummaryStateType {
  travellerList: any;
  removalTravellerList: any;
  isOpenRemovePaxMode: boolean;
  routeTo: any;
  isOpenRemoveDialog: boolean;
  isOpenRemoveToCancelDialog: boolean;
  isOpenConfirmToCancelDialog: boolean;
  removalFormData: any;
  bookingEligibility: Frontend.BookingEligibility | null;
  isExpandWarning: boolean;

  // for rebook flow
  originalFlightDate: any;
  rebookFlightDate: any;
  bookingSummaryData: any;
  isShowRebookSearchOverlay: boolean;
  isFlightRebook: boolean;
  isOpenRebookFlow: boolean;
  isOpenRebookCompleted: boolean;
  isOpenMoreAction: boolean;
  // for retrieve and pay flow
  isRetrieveAndPayFlow: boolean;
  isShowAnimation: boolean;
  isOpenRetrieveAndPayCompleted: boolean;
}

interface IActionType {
  setTravellerList: any;
  setIsOpenRemovePaxMode: any;
  setRemovalTravellerList: any;
  setRouteTo: any;
  setIsOpenRemoveDialog: any;
  setIsOpenRemoveToCancelDialog: any;
  setIsOpenConfirmToCancelDialog: any;
  setRemovalFormData: any;
  setIsOpenMoreAction: any;
  setBookingEligibility: any;
  setIsExpandWarning: any;

  // for rebook flow
  setOriginalFlightDate: any;
  setRebookFlightDate: any;
  setBookingSummaryData: any;
  setIsShowRebookSearchOverlay: any;
  setIsFlightRebook: any;
  setIsOpenRebookFlow: any;
  setIsOpenRebookCompleted: any;
  //for retrieve and pay flow
  setIsRetrieveAndPayFlow: any;
  setIsShowAnimation: any;
  setIsOpenRetrieveAndPayCompleted: any;
}

const BookingSummaryState = createContext<IBookingSummaryStateType>({
  travellerList: null,
  removalTravellerList: null,
  isOpenRemovePaxMode: false,
  routeTo: null,
  isOpenRemoveDialog: false,
  isOpenRemoveToCancelDialog: false,
  isOpenConfirmToCancelDialog: false,
  removalFormData: null,
  originalFlightDate: null,
  rebookFlightDate: null,
  bookingSummaryData: null,
  isShowRebookSearchOverlay: false,
  isFlightRebook: false,
  isOpenRebookFlow: false,
  isOpenRebookCompleted: false,
  isOpenMoreAction: false,
  bookingEligibility: null,
  isRetrieveAndPayFlow: false,
  isShowAnimation: false,
  isOpenRetrieveAndPayCompleted: false,
  isExpandWarning: true,
});

const BookingSummaryAction = createContext<IActionType>({
  setTravellerList: null,
  setIsOpenRemovePaxMode: false,
  setRemovalTravellerList: null,
  setRouteTo: null,
  setIsOpenRemoveDialog: null,
  setIsOpenRemoveToCancelDialog: null,
  setIsOpenConfirmToCancelDialog: null,
  setRemovalFormData: null,
  setOriginalFlightDate: null,
  setRebookFlightDate: null,
  setBookingSummaryData: null,
  setIsShowRebookSearchOverlay: null,
  setIsFlightRebook: null,
  setIsOpenRebookFlow: null,
  setIsOpenRebookCompleted: null,
  setIsOpenMoreAction: null,
  setBookingEligibility: null,
  setIsRetrieveAndPayFlow: null,
  setIsShowAnimation: null,
  setIsOpenRetrieveAndPayCompleted: null,
  setIsExpandWarning: null,
});

const BookingSummaryContextProvider = ({ children }: { children?: React.ReactNode }) => {
  const [travellerList, setTravellerList] = useState([]);
  const [removalTravellerList, setRemovalTravellerList] = useState([]);
  const [isOpenRemovePaxMode, setIsOpenRemovePaxMode] = useState(false);
  const [isOpenRemoveDialog, setIsOpenRemoveDialog] = useState(false);
  const [isOpenRemoveToCancelDialog, setIsOpenRemoveToCancelDialog] = useState(false);
  const [isOpenConfirmToCancelDialog, setIsOpenConfirmToCancelDialog] = useState(false);
  const [removalFormData, setRemovalFormData] = useState(null);

  const [originalFlightDate, setOriginalFlightDate] = useState(null);
  const [rebookFlightDate, setRebookFlightDate] = useState(null);
  const [bookingSummaryData, setBookingSummaryData] = useState(null);
  const [isShowRebookSearchOverlay, setIsShowRebookSearchOverlay] = useState(false);
  const [isFlightRebook, setIsFlightRebook] = useState(false);
  const [isOpenRebookFlow, setIsOpenRebookFlow] = useState(false);
  const [isOpenRebookCompleted, setIsOpenRebookCompleted] = useState(false);

  const [routeTo, setRouteTo] = useState<IRouteToProps>({
    step: 1,
  });
  const [isOpenMoreAction, setIsOpenMoreAction] = useState(false);
  const [bookingEligibility, setBookingEligibility] = useState<Frontend.BookingEligibility | null>(null);
  const [isRetrieveAndPayFlow, setIsRetrieveAndPayFlow] = useState(false);
  const [isShowAnimation, setIsShowAnimation] = useState(false);
  const [isOpenRetrieveAndPayCompleted, setIsOpenRetrieveAndPayCompleted] = useState(false);
  const [isExpandWarning, setIsExpandWarning] = useState(true);

  return (
    <BookingSummaryState.Provider
      value={{
        travellerList,
        removalTravellerList,
        isOpenRemovePaxMode,
        routeTo,
        isOpenRemoveDialog,
        isOpenRemoveToCancelDialog,
        isOpenConfirmToCancelDialog,
        removalFormData,
        originalFlightDate,
        rebookFlightDate,
        bookingSummaryData,
        isShowRebookSearchOverlay,
        isFlightRebook,
        isOpenRebookFlow,
        isOpenRebookCompleted,
        isOpenMoreAction,
        bookingEligibility,
        isRetrieveAndPayFlow,
        isShowAnimation,
        isOpenRetrieveAndPayCompleted,
        isExpandWarning,
      }}
    >
      <BookingSummaryAction.Provider
        value={{
          setTravellerList,
          setIsOpenRemovePaxMode,
          setRemovalTravellerList,
          setRouteTo,
          setIsOpenRemoveDialog,
          setIsOpenRemoveToCancelDialog,
          setIsOpenConfirmToCancelDialog,
          setRemovalFormData,
          setOriginalFlightDate,
          setRebookFlightDate,
          setBookingSummaryData,
          setIsShowRebookSearchOverlay,
          setIsFlightRebook,
          setIsOpenRebookFlow,
          setIsOpenRebookCompleted,
          setIsOpenMoreAction,
          setBookingEligibility,
          setIsRetrieveAndPayFlow,
          setIsShowAnimation,
          setIsOpenRetrieveAndPayCompleted,
          setIsExpandWarning,
        }}
      >
        {children}
      </BookingSummaryAction.Provider>
    </BookingSummaryState.Provider>
  );
};

export { BookingSummaryContextProvider, BookingSummaryState, BookingSummaryAction };
