import React from 'react';

interface MatchAndHighlightProps {
  label: string;
  keyword: string;
  fontWeight: number;
}

const MatchAndHighlightLabel: React.FC<MatchAndHighlightProps> = ({ label, keyword, fontWeight }) => {
  if (keyword === '' || keyword.length < 2) {
    return <span>{label}</span>;
  }

  try {
    const pattern = new RegExp(`(${keyword})`, 'ig');
    const parts = label.split(pattern);

    return (
      <span>
        {parts.map((part, index: number) =>
          pattern.test(part) ? (
            <span key={index} style={{ fontWeight }}>
              {part}
            </span>
          ) : (
            part
          ),
        )}
      </span>
    );
  } catch (e) {
    return <span>{label}</span>;
  }
};

export default MatchAndHighlightLabel;
