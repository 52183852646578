import { HTTP_REQUEST_TYPE, ADMIN_API } from '../../constants/api';
import en from '../../translations/en';

import { sendHttpRequest } from '../api';

const getEmployee = async (payload: { userId?: string; galaCXyId?: string }) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: ADMIN_API.EMPLOYEE_USER_API.employeeProfile,
    apiTitle: en.errorAlert.getEmployee,
    payload,
  });
  return resp;
};

const getSuggestion = async (payload: { userId?: string; galaCXyId?: string }) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: ADMIN_API.EMPLOYEE_USER_API.suggestionList,
    apiTitle: en.errorAlert.getSuggestion,
    payload: payload,
  });
  return resp;
};

const getNotes = async ({ userId, profileRevision }: { userId: string; profileRevision: number }) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: ADMIN_API.EMPLOYEE_USER_API.notes,
    apiTitle: en.errorAlert.getNotes,
    payload: { userId, profileRevision },
  });
  return resp;
};

const noteUpsert = async ({
  noteId,
  userId,
  profileRevision,
  type,
  subject,
  description,
}: {
  noteId?: string;
  userId: string;
  profileRevision: number;
  type: string;
  subject: string;
  description: string;
}) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: ADMIN_API.EMPLOYEE_USER_API.noteUpsert,
    apiTitle: en.errorAlert.noteUpsert,
    payload: { noteId, userId, profileRevision, type, subject, description },
  });
  return resp;
};

const getConcessions = async ({ userId, profileRevision }: { userId: string; profileRevision: number }) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: ADMIN_API.EMPLOYEE_USER_API.concessions,
    apiTitle: en.errorAlert.getConcessions,
    payload: { userId, profileRevision },
  });
  return resp;
};

export { getEmployee, getSuggestion, getNotes, noteUpsert, getConcessions };
