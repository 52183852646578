import { HTTP_REQUEST_TYPE, PREPAID_API } from '../constants/api';

import { IPrepaidBookFlightLtParams, IPrepaidRetrieveAndPaytBookFlightLtParams } from '../interfaces';

import en from '../translations/en';

import { sendHttpRequest } from './api';

const prepaidAuth = async (payload: IPrepaidBookFlightLtParams) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: PREPAID_API.prepaidAuth,
    payload,
  });

  return resp;
};

const prepaidBook = async (userId: string, tpAppRef: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: PREPAID_API.prepaidBook,
    apiTitle: en.errorAlert.bookFlights,
    payload: { userId, tpAppRef },
  });

  return resp;
};

const retrieveAndPayAuth = async (payload: IPrepaidRetrieveAndPaytBookFlightLtParams) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: PREPAID_API.retrieveAndPayAuth,
    payload,
  });

  return resp;
};

export { prepaidAuth, prepaidBook, retrieveAndPayAuth };
