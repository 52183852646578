import { useState, useEffect, useMemo } from 'react';

import { DATE_FORMAT, PAYMENT_CONCEPT, PAYMENT_MEDIUM, PAYMENT_SETUP_STATUS, USER_ROLE } from '../constants/constants';

import en from '../translations/en';

import { useAppSelector } from '../app/hooks';

import { selectUser } from '../slice/userSlice';
import { formatDateAsString, isHideLtFeatures } from '../helpers';

export interface IPaymentMethodIndicator {
  isShowWarning: boolean;
  paymentConcept: PAYMENT_CONCEPT | string;
  paymentMedium: PAYMENT_MEDIUM | string;
  paymentSetUpStatus: PAYMENT_SETUP_STATUS | string;
  paymentSetUpWarningMessage: string;
  paymentValidated: boolean | null;
  isError: boolean;
  isPrepaid: boolean; // ETP-2431
  isDirectDebitSetupNo: boolean;
  replaceTarget: string;
}

/*
  Mainly to check some payment method has setup or not
*/
export const usePaymentMethodIndicator = () => {
  const { profile, role, concession } = useAppSelector(selectUser) || {};
  const { leisureTravel } = concession || {};
  const [paymentMethodIndicator, setPaymentMethodIndicator] = useState<IPaymentMethodIndicator>({
    isShowWarning: false,
    paymentConcept: '',
    paymentMedium: '',
    paymentSetUpStatus: PAYMENT_SETUP_STATUS.NOT_YET_SET_UP,
    paymentSetUpWarningMessage: '',
    isError: false, //TODO handle error exception case
    isPrepaid: false,
    isDirectDebitSetupNo: false,
    paymentValidated: null,
    replaceTarget: '',
  });

  const isSelfRole = role.type === USER_ROLE.self;
  const isHideLtFeaturesControl = useMemo(() => {
    return isHideLtFeatures({
      isDelegation: role.type === USER_ROLE.delegation,
      isEmptyLtConcession: leisureTravel?.length === 0,
    });
  }, [role, leisureTravel]);

  // ETP-2431
  // validate payment concept is flown
  const isFlownPaymentConcept = useMemo(() => {
    return profile?.paymentConcept === PAYMENT_CONCEPT.FLOWN;
  }, [profile?.paymentConcept]);

  // validate is flown + paypal
  const isPaypalWithFlown = useMemo(() => {
    return isFlownPaymentConcept && profile?.paymentMedium === PAYMENT_MEDIUM.PAYPAL_PAYMENT;
  }, [profile?.paymentConcept, profile?.paymentMedium]);

  // validate is flown + direct debit
  const isDirectDebitWithFlown = useMemo(() => {
    return isFlownPaymentConcept && profile?.paymentMedium === PAYMENT_MEDIUM.DIRECT_DEBIT_PAYMENT;
  }, [profile?.paymentConcept, profile?.paymentMedium]);

  // validate is suspension
  const isSuspensionStatus = useMemo(() => {
    return profile?.isShowFlownSuspensionMessage || false;
  }, [profile?.isShowFlownSuspensionMessage]);

  // validate the setup indicator logic
  useEffect(() => {
    if (profile?.paymentConcept && profile?.paymentMedium) {
      /*
        Indicate payment method cases, only allow:
        - payment concept is `FWN` + payment medium is `DDP`
        - payment concept is `FWN` + payment medium is `PPP`
      */
      const isInPaymentMethodScope = isPaypalWithFlown || isDirectDebitWithFlown;

      // [ETP-4266] when checked `paymentValidated` is `false` means Not Yet Setup. If null, then should be display some generic message caused by billing agreement DB connection error.
      const isPaypalNotSetup = isPaypalWithFlown && profile?.paymentValidated === false;
      const isPaypalUnableRetrieve = profile?.paymentValidated === null;

      /**
       * directDebitStatus is the reliable source to identify directDebit being setup or not
       * we will fade out directDebitIndicator since it is relying on iFly, iFly is going to terminate soon
       */
      const isDirectDebitSetupRejected = isDirectDebitWithFlown && profile?.directDebitStatus === 'N';
      const isDirectDebitNotSetup = isDirectDebitWithFlown && !profile?.directDebitStatus;
      let warningMessage = '';

      if (isPaypalWithFlown) {
        // [ETP-4266] shown generic message or not yet setup message
        const enPaymentPaypalLbl = en.payment.paypal;
        warningMessage = isPaypalUnableRetrieve
          ? enPaymentPaypalLbl.unableRetrievePaypalDetailWarning
          : enPaymentPaypalLbl.commonWarning;
      }

      if (isDirectDebitWithFlown) {
        if (isDirectDebitSetupRejected) {
          warningMessage = en.payment.directDebit.rejectedWarning;
        } else if (isDirectDebitNotSetup) {
          warningMessage = en.payment.directDebit.commonWarning;
        }
      }

      // ETP-2431
      if (isSuspensionStatus) {
        warningMessage = en.userProfile.flownSuspension.contentMessage
          .replace(
            en.userProfile.flownSuspension.startDate,
            formatDateAsString(profile.etpFlownSuspensionStartDate, DATE_FORMAT.ddmmmyyyy) || '',
          )
          .replace(
            en.userProfile.flownSuspension.endDate,
            formatDateAsString(profile.etpFlownSuspensionEndDate, DATE_FORMAT.ddmmmyyyy) || '',
          );
      }

      let statusLabel = '';
      if (isPaypalWithFlown) {
        statusLabel = isPaypalNotSetup ? PAYMENT_SETUP_STATUS.NOT_YET_SET_UP : PAYMENT_SETUP_STATUS.COMPLETED;
        if (isPaypalUnableRetrieve) {
          statusLabel = PAYMENT_SETUP_STATUS.UNABLE_RETRIEVE;
        }
      }
      if (isDirectDebitWithFlown) {
        statusLabel =
          isDirectDebitNotSetup || isDirectDebitSetupRejected
            ? PAYMENT_SETUP_STATUS.NOT_YET_SET_UP
            : PAYMENT_SETUP_STATUS.COMPLETED;
      }

      // [ETP-4266]
      const paymentMethodReplaceTarget =
        !paymentMethodIndicator.isDirectDebitSetupNo && !isPaypalUnableRetrieve ? en.payment.replaceTarget : '';

      let paymentValidated: boolean | null = null;
      if (!isPaypalUnableRetrieve) {
        paymentValidated = profile?.paymentValidated;
      }
      setPaymentMethodIndicator({
        isShowWarning:
          isSuspensionStatus ||
          (!isHideLtFeaturesControl &&
            isSelfRole &&
            isInPaymentMethodScope &&
            (isPaypalWithFlown
              ? isPaypalNotSetup || isPaypalUnableRetrieve
              : isDirectDebitNotSetup || isDirectDebitSetupRejected)),
        paymentConcept: profile?.paymentConcept,
        paymentMedium: profile?.paymentMedium || '',
        paymentSetUpStatus: statusLabel,
        paymentSetUpWarningMessage: warningMessage,
        isError: false,
        isPrepaid: isSuspensionStatus,
        isDirectDebitSetupNo: isDirectDebitSetupRejected,
        paymentValidated,
        replaceTarget: paymentMethodReplaceTarget,
      });
    } else {
      // empty concept & medium
      setPaymentMethodIndicator({
        isShowWarning: isSelfRole || !isHideLtFeaturesControl,
        paymentConcept: profile?.paymentConcept || '',
        paymentMedium: profile?.paymentMedium || '',
        paymentSetUpStatus: PAYMENT_SETUP_STATUS.ERROR,
        paymentSetUpWarningMessage: '',
        isError: true,
        isPrepaid: isSuspensionStatus,
        isDirectDebitSetupNo: false,
        paymentValidated: profile?.paymentValidated || null,
        replaceTarget: en.payment.replaceTarget,
      });
    }
  }, [
    concession,
    profile?.paymentConcept,
    profile?.paymentValidated,
    profile?.paymentMedium,
    profile?.isShowFlownSuspensionMessage,
  ]);

  return [paymentMethodIndicator, setPaymentMethodIndicator] as const;
};
