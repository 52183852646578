import React, { useEffect } from 'react';
import { Typography, Box, useTheme } from '@mui/material';

import en from '../../../translations/en';
import { USER_PROFILE_DETAILS } from '../../../constants/constants';

import { IInformationListItem } from '../../../containers/UserProfile/Common/InformationList';

import { getDisplayVal } from '../../../helpers';

import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { selectAuth } from '../../../slice/authSlice';
import { selectUser, getProfileThunk } from '../../../slice/userSlice';
import { selectApp } from '../../../slice/appSlice';

import { InformationList } from '../../../containers';

const ContactDetailsContainer = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { profile } = useAppSelector(selectUser);
  const { ern: ernFromLogin } = useAppSelector(selectAuth) || {};
  const { isDesktop } = useAppSelector(selectApp) || {};

  useEffect(() => {
    dispatch(getProfileThunk(ernFromLogin));
    return () => {};
  }, [dispatch]);

  const { personalInfo } = profile || {};

  const { personalEmail, businessEmail, mobilePhone, workPhone } = personalInfo || {};

  const listDataContactInfo: IInformationListItem[] = [
    {
      key: en.userProfile.personal.contactInfo.companyEmail,
      value: getDisplayVal(businessEmail),
    },
    {
      key: en.userProfile.personal.contactInfo.personalEmail,
      value: getDisplayVal(personalEmail),
    },
    // TODO: hard code
    // {
    //   key: en.userProfile.personal.contactInfo.notificationEmail,
    //   value:  getDisplayVal(businessEmail),
    //   isEdit: true,
    // },
    {
      key: en.userProfile.personal.contactInfo.officePhoneNumber,
      value: getDisplayVal(workPhone),
    },
    {
      key: en.userProfile.personal.contactInfo.mobilePhoneNumber,
      value: getDisplayVal(mobilePhone),
    },
  ];

  return (
    <Box sx={{ ...(isDesktop && { width: USER_PROFILE_DETAILS.width }) }}>
      <InformationList title={en.userProfile.personal.contactInfo.title} listData={listDataContactInfo} />
      <Typography
        variant="body_2_regular"
        sx={{
          mt: 5,
          color: theme.color.secondary.dark_grey.option_3,
          width: { sm: USER_PROFILE_DETAILS.width, xs: 'auto' },
        }}
      >
        {en.userProfile.personal.contactInfo.noticeMessage}
      </Typography>
    </Box>
  );
};

export default ContactDetailsContainer;
