import React from 'react';
import { Box, Theme, SxProps } from '@mui/material';

import { ExclamationIcon } from '../../assets/images';

interface IAlertProps {
  severity: string;
  component: React.ReactNode;
  customStyles?: SxProps<Theme>;
}

const icons = (): {
  [key: string]: { icon: React.ReactNode; bgColor: string };
} => {
  return {
    info: {
      icon: <ExclamationIcon width="16" height="16" fill="#4C4C4C" />,
      bgColor: '#FBFAF8',
    },
  };
};

const FormAlert = ({ severity, component, customStyles }: IAlertProps) => (
  <Box
    sx={{
      bgcolor: icons()[severity].bgColor,
      borderRadius: 0.5,
      display: 'flex',
      px: 2,
      py: 1,
      mb: 2,
      border: '1.5px solid #C0B498',
      ...customStyles,
    }}
  >
    <Box sx={{ width: 15, height: 15 }}>{icons()[severity].icon}</Box>

    {component}
  </Box>
);

export default FormAlert;
