import { useState } from 'react';
import _ from 'lodash';
import { Typography, Box, useTheme, IconButton, Collapse } from '@mui/material';

import en from '../../../../translations/en';
import { DownArrowIcon, SeatClassIcon, SeatInfantClassIcon, UpArrowIcon } from '../../../../assets/images';
import { FONT_WEIGHT } from '../../../../constants/font';
import { FORM, NOMINATED_PASSENGER_AGE_TYPE } from '../../../../constants/constants';

import { Configuration } from 'configuration';
import { INominatedPassenger } from '../../../../interfaces';

import { getDisplayVal, getDisplayName, isEmptyOrUndefined } from '../../../../helpers';

import { useAppDispatch } from '../../../../app/hooks';
import { setEditTravellerKeyIndex } from '../../../../slice/bookingSlice';

// Will be reused later,so Hold
import { FormButton, ShadowContent, WarningAlert } from '../../../../components';

const TravellerInfo = ({
  traveller,
  isTravelDetailMandatory,
  isPassportDetailMandatory,
  isPassportDetailPartiallyFilledIn,
  isPassportDetailAllFilled,
  handleEditClick,
  keyIndex,
}: {
  traveller: INominatedPassenger;
  isTravelDetailMandatory?: boolean;
  isPassportDetailMandatory?: boolean;
  isPassportDetailPartiallyFilledIn?: boolean;
  isPassportDetailAllFilled?: boolean;
  handleEditClick: (type: string) => void;
  keyIndex: number;
}) => {
  /**
   * TODO:
   * 1. Traveller Detail "This is Mandatory" Warning
   * 2. Passport Detail "This is Mandatory" Warning
   * 3. Passport Detail "Please edit the passport detail" Warning
   */

  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [isExpand, setIsExpand] = useState(true);

  const { countryOfResidential, passportDetail, validationInfo, accompanyAdult, infantWithSeat } = traveller || {};
  const { passportNumber } = passportDetail || {};
  return (
    <Box sx={{ p: 2, my: 0.5, bgcolor: theme.color.etp.option_1, borderRadius: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {validationInfo?.ageType === NOMINATED_PASSENGER_AGE_TYPE.infant ? (
          <SeatInfantClassIcon height={28} width={28} fill={theme.color.etp.option_2} />
        ) : (
          <SeatClassIcon height={28} width={28} fill={theme.color.etp.option_2} />
        )}
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            pl: 0.75,
          }}
        >
          <Typography variant="body_1_medium" color={theme.color.secondary.dark_grey.option_3}>
            {getDisplayName({
              title: traveller?.title,
              firstName: traveller?.firstName,
              lastName: traveller?.lastName,
            })}
          </Typography>
          <IconButton sx={{ py: 0 }} onClick={() => setIsExpand(!isExpand)}>
            {isExpand ? (
              <UpArrowIcon fill={theme.palette.text.primary} />
            ) : (
              <DownArrowIcon fill={theme.palette.text.primary} />
            )}
          </IconButton>
        </Box>
      </Box>

      <Collapse in={isExpand}>
        <Box sx={{ pt: 1.5 }}>
          {/* Traveller Info */}
          {validationInfo?.ageType === NOMINATED_PASSENGER_AGE_TYPE.infant && (
            <Box
              sx={{
                mt: -0.5,
                ml: 4.25,
                overflow: 'hidden',
                color: theme.color.secondary.grey.option_2,
              }}
            >
              <Typography sx={{ mb: 0.5 }} variant="navigation_regular">
                {!infantWithSeat
                  ? en.booking.travelType.leisureTravel.infantWithoutSeat
                  : en.booking.travelType.leisureTravel.infantWithSeat}
              </Typography>
              {accompanyAdult && (
                <Typography sx={{ mb: 0.5 }} variant="navigation_regular">
                  {`${en.booking.confirmation.associatedWith} ${getDisplayName({
                    title: accompanyAdult?.title,
                    firstName: accompanyAdult?.firstName,
                    lastName: accompanyAdult?.lastName,
                  })} `}
                </Typography>
              )}
            </Box>
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mt: 1,
            }}
          >
            <Typography variant="body_1_medium" color={theme.color.secondary.dark_grey.option_3}>
              {en.booking.flightConfirmation.travelDetails.travellerDetails.info}
            </Typography>
            <FormButton
              theme={theme}
              customStyles={{
                height: '18px',
                width: '27px',
                minWidth: '27px',
                fontSize: '0.875rem',
                lineHeight: '18px',
                fontWeight: FONT_WEIGHT.bold,
                padding: 0,
              }}
              colour="transparent"
              onClick={() => {
                dispatch(setEditTravellerKeyIndex(keyIndex));
                handleEditClick(FORM.travellerDetails);
              }}
            >
              {en.common.edit}
            </FormButton>
          </Box>
          {isTravelDetailMandatory && (
            <Box sx={{ mt: 1 }}>
              <WarningAlert warningMsg={en.booking.confirmation.mandatory} />
            </Box>
          )}
          {/* Country of Residence */}
          <Box sx={{ mt: 1 }}>
            <Typography variant="navigation_regular" color={theme.color.secondary.grey.option_2}>
              {en.booking.flightConfirmation.travelDetails.travellerDetails.countryOfResidential}
            </Typography>
            <Typography variant="body_1_medium" color={theme.color.secondary.dark_grey.option_3}>
              {getDisplayVal((countryOfResidential as Configuration.DropdownOptionClient)?.label)}
            </Typography>
          </Box>
          {/* Passport Info */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mt: 1,
            }}
          >
            <Typography variant="body_1_medium" color={theme.color.secondary.dark_grey.option_3}>
              {en.booking.flightConfirmation.travelDetails.passportDetails.info}
            </Typography>
            <FormButton
              theme={theme}
              customStyles={{
                height: '18px',
                width: '27px',
                minWidth: '27px',
                fontSize: '0.875rem',
                lineHeight: '18px',
                fontWeight: FONT_WEIGHT.bold,
                padding: 0,
              }}
              colour="transparent"
              onClick={() => {
                // Add additional action in handleEditClick
                dispatch(setEditTravellerKeyIndex(keyIndex));
                handleEditClick(FORM.passportDetails);
              }}
            >
              {en.common.edit}
            </FormButton>
          </Box>
          {!isPassportDetailAllFilled && (
            <>
              {isPassportDetailPartiallyFilledIn ? (
                <Box sx={{ mt: 1 }}>
                  <WarningAlert warningMsg={en.booking.flightConfirmation.travelDetails.passportDetails.warning} />
                </Box>
              ) : isPassportDetailMandatory ? (
                <Box sx={{ mt: 1 }}>
                  <WarningAlert warningMsg={en.booking.confirmation.mandatory} />
                </Box>
              ) : (
                <></>
              )}
            </>
          )}
          {/* Passport Number */}
          <Box sx={{ mt: 1 }}>
            <Typography variant="navigation_regular" color={theme.color.secondary.grey.option_2}>
              {en.booking.flightConfirmation.travelDetails.passportDetails.passportNumber}
            </Typography>
            <Typography variant="body_1_medium" color={theme.color.secondary.dark_grey.option_3}>
              {getDisplayVal(passportNumber)}
            </Typography>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};

const ViewTravellerDetails = ({
  handleEditClick,
  requestedTravellers,
  setIsRestrictBooking,
  isTravellerInfoMandatory = false,
  isPassportInfoMandatory = false,
}: {
  handleEditClick: (type: string) => void;
  requestedTravellers: INominatedPassenger[];
  setIsRestrictBooking: (value: boolean) => void;
  isTravellerInfoMandatory?: boolean;
  isPassportInfoMandatory?: boolean;
}) => {
  const theme = useTheme();
  return (
    <Box component={ShadowContent} sx={{ mb: 2, p: 2 }}>
      <Typography sx={{ mb: 2, color: theme.color.secondary.dark_grey.option_1 }} variant="body_1_bold">
        {en.booking.flightConfirmation.travelDetails.travellerDetails.title}
      </Typography>
      {requestedTravellers &&
        requestedTravellers.map((traveller: INominatedPassenger, index) => {
          const { passportDetail } = traveller || {};

          const isTravelDetailMandatory = isTravellerInfoMandatory && _.isNil(traveller.countryOfResidential);

          const passportDetailFieldsForCheckWarningMessage = {
            dateOfBirth: passportDetail?.dateOfBirth,
            gender: passportDetail?.gender,
            passportNumber: passportDetail?.passportNumber,
            expiryDay: passportDetail?.passportExpirationDate,
            nationality: passportDetail?.passportNationality,
            issueCountry: passportDetail?.passportCountry,
          };

          const isPassportDetailAllFilled =
            !!traveller?.passportDetail &&
            Object.values(passportDetailFieldsForCheckWarningMessage).every((item) => !isEmptyOrUndefined(item));

          const isPassportDetailAllEmpty = Object.values(passportDetailFieldsForCheckWarningMessage).every((item) =>
            isEmptyOrUndefined(item),
          );

          const isPassportDetailPartiallyFilledIn =
            Object.values(passportDetailFieldsForCheckWarningMessage).some((item) => isEmptyOrUndefined(item)) &&
            !isPassportDetailAllEmpty;

          // isRestrictBooking = true: not showing the "Create booking button"
          setIsRestrictBooking(
            // Check Travel Detail Invalid to pass
            isTravelDetailMandatory ||
              // Check Passport Detail Invalid to pass when PassportInfo is Mandatory
              (isPassportInfoMandatory && !isPassportDetailAllFilled) ||
              // Check Passport Detail Invalid to pass when PassportInfo is not Mandatory
              (!isPassportInfoMandatory && isPassportDetailPartiallyFilledIn),
          );
          return (
            <TravellerInfo
              key={index}
              keyIndex={traveller.keyIndex}
              traveller={traveller}
              isPassportDetailMandatory={isPassportInfoMandatory}
              isPassportDetailPartiallyFilledIn={isPassportDetailPartiallyFilledIn}
              isPassportDetailAllFilled={isPassportDetailAllFilled}
              isTravelDetailMandatory={isTravelDetailMandatory}
              handleEditClick={handleEditClick}
            />
          );
        })}
    </Box>
  );
};

export default ViewTravellerDetails;
