import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  useTheme,
} from "@mui/material";

import { CheckBoxCheckIcon, CheckBoxIcon } from "../../assets/images";

import en from "../../translations/en";
import {
  ACKNOWLEDGE_FLOWN_SUSPENSION,
  DATE_FORMAT,
} from "../../constants/constants";

import { formatDateAsString, flownSuspensionPopup } from "../../helpers";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectApp } from "../../slice/appSlice";
import { selectAuth } from "../../slice/authSlice";
import { getProfileThunk } from "../../slice/userSlice";

import {
  selectFlownSuspension,
  setIsUnderstandChecked,
  setIsOpenFlownSuspensionDialog,
} from "../../slice/flownSuspensionSlice";

import { FormButton } from "../../components";
import { flownSuspensionChangeAcknowledged } from "../../services/flownSuspension";

const FlownSuspensionDialog = ({
  startDate,
  endDate,
}: {
  handleCloseClick?: () => void;
  handleConfirmClick?: () => void;
  startDate: string;
  endDate: string;
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);

  const { isDesktop } = useAppSelector(selectApp) || {};
  const { ern } = useAppSelector(selectAuth) || {};

  const { isUnderstandChecked, isOpenFlownSuspensionDialog } =
    useAppSelector(selectFlownSuspension) || {};

  useEffect(() => {
    setOpen(isOpenFlownSuspensionDialog);
  }, [isOpenFlownSuspensionDialog]);

  useEffect(() => {
    if (!open) {
      dispatch(setIsOpenFlownSuspensionDialog(false));
    }
  }, [open]);

  return (
    <Dialog
      sx={{
        "& .MuiDialog-container": {
          alignItems: "baseline",
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: isDesktop ? "478px" : "343px",
            borderRadius: 2,
            m: 0,
            px: isDesktop ? 4.5 : 3,
            py: 2,
            top: isDesktop ? "20%" : "8%",
            overflowX: "hidden",
            boxShadow: theme.boxShadow.important,
          },
        },
      }}
      open={open}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="title_1_bold"
          sx={{
            px: 0,
            py: 2,
            color: theme.color.secondary.dark_grey.option_1,
            textAlign: "center",
          }}
        >
          {en.userProfile.flownSuspension.title}
        </Typography>
        <DialogContent sx={{ px: 0, pt: 1, pb: 2, mx: "auto" }}>
          {flownSuspensionPopup(
            formatDateAsString(startDate, DATE_FORMAT.ddmmmyyyy),
            formatDateAsString(endDate, DATE_FORMAT.ddmmmyyyy),
            en.userProfile.flownSuspension.contentMessage,
            600,
            en.userProfile.flownSuspension.link
          )}
          <Typography
            variant="body_1_regular"
            sx={{
              color: theme.color.secondary.dark_grey.option_1,
            }}
          >
            <Box sx={{ my: 1, display: "flex", flexDirection: "row", mt: 3 }}>
              <Box sx={{ mt: -1, ml: -1 }}>
                <Checkbox
                  sx={{
                    "&.MuiCheckbox-root": {
                      color: theme.status.selected,
                    },
                  }}
                  checkedIcon={
                    <CheckBoxCheckIcon fill={theme.status.selected} />
                  }
                  icon={<CheckBoxIcon stroke={theme.status.selected} />}
                  color="info"
                  checked={isUnderstandChecked}
                  onChange={() =>
                    dispatch(setIsUnderstandChecked(!isUnderstandChecked))
                  }
                />
              </Box>
              {flownSuspensionPopup(
                formatDateAsString(startDate, DATE_FORMAT.ddmmmyyyy),
                formatDateAsString(endDate, DATE_FORMAT.ddmmmyyyy),
                en.userProfile.flownSuspension.consentMessage,
                600
              )}
            </Box>
          </Typography>
        </DialogContent>
      </Box>
      <DialogActions
        sx={{
          p: 0,
          display: "flex",
          alignItems: "center",
          mt: 1,
          pb: 3,
        }}
      >
        <FormButton
          theme={theme}
          colour="green"
          variant="contained"
          color="success"
          size="large"
          customStyles={{
            borderRadius: 8,
            width: "100%",
            height: "44px",
          }}
          onClick={async () => {
            const type = ACKNOWLEDGE_FLOWN_SUSPENSION;
            const resp = await flownSuspensionChangeAcknowledged(
              ern,
              startDate,
              endDate,
              type
            );
            if (resp && resp.message) {
              dispatch(setIsUnderstandChecked(false));
              dispatch(setIsOpenFlownSuspensionDialog(false));
              dispatch(getProfileThunk(ern));
            }
          }}
          disabled={!isUnderstandChecked}
          sx={{
            "&:not(:first-of-type), &:first-of-type": {
              ml: 0,
            },
          }}
        >
          {en.userProfile.flownSuspension.changeAcknowledged}
        </FormButton>
      </DialogActions>
    </Dialog>
  );
};

export default FlownSuspensionDialog;
