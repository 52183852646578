import { useContext, useEffect } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import { use100vh } from 'react-div-100vh';

import { Configuration } from 'configuration';
import {
  DATE_FORMAT,
  DATE_UNIT,
  HOME_SEARCH_MAX_LT_ADVANCE_BOOKING_DAYS,
  UI_STYLES,
} from '../../../constants/constants';
import en from '../../../translations/en';

import { useAppSelector } from '../../../app/hooks';
import { formatDateAsString } from '../../../helpers';
import {
  selectBooking,
  setDestination,
  setDetail,
  setLeisureTravelDetails,
  setOrigin,
  setStartDate,
  setType,
  travelDetailInitialState,
} from '../../../slice/bookingSlice';
import { selectApp } from '../../../slice/appSlice';
import { selectConfiguration } from '../../../slice/configurationSlice';
import { BookingSummaryAction, BookingSummaryState } from '../../../context';

import { RebookDateFieldContainer } from '.';
import { Footer, FormButton, ScrollableView } from '../../../components';

const RebookEditFlightDate = ({
  handleCancelClick,
  handleContinueClick,
}: {
  handleCancelClick: () => void;
  handleContinueClick: () => void;
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const screenHeight = use100vh();
  const { isDesktop } = useAppSelector(selectApp) || {};

  const { originalFlightDate, bookingSummaryData } = useContext(BookingSummaryState) || {};
  const { setOriginalFlightDate, setRebookFlightDate } = useContext(BookingSummaryAction) || {};

  const { ticketsBySegment } = bookingSummaryData || {};
  const { arrivalPort, departurePort, arrivalDate } = (ticketsBySegment && ticketsBySegment[0]) || {};

  const { airports } = useAppSelector(selectConfiguration) || {};

  const { travelType, leisureTravelDetails } = useAppSelector(selectBooking) || {};

  const originPort =
    airports.find((airport: Configuration.Airport) => airport.airportCode && airport.airportCode === departurePort) ||
    null;

  const destinationPort =
    airports.find((airport: Configuration.Airport) => airport.airportCode && airport.airportCode === arrivalPort) ||
    null;

  useEffect(() => {
    if (!originalFlightDate) {
      setOriginalFlightDate(formatDateAsString(arrivalDate, DATE_FORMAT.date));
    }
  }, [arrivalDate]);

  return (
    <>
      <Box
        component={ScrollableView}
        sx={
          isDesktop
            ? {
                height: `calc(${screenHeight}px - ${UI_STYLES.navBarHeight} - 
                  ${UI_STYLES.desktopFooterHeight} 
                  - ${UI_STYLES.overlayHeaderHeightSearchCriteria})`,
              }
            : {
                pb: 2,
                px: 2,
                height: `calc(${screenHeight}px - ${UI_STYLES.footerHeight} - ${UI_STYLES.overlayHeaderHeightSearchCriteria})`,
                background: theme.backgroundColor.wheat,
                width: '100%',
                borderTopLeftRadius: '24px',
                borderTopRightRadius: '24px',
              }
        }
      >
        {!isDesktop && (
          <Typography variant="body_2_medium" sx={{ mt: 3, mb: 1 }}>
            {en.booking.confirmation.cancelActions.rebook.editFlightDate}
          </Typography>
        )}

        <RebookDateFieldContainer
          isEditPopUp={false}
          customStyles={{
            ...(isDesktop && {
              mt: 0.5,
            }),
          }}
        />
      </Box>

      <Footer
        leftChild={
          <FormButton
            theme={theme}
            colour="transparent"
            size="large"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              height: '44px',
              width: '60px',
              borderRadius: 1,
            }}
            onClick={handleCancelClick}
          >
            {en.common.cancel}
          </FormButton>
        }
        primaryBtn={{
          text: en.common.continue,
          customOnClick: () => {
            dispatch(setOrigin(originPort));
            dispatch(setDestination(destinationPort));
            dispatch(setStartDate(originalFlightDate || formatDateAsString(new Date(), DATE_FORMAT.date)));
            dispatch(setType(travelType));
            dispatch(
              setDetail({
                ...travelDetailInitialState,
                advanceDay: HOME_SEARCH_MAX_LT_ADVANCE_BOOKING_DAYS,
                advanceDayUnit: DATE_UNIT.DYS,
              }),
            );
            dispatch(setLeisureTravelDetails(leisureTravelDetails));

            setRebookFlightDate(originalFlightDate);
            handleContinueClick?.();
          },
          customButtonStyles: {
            marginRight: 0,
            width: '160px',
          },
        }}
        customStyles={
          isDesktop && {
            height: UI_STYLES.desktopFooterHeight,
            px: 22,
            boxShadow: theme.palette.boxShadow.dark,
            borderTopLeftRadius: '24px',
            borderTopRightRadius: '24px',
          }
        }
      />
    </>
  );
};

export default RebookEditFlightDate;
