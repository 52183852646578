import { styled, Theme } from '@mui/material/styles';
import { Button } from '@mui/material';

const FormButton = styled(Button)(({
  theme,
  colour, // what is colour and color???
  customStyles,
}: {
  theme: Theme;
  colour: string;
  customStyles?: any;
}) => {
  const colorStyles: { [type: string]: any } = {
    blue: {
      color: 'white',
      backgroundColor: theme.palette.borderColor.light,
      '&:hover': {
        backgroundColor: theme.palette.borderColor.light,
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: theme.palette.borderColor.light,
      },
    },
    green: {
      backgroundColor: theme.color.utility.link.option_3,
      '&:hover': {
        backgroundColor: theme.color.utility.link.option_3,
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: theme.color.utility.link.option_3,
      },
    },
    outline: {
      border: `2px solid ${theme.color.utility.link.option_3}`,
      color: theme.color.utility.link.option_3,
      backgroundColor: 'white',
      '&:hover': {
        backgroundColor: 'white',
        border: `2px solid ${theme.color.utility.link.option_3}`,
      },
    },
    transparent: {
      color: theme.status.selected,
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
      },
      '&:active': {
        boxShadow: 'none',
        backgroundColor: 'transparent',
      },
    },
  };

  return {
    boxShadow: 'none',
    borderRadius: 4,
    textTransform: 'none',
    fontSize: theme.typography.body1.fontSize,
    height: 40,
    fontWeight: 'bold',
    ...colorStyles[colour],
    ...customStyles,
  };
});

FormButton.defaultProps = {
  customStyles: {},
};

export default FormButton;
