import { Frontend } from 'booking';
import { PREPAID_STATUS, USER_ROLE } from '../constants/constants';
import en from '../translations/en';

const getCancelWarningMessage = (role?: string, roleName?: string, isPrepaid?: boolean, prepaidMes?: string) => {
  const { cancel } = en.booking;
  switch (role) {
    case USER_ROLE.self:
      return isPrepaid ? prepaidMes : cancel.userCancel;
    case USER_ROLE.admin:
      return `${cancel.genericCancel} ${roleName}`;
    case USER_ROLE.delegation:
      return cancel.delegateeCancel;
    case USER_ROLE.system:
      return cancel.systemCancel;
    default:
      return '';
  }
};

const getMatchWarningMessage = ({
  isFlightRebook,
  prepaidStatus,
  bookingEligibility,
}: {
  isFlightRebook: boolean;
  bookingEligibility: Frontend.BookingEligibility | null;
  prepaidStatus: string | null | undefined;
}) => {
  /**
   * Not refund & Cancelled & rebook & refund: This booking is cancelled by user. The tickets are still valid.
   *
   * Not refund & Cancelled & !rebook & refund: This booking is cancelled by user. The tickets are no longer valid.
   *
   * Refunded: The booking is pending for refund.
   *
   * Rebooked: You have rebooked with existing ticket.
   */

  const isAllowRebook = bookingEligibility?.REBOOK;
  const isAllowRefund = bookingEligibility?.REFUND;

  const isCancelled = prepaidStatus === PREPAID_STATUS.NOT_REFUND || PREPAID_STATUS.REFUNDED;
  const isRefunded = prepaidStatus === PREPAID_STATUS.REFUNDED;
  const isNotRefunded = prepaidStatus === PREPAID_STATUS.NOT_REFUND;
  const isRetrieveAndPay = prepaidStatus === PREPAID_STATUS.RETRIEVE_AND_PAY;

  let message = en.booking.cancel.NotRebookAndRefundWarning;
  if (isFlightRebook) {
    // etp-2976 add for test
    message = en.booking.confirmation.cancelActions.rebook.haveRebooked;
  } else if (isNotRefunded && isCancelled && isAllowRebook && isAllowRefund) {
    message = en.booking.cancel.existRebookAndRefundWarning;
  } else if (isNotRefunded && isCancelled && isAllowRebook && !isAllowRefund) {
    message = en.booking.cancel.onlyRebookWarning;
  } else if (isNotRefunded && isCancelled && !isAllowRebook && isAllowRefund) {
    message = en.booking.cancel.onlyRefundWarning;
  } else if (isRefunded) {
    message = en.booking.confirmation.cancelActions.refund.refundWarningMsg;
  } else if (!isCancelled) {
    message = en.booking.cancel.existNotRefundWarning;
  } else if (isRetrieveAndPay) {
    message = en.booking.retrieveToPay.errorMessage;
  }
  return message;
};

export { getCancelWarningMessage, getMatchWarningMessage };
