import en from '../translations/en';

const validateRegex = (value: string, regex: { [Symbol.match](string: string): RegExpMatchArray | null }) => {
  value = !value ? '' : value;
  const result = value.match(regex);
  return result && result[0] === value;
};

const isNull = (value: string | null | undefined) => !value || value === null || value === undefined;

const isEmptyOrUndefined = (value: any): boolean => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'string' && value.trim() === '') ||
    (Array.isArray(value) && value.length === 0) ||
    (typeof value === 'object' && Object.keys(value).length === 0)
  );
};

const validateRequired = (value: string) => (isNull(value) ? en.error.required : null);

const validateRequiredFields = (values: any, requiredFields: string[], errors: any) => {
  requiredFields.forEach((key) => {
    const err = validateRequired(values[key]);
    if (err) {
      errors[key] = err;
    }
  });
  return errors;
};

const isValid = (errors: any) => Object.keys(errors).length === 0;

const haveAllRequiredFields = (values: any, requiredFields: string[]) =>
  !!values && isValid(validateRequiredFields(values, requiredFields, {}));

export {
  validateRegex,
  validateRequired,
  validateRequiredFields,
  isValid,
  haveAllRequiredFields,
  isEmptyOrUndefined,
  isNull,
};
