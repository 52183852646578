import { ROOT_PATHS } from '../constants/paths';
import { IRouteConfig } from '../interfaces';
import Home from './home/Home';

const rootRouterConfig: IRouteConfig = {
  path: ROOT_PATHS.landing,
  component: Home,
};

export default rootRouterConfig;
