import { Typography, Box, useTheme, Grid } from '@mui/material';

import en from '../../../../translations/en';
import { FONT_WEIGHT } from '../../../../constants/font';
import { FORM } from '../../../../constants/constants';

import { ILtEditContactDetails, IMultipleEmail } from '../../../../interfaces';

import { getDisplayVal } from '../../../../helpers';

import { FormButton, ShadowContent, WarningAlert } from '../../../../components';

const ViewContactDetails = ({
  handleEditClick,
  setIsRestrictBooking,
  data,
}: {
  handleEditClick: (type: string) => void;
  setIsRestrictBooking: (value: boolean) => void;
  data?: ILtEditContactDetails;
}) => {
  const theme = useTheme();

  const { phoneNumber, emailList } = data || {};
  const { title, phoneNumber: phoneLabel, email } = en.booking.flightConfirmation.travelDetails.contactDetails;

  const isContactMandatory = !phoneNumber || !emailList?.length;
  setIsRestrictBooking(isContactMandatory);

  return (
    <Box component={ShadowContent} sx={{ mb: 2, p: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography sx={{ mb: 2, color: theme.color.secondary.dark_grey.option_1 }} variant="body_1_bold">
          {title}
        </Typography>
        <FormButton
          theme={theme}
          customStyles={{
            height: '18px',
            width: '27px',
            minWidth: '27px',
            fontSize: '0.875rem',
            lineHeight: '18px',
            fontWeight: FONT_WEIGHT.bold,
            padding: 0,
          }}
          colour="transparent"
          onClick={() => handleEditClick(FORM.contactDetails)}
        >
          {en.common.edit}
        </FormButton>
      </Box>

      <Box>
        {/* Warning Box */}
        {isContactMandatory && <WarningAlert warningMsg={en.booking.confirmation.mandatory} />}
        <Grid container rowSpacing={0} columnSpacing={1} sx={{ mt: 2 }}>
          <Grid xs={12} pl={1}>
            <Typography variant="navigation_regular" color={theme.color.secondary.grey.option_2}>
              {phoneLabel}
            </Typography>
            <Typography variant="body_1_medium" color={theme.color.secondary.dark_grey.option_3}>
              {getDisplayVal(phoneNumber)}
            </Typography>
          </Grid>
          {emailList &&
            emailList.map((emailOption: IMultipleEmail, index: number) => {
              return (
                <Grid key={index} xs={12} pl={1} pt={1.5}>
                  <Typography variant="navigation_regular" color={theme.color.secondary.grey.option_2}>
                    {getDisplayVal(emailOption?.type?.label)} {email}
                  </Typography>
                  <Typography variant="body_1_medium" color={theme.color.secondary.dark_grey.option_3}>
                    {getDisplayVal(emailOption?.email)}
                  </Typography>
                </Grid>
              );
            })}
        </Grid>
      </Box>
    </Box>
  );
};

export default ViewContactDetails;
