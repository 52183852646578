import React, { useCallback, useEffect, useState } from 'react';
import { Box, Checkbox, Grid, Typography, useTheme } from '@mui/material';
import { use100vh } from 'react-div-100vh';
import _, { isArray } from 'lodash';

import en from '../../../translations/en';
import {
  BOOKING_CLASS,
  NOMINATED_PASSENGER_AGE_TYPE,
  UI_STYLES,
  LABEL_CATEGORY,
  DATE_UNIT,
  ID_TICKET_TYPE,
  CARRIER_OPTION,
} from '../../../constants/constants';
import { CheckBoxIcon, CheckBoxCheckIcon } from '../../../assets/images';

import { RADIO_BUTTON_GROUP_VARIANT } from '../../../components/Button/RadioButtonGroup';
import {
  IButtonValue,
  IConcessionOption,
  IKeyValue,
  ILtConcession,
  ILtTravellerInfo,
  INominatedPassenger,
  IPassport,
  ISeatClassOption,
  ITravellerDetailsItem,
} from '../../../interfaces';
import { Configuration } from 'configuration';

import {
  isSomeTravellerAvailable,
  sort,
  getSeatClass,
  constructFlightSectors,
  sortConcessionForRule,
  getIsOnlySeatOptionAvailable,
  checkNominatedPassengers,
  requireAccompanyForm,
} from '../../../helpers';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectBooking, setLeisureTravelDetails, setDocsDoca } from '../../../slice/bookingSlice';
import { selectAuth } from '../../../slice/authSlice';
import { selectApp } from '../../../slice/appSlice';
import { selectConfiguration } from '../../../slice/configurationSlice';

import { getDocsDoca, getFareAndTravellerDetailsLT } from '../../../services/booking';
import { getValidSeatConcession, selectOrPrefillConcession, selectOrPrefillSeatOption } from '../../../hooks/booking';

import {
  Footer,
  Header,
  FormButton,
  ReduxFormSelectInput,
  ScrollableView,
  RadioButtonGroup,
  DesktopBreadcrumb,
} from '../../../components';
import { LtMiniFlightInfo, LtTravellers, LtBookingReminder } from '../..';

const ConcessionLabel = ({ data }: { data: IKeyValue }) => {
  const theme = useTheme();

  return (
    <>
      <Grid xs={6} px={1} pb={1}>
        <Typography variant="navigation_regular" color={theme.color.secondary.grey.option_2}>
          {data.key}
        </Typography>
        {isArray(data.value) ? (
          <>
            {data.value?.map((item: string) => {
              return (
                <Typography key={item} variant="body_1_regular" color={theme.color.secondary.dark_grey.option_3}>
                  {getSeatClass(item)}
                </Typography>
              );
            })}
          </>
        ) : (
          <Typography variant="body_1_regular" color={theme.color.secondary.dark_grey.option_3}>
            {data.value}
          </Typography>
        )}
      </Grid>
    </>
  );
};

const LtSeatClassSelection = (props: any) => {
  /**
   * TODO:
   * 1. Utilize code, seem too many redundant code
   * 2. Need to redesign redux structure
   * 3. Align practice for using useContext, redux form
   */

  const {
    handleBackClick,
    handleFooterClick,
  }: {
    handleBackClick?: () => void;
    handleFooterClick?: () => void;
  } = props;

  const theme = useTheme();
  const screenHeight = use100vh();
  const dispatch = useAppDispatch();
  const { isDesktop } = useAppSelector(selectApp) || {};

  const { configurations } = useAppSelector(selectConfiguration) || {};

  const { ern } = useAppSelector(selectAuth) || {};

  const { leisureTravelDetails, outwardFlight, startDate } = useAppSelector(selectBooking) || {};
  const { callSign, departureTime, arrivalTime, departureDate, arrivalDate, marketingCompany, availability } =
    outwardFlight || {};

  // etp-2974: if not carrier means owoal
  const isCXCarrier = marketingCompany === CARRIER_OPTION.CX;

  const {
    seatConcession,
    seatOption,
    ltSeatConcessionTravellerList,
    ltConcessions,
    isAccompanyFormChecked,
    restrictionBeneficiaryType,
  } = leisureTravelDetails || {};

  const concessionRule = ltConcessions && ltConcessions?.find((item: ILtConcession) => item._id === seatConcession);

  const [leisureTravelConcessions, setLeisureTravelConcessions] = useState<ILtConcession[]>(ltConcessions || []);

  const [checked, setChecked] = useState<boolean>(isAccompanyFormChecked || false);
  const [isAllAccompanySelected, setIsAllAccompanySelected] = useState<boolean>(false);
  const [isKeepData, setIsKeepData] = useState<boolean>(true);

  const selectedTravellers =
    (ltSeatConcessionTravellerList &&
      ltSeatConcessionTravellerList.filter((traveller: INominatedPassenger) => traveller?.isSelected)) ||
    [];

  const unSelectTravellers =
    (ltSeatConcessionTravellerList &&
      ltSeatConcessionTravellerList.filter((traveller: INominatedPassenger) => !traveller?.isSelected)) ||
    [];

  const adultTravellers =
    selectedTravellers &&
    selectedTravellers.filter(
      (traveller: INominatedPassenger) =>
        traveller?.validationInfo?.allowAccompany && traveller?.validationInfo?.restriction?.length === 0,
    );

  const infantWithoutSeatTravellers =
    selectedTravellers &&
    selectedTravellers.filter(
      (traveller: INominatedPassenger) =>
        traveller?.validationInfo?.ageType === NOMINATED_PASSENGER_AGE_TYPE.infant &&
        traveller?.infantWithSeat === false &&
        traveller?.validationInfo?.restriction &&
        traveller?.validationInfo?.restriction?.length === 0,
    );

  const selectedConcession =
    leisureTravelConcessions &&
    leisureTravelConcessions?.find((item: ILtConcession) => {
      return item._id === seatConcession;
    });

  const SEAT_CONCESSION: IConcessionOption[] =
    leisureTravelConcessions &&
    sortConcessionForRule(
      getValidSeatConcession({ ltConcessions: leisureTravelConcessions, outwardFlight, startDate }),
    );

  const selectedConcessionDayUnit = selectedConcession?.concessionDef.advanceBookingDay.unit;
  const unitCode = DATE_UNIT?.[selectedConcessionDayUnit as keyof typeof DATE_UNIT];
  const unitGroupLbl = en.booking.selectFlight.exceedAdvanceBookingPeriod.unit;

  const CONCESSION_DEF_DATA: IKeyValue[] = [
    {
      key: en.booking.travelType.boardingPriority,
      value: isCXCarrier // owoal show "--"
        ? selectedConcession?.boardingPriority
        : en.common.empty,
    },
    {
      key: en.booking.travelType.advanceBookingDays,
      value: `${selectedConcession?.concessionDef.advanceBookingDay.value} ${unitGroupLbl?.[
        unitCode as keyof typeof unitGroupLbl
      ]}`,
    },
    {
      key: en.booking.travelType.travelClass,
      value: sort(selectedConcession?.bookingClass?.slice(), BOOKING_CLASS) || [],
    },
    {
      key: en.booking.travelType.ugsaClass,
      value: getSeatClass(selectedConcession?.regradeClass),
    },
  ];

  const SEAT_OPTIONS: ISeatClassOption[] = sort(selectedConcession?.bookingClass?.slice(), BOOKING_CLASS).map(
    (item: string) => {
      return {
        id: item,
        val: item,
        title: item,
        isDisabled: !Object.keys(outwardFlight?.availability || {})?.includes(item),
      };
    },
  );

  const isAllSeatOptionAvailable: boolean = SEAT_OPTIONS.every(
    (seatOption: ISeatClassOption) => seatOption?.isDisabled === false,
  );
  const isOnlySeatOptionAvailable: IConcessionOption[] = getIsOnlySeatOptionAvailable(SEAT_CONCESSION);

  // ----------------- show continue checkers -----------------
  const isConcessionAndSeatOptionSelected: boolean = !!seatConcession && !!seatOption;

  const isTravellerAvailable: boolean = isSomeTravellerAvailable(selectedTravellers);

  // check selected passengers if still can proceed
  const { canProceed, eligibleTravellers } = checkNominatedPassengers(selectedTravellers) || {};
  const travellerCount = canProceed ? eligibleTravellers.length || 0 : 0;

  // owoal hide accompany form
  const isRequireAccompanyForm: boolean = isCXCarrier ? requireAccompanyForm(eligibleTravellers) : false;

  const isInfantOrChildTraveller: boolean =
    eligibleTravellers &&
    !eligibleTravellers.some(
      (traveller: INominatedPassenger) => traveller?.validationInfo?.ageType === NOMINATED_PASSENGER_AGE_TYPE.adult,
    );

  const infantOrChildNumTotal = selectedTravellers.filter(
    (traveller: INominatedPassenger) =>
      traveller?.validationInfo?.ageType === NOMINATED_PASSENGER_AGE_TYPE.infant ||
      traveller?.validationInfo?.ageType === NOMINATED_PASSENGER_AGE_TYPE.child,
  );

  const isAllFieldInputed: boolean =
    !!infantOrChildNumTotal &&
    infantOrChildNumTotal?.every(
      (data: INominatedPassenger) =>
        data &&
        data.accompanyFormData &&
        data?.accompanyFormData?.bookingReference !== '' &&
        data?.accompanyFormData?.bookingReference.length === 6 &&
        data?.accompanyFormData?.firstName !== '' &&
        data?.accompanyFormData?.lastName !== '' &&
        data?.accompanyFormData?.salutation !== null,
    );

  /**
   * Show Accompany Button Condition
   * 1. 1 adult, 1 infant without seat (not show)
   * 2. N adult, 1 infant without seat (show)
   * 3. N adult, N infant without seat (show)
   */
  const isShowAccompanyButton: boolean =
    adultTravellers &&
    adultTravellers?.length > 1 &&
    infantWithoutSeatTravellers &&
    infantWithoutSeatTravellers?.length >= 1;
  /**
   * Have 3 condition to show continue button:
   * 1. Infant or child only travellers
   * 2. Infant without seat with adult travellers
   * 3. No extra checking is needed cases (any cases that do not hit flow 1 and 2)
   */
  const isFooterValid =
    canProceed &&
    isTravellerAvailable &&
    (isRequireAccompanyForm || isShowAccompanyButton
      ? ((checked && isAllFieldInputed) || isAllAccompanySelected) && isConcessionAndSeatOptionSelected
      : isConcessionAndSeatOptionSelected);

  // ----------------- End of show continue checkers -----------------

  const checkAccompanyForm = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    dispatch(
      setLeisureTravelDetails({
        ...leisureTravelDetails,
        isAccompanyFormChecked: event.target.checked,
      }),
    );
  };

  const { selectedPassengers: selectedTraveller } = checkNominatedPassengers(ltSeatConcessionTravellerList) || {};

  const selectedTravellerInfo: ILtTravellerInfo[] = selectedTraveller.map((item: INominatedPassenger) => {
    const isPassportDataExist = !!item?.passportDetail;
    const baseData: ITravellerDetailsItem = {
      dependentId: item.dependentId,
      beneficiaryTypeCode: item.beneficiaryTypeCode,
      firstName: item.firstName,
      lastName: item.lastName,
      mustTravelWithBeneficiaryTypeCode: item.mustTravelWithBeneficiaryTypeCode,
    };

    return {
      ...baseData,
      ...(isPassportDataExist && {
        passportInput: {
          dateOfBirth: item.passportDetail?.dateOfBirth,
          expiryDate: item.passportDetail?.passportExpirationDate,
          gender: (item.passportDetail?.gender as Configuration.DropdownOptionClient)?.code,
          issuingCountry: (item.passportDetail?.passportCountry as Configuration.DropdownOptionClient)?.code,
          nationality: (item.passportDetail?.passportNationality as Configuration.DropdownOptionClient)?.code,
          passportName: item.passportDetail?.passportName,
          passportNumber: item.passportDetail?.passportNumber,
        },
      }),
      ...(item.infantWithSeat === false &&
        item.validationInfo.ageType === NOMINATED_PASSENGER_AGE_TYPE.infant && {
          attachedTo: {
            dependentId:
              adultTravellers.length >= 2 ? item.accompanyAdult?.dependentId || null : adultTravellers[0]?.dependentId,
            beneficiaryTypeCode:
              adultTravellers.length >= 2
                ? item.accompanyAdult?.beneficiaryTypeCode || ''
                : adultTravellers[0]?.beneficiaryTypeCode,
          },
        }),
    };
  });

  const getFareAndTravellerDetails = async () => {
    const { arrivalDate } = outwardFlight || {};
    const bookingClass = seatOption || {};
    const flightSectors = constructFlightSectors(outwardFlight, {
      arrivalDate,
      bookingClass,
    });

    const results =
      (await getFareAndTravellerDetailsLT(
        ern,
        concessionRule?._id || '',
        flightSectors,
        selectedTravellerInfo,
        en.errorAlert.getFareAndTravellerInfo,
      )) || null;

    if (results) {
      // 1. Extract the travellerDetail array
      const travellerDetails: ITravellerDetailsItem[] = results.travellerDetails;

      const returnedTravellerDependentId: Array<string | null> = travellerDetails.map(
        (travellerDetail: ITravellerDetailsItem) => travellerDetail.dependentId,
      );

      let updatedltTravellerList = _.cloneDeep(ltSeatConcessionTravellerList);

      // 2. Set travelDetail and passportDetail to ltTraveller
      updatedltTravellerList = updatedltTravellerList.map((traveller: INominatedPassenger) => {
        // Get the travellerDetail from the getFare API
        if (returnedTravellerDependentId.includes(traveller.dependentId)) {
          // from api resp data
          const matchedTravellerDetails = travellerDetails.find(
            (travellerDetail: ITravellerDetailsItem) => travellerDetail.dependentId === traveller.dependentId,
          );
          // 3. Process the countryOfResidential into Configuration.CodeLabel
          let countryOfResidentialOption;
          if (traveller.isUpdated && traveller?.countryOfResidential) {
            // get lastest data from Redux
            countryOfResidentialOption = traveller.countryOfResidential;
          } else {
            countryOfResidentialOption = configurations?.countries?.find(
              (option: Configuration.DropdownOptionClient) =>
                option.code === matchedTravellerDetails?.countryOfResidential,
            );
          }

          //4. Process passport Detail (passportCountry, passportNationality and gender)
          let passportDetail: IPassport | undefined;
          let accompanyAdult: any;

          if (traveller.isUpdated && traveller?.passportDetail) {
            passportDetail = traveller?.passportDetail;
          } else {
            passportDetail = _.cloneDeep(matchedTravellerDetails?.passport);

            // update passport data from fare API
            if (passportDetail) {
              passportDetail.gender = configurations?.labels?.find(
                (option: Configuration.CodeLabel) =>
                  option.category === LABEL_CATEGORY.gender && option.code === passportDetail?.gender,
              );
              passportDetail.passportCountry = configurations?.countries?.find(
                (option: Configuration.DropdownOptionClient) => option.code === passportDetail?.passportCountry,
              );
              passportDetail.passportNationality = configurations?.countries?.find(
                (option: Configuration.DropdownOptionClient) => option.code === passportDetail?.passportNationality,
              );
            }
          }
          if (matchedTravellerDetails?.attachedTo) {
            accompanyAdult = travellerDetails.find(
              (travellerDetail: ITravellerDetailsItem) =>
                travellerDetail.dependentId === matchedTravellerDetails?.attachedTo.dependentId,
            );
          }

          return {
            ...traveller,
            accompanyAdult: accompanyAdult,
            countryOfResidential: countryOfResidentialOption,
            passportDetail: passportDetail,
          };
        } else {
          return traveller;
        }
      });

      // Set contactDetails
      dispatch(
        setLeisureTravelDetails({
          ...leisureTravelDetails,
          ltSeatConcessionTravellerList: updatedltTravellerList,
          contactDetails: results.contactDetails || {},
          fareDetail: results.fare,
        }),
      );
    }

    return results;
  };

  const getDocsDocaAction = async () => {
    const { arrivalDate } = outwardFlight || {};
    const bookingClass = seatOption;
    const flightSectors = constructFlightSectors(outwardFlight, {
      arrivalDate,
      bookingClass,
    });

    const docsDocaResult = await getDocsDoca(flightSectors);

    dispatch(setDocsDoca(docsDocaResult));

    return docsDocaResult;
  };

  const selectConcessionAction = useCallback(
    (concession: Configuration.DropdownOptionClient) => {
      if (concession && outwardFlight) {
        // Handle: Drop the accompany form data when switch concession
        setIsKeepData(false);

        const updateDetails = selectOrPrefillConcession({
          ltConcessions,
          ltSeatConcessionTravellerList,
          restrictionBeneficiaryType,
          selectedConcessionCode: concession?.code,
          outwardFlight,
          leisureTravelDetails,
          startDate,
        });

        dispatch(setLeisureTravelDetails(updateDetails));
      }
    },
    [selectedConcession, isAllSeatOptionAvailable, ltConcessions, canProceed, SEAT_OPTIONS],
  );

  const selectSeatOptionAction = useCallback(
    (value: IButtonValue) => {
      if (selectedConcession && restrictionBeneficiaryType) {
        const updateDetails = selectOrPrefillSeatOption({
          selectedConcession,
          ltConcessions,
          ltSeatConcessionTravellerList,
          leisureTravelDetails,
          restrictionBeneficiaryType,
          availability,
          selectedSeatOption: value.id,
        });
        dispatch(setLeisureTravelDetails(updateDetails));
      }
    },
    [ltConcessions, seatOption, seatConcession, leisureTravelDetails, restrictionBeneficiaryType, canProceed],
  );

  useEffect(() => {
    if (isFooterValid) {
      setIsKeepData(true);
    }
  }, [isFooterValid]);
  // ----------------- End of Handle Keep Data Logic -----------------

  useEffect(() => {
    setLeisureTravelConcessions(ltConcessions || []);
  }, []);

  return (
    <Box
      sx={{
        background: isDesktop ? theme.color.secondary.light_slate.option_7 : 'transparent',
      }}
    >
      {!isDesktop && (
        <LtMiniFlightInfo
          {...{
            callSign,
            departureTime,
            arrivalTime,
            departureDate,
            arrivalDate,
          }}
        />
      )}
      <Grid
        component={ScrollableView}
        sx={{
          mt: isDesktop ? 0 : 2,
          px: isDesktop ? 0 : 2,
          pb: 2,
          height: isDesktop
            ? `calc(${screenHeight}px  - ${UI_STYLES.desktopHeaderHeight} - ${
                isFooterValid ? UI_STYLES.desktopFooterHeight : '0px'
              } )`
            : `calc(${screenHeight}px - ${UI_STYLES.navBarHeight}  
                - ${UI_STYLES.searchBarResultPageHeight} - ${UI_STYLES.footerHeight} )`,
        }}
      >
        <DesktopBreadcrumb
          step={2}
          customStyles={{ height: UI_STYLES.overlayHeaderHeightSearchCriteria }}
          customCrumbs={en.booking.ltBreadCrumbs}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            width: isDesktop ? UI_STYLES.desktopHeaderNavWidth : '100%',
            mx: 'auto',
          }}
        >
          {isDesktop && (
            <Header
              leftChild={
                <Typography
                  color={theme.color.utility.link.option_3}
                  fontWeight="bold"
                  sx={{
                    pl: 1,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    handleBackClick?.();
                  }}
                >
                  {en.booking.flightClass.backTravelOptions}
                </Typography>
              }
              customStyles={{
                mb: 2,
              }}
              handleOnBack={handleBackClick}
            />
          )}

          {outwardFlight?.marketingCompany === CARRIER_OPTION.CX &&
            leisureTravelDetails.sub === ID_TICKET_TYPE.SUBLO && (
              <LtBookingReminder
                title={en.booking.travelType.leisureTravel.travelReminder}
                content={en.booking.travelType.leisureTravel.travelReminderContent}
              />
            )}

          {isDesktop && (
            <LtMiniFlightInfo
              {...{
                callSign,
                departureTime,
                arrivalTime,
                departureDate,
                arrivalDate,
              }}
            />
          )}

          <Typography
            variant="body_1_medium"
            sx={{
              mt: isDesktop ? 1.5 : 0,
              color: theme.color.secondary.dark_grey.option_3,
            }}
          >
            {en.booking.travelType.concession}
          </Typography>
          <ReduxFormSelectInput
            input={{
              value: seatConcession,
              onChange: selectConcessionAction,
            }}
            title={seatConcession ? '' : en.booking.travelType.selectConcession}
            options={SEAT_CONCESSION || []}
            autocomplete={{
              customOpen: true,
            }}
            isReadOnly={isOnlySeatOptionAvailable.length > 0 && leisureTravelConcessions?.length === 1}
            customInputStyle={{
              '& .MuiFilledInput-root': {
                // width: exceed textfield width
                width: isDesktop ? '532px' : 'calc(100vw - 32px)',
                paddingRight: '0 !important',
                '& .MuiFilledInput-input': {
                  paddingTop: '8px',
                  marginLeft: '-8px',
                  paddingLeft: '16px',
                  background: 'transparent',
                },
                '& .MuiSelect-icon': {
                  top: 'calc(50% - 0.75em)',
                  right: '10px',
                },
                '& .MuiTypography-root': {
                  marginRight: '8px',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                },
              },
            }}
            customMenuStyle={{
              // width: exceed dropdown box width
              width: isDesktop ? '532px' : 'calc(100vw - 32px)',
            }}
          />

          {seatConcession && (
            <>
              <Box
                sx={{
                  minHeight: '112px',
                  background: theme.color.etp.option_1,
                  borderRadius: 1,
                  mb: 3,
                }}
              >
                <Grid container xs={12} px={1} pt={1.5} pb={0.5}>
                  {CONCESSION_DEF_DATA &&
                    CONCESSION_DEF_DATA.map((item: any, index: number) => {
                      return <ConcessionLabel key={index} data={item} />;
                    })}
                </Grid>
              </Box>
              <Typography
                variant="body_1_medium"
                sx={{
                  my: 1.5,
                  color: theme.color.secondary.dark_grey.option_3,
                }}
              >
                {en.booking.breadCrumbs.seatOptions}
              </Typography>

              <Box
                sx={{
                  height: '70px',
                }}
              >
                {/* Seat Option Selection */}
                {SEAT_OPTIONS && (
                  <RadioButtonGroup
                    options={SEAT_OPTIONS}
                    variant={RADIO_BUTTON_GROUP_VARIANT.CHIP}
                    titleVariant="body_2_medium"
                    defaultVal={seatOption}
                    isChip
                    onChange={selectSeatOptionAction}
                    customButtonStyle={{
                      width: '48px',
                      height: '48px',
                      py: 0,
                      border: 'none',
                      boxShadow: theme.boxShadow.important,
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      '&.MuiButtonBase-root': {
                        '&.MuiToggleButtonGroup-grouped': {
                          m: 0,
                          mr: 2,
                          background: theme.palette.bgColor.main,
                          borderRadius: '8px',
                        },
                        '&.MuiToggleButtonGroup-grouped:last-of-type': {
                          mr: 0,
                        },
                        '.MuiTypography-root': {
                          color: theme.color.secondary.dark_grey.option_3,
                        },
                        '&.Mui-selected, &.Mui-selected:hover': {
                          background: theme.color.utility.link.option_3,
                          '.MuiTypography-root': {
                            color: theme.palette.bgColor.main,
                          },
                        },
                        '&.Mui-disabled': {
                          background: theme.color.utility.unselectable.option_7,
                          boxShadow: 'none !important',
                          border: 0,
                        },
                      },
                    }}
                  />
                )}
              </Box>
            </>
          )}
          <Typography
            variant="body_1_medium"
            sx={{
              mt: 1,
              mb: 1.5,
              color: theme.color.secondary.dark_grey.option_3,
            }}
          >
            {`${en.booking.travelType.leisureTravel.selectedTraveller} (${travellerCount})`}
          </Typography>

          <LtTravellers
            isSelectConcessionPage={true}
            ltTravellers={selectedTravellers}
            unSelectTravellers={unSelectTravellers}
            setIsAllAccompanySelected={setIsAllAccompanySelected}
            isKeepData={isKeepData}
            isShowForm={canProceed && isConcessionAndSeatOptionSelected}
            isInfantOrChildTraveller={isCXCarrier ? isInfantOrChildTraveller : false}
            isRequireAccompanyForm={isRequireAccompanyForm}
            isShowAccompanyButton={isShowAccompanyButton}
            adultTravellers={adultTravellers}
            infantWithoutSeatTravellers={infantWithoutSeatTravellers}
          />
          {isRequireAccompanyForm && isConcessionAndSeatOptionSelected && canProceed && isTravellerAvailable && (
            <Box sx={{ mt: 2, display: 'flex', flexDirection: 'row' }}>
              <Box sx={{ mt: -1, ml: -1 }}>
                <Checkbox
                  sx={{
                    '&.MuiCheckbox-root': {
                      color: theme.status.selected,
                    },
                  }}
                  checkedIcon={<CheckBoxCheckIcon fill={theme.status.selected} />}
                  icon={<CheckBoxIcon stroke={theme.status.selected} />}
                  color="info"
                  checked={checked}
                  onChange={checkAccompanyForm}
                />
              </Box>

              <Typography
                variant="body_2_regular"
                sx={{
                  color: theme.color.secondary.dark_grey.option_1,
                }}
              >
                {en.booking.travelType.leisureTravel.consent}
              </Typography>
            </Box>
          )}
        </Box>
      </Grid>

      <Footer
        leftChild={
          <FormButton
            theme={theme}
            colour="transparent"
            size="large"
            sx={{
              height: '44px',
              display: 'flex',
              justifyContent: 'center',
              minWidth: 0,
            }}
            onClick={() => {
              history.go(0);
            }}
          >
            {en.common.cancel}
          </FormButton>
        }
        primaryBtn={
          isFooterValid
            ? {
                text: en.common.continue,
                customOnClick: async () => {
                  // ow/oal no need to call docs/doca api
                  const docsDocaResult = isCXCarrier ? await getDocsDocaAction() : undefined;

                  if (!_.isNil(docsDocaResult) || !isCXCarrier) {
                    const fareResult = await getFareAndTravellerDetails();
                    !_.isNil(fareResult) && handleFooterClick?.();
                  }
                },
                customButtonStyles: {
                  marginRight: 0,
                },
              }
            : undefined
        }
        customStyles={
          isDesktop
            ? {
                height: UI_STYLES.desktopFooterHeight,
                px: 22,
                boxShadow: theme.palette.boxShadow.dark,
                borderTopLeftRadius: '24px',
                borderTopRightRadius: '24px',
              }
            : {
                boxShadow: theme.boxShadow.important,
                px: 2,
              }
        }
      />
    </Box>
  );
};

const LtSeatClassSelectionContextWrapper = (props: any) => {
  return <LtSeatClassSelection {...props} />;
};

export default LtSeatClassSelectionContextWrapper;
