import React from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { use100vh } from 'react-div-100vh';
import { useHistory, useLocation } from 'react-router-dom';

import { UI_STYLES } from '../../constants/constants';
import { USER_PROFILE_PATHS } from '../../constants/paths';
import en from '../../translations/en';

import { IRouteConfigProps } from '../../interfaces';

import { handleBackToUserProfile } from '../../helpers';

import { setIsShowPaymentHistoryDialog } from '../../slice/userSlice';
import { EditPaymentHistoryContextProvider } from '../../context/PaymentHistoryContext';
import { selectApp } from '../../slice/appSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

import { Header, DesktopView, RouteSwitch, ScrollableView, Div100vhView } from '../../components';
import ConcessionWithTraveller from '../../containers/UserProfile/Common/ConcessionWithTraveller';

const UserProfileDetails = ({ routes }: IRouteConfigProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const theme = useTheme();
  const screenHeight = use100vh();
  const { isDesktop } = useAppSelector(selectApp) || {};

  const location = useLocation();

  const isPaymentHistoryPage = location?.pathname === USER_PROFILE_PATHS.paymentHistory;

  const isConcessionPage = location?.pathname === USER_PROFILE_PATHS.concessionDetails;

  //TODO can enhance more pages if need empty padding config
  const isEmptyPaddingPage = [USER_PROFILE_PATHS.paymentHistory].includes(location?.pathname);
  return (
    <EditPaymentHistoryContextProvider>
      <Div100vhView sx={{ bgcolor: theme.color.secondary.light_slate.option_7 }}>
        <DesktopView component={Grid}>
          <Box
            sx={{
              display: 'flex',
              background: 'white',
              px: 21,
              ...(isPaymentHistoryPage && {
                pr: 21,
              }),
              boxShadow: theme.boxShadow.important,
              height: UI_STYLES.homepageNavBarHeight,
              my: 'auto',
            }}
          >
            <Header
              iconColor={theme.color.utility.link.option_3}
              leftChild={
                <Typography
                  variant="headline_bold"
                  color={theme.color.utility.link.option_3}
                  sx={{ ml: 1, cursor: 'pointer' }}
                  onClick={() => handleBackToUserProfile(history)}
                >
                  {en.userProfile.backToProfile}
                </Typography>
              }
              rightChild={isDesktop && isConcessionPage && <ConcessionWithTraveller />}
              handleOnBack={() => {
                dispatch(setIsShowPaymentHistoryDialog(false));
                handleBackToUserProfile(history);
              }}
            />
          </Box>
        </DesktopView>
        <Grid
          component={ScrollableView}
          sx={{
            height: isDesktop
              ? `calc(${screenHeight}px - ${UI_STYLES.navBarHeight} - ${UI_STYLES.desktopHeaderHeight} )`
              : undefined,
          }}
          container
          pt={!isEmptyPaddingPage && isDesktop ? 2 : 0}
          px={{
            xs: !isEmptyPaddingPage ? 2 : 0,
            sm: 0,
          }}
        >
          <Grid container direction="column">
            <Grid
              item
              py={isEmptyPaddingPage ? 0 : 2}
              sx={{
                ...(isDesktop && {
                  display: 'flex',
                  justifyContent: 'center',
                }),
              }}
            >
              <RouteSwitch routes={routes} />
            </Grid>
          </Grid>
        </Grid>
      </Div100vhView>
    </EditPaymentHistoryContextProvider>
  );
};

export default UserProfileDetails;
