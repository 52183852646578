import { HTTP_REQUEST_TYPE, FLIGHTLOAD_API } from '../constants/api';
import en from '../translations/en';

import { sendHttpRequest } from './api';

import { FlightSearch } from 'flightSearch';

const getFlightLoad = async (flight: FlightSearch.ParsedFlightSearchResult) => {
  const { marketingCompany: airlineCode, flightNo: flightNumber, departureDate, departurePort, arrivalPort } = flight;
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: FLIGHTLOAD_API.getFlightLoad,
    apiTitle: en.errorAlert.getFareAndTravellerLT,
    needLoading: false,
    hideErrorDialog: true,
    payload: {
      airlineCode,
      flightNumber,
      departureDate,
      departurePort,
      arrivalPort,
    },
  });

  return resp;
};

export { getFlightLoad };
