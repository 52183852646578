import React from 'react';
import { Typography, useTheme } from '@mui/material';

import en from '../../../../translations/en';

import { IButtonValue } from '../../../../interfaces';

import { getConcessionForRadioButtonGroup } from '../../../../helpers';

import { RadioButtonGroup } from '../../../../components';
import { NoTravelConcessionComponent, ConcessionListContainer } from '../../..';

const DtConcessionSection = ({
  concession,
  isSearchBar,
  startDate,
  defaultConcessionValue,
  concessionOnChange,
}: {
  concession: any;
  startDate: any;
  defaultConcessionValue: any;
  isSearchBar?: boolean;
  concessionOnChange: (value: IButtonValue) => void;
}) => {
  const theme = useTheme();

  const concessionItemProps = {
    options: getConcessionForRadioButtonGroup(concession, startDate),
    defaultVal: defaultConcessionValue,
    onChange: concessionOnChange,
  };
  return (
    <>
      <Typography
        variant="headline_medium"
        color={theme.color.secondary.dark_grey.option_3}
        sx={{ mt: 1, mb: 1.5, px: 1 }}
      >
        {en.booking.travelType.concession}
      </Typography>

      {concession?.length > 0 ? (
        isSearchBar ? (
          <ConcessionListContainer {...concessionItemProps} />
        ) : (
          <RadioButtonGroup {...concessionItemProps} customButtonStyle={{ p: 2 }} />
        )
      ) : (
        <NoTravelConcessionComponent />
      )}
    </>
  );
};

export default DtConcessionSection;
