import React, { useState } from 'react';
import { InputLabelProps, Box, useTheme } from '@mui/material';

import { IBookingFlowOverlayData } from '../../../interfaces';

import { useAppSelector } from '../../../app/hooks';
import { selectApp } from '../../../slice/appSlice';

import { DrawerOverlay, FormFieldTextInput } from '../../../components';

const DateFieldBoxComponent = ({
  label,
  inputRef,
  value,
  shrink,
  title,
  component,
  data,
}: {
  label: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  value?: string;
  shrink?: Partial<InputLabelProps>;
  title: string;
  component: React.ComponentType<any>;
  data?: IBookingFlowOverlayData;
}) => {
  const theme = useTheme();
  const { isDesktop } = useAppSelector(selectApp) || {};

  const [showOverlay, setShowOverlay] = useState<boolean>(false);

  return (
    <Box
      sx={{
        mt: 1,
        mb: 0.5,
        display: 'flex',
        alignItems: 'center',
        background: theme.color.secondary.light_slate.option_6,
        borderRadius: '6px',
      }}
    >
      <DrawerOverlay
        showOverlay={showOverlay}
        handleClose={() => {
          setShowOverlay(false);
        }}
        title={title}
        component={component}
        data={data}
      />
      <FormFieldTextInput
        variant="filled"
        label={label}
        color="info"
        value={value}
        onClick={() => setShowOverlay(true)}
        inputRef={inputRef}
        InputLabelProps={shrink}
        autoComplete="off"
        sx={{
          width: '100%',
          m: 0,
          mt: isDesktop ? -0.5 : 0,
          ...(data?.isEditPopup && {
            '& .MuiFilledInput-root': {
              border: 0,
              '&.Mui-focused': {
                border: 0,
              },
            },
          }),
        }}
        inputProps={{
          style: {
            ...theme.typography.body_1_medium,
            ...(data?.isEditPopup && {
              backgroundColor: theme.color.secondary.light_slate.option_6,
            }),
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            color: theme.color.secondary.dark_grey.option_3,
          },
          readOnly: true,
        }}
      />
    </Box>
  );
};

export default DateFieldBoxComponent;
