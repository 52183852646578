import React from 'react';
import { Box } from '@mui/material';

import { USER_ROLE } from '../../../../constants/constants';
import en from '../../../../translations/en';

import { useAppSelector } from '../../../../app/hooks';
import { selectUser } from '../../../../slice/userSlice';
import { selectBooking } from '../../../../slice/bookingSlice';

import { FlightBookingCart, FlightSearchFooter, AdminEmployeeTraveller } from '../../..';

const ResultRightLayout = ({
  isFromRebookFlow,
  handleFooterClick,
}: {
  isFromRebookFlow?: boolean;
  handleFooterClick?: () => void;
}) => {
  const { role } = useAppSelector(selectUser) || {};
  const { isLegendsDisplay } = useAppSelector(selectBooking) || {};
  const isAdminRole = role.type === USER_ROLE.admin;

  return (
    <>
      {isAdminRole ? (
        <Box sx={{ p: 0.5 }}>
          <AdminEmployeeTraveller />
          <FlightBookingCart
            handleFooterClick={() => {
              handleFooterClick?.();
            }}
          />
        </Box>
      ) : (
        <FlightBookingCart
          isFromRebookFlow={isFromRebookFlow}
          handleFooterClick={() => {
            handleFooterClick?.();
          }}
        />
      )}
      {isLegendsDisplay && (
        <Box sx={{ mt: 2 }}>
          <FlightSearchFooter
            desc={en.booking.legends.desc}
            title={en.booking.legends.title}
            dateList={en.booking.legends.remarks}
          />
        </Box>
      )}
    </>
  );
};

ResultRightLayout.defaultProps = {
  isFromRebookFlow: false,
  handleFooterClick: undefined,
};

export default ResultRightLayout;
