import React, { useEffect, useState } from 'react';

import { API_CACHE } from '../../constants/api';

import { IRouteToProps } from '../../interfaces';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
  selectBooking,
  setOutwardFlight,
  cleanUpLTFareDetail,
  setTpAppRef,
  // setBookingStep,
  // setViewBookingApplicationId,
} from '../../slice/bookingSlice';

import { clearCacheData } from '../../services/api';

import { BookingFlowOverlay } from '../../containers';
import { BOOKING_FLOW_PAGE_NO } from '../../constants/constants';
import { ROOT_PATHS } from '../../constants/paths';
import { selectApp } from '../../slice/appSlice';
import { useLocation } from 'react-router-dom';

const BookingFlowContainer = ({
  handleClose,
  routePages,
  variant,
}: {
  handleClose: () => void;
  routePages: any[];
  variant?: string;
}) => {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const tpAppRefFromUrl = searchParams.get('tpAppRef');

  const { retrieveAndPaySuccessData } = useAppSelector(selectApp) || {};
  const { leisureTravelDetails, bookingStep, tpAppRef } = useAppSelector(selectBooking) || {};
  const dispatch = useAppDispatch();

  const [showOverlay, setShowOverlay] = useState<boolean>(true);
  const [routeTo, setRouteTo] = useState<IRouteToProps>({
    step: 0,
    data: {},
  });

  const { step: routeToStep, data: routeToData } = routeTo || {};

  //TODO need to be refactored for the clean up logic later
  useEffect(() => {
    if (routeToStep === 1) {
      sessionStorage.removeItem('searchResultCache');
    }
    if (routeToStep !== 3) {
      // clear cache data
      clearCacheData([
        API_CACHE.dtFare,
        API_CACHE.dtDocsDoca,
        API_CACHE.dtTravellerName,
        API_CACHE.adminDtFare,
        API_CACHE.adminDtDocsDoca,
        API_CACHE.adminDtTravellerName,
        API_CACHE.delegateeDtFare,
        API_CACHE.delegateeDtDocsDoca,
        API_CACHE.delegateeDtTravellerName,
      ]);

      // clear the LT review & confirm page's `fareDetail` redux data
      if (leisureTravelDetails?.fareDetail !== null && bookingStep === null) dispatch(cleanUpLTFareDetail());
    }
  }, [routeToStep]);

  // This useEffect is used to CPP back and completed redirect action
  useEffect(() => {
    if (bookingStep === BOOKING_FLOW_PAGE_NO.confirmation) {
      setRouteTo({ step: bookingStep });
    } else if (tpAppRefFromUrl && bookingStep === BOOKING_FLOW_PAGE_NO.viewBooking) {
      dispatch(setTpAppRef(tpAppRefFromUrl));
    } else if (bookingStep === BOOKING_FLOW_PAGE_NO.completed && tpAppRef && retrieveAndPaySuccessData) {
      setRouteTo({ step: bookingStep, data: retrieveAndPaySuccessData });
    }
  }, [bookingStep, tpAppRef, tpAppRefFromUrl, retrieveAndPaySuccessData]);

  useEffect(() => {
    if (tpAppRef && bookingStep === BOOKING_FLOW_PAGE_NO.viewBooking) {
      // retrieve and pay flow
      setTimeout(() => {
        window.location.replace(`${ROOT_PATHS.landing}?tpAppRef=${tpAppRef}`);
      }, 500);
    }
  }, [tpAppRef, bookingStep]);

  return (
    <BookingFlowOverlay
      variant={variant}
      open={showOverlay}
      handleBackClick={() => {
        if (routeToStep > 0) {
          if (routeToStep === 1) {
            // SeatClass -> SearchResult
            dispatch(setOutwardFlight(null));
          }
          setRouteTo((prev) => {
            return {
              step: prev.step - 1,
            };
          });
        } else {
          setShowOverlay(false);
          handleClose && handleClose();
        }
      }}
      handleFooterClick={() => {
        setRouteTo((prev) => {
          return {
            step: prev.step + 1,
          };
        });
      }}
      {...routePages[routeToStep]}
      data={{
        goToRouteStep: (routeTo: IRouteToProps) => {
          setRouteTo(routeTo);
        },
        ...routePages[routeToStep].data,
        ...routeToData,
        routeToStep,
      }}
    />
  );
};

export default BookingFlowContainer;
