import { Typography, Grid, useTheme, Box } from '@mui/material';

import en from '../../../../translations/en';
import { LABEL_CATEGORY, PAYMENT_CONCEPT } from '../../../../constants/constants';

import { useAppSelector } from '../../../../app/hooks';
import { selectApp } from '../../../../slice/appSlice';
import { selectConfiguration } from '../../../../slice/configurationSlice';

import { getDisplayLabel } from '../../../../helpers';

import { ShadowContent } from '../../../../components';

const ViewLtPaymentDetails = ({ paymentConcept, paymentMedium }: { paymentConcept: string; paymentMedium: string }) => {
  const theme = useTheme();

  const { isDesktop } = useAppSelector(selectApp) || {};
  const {
    title,
    paymentConcept: paymentConceptLbl,
    paymentMedium: paymentMediumLbl,
  } = en.booking.flightConfirmation.travelDetails.paymentDetails;

  const { configurations } = useAppSelector(selectConfiguration) || {};

  return (
    <Box
      component={ShadowContent}
      px={1}
      sx={{
        maxWidth: isDesktop ? '518px' : 'none',
        margin: 'auto',
        mb: 2,
        pb: 0,
        pt: 2,
      }}
    >
      <Box
        component={Typography}
        variant="body_2_medium"
        sx={{
          px: 1,
        }}
      >
        <Typography variant="body_1_bold">{title}</Typography>

        <Grid container rowSpacing={0} columnSpacing={1} sx={{ my: 2 }}>
          {
            <Grid xs={12} pl={1}>
              <Typography variant="navigation_regular" color={theme.color.secondary.grey.option_2}>
                {paymentConceptLbl}
              </Typography>
              <Typography variant="body_1_medium" color={theme.color.secondary.dark_grey.option_3}>
                {getDisplayLabel(configurations.labels, LABEL_CATEGORY.paymentConcept, paymentConcept)}
              </Typography>
            </Grid>
          }

          {paymentConcept !== PAYMENT_CONCEPT.PREPAID && (
            <Grid xs={12} pl={1} pt={2}>
              <Typography variant="navigation_regular" color={theme.color.secondary.grey.option_2}>
                {paymentMediumLbl}
              </Typography>
              <Typography variant="body_1_medium" color={theme.color.secondary.dark_grey.option_3}>
                {getDisplayLabel(configurations.labels, LABEL_CATEGORY.paymentMedium, paymentMedium)}
              </Typography>
            </Grid>
          )}
          {/* ETP-2431 */}
          {paymentConcept === PAYMENT_CONCEPT.PREPAID && (
            <Grid xs={12} pl={1} pt={2}>
              <Typography variant="navigation_regular" color={theme.color.secondary.grey.option_2}>
                {en.booking.flightConfirmation.travelDetails.paymentDetails.paymentStatus}
              </Typography>
              <Typography variant="body_1_medium" color={theme.color.secondary.dark_grey.option_3}>
                {en.booking.flightConfirmation.travelDetails.paymentDetails.notPaid}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default ViewLtPaymentDetails;
