import React, { createContext, useEffect, useState } from 'react';
import _ from 'lodash';

import { TRAVELLER_TYPE } from '../constants/constants';

import { ILtConcession, INominatedPassenger } from '../interfaces';

import { getDisplayName } from '../helpers';

import { useAppSelector } from '../app/hooks';
import { selectUser } from '../slice/userSlice';

interface IConcessionInProfileStateType {
  selectTraveller: any;
  middleTraveller: any;
  travellerList: any;
  ltConcessionList: any;
  restrictionBeneficiaryType: any;
}

interface IActionType {
  setSelectTraveller: any;
  setMiddleTraveller: any;
  setTravellerList: any;
}

const ConcessionInProfileState = createContext<IConcessionInProfileStateType>({
  selectTraveller: null,
  middleTraveller: null,
  travellerList: null,
  ltConcessionList: null,
  restrictionBeneficiaryType: null,
});

const ConcessionInProfileAction = createContext<IActionType>({
  setSelectTraveller: null,
  setMiddleTraveller: null,
  setTravellerList: null,
});

const ConcessionInProfileContextProvider = ({ children }: { children?: React.ReactNode }) => {
  const { concession } = useAppSelector(selectUser) || {};
  const [selectTraveller, setSelectTraveller] = useState<INominatedPassenger>();
  const [middleTraveller, setMiddleTraveller] = useState<INominatedPassenger | null>();
  const [ltConcessionList, setLtConcessionList] = useState<ILtConcession[]>();
  const [travellerList, setTravellerList] = useState<INominatedPassenger[]>();
  const [restrictionBeneficiaryType, setRestrictionBeneficiaryType] = useState<[]>();

  useEffect(() => {
    const newConcession = _.cloneDeep(concession);
    const tempPaxList = (newConcession?.paxList || []) as INominatedPassenger[];
    const tempLtConcession = (newConcession?.leisureTravel || []) as ILtConcession[];
    const tempRestrictionBeneficiaryType = newConcession.restrictionBeneficiaryType;
    tempPaxList.map((item) => {
      item['label'] = getDisplayName({
        title: item?.title,
        firstName: item?.firstName,
        lastName: item?.lastName,
      });
    });
    const tempEmployee: INominatedPassenger | undefined = tempPaxList?.find(
      (item: INominatedPassenger) => item.beneficiaryTypeCode === TRAVELLER_TYPE.employee,
    );

    setSelectTraveller(tempEmployee);
    setMiddleTraveller(tempEmployee);
    setLtConcessionList(tempLtConcession);
    setTravellerList(tempPaxList);
    setRestrictionBeneficiaryType(tempRestrictionBeneficiaryType);
  }, [concession]);

  useEffect(() => {
    middleTraveller && setSelectTraveller(middleTraveller);
  }, [middleTraveller]);

  return (
    <ConcessionInProfileState.Provider
      value={{
        selectTraveller,
        middleTraveller,
        travellerList,
        ltConcessionList,
        restrictionBeneficiaryType,
      }}
    >
      <ConcessionInProfileAction.Provider
        value={{
          setSelectTraveller,
          setMiddleTraveller,
          setTravellerList,
        }}
      >
        {children}
      </ConcessionInProfileAction.Provider>
    </ConcessionInProfileState.Provider>
  );
};

export { ConcessionInProfileContextProvider, ConcessionInProfileState, ConcessionInProfileAction };
