import { Box, Typography } from '@mui/material';

import { USER_ROLE } from '../../constants/constants';
import { DesktopBackground } from '../../assets/images';
import en from '../../translations/en';

import { getShortDisplayName } from '../../helpers';

import { useAppSelector } from '../../app/hooks';
import { selectUser } from '../../slice/userSlice';

import { NavBar } from '../../components';
import { RoleIndicatorBar } from '..';

const DesktopHeader = () => {
  const { profile, role } = useAppSelector(selectUser) || {};

  const { personalInfo } = profile || {};
  const { preferredFirstName, firstName, lastName, middleName } = personalInfo || {};

  const { type: roleType, delegator = {} } = role || {};

  const isAdminDelegation = [USER_ROLE.admin, USER_ROLE.delegation].includes(roleType);

  return (
    <Box
      sx={{
        backgroundImage: `url(${DesktopBackground})`,
        height: isAdminDelegation ? 220 : 180,
        backgroundSize: '100% 190%',
        color: 'white',
        position: 'relative',
        backgroundPositionY: '-82px',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <NavBar
        handleCloseOverlay={() => {
          history.go(0);
        }}
      />
      {isAdminDelegation && (
        <RoleIndicatorBar
          customStyles={{
            position: 'absolute',
            top: '72px',
            px: '14%',
            background: 'rgba(252, 252, 252, 0.7)',
          }}
        />
      )}
      <Box sx={{ mt: isAdminDelegation ? 6 : 1, ml: '14%' }}>
        <Typography variant="title_1_bold">
          {en.home.hello}
          {getShortDisplayName(
            roleType === USER_ROLE.delegation
              ? delegator
              : {
                  preferredFirstName,
                  firstName,
                  lastName,
                  middleName,
                },
          )}
        </Typography>
      </Box>
    </Box>
  );
};

export default DesktopHeader;
