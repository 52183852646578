import React from 'react';
import { Typography, useTheme, Box, Button } from '@mui/material';
import { shallowEqual } from 'react-redux';

import { MULTIPLE_WARNING_TYPE, TRAVEL_PURPOSE, USER_ROLE } from '../../../../constants/constants';
import { ShoppingCartIcon } from '../../../../assets/images';
import en from '../../../../translations/en';

import { handleBookOneWayOnClick, getNonEnglishCharacterTravellers } from '../../../../helpers';

import { selectBooking, setIsShowDTSecurityReminder, setOutwardFlight } from '../../../../slice/bookingSlice';
import { selectAuth } from '../../../../slice/authSlice';
import { selectUser } from '../../../../slice/userSlice';
import { useAppSelector, useAppDispatch } from '../../../../app/hooks';
import { usePaymentMethodIndicator } from '../../../../hooks';

import { Footer, ShadowContent } from '../../../../components';
import { FlightCard, LtFlightCard, FlightODLabel } from '../../..';
import MultipleWarning from '../../Common/MultipleWarning';

const FlightBookingCart = ({
  isFromRebookFlow,
  handleFooterClick,
}: {
  isFromRebookFlow?: boolean;
  handleFooterClick?: () => void;
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { profile, concession, role } = useAppSelector(selectUser) || {};
  const { ern } = useAppSelector(selectAuth) || {};
  const {
    origin,
    destination,
    outwardFlight,
    travelType,
    lTCabinClassData,
    leisureTravelDetails,
    startDate,
    isShowDTSecurityReminder,
  } = useAppSelector(selectBooking, shallowEqual) || {};

  const isLt = travelType === TRAVEL_PURPOSE.employeeLeisureTravel;
  const isDT = travelType === TRAVEL_PURPOSE.employeeDutyTravel;

  // Payment method indicator
  const [paymentMethodIndicator] = usePaymentMethodIndicator();
  const regulatoryRegion = profile?.regulatoryRegion;

  // Non English Character Name handle
  const paxList = (concession?.paxList || []) as any;
  const isShowNonEnglishCharacter = !isLt && !!getNonEnglishCharacterTravellers(paxList, true);

  // handle delegation role
  const isDelegationRole = role.type === USER_ROLE.delegation;

  // ETP - 4330 DT security reminder
  const securityReminderJson = en.multipleWarning.DT.securityReminderJson;

  return (
    <ShadowContent sx={{ borderRadius: 2, pt: 0 }}>
      <Typography variant="title_2_bold" color={theme.color.secondary.dark_grey.option_3} my={3}>
        <ShoppingCartIcon style={{ marginRight: 11 }} />
        {en.booking.selectFlight.flightBookingCart}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          border: `2px solid ${theme.color.primary.light_jade.option_3}`,
          borderRadius: 0.5,
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'space-between',
          p: 1.5,
        }}
      >
        <Typography variant="body_1_regular" color={theme.color.secondary.dark_grey.option_3}>
          {en.booking.selectFlight.travelRequirements}
        </Typography>
        <Button
          onClick={() => window.open(window.config.travelRequirementsLink)}
          sx={{
            color: theme.color.primary.light_jade.option_2,
            fontWeight: 'bold',
            fontSize: theme.spacing(2),
          }}
        >
          {en.booking.selectFlight.readMore}
        </Button>
      </Box>

      {isDT && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            mt: 1,
          }}
        >
          <MultipleWarning
            onChange={() => {
              dispatch(setIsShowDTSecurityReminder(!isShowDTSecurityReminder));
            }}
            type={MULTIPLE_WARNING_TYPE.DTSecurityReminder}
            isExpand={isShowDTSecurityReminder}
            data={[
              {
                headingCode: 'message1',
              },
            ]}
            messageFormatter={securityReminderJson}
          />
        </Box>
      )}

      <Box mt={outwardFlight ? 2 : 3} display="flex" alignItems="center">
        <FlightODLabel departurePort={origin?.airportCode} arrivalPort={destination?.airportCode} />
        {outwardFlight && (
          <Button
            sx={{
              mx: 1,
              typography: 'body_1_bold',
              color: theme.color.utility.link.option_3,
            }}
            onClick={() => {
              dispatch(setOutwardFlight(null));
            }}
          >
            {en.common.remove}
          </Button>
        )}
      </Box>

      {outwardFlight ? (
        <Box display="flex" sx={{ mb: 2 }}>
          {isLt ? (
            <LtFlightCard
              flight={outwardFlight}
              flag={lTCabinClassData.type}
              isSelected={true}
              initData={true}
              selectFlight={() => {}}
              isShowCabinHeader={true}
            />
          ) : (
            <FlightCard flight={outwardFlight} selectFlight={() => {}} isSelected={true} isFromCart={true} />
          )}
        </Box>
      ) : (
        <Box
          sx={{
            borderRadius: 1,
            border: `${theme.spacing(0.25)} dashed ${theme.color.secondary.dark_grey.option_7}`,
            mt: 2.7,
            pl: 2,
            height: theme.spacing(18.25),
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Typography variant="body_1_medium" color={theme.color.secondary.dark_grey.option_6}>
            {en.booking.selectFlight.selectAFlight}
          </Typography>
        </Box>
      )}

      {outwardFlight && (
        <Footer
          customStyles={{
            borderTop: 0,
            position: 'unset',
            pr: -2,
          }}
          primaryBtn={{
            text: isFromRebookFlow ? en.common.continue : en.booking.selectFlight.oneWay,
            customOnClick: () => {
              isFromRebookFlow
                ? handleFooterClick?.()
                : // continue to book one way
                  handleBookOneWayOnClick({
                    isLt,
                    ern,
                    startDate,
                    outwardFlight,
                    leisureTravelDetails,
                    concession: (isLt ? concession?.leisureTravel : concession?.dutyTravel) || [],
                    handleFooterClick,
                    dispatch,
                    paymentMethodIndicator,
                    regulatoryRegion: regulatoryRegion || '',
                    isShowNonEnglishCharacter,
                    isDelegationRole,
                    concessionRuleId: leisureTravelDetails?.concessionRuleId,
                  });
            },
            customButtonStyles: {
              width: 'auto',
              minWidth: '159px',
            },
          }}
        />
      )}
    </ShadowContent>
  );
};

FlightBookingCart.defaultProps = {
  isFromRebookFlow: false,
  handleFooterClick: undefined,
};

export default FlightBookingCart;
