import React from 'react';
import { Box, Typography, SxProps, Theme } from '@mui/material';

import en from '../../../translations/en';
import theme from '../../../style/theme';

import { ShadowContent } from '../../../components';

const BaseCreatedByInfo = ({ data, customStyles }: { data: string; customStyles?: SxProps<Theme> }) => {
  return (
    <Box component={ShadowContent} sx={{ mb: 2, ...customStyles }}>
      <Typography variant="body_1_regular" color={theme.color.secondary.dark_grey.option_1}>
        {`${en.booking.create.genericCreate} ${data}`}
      </Typography>
    </Box>
  );
};

export default BaseCreatedByInfo;
