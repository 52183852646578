import { HTTP_REQUEST_TYPE, PAYPAL_API } from '../constants/api';
import en from '../translations/en';

import { sendHttpRequest } from './api';

const createBillingAgreementToken = async (userId: string, hostname: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: PAYPAL_API.billingAgreementToken,
    apiTitle: en.errorAlert.createBillingAgreementToken,
    payload: { userId, hostname },
  });

  return resp;
};

const createBillingAgreementId = async (userId: string, tokenId: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: PAYPAL_API.billingAgreementId,
    apiTitle: en.errorAlert.createBillingAgreementId,
    payload: { userId, tokenId },
  });

  return resp;
};

export { createBillingAgreementToken, createBillingAgreementId };
