import React from 'react';
import { Box, Typography } from '@mui/material';
import LoginBgc from '../../assets/images/login/login-test-bgc.jpg';

interface AssoSubsidLoginProps {
  onBtnClick: () => void;
}

const AssoSubsidLogin: React.FC<AssoSubsidLoginProps> = ({ onBtnClick }: AssoSubsidLoginProps) => {
  const BtnSty = {
    marginBottom: '40px',
    boxSizing: 'border-box',
    padding: '10px',
    wordWrap: 'break-word',
    fontSize: '16px',
    color: '#fff',
    backgroundColor: '#4d4f99',
  };
  return (
    <Box
      className="Login"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 auto',
        width: '100%',
        minHeight: `${window.innerHeight}px`,
        height: '100%',
        backgroundImage: `url(${LoginBgc})`,
        backgroundSize: '100% 100%',
      }}
    >
      <Typography
        className="Login-Card"
        sx={{
          width: '40%',
          minWidth: '605px',
          minHeight: '450px',
          backgroundColor: 'white',
          overflow: 'hidden',
          borderRadius: '12px',
          boxSizing: 'border-box',
          padding: '40px',
        }}
      >
        <Typography
          className="Logo"
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '30px',
            fontWeight: '700',
          }}
        >
          <Typography
            className="Login-Logo"
            sx={{
              width: '110px',
              height: '65px',
              backgroundImage: `url(${LoginBgc})`,
              backgroundSize: '100% 100%',
            }}
          />
          <Typography
            className="Title"
            variant="body_1_bold"
            lineHeight="15px"
            color={'cathayJade.dark'}
            sx={{
              margin: '0 15px',
              fontSize: '40px',
            }}
          >
            Login Page
          </Typography>
          <Typography
            className="Title"
            variant="navigation_medium"
            lineHeight="15px"
            color={'cathayJade.dark'}
            sx={{
              margin: '0 15px',
              fontSize: '30px',
            }}
          >
            Travel Hub
          </Typography>
        </Typography>
        <Typography className="description" sx={{ marginBottom: '40px' }}>
          Click the following button to login
        </Typography>
        <Typography sx={BtnSty} onClick={onBtnClick}>
          Special Profile
        </Typography>
        <Typography sx={BtnSty} onClick={onBtnClick}>
          Asso. Subsuid. (in CX AAD)
        </Typography>
        <Typography sx={BtnSty} onClick={onBtnClick}>
          Asso. Subsuid. (B2B Collaboration)
        </Typography>
      </Typography>
    </Box>
  );
};

export default AssoSubsidLogin;
