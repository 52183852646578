const SAMPLE_NORMAL_FLIGHT_LOAD_DATA = {
  carrier: 'CX',
  flightNumber: '408',
  flightDate: '2023-02-20',
  departurePort: 'HKG',
  arrivalPort: 'TPE',
  cabins: [
    {
      cabin: 'F',
      avStr: '4+',
      staffStandbyCounter: 0,
    },
    {
      cabin: 'J',
      avStr: '10+',
      staffStandbyCounter: 0,
    },
    {
      cabin: 'W',
      avStr: '10+',
      staffStandbyCounter: 0,
    },
    {
      cabin: 'Y',
      avStr: '22',
      staffStandbyCounter: 0,
    },
  ],
  percentFilled: '1.61',
  color: 'GREEN',
};

const SAMPLE_FULL_FLIGHT_LOAD_DATA = {
  carrier: 'CX',
  flightNumber: '468',
  flightDate: '2023-02-20',
  departurePort: 'HKG',
  arrivalPort: 'TPE',
  cabins: [
    {
      cabin: 'J',
      avStr: '0',
      staffStandbyCounter: 0,
    },
    {
      cabin: 'W',
      avStr: '8',
      staffStandbyCounter: 0,
    },
    {
      cabin: 'Y',
      avStr: '-5',
      staffStandbyCounter: 0,
    },
  ],
  percentFilled: '1.61',
  color: 'YELLOW',
};

const SAMPLE_EXTREME_FLIGHT_LOAD_DATA = {
  carrier: 'CX',
  flightNumber: '468',
  flightDate: '2023-02-20',
  departurePort: 'HKG',
  arrivalPort: 'TPE',
  cabins: [
    {
      cabin: 'F',
      avStr: '0',
      staffStandbyCounter: 0,
    },
    {
      cabin: 'J',
      avStr: '-2',
      staffStandbyCounter: 0,
    },
    {
      cabin: 'W',
      avStr: '-10*',
      staffStandbyCounter: 0,
    },
    {
      cabin: 'Y',
      avStr: '-20*',
      staffStandbyCounter: 0,
    },
  ],
  percentFilled: '1.61',
  color: 'RED',
};

const SAMPLE_CONCESSION_DATA = {
  dutyTravel: [
    {
      _id: '19',
      name: 'CX flight - Cathay Ground employee (Level A)',
      advanceBookingDays: 90,
      boardingPriority: '10',
      bookingClass: ['Y'],
      regradeClass: 'J',
    },
    {
      _id: '21',
      name: 'oneworld BA - Cathay Ground employee',
      advanceBookingDays: 29,
      boardingPriority: '19/J30',
      bookingClass: ['Y'],
      regradeClass: 'J',
    },
    {
      _id: '54',
      name: 'oneworld JL - Cathay Flight Crew with ground post',
      advanceBookingDays: 29,
      boardingPriority: 'YP2CS4',
      bookingClass: ['Y'],
      regradeClass: 'J',
    },
    {
      _id: '53',
      name: 'oneworld JL - Cathay Ground employee',
      advanceBookingDays: 29,
      boardingPriority: 'YP2CS4',
      bookingClass: ['Y'],
      regradeClass: 'J',
    },
    {
      _id: '23',
      name: 'oneworld QF - Cathay Ground employee',
      advanceBookingDays: 29,
      boardingPriority: '35Y/J900',
      bookingClass: ['Y'],
      regradeClass: 'J',
    },
    {
      _id: '56',
      name: 'oneworld QR - Cathay Flight Crew with ground post',
      advanceBookingDays: 29,
      boardingPriority: '31Y/35C',
      bookingClass: ['Y'],
      regradeClass: 'J',
    },
    {
      _id: '55',
      name: 'oneworld QR - Cathay Ground employee',
      advanceBookingDays: 29,
      boardingPriority: '31Y/35C',
      bookingClass: ['Y'],
      regradeClass: 'J',
    },
  ],
  leisureTravel: {
    paxList: [
      {
        firstName: 'BD XWX',
        lastName: 'LKB',
        title: 'Mr',
        relationship: 'Employee',
        dateOfBirth: '1974-01-23',
        beneficiaryTypeCode: 'EMP',
        dependentId: null,
        eligibleConcession: ['LTRULEZONECX10', 'LTRULEZONECX11', 'LTRULEZONECX12'],
        validationInfo: {
          restriction: [],
          ageType: 'ADT',
          allowAccompany: true,
        },
        age: 49,
      },
      {
        firstName: 'Amanda Sisley',
        lastName: 'Deborah',
        title: 'Miss',
        relationship: 'Companion',
        dateOfBirth: '2009-01-02',
        beneficiaryTypeCode: 'FRD',
        dependentId: 'IFLY_519252',
        eligibleConcession: ['LTRULEZONECX12'],
        validationInfo: {
          restriction: [],
          ageType: 'ADT',
          allowAccompany: false,
        },
        age: 14,
      },
    ],
    concession: [
      {
        _id: 'LTRULEZONECX10',
        displayName: 'ZED: CX Zone (J,W,Y)(Priority 25) - employee, spouse and children',
        group: 'LTZONEGROUP1',
        boardingPriority: '25',
        bookingClass: ['J', 'W', 'Y'],
        regradeClass: '',
        regradeBoardingPriority: '25',
        concessionDef: {
          _id: 'LTDEFZONECX1',
          displayName: 'CX flight - Zone fare (Standby)',
          concessionName: 'CX Zone Fare',
          advanceBookingDay: { value: 30, unit: 'DYS' },
          fareType: 'ID',
          fareDiscount: 100,
          carrierGroup: 'ZED',
        },
        restriction: { beneficiaryType: ['LTageRESTR1'], sector: [] },
      },
      {
        _id: 'LTRULEZONECX11',
        displayName: 'CX1: Zone: CX Zone (J,W,Y)(Priority 27) - employee, children, companion, family travel nominees',
        group: 'LTZONEGROUP1',
        boardingPriority: '27',
        bookingClass: ['J', 'W', 'Y'],
        regradeClass: '',
        regradeBoardingPriority: '27',
        concessionDef: {
          _id: 'LTDEFZONECX1',
          displayName: 'CX flight - Zone fare (Standby)',
          concessionName: 'CX Zone Fare',
          advanceBookingDay: { value: 30, unit: 'DYS' },
          fareType: 'ZED',
          fareDiscount: 0,
          carrierGroup: 'CX',
        },
        restriction: {
          beneficiaryType: ['LTageRESTR1', 'LTageRESTR2'],
          sector: [],
        },
      },
      {
        _id: 'LTRULEZONECX12',
        displayName: 'oneworld',
        group: 'LTZONEGROUP1',
        boardingPriority: '27',
        bookingClass: ['J', 'W', 'Y'],
        regradeClass: '',
        regradeBoardingPriority: '27',
        concessionDef: {
          _id: 'LTDEFZONECX1',
          displayName: 'CX flight - Zone fare (Standby)',
          concessionName: 'CX Zone Fare',
          advanceBookingDay: { value: 30, unit: 'DYS' },
          fareType: 'ID',
          fareDiscount: 50,
          carrierGroup: 'oneworld',
        },
        restriction: {
          beneficiaryType: ['LTageRESTR1', 'LTageRESTR2', 'LTageRESTR3'],
          sector: [],
        },
      },
      {
        _id: 'LTRULEZONECX11',
        displayName:
          'CX4: ConfirmedFare: CX Zone (J,W,Y)(Priority 27) - employee, children, companion, family travel nominees',
        group: 'LTZONEGROUP1',
        boardingPriority: '27',
        bookingClass: ['J', 'W', 'Y'],
        regradeClass: '',
        regradeBoardingPriority: '27',
        concessionDef: {
          _id: 'LTDEFZONECX1',
          displayName: 'CX flight - Zone fare (Standby)',
          concessionName: 'CX Zone Fare',
          advanceBookingDay: { value: 30, unit: 'DYS' },
          fareType: 'ID',
          fareDiscount: 50,
          carrierGroup: 'CX',
        },
        restriction: {
          beneficiaryType: ['LTageRESTR1', 'LTageRESTR2'],
          sector: [],
        },
      },
      {
        _id: 'LTRULEZONECX11',
        displayName:
          'CX2: ID3: CX Zone (J,W,Y)(Priority 27) - employee, children, companion, family travel nominees zzz',
        group: 'LTZONEGROUP1',
        boardingPriority: '27',
        bookingClass: ['J', 'W', 'Y'],
        regradeClass: '',
        regradeBoardingPriority: '27',
        concessionDef: {
          _id: 'LTDEFZONECX1',
          displayName: 'CX flight - Zone fare (Standby)',
          concessionName: 'CX Zone Fare',
          advanceBookingDay: { value: 30, unit: 'DYS' },
          fareType: 'ID',
          fareDiscount: 3,
          carrierGroup: 'CX',
        },
        restriction: {
          beneficiaryType: ['LTageRESTR1', 'LTageRESTR2'],
          sector: [],
        },
      },
      {
        _id: 'LTRULEZONECX11',
        displayName:
          'CX2: ID3: CX Zone (J,W,Y)(Priority 27) - employee, children, companion, family travel nominees aaa',
        group: 'LTZONEGROUP1',
        boardingPriority: '27',
        bookingClass: ['J', 'W', 'Y'],
        regradeClass: '',
        regradeBoardingPriority: '27',
        concessionDef: {
          _id: 'LTDEFZONECX1',
          displayName: 'CX flight - Zone fare (Standby)',
          concessionName: 'CX Zone Fare',
          advanceBookingDay: { value: 30, unit: 'DYS' },
          fareType: 'ID',
          fareDiscount: 3,
          carrierGroup: 'CX',
        },
        restriction: {
          beneficiaryType: ['LTageRESTR1', 'LTageRESTR2'],
          sector: [],
        },
      },
      {
        _id: 'LTRULEZONECX11',
        displayName: 'CX3: FOC: CX Zone (J,W,Y)(Priority 27) - employee, children, companion, family travel nominees',
        group: 'LTZONEGROUP1',
        boardingPriority: '27',
        bookingClass: ['J', 'W', 'Y'],
        regradeClass: '',
        regradeBoardingPriority: '27',
        concessionDef: {
          _id: 'LTDEFZONECX1',
          displayName: 'CX flight - Zone fare (Standby)',
          concessionName: 'CX Zone Fare',
          advanceBookingDay: { value: 30, unit: 'DYS' },
          fareType: 'ID',
          fareDiscount: 100,
          carrierGroup: 'CX',
        },
        restriction: {
          beneficiaryType: ['LTageRESTR1', 'LTageRESTR2'],
          sector: [],
        },
      },
    ],
    restrictionBeneficiaryType: [
      {
        _id: 'LTageRESTR1',
        bookingClass: 'J',
        beneficiaryType: 'CHD',
        ageFromYear: 0,
        ageToYear: 12,
        classType: 'T',
      },
      {
        _id: 'LTageRESTR2',
        bookingClass: 'J',
        beneficiaryType: 'SIB',
        ageFromYear: 0,
        ageToYear: 12,
        classType: 'T',
      },
      {
        _id: 'LTageRESTR3',
        bookingClass: 'J',
        beneficiaryType: 'FRD',
        ageFromYear: 0,
        ageToYear: 12,
        classType: 'T',
      },
    ],
  },
};

//TEST including paypal, direct debit, prepaid status
const SAMPLE_PAYMENT_HISTORY_LOAD_DATA = [
  {
    _id: '641c4514f0ffbebf5b5ebc41',
    applicationId: 'ELT230000006',
    ticketNumber: '1602384590746',
    actualArrivalPort: 'TPE',
    actualBookingClass: 'Y',
    actualCarrier: 'CX',
    actualDepartureDate: '2023-03-23',
    actualDeparturePort: 'HKG',
    actualFlightNum: '0402',
    bookingParentClass: 'Y',
    concessionName: 'CX Zone (J,W,Y)(Priority 25) - employee, spouse and children',
    currency: 'HKD',
    employeeId: '137852A',
    firstName: 'CHILDSON DEBBIE',
    lastName: 'TESTING',
    //paymentMedium: "DDP",
    paymentMedium: 'PPP',
    paypalStatus: 'FI_SUCCESS',
    status: 'DONE',
    title: 'MSTR',
    total: 448,
    processDateHKT: '2023-03-31',
    type: 'ELT',
  },
  {
    _id: '641c4514f0ffbebf5b5ebc80',
    applicationId: 'ELT230000006',
    ticketNumber: '1602384590748',
    actualArrivalPort: 'TPE',
    actualBookingClass: 'Y',
    actualCarrier: 'CX',
    actualDepartureDate: '2023-03-23',
    actualDeparturePort: 'HKG',
    actualFlightNum: '0402',
    bookingParentClass: 'Y',
    concessionName: 'CX Zone (J,W,Y)(Priority 25) - employee, spouse and children',
    currency: 'HKD',
    employeeId: '137852A',
    firstName: 'DEBORAH DEV',
    lastName: 'TESTING',
    paymentMedium: 'DDP',
    status: 'DONE',
    title: 'MS',
    total: 398,
    processDateHKT: '2023-03-31',
    type: 'ELT',
  },
  {
    _id: '641c4514f0ffbebf5b5ebc81',
    applicationId: 'ELT230000068',
    ticketNumber: '1602384590748',
    actualArrivalPort: 'TPE',
    actualBookingClass: 'Y',
    actualCarrier: 'CX',
    actualDepartureDate: '2023-03-23',
    actualDeparturePort: 'HKG',
    actualFlightNum: '0402',
    bookingParentClass: 'Y',
    concessionName: 'CX Zone (J,W,Y)(Priority 25) - employee, spouse and children',
    currency: 'HKD',
    employeeId: '137852A',
    firstName: 'DEBORAH DEV',
    lastName: 'TESTING',
    paymentMedium: 'CRP',
    prepaidStatus: 'PAID',
    //prepaidStatus: "REQUEST",
    //prepaidStatus: "SUBMITTED",
    status: 'DONE',
    title: 'MS',
    total: 398,
    processDateHKT: '2023-03-31',
    type: 'ELT',
  },
  {
    _id: '641c4514f0ffbebf5b5ebc82',
    applicationId: 'ELT230000069',
    ticketNumber: '1602384590748',
    actualArrivalPort: 'TPE',
    actualBookingClass: 'Y',
    actualCarrier: 'CX',
    actualDepartureDate: '2023-03-23',
    actualDeparturePort: 'HKG',
    actualFlightNum: '0402',
    bookingParentClass: 'Y',
    concessionName: 'CX Zone (J,W,Y)(Priority 25) - employee, spouse and children',
    currency: 'HKD',
    employeeId: '137852A',
    firstName: 'DEBORAH DEV',
    lastName: 'TESTING',
    paymentMedium: 'CRP',
    //prepaidStatus: "PAID",
    prepaidStatus: 'REQUEST',
    //prepaidStatus: "SUBMITTED",
    status: 'DONE',
    title: 'MS',
    total: 398,
    processDateHKT: '2023-03-31',
    type: 'ELT',
  },
  {
    _id: '641c4514f0ffbebf5b5ebc83',
    applicationId: 'ELT230000070',
    ticketNumber: '1602384590748',
    actualArrivalPort: 'TPE',
    actualBookingClass: 'Y',
    actualCarrier: 'CX',
    actualDepartureDate: '2023-03-23',
    actualDeparturePort: 'HKG',
    actualFlightNum: '0402',
    bookingParentClass: 'Y',
    concessionName: 'CX Zone (J,W,Y)(Priority 25) - employee, spouse and children',
    currency: 'HKD',
    employeeId: '137852A',
    firstName: 'DEBORAH DEV',
    lastName: 'TESTING',
    paymentMedium: 'CRP',
    //prepaidStatus: "PAID",
    //prepaidStatus: "REQUEST",
    prepaidStatus: 'SUBMITTED',
    status: 'DONE',
    title: 'MS',
    total: 398,
    processDateHKT: '2023-03-31',
    type: 'ELT',
  },
];

const SAMPLE_ADMIN_MAINTAIN_EMPLOYEE_DATA = {
  cx: 'CX',
  fullName: 'MLY ALEX WORYU',
  employeeId: '123456A',
};
const SAMPLE_EMPLOYEE_PROFILE_DATA = {
  profileDetails: {
    title: 'Ms',
    firstName: 'Mat',
    middleName: 'Anne',
    surname: 'Wong',
    preferredFirstName: '',
    preferredLastName: '',
    gender: 'M',
    dateOfBirth: '11 Nov 2017',
    employeeID: '123456A',
    company: 'CX',
    countryOfResidence: 'HKG',
    maritalStatus: false,
    maritalStatusAsOfDate: '23 Nov 2017',
  },
  employmentDetails: {
    jobLevel: 'B',
    jobTitle: 'Engineer',
    department: 'ENG',
    contractType: 'LMP',
    employmentType: 'POI',
    poiType: '10001',
    officeCode: 'G-GML',
    employmentStatus: true,
    serviceStartDate: '01 Jan 2015',
    serviceEndDate: '',
    travelEndDate: '',
    resignationCode: '',
    employeeCategory: 'Ground staff',
    fullOrPartTime: 'Full-time',
    workingLocation: 'Hong Kong',
    domicilePort: '',
    recruitmentPort: 'Hong Kong',
    countryOfEmployment: 'Hong Kong',
    regularOrTemp: 'Hong Kong',
    homePort: 'Hong Kong',
  },
  paymentDetails: {
    heading: 'Payment Details',
    paymentConcept: 'Flown',
    paymentMedium: 'Direct Debit',
    directDebitIndicator: 'Yes',
    billingAagreementSetup: 'Yes',
    flownSuspensionStartDate: '--',
    flownSuspensionEndDate: '--',
  },
  benefitDetails: {
    heading: 'Benefit Details',
    benefitJobLevelDuty: 'Level B',
    effectiveDate: '26 Apr 2021',
    profileStatus: 'Active',
    profileType: 'Employee',
    age: '22',
    yearOfService: '2 years 0 month 2 days',
    companySeniorityDate: '26 Apr 2021',
  },
  contactDetails: {
    heading: 'Contact Details',
    personalEmail: 'may_wong@gmail.com',
    officeEmail: 'May_wong@cathaypacific.com',
    notificationEmail: '--',
    phoneNumberMobile: '852-91234567',
    phoneNumberOffice: '852-27471234',
    addressDetails: 'Cathay Pacific City, 8 Scenic Road, Hong Kong International Airport, Lantau Island, Hong Kong',
  },
  passportDetails: {
    heading: 'Passport Details',
    passportNumber: 'K01234567',
    firstOtherNameAsInPassport: 'May Anne',
    surnameAsInPassport: 'Wong',
    passportExpiryDate: '25 May 2030',
    nationalityInPassport: 'Hong Kong SAR',
    issuedCountry: 'Hong Kong',
    countryOfResidence: 'Hong Kong',
  },
};

const SAMPLE_ADMIN_NOTES_DATA = [
  {
    createdDate: '2023-04-01',
    lastModifiedDate: '2023-05-25',
    modifiedBy: 'echo1',
    subject: 'echo subject1',
    description: 'Echo note 11111111111111111111111111111111111111111111111111111111111111',
    noteId: 'note1',
  },
  {
    createdDate: '2023-04-01',
    lastModifiedDate: '2023-05-25',
    modifiedBy: 'echo2',
    subject: 'echo subject2',
    description: 'Echo note 22222222222222222222222222222222222222222222222222222222222222',
    noteId: 'note2',
  },
];

const SAMPLE_REVIEW_AND_CONFIRM_DATA = {
  booking: {
    _id: '647f51ce7e0a2af87d8a129d',
    applicationId: 'ELT230000959',
    __v: 0,
    bookingUser: {
      employeeId: '500001A',
      galaCXyId: 'ETP001',
      profileRevision: 1,
      employeeClassification: 'GS',
      department: 'D701700',
      countryOfEmployment: 'HKG',
      workingLocation: 'HKG',
      domicilePort: null,
      jobLevel: 'B',
      benefitDTJobLevel: null,
      benefitLTJobLevel: null,
      profileName: {
        firstName: 'Deborah',
        middleName: 'Dev',
        lastName: 'Testing',
        preferredFirstName: 'Testing',
      },
      passportName: {
        firstName: '',
        lastName: '',
      },
      hiringCompany: 'CX',
      company: 'CPA',
      type: 'EMPLOYEE',
      paymentConcept: 'PRP',
      paymentMedium: 'DDP',
    },
    createdAt: '2023-06-06T15:33:34.886Z',
    createdBy: {
      employeeId: '500001A',
      profileRevision: 1,
      role: 'SELF',
      roleId: 'SELF',
    },
    etpPassengerList: [
      {
        pnrRef: 'PT2',
        pnrFirstName: 'Deborah Dev Ms',
        pnrLastName: 'Testing',
        ageType: 'ADT',
        beneficiaryTypeCode: 'EMP',
        dependentId: null,
        relationship: '',
        relationshipCode: '',
        title: 'MS',
        firstName: 'DEBORAH DEV',
        lastName: 'TESTING',
        infantWithoutSeat: false,
        ptc: 'ZEA',
      },
    ],
    latestSegments: [
      {
        departureDate: '2023-06-07',
        departureTime: '08:00',
        departurePort: 'HKG',
        arrivalDate: '2023-06-07',
        arrivalTime: '09:55',
        arrivalPort: 'TPE',
        carrier: '',
        flightNum: '',
        bookingClass: 'O',
        aircraftType: '77W',
        numOfStops: 0,
        status: 'SA',
        segRefNum: 'ST1',
        ticketNumArr: ['1602384643695'],
      },
    ],
    leisureTravel: {
      passengerList: [
        {
          pnrRef: 'PT2',
          ageType: 'ADT',
          beneficiaryTypeCode: 'EMP',
          dependentId: null,
          relationship: null,
          relationshipCode: null,
          title: 'MS',
          firstName: 'DEBORAH DEV',
          lastName: 'TESTING',
          infantWithoutSeat: false,
        },
      ],
    },
    officeInfo: {
      officeId: 'HKGCX0334',
      currencyCode: 'HKD',
      paymentType: 'FWN',
      paypalPortCode: 'HKG',
    },
    pendingStatus: 'DONE',
    recLoc: '',
    status: 'NOT_REFUND',
    type: 'ELT',
    updatedAt: '2023-06-06T15:34:06.083Z',
    updatedBy: {
      employeeId: '500001A',
      profileRevision: 1,
      role: 'SELF',
      roleId: 'SELF',
    },
    contactInformation: {
      phone: '852-9998214',
      email: 'DL_IT_ETP@CATHAYPACIFIC.COM',
      altEmail: null,
    },
    concession: {
      id: 'LTRULEZONECX10',
      name: 'Priority 25 - employee / spouse / children only',
    },
    ticketsBySegment: [
      {
        departureDate: '',
        departureTime: '08:00',
        departurePort: 'HKG',
        arrivalDate: '',
        arrivalTime: '09:55',
        arrivalPort: 'TPE',
        carrier: '',
        flightNum: '',
        bookingParentClass: 'Y',
        bookingClass: 'O',
        aircraftType: '77W',
        numOfStops: 0,
        status: '',
        segRefNum: 'ST1',
        ticketNumArr: ['1602384643695'],
        overrideStatus: '',
        tickets: [
          {
            latestTicket: {
              ticketNum: '1602384643695',
              segRefNum: 'ST1',
              paxRefNum: 'PT2',
              fare: {
                totalAmount: 345,
                totalTax: 265,
                baseFare: 80,
                bsrRate: 7.83205222,
                grossFare: 80,
                currency: 'HKD',
                baseFareCurrency: 'USD',
                fareBasis: 'YIDZS2R2/ZEA',
                fcString: 'HKG CX TPE9.00NUC9.00END ROE1.000000',
                tax: [
                  {
                    rate: 0,
                    type: 'YR',
                  },
                  {
                    rate: 90,
                    currencyCode: 'HKD',
                    type: 'G3',
                  },
                  {
                    rate: 120,
                    currencyCode: 'HKD',
                    type: 'HK',
                  },
                  {
                    rate: 55,
                    currencyCode: 'HKD',
                    type: 'I5',
                  },
                ],
                taxFilters: [],
              },
              baggage: [
                {
                  quantity: 2,
                  unit: 'PC',
                },
              ],
              status: '',
              ticketValidity: {
                type: 'A',
                date: '2023-09-05',
              },
              cpnNumber: '1',
              ticketSegObj: {
                departureDate: '',
                departureTime: '08:00',
                departurePort: 'HKG',
                arrivalDate: '2023-06-07',
                arrivalTime: '09:55',
                arrivalPort: 'TPE',
                carrier: '',
                flightNum: '',
                bookingClass: 'O',
                ticketNumArr: [],
              },
              segObj: {
                departureDate: '',
                departureTime: '08:00',
                departurePort: 'HKG',
                arrivalDate: '',
                arrivalTime: '',
                arrivalPort: 'TPE',
                carrier: '',
                flightNum: '',
                bookingParentClass: 'Y',
                bookingClass: 'O',
                aircraftType: '77W',
                numOfStops: 0,
                status: '',
                segRefNum: 'ST1',
                ticketNumArr: ['1602384643695'],
                overrideStatus: '',
              },
              paxObj: {
                paxRefNum: 'PT2',
                lastName: 'TESTING',
                firstName: 'DEBORAH DEV MS',
                type: 'ADT',
                ticketPaxType: 'A',
                countryOfResidential: 'HKG',
                title: 'MS',
                beneficiaryTypeCode: 'EMP',
                dependentId: null,
                ptc: 'ZEA',
                ticketNum: '1602384643695',
              },
              originatorId: '13391302',
              tourCode: '',
              sourcePort: 'HKG',
              sourceCountry: 'HK',
              endorsement: 'SUBLO 25Y/Y25 26MAR20 CX 500001A VALID ON CX ONLY',
              issueDate: '2023-06-06',
              fop: [
                {
                  type: 'MS',
                  amount: 345,
                  indicator: '3',
                  fopFreeText: 'SD',
                },
                {
                  type: 'MS',
                  amount: 0,
                  indicator: '3',
                  fopFreeText: 'CV*ELT230000959',
                },
              ],
              updatedAt: '2023-06-06 23:33',
              createdAt: '2023-06-06T15:33:51.064Z',
              overrideStatus: '',
            },
          },
        ],
      },
    ],
  },
  isAllowRebook: true,
  isAllowRefund: true,
  isAllowCancel: false,
  isFromAmadeus: false,
};

const SAMPLE_ADMIN_DEPENDENT_DATA = [
  {
    dependentId: 'A1234',
    name: 'CHAN TAI MAN',
    relationship: 'Son',
    age: 22,
    nominationFrom: '25 Feb 2020',
    nominationTo: '25 Feb 2030',
    nominationStatus: 'Current Nominee',
    profileDetails: {
      dependentType: 'CHD',
      title: 'MR',
      firstName: 'TAI MAN',
      middleName: '',
      surname: 'CHAN',
      gender: 'M',
      relationship: 'Son',
      dateOfBirth: '1 Jan 2001',
      validFrom: '1 Feb 2019',
      validTo: '25 May 2024',
      dependentStatus: true,
    },
    passportDetails: {
      passportNumber: 'K01234567',
      firstOtherNameAsInPassport: 'May Anne',
      surnameAsInPassport: 'Wong',
      passportExpiryDate: '25 May 2030',
      nationalityInPassport: 'Hong Kong SAR',
      issuedCountry: 'Hong Kong',
    },
  },
  {
    dependentId: 'A1245',
    name: 'CHAN SIU MAN',
    relationship: 'Son',
    age: 5,
    nominationFrom: '25 Mar 2020',
    nominationTo: '25 Mar 2030',
    nominationStatus: 'Current Nominee',
    profileDetails: {
      dependentType: 'CHD',
      title: 'MR',
      firstName: 'TAI MAN',
      middleName: '',
      surname: 'CHAN',
      relationship: 'Son',
      gender: 'M',
      dateOfBirth: '1 Jan 2018',
      validFrom: '1 Feb 2021',
      validTo: '25 May 2024',
      dependentStatus: true,
    },
    passportDetails: {
      passportNumber: 'K01234567',
      firstOtherNameAsInPassport: 'May Anne',
      surnameAsInPassport: 'Wong',
      passportExpiryDate: '25 May 2030',
      nationalityInPassport: 'Hong Kong SAR',
      issuedCountry: 'Hong Kong',
    },
  },
];

const SAMPLE_USER_FLOWN_SUSPENSION_DATA = {
  startDate: '2023-03-07',
  endDate: '2023-09-07',
};
const SAMPLE_USER_PROFILE_DATA = {
  employeeId: '117831A',
  action: 'PAY',
  actionReason: 'AUI',
  benefitDTJobLevel: {
    from: null,
    jobLevel: null,
    to: null,
  },
  benefitLTJobLevel: {
    from: null,
    jobLevel: null,
    to: null,
  },
  businessUnit: 'GB001',
  company: 'CPA',
  companySeniorityDate: '2011-10-06',
  contractBeginDate: '2016-01-01',
  contractEndDate: null,
  contractExpEndDate: null,
  contractTemplate: 'PERM D',
  contractType: 'LPM',
  costCentre: '100LHR3293',
  delegatee: null,
  department: 'D630000',
  domicilePort: null,
  employeeClassification: 'GS',
  employeeRecordNumber: '0',
  employmentType: 'EMP',
  event: [
    {
      type: 'NEW_HIRE',
      path: 'Travel Hub Profile - Not exist > New Hire',
      createdAt: '2023-04-17T13:17:17.214Z',
    },
    {
      type: 'SET_DATA',
      remark:
        'hiringCompany: undefined > CX, isHouseStaff: undefined > false, travelBenefitScheme: undefined > CNS, paymentConcept: undefined > FWN, paymentMedium: undefined > PPP',
      createdAt: '2023-04-17T13:17:17.214Z',
    },
    {
      type: 'DATA_PATCH',
      remark: 'Data patch hiringCompany, paymentConcept, paymentMedium, isHouseStaff and travelBenefitScheme',
      createdAt: '2023-05-16T07:58:07.241Z',
    },
    {
      type: 'DATA_PATCH',
      remark: 'Data patch hiringCompany, paymentConcept, paymentMedium, isHouseStaff and travelBenefitScheme',
      createdAt: '2023-06-01T04:21:43.849Z',
    },
    {
      type: 'DATA_PATCH',
      remark: 'Data patch hiringCompany, paymentConcept, paymentMedium, isHouseStaff and travelBenefitScheme',
      createdAt: '2023-06-01T07:43:27.175Z',
    },
  ],
  fullPartTime: 'F',
  galaCXyId: 'LONSCM',
  highLevelOfDepartment: 'PPL',
  hireDate: '2011-10-06',
  hiringCompany: 'CX',
  homePort: null,
  hrStatus: 'A',
  isActive: true,
  isHouseStaff: false,
  isResigneeBasing: false,
  isTestingProfile: false,
  jobLevel: 'D',
  jobTitle: 'REGIONAL HEAD OF PEOPLE EUROPE',
  lastHireDate: '2016-01-01',
  lastTerminationActionReason: 'TSP',
  lastTerminationDate: '2015-12-30',
  location: 'LON',
  manualDelegatee: [],
  officerCode: 'N',
  pHubDelegatee: [],
  payGroup: 'CX-GBR',
  paymentConcept: 'FWN',
  paymentMedium: 'PPP',
  personOfInterestType: null,
  probationDate: '2013-10-19',
  recruitmentPort: null,
  regularShift: 'N',
  regularTemporary: 'R',
  regulatoryRegion: 'GBR',
  reportEmployeeId: '101580D',
  retireeBadgeEffectiveDate: null,
  retireeBadgeExpirationDate: null,
  retireeBadgeNumber: null,
  secondmentEndDate: null,
  secondmentStartDate: null,
  secondmentToDepartment: null,
  secondmentToHighLevelOfDepartment: null,
  suspendPeopleHubData: false,
  terminationActionReason: null,
  terminationDate: null,
  travelBenefitScheme: 'CNS',
  type: 'EMPLOYEE',
  activeBenefitDTJobLevel: 'D',
  activeBenefitLTJobLevel: 'D',
  paymentValidated: false,
  etpFlownSuspensionStartDate: '2023-05-16',
  etpFlownSuspensionEndDate: '2023-12-31',
  isShowFlownSuspensionMessage: true,
  isShowFlownSuspensionAlert: false,
};

const SAMPLE_CANCELLED_PREPAID_DATA = {
  booking: {
    _id: '64bd52f118674c59f5ee506a',
    applicationId: 'ELT230001074',
    __v: 0,
    bookingUser: {
      employeeId: '128215J',
      galaCXyId: 'EXORSL',
      profileRevision: 1,
      employeeClassification: 'GS',
      department: 'D228800',
      countryOfEmployment: 'HKG',
      workingLocation: 'HKG',
      domicilePort: null,
      jobLevel: 'Z',
      benefitDTJobLevel: null,
      benefitLTJobLevel: null,
      profileName: {
        firstName: 'Mhxdld',
        middleName: 'Lwk Yhm',
        lastName: 'Ldm',
        preferredFirstName: 'Testing',
      },
      passportName: { firstName: 'Lwk Yhm Mhxdld', lastName: 'Ldm' },
      hiringCompany: 'CX',
      company: 'CPA',
      type: 'EMPLOYEE',
      paymentConcept: 'PRP',
      paymentMedium: 'CRD',
    },
    createdAt: '2023-07-23T16:18:57.580Z',
    createdBy: {
      employeeId: '128215J',
      profileRevision: 1,
      role: 'SELF',
      roleId: 'SELF',
    },
    etpPassengerList: [
      {
        pnrRef: 'PT2',
        pnrFirstName: 'Lwk Yhm Mhxdld Mr',
        pnrLastName: 'Ldm',
        ageType: 'ADT',
        beneficiaryTypeCode: 'EMP',
        dependentId: null,
        relationship: '',
        relationshipCode: '',
        title: 'MR',
        firstName: 'LWK YHM MHXDLD',
        lastName: 'LDM',
        infantWithoutSeat: false,
        ptc: 'ZEA',
        countryOfResidential: 'HKG',
        passportInput: {
          issuingCountry: 'HKG',
          passportNumber: 'H100129614',
          passportName: 'Ldm,Lwk Yhm Mhxdld',
          nationality: 'CHN',
          dateOfBirth: '1972-10-02',
          gender: 'M',
          expiryDate: '2031-04-23',
        },
        isAllowRemove: true,
      },
    ],
    latestSegments: [
      {
        departureDate: '2023-08-01',
        departureTime: '08:00',
        departurePort: 'HKG',
        arrivalDate: '2023-08-01',
        arrivalTime: '09:55',
        arrivalPort: 'TPE',
        carrier: 'CX',
        flightNum: '488',
        bookingClass: 'O',
        aircraftType: '333',
        numOfStops: 0,
        status: 'SA',
        segRefNum: 'ST1',
        ticketNumArr: ['1602387290867'],
      },
    ],
    leisureTravel: {
      passengerList: [
        {
          pnrRef: 'PT2',
          ageType: 'ADT',
          beneficiaryTypeCode: 'EMP',
          dependentId: null,
          relationship: null,
          relationshipCode: null,
          title: 'MR',
          firstName: 'LWK YHM MHXDLD',
          lastName: 'LDM',
          infantWithoutSeat: false,
        },
      ],
    },
    officeInfo: {
      officeId: 'HKGCX0334',
      currencyCode: 'HKD',
      paymentType: 'FWN',
      paypalPortCode: 'HKG',
    },
    paymentConcept: 'PRP',
    paymentMedium: 'CRD',
    prepaidStatus: 'NOT_REFUND',
    pendingStatus: 'DONE',
    recLoc: '',
    status: 'CANCELLED',
    type: 'ELT',
    updatedAt: '2023-07-23T17:29:25.875Z',
    updatedBy: { role: 'SYSTEM', roleId: 'SYSTEM' },
    contactInformation: {
      phone: '842-62824226',
      email: 'DL_IT_ETP@CATHAYPACIFIC.COM',
      altEmail: null,
    },
    concession: {
      id: 'LTRULEZONECX34',
      name: 'T1 Priority 25 - Employee, spouse and/or children under age 24 (if applicable)',
    },
    ticketsBySegment: [
      {
        departureDate: '2023-08-01',
        departureTime: '08:00',
        departurePort: 'HKG',
        arrivalDate: '2023-08-01',
        arrivalTime: '09:55',
        arrivalPort: 'TPE',
        carrier: 'CX',
        flightNum: '488',
        bookingParentClass: 'Y',
        bookingClass: 'O',
        aircraftType: '333',
        numOfStops: 0,
        status: 'SA',
        segRefNum: 'ST1',
        ticketNumArr: ['1602387290867'],
        tickets: [
          {
            latestTicket: {
              ticketNum: '1602387290867',
              segRefNum: 'ST1',
              paxRefNum: 'PT2',
              fare: {
                totalAmount: 345,
                totalTax: 265,
                baseFare: 80,
                bsrRate: 7.81377523,
                grossFare: 80,
                currency: 'HKD',
                baseFareCurrency: 'USD',
                fareBasis: 'YIDZS2R2/ZEA',
                fcString: 'HKG CX TPE9.00NUC9.00END ROE1.000000',
                tax: [
                  { rate: 0, type: 'YR' },
                  { rate: 90, currencyCode: 'HKD', type: 'G3' },
                  { rate: 120, currencyCode: 'HKD', type: 'HK' },
                  { rate: 55, currencyCode: 'HKD', type: 'I5' },
                ],
                taxFilters: [],
              },
              baggage: [{ quantity: 2, unit: 'PC' }],
              status: '',
              ticketValidity: { type: 'A', date: '2023-10-22' },
              cpnNumber: '1',
              ticketSegObj: {
                departureDate: '2023-08-01',
                departureTime: '08:00',
                departurePort: 'HKG',
                arrivalDate: '2023-08-01',
                arrivalTime: '09:55',
                arrivalPort: 'TPE',
                carrier: 'CX',
                flightNum: '488',
                bookingClass: 'O',
                ticketNumArr: [],
              },
              segObj: {
                departureDate: '2023-08-01',
                departureTime: '08:00',
                departurePort: 'HKG',
                arrivalDate: '2023-08-01',
                arrivalTime: '09:55',
                arrivalPort: 'TPE',
                carrier: 'CX',
                flightNum: '488',
                bookingParentClass: 'Y',
                bookingClass: 'O',
                aircraftType: '333',
                numOfStops: 0,
                status: 'SA',
                segRefNum: 'ST1',
                ticketNumArr: ['1602387290867'],
              },
              paxObj: {
                paxRefNum: 'PT2',
                lastName: 'LDM',
                firstName: 'LWK YHM MHXDLD MR',
                type: 'ADT',
                ticketPaxType: 'A',
                countryOfResidential: 'HKG',
                passport: {
                  issuingCountry: 'HKG',
                  passportNumber: 'H100129614',
                  nationality: 'CHN',
                  dateOfBirth: '02OCT72',
                  gender: 'M',
                  expiryDate: '23APR31',
                  lastName: 'LDM',
                  firstName: 'LWK YHM MHXDLD',
                },
                title: 'MR',
                beneficiaryTypeCode: 'EMP',
                dependentId: null,
                ptc: 'ZEA',
                ticketNum: '1602387290867',
              },
              originatorId: '13391302',
              tourCode: '',
              sourcePort: 'HKG',
              sourceCountry: 'HK',
              endorsement: 'SUBLO 25Y/Y25 02SEP96 CX 128215J VALID ON CX ONLY',
              issueDate: '2023-07-24',
              fop: [
                {
                  type: 'MS',
                  amount: 345,
                  indicator: '3',
                  fopFreeText: 'DEBIT*HKG2020110016',
                },
                {
                  type: 'MS',
                  amount: 0,
                  indicator: '3',
                  fopFreeText: 'CV*ELT230001074',
                },
              ],
              createdAt: '2023-07-23T17:29:25.875Z',
              updatedAt: '2023-07-24 01:29',
            },
          },
        ],
      },
    ],
  },
  bookingEligibility: {
    CANCEL_BOOKING: false,
    REMOVE_PAX: false,
    REBOOK: true,
    REFUND: true,
    RETRIEVE_AND_PAY: false,
  },
  isCheckedIn: false,
  isFromAmadeus: true,
};

const SAMPLE_NOT_CANCELLED_PREPAID_DATA = {
  booking: {
    _id: '64bd52f118674c59f5ee506a',
    applicationId: 'ELT230001074',
    __v: 0,
    bookingUser: {
      employeeId: '128215J',
      galaCXyId: 'EXORSL',
      profileRevision: 1,
      employeeClassification: 'GS',
      department: 'D228800',
      countryOfEmployment: 'HKG',
      workingLocation: 'HKG',
      domicilePort: null,
      jobLevel: 'Z',
      benefitDTJobLevel: null,
      benefitLTJobLevel: null,
      profileName: {
        firstName: 'Mhxdld',
        middleName: 'Lwk Yhm',
        lastName: 'Ldm',
        preferredFirstName: 'Testing',
      },
      passportName: { firstName: 'Lwk Yhm Mhxdld', lastName: 'Ldm' },
      hiringCompany: 'CX',
      company: 'CPA',
      type: 'EMPLOYEE',
      paymentConcept: 'PRP',
      paymentMedium: 'CRD',
    },
    createdAt: '2023-07-23T16:18:57.580Z',
    createdBy: {
      employeeId: '128215J',
      profileRevision: 1,
      role: 'SELF',
      roleId: 'SELF',
    },
    etpPassengerList: [
      {
        pnrRef: 'PT2',
        pnrFirstName: 'Lwk Yhm Mhxdld Mr',
        pnrLastName: 'Ldm',
        ageType: 'ADT',
        beneficiaryTypeCode: 'EMP',
        dependentId: null,
        relationship: '',
        relationshipCode: '',
        title: 'MR',
        firstName: 'LWK YHM MHXDLD',
        lastName: 'LDM',
        infantWithoutSeat: false,
        ptc: 'ZEA',
        countryOfResidential: 'HKG',
        passportInput: {
          issuingCountry: 'HKG',
          passportNumber: 'H100129614',
          passportName: 'Ldm,Lwk Yhm Mhxdld',
          nationality: 'CHN',
          dateOfBirth: '1972-10-02',
          gender: 'M',
          expiryDate: '2031-04-23',
        },
        isAllowRemove: true,
      },
    ],
    latestSegments: [
      {
        departureDate: '2023-08-01',
        departureTime: '08:00',
        departurePort: 'HKG',
        arrivalDate: '2023-08-01',
        arrivalTime: '09:55',
        arrivalPort: 'TPE',
        carrier: 'CX',
        flightNum: '488',
        bookingClass: 'O',
        aircraftType: '333',
        numOfStops: 0,
        status: 'SA',
        segRefNum: 'ST1',
        ticketNumArr: ['1602387290867'],
      },
    ],
    leisureTravel: {
      passengerList: [
        {
          pnrRef: 'PT2',
          ageType: 'ADT',
          beneficiaryTypeCode: 'EMP',
          dependentId: null,
          relationship: null,
          relationshipCode: null,
          title: 'MR',
          firstName: 'LWK YHM MHXDLD',
          lastName: 'LDM',
          infantWithoutSeat: false,
        },
      ],
    },
    officeInfo: {
      officeId: 'HKGCX0334',
      currencyCode: 'HKD',
      paymentType: 'FWN',
      paypalPortCode: 'HKG',
    },
    paymentConcept: 'PRP',
    paymentMedium: 'CRD',
    pendingStatus: 'DONE',
    prepaidStatus: 'PAID',
    recLoc: '5J3QT8',
    status: 'BOOKED',
    type: 'ELT',
    updatedAt: '2023-07-23T17:29:25.875Z',
    updatedBy: { role: 'SYSTEM', roleId: 'SYSTEM' },
    contactInformation: {
      phone: '842-62824226',
      email: 'DL_IT_ETP@CATHAYPACIFIC.COM',
      altEmail: null,
    },
    concession: {
      id: 'LTRULEZONECX34',
      name: 'T1 Priority 25 - Employee, spouse and/or children under age 24 (if applicable)',
    },
    ticketsBySegment: [
      {
        departureDate: '2023-08-01',
        departureTime: '08:00',
        departurePort: 'HKG',
        arrivalDate: '2023-08-01',
        arrivalTime: '09:55',
        arrivalPort: 'TPE',
        carrier: 'CX',
        flightNum: '488',
        bookingParentClass: 'Y',
        bookingClass: 'O',
        aircraftType: '333',
        numOfStops: 0,
        status: 'SA',
        segRefNum: 'ST1',
        ticketNumArr: ['1602387290867'],
        tickets: [
          {
            latestTicket: {
              ticketNum: '1602387290867',
              segRefNum: 'ST1',
              paxRefNum: 'PT2',
              fare: {
                totalAmount: 345,
                totalTax: 265,
                baseFare: 80,
                bsrRate: 7.81377523,
                grossFare: 80,
                currency: 'HKD',
                baseFareCurrency: 'USD',
                fareBasis: 'YIDZS2R2/ZEA',
                fcString: 'HKG CX TPE9.00NUC9.00END ROE1.000000',
                tax: [
                  { rate: 0, type: 'YR' },
                  { rate: 90, currencyCode: 'HKD', type: 'G3' },
                  { rate: 120, currencyCode: 'HKD', type: 'HK' },
                  { rate: 55, currencyCode: 'HKD', type: 'I5' },
                ],
                taxFilters: [],
              },
              baggage: [{ quantity: 2, unit: 'PC' }],
              status: 'I',
              ticketValidity: { type: 'A', date: '2023-10-22' },
              cpnNumber: '1',
              ticketSegObj: {
                departureDate: '2023-08-01',
                departureTime: '08:00',
                departurePort: 'HKG',
                arrivalDate: '2023-08-01',
                arrivalTime: '09:55',
                arrivalPort: 'TPE',
                carrier: 'CX',
                flightNum: '488',
                bookingClass: 'O',
                ticketNumArr: [],
              },
              segObj: {
                departureDate: '2023-08-01',
                departureTime: '08:00',
                departurePort: 'HKG',
                arrivalDate: '2023-08-01',
                arrivalTime: '09:55',
                arrivalPort: 'TPE',
                carrier: 'CX',
                flightNum: '488',
                bookingParentClass: 'Y',
                bookingClass: 'O',
                aircraftType: '333',
                numOfStops: 0,
                status: 'SA',
                segRefNum: 'ST1',
                ticketNumArr: ['1602387290867'],
              },
              paxObj: {
                paxRefNum: 'PT2',
                lastName: 'LDM',
                firstName: 'LWK YHM MHXDLD MR',
                type: 'ADT',
                ticketPaxType: 'A',
                countryOfResidential: 'HKG',
                passport: {
                  issuingCountry: 'HKG',
                  passportNumber: 'H100129614',
                  nationality: 'CHN',
                  dateOfBirth: '02OCT72',
                  gender: 'M',
                  expiryDate: '23APR31',
                  lastName: 'LDM',
                  firstName: 'LWK YHM MHXDLD',
                },
                title: 'MR',
                beneficiaryTypeCode: 'EMP',
                dependentId: null,
                ptc: 'ZEA',
                ticketNum: '1602387290867',
              },
              originatorId: '13391302',
              tourCode: '',
              sourcePort: 'HKG',
              sourceCountry: 'HK',
              endorsement: 'SUBLO 25Y/Y25 02SEP96 CX 128215J VALID ON CX ONLY',
              issueDate: '2023-07-24',
              fop: [
                {
                  type: 'MS',
                  amount: 345,
                  indicator: '3',
                  fopFreeText: 'DEBIT*HKG2020110016',
                },
                {
                  type: 'MS',
                  amount: 0,
                  indicator: '3',
                  fopFreeText: 'CV*ELT230001074',
                },
              ],
              createdAt: '2023-07-23T17:29:25.875Z',
              updatedAt: '2023-07-24 01:29',
            },
          },
        ],
      },
    ],
  },
  bookingEligibility: {
    CANCEL_BOOKING: true,
    REMOVE_PAX: false,
    REBOOK: true,
    REFUND: true,
    RETRIEVE_AND_PAY: false,
  },
  isCheckedIn: false,
  isFromAmadeus: true,
};

const SAMPLE_NOT_REFUND_DATA = {
  booking: {
    _id: '64bd52f118674c59f5ee506a',
    applicationId: 'ELT230001074',
    __v: 0,
    bookingUser: {
      employeeId: '128215J',
      galaCXyId: 'EXORSL',
      profileRevision: 1,
      employeeClassification: 'GS',
      department: 'D228800',
      countryOfEmployment: 'HKG',
      workingLocation: 'HKG',
      domicilePort: null,
      jobLevel: 'Z',
      benefitDTJobLevel: null,
      benefitLTJobLevel: null,
      profileName: {
        firstName: 'Mhxdld',
        middleName: 'Lwk Yhm',
        lastName: 'Ldm',
        preferredFirstName: 'Testing',
      },
      passportName: { firstName: 'Lwk Yhm Mhxdld', lastName: 'Ldm' },
      hiringCompany: 'CX',
      company: 'CPA',
      type: 'EMPLOYEE',
      paymentConcept: 'PRP',
      paymentMedium: 'CRD',
    },
    createdAt: '2023-07-23T16:18:57.580Z',
    createdBy: {
      employeeId: '128215J',
      profileRevision: 1,
      role: 'SELF',
      roleId: 'SELF',
    },
    etpPassengerList: [
      {
        pnrRef: 'PT2',
        pnrFirstName: 'Lwk Yhm Mhxdld Mr',
        pnrLastName: 'Ldm',
        ageType: 'ADT',
        beneficiaryTypeCode: 'EMP',
        dependentId: null,
        relationship: '',
        relationshipCode: '',
        title: 'MR',
        firstName: 'LWK YHM MHXDLD',
        lastName: 'LDM',
        infantWithoutSeat: false,
        ptc: 'ZEA',
        countryOfResidential: 'HKG',
        passportInput: {
          issuingCountry: 'HKG',
          passportNumber: 'H100129614',
          passportName: 'Ldm,Lwk Yhm Mhxdld',
          nationality: 'CHN',
          dateOfBirth: '1972-10-02',
          gender: 'M',
          expiryDate: '2031-04-23',
        },
        isAllowRemove: true,
      },
    ],
    latestSegments: [
      {
        departureDate: '2023-08-01',
        departureTime: '08:00',
        departurePort: 'HKG',
        arrivalDate: '2023-08-01',
        arrivalTime: '09:55',
        arrivalPort: 'TPE',
        carrier: 'CX',
        flightNum: '488',
        bookingClass: 'O',
        aircraftType: '333',
        numOfStops: 0,
        status: 'SA',
        segRefNum: 'ST1',
        ticketNumArr: ['1602387290867'],
      },
    ],
    leisureTravel: {
      passengerList: [
        {
          pnrRef: 'PT2',
          ageType: 'ADT',
          beneficiaryTypeCode: 'EMP',
          dependentId: null,
          relationship: null,
          relationshipCode: null,
          title: 'MR',
          firstName: 'LWK YHM MHXDLD',
          lastName: 'LDM',
          infantWithoutSeat: false,
        },
      ],
    },
    officeInfo: {
      officeId: 'HKGCX0334',
      currencyCode: 'HKD',
      paymentType: 'FWN',
      paypalPortCode: 'HKG',
    },
    paymentConcept: 'PRP',
    paymentMedium: 'CRD',
    pendingStatus: 'DONE',
    prepaidStatus: 'NOT_REFUND',
    recLoc: '',
    status: 'CANCELLED',
    type: 'ELT',
    updatedAt: '2023-07-23T17:29:25.875Z',
    updatedBy: { role: 'SYSTEM', roleId: 'SYSTEM' },
    contactInformation: {
      phone: '842-62824226',
      email: 'DL_IT_ETP@CATHAYPACIFIC.COM',
      altEmail: null,
    },
    concession: {
      id: 'LTRULEZONECX34',
      name: 'T1 Priority 25 - Employee, spouse and/or children under age 24 (if applicable)',
    },
    ticketsBySegment: [
      {
        departureDate: '2023-08-01',
        departureTime: '08:00',
        departurePort: 'HKG',
        arrivalDate: '2023-08-01',
        arrivalTime: '09:55',
        arrivalPort: 'TPE',
        carrier: 'CX',
        flightNum: '488',
        bookingParentClass: 'Y',
        bookingClass: 'O',
        aircraftType: '333',
        numOfStops: 0,
        status: 'SA',
        segRefNum: 'ST1',
        ticketNumArr: ['1602387290867'],
        tickets: [
          {
            latestTicket: {
              ticketNum: '1602387290867',
              segRefNum: 'ST1',
              paxRefNum: 'PT2',
              fare: {
                totalAmount: 345,
                totalTax: 265,
                baseFare: 80,
                bsrRate: 7.81377523,
                grossFare: 80,
                currency: 'HKD',
                baseFareCurrency: 'USD',
                fareBasis: 'YIDZS2R2/ZEA',
                fcString: 'HKG CX TPE9.00NUC9.00END ROE1.000000',
                tax: [
                  { rate: 0, type: 'YR' },
                  { rate: 90, currencyCode: 'HKD', type: 'G3' },
                  { rate: 120, currencyCode: 'HKD', type: 'HK' },
                  { rate: 55, currencyCode: 'HKD', type: 'I5' },
                ],
                taxFilters: [],
              },
              baggage: [{ quantity: 2, unit: 'PC' }],
              status: '',
              ticketValidity: { type: 'A', date: '2023-10-22' },
              cpnNumber: '1',
              ticketSegObj: {
                departureDate: '2023-08-01',
                departureTime: '08:00',
                departurePort: 'HKG',
                arrivalDate: '2023-08-01',
                arrivalTime: '09:55',
                arrivalPort: 'TPE',
                carrier: 'CX',
                flightNum: '488',
                bookingClass: 'O',
                ticketNumArr: [],
              },
              segObj: {
                departureDate: '2023-08-01',
                departureTime: '08:00',
                departurePort: 'HKG',
                arrivalDate: '2023-08-01',
                arrivalTime: '09:55',
                arrivalPort: 'TPE',
                carrier: 'CX',
                flightNum: '488',
                bookingParentClass: 'Y',
                bookingClass: 'O',
                aircraftType: '333',
                numOfStops: 0,
                status: 'SA',
                segRefNum: 'ST1',
                ticketNumArr: ['1602387290867'],
              },
              paxObj: {
                paxRefNum: 'PT2',
                lastName: 'LDM',
                firstName: 'LWK YHM MHXDLD MR',
                type: 'ADT',
                ticketPaxType: 'A',
                countryOfResidential: 'HKG',
                passport: {
                  issuingCountry: 'HKG',
                  passportNumber: 'H100129614',
                  nationality: 'CHN',
                  dateOfBirth: '02OCT72',
                  gender: 'M',
                  expiryDate: '23APR31',
                  lastName: 'LDM',
                  firstName: 'LWK YHM MHXDLD',
                },
                title: 'MR',
                beneficiaryTypeCode: 'EMP',
                dependentId: null,
                ptc: 'ZEA',
                ticketNum: '1602387290867',
              },
              originatorId: '13391302',
              tourCode: '',
              sourcePort: 'HKG',
              sourceCountry: 'HK',
              endorsement: 'SUBLO 25Y/Y25 02SEP96 CX 128215J VALID ON CX ONLY',
              issueDate: '2023-07-24',
              fop: [
                {
                  type: 'MS',
                  amount: 345,
                  indicator: '3',
                  fopFreeText: 'DEBIT*HKG2020110016',
                },
                {
                  type: 'MS',
                  amount: 0,
                  indicator: '3',
                  fopFreeText: 'CV*ELT230001074',
                },
              ],
              createdAt: '2023-07-23T17:29:25.875Z',
              updatedAt: '2023-07-24 01:29',
            },
          },
        ],
      },
    ],
  },
  bookingEligibility: {
    CANCEL_BOOKING: false,
    REMOVE_PAX: false,
    REBOOK: false,
    REFUND: false,
    RETRIEVE_AND_PAY: false,
  },
  isCheckedIn: false,
  isFromAmadeus: true,
};

const SAMPLE_REFUNDED_DATA = {
  booking: {
    _id: '64bd52f118674c59f5ee506a',
    applicationId: 'ELT230001074',
    __v: 0,
    bookingUser: {
      employeeId: '128215J',
      galaCXyId: 'EXORSL',
      profileRevision: 1,
      employeeClassification: 'GS',
      department: 'D228800',
      countryOfEmployment: 'HKG',
      workingLocation: 'HKG',
      domicilePort: null,
      jobLevel: 'Z',
      benefitDTJobLevel: null,
      benefitLTJobLevel: null,
      profileName: {
        firstName: 'Mhxdld',
        middleName: 'Lwk Yhm',
        lastName: 'Ldm',
        preferredFirstName: 'Testing',
      },
      passportName: { firstName: 'Lwk Yhm Mhxdld', lastName: 'Ldm' },
      hiringCompany: 'CX',
      company: 'CPA',
      type: 'EMPLOYEE',
      paymentConcept: 'PRP',
      paymentMedium: 'CRD',
    },
    createdAt: '2023-07-23T16:18:57.580Z',
    createdBy: {
      employeeId: '128215J',
      profileRevision: 1,
      role: 'SELF',
      roleId: 'SELF',
    },
    etpPassengerList: [
      {
        pnrRef: 'PT2',
        pnrFirstName: 'Lwk Yhm Mhxdld Mr',
        pnrLastName: 'Ldm',
        ageType: 'ADT',
        beneficiaryTypeCode: 'EMP',
        dependentId: null,
        relationship: '',
        relationshipCode: '',
        title: 'MR',
        firstName: 'LWK YHM MHXDLD',
        lastName: 'LDM',
        infantWithoutSeat: false,
        ptc: 'ZEA',
        countryOfResidential: 'HKG',
        passportInput: {
          issuingCountry: 'HKG',
          passportNumber: 'H100129614',
          passportName: 'Ldm,Lwk Yhm Mhxdld',
          nationality: 'CHN',
          dateOfBirth: '1972-10-02',
          gender: 'M',
          expiryDate: '2031-04-23',
        },
        isAllowRemove: true,
      },
    ],
    latestSegments: [
      {
        departureDate: '2023-08-01',
        departureTime: '08:00',
        departurePort: 'HKG',
        arrivalDate: '2023-08-01',
        arrivalTime: '09:55',
        arrivalPort: 'TPE',
        carrier: 'CX',
        flightNum: '488',
        bookingClass: 'O',
        aircraftType: '333',
        numOfStops: 0,
        status: 'SA',
        segRefNum: 'ST1',
        ticketNumArr: ['1602387290867'],
      },
    ],
    leisureTravel: {
      passengerList: [
        {
          pnrRef: 'PT2',
          ageType: 'ADT',
          beneficiaryTypeCode: 'EMP',
          dependentId: null,
          relationship: null,
          relationshipCode: null,
          title: 'MR',
          firstName: 'LWK YHM MHXDLD',
          lastName: 'LDM',
          infantWithoutSeat: false,
        },
      ],
    },
    officeInfo: {
      officeId: 'HKGCX0334',
      currencyCode: 'HKD',
      paymentType: 'FWN',
      paypalPortCode: 'HKG',
    },
    paymentConcept: 'PRP',
    paymentMedium: 'CRD',
    pendingStatus: 'DONE',
    prepaidStatus: 'REFUNDED',
    recLoc: '',
    status: 'CANCELLED',
    type: 'ELT',
    updatedAt: '2023-07-23T17:29:25.875Z',
    updatedBy: { role: 'SYSTEM', roleId: 'SYSTEM' },
    contactInformation: {
      phone: '842-62824226',
      email: 'DL_IT_ETP@CATHAYPACIFIC.COM',
      altEmail: null,
    },
    concession: {
      id: 'LTRULEZONECX34',
      name: 'T1 Priority 25 - Employee, spouse and/or children under age 24 (if applicable)',
    },
    ticketsBySegment: [
      {
        departureDate: '2023-08-01',
        departureTime: '08:00',
        departurePort: 'HKG',
        arrivalDate: '2023-08-01',
        arrivalTime: '09:55',
        arrivalPort: 'TPE',
        carrier: 'CX',
        flightNum: '488',
        bookingParentClass: 'Y',
        bookingClass: 'O',
        aircraftType: '333',
        numOfStops: 0,
        status: 'SA',
        segRefNum: 'ST1',
        ticketNumArr: ['1602387290867'],
        tickets: [
          {
            latestTicket: {
              ticketNum: '1602387290867',
              segRefNum: 'ST1',
              paxRefNum: 'PT2',
              fare: {
                totalAmount: 345,
                totalTax: 265,
                baseFare: 80,
                bsrRate: 7.81377523,
                grossFare: 80,
                currency: 'HKD',
                baseFareCurrency: 'USD',
                fareBasis: 'YIDZS2R2/ZEA',
                fcString: 'HKG CX TPE9.00NUC9.00END ROE1.000000',
                tax: [
                  { rate: 0, type: 'YR' },
                  { rate: 90, currencyCode: 'HKD', type: 'G3' },
                  { rate: 120, currencyCode: 'HKD', type: 'HK' },
                  { rate: 55, currencyCode: 'HKD', type: 'I5' },
                ],
                taxFilters: [],
              },
              baggage: [{ quantity: 2, unit: 'PC' }],
              status: '',
              ticketValidity: { type: 'A', date: '2023-10-22' },
              cpnNumber: '1',
              ticketSegObj: {
                departureDate: '2023-08-01',
                departureTime: '08:00',
                departurePort: 'HKG',
                arrivalDate: '2023-08-01',
                arrivalTime: '09:55',
                arrivalPort: 'TPE',
                carrier: 'CX',
                flightNum: '488',
                bookingClass: 'O',
                ticketNumArr: [],
              },
              segObj: {
                departureDate: '2023-08-01',
                departureTime: '08:00',
                departurePort: 'HKG',
                arrivalDate: '2023-08-01',
                arrivalTime: '09:55',
                arrivalPort: 'TPE',
                carrier: 'CX',
                flightNum: '488',
                bookingParentClass: 'Y',
                bookingClass: 'O',
                aircraftType: '333',
                numOfStops: 0,
                status: 'SA',
                segRefNum: 'ST1',
                ticketNumArr: ['1602387290867'],
              },
              paxObj: {
                paxRefNum: 'PT2',
                lastName: 'LDM',
                firstName: 'LWK YHM MHXDLD MR',
                type: 'ADT',
                ticketPaxType: 'A',
                countryOfResidential: 'HKG',
                passport: {
                  issuingCountry: 'HKG',
                  passportNumber: 'H100129614',
                  nationality: 'CHN',
                  dateOfBirth: '02OCT72',
                  gender: 'M',
                  expiryDate: '23APR31',
                  lastName: 'LDM',
                  firstName: 'LWK YHM MHXDLD',
                },
                title: 'MR',
                beneficiaryTypeCode: 'EMP',
                dependentId: null,
                ptc: 'ZEA',
                ticketNum: '1602387290867',
              },
              originatorId: '13391302',
              tourCode: '',
              sourcePort: 'HKG',
              sourceCountry: 'HK',
              endorsement: 'SUBLO 25Y/Y25 02SEP96 CX 128215J VALID ON CX ONLY',
              issueDate: '2023-07-24',
              fop: [
                {
                  type: 'MS',
                  amount: 345,
                  indicator: '3',
                  fopFreeText: 'DEBIT*HKG2020110016',
                },
                {
                  type: 'MS',
                  amount: 0,
                  indicator: '3',
                  fopFreeText: 'CV*ELT230001074',
                },
              ],
              createdAt: '2023-07-23T17:29:25.875Z',
              updatedAt: '2023-07-24 01:29',
            },
          },
        ],
      },
    ],
  },
  bookingEligibility: {
    CANCEL_BOOKING: false,
    REMOVE_PAX: false,
    REBOOK: false,
    REFUND: false,
    RETRIEVE_AND_PAY: false,
  },
  isCheckedIn: false,
  isFromAmadeus: true,
};

const SAMPLE_COMPLETED_SCREEN_DATA = {
  bookingResult: {
    booking: {
      _id: '64e950484164ed4f7f17de64',
      applicationId: 'ELT235202136',
      __v: 0,
      bookingUser: {
        employeeId: '500001A',
        galaCXyId: 'ETP001',
        profileRevision: 1,
        employeeClassification: 'GS',
        department: 'D701700',
        countryOfEmployment: 'HKG',
        workingLocation: 'HKG',
        domicilePort: null,
        jobLevel: 'B',
        benefitDTJobLevel: null,
        benefitLTJobLevel: null,
        profileName: {
          firstName: 'Deborah',
          middleName: 'Dev',
          lastName: 'Testing',
        },
        passportName: {
          firstName: '',
          lastName: '',
        },
        hiringCompany: 'CX',
        company: 'CPA',
        type: 'EMPLOYEE',
        paymentConcept: 'FWN',
        paymentMedium: 'DDP',
      },
      createdAt: '2023-08-26T01:07:20.251Z',
      createdBy: {
        employeeId: '500001A',
        profileRevision: 1,
        role: 'SELF',
        roleId: 'SELF',
      },
      etpPassengerList: [
        {
          pnrRef: 'PT2',
          pnrFirstName: 'Deborah Dev Ms',
          pnrLastName: 'Testing',
          ageType: 'ADT',
          age: 28,
          beneficiaryTypeCode: 'EMP',
          dependentId: null,
          relationship: '',
          relationshipCode: '',
          title: 'MS',
          firstName: 'DEBORAH DEV',
          lastName: 'TESTING',
          infantWithoutSeat: false,
          ptc: 'ZEA',
          countryOfResidential: 'HKG',
        },
      ],
      latestSegments: [
        {
          departureDate: '2023-08-27',
          departureTime: '16:40',
          departurePort: 'HKG',
          arrivalDate: '2023-08-27',
          arrivalTime: '20:35',
          arrivalPort: 'SIN',
          carrier: 'CX',
          flightNum: '711',
          bookingClass: 'O',
          aircraftType: '359',
          numOfStops: 0,
          status: 'SA',
          segRefNum: 'ST1',
          ticketNumArr: ['1602387291068'],
        },
      ],
      leisureTravel: {
        passengerList: [
          {
            pnrRef: 'PT2',
            ageType: 'ADT',
            beneficiaryTypeCode: 'EMP',
            dependentId: null,
            relationship: null,
            relationshipCode: null,
            title: 'MS',
            firstName: 'DEBORAH DEV',
            lastName: 'TESTING',
            infantWithoutSeat: false,
          },
        ],
      },
      officeInfo: {
        officeId: 'HKGCX0334',
        currencyCode: 'HKD',
        paymentType: 'FWN',
        paypalPortCode: 'HKG',
      },
      paymentConcept: 'PRP',
      paymentMedium: 'CRP',
      pendingStatus: 'DONE',
      recLoc: '6WJFRU',
      status: 'MODIFIED',
      type: 'ELT',
      updatedAt: '2023-08-26T13:09:27.726Z',
      updatedBy: {
        role: 'SYSTEM',
        roleId: 'SYSTEM',
      },
      contactInformation: {
        phone: '852-9998214',
        email: 'DL_IT_ETP@CATHAYPACIFIC.COM',
        altEmail: null,
      },
      prepaidStatus: 'RETRIEVE_AND_PAY',
      concession: {
        id: 'LTRULEZONECX16',
        name: 'T1 Priority 25 - Employee, spouse and/or children under age 24 (if applicable)',
      },
      ticketsBySegment: [
        {
          departureDate: '2023-08-27',
          departureTime: '16:40',
          departurePort: 'HKG',
          arrivalDate: '2023-08-27',
          arrivalTime: '20:35',
          arrivalPort: 'SIN',
          carrier: 'CX',
          flightNum: '711',
          bookingParentClass: 'Y',
          bookingClass: 'O',
          aircraftType: '359',
          numOfStops: 0,
          status: 'SA',
          segRefNum: 'ST1',
          ticketNumArr: ['1602387291068'],
          tickets: [
            {
              latestTicket: {
                ticketNum: '1602387291068',
                segRefNum: 'ST1',
                paxRefNum: 'PT2',
                fare: {
                  totalAmount: 375,
                  totalTax: 265,
                  baseFare: 110,
                  bsrRate: 7.83004653,
                  grossFare: 110,
                  currency: 'HKD',
                  baseFareCurrency: 'USD',
                  fareBasis: 'YIDZS3R2/ZEA',
                  fcString: 'HKG CX SIN14.00NUC14.00END ROE1.000000',
                  tax: [
                    {
                      rate: 0,
                      type: 'YR',
                    },
                    {
                      rate: 90,
                      currencyCode: 'HKD',
                      type: 'G3',
                    },
                    {
                      rate: 120,
                      currencyCode: 'HKD',
                      type: 'HK',
                    },
                    {
                      rate: 55,
                      currencyCode: 'HKD',
                      type: 'I5',
                    },
                  ],
                  taxFilters: [],
                },
                baggage: [
                  {
                    quantity: 2,
                    unit: 'PC',
                  },
                ],
                status: '--',
                ticketValidity: {
                  type: 'A',
                  date: '2023-11-24',
                },
                cpnNumber: '1',
                ticketSegObj: {
                  departureDate: '2023-08-27',
                  departureTime: '16:40',
                  departurePort: 'HKG',
                  arrivalDate: '2023-08-27',
                  arrivalTime: '20:35',
                  arrivalPort: 'SIN',
                  carrier: 'CX',
                  flightNum: '711',
                  bookingClass: 'O',
                  ticketNumArr: [],
                },
                segObj: {
                  departureDate: '2023-08-27',
                  departureTime: '16:40',
                  departurePort: 'HKG',
                  arrivalDate: '2023-08-27',
                  arrivalTime: '20:35',
                  arrivalPort: 'SIN',
                  carrier: 'CX',
                  flightNum: '711',
                  bookingParentClass: 'Y',
                  bookingClass: 'O',
                  aircraftType: '359',
                  numOfStops: 0,
                  status: 'SA',
                  segRefNum: 'ST1',
                  ticketNumArr: ['1602387291068'],
                },
                paxObj: {
                  paxRefNum: 'PT2',
                  lastName: 'TESTING',
                  firstName: 'DEBORAH DEV MS',
                  type: 'ADT',
                  ticketPaxType: 'A',
                  countryOfResidential: 'HKG',
                  title: 'MS',
                  beneficiaryTypeCode: 'EMP',
                  dependentId: null,
                  ptc: 'ZEA',
                  ticketNum: '1602387291068',
                },
                originatorId: '13391302',
                tourCode: '',
                sourcePort: 'HKG',
                sourceCountry: 'HK',
                endorsement: 'SUBLO 25Y/Y25 26MAR20 CX 500001A VALID ON CX ONLY',
                issueDate: '2023-08-26',
                fop: [
                  {
                    type: 'MS',
                    amount: 375,
                    indicator: '3',
                    fopFreeText: 'SD',
                  },
                  {
                    type: 'MS',
                    amount: 0,
                    indicator: '3',
                    fopFreeText: 'CV*ELT235202136',
                  },
                ],
                updatedAt: '2023-08-26 09:07',
                createdAt: '2023-08-26T01:07:43.520Z',
                overrideStatus: '--',
              },
            },
          ],
        },
      ],
    },
  },
  recLoc: '6WJFRU',
};

export {
  SAMPLE_NORMAL_FLIGHT_LOAD_DATA,
  SAMPLE_FULL_FLIGHT_LOAD_DATA,
  SAMPLE_EXTREME_FLIGHT_LOAD_DATA,
  SAMPLE_CONCESSION_DATA,
  SAMPLE_PAYMENT_HISTORY_LOAD_DATA,
  SAMPLE_ADMIN_MAINTAIN_EMPLOYEE_DATA,
  SAMPLE_EMPLOYEE_PROFILE_DATA,
  SAMPLE_ADMIN_NOTES_DATA,
  SAMPLE_REVIEW_AND_CONFIRM_DATA,
  SAMPLE_ADMIN_DEPENDENT_DATA,
  SAMPLE_USER_FLOWN_SUSPENSION_DATA,
  SAMPLE_USER_PROFILE_DATA,
  SAMPLE_CANCELLED_PREPAID_DATA,
  SAMPLE_NOT_CANCELLED_PREPAID_DATA,
  SAMPLE_NOT_REFUND_DATA,
  SAMPLE_REFUNDED_DATA,
  SAMPLE_COMPLETED_SCREEN_DATA,
};
