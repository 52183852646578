import { PAYPAL_PATHS } from '../../constants/paths';
import { IRouteConfig } from '../../interfaces';

import PayPalSubscription from './PayPalSubscription';
import PayPalSubscriptionCancelled from './PayPalSubscriptionCancelled';

const paypalRouteConfig: IRouteConfig[] = [
  {
    path: PAYPAL_PATHS.subscription,
    component: PayPalSubscription,
    exact: false,
  },
  {
    path: PAYPAL_PATHS.subscriptionCancelled,
    component: PayPalSubscriptionCancelled,
    exact: false,
  },
];

export default paypalRouteConfig;
