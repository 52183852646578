import React, { useContext } from 'react';
import { use100vh } from 'react-div-100vh';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, useTheme } from '@mui/material';
import { checkValidDateRange } from '../../../helpers';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { reset, selectApp } from '../../../slice/appSlice';
import { setSelectedPaymentHistoryPeriod } from '../../../slice/userSlice';
import { EditPaymentHistoryAction, EditPaymentHistoryState } from '../../../context/PaymentHistoryContext';

import { FormButton } from '../../../components';
import { PaymentHistorySearchDetails } from '../..';

interface IDialogContent {
  title?: string;
  yesTitle: string;
  noTitle?: string;
  PaymentHistoryResult?: any;
}

const PaymentHistoryDialog = ({
  open,
  handleCloseDialog,
  dialogContent,
}: {
  open: boolean;
  handleCloseDialog: () => void;
  dialogContent: IDialogContent;
}) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const screenHeight = use100vh();
  const { isDesktop } = useAppSelector(selectApp) || {};

  const editPaymentHistory = useContext(EditPaymentHistoryState) || {};
  const { editPeriod } = editPaymentHistory || {};
  const { from, to } = editPeriod || {};

  const editPaymentHistoryAction = useContext(EditPaymentHistoryAction) || {};
  const { resetPaymentHistory } = editPaymentHistoryAction || {};

  const { yesTitle, noTitle } = dialogContent || {};
  const isApplyButtonValid = !!from && !!to && checkValidDateRange(from, to);

  return (
    <Dialog
      sx={{
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        '& .MuiDialog-container': {
          alignItems: 'baseline',
          '& .MuiPaper-root': {
            my: 'auto',
            width: '100%',
            height: '715px',
            maxWidth: isDesktop ? '478px' : '343px',
            maxHeight: `calc(${screenHeight}px - 32px)`,
            borderRadius: 1,
            boxShadow: theme.boxShadow.important,
            overflow: 'hidden',
          },
        },
      }}
      open={open}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <DialogContent sx={{ p: 0, width: '100%' }}>
          <DialogContentText
            variant="body_1_regular"
            sx={{
              overflow: 'hidden',
              textAlign: 'center',
              width: '100%',
              color: theme.palette.text.secondary,
            }}
          >
            <PaymentHistorySearchDetails />
          </DialogContentText>
        </DialogContent>
      </Box>
      <Box
        sx={{
          background: 'white',
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          p: 2,
        }}
      >
        <DialogActions
          sx={{
            p: 0,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <FormButton
            theme={theme}
            colour="transparent"
            size="large"
            sx={{
              pl: 0,
              height: '44px',
              display: 'flex',
              justifyContent: 'center',
              ...{ minWidth: 0 },
            }}
            onClick={() => {
              resetPaymentHistory();
              dispatch(reset());
            }}
          >
            {noTitle}
          </FormButton>

          {isApplyButtonValid && (
            <FormButton
              theme={theme}
              colour="green"
              variant="contained"
              color="success"
              size="large"
              customStyles={{
                borderRadius: 8,
                width: '128px',
                height: '44px',
              }}
              onClick={() => {
                dispatch(setSelectedPaymentHistoryPeriod(editPeriod));

                handleCloseDialog?.();
              }}
              sx={{
                '&:not(:first-of-type), &:first-of-type': {
                  ml: 0,
                },
              }}
            >
              {yesTitle}
            </FormButton>
          )}
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default PaymentHistoryDialog;
