import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import storageSession from 'redux-persist/lib/storage/session';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import appReducer from '../slice/appSlice';
import authReducer from '../slice/authSlice';
import userReducer from '../slice/userSlice';
import bookingUserReducer from '../slice/bookingUserSlice';
import bookingSearchReducer from '../slice/bookingSlice';
import configurationReducer from '../slice/configurationSlice';
import paypalReducer from '../slice/paypalSlice';
import bookingFilterReducer from '../slice/bookingFilterSlice';
import flownSuspensionReducer from '../slice/flownSuspensionSlice';

const persistConfig = {
  key: 'etp-client',
  version: 1,
  storage: storageSession,
  // blacklist: ["passport"], // add blacklist here, to not persist
};

const reducers = combineReducers({
  app: appReducer,
  auth: authReducer,
  user: userReducer,
  bookingUser: bookingUserReducer,
  bookingSearch: bookingSearchReducer,
  configuration: configurationReducer,
  paypal: paypalReducer,
  bookingFilter: bookingFilterReducer,
  flownSuspension: flownSuspensionReducer,
  form: formReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);
const dismissButtonCallBackFuncPath = 'app.errorAlert.dismissCallBackFunc';

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        ignoredPaths: [dismissButtonCallBackFuncPath],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
