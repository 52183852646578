import { useTheme, SxProps, Theme } from '@mui/material';

import { FormFieldLabel } from '../../../components';

const LtTravellerWarning = ({
  warningMessage = '',
  overrideStyle,
}: {
  warningMessage: string;
  overrideStyle?: SxProps<Theme>;
}) => {
  const theme = useTheme();
  return (
    <FormFieldLabel
      style={{
        alignItems: 'flex-start',
        fontSize: theme.typography.navigation_regular.fontSize,
        marginTop: '4px',
        ...overrideStyle,
      }}
      iconStyle={{ width: '10px', height: '10px', marginTop: '2px' }}
      isError
      title={warningMessage}
    />
  );
};
export default LtTravellerWarning;
