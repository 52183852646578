import { USER_PROFILE_PATHS } from '../../constants/paths';
import { IRouteConfig } from '../../interfaces';

import UserProfile from './UserProfile';
import UserProfileDetails from './UserProfileDetails';
import UserUtility from './UserUtility';

import {
  PaymentMethodDetailContainer,
  PaymentHistoryContainer,
  PassportDetailsContainer,
  DelegationDetailsContainer,
  ContactDetailsContainer,
  AddDelegateeContainer,
  BasicDetailsContainer,
  ConcessionDetailsContainer,
  DependentDetailsContainer,
} from '../../containers';

const userRouteConfig: IRouteConfig[] = [
  {
    path: USER_PROFILE_PATHS.details,
    component: UserProfileDetails,
    exact: false,
    routes: [
      {
        path: USER_PROFILE_PATHS.basicInformation,
        component: BasicDetailsContainer,
      },
      {
        path: USER_PROFILE_PATHS.passport,
        component: PassportDetailsContainer,
      },
      {
        path: USER_PROFILE_PATHS.contactDetails,
        component: ContactDetailsContainer,
      },
      {
        path: USER_PROFILE_PATHS.delegation,
        component: DelegationDetailsContainer,
        exact: true,
      },
      {
        path: USER_PROFILE_PATHS.addDelegatee,
        component: AddDelegateeContainer,
      },
      {
        path: USER_PROFILE_PATHS.paymentHistory,
        component: PaymentHistoryContainer,
      },
      {
        path: USER_PROFILE_PATHS.paymentMethod,
        component: PaymentMethodDetailContainer,
      },
      {
        path: USER_PROFILE_PATHS.dependentDetails,
        component: DependentDetailsContainer,
      },
      {
        path: USER_PROFILE_PATHS.concessionDetails,
        component: ConcessionDetailsContainer,
      },
    ],
  },
  {
    path: USER_PROFILE_PATHS.menu,
    component: UserProfile,
  },
  {
    path: USER_PROFILE_PATHS.root,
    component: UserUtility,
  },
];

export default userRouteConfig;
