import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { REGEX, SALUTATION } from '../../../constants/constants';

import en from '../../../translations/en';

import { INominatedPassenger } from '../../../interfaces';

import { validateRegex } from '../../../helpers';

import { Configuration } from 'configuration';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectBooking, setLeisureTravelDetails } from '../../../slice/bookingSlice';

import { BookingSummaryAction, BookingSummaryState } from '../../../context/BookingSummaryContext';

import { ReduxFormSelectInput, ReduxFormTextInput } from '../../../components';

const LtAccompanyForm = ({
  keyIndex,
  isKeepData,
  isFromCompleted,
  customStyles,
  isRequireAccompanyForm,
}: {
  index?: number;
  keyIndex?: number;
  isKeepData?: boolean;
  isFromCompleted?: boolean;
  customStyles?: any;
  isRequireAccompanyForm?: boolean;
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { leisureTravelDetails } = useAppSelector(selectBooking) || {};
  const { ltSeatConcessionTravellerList } = leisureTravelDetails || {};

  const { removalFormData } = useContext(BookingSummaryState) || {};
  const { setRemovalFormData } = useContext(BookingSummaryAction) || {};

  const currentTraveller = isRequireAccompanyForm
    ? ltSeatConcessionTravellerList.find((traveller: INominatedPassenger) => traveller.isSelected)
    : ltSeatConcessionTravellerList.find((traveller: INominatedPassenger) => traveller.keyIndex === keyIndex);

  const previousFormData = currentTraveller?.accompanyFormData;

  let newCurrentFormArray = currentTraveller?.accompanyFormData || {};
  const currentForm = isKeepData ? previousFormData : (newCurrentFormArray as any);

  // useState Fields
  const [bookingReference, setBookingReference] = useState<string>(currentForm?.bookingReference || '');
  const [salutation, setSalutation] = useState<Configuration.DropdownOptionClient | null>(
    currentForm?.salutation || null,
  );
  const [lastName, setLastName] = useState<string>(currentForm?.lastName || '');
  const [firstName, setFirstName] = useState<string>(currentForm?.firstName || '');
  const [isBookingReferenceFocus, setIsBookingReferenceFocusFocus] = useState(false);
  const [isSalutationFocus, setIsSalutationFocus] = useState(false);
  const [isLastNameFocus, setIsLastNameFocus] = useState(false);
  const [isFirstNameFocus, setIsFirstNameFocus] = useState(false);

  useEffect(() => {
    if (isKeepData) {
      if (previousFormData) {
        setBookingReference(previousFormData?.bookingReference);
        setSalutation(previousFormData?.salutation);
        setLastName(previousFormData?.lastName);
        setFirstName(previousFormData?.firstName);
      }
      if (isFromCompleted) {
        setBookingReference(removalFormData?.bookingReference);
        setSalutation(removalFormData?.salutation);
        setLastName(removalFormData?.lastName);
        setFirstName(removalFormData?.firstName);
      }
    }
  }, [isKeepData, previousFormData]);

  useEffect(() => {
    if (currentForm) {
      setBookingReference(currentForm?.bookingReference || '');
      setSalutation(currentForm?.salutation || null);
      setLastName(currentForm?.lastName || '');
      setFirstName(currentForm?.firstName || '');
    }
  }, []);

  useEffect(() => {
    // update to useContext every time form updated
    newCurrentFormArray = {
      keyIndex,
      bookingReference,
      salutation,
      lastName,
      firstName,
    };
    const newTravellerVal = ltSeatConcessionTravellerList.find(
      (traveller: INominatedPassenger) => traveller.keyIndex === keyIndex,
    );
    const accompanyTraveller = {
      ...newTravellerVal,
      accompanyFormData: newCurrentFormArray,
    };
    let newArray: any[];
    if (isRequireAccompanyForm) {
      newArray = ltSeatConcessionTravellerList.map((traveller: INominatedPassenger) => {
        if (traveller.isSelected) {
          return { ...traveller, accompanyFormData: newCurrentFormArray };
        } else {
          return traveller;
        }
      });
    } else {
      newArray = ltSeatConcessionTravellerList.map((traveller: INominatedPassenger) => {
        if (traveller.keyIndex === keyIndex) {
          return accompanyTraveller;
        } else {
          return traveller;
        }
      });
    }

    if (isFromCompleted) {
      setRemovalFormData({
        ...removalFormData,
        bookingReference,
        salutation,
        lastName,
        firstName,
      });
    } else {
      dispatch(
        setLeisureTravelDetails({
          ...leisureTravelDetails,
          ltSeatConcessionTravellerList: newArray.sort((a: INominatedPassenger, b: INominatedPassenger) => {
            return a.keyIndex - b.keyIndex;
          }),
        }),
      );
    }
  }, [bookingReference, salutation, lastName, firstName]);

  return (
    <Box>
      <Box
        sx={{
          mt: 2,
          mb: 2,
          height: '1px',
          bgcolor: customStyles?.strokeColor || theme.color.etp.grey,
        }}
      />
      <Typography variant="body_2_regular" sx={{ mb: 1, color: customStyles?.textColor }}>
        {en.booking.travelType.leisureTravel.accompanyPassenger}
      </Typography>

      {/* Booking Reference */}
      <ReduxFormTextInput
        input={{
          value: bookingReference,
          onBlur: () => {
            setIsBookingReferenceFocusFocus(true);
          },
          onChange: (text: string) => {
            if (text.length <= 6) {
              setBookingReference(text);
              setIsBookingReferenceFocusFocus(true);
            }
          },
        }}
        title={en.booking.confirmation.bookingReference}
        subTitle={en.booking.confirmation.bookingReferenceWarning}
        isNumOrCapitalAlpha={true}
        maxLength={6}
        meta={{
          touched: isBookingReferenceFocus && !validateRegex(bookingReference, REGEX.sixCharacters),
          error: isBookingReferenceFocus
            ? !validateRegex(bookingReference, REGEX.sixCharacters) && en.booking.confirmation.mandatory
            : null,
        }}
      />
      {/* Salutation */}
      <ReduxFormSelectInput
        input={{
          value: salutation,
          onBlur: () => {
            setIsSalutationFocus(true);
          },
          onChange: (input: Configuration.DropdownOptionClient) => {
            setIsSalutationFocus(true);
            setSalutation(input);
            if (!validateRegex(salutation?.code || '', REGEX.notEmpty)) {
              setIsSalutationFocus(false);
            }
          },
        }}
        title={en.booking.travelType.salutation}
        options={SALUTATION}
        autocomplete={{
          customOpen: true,
        }}
        meta={{
          touched: isSalutationFocus && !validateRegex(salutation?.code || '', REGEX.notEmpty),
          error: isSalutationFocus
            ? !validateRegex(salutation?.code || '', REGEX.notEmpty) && en.booking.confirmation.mandatory
            : null,
        }}
      />

      {/* First name */}
      <ReduxFormTextInput
        input={{
          value: firstName,
          onBlur: () => {
            setIsFirstNameFocus(true);
            setFirstName(firstName.trim());
          },

          onChange: (text: string) => {
            setIsFirstNameFocus(true);
            setFirstName(text);
          },
        }}
        title={en.userProfile.personal.basicInfo.firstName}
        subTitle={en.booking.travelType.samePassportName}
        isAlphaOrSpace={true}
        meta={{
          touched: !validateRegex(firstName, REGEX.alphabetWithSpace),
          error: isFirstNameFocus
            ? !validateRegex(firstName, REGEX.alphabetWithSpace) && en.booking.confirmation.mandatory
            : null,
        }}
      />

      {/* Last Name */}
      <ReduxFormTextInput
        input={{
          value: lastName,
          onBlur: () => {
            setIsLastNameFocus(true);
            setLastName(lastName.trim());
          },
          onChange: (text: string) => {
            setLastName(text);
            setIsLastNameFocus(true);
          },
        }}
        title={en.userProfile.personal.basicInfo.lastName}
        subTitle={en.booking.travelType.samePassportName}
        isAlphaOrSpace={true}
        meta={{
          touched: isLastNameFocus && !validateRegex(lastName, REGEX.alphabetWithSpace),
          error: isLastNameFocus
            ? !validateRegex(lastName, REGEX.alphabetWithSpace) && en.booking.confirmation.mandatory
            : null,
        }}
      />
    </Box>
  );
};

LtAccompanyForm.defaultProps = {
  isFromCompleted: false,
  customStyles: {},
};

export default LtAccompanyForm;
