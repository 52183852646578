import { Configuration } from 'configuration';
import { stringAlphabeticSort } from './sort';

const formatAirportValue = (airport: Configuration.AirportClient | null, isAirportValue = true): string => {
  if (!airport) {
    return '';
  }

  return isAirportValue
    ? `${airport.cityName ? `${airport.cityName}, ` : ''}(${airport.airportCode})`
    : airport.airportCode;
};

const setInputValue = (current: HTMLInputElement, newValue: string) => {
  current.value = newValue;
};

const handleFilterList = (
  origin: Configuration.Airport | null,
  destination: Configuration.Airport | null,
  airports: Configuration.Airport[],
  keyword: string,
  isOriginField?: boolean,
) => {
  if (keyword === '' || keyword.length < 2) {
    return null;
  }

  const oppositeAirport = isOriginField ? destination : origin;
  const oppositeAirportCityCode = oppositeAirport ? oppositeAirport.cityCode : '';

  const regEx = new RegExp(`\\b${keyword}`, 'gi');
  const filterResultPort: Array<Configuration.Airport> = [];
  const filterResultCity: Array<Configuration.Airport> = [];
  const filterResultOthers: Array<any> = [];

  airports.forEach((airport) => {
    if (airport.cityCode === oppositeAirportCityCode) {
      return;
    }
    if (airport.airportCode.match(regEx)) {
      filterResultPort.push(airport);
    } else if (airport.cityCode?.match(regEx)) {
      filterResultCity.push(airport);
    } else if (airport.label?.match(regEx)) {
      const idx = airport.label.search(regEx);
      const wordAt = airport.label.slice(0, idx).split(' ').length;

      filterResultOthers[wordAt] = filterResultOthers[wordAt] ?? [];
      filterResultOthers[wordAt].push(airport);
    }
  });
  filterResultOthers.forEach((item) => item.sort((a: any, b: any) => stringAlphabeticSort(a.label, b.label)));
  return filterResultPort.concat(filterResultCity, filterResultOthers).flat();
};

export { formatAirportValue, setInputValue, handleFilterList };
