import { BOOKING_PATHS } from '../../constants/paths';
import { IRouteConfig } from '../../interfaces';
import Booking from './Booking';
import CommonBookings from './CommonBookings';

const bookingRouteConfig: IRouteConfig[] = [
  {
    path: BOOKING_PATHS.booking,
    component: Booking,
    exact: true,
  },
  {
    path: BOOKING_PATHS.myBookings,
    component: CommonBookings,
  },
];

export default bookingRouteConfig;
