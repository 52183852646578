import { Box, Typography, useTheme } from '@mui/material';

import en from '../../../translations/en';
import { CARRIER_OPTION, UI_STYLES } from '../../../constants/constants';
import { CheckCircleIcon, CheckCircleOutlineIcon, LockedIcon } from '../../../assets/images';

import { INominatedPassenger, ITravellerConcessionQuota } from '../../../interfaces';
import { isPaxAgeUnderSixteen, isSelectedAdult, isSelectedEMP, toCapitalize } from '../../../helpers';

import { useAppSelector } from '../../../app/hooks';
import { selectBooking } from '../../../slice/bookingSlice';

import { WithTextSwitch } from '../../../components';
import { LtTravellerQuotaElements, LtTravellerWarning } from '../../../containers';

const LtEligibleTravellerItem = ({
  travellerName,
  traveller,
  isSelected,
  eligibleList,
  isInfant,
  infantWithSeat,
  isAllowSeatForInfant,
  canProceed,
  handleSelected,
  handleToggled,
  concessionQuotaProps,
}: {
  travellerName: string;
  traveller: INominatedPassenger;
  isSelected: boolean;
  eligibleList: INominatedPassenger[];
  isInfant: boolean;
  infantWithSeat: boolean;
  isAllowSeatForInfant: boolean;
  canProceed: boolean;
  handleSelected: () => void;
  handleToggled: () => void;
  concessionQuotaProps?: ITravellerConcessionQuota;
}) => {
  const theme = useTheme();

  const { outwardFlight } = useAppSelector(selectBooking) || {};
  const { age, relationship, validationInfo } = traveller || {};

  const enLt = en.booking.travelType.leisureTravel;

  const isShowUnder16WarningMsg =
    isSelected &&
    isPaxAgeUnderSixteen(age) &&
    outwardFlight?.marketingCompany !== CARRIER_OPTION.CX &&
    !isSelectedAdult(eligibleList);

  const isShowMHCompanionMsg =
    isSelected && !!validationInfo?.mustTravelWithBeneficiaryTypeCode && !isSelectedEMP(eligibleList);

  return (
    <Box
      sx={{
        minHeight: UI_STYLES.eligibleTravellerItemHeight,
        background: 'white',
        borderRadius: 1,
        boxShadow: theme.boxShadow.important,
        p: 2,
        mt: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          '&:active': {
            background: 'transparent',
          },
          cursor: 'pointer',
        }}
        onClick={handleSelected}
      >
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            minHeight: '44px',
          }}
        >
          <Typography variant="body_1_regular" color={theme.color.secondary.dark_grey.option_1}>
            {travellerName}
          </Typography>
          <Typography variant="navigation_regular" color={theme.color.secondary.dark_grey.option_2} sx={{ mt: 0.5 }}>
            {relationship}
          </Typography>
        </Box>

        {isSelected ? <CheckCircleIcon /> : <CheckCircleOutlineIcon />}
      </Box>

      {concessionQuotaProps && <LtTravellerQuotaElements {...concessionQuotaProps} />}

      {isInfant && isSelected && (
        <Box>
          <Box
            sx={{
              mt: 1.5,
              mb: 1,
              height: '48px',
              borderRadius: 1,
              background: theme.color.secondary.light_slate.option_5,
              display: 'flex',
              alignItems: 'center',
              pr: 2,
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Typography
                variant="body_2_regular"
                sx={{
                  pl: 1.5,
                  color: theme.color.secondary.dark_grey.option_1,
                }}
              >
                {isAllowSeatForInfant ? enLt.infantWithSeat : enLt.infantWithoutSeat}
              </Typography>
            </Box>

            {isAllowSeatForInfant ? (
              <WithTextSwitch
                disabled={!isSelected}
                checked={infantWithSeat}
                trackColor={theme.color.secondary.dark_grey.option_3}
                onChange={() => {
                  handleToggled && handleToggled();
                }}
              />
            ) : (
              <LockedIcon />
            )}
          </Box>

          {!canProceed && (
            <LtTravellerWarning
              overrideStyle={{
                marginTop: 1,
              }}
              warningMessage={enLt.ensureSufficient}
            />
          )}

          {!infantWithSeat && !canProceed && (
            <LtTravellerWarning
              overrideStyle={{
                marginTop: 1,
              }}
              warningMessage={enLt.infantWithoutSeatOption}
            />
          )}
        </Box>
      )}

      {isSelected && concessionQuotaProps && concessionQuotaProps?.booked >= concessionQuotaProps?.unused && (
        <LtTravellerWarning
          overrideStyle={{
            marginTop: 1,
          }}
          warningMessage={enLt.quotaElement.limitQuotaRestriction}
        />
      )}

      {isShowMHCompanionMsg && (
        <LtTravellerWarning
          overrideStyle={{
            marginTop: 1,
          }}
          warningMessage={toCapitalize(
            enLt.dependentTypeMustTravelWithEmpWarning
              .replace(RegExp(`{beneficiaryType}`, 'g'), relationship.toLocaleLowerCase())
              .replace(RegExp(`{marketingCompany}`, 'g'), outwardFlight?.marketingCompany || ''),
          )}
        />
      )}

      {isShowUnder16WarningMsg && (
        <LtTravellerWarning
          overrideStyle={{
            marginTop: 1,
          }}
          warningMessage={enLt.childrenUnder16}
        />
      )}
    </Box>
  );
};

export default LtEligibleTravellerItem;
