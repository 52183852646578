import React from 'react';
import { Box, SxProps, Typography, useTheme, Theme } from '@mui/material';

import { TravellerIcon } from '../../../assets/images';

// ETP-2445 => ETP-2473
const LeisureTravellerCount = ({
  travellerCount,
  handleClick,
  customStyles,
  isShowOnFlightCard,
}: {
  travellerCount: number;
  handleClick: () => void;
  customStyles?: SxProps<Theme>;
  isShowOnFlightCard?: boolean;
}) => {
  const theme = useTheme();
  return (
    <Box
      onClick={handleClick}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: isShowOnFlightCard ? 'space-between' : 'center',
        bgcolor: isShowOnFlightCard ? theme.color.secondary.grey.option_8 : theme.color.secondary.slate.option_6,
        borderRadius: 3,
        height: isShowOnFlightCard ? '26px' : '34px',
        width: isShowOnFlightCard ? '46px' : '72px',
        px: isShowOnFlightCard ? 1 : 1.5,
        ...customStyles,
      }}
    >
      <TravellerIcon fill={theme.palette.gray.dark} width={isShowOnFlightCard ? '15px' : '24px'} />
      <Typography
        variant={isShowOnFlightCard ? 'navigation_regular' : 'body_1_medium'}
        sx={{
          color: theme.color.secondary.dark_grey.option_3,
          ...(isShowOnFlightCard
            ? {
                mx: 0.5,
              }
            : {
                ml: 1,
              }),
        }}
      >
        {travellerCount}
      </Typography>
    </Box>
  );
};

export default LeisureTravellerCount;
