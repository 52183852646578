import { OutlinedInput, styled } from '@mui/material';

const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  input: {
    caretColor: theme.color.utility.link.option_3,
    color: theme.color.secondary.dark_grey.option_3,
    ...theme.typography.body_1_regular,
  },
  '.MuiOutlinedInput-notchedOutline': {
    border: '0 !important',
    caretColor: 'red',
  },
}));

export default StyledOutlinedInput;
