import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { isBoolean } from 'lodash';

import {
  DisabledIcon,
  TakeOffIcon,
  CompanyLogoSmall,
  TickIcon,
  SeatClassIcon,
  BookedIcon,
  LeisureTravelBackgroundLessIcon,
  DutyTravelBackgroundLessIcon,
  // LocationIcon,
} from '../../../../assets/images';
import { CARRIER_OPTION, FLIGHT_AVAILABILITY_STATUS, TRAVEL_PURPOSE } from '../../../../constants/constants';
import en from '../../../../translations/en';

import { calcStops, getDefaultSeatClassSelection, getSeatClassDetails } from '../../../../helpers';

import { ISelectedFlight } from '../../../../interfaces';
import { FlightSearch } from 'flightSearch';

import { useAppSelector } from '../../../../app/hooks';
import { selectApp } from '../../../../slice/appSlice';
import { selectBooking } from '../../../../slice/bookingSlice';

import { FlightDateLabel, FlightClass } from '../../..';

interface IFlightCardProps {
  flight: FlightSearch.ParsedFlightSearchResult;
  selectFlight: (flight: ISelectedFlight | null) => void;
  isSelected: boolean;
  isFromCart?: boolean;
}

// embargo or flightRestriction scenario view
const DisabledView = ({ title, bgColor, fontWeight }: { title?: string; bgColor: string; fontWeight: string }) => {
  const theme = useTheme();

  if (title === undefined) {
    return null;
  }
  return (
    <Box
      sx={{
        bgcolor: bgColor,
        display: 'flex',
        height: '30px',
        borderRadius: 0.5,
        alignItems: 'center',
        justifyContent: 'center',
        px: 2,
        m: 2,
      }}
    >
      <DisabledIcon />
      <Typography
        variant="navigation_medium"
        fontWeight={fontWeight}
        sx={{ color: theme.color.secondary.dark_grey.option_1, ml: 1 }}
      >
        {title}
      </Typography>
    </Box>
  );
};

const SeatNotAvailView = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        bgcolor: theme.color.secondary.light_slate.option_5,
        display: 'flex',
        borderRadius: 0.5,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        my: 2,
        px: 1,
        py: 0.25,
      }}
    >
      <SeatClassIcon fill={theme.color.secondary.dark_grey.option_5} />
      <Typography
        sx={{
          color: theme.color.secondary.dark_grey.option_1,
          fontSize: '10px',
          ml: 1,
        }}
      >
        {en.booking.searchFlight.seatNotAvail}
      </Typography>
    </Box>
  );
};

/**
 * check Flight class seat not avail. status
 * @param availability
 * @returns
 */
const checkSeatNotAvailStatus = (availability: FlightSearch.FlightAvailability) => {
  // scenario: Seat not avail.
  // filter out classes undefined first if have
  // all remaining classes are notAvailable or others

  const status = Object.values(availability)
    .filter((element) => element)
    .every(
      (element) => element === FLIGHT_AVAILABILITY_STATUS.notAvailable || element === FLIGHT_AVAILABILITY_STATUS.others,
    );

  return status;
};

/**
 * check Flight class restricted status
 * @param flightRestriction
 * @returns status
 */
const checkRestrictedStatus = (fightRestriction?: FlightSearch.FlightRestriction) => {
  // scenario: Flight class restricted
  // if econ/first/business returns, check if true

  if (!fightRestriction) {
    return false;
  }

  const status = Object.values(fightRestriction)
    .filter((element) => isBoolean(element)) // filter out undefined / other value
    .every((element) => element);

  return status;
};

const FlightCard = ({ flight, selectFlight, isSelected, isFromCart }: IFlightCardProps) => {
  const { isDesktop } = useAppSelector(selectApp) || {};
  const theme = useTheme();
  const border = isFromCart
    ? `${theme.spacing(0.25)} solid ${theme.palette.secondary.main}`
    : isSelected
    ? `${theme.spacing(0.25)} solid ${theme.color.utility.link.option_3}`
    : `${theme.spacing(0.25)} solid transparent`;

  const {
    departureTime,
    arrivalTime,
    departureDate,
    arrivalDate,
    departurePort,
    arrivalPort,
    callSign,
    aircraft,
    numberOfStops,
    availability,
    embargo,
    marketingCompany, // "CX" -> Cathay flight
    duration,
    flightRestriction,
    booked,
    bookedType,
  } = flight;

  const { econ, business, first } = availability || {};
  const { origin, destination } = useAppSelector(selectBooking) || {};

  // if any of OD - isVirtualAirport is true
  const isVirtualAirport = origin?.isVirtualAirport || destination?.isVirtualAirport;

  // the flight is embargo
  const isEmbargo = embargo && embargo.length > 0;
  const embargoCategory = embargo && embargo[0]?.category;
  const embargoDescription = embargo && embargo[0]?.description;

  // the flight is restricted
  const isWholeFlightRestricted = checkRestrictedStatus(flightRestriction);

  // seat not avail.
  const isSeatNotAvail = availability && checkSeatNotAvailStatus(availability);

  const isDisabled = isEmbargo || isSeatNotAvail || isWholeFlightRestricted || booked;

  return (
    <Box
      onClick={() => {
        if (isDisabled) {
          // cannot select if disabled
          return;
        }
        if (isSelected) {
          selectFlight(null);
          return;
        }

        selectFlight({
          ...flight,
          seatClass: getDefaultSeatClassSelection(getSeatClassDetails(availability, flightRestriction)),
        });
      }}
      sx={{
        borderRadius: 1.5,
        border: border,
        boxShadow:
          isFromCart || isDisabled ? 'none' : isSelected ? theme.boxShadow.selected : theme.boxShadow.important,
        my: 1,
        mx: { xs: 2, sm: 0 },
        width: isDesktop ? '100%' : 'auto',
        minWidth: '343px',
        bgcolor:
          isSelected && !isFromCart
            ? theme.backgroundColor.rockGrey
            : isDisabled
            ? theme.color.secondary.light_slate.option_6
            : theme.palette.primary.light,
        boxSizing: 'border-box',
      }}
    >
      <Box
        sx={{
          cursor: isDisabled || isFromCart ? 'default' : 'pointer',
        }}
      >
        {!booked && (
          <DisabledView
            title={
              isEmbargo
                ? embargoCategory
                : isWholeFlightRestricted
                ? en.booking.searchFlight.flightRestriction
                : undefined
            }
            bgColor={
              isEmbargo ? theme.color.secondary.light_sand.option_2 || '' : theme.color.secondary.sand.option_7 || ''
            }
            fontWeight={isEmbargo ? 'medium' : 'bold'}
          />
        )}

        {/** flightNo & booked layout */}
        <Box sx={{ mt: 2, mx: 2, display: 'flex', flexDirection: 'row' }}>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {isSelected ? (
              <TickIcon style={{ width: 12, height: 12, marginRight: 6 }} />
            ) : (
              marketingCompany === CARRIER_OPTION.CX && (
                // cathay flight show company logo
                <CompanyLogoSmall
                  fill={theme.color.primary.cathay_jade.option_3}
                  style={{ marginRight: 4, width: 16, height: 16 }}
                />
              )
            )}

            <Typography variant="body_1_regular" color={theme.color.secondary.dark_grey.option_3}>
              {callSign}
            </Typography>
          </Box>

          {/** if booked, show booked status icon  */}
          {booked && (
            <Box
              sx={{
                bgcolor: theme.color.primary.cathay_jade.option_5,
                height: '24px',
                borderRadius: '12px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '82px',
              }}
            >
              {bookedType === TRAVEL_PURPOSE.employeeDutyTravel ? (
                <DutyTravelBackgroundLessIcon fill="white" />
              ) : bookedType === TRAVEL_PURPOSE.employeeLeisureTravel ? (
                <LeisureTravelBackgroundLessIcon fill="white" />
              ) : (
                <BookedIcon />
              )}
              <Typography variant="navigation_bold" sx={{ color: 'white', ml: 0.5 }}>
                {en.booking.searchFlight.booked}
              </Typography>
            </Box>
          )}
        </Box>

        {/** flight DateTime layout */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mx: 2,
            mb: -0.5,
          }}
        >
          <Box
            sx={{
              flex: 1,
            }}
          >
            <FlightDateLabel
              departureTime={departureTime}
              arrivalTime={arrivalTime}
              departureDate={departureDate}
              arrivalDate={arrivalDate}
              textColor={
                isEmbargo || booked
                  ? theme.color.secondary.grey.option_3
                  : isWholeFlightRestricted
                  ? theme.color.secondary.dark_grey.option_3
                  : 'black'
              }
            />

            <Typography variant="navigation_medium" color={theme.color.secondary.grey.option_3}>
              {`${calcStops(numberOfStops)} ${aircraft || ''}`}
            </Typography>
          </Box>

          {/** seat class status & flight card status layout */}
          <Box
            sx={{
              display: 'flex',
              mr: -0.5,
              ml: 0.5,
            }}
          >
            {isEmbargo ? (
              embargoDescription && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '134px',
                    minHeight: '43px',
                    bgcolor: theme.color.secondary.light_slate.option_5,
                    borderRadius: 1,
                    p: 1,
                  }}
                >
                  {embargoDescription.split('\n').map((description, index) => {
                    return (
                      <Typography
                        key={index}
                        variant="navigation_regular"
                        sx={{
                          textAlign: 'center',
                          color: theme.color.secondary.dark_grey.option_1,
                        }}
                      >
                        {description}
                      </Typography>
                    );
                  })}
                </Box>
              )
            ) : isSeatNotAvail ? (
              <SeatNotAvailView />
            ) : (
              <Box sx={{ mt: 1, display: 'flex' }}>
                <FlightClass
                  title="F"
                  availability={first}
                  isRestricted={flightRestriction?.first}
                  isDisabled={isDisabled}
                />
                <FlightClass
                  title="J"
                  availability={business}
                  isRestricted={flightRestriction?.business}
                  isDisabled={isDisabled}
                />
                <FlightClass
                  title="Y"
                  availability={econ}
                  isRestricted={flightRestriction?.econ}
                  isDisabled={isDisabled}
                />
              </Box>
            )}
          </Box>
        </Box>

        {/** flight port & hours layout */}
        <Box sx={{ m: 2, display: 'flex', flexDirection: 'row' }}>
          {isVirtualAirport && (
            <Box
              sx={{
                bgcolor: theme.color.cabin_class.premium_economy.option_6,
                display: 'flex',
                borderRadius: '12px',
                alignItems: 'center',
                justifyContent: 'center',
                width: '96px',
                height: '24px',
                mr: '6px',
              }}
            >
              <Typography variant="caption" fontWeight="bold" sx={{ color: theme.color.secondary.dark_grey.option_2 }}>
                {departurePort}
              </Typography>
              <TakeOffIcon fill={theme.palette.gray.dark} width="16" style={{ marginLeft: 4, marginRight: 4 }} />
              <Typography variant="caption" fontWeight="bold" sx={{ color: theme.color.secondary.dark_grey.option_2 }}>
                {arrivalPort}
              </Typography>
            </Box>
          )}
          <Box
            sx={{
              mr: '6px',
              bgcolor: theme.color.secondary.light_slate.option_4,
              height: '24px',
              borderRadius: '12px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '58px',
            }}
          >
            <Typography
              variant="navigation_bold"
              sx={{
                color: isEmbargo ? theme.color.secondary.grey.option_3 : theme.color.secondary.dark_grey.option_3,
              }}
            >
              {duration}
            </Typography>
          </Box>

          {/* TODO: show stopover airport code when has intermediate stops information  */}
          {/* <Box
            sx={{
              bgcolor: "gray.contrastText",
              display: "flex",
              height: "24px",
              width: "51px",
              borderRadius: "12px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LocationIcon fill={theme.palette.gray.dark} width="12px" />
            <Typography variant="caption" sx={{ color: "gray.dark" }}>
              {"TPE"}
            </Typography>
          </Box> */}
        </Box>
      </Box>
    </Box>
  );
};

export default FlightCard;
export { checkSeatNotAvailStatus, checkRestrictedStatus };
