import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const CPPAuthTransit = () => {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);

  useEffect(() => {
    console.log(`searchParams: ${searchParams}`);
  }, []);

  return <>Auth</>;
};

export default CPPAuthTransit;
