import { Box, Typography } from '@mui/material';

import { USER_ROLE } from '../../constants/constants';
import { OverlayBackground } from '../../assets/images';
import en from '../../translations/en';

import { getShortDisplayName } from '../../helpers';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setErn } from '../../slice/authSlice';
import { selectUser } from '../../slice/userSlice';

import { RoleIndicatorBar } from '..';

const MobileHeader = ({ isReachScrollOffset }: { isReachScrollOffset: boolean }) => {
  const dispatch = useAppDispatch();

  const hiddenSx = isReachScrollOffset ? { opacity: 0 } : {};

  const { profile, role } = useAppSelector(selectUser) || {};

  const { personalInfo } = profile || {};
  const { preferredFirstName, firstName, lastName, middleName } = personalInfo || {};

  const { type: roleType, delegator = {} } = role || {};

  return (
    <Box
      className="mobile-header"
      sx={{
        '&::before': {
          content: '""',
          backgroundImage: `url(${OverlayBackground})`,
          backgroundPositionY: '15%',
          backgroundSize: 'cover',
          filter: 'brightness(80%)',
          borderRadius: '0px 0px 8px 8px',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: 160,
        },
        position: 'relative',
        color: 'white',
        pt: 5,
        px: 2,
        transition: (theme) => theme.transitions.create(['all', 'transform']),
        ...hiddenSx,
      }}
    >
      {roleType === USER_ROLE.delegation && (
        <RoleIndicatorBar
          customStyles={{
            position: 'absolute',
            top: 0,
            left: 0,
            px: 2.5,
            background: 'rgba(252, 252, 252, 0.7)',
          }}
        />
      )}
      <Box position="relative">
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            variant="body_1_bold"
            sx={{ alignSelf: 'flex-start' }}
            onMouseDown={(event: React.MouseEvent<HTMLDivElement>) => {
              event.stopPropagation();
              if (window.mockUser) {
                window.mockUser((ern) => {
                  dispatch(setErn(ern.toUpperCase()));
                });
              }
            }}
          >
            {en.home.hello}
            {getShortDisplayName(
              roleType === USER_ROLE.delegation
                ? delegator
                : {
                    preferredFirstName,
                    firstName,
                    lastName,
                    middleName,
                  },
            )}
          </Typography>
          <Typography variant="body_2_medium">
            {roleType === USER_ROLE.admin ? en.home.adminStatus : en.home.yourTravelExperienceStartsHere}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default MobileHeader;
