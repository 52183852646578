import React, { createContext, useState } from 'react';
import { IAdminNote, IAdminConcession } from '../interfaces';

interface IEmployeeStateType {
  employeeDetail: any;
  dependents: any;
  employeeNotes: IAdminNote[];
  focusNote: IAdminNote | null;

  // admin concession
  concessionGrouping: string;
  concessionName: any;
  focusConcession: IAdminConcession | null;
  concessions: IAdminConcession[];
}

interface IActionType {
  setEmployeeDetail: any;
  setDependent: any;
  setEmployeeNotes: any;
  setFocusNote: any;

  // admin concession
  setConcessionGrouping: any;
  setConcessionName: any;
  setFocusConcession: any;
  setConcessions: any;
}

const AdminAccessEmployeeState = createContext<IEmployeeStateType>({
  employeeDetail: null,
  dependents: null,
  employeeNotes: [],
  focusNote: null,
  focusConcession: null,
  concessionName: null,
  concessionGrouping: '',
  concessions: [],
});

const AdminAccessEmployeeAction = createContext<IActionType>({
  setEmployeeDetail: null,
  setDependent: null,
  setEmployeeNotes: null,
  setFocusNote: null,
  setConcessionGrouping: null,
  setConcessionName: null,
  setFocusConcession: null,
  setConcessions: null,
});

const AdminAccessEmployeeContextProvider = ({ children }: { children?: React.ReactNode }) => {
  const [employeeDetail, setEmployeeDetail] = useState<any>(null);
  const [dependents, setDependent] = useState<any>(null);
  const [employeeNotes, setEmployeeNotes] = useState<IAdminNote[]>([]);
  const [focusNote, setFocusNote] = useState<IAdminNote | null>(null);
  const [focusConcession, setFocusConcession] = useState<IAdminConcession | null>(null);
  const [concessionName, setConcessionName] = useState<any>(null);
  const [concessionGrouping, setConcessionGrouping] = useState<string>('');
  const [concessions, setConcessions] = useState<IAdminConcession[]>([]);

  return (
    <AdminAccessEmployeeState.Provider
      value={{
        dependents,
        employeeDetail,
        employeeNotes,
        focusNote,
        concessionGrouping,
        concessionName,
        focusConcession,
        concessions,
      }}
    >
      <AdminAccessEmployeeAction.Provider
        value={{
          setEmployeeDetail,
          setDependent,
          setEmployeeNotes,
          setFocusNote,
          setConcessionGrouping,
          setConcessionName,
          setFocusConcession,
          setConcessions,
        }}
      >
        {children}
      </AdminAccessEmployeeAction.Provider>
    </AdminAccessEmployeeState.Provider>
  );
};

export { AdminAccessEmployeeContextProvider, AdminAccessEmployeeState, AdminAccessEmployeeAction };
