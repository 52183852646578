import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { debounce } from 'lodash';
import { InputLabelProps, useTheme } from '@mui/material';

import { HKG } from '../../../../constants/constants';

import { Configuration } from 'configuration';

import { formatAirportValue, handleFilterList, setInputValue } from '../../../../helpers';

import { useAppSelector } from '../../../../app/hooks';
import { selectConfiguration } from '../../../../slice/configurationSlice';

import { TextInput } from '../../../../components';

const ODInputField = ({
  label,
  inputRef,
  value,
  shrink,
  isOrigin,
  filterODPortList,
  currentFieldIsOrigin,
  setODCurrentFieldIsOrigin,
  setODPreviousFieldIsOrigin,
  currentOrigin,
  currentDestination,
  setCurrentOrigin,
  setCurrentDestination,
}: {
  label: string;
  inputRef: React.RefObject<HTMLInputElement>;
  value: string;
  shrink?: Partial<InputLabelProps> | undefined;
  isOrigin: boolean;
  filterODPortList: (keyword: string, isOriginField?: boolean) => void;
  currentFieldIsOrigin: boolean;
  setODCurrentFieldIsOrigin: (currentFieldIsOrigin: boolean) => void;
  setODPreviousFieldIsOrigin: (isPreviousFieldIsOrigin: boolean) => void;
  currentOrigin: Configuration.Airport | null;
  currentDestination: Configuration.Airport | null;
  setCurrentOrigin: Dispatch<SetStateAction<Configuration.Airport | null>>;
  setCurrentDestination: Dispatch<SetStateAction<Configuration.Airport | null>>;
}) => {
  const theme = useTheme();

  const { airports } = useAppSelector(selectConfiguration);
  const [inputKeyword, setInputKeyword] = useState(value);

  const debounceHandler = debounce((event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    filterODPortList(event.target.value, isOrigin);
  }, 500);

  const onODChange = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputKeyword(event.target.value);
    debounceHandler(event);
  };

  const onODFocus = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>, isOriginField: boolean) => {
    const airport = isOriginField ? currentOrigin : currentDestination;
    const { current } = inputRef;

    !inputKeyword && setInputKeyword(event.target.value);
    setODCurrentFieldIsOrigin(isOriginField);

    setTimeout(() => {
      filterODPortList(formatAirportValue(airport, false), isOriginField);
    }, 300);

    if (current) {
      current.select();
    }
  };

  const onODBlur = (isOriginField: boolean) => {
    const airport = isOriginField ? currentOrigin : currentDestination;
    const { current } = inputRef;

    setODPreviousFieldIsOrigin(currentFieldIsOrigin);
    setODCurrentFieldIsOrigin(false);

    if (!airport && current) {
      setInputValue(current, '');
    }

    if (inputKeyword && airport) {
      setInputKeyword(formatAirportValue(airport));
    }

    const filterResult = handleFilterList(currentOrigin, currentDestination, airports, inputKeyword, isOriginField);

    if (!filterResult || !airport || inputKeyword !== formatAirportValue(airport)) {
      if (isOrigin) {
        setCurrentOrigin?.(null);
      } else {
        setCurrentDestination?.(null);
      }
      setInputKeyword('');
    }

    setTimeout(() => {
      filterODPortList('');
    }, 200);
  };

  useEffect(() => {
    if (isOrigin) {
      if (!currentOrigin && !currentDestination && airports) {
        const defaultOrigin = airports.find((airport: any) => airport.airportCode && airport.airportCode === HKG);

        if (defaultOrigin) {
          setCurrentOrigin(defaultOrigin);

          if (inputRef.current) {
            setInputValue(inputRef.current, formatAirportValue(defaultOrigin));
          }
        }
      }
    }
  }, [airports]);

  // set airport value to the TextInput after selection
  useEffect(() => {
    if (isOrigin) {
      setInputKeyword(formatAirportValue(currentOrigin || null));
    } else {
      setInputKeyword(formatAirportValue(currentDestination || null));
    }
  }, [currentDestination, currentOrigin]);

  // clear useState origin and destination when input cleared
  useEffect(() => {
    if (!inputKeyword) {
      if (isOrigin) {
        setCurrentOrigin(null);
      } else {
        setCurrentDestination(null);
      }
    }
  }, [inputKeyword]);

  // clean up function
  useEffect(() => {
    return () => {
      filterODPortList('');
      setInputKeyword('');
      setCurrentDestination(null);
      setCurrentOrigin(null);
    };
  }, []);

  return (
    <TextInput
      variant="filled"
      label={label}
      color="info"
      value={inputKeyword}
      onChange={onODChange}
      onFocus={(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onODFocus(event, isOrigin);
      }}
      onBlur={() => {
        onODBlur(isOrigin);
      }}
      onKeyPress={(e: any) => {
        if (e.key === 'Enter') {
          e.target.blur();
        }
      }}
      inputRef={inputRef}
      InputLabelProps={shrink}
      autoComplete="off"
      inputProps={{ style: { ...theme.typography.body_1_medium } }}
    />
  );
};

ODInputField.defaultProps = {
  shrink: undefined,
};

export default ODInputField;
