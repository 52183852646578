import React, { useMemo } from 'react';
import { Box, useTheme } from '@mui/material';
import _ from 'lodash';

import en from '../../../translations/en';
import { imgAdminTableArrowRight } from '../../../assets/images';

import { IDependentForAdmin } from '../../../interfaces/admin';

import { BaseTable } from '../../../components';

const DependentsList = ({
  dataList,
  handleRowDataOnClick,
}: {
  dataList: IDependentForAdmin[];
  handleRowDataOnClick: (rowData: any) => void;
}) => {
  const theme = useTheme();
  const fieldLabel = en.admin.feature.dependent;

  const tableSchema = [
    {
      key: 'name',
      isHiddenField: false,
    },
    {
      key: 'relationship',
      isHiddenField: false,
    },
    {
      key: 'age',
      isHiddenField: false,
    },
    {
      key: 'nominationFrom',
      isHiddenField: false,
    },
    {
      key: 'nominationTo',
      isHiddenField: false,
    },
    {
      key: 'nominationStatus',
      isHiddenField: false,
    },
  ];
  const transformedTableHeaders = useMemo(() => {
    return tableSchema.map((item, index) => {
      return {
        label: fieldLabel?.[item.key as keyof typeof fieldLabel] as string,
        sxProps: {
          pb: 0.25,
          flex: index + 1 === tableSchema.length ? 2 : 1,
          fontSize: theme.typography.body_2_light.fontSize,
          display: 'flex',
          alignItems: 'center',
        },
        isHiddenField: item.isHiddenField,
        sortingFunc: null,
      };
    });
  }, [dataList]);

  const transformedTableContents = useMemo(() => {
    const tmpData = _.cloneDeep(dataList || []);
    return tmpData.map((dataItem: any) => {
      return {
        dependentId: dataItem.dependentId,
        sxProps: {
          display: 'flex',
          position: 'relative',
          '&:: after': {
            content: `""`,
            width: '28px',
            height: '28px',
            position: 'absolute',
            right: 0,
            transform: 'translateY(50%)',
            backgroundSize: 'cover',
            backgroundImage: `url(${imgAdminTableArrowRight})`,
          },
        },
        columns: tableSchema.map(({ key, isHiddenField }, index) => {
          const columnSxProps = {};
          let newValue = '';
          const currentValue = dataItem?.[key as keyof IDependentForAdmin];
          newValue = `${currentValue}`;

          return {
            columnkey: key,
            value: newValue,
            sxProps: {
              py: 1.25,
              px: 0.5,
              overflow: 'hidden',
              cursor: 'pointer',
              flex: index + 1 === tableSchema.length ? 2 : 1,
              lineHeight: '22px',
              display: 'flex',
              alignItems: 'center',
              ...columnSxProps,
            },
            isHiddenField,
            columnOnClick: dataItem?.handleColumnOnClick,
          };
        }),
        rowOnClick: handleRowDataOnClick,
      };
    });
  }, [dataList]);

  return (
    <Box
      className="IDependent_list"
      sx={{
        mt: 4,
        width: '100%',
      }}
    >
      <BaseTable
        tableHeaders={transformedTableHeaders}
        tableHeadersSXProps={{ display: 'flex', mt: 2 }}
        tableContents={transformedTableContents}
      />
    </Box>
  );
};

export default DependentsList;
