import React, { useEffect } from 'react';
import { Drawer, Box, IconButton, Typography } from '@mui/material';
import { SAVE_DATA_TO, UI_STYLES } from '../../constants/constants';
import { IBookingFlowOverlayData } from '../../interfaces';
import { CloseIcon, LeftArrowIcon, OverlayBackground } from '../../assets/images';
import { Div100vhView } from '..';
import { useAppSelector } from '../../app/hooks';
import { selectApp } from '../../slice/appSlice';

const DrawerOverlay = ({
  handleClose,
  showOverlay,
  isShowBackArrow = false,
  title,
  component: Component,
  data,
}: {
  handleClose: () => void;
  showOverlay: boolean;
  isShowBackArrow?: boolean;
  title: string;
  component: React.ComponentType<any>;
  data?: IBookingFlowOverlayData;
}) => {
  const isEditSearch: boolean = data?.saveTo === SAVE_DATA_TO.context || false;
  const { isDesktop } = useAppSelector(selectApp) || {};

  const backgroundHeight = `calc(${UI_STYLES.overlayHeaderHeightSearchCriteria} + 24px)`;
  const backgroundSize = `100%`;
  const backgroundPosition = `54% 18%`;

  useEffect(() => {
    if (isDesktop) {
      handleClose?.();
    }
  }, [isDesktop]);

  return (
    <Drawer
      anchor="bottom"
      open={showOverlay}
      onClose={handleClose}
      disablePortal
      sx={{
        '&.MuiDrawer-root': {
          '& .MuiDrawer-paper': {
            width: '100%',
            background: isEditSearch ? 'transparent' : 'auto',
          },
        },
        // zIndex: 1500, // drawer z-index is 1200, need to greater than 1200
      }}
    >
      <Div100vhView
        sx={{
          overflow: 'hidden',
          backgroundColor: 'transparent',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            background: `${
              isEditSearch ? 'linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ),' : ''
            }url(${OverlayBackground})`,
            width: '100%',
            height: isEditSearch ? UI_STYLES.overlayHeaderHeightBookingFlow : backgroundHeight,
            backgroundSize,
            display: 'flex',
            position: 'relative',
            backgroundPosition,
            px: 2,
            pt: 2,
          }}
        >
          {isShowBackArrow && (
            <Box sx={{ mr: 2 }}>
              <IconButton onClick={handleClose} sx={{ p: 0 }}>
                <LeftArrowIcon fill={'#fff'} />
              </IconButton>
            </Box>
          )}
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: isEditSearch ? 'center' : 'start',
                height: '24px',
              }}
            >
              <Typography
                variant={isEditSearch && !data?.isRebookTitle ? 'body_1_medium' : 'title_2_bold'}
                sx={{ color: 'white' }}
              >
                {title}
              </Typography>
            </Box>
          </Box>
          {!isShowBackArrow && (
            <Box sx={{ position: 'absolute', top: '16px', right: '16px' }}>
              <IconButton onClick={handleClose} sx={{ p: 0 }}>
                <CloseIcon fill="white" />
              </IconButton>
            </Box>
          )}
          <Box
            sx={{
              background: isEditSearch ? 'transparent' : 'white',
              position: 'fixed',
              display: 'flex',
              flexDirection: 'column',
              top: UI_STYLES.overlayHeaderHeightSearchCriteria,
              width: '100%',
              left: '0',
              bottom: '0',
              borderTopLeftRadius: '24px',
              borderTopRightRadius: '24px',
            }}
          >
            <Component
              handleBackClick={handleClose}
              handleFooterClick={handleClose}
              handleCloseClick={handleClose}
              {...{
                ...data,
                isShowOverlay: showOverlay,
              }}
            />
          </Box>
        </Box>
      </Div100vhView>
    </Drawer>
  );
};

export default DrawerOverlay;
