/*
  All Flags should included `isActive`.
  true - enable the feature.
  false - disable the feature.
*/

/**
 * Story 2482
 * set true to display for further related development
 */
const prepaidPaymentHistoryCheckingFlag = {
  isActive: false,
};
const retrieveToPayControlFlag = {
  showRetrieveAndPaidCase: false,
  showRetrieveAndPaidFailedCase: false,
};

/**
 * Story 2480
 * set true to setup prepaid
 */
const viewBookingCheckPrepaidFlag = {
  isActive: false, // if true, tune on prepaid flag
};

/**
 * Story 3037
 * set true to show flown suspension alert
 */
const showFlownSuspensionAlertFlag = {
  isActive: false, // if true, open flown suspension popup
  isShowFlownSuspensionFlow: false, // if true, use mock data to control the whole flow
};

const showRefundFlowFlag = {
  isNotCancelledRefund: false, // Booking being cancelled > do refund
  isCancelledRefund: false, // Booking not being cancelled > do refund
};

export {
  prepaidPaymentHistoryCheckingFlag,
  retrieveToPayControlFlag,
  viewBookingCheckPrepaidFlag,
  showFlownSuspensionAlertFlag,
  showRefundFlowFlag,
};
