import React from 'react';
import { Box, Typography, useTheme, Accordion, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { NOMINATED_PASSENGER_AGE_TYPE } from '../../../constants/constants';
import en from '../../../translations/en';

import { INominatedPassenger } from '../../../interfaces';

import { getDisplayName, isSomeTravellerAvailable } from '../../../helpers';

import LtAccompanyForm from './LtAccompanyForm';
import LtTravellerQuotaElements from './LtTravellerQuotaElements';
import LtTravellerWarning from './LtTravellerWarning';

type Props = {
  ltTravellers: INominatedPassenger[];
  isRequireAccompanyForm: boolean;
  isShowForm: boolean;
};

const LtOnlyChildTravellerForm = (props: Props) => {
  const theme = useTheme();
  const { infantWithoutSeat, infantWithSeat } = en.booking.travelType.leisureTravel;
  const { label, limitQuotaRestriction } = en.booking.travelType.leisureTravel.quotaElement;
  return (
    <>
      <Box
        sx={
          props.isShowForm
            ? {
                minHeight: '76px',
                mt: 1,
                p: 2,
                borderRadius: 1,
                background: theme.palette.bgColor.main,
                border: `1px solid ${theme.color.secondary.slate.option_3}`,
                cursor: 'default',
              }
            : null
        }
      >
        {props.ltTravellers &&
          props.ltTravellers.map((ltTraveller: INominatedPassenger, travellerIndex: number) => {
            const { title, firstName, lastName, relationship, validationInfo, quotaInfo } = ltTraveller || {};

            const isDisabled = !isSomeTravellerAvailable(ltTraveller);

            const isInfant = validationInfo?.ageType === NOMINATED_PASSENGER_AGE_TYPE.infant;

            const isInfantWithoutSeat = ltTraveller.infantWithSeat === false;

            return (
              <Box
                key={travellerIndex}
                sx={
                  props.isShowForm
                    ? {
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                      }
                    : {
                        display: 'flex',
                        alignItems: 'center',
                        minHeight: '76px',
                        p: 2,
                        mt: 1,
                        borderRadius: 1,
                        background: isDisabled
                          ? theme.color.secondary.light_slate.option_6
                          : theme.palette.bgColor.main,
                        border: isDisabled ? 'none' : `1px solid ${theme.color.secondary.slate.option_3}`,
                        cursor: isDisabled ? 'pointer' : 'default',
                      }
                }
              >
                <Accordion
                  disableGutters={true}
                  sx={{
                    width: '100%',
                    boxShadow: 'none',
                    background: 'none',
                    p: 0,
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      validationInfo?.restriction?.length > 0 ? (
                        <ExpandMoreIcon
                          sx={{
                            color: theme.color.secondary.dark_grey.option_3,
                          }}
                        />
                      ) : null
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{ cursor: isDisabled ? 'pointer' : 'default' }}
                    sx={{
                      p: 0,
                      m: 0,
                      cursor: isDisabled ? 'pointer' : 'default',
                      '.MuiAccordionSummary-content': {
                        my: 0,
                        mr: 0.5,
                        display: 'flex',
                        alignItems: 'center',
                        cursor: isDisabled ? 'pointer' : 'default',
                      },
                      '.Mui-expanded': {
                        my: 0,
                        minHeight: 0,
                      },
                      '&.Mui-focusVisible': {
                        background: 'none',
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flex: 1,
                        flexDirection: 'column',
                        ...(props.isShowForm && {
                          pt: travellerIndex == 0 ? 0 : 2,
                          ...(props.ltTravellers.length - 1 > travellerIndex && {
                            pb: 2,
                            borderBottom: `1px solid ${theme.color.etp.grey}`,
                          }),
                        }),
                      }}
                    >
                      <Typography
                        variant="body_1_regular"
                        color={
                          (theme.color.secondary.dark_grey.option_1,
                          isDisabled ? theme.color.secondary.grey.option_3 : theme.color.secondary.dark_grey.option_1)
                        }
                      >
                        {getDisplayName({
                          title,
                          firstName,
                          lastName,
                        })}
                      </Typography>
                      <Typography
                        variant="navigation_regular"
                        color={
                          (theme.color.secondary.grey.option_2,
                          isDisabled ? theme.color.secondary.grey.option_3 : theme.color.secondary.grey.option_2)
                        }
                        sx={{ my: 0.5 }}
                      >
                        {isInfant && relationship + ' / ' + (isInfantWithoutSeat ? infantWithoutSeat : infantWithSeat)}

                        {!isInfant && relationship}
                      </Typography>
                      {quotaInfo && quotaInfo?.unused !== 0 && (
                        <>
                          <LtTravellerQuotaElements
                            {...{
                              ...quotaInfo,
                              label,
                            }}
                          />

                          {quotaInfo?.booked >= quotaInfo?.unused && (
                            <LtTravellerWarning
                              overrideStyle={{
                                marginTop: 1,
                              }}
                              warningMessage={limitQuotaRestriction}
                            />
                          )}
                        </>
                      )}
                    </Box>
                  </AccordionSummary>
                </Accordion>
              </Box>
            );
          })}
        {props.isShowForm && <LtAccompanyForm isRequireAccompanyForm={props.isRequireAccompanyForm} />}
      </Box>
    </>
  );
};
export default LtOnlyChildTravellerForm;
