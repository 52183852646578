import { Grid, Typography, useTheme } from '@mui/material';
import { NoBookingIcon } from '../../assets/images';

const EmptyContent = ({ title }: { title: string }) => {
  const theme = useTheme();

  return (
    <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ mt: 2 }}>
      <NoBookingIcon />
      <Typography
        variant="body_1_regular"
        color={theme.color.secondary.grey.option_3}
        sx={{ mt: 1.625, textAlign: 'center' }}
      >
        {title}
      </Typography>
    </Grid>
  );
};

export default EmptyContent;
