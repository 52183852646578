import { useState, useContext } from 'react';

import { AdminAccessEmployeeState } from '../../../context';

import { NotesDetail, NotesList, NotesPopup } from './';

const AdminNotes = () => {
  const [isPopupActive, setIsPopupActive] = useState<boolean>(false);
  const { focusNote } = useContext(AdminAccessEmployeeState);

  return (
    <>
      {focusNote ? (
        <NotesDetail {...focusNote} setIsPopupActive={setIsPopupActive} />
      ) : (
        <NotesList setIsPopupActive={setIsPopupActive} />
      )}
      {isPopupActive && <NotesPopup setIsPopupActive={setIsPopupActive} />}
    </>
  );
};

export default AdminNotes;
