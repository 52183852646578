import React from 'react';
import { Grid, Box, Button, Typography, Icon, useTheme } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';

import { useAppSelector } from '../../app/hooks';
import { selectUser } from '../../slice/userSlice';

import { CompanyLogo } from '../../assets/images';

import { ETPLink } from '..';
import { UI_STYLES, USER_ROLE } from '../../constants/constants';
import { ROOT_PATHS, INFO_PATHS, ADMIN_PATHS } from '../../constants/paths';

import { RoleSelectionPopover } from '../../containers';

import en from '../../translations/en';

const NavBar = ({ isShowNavBar, handleCloseOverlay }: { isShowNavBar?: boolean; handleCloseOverlay?: () => void }) => {
  const history = useHistory();

  const theme = useTheme();

  const isHomePage = isShowNavBar ?? useLocation().pathname === ROOT_PATHS.landing;
  const { role } = useAppSelector(selectUser) || {};

  const isAdminRole = role.type === USER_ROLE.admin;
  const isDelegationRole = role.type === USER_ROLE.delegation;

  const color = isHomePage ? 'white' : theme.color.secondary.dark_grey.option_2;
  const logoColor = isHomePage ? 'white' : theme.palette.cathayJade.main;
  const fontWeight = isHomePage ? 'medium' : 'regular';
  const fontSize = '16px';
  const logoHeight = '28px';
  const textStyle = { color, fontSize, fontWeight };
  const style = isHomePage
    ? {
        background: 'transparent',
        px: 13,
        height: UI_STYLES.homepageNavBarHeight,
        alignItems: 'center',
        fontWeight: 'medium',
      }
    : {
        background: 'white',
        px: 22,
        boxShadow: 0,
        borderBottom: 1,
        borderColor: isAdminRole || isDelegationRole ? 'white' : theme.color.secondary.dark_grey.option_7,
        height: UI_STYLES.navBarHeight,
        alignItems: 'center',
      };

  return (
    <Grid container alignItems="middle" justifyContent="space-between" direction="row" sx={style}>
      <Grid
        item
        container
        direction="row"
        alignItems="middle"
        component={ETPLink}
        to="/"
        theme={theme}
        sm={3}
        onClick={() => {
          handleCloseOverlay?.();
        }}
      >
        <Grid item>
          <Icon sx={{ mr: 2, height: logoHeight }}>
            <CompanyLogo fill={logoColor} width="23px" height={logoHeight} />
          </Icon>
        </Grid>
        <Grid component={Typography} sx={{ ...textStyle, lineHeight: '30px', fontWeight: 'medium' }}>
          {en.common.applicationName}
        </Grid>
      </Grid>
      <Grid item flex="auto" />
      <Box component={Grid} item display={{ xs: 'none', sm: 'block' }} sx={{ pr: '90px' }}>
        <Button
          sx={{ ...textStyle, mx: 1 }}
          onClick={() => {
            history.push(ROOT_PATHS.landing);
            handleCloseOverlay?.();
          }}
        >
          {en.menu.home}
        </Button>
        {!isAdminRole && (
          <Button
            sx={{ ...textStyle, mx: 1 }}
            onClick={() => {
              history.push(INFO_PATHS.info);
            }}
          >
            {en.menu.info}
          </Button>
        )}
        {isAdminRole && (
          <Button
            sx={{ ...textStyle, mx: 1 }}
            onClick={() => {
              history.push(ADMIN_PATHS.adminGuide);
            }}
          >
            {en.menu.guide}
          </Button>
        )}
      </Box>
      <Grid item>
        <RoleSelectionPopover isHomePage={isHomePage} />
      </Grid>
    </Grid>
  );
};

export default NavBar;
