import React, { useState } from 'react';
import { ToggleButton, Typography, Theme, useTheme, SxProps, MenuList, MenuItem } from '@mui/material';

import { USER_ROLE } from '../../../../constants/constants';

import { IButtonDetails } from '../../../../interfaces';
import { useAppSelector } from '../../../../app/hooks';
import { selectUser } from '../../../../slice/userSlice';

interface IConcessionListContainerProps {
  defaultVal?: any;
  options: IButtonDetails[];
  onChange: (value: any) => void;
  customButtonStyle?: SxProps<Theme>;
}

const ConcessionListContainer = ({ defaultVal: defaultId, options, onChange }: IConcessionListContainerProps) => {
  const [selectedId, setSelectedId] = useState<string>(defaultId);
  const { role } = useAppSelector(selectUser) || {};

  const isAdminRole = role.type === USER_ROLE.admin;

  const handleChange = (event: React.MouseEvent<HTMLElement>, value: any) => {
    if (value) {
      setSelectedId(value.id);
      onChange(value);
    }
  };

  const theme = useTheme();

  return (
    <MenuList
      autoFocusItem={!isAdminRole}
      sx={{ mx: 1 }}
      onKeyDown={(event: React.KeyboardEvent) => {
        if (event.key === 'Tab') {
          // TODO:// click tab
        }
      }}
    >
      {options.map((option) => {
        const {
          id,
          val,
          title,
          subtitle,
          isDisabled = false,
          disabledSubtitle,
          customSubtitleStyle,
          expandedComponent,
        } = option || {};

        const isCurrentSelected = id === selectedId;
        const isShowError = isDisabled && disabledSubtitle;
        const displaySubtitle = isShowError ? disabledSubtitle : subtitle;

        return (
          <ToggleButton
            key={id}
            component={MenuItem}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
              boxShadow: theme.palette.boxShadow.main,
              border: 'transparent',
              my: 2,
              px: 2,
              background: 'white',
              '&.Mui-focusVisible': {
                background: theme.palette.bgColor.light,
                '&.Mui-selected, &.Mui-selected:hover': {
                  background: theme.palette.bgColor.light,
                },
              },
              '&:not(:first-of-type), &:first-of-type': {
                borderRadius: 1,
                border: 0,
                borderTopColor: theme.palette.borderColor.main,
                boxShadow: theme.palette.boxShadow.dark,
              },
              '&:not(:first-of-type):hover, &:first-of-type:hover': {
                background: theme.palette.bgColor.light,
              },
              '&.Mui-selected, &.Mui-selected:hover': {
                color: theme.color.secondary.dark_grey.option_1,
                border: `2px solid ${theme.color.utility.link.option_3}`,
                background: theme.palette.bgColor.light,
                boxShadow: theme.boxShadow.selected,
              },
            }}
            value={{ id, val, displaySubtitle }}
            disabled={isDisabled}
            selected={isCurrentSelected}
            onChange={handleChange}
          >
            <Typography
              component="div"
              variant="body_1_regular"
              color={theme.color.secondary.dark_grey.option_1}
              align="left"
              sx={{ pt: 0.125, whiteSpace: 'pre-line' }}
            >
              {title}
            </Typography>

            {isCurrentSelected && expandedComponent
              ? expandedComponent
              : displaySubtitle && (
                  <Typography
                    variant="navigation_medium"
                    color={isShowError ? theme.color.utility.error.option_2 : theme.color.utility.link.option_2}
                    sx={{
                      backgroundColor: theme.color.secondary.light_slate.option_4,
                      borderRadius: 2,
                      py: 0.5,
                      px: 1.5,
                      mt: 0.75,
                      ...customSubtitleStyle,
                    }}
                  >
                    {displaySubtitle}
                  </Typography>
                )}
          </ToggleButton>
        );
      })}
    </MenuList>
  );
};

export default ConcessionListContainer;
