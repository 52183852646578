import React from 'react';
import { Grid, Box, Typography, useTheme, Divider, Tooltip } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import _ from 'lodash';

import { BookingDB } from 'database';
import {
  LABEL_CATEGORY,
  DATE_FORMAT,
  TRAVEL_PURPOSE,
  CARRIER_OPTION,
  BOOKING_STATUS,
  BOOKING_UNUC_TYPE,
  USER_ROLE,
  NOMINATED_PASSENGER_AGE_TYPE,
} from '../../../constants/constants';

import en from '../../../translations/en';

import {
  CompanyLogo,
  TakeOffIcon,
  DutyTravelIcon,
  DutyTravelDisabledIcon,
  LeisureTravelIcon,
  LeisureTravelDisabledIcon,
} from '../../../assets/images';

import {
  formatDateAsString,
  calcStops,
  getSeatClass,
  getDisplayLabel,
  formatTicketNumForDisplay,
  getCabinClassColor,
  getPassengerDisplayName,
  isFlightInfoGreyOut,
  isEmptyOrUndefined,
  getDisplayVal,
  isOWOALBooking,
} from '../../../helpers';

import { Pnr } from 'booking';
import { Configuration } from 'configuration';
import { BOOKING_LAYOUT_VARIANT } from '../../MyBooking/BookingLayout';

import { useAppSelector } from '../../../app/hooks';

import { selectConfiguration } from '../../../slice/configurationSlice';
import { selectApp } from '../../../slice/appSlice';
import { selectUser } from '../../../slice/userSlice';

import { LeisureTravellerCount, Lounge } from '../..';

// ETP-2445 => ETP-2473
const Dot = ({ isDisabled }: { isDisabled: boolean }) => {
  const theme = useTheme();

  return (
    <CircleIcon
      style={{
        color: isDisabled ? theme.color.secondary.dark_grey.option_8 : theme.color.cabin_class.economy.option_8,
      }}
      sx={{ fontSize: 5 }}
    />
  );
};

const DottedLine = ({ isDisabled }: { isDisabled: boolean }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', flex: 1 }}>
      <Dot isDisabled={isDisabled} />
      <Dot isDisabled={isDisabled} />
      <Dot isDisabled={isDisabled} />
      <Dot isDisabled={isDisabled} />
      <Dot isDisabled={isDisabled} />
    </Box>
  );
};

const Passenger = ({ tickets, labels }: { tickets: Pnr.TicketByVersion[]; labels: Configuration.CodeLabel[] }) => {
  const theme = useTheme();

  if (!tickets || tickets.length === 0) {
    return <></>;
  }
  const ticketsData = tickets.slice().sort((a: any, b: any) => {
    return a.latestTicket.ticketNum - b.latestTicket.ticketNum;
  });

  return (
    <Box>
      {ticketsData.map((ticket, index) => {
        const accompanyAdult = ticketsData.find(
          (item) =>
            ticket.latestTicket.paxObj.type === NOMINATED_PASSENGER_AGE_TYPE.infant &&
            item.latestTicket.paxObj.paxRefNum === ticket.latestTicket.paxObj.paxRefNum &&
            item.latestTicket.paxObj.type !== NOMINATED_PASSENGER_AGE_TYPE.infant,
        );
        return (
          <Box
            key={index}
            sx={{
              display: 'flex',
              alignItems: 'start',
              minHeight: '64px',
              py: 1,
            }}
          >
            {/* Index */}
            <Box
              width={24}
              height={24}
              sx={{
                borderRadius: '50%',
                backgroundColor: theme.color.secondary.light_slate.option_4,
                mt: 1.25,
                mr: 1.5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography
                variant="navigation_medium"
                color={theme.color.secondary.dark_grey.option_3}
                textAlign="center"
              >
                {index + 1}
              </Typography>
            </Box>

            {/* Tickets */}
            <Box display="flex" flex="1" flexDirection="column">
              {/* Name */}
              <Typography
                variant="body_1_regular"
                sx={{
                  color: theme.color.secondary.dark_grey.option_1,
                }}
              >
                {getPassengerDisplayName(ticket.latestTicket.paxObj)}
              </Typography>
              {ticket.latestTicket.ticketNum && (
                <Box>
                  <Typography variant="body_2_regular" color={theme.color.secondary.dark_grey.option_4}>
                    {formatTicketNumForDisplay(ticket.latestTicket.ticketNum)}
                  </Typography>

                  <Lounge lounge={ticket.latestTicket.lounge} labels={labels} />
                </Box>
              )}
              {(ticket.latestTicket.paxObj.type === NOMINATED_PASSENGER_AGE_TYPE.infant ||
                ticket.latestTicket.paxObj.type === NOMINATED_PASSENGER_AGE_TYPE.infantWithSeat) && (
                <Box
                  sx={{
                    overflow: 'hidden',
                    color: theme.color.secondary.grey.option_2,
                  }}
                >
                  <Typography sx={{ lineHeight: '24px' }} variant="navigation_regular">
                    {accompanyAdult
                      ? en.booking.travelType.leisureTravel.infantWithoutSeat
                      : en.booking.travelType.leisureTravel.infantWithSeat}
                  </Typography>
                  {accompanyAdult && (
                    <Typography sx={{ lineHeight: '24px' }} variant="navigation_regular">
                      {`${en.booking.confirmation.associatedWith} ${getPassengerDisplayName(
                        accompanyAdult.latestTicket.paxObj,
                      )}`}
                    </Typography>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

const FlightInfoBySegment = ({
  index,
  segment,
  type,
  recLoc,
  isViewBookingDetails,
  showPax,
  labels,
  bookingStatus,
  unUcType,
  isOriginalFlightForUnUc,
  variant,
  concessionName,
  travellerCount,
}: {
  index: number;
  segment: Pnr.TicketBySegment;
  concessionName: string;
  type: string;
  recLoc: string;
  isViewBookingDetails?: boolean;
  showPax?: boolean;
  labels: Configuration.CodeLabel[];
  bookingStatus: string;
  unUcType: string;
  isOriginalFlightForUnUc?: boolean;
  variant?: BOOKING_LAYOUT_VARIANT;
  travellerCount: number;
}) => {
  if (!segment) {
    return <></>;
  }

  const theme = useTheme();
  const { isDesktop } = useAppSelector(selectApp) || {};
  const isDT = type === TRAVEL_PURPOSE.employeeDutyTravel;

  // is from myBooking/adminBooking page & isDesktop view
  const isMyBookingDesktop =
    isDesktop &&
    (variant === BOOKING_LAYOUT_VARIANT.MY_BOOKING_PAGE || variant === BOOKING_LAYOUT_VARIANT.ADMIN_BOOKING_PAGE);

  const isDisabled = isFlightInfoGreyOut({
    bookingStatus,
    unUcType,
    isViewBookingDetails,
    isOriginalFlightForUnUc,
  });

  const {
    aircraftType,
    arrivalPort,
    arrivalTime,
    bookingParentClass,
    carrier = '',
    departurePort,
    departureDate = '',
    departureTime,
    flightNum,
    numOfStops = 0,
    status,
  } = segment;

  const [
    companyLogoColor,
    textColor,
    dateTextColor,
    timeTextColor,
    cabinClassColor,
    bookingReferenceTextColor,
    recLocTextColor,
  ] = isDisabled
    ? [
        theme.color.primary.cathay_jade.option_6,
        isViewBookingDetails ? theme.color.secondary.grey.option_3 : theme.color.secondary.grey.option_4,
        isViewBookingDetails ? theme.color.secondary.grey.option_3 : theme.color.secondary.grey.option_4,
        isViewBookingDetails ? theme.color.secondary.grey.option_3 : theme.color.secondary.grey.option_4,
        theme.color.secondary.grey.option_4,
        theme.color.secondary.grey.option_4,
        theme.color.secondary.grey.option_4,
      ]
    : [
        theme.color.primary.cathay_jade.option_3,
        theme.color.secondary.dark_grey.option_3,
        theme.color.secondary.dark_grey.option_2,
        'black',
        getCabinClassColor(bookingParentClass),
        theme.color.secondary.grey.option_3,
        theme.color.secondary.dark_grey.option_1,
      ];

  const isOWOALFlight = isOWOALBooking(flightNum);
  const isPrepaidBookingCancelled =
    bookingStatus === BOOKING_STATUS.refunded || bookingStatus === BOOKING_STATUS.notRefund;

  const isHideFlightNum = isOWOALFlight || isPrepaidBookingCancelled;

  const isShowFlightTime = (): boolean => {
    if (isPrepaidBookingCancelled) {
      // if booking is prepaid and cancelled, not show departure & arrival time
      return false;
    } else {
      // owoal flight not show
      return !!isViewBookingDetails && !isOWOALFlight;
    }
  };

  const segmentStatus = getDisplayLabel(labels, LABEL_CATEGORY.segmentStatus, status);

  const FlightNumber = () => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="body_1_regular" color={textColor}>
          {carrier}
          {isHideFlightNum ? '' : flightNum}
        </Typography>
      </Box>
    );
  };

  const FlightDate = () => {
    return (
      <Typography
        variant={unUcType === BOOKING_UNUC_TYPE.protected ? 'body_1_bold' : 'body_1_medium'}
        sx={{
          color: unUcType === BOOKING_UNUC_TYPE.protected ? theme.color.utility.link.option_3 : dateTextColor,
        }}
      >
        {isHideFlightNum
          ? en.booking.confirmation.ticketOnly
          : formatDateAsString(departureDate, DATE_FORMAT.ddmmmyyyy, DATE_FORMAT.date)}
      </Typography>
    );
  };

  const SeatClass = () => {
    return (
      <>
        <Grid container mt={1} alignItems="center">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              flex: 1,
            }}
          >
            <Typography
              variant={
                isViewBookingDetails ? 'title_2_regular' : isMyBookingDesktop ? 'title_1_bold' : 'title_1_medium'
              }
              color={textColor}
            >
              {departurePort}
            </Typography>
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                maxWidth: '232px',
                mx: 2,
              }}
            >
              <DottedLine isDisabled={isDisabled} />
              <TakeOffIcon
                fill={cabinClassColor}
                width="32px"
                height="32px"
                style={{ marginLeft: '8px', marginRight: '8px' }}
              />
              <DottedLine isDisabled={isDisabled} />
            </Box>

            <Typography
              variant={
                isViewBookingDetails ? 'title_2_regular' : isMyBookingDesktop ? 'title_1_bold' : 'title_1_medium'
              }
              color={textColor}
            >
              {arrivalPort}
            </Typography>
          </Box>
        </Grid>

        {!isHideFlightNum && (
          <Grid container alignItems="center" mt={-1} sx={{ height: '40px' }}>
            <Grid item xs={3} flexDirection="column">
              {isShowFlightTime() && (
                <Typography variant="large_title_medium" color={timeTextColor} sx={{ mt: 1 }}>
                  {departureTime}
                </Typography>
              )}
            </Grid>
            <Grid item xs={6} flexDirection="column" textAlign="center" gap={1}>
              {isViewBookingDetails ? (
                <Typography
                  variant="body_2_medium"
                  color={theme.color.secondary.grey.option_3}
                  sx={{ mt: 1, height: '18px' }}
                >
                  {!isOWOALFlight ? `${calcStops(numOfStops)} ${aircraftType || ''}` : ''}
                </Typography>
              ) : (
                <Typography variant="body_2_medium" color={cabinClassColor} sx={{ mt: 1, height: '18px' }}>
                  {getSeatClass(bookingParentClass)}
                </Typography>
              )}
            </Grid>
            <Grid item xs={3} textAlign="right">
              {isShowFlightTime() && (
                <Typography variant="large_title_medium" color={timeTextColor} sx={{ mt: 1 }}>
                  {arrivalTime}
                </Typography>
              )}
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  const BookingReference = () => {
    return isOWOALFlight ? (
      // if owoal, not show
      <Box sx={{ height: '42px' }}></Box>
    ) : (
      <>
        <Typography color={bookingReferenceTextColor} variant="body_2_regular">
          {variant !== BOOKING_LAYOUT_VARIANT.ADMIN_BOOKING_PAGE
            ? en.booking.confirmation.bookingReference
            : en.booking.confirmation.bookingRef}
        </Typography>

        <Typography
          variant="body_1_medium"
          sx={{
            mt: variant === BOOKING_LAYOUT_VARIANT.ADMIN_BOOKING_PAGE ? 1 : 0,
            color: recLocTextColor,
          }}
        >
          {getDisplayVal(recLoc)}
        </Typography>
      </>
    );
  };

  const Concession = () => {
    return (
      <>
        <Typography color={bookingReferenceTextColor} variant="body_2_regular">
          {en.booking.confirmation.concession}
        </Typography>

        <Tooltip title={concessionName}>
          <Typography
            variant="body_1_medium"
            sx={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              color: recLocTextColor,
              mt: variant === BOOKING_LAYOUT_VARIANT.ADMIN_BOOKING_PAGE ? 1 : 0,
            }}
          >
            {concessionName}
          </Typography>
        </Tooltip>
      </>
    );
  };

  const BookingStatus = ({ statusFlag }: { statusFlag: boolean }) => {
    const isRetrieveOrnotRefundOrRefunded =
      bookingStatus === BOOKING_STATUS.retrieveToPay ||
      bookingStatus === BOOKING_STATUS.notRefund ||
      bookingStatus === BOOKING_STATUS.refunded;
    const isGreyColor =
      bookingStatus === BOOKING_STATUS.cancelled ||
      bookingStatus === BOOKING_STATUS.notRefund ||
      bookingStatus === BOOKING_STATUS.refunded ||
      bookingStatus === BOOKING_STATUS.original; // etp-2915 hardcode

    const bookingStatusBackground = {
      cancelled: theme.bookingStatus.background.cancelled || '',
      flown: theme.bookingStatus.background.flown || '',
      retrieveToPay: theme.color.cabin_class.first.option_7 || '',
      rebook: theme.color.cabin_class.first.option_7 || '',
      refund: theme.color.secondary.sand.option_5 || '',
    };

    const getStatusColor = (isGreyColor: boolean, bookingStatus: string | null): string | null => {
      if (isGreyColor) {
        return bookingStatusBackground.cancelled;
      }

      if (bookingStatus === BOOKING_STATUS.flown) {
        return bookingStatusBackground.flown;
      }

      if (bookingStatus === BOOKING_STATUS.retrieveToPay || bookingStatus === BOOKING_STATUS.rebook) {
        return bookingStatusBackground.retrieveToPay;
      }

      if (bookingStatus === BOOKING_STATUS.refunded) {
        return bookingStatusBackground.refund;
      }

      return null;
    };

    return (
      <Box>
        {statusFlag && (
          <Box
            sx={{
              background: getStatusColor(isGreyColor, bookingStatus),
              width: isRetrieveOrnotRefundOrRefunded ? '115px' : '86px',
              height: '26px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '4px',
            }}
          >
            <Typography
              color={
                isMyBookingDesktop ? theme.color.secondary.dark_grey.option_2 : theme.color.secondary.dark_grey.option_1
              }
              variant={isMyBookingDesktop ? 'body_2_medium' : 'navigation_medium'}
            >
              {bookingStatus === BOOKING_STATUS.original
                ? 'Original' // // etp-2915 hardcode
                : bookingStatus === BOOKING_STATUS.rebook
                ? 'Rebook' // // etp-2915 hardcode
                : getDisplayLabel(labels, LABEL_CATEGORY.bookingStatus, bookingStatus)}
            </Typography>
          </Box>
        )}
      </Box>
    );
  };

  const TravelTypeIcon = () => {
    return (
      <>
        {isDT ? (
          isDisabled ? (
            <DutyTravelDisabledIcon />
          ) : (
            <DutyTravelIcon />
          )
        ) : isDisabled ? (
          <LeisureTravelDisabledIcon />
        ) : (
          <LeisureTravelIcon />
        )}
      </>
    );
  };

  const PassengerCount = ({ count }: { count: number }) => {
    return (
      <Box>
        <Typography color={bookingReferenceTextColor} variant="body_2_regular">
          {en.booking.flightConfirmation.LTFareDetails.noOfPassengers}
        </Typography>

        <Typography
          variant="body_1_medium"
          sx={{
            color: recLocTextColor,
          }}
        >
          {count}
        </Typography>
      </Box>
    );
  };

  const { role } = useAppSelector(selectUser) || {};
  const isAdminRole = role.type === USER_ROLE.admin;
  const statusFlag =
    bookingStatus === BOOKING_STATUS.cancelled ||
    bookingStatus === BOOKING_STATUS.flown ||
    bookingStatus === BOOKING_STATUS.retrieveToPay ||
    bookingStatus === BOOKING_STATUS.modified ||
    bookingStatus === BOOKING_STATUS.notRefund ||
    // etp-2915 hardcode
    bookingStatus === BOOKING_STATUS.original ||
    bookingStatus === BOOKING_STATUS.rebook ||
    bookingStatus === BOOKING_STATUS.refunded;

  return isMyBookingDesktop ? (
    <Grid
      container
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      {isAdminRole ? (
        // Admin booking list UI
        <>
          {/* carrier & flight time */}
          <Grid item sm={1.75} pl={1}>
            <FlightNumber />
            <FlightDate />
          </Grid>

          {/* od & seatClass */}
          <Grid item sm={4}>
            <SeatClass />
          </Grid>

          {/* booking reference */}
          <Grid item sm={2} pl={5}>
            <BookingReference />
          </Grid>
          <Grid item sm={2} pr={1}>
            <Concession />
          </Grid>

          {/* status & icon */}
          <Grid item sm={0} display="flex" justifyContent="flex-end">
            <Box pr={2}>
              <BookingStatus statusFlag={statusFlag} />
            </Box>
            <TravelTypeIcon />
          </Grid>
        </>
      ) : (
        // My booking list UI
        <>
          {/* carrier & flight time */}
          <Grid item sm={2} pl={1.25}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              {carrier === CARRIER_OPTION.CX && (
                <CompanyLogo
                  width="16px"
                  height="16px"
                  style={{
                    paddingRight: 6,
                    fill: companyLogoColor,
                  }}
                />
              )}
              <FlightNumber />
            </Box>

            <FlightDate />
          </Grid>

          {/* od & seatClass */}
          <Grid item sm={4}>
            <SeatClass />
          </Grid>

          {/* booking reference */}
          <Grid item sm={2} pl={3}>
            <BookingReference />
          </Grid>

          {/* no of passenger */}
          <Grid item sm={2} pl={3}>
            <PassengerCount count={travellerCount} />
          </Grid>

          {/* status & icon */}
          <Grid item sm={2} display="flex" justifyContent="flex-end">
            <Box pr={3}>
              <BookingStatus statusFlag={statusFlag} />
            </Box>
            <TravelTypeIcon />
          </Grid>
        </>
      )}
    </Grid>
  ) : (
    <Grid container mt={index > 0 ? 6 : 0}>
      {/* Header */}
      <Grid container alignItems="center" sx={{ mb: isHideFlightNum ? 2.5 : 0 }}>
        <Grid
          item
          xs={8.5}
          alignItems="center"
          sx={{
            display: 'flex',
            alignItems: 'center',
            mt: -1,
          }}
        >
          {carrier === CARRIER_OPTION.CX && (
            <CompanyLogo
              width="16px"
              height="16px"
              style={{
                paddingRight: 6,
                fill: companyLogoColor,
              }}
            />
          )}
          <>
            <FlightNumber />
            {!isEmptyOrUndefined(flightNum) && (
              <Box
                sx={{
                  width: '4px',
                  height: '4px',
                  borderRadius: '50%',
                  bgcolor: theme.color.secondary.dark_grey.option_6,
                  float: 'right',
                  m: 1,
                }}
              />
            )}
            <FlightDate />
          </>
        </Grid>
        <Grid item xs={3.5} textAlign="right" sx={{ display: 'flex', justifyContent: 'end' }}>
          <LeisureTravellerCount
            customStyles={{
              height: '28px',
              width: '46px',
              justifyContent: 'space-between',
              mr: 1,
            }}
            handleClick={() => {}}
            travellerCount={travellerCount}
            isShowOnFlightCard={true}
          />

          <TravelTypeIcon />
        </Grid>
      </Grid>

      {/* Content */}
      <Grid container>
        <SeatClass />
        <Grid container mt={isHideFlightNum ? 3.25 : 2}>
          <Grid container>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: statusFlag ? 'space-between' : 'end',
              }}
            >
              {statusFlag && <BookingStatus statusFlag={statusFlag} />}

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'right',
                }}
              >
                {isViewBookingDetails ? (
                  <>
                    <Typography color={textColor} variant="body_2_regular">
                      {getSeatClass(bookingParentClass)}
                    </Typography>

                    <Typography
                      variant="body_1_bold"
                      color={
                        isDisabled
                          ? textColor
                          : segmentStatus.toLowerCase().includes('confirmed')
                          ? theme.color.primary.medium_jade.option_3
                          : theme.color.secondary.dark_grey.option_1
                      }
                    >
                      {/* etp-2976 for test */}
                      {bookingStatus === BOOKING_STATUS.original ? en.common.empty : segmentStatus}
                    </Typography>
                  </>
                ) : (
                  <BookingReference />
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      {/* Passenger */}
      {showPax && (
        <>
          <Divider
            sx={{
              width: '100%',
              my: 2,
            }}
          />
          <Box
            sx={{
              maxHeight: isDesktop ? '198px' : 'auto',
              width: '100%',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: 4,
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'transparent',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.color.utility.unselectable.option_3,
              },
            }}
          >
            <Passenger tickets={segment.tickets} labels={labels} />
          </Box>
        </>
      )}
    </Grid>
  );
};

const FlightInfo = ({
  bookingStatus,
  recLoc,
  type,
  ticketsBySegment,
  isViewBookingDetails,
  showPax,
  unUcType,
  isOriginalFlightForUnUc,
  variant,
  concessionName,
  etpPassengerList,
}: {
  bookingStatus?: string;
  recLoc?: string;
  type?: string;
  concessionName?: string;
  ticketsBySegment: Pnr.TicketBySegment[];
  isViewBookingDetails?: boolean;
  showPax?: boolean;
  unUcType?: string;
  isOriginalFlightForUnUc?: boolean;
  variant?: BOOKING_LAYOUT_VARIANT;
  etpPassengerList: BookingDB.EtpPassenger[];
}) => {
  const { configurations } = useAppSelector(selectConfiguration);

  // use etpPassengerList to calc traveller count
  // need to filter out isRemoved pax
  const clonedEtpPassengerList = _.cloneDeep(etpPassengerList) || [];
  const travellerCount = _.remove(clonedEtpPassengerList, (pax) => !pax.isRemoved).length || 1;

  return (
    <>
      {ticketsBySegment?.map((segment: Pnr.TicketBySegment, index) => {
        return (
          <FlightInfoBySegment
            key={index}
            index={index}
            segment={segment}
            concessionName={concessionName || ''}
            recLoc={recLoc || ''}
            type={type || ''}
            isViewBookingDetails={isViewBookingDetails}
            showPax={showPax}
            labels={configurations.labels}
            bookingStatus={bookingStatus || ''}
            unUcType={unUcType || ''}
            isOriginalFlightForUnUc={isOriginalFlightForUnUc}
            variant={variant}
            travellerCount={travellerCount}
          />
        );
      })}
    </>
  );
};

FlightInfo.defaultProps = {
  isViewBookingDetails: true,
};

export default FlightInfo;
