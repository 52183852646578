import { useEffect } from 'react';

import { ROOT_PATHS } from '../../constants/paths';
import en from '../../translations/en';

import { selectAuth } from '../../slice/authSlice';
import { selectPaypal, reset as resetPaypalSlice } from '../../slice/paypalSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setSnackBarData } from '../../slice/appSlice';
import { getProfileThunk } from '../../slice/userSlice';

import { createBillingAgreementId } from '../../services/paypal';

const PayPalSubscription = () => {
  const dispatch = useAppDispatch();

  const { ern } = useAppSelector(selectAuth) || {};
  const { currentUrl, tokenId } = useAppSelector(selectPaypal) || {};

  const createBillingAgreementIdAction = async () => {
    if (currentUrl && tokenId) {
      const response = await createBillingAgreementId(ern, tokenId);

      if (response) {
        dispatch(
          setSnackBarData({
            message: en.payment.paypal.billingAgreement.success,
            isShow: true,
            delay: 2000,
          }),
        );

        dispatch(resetPaypalSlice());

        // [ETP-4308] update latest profile
        await dispatch(getProfileThunk(ern));

        window.location.replace(currentUrl);
      }
    } else {
      window.location.replace(ROOT_PATHS.landing);
    }
  };

  useEffect(() => {
    createBillingAgreementIdAction();
  }, [ern]);

  return <></>;
};

export default PayPalSubscription;
