import React from 'react';
import { Box, Paper, Typography, Snackbar, SxProps, Theme } from '@mui/material';

import en from '../../translations/en';

import { useAppSelector } from '../../app/hooks';
import { selectApp } from '../../slice/appSlice';

const SnackbarComponent = ({
  open,
  flightNumber,
  toastNotification,
  IconComponent,
  handleClose,
  customStyles,
}: {
  open: boolean;
  flightNumber?: string;
  toastNotification?: string;
  IconComponent: React.ReactNode;
  handleClose: () => void;
  customStyles?: SxProps<Theme>;
}) => {
  const { isDesktop } = useAppSelector(selectApp) || {};
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: isDesktop ? 'left' : 'center',
      }}
      open={(!!toastNotification || !!flightNumber) && open}
      autoHideDuration={5000}
      onClose={handleClose}
      sx={{
        ...(isDesktop
          ? {
              left: '195px !important',
              bottom: '31px !important',
            }
          : { mb: 3.625 }),
        ...customStyles,
      }}
    >
      <Paper
        elevation={3}
        sx={{
          width: '343px',
          display: 'flex',
          alignItems: 'center',
          px: 2,
          py: 1.625,
        }}
      >
        <Box sx={{ width: '24px', height: '24px', mr: 1 }}>{IconComponent}</Box>
        {toastNotification && <Typography variant="body_2_regular">{toastNotification}</Typography>}
        {flightNumber && (
          <Typography variant="body_2_regular">
            {en.booking.cancelBooking.snackbar.firstPart}
            <strong>{flightNumber}</strong>
            {en.booking.cancelBooking.snackbar.secondPart}
          </Typography>
        )}
      </Paper>
    </Snackbar>
  );
};

export default SnackbarComponent;
