import Div100vh from "react-div-100vh";
import { styled } from "@mui/material/styles";

const Div100vhView = styled(Div100vh)(() => ({
  display: "flex",
  flexDirection: "column",
  background: "white",
}));

export default Div100vhView;
