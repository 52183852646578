import { useEffect } from 'react';

import { ROOT_PATHS } from '../../constants/paths';

import { selectPaypal } from '../../slice/paypalSlice';

import { useAppSelector } from '../../app/hooks';

const PayPalSubscriptionCancelled = () => {
  const { currentUrl } = useAppSelector(selectPaypal) || {};

  useEffect(() => {
    if (currentUrl) {
      window.location.replace(currentUrl);
    } else {
      window.location.replace(ROOT_PATHS.landing);
    }
  }, []);

  return <></>;
};

export default PayPalSubscriptionCancelled;
