import React, { createContext, useState } from 'react';
import { IPeriod, IPaymentHistoryStateType } from '../interfaces';

interface IActionType {
  setEditPeriod: any;
  resetPaymentHistory: any;
}

const EditPaymentHistoryState = createContext<IPaymentHistoryStateType>({
  editPeriod: {},
});

const EditPaymentHistoryAction = createContext<IActionType>({
  setEditPeriod: null,
  resetPaymentHistory: null,
});

const EditPaymentHistoryContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [editPeriod, setEditPeriod] = useState<IPeriod>({});
  const resetPaymentHistory = () => {
    setEditPeriod({});
  };

  return (
    <EditPaymentHistoryState.Provider
      value={{
        editPeriod,
      }}
    >
      <EditPaymentHistoryAction.Provider
        value={{
          setEditPeriod,
          resetPaymentHistory,
        }}
      >
        {children}
      </EditPaymentHistoryAction.Provider>
    </EditPaymentHistoryState.Provider>
  );
};

export { EditPaymentHistoryContextProvider, EditPaymentHistoryState, EditPaymentHistoryAction };
