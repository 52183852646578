import React from 'react';
import { Grid, Typography, Box, useTheme, SxProps, Theme } from '@mui/material';
import en from '../../../translations/en';

import { IDetailsInfoProps } from '../../../interfaces';

import { getDisplayVal } from '../../../helpers';

import { FormAlert } from '../../../components';
import DetailInfoTitle from './DetailInfoTitle';

interface IDetailsProps {
  title: string;
  isDisabled?: boolean;
  handleEditClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  handleExpandClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  expandTitle?: string;
  titleColor?: string;
}

const DetailsInfo = ({
  data,
  title,
  titleVariant,
  warningMsg,
  titleColor,
  childStyles,
  isRetrieveToPayStatus = false,
}: {
  data: IDetailsInfoProps[];
  title: IDetailsProps;
  titleVariant?: any;
  warningMsg?: string;
  titleColor?: string;
  childStyles?: SxProps<Theme>;
  isRetrieveToPayStatus?: boolean;
}) => {
  const theme = useTheme();

  return (
    <Grid container sx={{ ...childStyles }}>
      {!isRetrieveToPayStatus && (
        <DetailInfoTitle
          title={title.title}
          handleEditClick={title.handleEditClick}
          handleExpandClick={title.handleExpandClick}
          expandTitle={title.expandTitle}
          isDisabled={title.isDisabled}
          titleColor={titleColor}
          titleVariant={titleVariant}
        />
      )}
      {warningMsg && (
        <Grid item xs={12}>
          <FormAlert
            severity="info"
            component={
              <Typography variant="body_2_regular" sx={{ ml: 1, color: theme.color.secondary.dark_grey.option_3 }}>
                {warningMsg}
              </Typography>
            }
          />
        </Grid>
      )}
      {isRetrieveToPayStatus ? (
        <Typography variant="body_2_regular" sx={{ color: theme.palette.gray.dark }}>
          {en.booking.retrieveToPay.travellerDetailDisplay}
        </Typography>
      ) : (
        data?.map((item: IDetailsInfoProps, index) => (
          <Grid
            item
            key={item.key}
            xs={item.singleRow ? 12 : 6}
            mb={1}
            sx={{
              wordBreak: 'break-word',
              mb: index === data.length - 1 ? 0 : 1.5,
            }}
          >
            <Grid item xs={12}>
              <Typography
                variant="navigation_regular"
                color={theme.color.secondary.grey.option_2}
                mt={index === data.length - 1 ? 0.375 : 0}
              >
                {item.key}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  color: theme.color.secondary.dark_grey.option_3,
                }}
              >
                <Typography variant="body_1_medium">{getDisplayVal(item.value)}</Typography>
                {item.unit && item.value !== en.common.empty && (
                  <Typography sx={{ mt: 0.425, ml: 0.1 }} fontWeight="medium" variant="caption">
                    {item.unit}
                  </Typography>
                )}
                {item.remark && item.value !== en.common.empty && (
                  <Typography sx={{ ml: 0.5, mt: 0.3 }} fontWeight="medium" variant="caption">
                    {item.remark}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>
        ))
      )}
    </Grid>
  );
};

export default DetailsInfo;
