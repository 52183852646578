import React, { useState } from 'react';
import { Collapse, Typography, useTheme, Box } from '@mui/material';

import { NominatedIcon } from '../../../assets/images';
import en from '../../../translations/en';
import { DATE_FORMAT, INFINITE_DATE } from '../../../constants/constants';

import { IDependent } from '../../../interfaces';
import {
  compareDate,
  findDropdownOptionClient,
  formatDateAsString,
  getDisplayName,
  getDisplayVal,
} from '../../../helpers';

import { useAppSelector } from '../../../app/hooks';
import { selectApp } from '../../../slice/appSlice';
import { selectConfiguration } from '../../../slice/configurationSlice';

interface IItemType {
  leftSideLabel?: string | boolean;
  rightSideLabel?: string | boolean;
  leftSideValue?: string | boolean;
  rightSideValue?: string | boolean;
}

const RenderHorizontalView = ({ leftSideValue, leftSideLabel, rightSideValue, rightSideLabel }: IItemType) => {
  const theme = useTheme();
  if (!leftSideLabel) {
    if (rightSideLabel) {
      return (
        <Typography
          variant="body_2_bold"
          sx={{
            color: theme.color.secondary.dark_grey.option_1,
            mb: leftSideLabel ? 3 : 1.75,
            mt: 2,
          }}
        >
          {en.userProfile.dependent.passportDetails}
        </Typography>
      );
    } else {
      return <></>;
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        mb: 2,
      }}
    >
      <Box sx={{ minWidth: '130px', width: rightSideLabel ? '50%' : '100%' }}>
        <Typography variant="navigation_regular" color={theme.color.secondary.grey.option_2}>
          {leftSideLabel}
        </Typography>
        <Typography variant="body_1_medium">
          {leftSideLabel === en.userProfile.dependent.nominationStatus
            ? leftSideValue
              ? en.userProfile.dependent.currentNominee
              : en.userProfile.dependent.pastNominee
            : getDisplayVal(leftSideValue)}
        </Typography>
      </Box>
      {rightSideLabel && (
        <Box sx={{ minWidth: '130px', width: '50%', pl: 1 }}>
          <Typography variant="navigation_regular" color={theme.color.secondary.grey.option_2}>
            {rightSideLabel}
          </Typography>
          <Typography variant="body_1_medium">
            {rightSideLabel === en.userProfile.dependent.dependentStatus
              ? rightSideValue
                ? en.userProfile.dependent.active
                : en.userProfile.dependent.inActive
              : getDisplayVal(rightSideValue)}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const DependentDetailsCard = ({ isShowIcon, data }: { isShowIcon?: boolean; data?: IDependent }) => {
  const theme = useTheme();
  const { isDesktop } = useAppSelector(selectApp) || {};
  const { configurations } = useAppSelector(selectConfiguration) || {};
  const [isExpand, setIsExpand] = useState(false);
  const listDatas: IItemType[] = [
    {
      leftSideLabel: en.userProfile.dependent.relationship,
      leftSideValue: data?.relationship,
      rightSideLabel: en.userProfile.dependent.dateOfBirth,
      rightSideValue: getDisplayVal(formatDateAsString(data?.dateOfBirth, DATE_FORMAT.ddmmmyyyy)),
    },
    {
      leftSideLabel: en.userProfile.dependent.nominationStatus,
      leftSideValue: data?.isNominated,
      rightSideLabel: en.userProfile.dependent.dependentStatus,
      rightSideValue: data?.isActive,
    },
    {
      leftSideLabel: en.userProfile.dependent.nominationDate,
      leftSideValue: `${getDisplayVal(formatDateAsString(data?.nominationFrom, DATE_FORMAT.ddmmmyyyy))} - ${
        compareDate(getDisplayVal(data?.nominationTo), INFINITE_DATE) > 0
          ? en.userProfile.delegation.noDueDate
          : getDisplayVal(formatDateAsString(data?.nominationTo, DATE_FORMAT.ddmmmyyyy))
      }`,
      rightSideLabel: false,
      rightSideValue: false,
    },
    {
      leftSideLabel: false,
      rightSideLabel: true,
    },
    {
      leftSideLabel: en.userProfile.dependent.firstOtherNameAsInPassport,
      leftSideValue: data?.passportName.firstName,
      rightSideLabel: isDesktop ? en.userProfile.dependent.lastNameAsInPassport : false,
      rightSideValue: isDesktop ? data?.passportName.lastName : false,
    },
    {
      leftSideLabel: !isDesktop ? en.userProfile.dependent.lastNameAsInPassport : false,
      leftSideValue: data?.passportName.lastName,
      rightSideLabel: false,
      rightSideValue: false,
    },
    {
      leftSideLabel: en.userProfile.dependent.passportNumber,
      leftSideValue: data?.passportNumber,
      rightSideLabel: en.userProfile.dependent.passportExpiryDate,
      rightSideValue: getDisplayVal(formatDateAsString(data?.passportExpirationDate, DATE_FORMAT.ddmmmyyyy)),
    },
    {
      leftSideLabel: en.userProfile.dependent.nationalityInPassport,
      leftSideValue: findDropdownOptionClient(configurations?.countries, data?.passportNationality || '')?.label,
      rightSideLabel: en.userProfile.dependent.issuedCountry,
      rightSideValue: findDropdownOptionClient(configurations?.countries, data?.passportCountry || '')?.label,
    },
  ];

  return (
    <Box
      sx={{
        boxShadow: theme.palette.boxShadow.dark,
        borderRadius: 1,
        py: 2,
        px: 2,
        mt: 1,
        bgcolor: isShowIcon ? theme.palette.bgColor.main : theme.color.secondary.light_slate.option_6,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ width: '50%' }}>
          <Typography variant="navigation_regular" color={theme.color.secondary.grey.option_2}>
            {en.userProfile.dependent.dependentName}
          </Typography>
          <Typography variant="body_1_medium">
            {getDisplayName({
              title: data?.title,
              firstName: data?.firstName,
              lastName: data?.lastName,
            })}
          </Typography>
        </Box>
        <Box sx={{ width: '50%', pl: 1 }}>
          <Typography variant="navigation_regular" color={theme.color.secondary.grey.option_2}>
            {en.userProfile.dependent.dependentType}
          </Typography>
          <Typography variant="body_1_medium">{getDisplayVal(data?.beneficiaryType)}</Typography>
        </Box>
        {isShowIcon ? (
          <NominatedIcon width={20} height={20} fill={theme.color.secondary.dark_grey.option_3} />
        ) : (
          <Box sx={{ width: '20px' }} />
        )}
      </Box>
      <Collapse in={isExpand} collapsedSize={`${17 * 2}px`}>
        <Typography
          variant="body_2_bold"
          sx={{
            color: theme.color.utility.link.option_3,
            mt: 2,
            cursor: 'pointer',
            textAlign: 'end',
          }}
          onClick={() => setIsExpand(!isExpand)}
        >
          {isExpand ? en.booking.nonEnglishCharacter.viewLess : en.booking.nonEnglishCharacter.viewMore}
        </Typography>

        {isExpand && (
          <>
            <Typography
              variant="body_2_bold"
              sx={{
                color: theme.color.secondary.dark_grey.option_1,
                mb: 1.75,
                mt: 2,
              }}
            >
              {en.userProfile.dependent.dependentDetails}
            </Typography>
            {listDatas?.map((item: IItemType, index: number) => {
              return (
                <RenderHorizontalView
                  key={index}
                  leftSideValue={item.leftSideValue}
                  leftSideLabel={item.leftSideLabel}
                  rightSideValue={item.rightSideValue}
                  rightSideLabel={item.rightSideLabel}
                />
              );
            })}
          </>
        )}
      </Collapse>
    </Box>
  );
};

export default DependentDetailsCard;
