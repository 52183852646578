import { SxProps, Theme, Typography, useTheme } from '@mui/material';

const BoldButtonTypography = ({ customStyles, textLabel }: { customStyles?: SxProps<Theme>; textLabel: string }) => {
  const theme = useTheme();

  return (
    <Typography
      variant="body_2_bold"
      sx={{
        width: '120px',
        height: '50px',
        lineHeight: '50px',
        background: theme.color.utility.link.option_3,
        borderRadius: 1,
        color: theme.palette.bgColor.main,
        textAlign: 'center',
        ...customStyles,
      }}
    >
      {textLabel}
    </Typography>
  );
};

export default BoldButtonTypography;
