import React from 'react';
import { Typography, Box, Theme, SxProps } from '@mui/material';

import en from '../../../translations/en';
import theme from '../../../style/theme';

import { getDisplayVal } from '../../../helpers';

import { useAppSelector } from '../../../app/hooks';
import { selectApp } from '../../../slice/appSlice';

const BookingReferenceHeader = ({ recLoc, customStyles }: { recLoc: string; customStyles?: SxProps<Theme> }) => {
  const { isDesktop } = useAppSelector(selectApp) || {};
  return (
    <Box
      color={isDesktop ? theme.color.secondary.dark_grey.option_1 : 'white'}
      sx={{
        display: 'flex',
        alignItems: 'baseline',
        ...customStyles,
      }}
    >
      <Typography
        variant="body_1_regular"
        sx={{
          pr: isDesktop ? 0.5 : 1,
        }}
      >
        {en.booking.confirmation.bookingReference}:
      </Typography>
      <Typography variant="title_2_bold">{getDisplayVal(recLoc)}</Typography>
    </Box>
  );
};

export default BookingReferenceHeader;
