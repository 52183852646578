import { getCurrentHostNameAndUrl } from './common';
import { PREPAID_API } from '../constants/api';

import { showErrorAlert } from '../services/api';
import { prepaidAuth, retrieveAndPayAuth } from '../services/prepaid';

import { IBookFlightLtParams, IPrepaidRetrieveAndPaytBookFlightLtParams } from '../interfaces';

import en from '../translations/en';

const handleResponseAction = async (response: any) => {
  if (response) {
    const { cpp_signature: cppSignature } = response;

    if (cppSignature) {
      try {
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = `${window.config.cppBaseURL}${PREPAID_API.auth}`;

        const responseEntries = Object.entries(response) || {};

        responseEntries.forEach(([key, value]) => {
          const hiddenField = document.createElement('input');
          hiddenField.type = 'hidden';
          hiddenField.name = key;
          hiddenField.value = `${value}`;
          form.appendChild(hiddenField);
        });

        document.body.appendChild(form);

        form.submit();
      } catch (err) {
        showErrorAlert({
          title: en.errorAlert.prepaidAuth,
          message: en.payment.prepaid.errorDuringRedirectCPP,
        });
      }
    } else {
      showErrorAlert({
        title: en.errorAlert.prepaidAuth,
        message: en.payment.prepaid.noCppSignature,
      });
    }
  }
};

const prepaidAuthAction = async (param: IBookFlightLtParams) => {
  const { currentHostName } = getCurrentHostNameAndUrl();

  const response = await prepaidAuth({ ...param, hostname: currentHostName });

  handleResponseAction(response);
};

const retrieveAndPayAuthAction = async (param: Omit<IPrepaidRetrieveAndPaytBookFlightLtParams, 'hostname'>) => {
  const { currentHostName } = getCurrentHostNameAndUrl();

  const response = await retrieveAndPayAuth({
    ...param,
    hostname: currentHostName,
  });

  handleResponseAction(response);
};

export { prepaidAuthAction, retrieveAndPayAuthAction };
