import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

import { DATE_FORMAT } from "../../../constants/constants";

import { TickIcon } from "../../../assets/images";

import { formatDateAsString } from "../../../helpers";

import { ShadowContent } from "../../../components";
import { FlightDateLabel } from "../..";

interface ILtMiniFlightInfo {
  callSign?: string;
  departureTime?: string;
  arrivalTime?: string;
  departureDate?: string;
  arrivalDate?: string;
}

const LtMiniFlightInfo = ({
  callSign = "",
  departureTime = "",
  arrivalTime = "",
  departureDate = "",
  arrivalDate = "",
}: ILtMiniFlightInfo) => {
  const theme = useTheme();
  const textColor = theme.color.secondary.dark_grey.option_2;
  const textVariant = "body_2_medium";

  return (
    <ShadowContent
      sx={{
        p: 2,
        mt: { xs: -3.3, sm: 0 },
        mx: { xs: 2, sm: 0 },
        width: { xs: "calc(100vw - 32px)", sm: 1 },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
          columnGap={1}
        >
          <Typography
            color={theme.color.utility.link.option_3}
            variant="body_2_medium"
          >
            <TickIcon style={{ marginRight: 4, width: 11, height: 11 }} />
            {callSign || ""}
          </Typography>

          <CircleIcon
            sx={{
              fontSize: 4,
              color: theme.color.secondary.dark_grey.option_6,
            }}
          />

          <FlightDateLabel
            departureTime={departureTime}
            arrivalTime={arrivalTime}
            arrivalDate={arrivalDate}
            departureDate={departureDate}
            variant={textVariant}
            color={textColor}
          />
        </Box>

        <Typography variant={textVariant} color={textColor}>
          {formatDateAsString(
            departureDate || "",
            DATE_FORMAT.ddmmmyyyy,
            DATE_FORMAT.date
          )}
        </Typography>
      </Box>
    </ShadowContent>
  );
};

export default LtMiniFlightInfo;
