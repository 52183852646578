import React, { useContext, useEffect, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { getFormValues, reset } from 'redux-form';
import { connect } from 'react-redux';
import _ from 'lodash';
import { use100vh } from 'react-div-100vh';

import { UI_STYLES, FORM } from '../../../constants/constants';
import en from '../../../translations/en';

import { ILtEditContactDetails, ILtFare } from '../../../interfaces';
import { OVERLAY_VARIANT } from '../../../containers/BookingFlow/Common/BookingFlowOverlay';

import { RootState } from '../../../app/store';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  selectBooking,
  setContactDetails,
  //   cleanUpLTFareDetail,
} from '../../../slice/bookingSlice';
import { selectApp } from '../../../slice/appSlice';
import { selectUser } from '../../../slice/userSlice';
import { BookingSummaryState } from '../../../context';

// import { bookFlightsLT } from "../../../services/booking";
// import { showErrorAlert } from "../../../services/api";

import {
  EditTravellerDetails,
  EditPassportDetails,
  EditUsAddressDetails,
  EditLtContactDetails,
  ViewContactDetails,
  ViewLtPaymentDetails,
  ViewTicketCostDetails,
  ViewUsAddressDetails,
  ViewTravellerDetails,
  SegmentInfo,
  BaseConcessionInfo,
  BookingFlowOverlay,
} from '../../../containers';
import { Footer, FormButton, ScrollableView } from '../../../components';

const { travelDetails } = en.booking.flightConfirmation;
const { contactDetails: contactDetailsLbl } = travelDetails || {};

// for etp-2915 happy flow, need to check after call backend api
const RebookConfirmation = ({
  handleCancelClick,
  gotoCompleted,
}: {
  handleCancelClick: () => void;
  gotoCompleted?: () => void;
}) => {
  /**
   * TODO:
   * 1. initialize docs, docsResidence, docaAddress
   * 2. Mandatory & Please edit warning (need to chat with David Li)
   * */

  const dispatch = useAppDispatch();
  const screenHeight = use100vh();
  const theme = useTheme();
  const { isDesktop } = useAppSelector(selectApp) || {};

  const [editForm, setEditForm] = useState('');
  const [isOpenOverlay, setIsOpenOverlay] = useState(false);

  const openOverlayOfType = (type: string) => {
    setEditForm(type);
    setIsOpenOverlay(true);
  };

  const { outwardFlight, contactDetails, docsDoca, leisureTravelDetails, usAddress } =
    useAppSelector(selectBooking) || {};

  const {
    ltSeatConcessionTravellerList,
    // seatConcession,
    // seatOption,
    // ltConcessions,
    fareDetail,
  } = leisureTravelDetails || {};

  const { bookingSummaryData } = useContext(BookingSummaryState) || {};

  const { profile } = useAppSelector(selectUser);
  const { concession } = bookingSummaryData || {};
  const { paymentConcept, paymentMedium } = profile || {};

  const concessionRule = concession.name;

  const {
    docaAddress: isUsAddressMandatory,
    docaResidence: isTravellerInfoMandatory,
    docs: isPassportInfoMandatory,
  } = docsDoca || {};

  const transformContact = () => {
    let updatedContactDetails: ILtEditContactDetails | null = null;

    if (leisureTravelDetails?.contactDetails) {
      // Prefill phoneNumber and email from get fare API
      const { mobilePhone, businessEmail, personalEmail } = leisureTravelDetails?.contactDetails || {};

      if (businessEmail) {
        // prefill the phoneNumber and businessEmail
        updatedContactDetails = {
          phoneNumber: mobilePhone,
          emailList: [
            {
              type: contactDetailsLbl.options[0],
              email: businessEmail,
            },
          ],
        };
      } else if (personalEmail) {
        // prefill the phoneNumber and personalEmail
        updatedContactDetails = {
          phoneNumber: mobilePhone,
          emailList: [
            {
              type: contactDetailsLbl.options[1],
              email: personalEmail,
            },
          ],
        };
      } else {
        //no business email/personal email
        updatedContactDetails = { phoneNumber: mobilePhone };
      }
    }

    return updatedContactDetails;
  };

  // set default contact detail data from Redux
  useEffect(() => {
    if (!contactDetails && leisureTravelDetails?.contactDetails) {
      const updatedContactDetails = transformContact();
      dispatch(setContactDetails(updatedContactDetails));
    }
  }, [contactDetails, leisureTravelDetails?.contactDetails]);

  const editComponents = {
    [FORM.travellerDetails]: EditTravellerDetails,
    [FORM.passportDetails]: EditPassportDetails,
    [FORM.contactDetails]: EditLtContactDetails,
    [FORM.usAddress]: EditUsAddressDetails,
  };

  const selectedTraveller = _.cloneDeep(ltSeatConcessionTravellerList);

  // const { adultPassengers: adultTravellers } =
  //   checkNominatedPassengers(selectedTraveller) || {};

  // const travellerInfo = (forCreateBooking: boolean) =>
  //   selectedTraveller.map((item: INominatedPassenger) => {
  //     const { passportDetail } = item || {};
  //     const {
  //       dateOfBirth,
  //       passportExpirationDate,
  //       gender,
  //       passportCountry,
  //       passportNationality,
  //       passportName,
  //     } = passportDetail || {};
  //     const isPassportDataExist =
  //       !!dateOfBirth &&
  //       !!passportExpirationDate &&
  //       !!gender &&
  //       !!passportCountry &&
  //       !!passportNationality &&
  //       !!passportName;
  //     const baseData: ITravellerDetailsItem = {
  //       dependentId: item.dependentId,
  //       beneficiaryTypeCode: item.beneficiaryTypeCode,
  //       firstName: item.firstName,
  //       lastName: item.lastName,
  //       ...(forCreateBooking && {
  //         title: item.passportDetail?.title,
  //         countryOfResidential: (
  //           item?.countryOfResidential as Configuration.DropdownOptionClient
  //         )?.code,
  //       }),
  //     };

  //     return {
  //       ...baseData,
  //       ...(isPassportDataExist && {
  //         passportInput: {
  //           dateOfBirth: item.passportDetail?.dateOfBirth,
  //           expiryDate: item.passportDetail?.passportExpirationDate,
  //           gender: (
  //             item.passportDetail?.gender as Configuration.DropdownOptionClient
  //           )?.code,
  //           issuingCountry: (
  //             item.passportDetail
  //               ?.passportCountry as Configuration.DropdownOptionClient
  //           )?.code,
  //           nationality: (
  //             item.passportDetail
  //               ?.passportNationality as Configuration.DropdownOptionClient
  //           )?.code,
  //           passportName: item.passportDetail?.passportName,
  //           passportNumber: item.passportDetail?.passportNumber,
  //         },
  //       }),
  //       ...(isUsAddressMandatory && forCreateBooking && { usAddress }),
  //       ...(item.infantWithSeat === false &&
  //         item.validationInfo.ageType ===
  //           NOMINATED_PASSENGER_AGE_TYPE.infant && {
  //           attachedTo: {
  //             dependentId:
  //               adultTravellers.length >= 2
  //                 ? item.accompanyAdult?.dependentId || null
  //                 : adultTravellers[0].dependentId,
  //             beneficiaryTypeCode:
  //               adultTravellers.length >= 2
  //                 ? item.accompanyAdult?.beneficiaryTypeCode || ""
  //                 : adultTravellers[0].beneficiaryTypeCode,
  //           },
  //         }),
  //       ...(forCreateBooking &&
  //         item.accompanyFormData && {
  //           travelWith: {
  //             firstName: item.accompanyFormData.firstName,
  //             lastName: item.accompanyFormData.lastName,
  //             title: item.accompanyFormData.salutation?.code,
  //             recLoc: item.accompanyFormData.bookingReference,
  //           },
  //         }),
  //     };
  //   });

  // const constructBookFlightLTParams = (): IBookFlightLtParams => {
  //   const { departureTime, arrivalDate, arrivalTime } = outwardFlight || {};
  //   const flightSectors = constructFlightSectors(outwardFlight, {
  //     departureTime,
  //     arrivalDate,
  //     arrivalTime,
  //     bookingClass: seatOption || "",
  //   });

  //   const baseParams: IBookFlightLtParams = {
  //     userId: ern,
  //     flightSectors,
  //     email: "",
  //     phoneNo: "",
  //     concessionRuleId: concessionRule?._id || "",
  //     travellerInfo: travellerInfo(true),
  //   };
  //   if (emailList?.[1]?.email) {
  //     baseParams.altEmail = emailList?.[1]?.email;
  //   }
  //   return baseParams;
  // };

  //   const cleanUpCurrentStepData = () => {
  //     // including: `contactDetails`, `leisureTravelDetails.fareDetail`
  //     dispatch(cleanUpLTFareDetail());
  //     dispatch(setContactDetails(null));
  //   };

  return (
    <Box
      sx={{
        background: 'transparent',
      }}
    >
      <Box
        component={ScrollableView}
        sx={
          isDesktop
            ? {
                height: `calc(${screenHeight}px - ${UI_STYLES.navBarHeight} 
                  - ${UI_STYLES.desktopFooterHeight}
                  - ${UI_STYLES.overlayHeaderHeightSearchCriteria})`,
              }
            : {
                height: `calc(${screenHeight}px - ${UI_STYLES.footerHeight} - 56px)`,
                px: 2,
              }
        }
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            background: theme.color.secondary.light_slate.option_7,
            width: isDesktop ? '518px' : '100%',
            mx: 'auto',
            borderRadius: 1,
            mt: { xs: 0, sm: 4.5 },
          }}
        >
          <Box
            sx={{
              borderRadius: 1,
              mx: 0,
              backgroundColor: 'white',
            }}
          >
            {outwardFlight && <SegmentInfo flightInfo={outwardFlight} isShowSegmentStatus={false} />}

            <ViewTicketCostDetails
              currency={fareDetail ? Object.values(fareDetail as ILtFare)?.[0]?.currency : ''}
              fareDetails={(fareDetail as ILtFare) || {}}
            />
            <BaseConcessionInfo data={concessionRule} customStyles={{ p: 2 }} />

            <ViewTravellerDetails
              isTravellerInfoMandatory={isTravellerInfoMandatory}
              isPassportInfoMandatory={isPassportInfoMandatory}
              handleEditClick={openOverlayOfType}
              requestedTravellers={selectedTraveller || []}
              setIsRestrictBooking={(value: boolean) => {
                // TBC:
                console.log(value);
              }}
            />

            {isUsAddressMandatory && (
              <ViewUsAddressDetails
                handleEditClick={openOverlayOfType}
                setIsRestrictBooking={(value: boolean) => {
                  // TBC:
                  console.log(value);
                }}
                data={usAddress}
              />
            )}

            <ViewContactDetails
              handleEditClick={openOverlayOfType}
              setIsRestrictBooking={(value: boolean) => {
                // TBC:
                console.log(value);
              }}
              data={contactDetails as ILtEditContactDetails}
            />

            <ViewLtPaymentDetails paymentConcept={paymentConcept || ''} paymentMedium={paymentMedium || ''} />
          </Box>
        </Box>

        {editComponents[editForm] && (
          <BookingFlowOverlay
            component={editComponents[editForm]}
            title={en.booking.flightConfirmation.reviewBooking}
            open={isOpenOverlay}
            variant={OVERLAY_VARIANT.SEARCH_CRITERIA}
            isDrawer={true}
            handleBackClick={() => {
              setIsOpenOverlay(false);
              setTimeout(() => {
                dispatch(reset(editForm));
                setEditForm('');
              }, 500);
            }}
            data={{
              handleClose: () => {
                setIsOpenOverlay(false);
                setTimeout(() => {
                  dispatch(reset(editForm));
                  setEditForm('');
                }, 500);
              },
            }}
          />
        )}
      </Box>

      <Footer
        leftChild={
          <FormButton
            theme={theme}
            colour="transparent"
            size="large"
            sx={{
              height: '44px',
              display: 'flex',
              justifyContent: 'center',
              ...{ minWidth: 0 },
            }}
            onClick={() => {
              handleCancelClick();
            }}
          >
            {en.common.cancel}
          </FormButton>
        }
        primaryBtn={{
          // TBC: etp-2915: not check valid, just pass now
          text: en.common.createBooking,
          customOnClick: async () => {
            // ETP-2431
            // if (paymentConcept === PAYMENT_CONCEPT.PREPAID) {
            //   showErrorAlert({
            //     title:
            //       en.booking.flightConfirmation.travelDetails
            //         .paymentDetails.paymentFailHeader,
            //     message:
            //       en.booking.flightConfirmation.travelDetails
            //         .paymentDetails.PaymentFailBody,
            //     noErrorAlert: true,
            //   });
            //   return;
            //   // TODO
            //   // please update this section during integration when prepaid api is ready
            // }
            // const result = await bookFlightsLT(
            //   constructBookFlightLTParams()
            // );
            // result &&
            //   goToRouteStep?.({
            //     step: BOOKING_FLOW_PAGE_NO.completed,
            //     data: {
            //       bookingResult: result,
            //       recLoc: result.booking.recLoc,
            //     },

            gotoCompleted?.();
          },
          customButtonStyles: {
            marginRight: 0,
          },
        }}
        customStyles={
          isDesktop
            ? {
                height: UI_STYLES.desktopFooterHeight,
                px: 22,
                boxShadow: theme.palette.boxShadow.dark,
                borderTopLeftRadius: '24px',
                borderTopRightRadius: '24px',
              }
            : {
                px: 2,
              }
        }
      />
    </Box>
  );
};

export default connect((state: RootState) => ({
  travelDetailsFormValues: getFormValues(FORM.travelDetails)(state),
}))(RebookConfirmation);
