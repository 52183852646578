import { Typography, useTheme, Box } from "@mui/material";

const FilterCount = ({ count }: { count: number }) => {
  const theme = useTheme();
  if (!count) {
    return <></>;
  } else {
    return (
      <Box>
        <Typography
          variant="navigation_bold"
          sx={{
            textAlign: "center",
            borderRadius: 4,
            backgroundColor: theme.color.utility.link.option_3,
            height: "20px",
            width: "20px",
            color: "#fff",
            lineHeight: 1.55,
          }}
        >
          {count}
        </Typography>
      </Box>
    );
  }
};

export default FilterCount;
