import { Box, Typography, useTheme } from '@mui/material';

import en from '../../../translations/en';

import { getPassengerDisplayName } from '../../../helpers';
import { useAppSelector } from '../../../app/hooks';
import { selectApp } from '../../../slice/appSlice';

import { ScrollableView } from '../../../components';

const ConfirmRemoveContainer = ({ removalTravellerList }: { removalTravellerList: any }) => {
  const theme = useTheme();
  const { isDesktop } = useAppSelector(selectApp) || {};

  return (
    <Box
      component={ScrollableView}
      sx={{
        flex: 1,
        minWidth: 0,
        minHeight: 0,
        ...(isDesktop
          ? {
              textAlign: 'left',
            }
          : {
              mt: 1,
              mb: 2,
              p: 2,
              borderRadius: 1,
              boxShadow: theme.palette.boxShadow.dark,
            }),
        background: 'white',
      }}
    >
      <Typography variant="body_2_bold" lineHeight="20px" sx={{ px: { sm: 4, xs: 1 } }}>
        {en.booking.confirmation.removePax.confirmRemovePaxDialog.description}
      </Typography>

      {removalTravellerList?.length > 0 &&
        removalTravellerList.map((removalTraveller: any, index: number) => {
          return removalTraveller?.isRemoved ? (
            <Typography
              key={index}
              variant="body_2_regular"
              color={theme.color.secondary.dark_grey.option_1}
              sx={{
                ...(isDesktop && {
                  textAlign: 'left',
                }),
                flex: 1,
                wordBreak: 'break-word',
                px: { sm: 4, xs: 1 },
                mt: 1,
              }}
            >
              {getPassengerDisplayName(removalTraveller.traveler)}
            </Typography>
          ) : undefined;
        })}
    </Box>
  );
};

export default ConfirmRemoveContainer;
