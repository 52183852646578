import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga4';

import en from '../../translations/en';
import { BOOKING_PATHS, ROOT_PATHS } from '../../constants/paths';
import { USER_ROLE, GOOGLE_ANALYTICS_EVENTS, BOOKING_FLOW_PAGE_NO } from '../../constants/constants';
import { SAMPLE_CANCELLED_PREPAID_DATA, SAMPLE_NOT_CANCELLED_PREPAID_DATA } from '../../constants/sampleData';

import { BOOKING_LAYOUT_VARIANT } from '../MyBooking/BookingLayout';
import { Frontend } from 'booking';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  selectApp,
  setCancelBookingSuccessData,
  setRefundBookingSuccessData,
  setRetrieveAndPaySuccessData,
  setSnackBarData,
} from '../../slice/appSlice';
import { selectAuth } from '../../slice/authSlice';
import { selectUser } from '../../slice/userSlice';

import { getUpcomingBooking } from '../../services/booking';
import { getUpcomingBooking as getUpcomingBookingDelegation } from '../../services/delegation/booking';

import { BookingLayout, BookingSummaryOverlay } from '..';
import { showRefundFlowFlag } from '../../config/featureFlags';
import { selectBooking, setBookingStep } from '../../slice/bookingSlice';
import { prepaidBook } from '../../services/prepaid';

const UpComingBookingLayout = ({ scrollViewRef }: { scrollViewRef: any }) => {
  const dispatch = useAppDispatch();

  const history = useHistory();

  const theme = useTheme();

  const { refundBookingSuccessData, retrieveAndPaySuccessData } = useAppSelector(selectApp) || {};
  const { bookingResult } = retrieveAndPaySuccessData || {};

  const { bookingStep, viewBookingApplicationId, tpAppRef } = useAppSelector(selectBooking) || {};

  const { ern } = useAppSelector(selectAuth) || {};
  const { isDesktop, cancelBookingSuccessData } = useAppSelector(selectApp) || {};

  const { role } = useAppSelector(selectUser) || {};
  const { type: roleType, id: roleId } = role || {};

  const [upcomingBookings, setUpcomingBookings] = useState<Frontend.ParsedBooking[]>([]);
  const [bookingSelected, setBookingSelected] = useState<Frontend.ParsedBooking | null>(null);

  useEffect(() => {
    // Triggered loading action
    const openBookingAction = async ({ ern }: { ern: string }) => {
      setBookingSelected(bookingResult);

      if (tpAppRef) {
        const response = await prepaidBook(ern, tpAppRef);

        if (response && bookingStep === BOOKING_FLOW_PAGE_NO.viewBooking) {
          // redirect to completed screen
          dispatch(setBookingStep(BOOKING_FLOW_PAGE_NO.completed));
          dispatch(
            setRetrieveAndPaySuccessData({
              bookingResult: {
                booking: response?.booking,
              },
              recLoc: response?.booking?.recLoc,
            }),
          );

          window.location.replace('/booking');
        }
      } else {
        window.location.replace(ROOT_PATHS.landing);
      }
    };

    if (bookingStep === BOOKING_FLOW_PAGE_NO.viewBooking && viewBookingApplicationId && tpAppRef) {
      openBookingAction({ ern });
    }
  }, [bookingStep, viewBookingApplicationId, tpAppRef]);

  const getUpcomingBookingAction = async () => {
    let results;

    if (roleType === USER_ROLE.delegation) {
      results = await getUpcomingBookingDelegation(roleId);
    } else {
      results = await getUpcomingBooking(ern);
    }

    if (results) {
      // Will remove retrieveToPayControlFlag after confirm and pay api integration
      if (showRefundFlowFlag?.isNotCancelledRefund) {
        setUpcomingBookings([SAMPLE_NOT_CANCELLED_PREPAID_DATA.booking, ...results]);
      } else if (showRefundFlowFlag?.isCancelledRefund) {
        setUpcomingBookings([SAMPLE_CANCELLED_PREPAID_DATA.booking, ...results]);
      } else {
        setUpcomingBookings(results);
      }
    }
  };

  useEffect(() => {
    setUpcomingBookings([]);
    getUpcomingBookingAction();
  }, [ern, roleId]);

  useEffect(() => {
    if (cancelBookingSuccessData?.flightNumber || cancelBookingSuccessData?.removePaxLabel) {
      setUpcomingBookings([]);
      getUpcomingBookingAction();
      if (scrollViewRef.current) {
        scrollViewRef.current.scrollTop = 0;
      }

      dispatch(
        setCancelBookingSuccessData({
          ...cancelBookingSuccessData,
          isShow: cancelBookingSuccessData?.removePaxLabel ? false : true,
          removePaxLabel: undefined,
        }),
      );
    }
  }, [cancelBookingSuccessData?.flightNumber, cancelBookingSuccessData?.removePaxLabel]);

  useEffect(() => {
    if (refundBookingSuccessData && refundBookingSuccessData?.applicationId) {
      dispatch(
        setSnackBarData({
          message: en.booking.confirmation.cancelActions.refund.refundSnackBarMsg,
          isShow: true,
        }),
      );
    }
  }, [refundBookingSuccessData]);

  const upcomingBookingCards = useMemo(() => {
    // TODO: when ticketsBySegment have multi segments,
    // need to consider only show 4 bookingCards.
    // coz one segment is show as a booking card
    const bookingForDisplay = isDesktop ? upcomingBookings.slice(0, 9) : upcomingBookings.slice(0, 4);
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignItems: 'center',
          px: { sm: 13, xs: 2 },
        }}
      >
        {/* // ETP-2445 => ETP-2473 */}
        <BookingLayout
          bookings={bookingForDisplay}
          emptyTitle={en.home.noUpcomingTrips}
          handleClick={(selectedItem: Frontend.ParsedBooking) => {
            setBookingSelected(selectedItem);
          }}
          variant={BOOKING_LAYOUT_VARIANT.HOME_PAGE}
        />
      </Box>
    );
  }, [isDesktop, upcomingBookings]);

  const handleBookingSummaryClose = useCallback(() => {
    setBookingSelected(null);
    if (refundBookingSuccessData?.applicationId) {
      dispatch(setRefundBookingSuccessData({ applicationId: '' }));
    }
  }, [refundBookingSuccessData?.applicationId]);

  return (
    <>
      <Grid item xs={12} px={{ sm: 13, xs: 2 }} pt={isDesktop ? 3.75 : 2} pb={isDesktop ? 3 : 2}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="headline_medium" color={theme.color.secondary.dark_grey.option_3}>
            {en.home.upcomingTrips}
          </Typography>

          <Button
            sx={{ p: 0, lineHeight: 'normal' }}
            onClick={() => {
              ReactGA.event({
                category: GOOGLE_ANALYTICS_EVENTS.category.manageBookingFlow,
                action: GOOGLE_ANALYTICS_EVENTS.action.viewAll,
              });

              history.push(BOOKING_PATHS.myBookings);
            }}
          >
            <Typography variant="body_1_bold" color={theme.color.utility.link.option_3}>
              {en.home.viewAll}
            </Typography>
          </Button>
        </Box>
      </Grid>

      {upcomingBookingCards}

      <BookingSummaryOverlay
        bookingSelected={bookingSelected}
        handleClose={handleBookingSummaryClose}
        data={{
          applicationId: bookingSelected?.applicationId,
          type: bookingSelected?.type,
        }}
      />
    </>
  );
};

export default UpComingBookingLayout;
