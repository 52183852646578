import React, { Dispatch, SetStateAction, useContext } from 'react';
import { shallowEqual } from 'react-redux';
import { Grid, IconButton } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';

import { SAVE_DATA_TO } from '../../../../constants/constants';
import { ReverseIcon, ExchangeIcon } from '../../../../assets/images';

import { Configuration } from 'configuration';

import { formatAirportValue, setInputValue } from '../../../../helpers';

import { EditSearchBarState, EditSearchBarAction } from '../../../../context';

import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { selectBooking, setOrigin, setDestination } from '../../../../slice/bookingSlice';
import { selectApp } from '../../../../slice/appSlice';

// TODO unused component currently
const ODExchange = ({
  originRef,
  destinationRef,
  switchODData,
  customStyles = [],
  saveTo = SAVE_DATA_TO.redux,
  currentOrigin,
  currentDestination,
  setCurrentOrigin,
  setCurrentDestination,
}: {
  originRef: React.RefObject<HTMLInputElement>;
  destinationRef: React.RefObject<HTMLInputElement>;
  switchODData?: () => void;
  customStyles?: SxProps<Theme>;
  saveTo?: string;
  currentOrigin?: Configuration.Airport | null;
  currentDestination?: Configuration.Airport | null;
  setCurrentOrigin?: Dispatch<SetStateAction<Configuration.Airport | null>>;
  setCurrentDestination?: Dispatch<SetStateAction<Configuration.Airport | null>>;
}) => {
  const dispatch = useAppDispatch();
  const { origin, destination } =
    saveTo === SAVE_DATA_TO.context
      ? useContext(EditSearchBarState) || {}
      : useAppSelector(selectBooking, shallowEqual) || {};
  const { setEditOrigin, setEditDestination } = useContext(EditSearchBarAction) || {};

  const { isDesktop } = useAppSelector(selectApp) || {};
  const exchangeOD = () => {
    switchODData?.();
    if (originRef.current && destinationRef.current) {
      setInputValue(originRef.current, formatAirportValue(destination));
      setInputValue(destinationRef.current, formatAirportValue(origin));

      if (saveTo === SAVE_DATA_TO.useState) {
        setCurrentOrigin?.(currentDestination || null);
        setCurrentDestination?.(currentOrigin || null);
      } else if (saveTo === SAVE_DATA_TO.context) {
        setEditOrigin?.(destination);
        setEditDestination?.(origin);
      } else {
        dispatch(setOrigin(destination));
        dispatch(setDestination(origin));
      }
    }
  };

  return isDesktop ? (
    <IconButton sx={{ mr: -4.8, ml: -3.2, zIndex: 1 }} onClick={exchangeOD}>
      <ExchangeIcon />
    </IconButton>
  ) : (
    <Grid
      component={IconButton}
      onClick={exchangeOD}
      size="large"
      sx={[
        {
          color: (theme) => theme.status.selected,
        },
        ...(Array.isArray(customStyles) ? customStyles : [customStyles]),
      ]}
    >
      <ReverseIcon />
    </Grid>
  );
};

export default ODExchange;
