import { useEffect, useState } from 'react';
import { Box, Typography, IconButton, useTheme } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { reduxForm, Field, getFormValues } from 'redux-form';

import { BackIcon } from '../../../assets/images';
import { FORM, INFINITE_DATE } from '../../../constants/constants';
import en from '../../../translations/en';

import { IDelegationRole, IDelegatee } from '../../../interfaces';

import { haveAllRequiredFields, getLocalDate, getFullDisplayName, stringAlphabeticSort } from '../../../helpers';

import { RootState } from '../../../app/store';
import { useAppSelector } from '../../../app/hooks';
import { selectApp } from '../../../slice/appSlice';
import { selectUser } from '../../../slice/userSlice';
import { selectAuth } from '../../../slice/authSlice';
import { addDelegatee, delegateeList } from '../../../services/delegation/delegatee';

import { Footer, IOSSwitch, ReduxFormSelectInput, ReduxFormDatePicker } from '../../../components';

const AddDelegateeContainer = (props: any) => {
  const {
    formValues,
  }: {
    formValues: IDelegatee | null;
  } = props;

  const theme = useTheme();
  const history = useHistory();

  const { ern } = useAppSelector(selectAuth) || {};
  const { isDesktop } = useAppSelector(selectApp) || {};
  const { allowDelegation } = useAppSelector(selectUser) || {};
  const [dueDateOption, setDueDateOption] = useState<boolean>(false);
  const [isValidInput, setIsValidInput] = useState<boolean>(false);
  const [delegatorList, setDelegatorList] = useState<IDelegationRole[]>([]);

  const today = getLocalDate();
  const { name, from = '', to = '' } = formValues || {};

  const addDelegateeAction = async () => {
    if (name) {
      const delegatorId = JSON.parse(JSON.stringify(name)).employeeId;
      const result = await addDelegatee(ern, delegatorId, from, dueDateOption ? INFINITE_DATE : to);

      if (result) {
        history.goBack();
      }
    }
  };

  useEffect(() => {
    if (!allowDelegation) {
      history.goBack();
    } else {
      (async () => {
        const delegatorList = await delegateeList(ern);
        delegatorList?.map?.((item: any) => {
          item['code'] = item.employeeId;
          item['label'] = getFullDisplayName(item.name);
        });
        delegatorList?.sort((a: any, b: any) => stringAlphabeticSort(a.label, b.label));
        delegatorList && setDelegatorList(delegatorList);
      })();
    }
  }, []);

  const checkInputFieldParams = (): boolean => {
    if (dueDateOption) {
      return haveAllRequiredFields(formValues, ['name', 'from']);
    } else {
      return haveAllRequiredFields(formValues, ['name', 'from', 'to']);
    }
  };

  useEffect(() => {
    const isFieldValid = checkInputFieldParams();
    setIsValidInput(isFieldValid);
  }, [formValues, dueDateOption]);

  return (
    <Box sx={{ ...(isDesktop && { width: '410px' }) }}>
      {!isDesktop && (
        <IconButton onClick={() => history.goBack()} sx={{ py: 1.625, pl: 0 }}>
          <BackIcon fill={theme.color.utility.link.option_3} />
        </IconButton>
      )}

      <Typography
        variant="headline_medium"
        sx={{
          mt: 2.125,
          mb: 2,
          color: theme.color.secondary.dark_grey.option_1,
        }}
      >
        {en.userProfile.delegation.addDelegatee}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Field
          component={ReduxFormSelectInput}
          name="name"
          title={en.userProfile.delegation.delegateeName}
          options={delegatorList}
        />

        <Field
          component={ReduxFormDatePicker}
          name="from"
          title={en.userProfile.delegation.from}
          minDate={today}
          calendarDefaultDate={today}
          calendarFootButtonText={en.userProfile.delegation.confirm}
          InputProps={{
            disableUnderline: true,
          }}
          containerStyles={{
            ...(isDesktop && {
              position: 'fixed',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              backgroundColor: `rgba(0,0,0,.5)`,
              zIndex: 120,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }),
          }}
          contentStyles={{
            ...(isDesktop
              ? {
                  position: 'unset',
                }
              : { top: 0 }),
          }}
        />

        <Box
          sx={{
            my: 1,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="body_1_regular">{en.userProfile.delegation.noDueDate}</Typography>

          <IOSSwitch
            onChange={(event: any) => {
              setDueDateOption(event.target.checked);
            }}
          />
        </Box>

        {!dueDateOption && (
          <Field
            component={ReduxFormDatePicker}
            name="to"
            title={en.userProfile.delegation.to}
            minDate={today}
            calendarFootButtonText={en.userProfile.delegation.confirm}
            InputProps={{
              disableUnderline: true,
            }}
            containerStyles={{
              ...(isDesktop && {
                position: 'fixed',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                backgroundColor: `rgba(0,0,0,.5)`,
                zIndex: 120,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }),
            }}
            contentStyles={{
              ...(isDesktop
                ? {
                    position: 'unset',
                  }
                : { top: 0 }),
            }}
          />
        )}
      </Box>

      {isValidInput && (
        <Footer
          primaryBtn={{
            isFullWidth: !isDesktop,
            text: en.userProfile.delegation.confirm,
            customButtonStyles: {
              width: '200px',
            },
            customOnClick: () => {
              addDelegateeAction();
            },
          }}
          customStyles={{
            position: 'fixed',
            left: 0,
            bottom: 0,
            pr: isDesktop ? 24.375 : 2,
          }}
        />
      )}
    </Box>
  );
};

const form = reduxForm({
  form: FORM.addDelegatee,
  enableReinitialize: true,
  touchOnBlur: true,
  touchOnChange: true,
  destroyOnUnmount: true,
})(AddDelegateeContainer);

const connectForm = connect((state: RootState) => ({
  initialValues: state.user.delegatee,
  formValues: getFormValues(FORM.addDelegatee)(state),
}))(form);

connectForm.displayName = FORM.addDelegatee;

export default connectForm;
