import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  useTheme,
} from "@mui/material";

import en from "../../translations/en";
import { CheckBoxCheckIcon, CheckBoxIcon } from "../../assets/images";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectApp } from "../../slice/appSlice";
import {
  selectPaypal,
  setIsBillingAgreementConsentChecked,
  setIsOpenBillingAgreementDialog,
} from "../../slice/paypalSlice";

import { FormButton } from "../../components";

const BillingAgreementDialog = ({
  handleCloseClick,
  handleConfirmClick,
}: {
  handleCloseClick?: () => void;
  handleConfirmClick?: () => void;
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);

  const { isDesktop } = useAppSelector(selectApp) || {};

  const { isBillingAgreementConsentChecked, isOpenBillingAgreementDialog } =
    useAppSelector(selectPaypal) || {};

  useEffect(() => {
    setOpen(isOpenBillingAgreementDialog);
  }, [isOpenBillingAgreementDialog]);

  useEffect(() => {
    if (!open) {
      dispatch(setIsOpenBillingAgreementDialog(false));
    }
  }, [open]);

  return (
    <Dialog
      sx={{
        "& .MuiDialog-container": {
          alignItems: "baseline",
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: isDesktop ? "478px" : "343px",
            borderRadius: 2,
            m: 0,
            px: isDesktop ? 4.5 : 3,
            py: 2,
            top: "31%",
            overflowX: "hidden",
            boxShadow: theme.boxShadow.important,
          },
        },
      }}
      open={open}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="title_1_bold"
          sx={{
            px: 0,
            py: 2,
            color: theme.palette.text.secondary,
          }}
        >
          {en.payment.paypal.billingAgreement.title}
        </Typography>
        <DialogContent sx={{ px: 0, pt: 1, pb: 2 }}>
          <Typography
            variant="body_1_regular"
            sx={{
              color: theme.palette.text.secondary,
            }}
          >
            <Box sx={{ my: 1, display: "flex", flexDirection: "row" }}>
              <Box sx={{ mt: -1, ml: -1 }}>
                <Checkbox
                  sx={{
                    "&.MuiCheckbox-root": {
                      color: theme.status.selected,
                    },
                  }}
                  checkedIcon={
                    <CheckBoxCheckIcon fill={theme.status.selected} />
                  }
                  icon={<CheckBoxIcon stroke={theme.status.selected} />}
                  color="info"
                  checked={isBillingAgreementConsentChecked}
                  onChange={() =>
                    dispatch(
                      setIsBillingAgreementConsentChecked(
                        !isBillingAgreementConsentChecked
                      )
                    )
                  }
                />
              </Box>

              <Typography
                variant="body_2_regular"
                sx={{
                  color: theme.color.secondary.dark_grey.option_1,
                }}
              >
                {en.payment.paypal.billingAgreement.agreement}
              </Typography>
            </Box>
          </Typography>
        </DialogContent>
      </Box>
      <DialogActions
        sx={{
          p: 0,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <FormButton
          theme={theme}
          colour="outline"
          variant="contained"
          color="success"
          size="large"
          customStyles={{
            borderRadius: 8,
            width: isDesktop ? "195px" : "140px",
            height: "44px",
            "&:hover": {
              backgroundColor: "white",
              boxShadow: "none",
            },
          }}
          onClick={() => {
            // clean consent checkbox
            dispatch(setIsBillingAgreementConsentChecked(false));

            // close dialog
            dispatch(setIsOpenBillingAgreementDialog(false));

            handleCloseClick?.();
          }}
          sx={{
            "&:not(:first-of-type), &:first-of-type": {
              ml: 0,
            },
          }}
        >
          {en.common.cancel}
        </FormButton>

        <FormButton
          theme={theme}
          colour="green"
          variant="contained"
          color="success"
          size="large"
          customStyles={{
            borderRadius: 8,
            width: isDesktop ? "195px" : "140px",
            height: "44px",
          }}
          onClick={() => {
            handleConfirmClick?.();

            // clean consent checkbox
            dispatch(setIsBillingAgreementConsentChecked(false));

            // close dialog
            dispatch(setIsOpenBillingAgreementDialog(false));

            handleCloseClick?.();
          }}
          disabled={!isBillingAgreementConsentChecked}
          sx={{
            "&:not(:first-of-type), &:first-of-type": {
              ml: 0,
            },
          }}
        >
          {en.common.continue}
        </FormButton>
      </DialogActions>
    </Dialog>
  );
};

export default BillingAgreementDialog;
