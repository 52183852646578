import React from 'react';
import { Grid, Typography, useTheme, Theme, SxProps } from '@mui/material';

import { FormAlert } from '..';

const WarningAlert = ({
  warningMsg,
  customStyles,
}: {
  warningMsg: string | React.ReactNode;
  customStyles?: SxProps<Theme>;
}) => {
  const theme = useTheme();
  return (
    <Grid xs={12}>
      <FormAlert
        customStyles={{ marginBottom: 0, ...customStyles }}
        severity="info"
        component={
          <Typography variant="body_2_regular" sx={{ ml: 1, color: theme.color.secondary.dark_grey.option_3 }}>
            {warningMsg}
          </Typography>
        }
      />
    </Grid>
  );
};

export default WarningAlert;
