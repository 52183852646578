import React from 'react';
import { Grid, Typography, Theme } from '@mui/material';
import { isArray } from 'lodash';

import { IKeyValue } from '../../../interfaces';

import { getSeatClass } from '../../../helpers';

const ExpandedConcessionDetails = ({ concessionDetailItems }: { concessionDetailItems: IKeyValue[] }) => {
  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1 }} sx={{ my: 1 }} textAlign="left">
      {concessionDetailItems.map((concessionDetailItem: IKeyValue) => {
        return (
          <Grid item key={concessionDetailItem.key} xs={6}>
            <Typography
              variant="navigation_regular"
              sx={{
                color: (theme: Theme) => theme.color.secondary.grey.option_2,
              }}
            >
              {concessionDetailItem.key}
            </Typography>
            {isArray(concessionDetailItem.value) ? (
              <>
                {concessionDetailItem.value?.map((item: string) => {
                  return (
                    <Typography variant="body_1_regular" key={item}>
                      {getSeatClass(item)}
                    </Typography>
                  );
                })}
              </>
            ) : (
              <Typography variant="body_1_regular">{concessionDetailItem.value}</Typography>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};
export default ExpandedConcessionDetails;
