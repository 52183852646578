import React, { useEffect, useState } from 'react';
import { Box, Typography, IconButton, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from 'react-router-dom';

import { BackIcon, CheckMarkIcon } from '../../../assets/images';
import { USER_PROFILE_PATHS } from '../../../constants/paths';
import en from '../../../translations/en';
import { DATE_FORMAT, INFINITE_DATE } from '../../../constants/constants';

import { IDelegatee } from '../../../interfaces';

import { formatDateAsString, getFullDisplayName } from '../../../helpers';

import { useAppSelector } from '../../../app/hooks';
import { selectApp } from '../../../slice/appSlice';
import { selectUser } from '../../../slice/userSlice';
import { selectAuth } from '../../../slice/authSlice';

import { getDelegatee, removeDelegatee } from '../../../services/delegation/delegatee';

import { Footer, CancelDialog } from '../../../components';
import { SnackbarComponent } from '../..';
const DelegationDetailsContainer = () => {
  const theme = useTheme();
  const history = useHistory();
  const { ern } = useAppSelector(selectAuth) || {};
  const { isDesktop } = useAppSelector(selectApp) || {};
  const { allowDelegation } = useAppSelector(selectUser) || {};

  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [delegateeList, setDelegateeList] = useState<IDelegatee[]>([]);
  const [delegateeIdWantToRemove, setDelegateeIdWantToRemove] = useState<string | null>(null);

  const getDelegateeAction = async () => {
    const result = await getDelegatee(ern);
    setDelegateeList(result);
  };

  const removeDelegateeAction = async () => {
    const result = await removeDelegatee(ern, delegateeIdWantToRemove || '');

    if (result) {
      setDelegateeList(result);
      setOpenSnackbar(true);

      setTimeout(() => {
        setOpenSnackbar(false);
      }, 3000);
    }
  };

  useEffect(() => {
    if (!allowDelegation) {
      history.goBack();
    }

    getDelegateeAction();
  }, []);

  return (
    <>
      <>
        {!isDesktop && (
          <IconButton
            onClick={() => history.goBack()}
            sx={{
              py: 1.625,
              pl: 0,
            }}
          >
            <BackIcon fill={theme.color.utility.link.option_3} />
          </IconButton>
        )}
        <Box>
          <Typography
            variant="headline_medium"
            sx={{
              mt: 2.125,
              mb: 2,
              color: theme.color.secondary.dark_grey.option_1,
            }}
          >
            {en.userProfile.delegation.title}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            {delegateeList && delegateeList.length > 0 ? (
              delegateeList.map((delegatee) => {
                return (
                  <Box
                    key={delegatee.employeeId}
                    sx={{
                      width: isDesktop ? '410px' : '100%',
                      height: '72px',
                      borderRadius: 1,
                      background: 'white',
                      boxShadow: theme.boxShadow.important,
                      display: 'flex',
                      alignItems: 'center',
                      p: 2,
                      pr: 2.5,
                    }}
                  >
                    <Box
                      sx={{
                        flex: 1,
                      }}
                    >
                      <Typography variant="body_1_medium" sx={{ color: theme.color.secondary.dark_grey.option_3 }}>
                        {getFullDisplayName(delegatee.name)}
                      </Typography>
                      <Box
                        component={Typography}
                        variant="body_2_regular"
                        sx={{
                          color: theme.color.secondary.dark_grey.option_4,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {formatDateAsString(delegatee.from, DATE_FORMAT.ddmmmyyyy, DATE_FORMAT.date)}

                        <Typography sx={{ px: 0.75, textAlign: 'center' }}>{en.common.hyphen}</Typography>
                        {delegatee.to === INFINITE_DATE
                          ? en.userProfile.delegation.noDueDate
                          : formatDateAsString(delegatee.to, DATE_FORMAT.ddmmmyyyy, DATE_FORMAT.date)}
                      </Box>
                    </Box>
                    <IconButton
                      onClick={() => {
                        setDelegateeIdWantToRemove(delegatee.employeeId);
                        setOpenRemoveDialog(true);
                      }}
                    >
                      <CloseIcon
                        sx={{
                          p: '1px',
                          width: '16px',
                          height: '16px',
                          borderRadius: '50%',
                          bgcolor: theme.color.secondary.grey.option_3,
                          color: 'white',
                        }}
                      />
                    </IconButton>
                  </Box>
                );
              })
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  variant="body_2_regular"
                  sx={{
                    mt: 1,
                    color: theme.color.secondary.dark_grey.option_3,
                    textAlign: 'center',
                  }}
                >
                  {en.userProfile.delegation.noDelegation}
                </Typography>
                <Typography
                  variant="body_2_regular"
                  sx={{
                    mt: 1,
                    color: theme.color.secondary.dark_grey.option_3,
                    textAlign: 'center',
                  }}
                >
                  {en.userProfile.delegation.addOneDelegatee}
                </Typography>
                <Footer
                  primaryBtn={{
                    isFullWidth: !isDesktop,
                    text: en.userProfile.delegation.addDelegatee,
                    customOnClick: () => {
                      history.push(USER_PROFILE_PATHS.addDelegatee);
                    },
                  }}
                  customStyles={{
                    position: 'fixed',
                    left: 0,
                    bottom: 0,
                    px: 2,
                    ...(isDesktop && {
                      px: 22,
                      borderRadius: '24px 24px 0px 0px',
                      boxShadow: theme.boxShadow.important,
                    }),
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>
      </>

      <CancelDialog
        open={openRemoveDialog}
        handleConfirmAction={() => {
          removeDelegateeAction();
        }}
        handleCloseDialog={() => {
          setOpenRemoveDialog(false);
          setDelegateeIdWantToRemove(null);
        }}
        dialogContent={{
          title: en.userProfile.delegation.removeDelegatee,
          message: en.userProfile.delegation.removeDelegateeDesc,
          yesTitle: en.common.remove,
          noTitle: en.common.cancel,
        }}
      />

      <SnackbarComponent
        open={openSnackbar}
        toastNotification={en.userProfile.delegation.delegateeRemoved}
        IconComponent={<CheckMarkIcon />}
        handleClose={() => {}}
        customStyles={{ mb: 10 }}
      />
    </>
  );
};

export default DelegationDetailsContainer;
