import { FormErrors } from 'redux-form';
import { validateRequiredFields, isLevelZEmployee } from '../../../helpers';
import { IDtTravelDetails } from '../../../interfaces';

const requiredFields = ['purpose', 'description', 'costCentre', 'accountCode'];

export const validate = (values: IDtTravelDetails, props: any): FormErrors<IDtTravelDetails> => {
  const errors: FormErrors<IDtTravelDetails> = {};
  const isLevelZUser = isLevelZEmployee(props);

  // Level Z user will not validate endorserGalaCXyId field
  validateRequiredFields(values, isLevelZUser ? requiredFields : requiredFields.concat('endorserGalaCXyId'), errors);

  return errors;
};
