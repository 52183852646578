import { useEffect, useState } from 'react';
import { Typography, Grid, useTheme, Box, IconButton, Collapse } from '@mui/material';

import en from '../../../../translations/en';
import { DownArrowInheritIcon, UpArrowInheritIcon } from '../../../../assets/images';

import { ILtFare, ILtTravellers } from '../../../../interfaces';

import { formatPrice, getDisplayVal, toCapitalize } from '../../../../helpers';

import { useAppSelector } from '../../../../app/hooks';
import { selectApp } from '../../../../slice/appSlice';

import { ShadowContent } from '../../../../components';

const ViewTicketCostDetails = ({ currency, fareDetails }: { currency: string; fareDetails: ILtFare }) => {
  const theme = useTheme();
  const { isDesktop } = useAppSelector(selectApp) || {};

  const [isExpand, setIsExpand] = useState(false);
  const newFareDetails: ILtTravellers[] = [];
  const fareDetailPairs = Object.entries(fareDetails) || {};
  fareDetailPairs.forEach(([key, values]) => {
    const item = {
      travelerType: key,
      ...values,
    };
    newFareDetails.push(item);
  });
  const [grandTotal, setGrandTotal] = useState(0);
  const [totalNoOfPassengers, setTotalNoOfPassengers] = useState(0);

  useEffect(() => {
    let tmpTotalFare = 0;
    let tmpNumberOfPax = 0;
    newFareDetails.forEach((ticket: ILtTravellers) => {
      tmpTotalFare += ticket.totalFare;
      tmpNumberOfPax += ticket.numberOfPax;
      setGrandTotal(tmpTotalFare);
      setTotalNoOfPassengers(tmpNumberOfPax);
    });
  }, [fareDetails]);

  const CurrencyLabel = () => {
    return (
      <Typography variant="navigation_medium" sx={{ display: 'inline', px: 0.5 }}>
        {currency || ''}
      </Typography>
    );
  };

  const TicketDetail = ({ fareDetail }: { fareDetail: ILtTravellers }) => {
    return (
      <>
        <Grid container xs={12} columnSpacing={1} rowSpacing={0} pt={2} pb={1}>
          <Grid xs={6} px={1} pb={1}>
            <Typography variant="navigation_regular" color={theme.color.secondary.grey.option_2}>
              {en.booking.flightConfirmation.LTFareDetails.travelerType}
            </Typography>
            <Typography variant="body_1_medium" color={theme.color.secondary.dark_grey.option_3}>
              {getDisplayVal(toCapitalize(fareDetail?.travelerType))}
            </Typography>
          </Grid>
          <Grid xs={6} px={1} pb={1}>
            <Typography variant="navigation_regular" color={theme.color.secondary.grey.option_2}>
              {en.booking.flightConfirmation.LTFareDetails.noOfPassengers}
            </Typography>
            <Typography variant="body_1_medium" color={theme.color.secondary.dark_grey.option_3}>
              {getDisplayVal(fareDetail?.numberOfPax)}
            </Typography>
          </Grid>
          <Grid xs={6} px={1} pb={1}>
            <Typography variant="navigation_regular" color={theme.color.secondary.grey.option_2}>
              {en.booking.flightConfirmation.LTFareDetails.baseFare}
            </Typography>
            <Typography variant="body_1_medium" color={theme.color.secondary.dark_grey.option_3}>
              {getDisplayVal(formatPrice(fareDetail?.baseFare))}
              <CurrencyLabel />
            </Typography>
          </Grid>
          <Grid xs={6} px={1} pb={1}>
            <Typography variant="navigation_regular" color={theme.color.secondary.grey.option_2}>
              {en.booking.flightConfirmation.LTFareDetails.tax}
            </Typography>
            <Typography variant="body_1_medium" color={theme.color.secondary.dark_grey.option_3}>
              {getDisplayVal(formatPrice(fareDetail?.totalTax))}
              <CurrencyLabel />
            </Typography>
          </Grid>
          <Grid xs={6} px={1} pb={1}>
            <Typography variant="navigation_regular" color={theme.color.secondary.grey.option_2}>
              {en.booking.flightConfirmation.LTFareDetails.total}
            </Typography>
            <Typography variant="body_1_medium" color={theme.color.secondary.dark_grey.option_3}>
              {getDisplayVal(formatPrice(fareDetail?.totalFare))}
              <CurrencyLabel />
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ m: 0, p: 0, bgcolor: '#EBEDEC', height: '1px' }} />
      </>
    );
  };

  return (
    <Box
      component={ShadowContent}
      px={1}
      sx={{
        maxWidth: isDesktop ? '518px' : 'none',
        margin: 'auto',
        mb: 2,
        pb: 0,
        pt: 2,
      }}
    >
      <Box
        component={Typography}
        variant="body_2_medium"
        sx={{
          px: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="body_1_bold">{en.booking.flightConfirmation.LTFareDetails.title}</Typography>
          <IconButton sx={{ py: 0 }} onClick={() => setIsExpand(!isExpand)}>
            {isExpand ? (
              <UpArrowInheritIcon fill={theme.palette.text.primary} />
            ) : (
              <DownArrowInheritIcon fill={theme.palette.text.primary} />
            )}
          </IconButton>
        </Box>

        <Collapse in={isExpand}>
          {newFareDetails &&
            newFareDetails.map((fareDetail: ILtTravellers, index: number) => {
              return <TicketDetail fareDetail={fareDetail} key={index} />;
            })}
        </Collapse>

        <Grid container rowSpacing={0} columnSpacing={1} sx={{ my: 2 }}>
          <Grid xs={6} pl={1}>
            <Typography variant="navigation_regular" color={theme.color.secondary.grey.option_2}>
              {en.booking.flightConfirmation.LTFareDetails.grandTotal}
            </Typography>
            <Typography variant="body_1_medium" color={theme.color.secondary.dark_grey.option_3}>
              {getDisplayVal(formatPrice(grandTotal))}
              <CurrencyLabel />
            </Typography>
          </Grid>
          <Grid xs={6} px={0.5}>
            <Typography variant="navigation_regular" color={theme.color.secondary.grey.option_2}>
              {en.booking.flightConfirmation.LTFareDetails.totalNoOfPassengers}
            </Typography>
            <Typography variant="body_1_medium" color={theme.color.secondary.dark_grey.option_3}>
              {totalNoOfPassengers}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ViewTicketCostDetails;
