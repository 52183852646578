import { NavLink, Link } from 'react-router-dom';
import { styled } from '@mui/system';
import { Theme } from '@mui/material/styles';

const ETPNavLink = styled(NavLink)({
  textDecoration: 'none',
});

const ETPLink = styled(Link)(
  ({ theme }: { theme: Theme }) => `
    text-decoration: none;
    color: ${theme.status.selected};`,
);

export { ETPNavLink, ETPLink };
