import React, { useMemo } from 'react';
import { Box, useTheme } from '@mui/material';
import _ from 'lodash';

import en from '../../../translations/en';
import { imgAdminTableArrowRight } from '../../../assets/images';

import { IAdminEmployee } from '../../../interfaces';

import { BaseTable } from '../../../components';

const AdminEmployeeList = ({
  dataList,
  handleRowDataOnClick,
}: {
  dataList: IAdminEmployee[];
  handleRowDataOnClick?: (rowData: any) => void;
}) => {
  const theme = useTheme();
  const fieldLabel = en.admin.feature.maintainEmployeeDetails.fields;

  const tableSchema = [
    {
      key: 'regulatoryRegion',
      isHiddenField: false,
    },
    {
      key: 'employeeClassification',
      isHiddenField: false,
    },
    {
      key: 'terminationActionReason',
      isHiddenField: false,
    },
    {
      key: 'revision',
      isHiddenField: false,
    },
    {
      key: 'isAccessible',
      isHiddenField: false,
    },
  ];
  const transformedTableHeaders = useMemo(() => {
    return tableSchema.map((item, index) => {
      return {
        label: fieldLabel?.[item.key as keyof typeof fieldLabel],
        sxProps: {
          pb: 0.25,
          flex: index + 1 === 4 ? 2 : 1,
          fontSize: theme.typography.body_2_light.fontSize,
          display: 'flex',
          alignItems: 'center',
          px: 0,
        },
        isHiddenField: item.isHiddenField,
      };
    });
  }, [dataList]);

  const transformedTableContents = useMemo(() => {
    const tmpData = _.cloneDeep(dataList || []);
    return tmpData.map((dataItem: any) => {
      const { profile } = dataItem;
      return {
        employee: dataItem,
        sxProps: {
          display: 'flex',
          position: 'relative',
          '&:: after': {
            content: `""`,
            width: '28px',
            height: '28px',
            position: 'absolute',
            right: 0,
            transform: 'translateY(50%)',
            backgroundSize: 'cover',
            backgroundImage: `url(${imgAdminTableArrowRight})`,
            opacity: profile.isAccessible ? 1 : 0.5,
          },
        },
        columns: tableSchema.map(({ key, isHiddenField }, index) => {
          let columnSxProps;
          let cellTextDecoration;
          let newValue = profile[key as keyof IAdminEmployee] ?? '--';

          if (!profile.isAccessible) {
            columnSxProps = { color: theme.backgroundColor.popup };
            if (key === 'isAccessible') {
              newValue = profile.isAccessible ? '' : en.admin.feature.maintainEmployeeDetails.noPermission;
              cellTextDecoration = {
                px: 2,
                py: 1,
                borderRadius: 0.5,
                lineHeight: '12px',
                color: theme.color.secondary.dark_grey.option_3,
                backgroundColor: theme.color.utility.alert.option_3,
                fontSize: theme.typography.navigation_regular.fontSize,
              };
            }
          }

          return {
            columnkey: key,
            value: newValue,
            sxProps: {
              px: 0,
              py: '10px',
              height: '60px',
              maxHeight: '60px',
              overflow: 'hidden',
              cursor: 'pointer',
              flex: index + 1 === 4 ? 2 : 1,
              lineHeight: '22px',
              display: 'flex',
              alignItems: 'center',
              ...columnSxProps,
            },
            cellTextDecoration,
            isHiddenField,
            columnOnClick: dataItem?.handleColumnOnClick,
          };
        }),
        rowOnClick: handleRowDataOnClick,
      };
    });
  }, [dataList]);

  return (
    <Box
      className="employee_list"
      sx={{
        mt: 4,
        width: '100%',
      }}
    >
      <BaseTable
        tableHeaders={transformedTableHeaders}
        tableHeadersSXProps={{ display: 'flex', mt: 2 }}
        tableContents={transformedTableContents}
      />
    </Box>
  );
};

export default AdminEmployeeList;
