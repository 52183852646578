import React, { useEffect, useState, useMemo } from 'react';
import { Box, Drawer, SxProps, Theme } from '@mui/material';
import { use100vh } from 'react-div-100vh';

import { DateRangePicker, DateSinglePicker, FormFieldTextInput } from '..';

import { Footer, Header } from '../../components';

import { BOOKING_PATHS } from '../../constants/paths';
import { DATE_FORMAT, UI_STYLES } from '../../constants/constants';
import { formatDateAsString, formatStringToDate } from '../../helpers';
import { useAppSelector } from '../../app/hooks';
import { selectApp } from '../../slice/appSlice';

import en from '../../translations/en';
import { RangeKeyDict } from 'react-date-range';

const Container = ({
  title,
  value,
  isRangeDate,
  containerStyles,
  contentStyles,
  onChange,
  handleDateRangeChange,
  dateRangePickerOnChangeCallback,
  calendarDefaultDate,
  calendarFootButtonText,
  minDate,
  maxDate,
  rangeStartDate,
  rangeEndDate,
  shownDate,
  ...props
}: {
  title?: string;
  value: string;
  isRangeDate?: boolean;
  containerStyles?: { [key: string]: SxProps<Theme> };
  contentStyles?: { [key: string]: SxProps<Theme> };
  onChange: (value?: string) => void;
  handleDateRangeChange: (date: RangeKeyDict) => void;
  dateRangePickerOnChangeCallback?: (date: RangeKeyDict) => void;
  calendarDefaultDate?: Date;
  calendarFootButtonText?: string;
  minDate?: Date;
  maxDate?: Date;
  rangeStartDate?: Date;
  rangeEndDate?: Date;
  shownDate?: Date;
  [key: string]: any;
}) => {
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string | undefined>(value);
  const [displayDate, setDisplayDate] = useState<string | undefined>(value);
  const [rangeDate, setRangeDate] = useState<RangeKeyDict>();

  useEffect(() => {
    setSelectedDate(value);
  }, [value]);

  const screenHeight = use100vh();
  const { isDesktop } = useAppSelector(selectApp) || {};
  const calendarHeight = useMemo(() => {
    let height = 0;
    if (screenHeight) {
      if (isDesktop) {
        const surplusHeight = 258; // 258 = dateTop(26) + dateNavHeight(30) + calendarOffset(50) + footerButton(92) + rdrMonthAndYearWrapper(60);
        if (screenHeight > 715 + 32) {
          height = 715 - surplusHeight;
        } else {
          height = screenHeight - 32 - surplusHeight;
        }
      } else {
        height = screenHeight - (contentStyles?.top === 0 ? 247 : 303);
      }
    }
    return height;
  }, [screenHeight, isDesktop]);

  useEffect(() => {
    if (!selectedDate) {
      setDisplayDate(formatDateAsString(selectedDate, DATE_FORMAT.date));
    } else {
      // scenario: if selectedDate is empty (not select new range, just click Confirm button)
      // need to fill display date
      if (isRangeDate) {
        setDisplayDate(
          `${formatDateAsString(rangeDate?.rangePicker.startDate, DATE_FORMAT.date)} - ${formatDateAsString(
            rangeDate?.rangePicker.endDate,
            DATE_FORMAT.date,
          )}`,
        );
      } else {
        setDisplayDate(formatDateAsString(selectedDate, DATE_FORMAT.date));
      }
    }
  }, [selectedDate]);

  useEffect(() => {
    !value && calendarDefaultDate && setDisplayDate(formatDateAsString(calendarDefaultDate, DATE_FORMAT.date));
  }, [open]);

  const isFooterValid = () => {
    if (isRangeDate) {
      return !!rangeDate || (rangeStartDate && rangeEndDate);
    }
    return (
      ![undefined, selectedDate].includes(displayDate) &&
      displayDate !== formatDateAsString(selectedDate, DATE_FORMAT.date)
    );
  };

  return (
    <>
      <Box
        sx={{ display: 'flex', alignItems: 'center' }}
        onClick={() => {
          setOpen(true);
        }}
      >
        <FormFieldTextInput
          label={title}
          sx={{ flex: 1 }}
          color="info"
          variant="filled"
          value={value}
          placeholder={title}
          autoComplete="off"
          InputProps={{
            disableUnderline: true,
          }}
          inputProps={{
            readOnly: true,
          }}
          {...props}
        />
      </Box>

      <Drawer
        variant={isDesktop ? 'persistent' : 'temporary'}
        anchor="bottom"
        hideBackdrop
        sx={{
          '&.MuiDrawer-root': {
            ...(open && containerStyles),
            '& .MuiDrawer-paper': {
              background: 'white !important',
              ...(isDesktop
                ? {
                    maxWidth: '478px',
                    top: '0',
                    bottom: 'auto',
                    left: '0',
                    borderRadius: 1,
                    backgroundColor: 'white',
                    position: 'absolute',
                    maxHeight: '715px',
                  }
                : {
                    borderTopLeftRadius: '24px',
                    borderTopRightRadius: '24px',
                    top: UI_STYLES.overlayHeaderHeightSearchCriteria,
                  }),
              ...contentStyles,
            },
          },
        }}
        open={open}
      >
        {open && (
          <Box
            sx={{
              px: 2,
              pt: 4,
              // TODO: Filter currently commented for demo
              // overflow: "hidden",
            }}
          >
            <Header
              handleOnBack={() => {
                if (!selectedDate) {
                  setDisplayDate(undefined);
                } else {
                  setDisplayDate(formatDateAsString(selectedDate, DATE_FORMAT.date));
                }
                setOpen(false);
              }}
            />

            {isRangeDate ? (
              <DateRangePicker
                isDesktop={false}
                minDate={minDate}
                maxDate={maxDate}
                rangeStartDate={rangeStartDate}
                rangeEndDate={rangeEndDate}
                shownDate={shownDate}
                onChange={(date: RangeKeyDict) => {
                  if (
                    date.rangePicker &&
                    formatDateAsString(date.rangePicker.startDate, DATE_FORMAT.date) !==
                      formatDateAsString(date.rangePicker.endDate, DATE_FORMAT.date)
                  ) {
                    // if startDate === endDate, cannot go Confirm
                    setRangeDate(date);
                    setDisplayDate(
                      `${formatDateAsString(date.rangePicker.startDate, DATE_FORMAT.date)} - ${formatDateAsString(
                        date.rangePicker.endDate,
                        DATE_FORMAT.date,
                      )}`,
                    );
                  } else {
                    setRangeDate(undefined);
                    setDisplayDate(undefined);
                  }

                  dateRangePickerOnChangeCallback?.(date);
                }}
                calendarHeight={calendarHeight}
                showMonthAndYearDropDown={true}
                {...props}
              />
            ) : (
              <DateSinglePicker
                isDesktop={false}
                defaultDate={displayDate ? formatStringToDate(displayDate, DATE_FORMAT.date) : undefined}
                onChange={(date: Date) => {
                  setDisplayDate(formatDateAsString(date, DATE_FORMAT.date));
                }}
                calendarHeight={calendarHeight}
                showMonthAndYearDropDown={true}
                minDate={minDate}
                maxDate={maxDate}
                {...props}
              />
            )}

            <Footer
              primaryBtn={
                isFooterValid()
                  ? {
                      isFullWidth: true,
                      text: calendarFootButtonText || en.common.update,
                      route: BOOKING_PATHS.travelTypeSelection,
                      customOnClick: () => {
                        !!rangeDate && handleDateRangeChange?.(rangeDate);
                        onChange?.(displayDate);
                        setSelectedDate(displayDate);
                        setOpen(false);
                      },
                    }
                  : undefined
              }
            />
          </Box>
        )}
      </Drawer>
    </>
  );
};

const ReduxFormDatePicker = ({ input: { name, value, onChange }, ...props }: any) => {
  return (
    <Container name={name} value={formatDateAsString(value, DATE_FORMAT.ddmmmyyyy)} onChange={onChange} {...props} />
  );
};

Container.defaultProps = {
  title: '',
  isRangeDate: false,
  containerStyles: {},
  contentStyles: {},
};

export default ReduxFormDatePicker;
