import React, { useEffect, useState, useCallback, useRef, useContext } from 'react';
import { Grid, Box } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';

import en from '../../translations/en';
import { SAVE_DATA_TO, UI_METRICS_IN_PX, USER_ROLE } from '../../constants/constants';
import { BOOKING_PATHS, ROOT_PATHS } from '../../constants/paths';

import { OVERLAY_VARIANT } from '../../containers/BookingFlow/Common/BookingFlowOverlay';

import { retrieveUserModeStatus } from '../../helpers';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getProfileThunk, selectUser, resetPaymentHistoryPage } from '../../slice/userSlice';
import { selectAuth } from '../../slice/authSlice';
import { reset as resetBookingSlice, selectBooking } from '../../slice/bookingSlice';
import { reset as resetBookingUserSlice } from '../../slice/bookingUserSlice';
import { reset as resetBookingFilterSlice } from '../../slice/bookingFilterSlice';
import { getAirportsThunk, getConfigurationsThunk, selectConfiguration } from '../../slice/configurationSlice';
import { selectApp } from '../../slice/appSlice';
import { setIsOpenFlownSuspensionDialog } from '../../slice/flownSuspensionSlice';

import { TravelTypeListLtAction } from '../../context';
import { showFlownSuspensionAlertFlag } from '../../config/featureFlags';

import { ScrollableView, MobileView, DesktopView, BottomNavBar, Div100vhView } from '../../components';
import {
  DateSelection,
  BookingFlowContainer,
  SearchBar,
  ConcessionSelectionContainer,
  LocationSelection,
  UpComingBookingLayout,
  AdminFeatureLayout,
  DesktopHeader,
  MobileHeader,
  MobileSearchCriteriaBox,
} from '../../containers';

const Home = () => {
  const dispatch = useAppDispatch();

  const history = useHistory();
  const location = useLocation<{
    isKeepSearchCriteria?: boolean;
    performShowPopup?: boolean;
  }>();

  const { ern } = useAppSelector(selectAuth) || {};
  const { isDesktop } = useAppSelector(selectApp) || {};
  const { viewBookingApplicationId, bookingStep } = useAppSelector(selectBooking) || {};
  const { role, adminRoles, profile } = useAppSelector(selectUser) || {};
  const { airports, configurations } = useAppSelector(selectConfiguration) || {};
  const { setIsShowDefaultTravelTypeOption } = useContext(TravelTypeListLtAction);

  const [showFlowView, setShowFlowView] = useState(false);

  const [isReachScrollOffset, setIsReachScrollOffset] = useState(false);

  const [curShowPop, setCurShowPop] = useState<string>('');

  const scrollViewRef = useRef<HTMLDivElement>(null);

  const isConfigurationRefreshOnReload = window.config?.isConfigurationRefreshOnReload;
  const { type: roleType } = role || {};

  const toggleSticky = useCallback(
    (scrollTop) => {
      if (scrollTop >= UI_METRICS_IN_PX.mobileHeaderHeight) {
        setIsReachScrollOffset(true);
      } else {
        setIsReachScrollOffset(false);
      }
    },
    [isReachScrollOffset],
  );

  useEffect(() => {
    // (1) Clear slice data
    if (!isKeepSearchCriteria && !viewBookingApplicationId && !bookingStep) {
      dispatch(resetBookingSlice());
    }
    dispatch(resetBookingUserSlice());
    dispatch(resetPaymentHistoryPage());
    dispatch(resetBookingFilterSlice());

    // (2) calling APIs
    if (isConfigurationRefreshOnReload) {
      // (2.1) get the airport and configuration on every entry to home screen
      dispatch(getAirportsThunk());
      dispatch(getConfigurationsThunk());
    } else {
      if (isEmpty(airports) || isEmpty(configurations)) {
        // (2.2) get the airport and configuration only when airport and configuration is empty
        dispatch(getAirportsThunk());
        dispatch(getConfigurationsThunk());
      }
    }
    dispatch(getProfileThunk(ern));

    // (3) Retrieve user mode
    retrieveUserModeStatus(ern, dispatch);

    // (4) clear the selected concession selection option
    setIsShowDefaultTravelTypeOption(false);
  }, [ern]);

  const isKeepSearchCriteria = location.state?.isKeepSearchCriteria || false;
  const performShowPopup = location.state?.performShowPopup || false;

  const resetRoute = () => {
    history.replace(ROOT_PATHS.landing);
  };

  useEffect(() => {
    const isNotShowFlownSuspensionPopup = roleType === USER_ROLE.admin || roleType === USER_ROLE.delegation || false;
    if (
      (profile?.isShowFlownSuspensionAlert || showFlownSuspensionAlertFlag.isActive) &&
      !isNotShowFlownSuspensionPopup
    ) {
      dispatch(setIsOpenFlownSuspensionDialog(true));
    } else {
      dispatch(setIsOpenFlownSuspensionDialog(false));
    }
  }, [profile?.isShowFlownSuspensionAlert, role]);

  useEffect(() => {
    // (1) reset route
    resetRoute();

    // (2) add scroll event listener
    if (!showFlowView) {
      // (a) reset flag
      setIsReachScrollOffset(false);

      // (b) add event listener
      const { current } = scrollViewRef || {};

      const handleScroll = () => {
        if (current) {
          toggleSticky(current.scrollTop);
        }
      };

      if (current) {
        current.addEventListener('scroll', handleScroll);
      }

      return () => {
        current && current.removeEventListener('scroll', handleScroll);
      };
    }
  }, [showFlowView]);

  useEffect(() => {
    isDesktop && setShowFlowView(false);
    resetRoute();
  }, [isDesktop]);

  //  Booking Flow Screen
  if (showFlowView) {
    return (
      <BookingFlowContainer
        variant={OVERLAY_VARIANT.SEARCH_CRITERIA}
        routePages={[
          {
            component: LocationSelection,
            title: en.home.whereAreYouFlyingTo,
          },
          {
            component: DateSelection,
            title: en.home.whenWillYouBeThere,
          },
          {
            component: ConcessionSelectionContainer,
            title: en.home.howTravel,
          },
        ]}
        handleClose={() => {
          setShowFlowView(false);
          resetRoute();
        }}
      />
    );
  }

  const isAdminRole = role.type === USER_ROLE.admin;
  const isSelfDelegationRole = [USER_ROLE.self, USER_ROLE.delegation].includes(role.type);

  //  Home Screen
  return (
    <Div100vhView
      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        setCurShowPop('');
      }}
    >
      <Box component={ScrollableView} ref={scrollViewRef}>
        <Grid container>
          <MobileView component={Grid} xs={12}>
            <MobileHeader isReachScrollOffset={isReachScrollOffset} />
            <MobileSearchCriteriaBox
              saveTo={SAVE_DATA_TO.redux}
              handleSearchClick={() => history.push(BOOKING_PATHS.booking)}
              customStyles={{
                mt: 1.5,
                mb: 4,
                mx: 2,
              }}
            />
          </MobileView>

          <DesktopView sx={{ width: 1 }}>
            <DesktopHeader />
            {isAdminRole ? (
              adminRoles[0].access.createBooking && (
                <SearchBar curShowPop={curShowPop} setCurShowPop={setCurShowPop} performShowPopup={performShowPopup} />
              )
            ) : (
              <SearchBar curShowPop={curShowPop} setCurShowPop={setCurShowPop} performShowPopup={performShowPopup} />
            )}
          </DesktopView>
          {isSelfDelegationRole && <UpComingBookingLayout scrollViewRef={scrollViewRef} />}
        </Grid>
        {isDesktop && isAdminRole && <AdminFeatureLayout />}
      </Box>
      {!isDesktop && !showFlowView && <BottomNavBar />}
    </Div100vhView>
  );
};

export default Home;
