import { cloneDeep } from "lodash";
import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Grid,
  Box,
  Button,
  Popover,
  Typography,
  Divider,
  useTheme,
} from "@mui/material";

import en from "../../translations/en";
import { ROOT_PATHS, USER_PROFILE_PATHS } from "../../constants/paths";
import { USER_ROLE } from "../../constants/constants";
import {
  ProfileIcon,
  ProfileLogoIcon,
  AdminKeyIcon,
  DelegationKeyIcon,
  SignOutIcon,
  SelectionArrowRightIcon,
} from "../../assets/images";

import {
  getShortDisplayName,
  getFullDisplayName,
  logout,
  retrieveUserModeStatus,
  stringAlphabeticSort,
} from "../../helpers";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectUser } from "../../slice/userSlice";
import { selectApp } from "../../slice/appSlice";

import { changeRole } from "../../services/user";

import { IAdminRole, IDelegationRole } from "../../interfaces";

import { RoleItems } from "..";

const RoleSelectionPopover = ({ isHomePage }: { isHomePage: boolean }) => {
  const dispatch = useAppDispatch();

  const history = useHistory();

  const location = useLocation();

  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { isDesktop } = useAppSelector(selectApp) || {};
  const { profile, role, adminRoles, delegatedBy } =
    useAppSelector(selectUser) || {};
  const { personalInfo } = profile || {};
  const { preferredFirstName, firstName, lastName, middleName } =
    personalInfo || {};
  const { type: roleType } = role || {};
  const isAdminDelegation = [USER_ROLE.admin, USER_ROLE.delegation].includes(
    roleType
  );

  const profileColor = isHomePage
    ? "white"
    : theme.color.secondary.dark_grey.option_3;

  useEffect(() => {
    setAnchorEl(null);
  }, [isDesktop]);

  const displayName = getShortDisplayName({
    preferredFirstName,
    firstName,
    lastName,
    middleName,
  });

  const handleOnClick = (type: string, id: string) => {
    return async () => {
      const { user } = await changeRole(id, type);
      await retrieveUserModeStatus(user.ern, dispatch);
      setAnchorEl(null);
      if (location.pathname !== ROOT_PATHS.landing) {
        history.push(ROOT_PATHS.landing);
      } else {
        history.go(0);
      }
    };
  };

  const adminRolesItems = adminRoles
    ?.filter((item: IAdminRole) => role.name !== item.name)
    ?.sort((a: IAdminRole, b: IAdminRole) =>
      stringAlphabeticSort(a.name, b.name)
    )
    .map((r: IAdminRole, index: number) => {
      return (
        <RoleItems
          key={index}
          text={r.name}
          handleOnClick={handleOnClick(USER_ROLE.admin, r.id)}
          customStyles={index === 0 ? { mt: 1.5 } : {}}
        />
      );
    });

  const delegatedByItems = cloneDeep(delegatedBy)
    ?.filter((item: IDelegationRole) => item.employeeId !== role.id)
    ?.map((item: IDelegationRole) => {
      item["name"] = getFullDisplayName(item);
      return item;
    })
    ?.sort((a: IDelegationRole, b: IDelegationRole) =>
      stringAlphabeticSort(a.name, b.name)
    )
    .map((r: IDelegationRole, index: number) => {
      return (
        <RoleItems
          key={r.employeeId}
          text={r.name}
          handleOnClick={handleOnClick(USER_ROLE.delegation, r.employeeId)}
          customStyles={index === 0 ? { mt: 1.5 } : {}}
        />
      );
    });

  const open = Boolean(anchorEl);

  return (
    <>
      <Button
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
        size="medium"
        startIcon={
          <ProfileIcon height="20px" width="20px" fill={profileColor} />
        }
        sx={{ px: 1.5, py: 0 }}
      >
        <Box
          sx={{
            typography: "body",
            color: profileColor,
            fontSize: "16px",
            fontWeight: "medium",
          }}
          display={{ xs: "none", sm: "block" }}
        >
          {displayName}
        </Box>
      </Button>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          mt: 1.5,
          zIndex: 1200,
        }}
      >
        <Grid
          container
          direction="column"
          sx={{
            width: "301px",
            p: 2,
            borderRadius: "8px",
            background: "white",
            boxShadow: theme.boxShadow.important,
          }}
        >
          {!isAdminDelegation && (
            <>
              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={() => history.push(USER_PROFILE_PATHS.menu)}
              >
                <ProfileLogoIcon />
                <Typography
                  sx={{ color: theme.color.secondary.grey.option_1 }}
                  variant="body_1_medium"
                >
                  {en.accountMenu.profile}
                </Typography>
                <SelectionArrowRightIcon
                  style={{ marginLeft: "auto" }}
                  color={theme.color.secondary.grey.option_1}
                />
              </Box>
              <Divider
                sx={{
                  my: 2,
                }}
              />
            </>
          )}
          {roleType !== USER_ROLE.self && (
            <>
              <Box sx={{ display: "flex", gap: "8px" }}>
                <ProfileLogoIcon />
                <Typography
                  sx={{ color: theme.color.secondary.grey.option_1 }}
                  variant="body_1_medium"
                >
                  {en.accountMenu.personalAccount}
                </Typography>
              </Box>
              <RoleItems
                text={displayName}
                handleOnClick={handleOnClick(USER_ROLE.self, USER_ROLE.self)}
                customStyles={{ mt: 1.5 }}
              />
              <Divider
                sx={{
                  my: 2,
                }}
              />
            </>
          )}

          {delegatedByItems?.length > 0 && (
            <>
              <Box sx={{ display: "flex", gap: "8px" }}>
                <DelegationKeyIcon />
                <Typography
                  sx={{ color: theme.color.secondary.grey.option_1 }}
                  variant="body_1_medium"
                >
                  {en.accountMenu.delegation}
                </Typography>
              </Box>
              {delegatedByItems}
              <Divider
                sx={{
                  my: 2,
                }}
              />
            </>
          )}

          {adminRolesItems?.length > 0 && (
            <>
              <Box sx={{ display: "flex", gap: "8px" }}>
                <AdminKeyIcon />
                <Typography
                  sx={{ color: theme.color.secondary.grey.option_1 }}
                  variant="body_1_medium"
                >
                  {en.accountMenu.admin}
                </Typography>
              </Box>
              {adminRolesItems}
              <Divider
                sx={{
                  my: 2,
                }}
              />
            </>
          )}

          <Box
            sx={{
              display: "flex",
              gap: "8px",
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => logout(dispatch)}
          >
            <SignOutIcon />
            <Typography
              sx={{ color: theme.color.secondary.grey.option_1 }}
              variant="body_1_medium"
            >
              {en.accountMenu.signOut}
            </Typography>
          </Box>
        </Grid>
      </Popover>
    </>
  );
};

export default RoleSelectionPopover;
