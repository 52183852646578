import React, { useContext, Dispatch, SetStateAction } from 'react';
import { use100vh } from 'react-div-100vh';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, useTheme } from '@mui/material';

import { COMMON_API } from '../../constants/api';

import { checkValidDateRange } from '../../helpers';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { reset, selectApp } from '../../slice/appSlice';
import { selectAuth } from '../../slice/authSlice';
import { setPeriod, setTravelTypeFilter } from '../../slice/bookingFilterSlice';
import { EditBookingFilterAction, EditBookingFilterState } from '../../context/BookingFilterContext';

import { getMyBookings } from '../../services/booking';

import { ViewBookingDetails } from '..';
import { FormButton } from '../../components';

interface IDialogContent {
  title?: string;
  yesTitle: string;
  noTitle?: string;
  bookingResult?: any;
}

const MyBookingFilterDialog = ({
  open,
  handleCloseDialog,
  setSearchResult,
  dialogContent,
}: {
  open: boolean;
  handleCloseDialog: () => void;
  setSearchResult: Dispatch<SetStateAction<string[] | null | undefined>>;
  dialogContent: IDialogContent;
}) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const screenHeight = use100vh();
  const { isDesktop } = useAppSelector(selectApp) || {};
  const { ern } = useAppSelector(selectAuth) || {};

  const editBookingFilter = useContext(EditBookingFilterState) || {};
  const { editTravelTypes, editPeriod } = editBookingFilter || {};
  const { from, to } = editPeriod || {};

  const editBookingAction = useContext(EditBookingFilterAction) || {};
  const { resetBookingFilter } = editBookingAction || {};

  const { yesTitle, noTitle } = dialogContent || {};
  const isApplyButtonValid = !!from || !!to ? !!from && !!to && checkValidDateRange(from, to) : true;

  return (
    <Dialog
      sx={{
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        '& .MuiDialog-container': {
          alignItems: 'baseline',
          '& .MuiPaper-root': {
            my: 'auto',
            width: '100%',
            height: '715px',
            maxWidth: isDesktop ? '478px' : '343px',
            maxHeight: `calc(${screenHeight}px - 32px)`,
            borderRadius: 1,
            boxShadow: theme.boxShadow.important,
            overflow: 'hidden',
          },
        },
      }}
      open={open}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <DialogContent sx={{ p: 0, width: '100%' }}>
          <DialogContentText
            variant="body_1_regular"
            sx={{
              overflow: 'hidden',
              textAlign: 'center',
              width: '100%',
              color: theme.palette.text.secondary,
            }}
          >
            <ViewBookingDetails />
          </DialogContentText>
        </DialogContent>
      </Box>
      <Box
        sx={{
          background: 'white',
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          p: 2,
        }}
      >
        <DialogActions
          sx={{
            p: 0,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <FormButton
            theme={theme}
            colour="transparent"
            size="large"
            sx={{
              pl: 0,
              height: '44px',
              display: 'flex',
              justifyContent: 'center',
              ...{ minWidth: 0 },
            }}
            onClick={() => {
              resetBookingFilter();
              dispatch(reset());
            }}
          >
            {noTitle}
          </FormButton>

          {isApplyButtonValid && (
            <FormButton
              theme={theme}
              colour="green"
              variant="contained"
              color="success"
              size="large"
              customStyles={{
                borderRadius: 8,
                width: '128px',
                height: '44px',
              }}
              onClick={async () => {
                dispatch(setTravelTypeFilter(editTravelTypes));
                dispatch(setPeriod(editPeriod));

                const myBookings = await getMyBookings({
                  userId: ern,
                  from: 0,
                  path: COMMON_API.myBooking,
                  startDate: editPeriod?.from,
                  endDate: editPeriod?.to,
                  type: JSON.stringify(editTravelTypes),
                  isSearchAndFilter: true,
                });
                setSearchResult(myBookings);

                handleCloseDialog?.();
              }}
              sx={{
                '&:not(:first-of-type), &:first-of-type': {
                  ml: 0,
                },
              }}
            >
              {yesTitle}
            </FormButton>
          )}
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default MyBookingFilterDialog;
