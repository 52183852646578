import { FormErrors } from 'redux-form';

import { REGEX } from '../../../../../constants/constants';
import { validateRegex, validateRequiredFields } from '../../../../../helpers/validation';
import { IContactDetails } from '../../../../../interfaces';
import en from '../../../../../translations/en';

export const validate = (values: IContactDetails | null): FormErrors<IContactDetails | null> => {
  const errors: FormErrors<IContactDetails | null> = {};

  if (values) {
    validateRequiredFields(values, ['phoneNumber', 'email'], errors);

    if (values.email && !validateRegex(values.email, REGEX.email)) {
      errors.email = en.error.invalidEmail;
    }
  }

  return errors;
};
