import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

const GenericHeader = ({ carrierLabel }: { carrierLabel: string }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        mx: 1,
      }}
    >
      <Typography
        sx={{
          color: theme.color.secondary.grey.option_1,
          ml: 1,
        }}
        variant="body_1_medium"
      >
        {carrierLabel}
      </Typography>
    </Box>
  );
};

export default GenericHeader;
