import { useContext, useEffect, useMemo } from 'react';

import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { use100vh } from 'react-div-100vh';

import en from '../../translations/en';
import { CloseIcon } from '../../assets/images';
import { UI_STYLES, USER_ROLE } from '../../constants/constants';

import { RADIO_BUTTON_GROUP_VARIANT } from '../../components/Button/RadioButtonGroup';

import { getFilterCount, isHideLtFeatures, getTravelTypeSelectionList } from '../../helpers';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectUser } from '../../slice/userSlice';
import { selectApp } from '../../slice/appSlice';
import { setIsShowMyBookingFilterDialog } from '../../slice/bookingSlice';
import { selectBookingFilter } from '../../slice/bookingFilterSlice';
import { EditBookingFilterAction, EditBookingFilterState } from '../../context/BookingFilterContext';

import { ReduxFormDatePicker, ScrollableView, FilterCount } from '../../components';
import { TravelTypeToggleButtonGroup } from '..';

const ViewBookingDetails = () => {
  const theme = useTheme();
  const screenHeight = use100vh();
  const dispatch = useAppDispatch();

  const { isDesktop } = useAppSelector(selectApp) || {};
  const { concession, role } = useAppSelector(selectUser) || {};
  const { leisureTravel } = concession || {};
  const { travelTypes, period } = useAppSelector(selectBookingFilter) || {};
  const { from, to } = period || {};

  const editBookingData = useContext(EditBookingFilterState) || {};
  const { editTravelTypes, editPeriod } = editBookingData || {};
  const { from: editFrom, to: editTo } = editPeriod || {};
  const { setEditTravelTypes, setEditPeriod } = useContext(EditBookingFilterAction) || {};

  // handle delegation role + empty LT concession data
  const isHideLtFeaturesControl = useMemo(() => {
    return isHideLtFeatures({
      isDelegation: role.type === USER_ROLE.delegation,
      isEmptyLtConcession: leisureTravel?.length === 0,
    });
  }, [role, leisureTravel]);

  const travelTypeSelectionList = useMemo(() => {
    return getTravelTypeSelectionList(isHideLtFeaturesControl);
  }, [isHideLtFeaturesControl]);

  useEffect(() => {
    setEditPeriod({
      from: from,
      to: to,
    });
    setEditTravelTypes(travelTypes);
  }, []);

  return (
    <>
      <Box
        sx={{
          px: 2,
          pt: 3,
          bgcolor: '#FDFDFD',
          borderTopLeftRadius: '24px',
          borderTopRightRadius: '24px',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              mb: 1.5,
            }}
          >
            <Box>
              <Typography
                variant={isDesktop ? 'title_2_bold' : 'headline_medium'}
                sx={{
                  mr: 1,
                  color: theme.palette.gray.dark,
                }}
              >
                {en.common.filter}
              </Typography>
            </Box>
            <FilterCount count={getFilterCount(editBookingData)} />
          </Box>
          {isDesktop && (
            <Box>
              <IconButton onClick={() => dispatch(setIsShowMyBookingFilterDialog(false))} sx={{ p: 0 }}>
                <CloseIcon fill={theme.color.utility.link.option_3} />
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        component={ScrollableView}
        sx={{
          // 26px: overlayHeaderHeightSearchCriteria's space | 50px: Back button height with space
          height: `calc(${screenHeight}px - ${UI_STYLES.overlayHeaderHeightBookingFlow} - ${UI_STYLES.footerHeight} - 32px)`,
          px: 2,
          backgroundColor: 'transparent',
        }}
      >
        <Box
          sx={{
            mb: 1,
            borderRadius: 1,
            backgroundColor: 'white',
          }}
        >
          <Box>
            <Typography
              sx={{ mb: 1, textAlign: 'start' }}
              variant="body_1_medium"
              color={theme.color.secondary.dark_grey.option_3}
            >
              {en.booking.filter.travelType}
            </Typography>
            <TravelTypeToggleButtonGroup
              options={travelTypeSelectionList}
              variant={RADIO_BUTTON_GROUP_VARIANT.CHIP}
              titleVariant="body_2_medium"
              onChange={(value: string[]) => {
                setEditTravelTypes?.(value);
              }}
              value={editTravelTypes}
              isChip
              customButtonStyle={{
                width: 'auto',
                height: '30px',
                border: 'none',
                py: 0,
                px: 1.5,
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'center',
                '&.MuiButtonBase-root': {
                  '&.MuiToggleButtonGroup-grouped': {
                    m: 0,
                    mt: 1,
                    mr: 1,
                    background: theme.color.secondary.light_slate.option_5,
                  },
                  '&.MuiToggleButtonGroup-grouped:last-of-type': {
                    mr: 0,
                  },
                  '.MuiTypography-root': {
                    color: theme.color.secondary.dark_grey.option_3,
                  },
                  '&.Mui-selected, &.Mui-selected:hover': {
                    background: theme.color.utility.link.option_3,
                    '.MuiTypography-root': {
                      color: 'white',
                    },
                  },
                },
              }}
            />
          </Box>

          <Box sx={{ mt: 3 }}>
            <Typography
              sx={{ mb: 1, textAlign: 'start' }}
              variant="body_1_medium"
              color={theme.color.secondary.dark_grey.option_3}
            >
              {en.booking.filter.travelPeriod}
            </Typography>
            <ReduxFormDatePicker
              show={open}
              title={en.common.from}
              calendarFootButtonText={en.common.confirm}
              input={{
                name: 'from',
                value: editFrom || null,
              }}
              minDate={null}
              maxDate={null}
              onChange={(date: Date) => {
                // handle date from range-picker
                if (!editTo) {
                  setEditPeriod({ from: date, to: date });
                } else {
                  setEditPeriod({ ...editPeriod, ...{ from: date } });
                }
              }}
            />
            <ReduxFormDatePicker
              show={open}
              title={en.common.to}
              calendarFootButtonText={en.common.confirm}
              input={{
                name: 'to',
                value: editTo || null,
              }}
              minDate={null}
              maxDate={null}
              onChange={(date: Date) => {
                // handle date from range-picker
                setEditPeriod && setEditPeriod({ ...editPeriod, ...{ to: date } });
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ViewBookingDetails;
