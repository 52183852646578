import React, { useEffect, useState } from 'react';
import { Box, useTheme, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { grey } from '@mui/material/colors';
import { initialize, reduxForm, Field, FormErrors, formValueSelector, getFormSyncErrors } from 'redux-form';
import { connect } from 'react-redux';
import { use100vh } from 'react-div-100vh';

import en from '../../../translations/en';
import { DownArrowIcon, InfoIcon } from '../../../assets/images';
import { UI_STYLES, TRAVEL_DETAIL_PURPOSE, FORM, USER_ROLE } from '../../../constants/constants';
import { BOOKING_PATHS } from '../../../constants/paths';

import { IDtTravelDetails } from '../../../interfaces';

import { isValid, isLevelZEmployee, getAdminBookingEmployeeErn, findDropdownOptionClient } from '../../../helpers';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectBooking } from '../../../slice/bookingSlice';
import { selectAuth } from '../../../slice/authSlice';
import { selectApp } from '../../../slice/appSlice';
import { selectConfiguration } from '../../../slice/configurationSlice';
import { selectUser } from '../../../slice/userSlice';
import {
  getEndorserThunk,
  getEndorserAdminThunk,
  getEndorserDelegationThunk,
  getProfileThunk,
  getProfileAdminThunk,
  getProfileDelegationThunk,
  selectBookingUser,
} from '../../../slice/bookingUserSlice';

import { RootState } from '../../../app/store';

import { validateEndorser } from '../../../services/user';
import { validateEndorser as validateEndorserAdmin } from '../../../services/admin/bookingUser';
import { validateEndorser as validateEndorserDelegation } from '../../../services/delegation/bookingUser';

import { validate } from './validate';

import {
  Header,
  Footer,
  ReduxFormTextInput,
  ReduxFormSelectInput,
  ScrollableView,
  DesktopBreadcrumb,
} from '../../../components';
import { SegmentInfo } from '../..';

const selector = formValueSelector(FORM.travelDetails);

const DtTravelDetailsContainer = (props: any) => {
  const {
    endorser,
    endorserGalaCXyId,
    formErrors,
    routeToStep,
    handleBackClick,
    handleFooterClick,
    BackButtonText,
  }: {
    endorser: string | null;
    endorserGalaCXyId: string;
    formErrors: FormErrors<IDtTravelDetails>;
    routeToStep?: number;
    BackButtonText?: string;
    handleBackClick?: () => void;
    handleFooterClick?: () => void;
  } = props;

  const dispatch = useAppDispatch();
  const theme = useTheme();

  const { outwardFlight, returnFlight, travellerDetails } = useAppSelector(selectBooking) || {};
  const adminBookingEmployeeErn = getAdminBookingEmployeeErn(travellerDetails);

  const { configurations } = useAppSelector(selectConfiguration) || {};
  const { ern } = useAppSelector(selectAuth) || {};
  const { isDesktop } = useAppSelector(selectApp) || {};
  const { role } = useAppSelector(selectUser) || {};
  const { profile } = useAppSelector(selectBookingUser) || {};

  const isLevelZUser = isLevelZEmployee(profile);

  const { type: roleType, id: roleId } = role || {};

  const isAdminDelegation = [USER_ROLE.admin, USER_ROLE.delegation].includes(roleType);

  const [isEndorserGalaCXyIdError, setIsEndorserGalaCXyIdError] = useState<string | null>(null);

  const screenHeight = use100vh();

  useEffect(() => {
    if (!endorser && endorserGalaCXyId === undefined) {
      if (roleType === USER_ROLE.admin) {
        dispatch(getEndorserAdminThunk(adminBookingEmployeeErn));
      } else if (roleType === USER_ROLE.delegation) {
        dispatch(getEndorserDelegationThunk(roleId));
      } else {
        dispatch(getEndorserThunk(ern));
      }
    }

    if (!profile) {
      if (roleType === USER_ROLE.admin) {
        dispatch(getProfileAdminThunk(adminBookingEmployeeErn));
      } else if (roleType === USER_ROLE.delegation) {
        dispatch(getProfileDelegationThunk(roleId));
      } else {
        dispatch(getProfileThunk(ern));
      }
    }
  }, []);

  useEffect(() => {
    dispatch(
      initialize(
        FORM.travelDetails,
        {
          endorserGalaCXyId: endorser,
          costCentre: findDropdownOptionClient(configurations.costCentres, profile?.costCentre || ''),
        },
        true,
      ),
    );
  }, [endorser, profile?.costCentre]);

  const checkStaffApi = async () => {
    setIsEndorserGalaCXyIdError(null);

    let result;
    if (roleType === USER_ROLE.admin) {
      result = await validateEndorserAdmin(endorserGalaCXyId, adminBookingEmployeeErn);
    } else if (roleType === USER_ROLE.delegation) {
      result = await validateEndorserDelegation(endorserGalaCXyId, roleId);
    } else {
      result = await validateEndorser(endorserGalaCXyId, ern);
    }

    if (result?.valid) {
      handleFooterClick && handleFooterClick();
    } else {
      setIsEndorserGalaCXyIdError(en.error.invalidEndorser);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        background: grey[50],
      }}
    >
      <Box sx={{ px: { xs: 2, sm: 0 } }}>
        {!isDesktop && (
          <Box
            sx={{
              mt: { xs: -5.3, sm: 0 },
            }}
          >
            {outwardFlight && <SegmentInfo flightInfo={outwardFlight} isShowSegmentStatus={false} />}
            {returnFlight && <SegmentInfo flightInfo={returnFlight} isShowSegmentStatus={false} />}
          </Box>
        )}

        <Box
          component={ScrollableView}
          sx={
            isDesktop
              ? {
                  height: `calc(${screenHeight}px - ${UI_STYLES.desktopHeaderHeight} - ${
                    isAdminDelegation ? UI_STYLES.indicatorBarHeight : '0px'
                  } - ${isValid(formErrors) ? UI_STYLES.desktopFooterHeight : '0px'})`,
                }
              : {
                  height: `calc(${screenHeight}px - ${UI_STYLES.overlayHeaderHeightBookingFlow} - ${
                    isValid(formErrors) ? UI_STYLES.footerHeight : '0px'
                  } - ${isAdminDelegation ? UI_STYLES.indicatorBarHeight : '0px'} - 40px - 48px)`,
                }
          }
        >
          <DesktopBreadcrumb step={routeToStep} />
          <Box
            sx={
              isDesktop
                ? {
                    width: '854px',
                    mt: 0,
                    px: 1,
                    mx: 'auto',
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    flexDirection: 'row-reverse',
                    alignContent: 'flex-start',
                  }
                : {
                    width: { sm: '570px' },
                    mx: 'auto',
                    flexDirection: 'column',
                  }
            }
          >
            {isDesktop && (
              <>
                <Header
                  leftChild={
                    <Typography
                      color={theme.color.utility.link.option_3}
                      variant="body_1_bold"
                      sx={{
                        pl: 1,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        handleBackClick?.();
                      }}
                    >
                      {BackButtonText ?? en.booking.flightClass.backSeatOptions}
                    </Typography>
                  }
                  handleOnBack={handleBackClick}
                  customStyles={{
                    width: '100%',
                    mb: 3.25,
                  }}
                />
                <Box sx={{ width: '320px' }}>
                  {outwardFlight && <SegmentInfo flightInfo={outwardFlight} isShowSegmentStatus={false} />}
                  {returnFlight && <SegmentInfo flightInfo={returnFlight} isShowSegmentStatus={false} />}
                </Box>
              </>
            )}
            <Box
              sx={{
                ...(isDesktop && {
                  p: 2,
                  mb: 1,
                  bgcolor: theme.palette.bgColor.main,
                  borderRadius: 1,
                  boxShadow: theme.palette.boxShadow.dark,
                  width: '478px',
                }),
              }}
            >
              <Field
                component={ReduxFormSelectInput}
                name="purpose"
                title={en.booking.travelDetail.purpose}
                options={TRAVEL_DETAIL_PURPOSE}
                autocomplete={{
                  customOpen: true,
                }}
              />

              <Field
                component={ReduxFormTextInput}
                name="description"
                title={en.booking.travelDetail.description}
                subTitle={en.booking.travelDetail.descriptionSubTitle}
              />

              {!isLevelZUser && (
                <Field
                  component={ReduxFormTextInput}
                  name="endorserGalaCXyId"
                  title={en.booking.travelDetail.endorserGalaCXyId}
                  isReadOnly={endorser !== '' || endorser === null}
                  isError={isEndorserGalaCXyIdError}
                />
              )}

              <Field component={ReduxFormTextInput} name="projectCode" title={en.booking.travelDetail.projectCode} />

              <Field
                component={ReduxFormSelectInput}
                name="costCentre"
                title={en.booking.travelDetail.costCentre}
                options={configurations?.costCentres}
                autocomplete={{
                  showTypedNoOfChar: 6,
                  matchFrom: 'start',
                }}
              />

              <Field
                component={ReduxFormSelectInput}
                name="accountCode"
                title={en.booking.travelDetail.accountCode}
                options={configurations?.generalLedgerAccounts}
                autocomplete={{
                  matchFrom: 'start',
                }}
              />
              <Accordion
                disableGutters
                elevation={0}
                sx={{
                  borderRadius: '4px !important',
                  minHeight: '36px',
                  backgroundColor: theme.color.secondary.light_slate.option_6,
                  '&:before': {
                    display: 'none',
                  },
                  '.MuiAccordionDetails-root': {
                    pt: 0,
                    pb: 1,
                    px: 4.125,
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={<DownArrowIcon />}
                  aria-controls="panel1a-content"
                  sx={{
                    minHeight: '36px',
                    height: '36px',
                    lineHeight: '36px',
                    color: theme.color.utility.link.option_3,
                    pl: 1.3125,
                    pr: 2.5,
                    '.MuiAccordionSummary-content': {
                      display: 'flex',
                      alignItems: 'center',
                    },
                  }}
                >
                  <InfoIcon width="16" height="16" />
                  <Typography variant="navigation_medium" sx={{ ml: 0.8125 }}>
                    {en.exampleOfAccountCode.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {en.exampleOfAccountCode.list.map((item, key) => (
                    <Typography
                      key={key}
                      variant="navigation_regular"
                      sx={{
                        lineHeight: '20px',
                        color: '#595959',
                      }}
                    >
                      {item}
                    </Typography>
                  ))}
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        </Box>
      </Box>

      {isValid(formErrors) && (
        <Footer
          primaryBtn={{
            isFullWidth: !isDesktop,
            text: en.common.continue,
            route: BOOKING_PATHS.confirmation,
            customOnClick: checkStaffApi,
          }}
          customStyles={
            isDesktop
              ? {
                  px: 22,
                  borderRadius: '24px 24px 0px 0px',
                  boxShadow: theme.boxShadow.important,
                }
              : {
                  px: 2,
                }
          }
        />
      )}
    </Box>
  );
};

const form = reduxForm({
  form: FORM.travelDetails,
  validate,
  initialValues: {},
  touchOnBlur: true,
  touchOnChange: true,
  destroyOnUnmount: false,
})(DtTravelDetailsContainer);

// DT travel detail page
export default connect((state: RootState) => ({
  endorser: state.bookingUser.endorser,
  activeBenefitDTJobLevel: state.bookingUser.profile?.activeBenefitDTJobLevel,
  endorserGalaCXyId: selector(state, 'endorserGalaCXyId'),
  formErrors: getFormSyncErrors(FORM.travelDetails)(state),
}))(form);
