import React, { useCallback } from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material';

import en from '../../../translations/en';

import { useAppSelector } from '../../../app/hooks';

import { IPaymentHistoryList } from '../../../interfaces';

import { selectApp } from '../../../slice/appSlice';

import { NoSearchResult } from '../..';

type Props = {
  paymentHistoryData?: IPaymentHistoryList[] | any;
  isNoResult?: boolean;
  handlePaymentHistoryRecordOnClick?: (data: any) => void;
};

const PaymentHistoryCardList = ({
  paymentHistoryData,
  isNoResult = false,
  handlePaymentHistoryRecordOnClick,
}: Props) => {
  const theme = useTheme();

  const { isLoading } = useAppSelector(selectApp);

  const renderHistoryCard = useCallback(
    (data: IPaymentHistoryList[]) => {
      return data?.map((item: IPaymentHistoryList, index: number) => {
        return (
          <Box
            component={Button}
            key={item?.id || index}
            sx={{
              minHeight: '168px',
              flex: 1,
              justifyContent: 'flex-start',
              textAlign: 'left',
              boxShadow: theme.boxShadow.important,
              mx: 2,
              borderRadius: 1,
              mb: 2,
            }}
            onClick={() => {
              handlePaymentHistoryRecordOnClick?.({
                applicationId: item?.applicationId,
                type: item?.type,
              });
            }}
          >
            <Box sx={{ mx: 2, my: 2 }}>
              <Box sx={{ mb: 1 }}>
                <Typography variant="navigation_regular" sx={{ color: theme.palette.primary.contrastText }}>
                  {en.booking.flightConfirmation.travelDetails.travellerDetails.travellerName}
                </Typography>
                <Typography variant="body_1_medium" sx={{ color: theme.color.secondary.dark_grey.option_3 }}>
                  {item.travellerName}
                </Typography>
              </Box>

              <Box sx={{ mb: 1 }}>
                <Typography variant="navigation_regular" sx={{ color: theme.palette.primary.contrastText }}>
                  {en.userProfile.employmentDetail.paymentHistory.itinerary}
                </Typography>
                <Typography variant="body_1_medium" sx={{ color: theme.color.secondary.dark_grey.option_3 }}>
                  {item.itinerary}
                </Typography>
              </Box>

              <Box>
                <Typography variant="navigation_regular" sx={{ color: theme.palette.primary.contrastText }}>
                  {en.booking.flightConfirmation.fareDetails.total}
                </Typography>
                <Box sx={{ display: 'flex' }}>
                  <Typography
                    variant="body_1_medium"
                    sx={{
                      color: theme.color.secondary.dark_grey.option_3,
                    }}
                  >
                    {item.totalAmount}
                  </Typography>
                  <Typography variant="navigation_medium" sx={{ pl: 0.5, pt: 0.8 }}>
                    {item.currency}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        );
      });
    },
    [paymentHistoryData],
  );

  return (
    <>
      {!isLoading && isNoResult && (
        <Box>
          <NoSearchResult isShowIcon={true} desc={en.userProfile.employmentDetail.paymentHistory.noSearchResult} />
        </Box>
      )}

      {!isNoResult && (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>{renderHistoryCard(paymentHistoryData)}</Box>
      )}
    </>
  );
};

export default PaymentHistoryCardList;
