import React, { useEffect } from 'react';
import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { CARRIER_OPTION, NOMINATED_PASSENGER_AGE_TYPE } from '../../../constants/constants';
import { ExclamationIcon } from '../../../assets/images';
import en from '../../../translations/en';

import { INominatedPassenger } from '../../../interfaces';

import {
  getDisplayName,
  isSomeTravellerAvailable,
  checkNominatedPassengers,
  isPaxAgeUnderSixteen,
  toCapitalize,
  isSelectedAdult,
  isSelectedEMP,
} from '../../../helpers';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  selectBooking,
  setInfantAdultMapFromRedux,
  setLtSeatConcessionTravellerList,
} from '../../../slice/bookingSlice';

import { LtAccompanyForm, LtOnlyChildTravellerForm, LtTravellerQuotaElements, LtTravellerWarning } from '../..';

const LtTravellers = ({
  ltTravellers,
  unSelectTravellers,
  setIsAllAccompanySelected,
  isShowForm,
  isShowAccompanyButton,
  isInfantOrChildTraveller,
  isRequireAccompanyForm,
  adultTravellers,
  infantWithoutSeatTravellers,
  isKeepData,
  isSelectConcessionPage = false,
}: {
  ltTravellers: INominatedPassenger[];
  unSelectTravellers: INominatedPassenger[];
  setIsAllAccompanySelected: any;
  isShowForm: boolean;
  isShowAccompanyButton: boolean;
  isInfantOrChildTraveller: boolean;
  adultTravellers: INominatedPassenger[];
  infantWithoutSeatTravellers: INominatedPassenger[];
  isRequireAccompanyForm?: boolean;
  isKeepData: boolean;
  isSelectConcessionPage?: boolean;
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { leisureTravelDetails, outwardFlight } = useAppSelector(selectBooking) || {};
  const { ltSeatConcessionTravellerList: nominatedTravellerList, infantAdultMapFromRedux } = leisureTravelDetails || {};

  const enLt = en.booking.travelType.leisureTravel;

  const { eligibleTravellers, nonEligibleTravellers } = checkNominatedPassengers(ltTravellers) || {};

  const isInfantWithAdultTraveller =
    ltTravellers &&
    ltTravellers.some(
      (traveller: INominatedPassenger) => traveller?.validationInfo?.ageType === NOMINATED_PASSENGER_AGE_TYPE.infant,
    ) &&
    ltTravellers.some(
      (traveller: INominatedPassenger) => traveller?.validationInfo?.ageType === NOMINATED_PASSENGER_AGE_TYPE.adult,
    );

  useEffect(() => {
    if (
      infantAdultMapFromRedux &&
      infantWithoutSeatTravellers &&
      Object.keys(infantAdultMapFromRedux).length !== 0 &&
      Object.keys(infantAdultMapFromRedux).length === infantWithoutSeatTravellers.length
    ) {
      setIsAllAccompanySelected(true);
    } else {
      setIsAllAccompanySelected(false);
    }
  }, [infantAdultMapFromRedux]);

  const leisureTravellers = isRequireAccompanyForm ? nonEligibleTravellers : ltTravellers;

  return (
    <>
      {isRequireAccompanyForm && (
        <LtOnlyChildTravellerForm
          ltTravellers={eligibleTravellers}
          isShowForm={isShowForm}
          isRequireAccompanyForm={isRequireAccompanyForm}
        />
      )}

      {leisureTravellers &&
        leisureTravellers.map((ltTraveller: INominatedPassenger, travellerIndex: number) => {
          const { age, title, firstName, lastName, relationship, validationInfo, quotaInfo } = ltTraveller || {};

          const isDisabled = !isSomeTravellerAvailable(ltTraveller);
          const {
            ageType,
            restriction: travellersWithRestriction,
            mustTravelWithBeneficiaryTypeCode,
          } = validationInfo || {};

          const isInfant = ageType === NOMINATED_PASSENGER_AGE_TYPE.infant;

          const isInfantWithoutSeat = ltTraveller.infantWithSeat === false;

          const isShowMHCompanionMsg =
            !!mustTravelWithBeneficiaryTypeCode && !isDisabled && !isSelectedEMP(leisureTravellers);

          const isShowUnder16WarningMsg =
            isPaxAgeUnderSixteen(age) &&
            !isDisabled &&
            outwardFlight?.marketingCompany !== CARRIER_OPTION.CX &&
            !isSelectedAdult(leisureTravellers);

          const handleSelectAccompany = (
            event: React.MouseEvent<HTMLElement>,
            selectedAccompanyAdultIndex: number | null,
          ) => {
            /**
             * attach the selected adult travellers object to child/infant
             */
            const tempTravellersArray = [...ltTravellers];

            /**
             * First time select accompany adult - !(selectedAdultTravellers?.[travellerIndex] >= 0)
             * Same Infant but different accompany adult - selectedAdultTravellers?.[travellerIndex] !== selectedAccompanyAdultIndex
             */
            if (
              selectedAccompanyAdultIndex !== null &&
              (!(infantAdultMapFromRedux?.[travellerIndex] >= 0) ||
                infantAdultMapFromRedux?.[travellerIndex] !== selectedAccompanyAdultIndex)
            ) {
              // Action: select
              // selectedAccompanyAdultIndex is not null if any option is selected
              dispatch(
                setInfantAdultMapFromRedux({
                  ...infantAdultMapFromRedux,
                  [travellerIndex]: selectedAccompanyAdultIndex,
                }),
              );

              //Override and map the child/infant index to the adult index
              const updatedTraveller = {
                ...ltTraveller,
                accompanyAdult: nominatedTravellerList.find(
                  (traveller: INominatedPassenger) => traveller.keyIndex === selectedAccompanyAdultIndex,
                ),
              };
              tempTravellersArray[travellerIndex] = updatedTraveller;
            } else {
              // Action: unselect
              // clear removedSelectedAdultTravellers saved index
              const removedSelectedAdultTravellers = {
                ...infantAdultMapFromRedux,
              };

              delete removedSelectedAdultTravellers[travellerIndex];
              dispatch(setInfantAdultMapFromRedux(removedSelectedAdultTravellers));

              const clearedTraveller = {
                ...ltTraveller,
                accompanyAdult: undefined,
              };
              tempTravellersArray[travellerIndex] = clearedTraveller;
            }
            const newLtTraverllers = [...tempTravellersArray, ...unSelectTravellers];

            dispatch(
              setLtSeatConcessionTravellerList(
                newLtTraverllers.sort((a: INominatedPassenger, b: INominatedPassenger) => {
                  return a.keyIndex - b.keyIndex;
                }),
              ),
            );
          };

          return (
            <Box
              key={travellerIndex}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '76px',
                mt: 1,
                p: 2,
                borderRadius: 1,
                background: isDisabled ? theme.color.secondary.light_slate.option_6 : theme.palette.bgColor.main,
                border: isDisabled ? 'none' : '1px solid #BCBEC0',
                cursor: isDisabled ? 'pointer' : 'default',
              }}
            >
              <Accordion
                disableGutters={true}
                sx={{
                  width: '100%',
                  boxShadow: 'none',
                  background: 'none',
                  p: 0,
                }}
              >
                <AccordionSummary
                  expandIcon={
                    travellersWithRestriction?.length > 0 ? (
                      <ExpandMoreIcon
                        sx={{
                          color: theme.color.secondary.dark_grey.option_3,
                        }}
                      />
                    ) : null
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{
                    cursor: isDisabled ? 'pointer' : 'default',
                  }}
                  sx={{
                    p: 0,
                    m: 0,
                    cursor: isDisabled ? 'pointer' : 'default',
                    '.MuiAccordionSummary-content': {
                      my: 0,
                      mr: 0.5,
                      display: 'flex',
                      alignItems: 'center',
                      cursor: isDisabled ? 'pointer' : 'default',
                    },
                    '.Mui-expanded': {
                      my: 0,
                      minHeight: 0,
                    },
                    '&.Mui-focusVisible': {
                      background: 'none',
                    },
                  }}
                >
                  <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                    <Typography
                      variant="body_1_regular"
                      color={
                        (theme.color.secondary.dark_grey.option_1,
                        isDisabled ? theme.color.secondary.grey.option_3 : theme.color.secondary.dark_grey.option_1)
                      }
                    >
                      {getDisplayName({
                        title,
                        firstName,
                        lastName,
                      })}
                    </Typography>

                    <Typography
                      variant="navigation_regular"
                      color={
                        (theme.color.secondary.grey.option_2,
                        isDisabled ? theme.color.secondary.grey.option_3 : theme.color.secondary.grey.option_2)
                      }
                      sx={{ mt: 0.5 }}
                    >
                      {isInfant &&
                        relationship + ' / ' + (isInfantWithoutSeat ? enLt.infantWithoutSeat : enLt.infantWithSeat)}

                      {!isInfant && relationship}
                    </Typography>

                    {isShowMHCompanionMsg && (
                      <LtTravellerWarning
                        overrideStyle={{
                          marginTop: 1,
                        }}
                        warningMessage={toCapitalize(
                          enLt.dependentTypeMustTravelWithEmpWarning
                            .replace(RegExp(`{beneficiaryType}`, 'g'), relationship.toLocaleLowerCase())
                            .replace(RegExp(`{marketingCompany}`, 'g'), outwardFlight?.marketingCompany || ''),
                        )}
                      />
                    )}

                    {isShowUnder16WarningMsg && (
                      <LtTravellerWarning
                        overrideStyle={{
                          marginTop: 1,
                        }}
                        warningMessage={enLt.childrenUnder16}
                      />
                    )}

                    {travellersWithRestriction?.length < 1 &&
                      isSelectConcessionPage &&
                      quotaInfo &&
                      quotaInfo?.unused !== 0 && (
                        <Box sx={{ mt: 0.5 }}>
                          <LtTravellerQuotaElements
                            {...{
                              ...quotaInfo,
                              label: enLt.quotaElement.label,
                            }}
                          />

                          {quotaInfo?.booked >= quotaInfo?.unused && (
                            <LtTravellerWarning
                              overrideStyle={{
                                marginTop: 1,
                              }}
                              warningMessage={enLt.quotaElement.limitQuotaRestriction}
                            />
                          )}
                        </Box>
                      )}

                    {isShowForm && !isDisabled && (
                      <>
                        {isInfantOrChildTraveller && (
                          <LtAccompanyForm
                            index={travellerIndex}
                            keyIndex={ltTraveller.keyIndex}
                            isKeepData={isKeepData}
                          />
                        )}

                        {/* Accompany Adult Component */}
                        {isInfantWithAdultTraveller && isInfant && isInfantWithoutSeat && isShowAccompanyButton && (
                          <>
                            <Box
                              sx={{
                                mt: 2,
                                height: '1px',
                                bgcolor: theme.color.etp.grey,
                              }}
                            />
                            <Typography
                              variant="body_2_regular"
                              sx={{
                                mt: 1,
                                color: theme.color.secondary.dark_grey.option_3,
                              }}
                            >
                              {en.booking.travelType.leisureTravel.accompanyPassenger}
                            </Typography>

                            <ToggleButtonGroup
                              orientation="vertical"
                              value={infantAdultMapFromRedux?.[travellerIndex] || null}
                              exclusive
                              onChange={handleSelectAccompany}
                            >
                              {adultTravellers.map((accompanyAdult: any, adultOnlyIndex: number) => {
                                const { title, firstName, lastName, keyIndex } = accompanyAdult || {};

                                const isSelected =
                                  infantAdultMapFromRedux && infantAdultMapFromRedux[travellerIndex] === keyIndex;

                                const isDisabled =
                                  Object.values(infantAdultMapFromRedux).includes(keyIndex) && !isSelected;

                                return (
                                  <ToggleButton
                                    key={adultOnlyIndex}
                                    value={keyIndex}
                                    selected={isSelected}
                                    disabled={isDisabled}
                                    sx={{
                                      justifyContent: 'start',
                                      px: 2,
                                      my: 2,
                                      height: '56px',
                                      borderRadius: 1,
                                      background: theme.palette.bgColor.main,
                                      border: 'none',
                                      boxShadow: theme.boxShadow.important,
                                      '&.MuiToggleButtonGroup-grouped:not(:last-of-type), &.MuiToggleButtonGroup-grouped:not(:first-of-type)':
                                        {
                                          borderRadius: 1,
                                        },
                                      '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected.MuiToggleButtonGroup-grouped:not(:first-of-type), &.Mui-selected:hover.MuiToggleButtonGroup-grouped:not(:first-of-type)':
                                        {
                                          background: '#F4FCFF',
                                          border: '1px solid #116F9A',
                                          boxShadow: 'none',
                                        },
                                      '&.Mui-disabled, &.Mui-disabled:hover': {
                                        boxShadow: 'none',
                                        border: 'none',
                                        backgroundColor: theme.color.secondary.light_slate.option_6,
                                      },
                                    }}
                                  >
                                    <Typography
                                      variant="body_1_regular"
                                      sx={{
                                        color: isDisabled
                                          ? theme.color.secondary.grey.option_3
                                          : theme.color.secondary.dark_grey.option_1,
                                      }}
                                    >
                                      {getDisplayName({
                                        title,
                                        firstName,
                                        lastName,
                                      })}
                                    </Typography>
                                  </ToggleButton>
                                );
                              })}
                            </ToggleButtonGroup>
                          </>
                        )}
                      </>
                    )}
                  </Box>

                  {travellersWithRestriction?.length > 0 && (
                    <ExclamationIcon
                      width="16"
                      height="16"
                      fill={theme.color.secondary.dark_grey.option_3}
                      style={{ flex: 'none' }}
                    />
                  )}
                </AccordionSummary>

                <AccordionDetails sx={{ my: 0, p: 0 }}>
                  <>
                    {travellersWithRestriction &&
                      travellersWithRestriction.map((item: string, index: number) => (
                        <LtTravellerWarning
                          key={index}
                          overrideStyle={{
                            marginTop: 1,
                          }}
                          warningMessage={item}
                        />
                      ))}
                  </>
                </AccordionDetails>
              </Accordion>
            </Box>
          );
        })}
    </>
  );
};

export default LtTravellers;
