import React from 'react';
import { Box, Typography } from '@mui/material';
import { reduxForm, Field, FormErrors, getFormSyncErrors, getFormValues } from 'redux-form';
import { connect } from 'react-redux';

import theme from '../../../../../style/theme';
import { FORM } from '../../../../../constants/constants';
import en from '../../../../../translations/en';

import { IContactDetails } from '../../../../../interfaces';

import { isValid } from '../../../../../helpers/validation';
import { validate } from './validate';

import { RootState } from '../../../../../app/store';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { selectApp } from '../../../../../slice/appSlice';
import { setContactDetails } from '../../../../../slice/bookingSlice';

import { Header, Footer, FormAlert, ReduxFormTextInput, ScrollableView } from '../../../../../components';

const { travelDetails } = en.booking.flightConfirmation;
const { contactDetails: contactDetailsLbl } = travelDetails || {};

const EditContactDetails = (props: any) => {
  const {
    handleClose,
    formValues,
    formErrors,
  }: {
    handleClose?: () => void;
    formValues: IContactDetails | null;
    formErrors: FormErrors<IContactDetails | null>;
  } = props;

  const dispatch = useAppDispatch();
  const { isDesktop } = useAppSelector(selectApp) || {};

  return (
    <>
      <Box sx={{ px: 2, flex: 1 }}>
        <Header
          isHideBackBtn={true}
          leftChild={
            <Typography variant={isDesktop ? 'headline_bold' : 'headline_medium'} color="gray.dark">
              {contactDetailsLbl.title}
            </Typography>
          }
          handleOnClose={handleClose}
          customStyles={{ mt: 3 }}
        />

        <Box
          component={ScrollableView}
          sx={{
            height: `calc(100% - 64px)`,
            py: 2,
          }}
        >
          <FormAlert
            severity="info"
            component={
              <Typography variant="body_2_regular" sx={{ ml: 1, color: theme.color.secondary.dark_grey.option_3 }}>
                {en.booking.confirmation.mandatory}
              </Typography>
            }
          />

          <Box sx={{ mt: -1 }}>
            <Field component={ReduxFormTextInput} name="phoneNumber" title={contactDetailsLbl.phoneNumber} isPhoneNum />

            <Field component={ReduxFormTextInput} name="email" title={contactDetailsLbl.email} />
          </Box>
        </Box>
      </Box>

      {isValid(formErrors) && (
        <Footer
          customStyles={{
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
          }}
          primaryBtn={{
            isFullWidth: true,
            text: en.common.update,
            customOnClick: () => {
              dispatch(setContactDetails(formValues));
              handleClose && handleClose();
            },
          }}
        />
      )}
    </>
  );
};

const form = reduxForm({
  form: FORM.contactDetails,
  validate,
  enableReinitialize: true,
  touchOnBlur: true,
  touchOnChange: true,
  destroyOnUnmount: true,
})(EditContactDetails);

const connectForm = connect((state: RootState) => ({
  initialValues: state.bookingSearch.contactDetails,
  formValues: getFormValues(FORM.contactDetails)(state),
  formErrors: getFormSyncErrors(FORM.contactDetails)(state),
}))(form);

connectForm.displayName = FORM.contactDetails;

export default connectForm;
