import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

import { FARE_TYPES, SEAT_CLASS, UI_STYLES } from '../../../../constants/constants';
import en from '../../../../translations/en';

import { useAppSelector } from '../../../../app/hooks';
import { selectApp } from '../../../../slice/appSlice';
import { selectBooking } from '../../../../slice/bookingSlice';

const LtCXHeader = () => {
  const theme = useTheme();
  const { isDesktop } = useAppSelector(selectApp) || {};
  const { leisureTravelDetails } = useAppSelector(selectBooking) || {};

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        mx: 1,
        mt: 2,
        minWidth: '343px',
      }}
    >
      <Typography
        sx={{
          color: theme.color.secondary.grey.option_1,
          ml: 1,
        }}
        variant="body_1_medium"
      >
        {en.booking.flightSearchResultCarrierOptions.cx}
      </Typography>

      {leisureTravelDetails.fareType !== FARE_TYPES.ID && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
            mr: isDesktop ? 3 : 5,
            width: '126px',
          }}
        >
          {Object.values(SEAT_CLASS).map((val, idx) => {
            return (
              <Typography
                key={idx}
                sx={{
                  color: theme.color.secondary.grey.option_1,
                  width: UI_STYLES.flightLoadBarWidth,
                  textAlign: 'center',
                  mt: 0.5,
                }}
                variant="body_1_medium"
              >
                {val}
              </Typography>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default LtCXHeader;
