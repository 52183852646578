import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import { UI_STYLES } from '../../constants/constants';

const ContentView = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: `calc(100vh - ${UI_STYLES.navBarHeight})`,
}));

export default ContentView;
