import React from 'react';
import { Box, Typography } from '@mui/material';

import { LABEL_CATEGORY } from '../../../constants/constants';
import { LoungeIcon } from '../../../assets/images';

import { Configuration } from 'configuration';

import { getDisplayLabel } from '../../../helpers';

const Lounge = ({ lounge, labels }: { lounge: string; labels: Configuration.CodeLabel[] }) => {
  if (!lounge) {
    return <></>;
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <LoungeIcon style={{ marginRight: '8px' }} />

      <Typography variant="body_2_regular" color="cathayJadeLight.contrastText">
        {getDisplayLabel(labels, LABEL_CATEGORY.lounge, lounge)}
      </Typography>
    </Box>
  );
};

Lounge.defaultProps = {
  lounge: '',
  labels: [],
};

export default Lounge;
