import React, { useMemo } from 'react';
import { Box, useTheme } from '@mui/material';
import { use100vh } from 'react-div-100vh';
import _ from 'lodash';

import { UI_STYLES } from '../../../constants/constants';
import { FONT_WEIGHT } from '../../../constants/font';
import en from '../../../translations/en';

import { IPaymentHistoryList } from '../../../interfaces';

import { useAppSelector } from '../../../app/hooks';

import { selectApp } from '../../../slice/appSlice';

import { BaseTable } from '../../../components';
import { NoSearchResult } from '../..';

interface IProps {
  data: IPaymentHistoryList[];
  isNoResult?: boolean;
  handlePaymentHistoryRecordOnClick?: (data: any) => void;
}

const tableSchema = [
  {
    key: 'type',
    isHiddenField: true,
  },
  {
    key: 'applicationId',
    isHiddenField: true,
  },
  {
    key: 'concession',
    isHiddenField: false,
  },
  {
    key: 'travellerName',
    isHiddenField: false,
  },
  {
    key: 'ticketNumber',
    isHiddenField: false,
  },
  {
    key: 'itinerary',
    isHiddenField: false,
  },
  {
    key: 'processedDate',
    isHiddenField: false,
  },
  {
    key: 'currency',
    isHiddenField: false,
  },
  {
    key: 'totalAmount',
    isHiddenField: false,
  },
  {
    key: 'paymentMedium',
    isHiddenField: false,
  },
  {
    key: 'status',
    isHiddenField: false,
  },
];

const PaymentHistoryTable = ({ data, isNoResult = false, handlePaymentHistoryRecordOnClick }: IProps) => {
  const theme = useTheme();
  const screenHeight = use100vh();

  const { isLoading } = useAppSelector(selectApp);

  const fieldLabel = en.userProfile.employmentDetail.paymentHistory.fields;
  const tableMaxHeight = `calc(${screenHeight}px - 96px - ${UI_STYLES.desktopReferenceHeaderHeight} - ${UI_STYLES.navBarHeight})`;
  const isFixedTableWidth = true;
  const isStickyHeader = false;

  const handleRowDataOnClick = (rowData: any) => {
    const matchedApplicationColumn = rowData?.columns?.find((item: any) => item.id === 'applicationId');
    const matchedTypeColumn = rowData?.columns?.find((item: any) => item.id === 'type');
    handlePaymentHistoryRecordOnClick?.({
      applicationId: matchedApplicationColumn?.value,
      type: matchedTypeColumn?.value,
    });
  };

  const transformedTableHeaders = useMemo(() => {
    return tableSchema.map((item) => {
      return {
        label: fieldLabel?.[item.key as keyof typeof fieldLabel],
        sxProps: {
          '&.MuiTableCell-stickyHeader': {
            backgroundColor: theme.color.secondary.light_slate.option_7,
          },
          pb: 0.25,
        },
        isHiddenField: item.isHiddenField,
      };
    });
  }, [data]);

  const transformedTableContents = useMemo(() => {
    const tmpData = _.cloneDeep(data);
    return tmpData.map((dataItem) => {
      return {
        sxProps: {},
        columns: tableSchema.map(({ key, isHiddenField }) => {
          let columnSxProps = {};
          let newValue = '';
          const currentValue = dataItem?.[key as keyof IPaymentHistoryList];
          newValue = `${currentValue}`;

          // styling for Prepaid request refund status
          if (currentValue === en.userProfile.employmentDetail.paymentHistory.status.request) {
            columnSxProps = {
              fontWeight: FONT_WEIGHT.bold,
              color: theme.color.utility.link.option_3,
            };
          }

          return {
            id: key,
            value: newValue,
            sxProps: {
              pt: '21px',
              pb: '45px',
              cursor: 'pointer',
              ...columnSxProps,
            },
            isHiddenField,
            columnOnClick: dataItem?.handleColumnOnClick,
          };
        }),
        rowOnClick: handleRowDataOnClick,
      };
    });
  }, [data]);

  return (
    <Box
      sx={{
        width: '100vw',
        px: 10.5,
        overflow: 'hidden',
      }}
    >
      {/* TODO will integrate */}
      {!isLoading && isNoResult && (
        <Box>
          <NoSearchResult isShowIcon={true} desc={en.userProfile.employmentDetail.paymentHistory.noSearchResult} />
        </Box>
      )}

      {!isNoResult && data?.length > 0 && (
        <BaseTable
          tableHeaders={transformedTableHeaders}
          tableContents={transformedTableContents}
          tableContainerSXProps={{
            ...((isStickyHeader && {
              maxHeight: tableMaxHeight,
            }) ||
              {}),
          }}
          isFixedTableWidth={isFixedTableWidth}
          isStickyHeader={isStickyHeader}
        />
      )}
    </Box>
  );
};

export default PaymentHistoryTable;
