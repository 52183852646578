import React, { useContext, useEffect, useState } from 'react';

import { IDependentForAdmin } from '../../../interfaces/admin';

import { DependentsDetail, DependentsList } from './';
import { AdminAccessEmployeeState } from '../../../context';

function AdminDependents() {
  const [selectDependent, setSelectDependent] = useState<null | IDependentForAdmin>(null);
  const [validDependList, setValidDependList] = useState<null | IDependentForAdmin[]>([]);
  const { dependents } = useContext(AdminAccessEmployeeState) || {};
  const handleRowDataOnClick = (rowData: any) => {
    setSelectDependent(
      dependents?.find((item: IDependentForAdmin) => item.dependentId === rowData.dependentId) || null,
    );
  };

  useEffect(() => {
    const activeDependList = dependents.filter(
      (item: IDependentForAdmin) => item.profileDetails.dependentStatus === true,
    );
    const inactiveDependList = dependents.filter(
      (item: IDependentForAdmin) => item.profileDetails.dependentStatus === false,
    );
    setValidDependList([...activeDependList, ...inactiveDependList]);
  }, [dependents]);

  return (
    <>
      {selectDependent ? (
        <DependentsDetail {...selectDependent} setSelectDependent={setSelectDependent} />
      ) : (
        <DependentsList
          dataList={validDependList as IDependentForAdmin[]}
          handleRowDataOnClick={handleRowDataOnClick}
        />
      )}
    </>
  );
}

export default AdminDependents;
