import {
  Typography,
  useTheme,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";

import { DownArrowIcon, InfoIcon } from "../../../assets/images";

import { isShowReminderMessage } from "../../../helpers";

interface ILtBookingReminder {
  title: string;
  content: string;
}

const LtBookingReminder = ({
  title = "",
  content = "",
}: ILtBookingReminder) => {
  const theme = useTheme();

  return (
    <>
      {isShowReminderMessage && (
        <Accordion
          disableGutters
          elevation={0}
          sx={{
            borderRadius: "4px !important",
            mb: 2,
            background: theme.color.secondary.light_slate.option_6,
            "&:before": {
              display: "none",
            },
            ".MuiAccordionDetails-root": {
              pt: 0,
              pb: 1,
              px: 4.125,
            },
            ".MuiAccordionSummary-root": {
              minHeight: "36px",
            },
          }}
        >
          <AccordionSummary
            expandIcon={<DownArrowIcon />}
            aria-controls="panel1a-content"
            sx={{
              color: theme.color.utility.link.option_3,
              pl: 1.3125,
              pr: 2.5,
              ".MuiAccordionSummary-content": {
                display: "flex",
                alignItems: "flex-start",
              },
            }}
          >
            <InfoIcon width="16" height="16" />
            <Typography variant="navigation_medium" sx={{ ml: 0.8125 }}>
              {title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="navigation_regular"
              sx={{
                lineHeight: "16px",
                color: "#595959",
                mt: "-2px",
              }}
            >
              {content}
            </Typography>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

export default LtBookingReminder;
