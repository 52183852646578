import { HTTP_REQUEST_TYPE, CONFIGURATION_API } from '../constants/api';
import en from '../translations/en';

import { sendHttpRequest } from './api';

const getAirports = async () => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: CONFIGURATION_API.airports,
    apiTitle: en.errorAlert.getAirports,
  });
  return resp;
};

const getConfigurations = async () => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: CONFIGURATION_API.configuration,
    apiTitle: en.errorAlert.getConfigurations,
  });
  return resp;
};

export { getAirports, getConfigurations };
