import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Box,
  Drawer,
  IconButton,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import { shallowEqual } from "react-redux";
import { use100vh } from "react-div-100vh";

import { ROOT_PATHS } from "../../../constants/paths";
import { OverlayBackground, BackIcon, CloseIcon } from "../../../assets/images";
import {
  UI_STYLES,
  FORM,
  USER_ROLE,
  SAVE_DATA_TO,
} from "../../../constants/constants";
import en from "../../../translations/en";
import "../../../assets/css/transition.css";

import { IBookingFlowOverlayData } from "../../../interfaces";

import { useAppSelector } from "../../../app/hooks";
import { selectApp } from "../../../slice/appSlice";
import { selectBooking } from "../../../slice/bookingSlice";
import { selectUser } from "../../../slice/userSlice";

import { EditSearchBarState, EditSearchBarAction } from "../../../context";

import {
  MobileView,
  DesktopView,
  Div100vhView,
  NavBar,
  DrawerOverlay,
} from "../../../components";
import { RoleIndicatorBar, MobileSearchCriteriaBox } from "../..";

enum OVERLAY_VARIANT {
  SEARCH_CRITERIA = "searchCriteria",
  BOOKING_FLOW = " bookingFlow",
  BOOKING_COMPLETED = "bookingCompleted",
}

export interface IBookingFlowOverlayProps {
  component: React.ComponentType<any>;
  data?: IBookingFlowOverlayData;
  title?: string;
  titleComponent?: React.ComponentType<any>;
  open: boolean;
  customStyles?: SxProps<Theme>;
  variant?: OVERLAY_VARIANT;
  isDrawer?: boolean;
  backgroundImgUrl?: string;
  handleBackClick?: () => void;
  handleFooterClick?: () => void;
  handleEditClick?: () => void;
  handleCloseClick?: () => void;
  isOverlayHeaderHidden?: boolean;
  handleOpenRebookFlow?: (isOpen: boolean) => void;
  handleOpenRebookCompleted?: (isOpen: boolean) => void;
}

const BookingFlowOverlay = ({
  component: Component,
  data,
  title,
  titleComponent: TitleComponent,
  open,
  backgroundImgUrl,
  customStyles,
  variant = OVERLAY_VARIANT.BOOKING_FLOW,
  isDrawer = false,
  handleBackClick,
  handleFooterClick,
  handleEditClick,
  handleCloseClick,
  isOverlayHeaderHidden,
}: IBookingFlowOverlayProps) => {
  const screenHeight = use100vh();
  const theme = useTheme();
  const { isDesktop } = useAppSelector(selectApp) || {};
  const { role } = useAppSelector(selectUser) || {};
  const {
    origin,
    destination,
    startDate,
    travelDetails,
    travelType,
    travellerDetails,
    leisureTravelDetails,
  } = useAppSelector(selectBooking, shallowEqual) || {};
  const { isShowEditSearchOverlay } = useContext(EditSearchBarState) || {};
  const {
    setEditOrigin,
    setEditDestination,
    setEditStartDate,
    setEditTravelDetails,
    setEditTravelType,
    setEditTravellerDetails,
    setEditLeisureTravelDetails,
  } = useContext(EditSearchBarAction) || {};

  const isSearchCriteria = variant === OVERLAY_VARIANT.SEARCH_CRITERIA;
  const isBookingCompleted = variant === OVERLAY_VARIANT.BOOKING_COMPLETED;

  const customHandleBackClick = data?.handleBackClick || handleBackClick;

  const isHomePage = useLocation().pathname === ROOT_PATHS.landing;

  // trigger cssTransition in style
  const [showTransitionIn, setShowTransitionIn] = useState(false);
  useEffect(() => {
    setShowTransitionIn(!showTransitionIn);
  }, [Component]);

  const displayContent = useMemo(() => {
    if (!Component) {
      return <></>;
    }

    return (
      <Component
        handleBackClick={() => {
          customHandleBackClick && customHandleBackClick();
          Component.name !== "LocationSelection" && setShowTransitionIn(true);
        }}
        handleFooterClick={() => {
          handleFooterClick && handleFooterClick();
          if (Component.displayName === "Connect(ReduxForm)") {
            setShowTransitionIn(true);
          } else {
            setShowTransitionIn(false);
          }
        }}
        {...(handleCloseClick && { handleCloseClick })}
        {...data}
      />
    );
  }, [
    Component,
    data,
    customHandleBackClick,
    handleFooterClick,
    handleCloseClick,
  ]);

  const SwitchComponent = () => {
    const backgroundHeight = isSearchCriteria
      ? `calc(${UI_STYLES.overlayHeaderHeightSearchCriteria} + 24px)` // 24px -> borderTopRadius
      : isBookingCompleted
      ? UI_STYLES.overlayHeaderHeightBookingCompleted
      : UI_STYLES.overlayHeaderHeightBookingFlow;

    const backgroundSize = isSearchCriteria
      ? "125%"
      : isBookingCompleted
      ? "200%"
      : "100%";
    const backgroundPosition = isSearchCriteria
      ? "54% 18%"
      : isBookingCompleted
      ? "42% 20%"
      : "50% 20%";
    const isPopUp = Object.values(FORM).includes(Component.displayName ?? "");

    const { type: roleType } = role || {};

    const isAdminDelegation = [USER_ROLE.admin, USER_ROLE.delegation].includes(
      roleType
    );

    return (
      <CSSTransition
        key={Component.name}
        // in->true show enter animation
        // in->false show exit animation
        in={showTransitionIn}
        timeout={400}
        classNames={"next"}
      >
        <Div100vhView
          sx={{
            overflow: "hidden",
            ...(isPopUp
              ? {
                  backgroundColor: "transparent",
                  justifyContent: isDesktop ? "center" : "flex-start",
                  alignItems: "center",
                }
              : {
                  backgroundColor: theme.color.secondary.light_slate.option_7,
                }),
          }}
        >
          {isDesktop && !isPopUp && (
            <NavBar
              isShowNavBar={false}
              handleCloseOverlay={() => {
                isHomePage && history.go(0);
              }}
            />
          )}

          {isAdminDelegation ? (
            isDesktop && isPopUp ? null : (
              <RoleIndicatorBar
                customStyles={{
                  ...(isDesktop
                    ? { pl: 22 }
                    : {
                        px: 2.5,
                      }),
                  background: "rgba(237, 237, 237, 0.7)",
                }}
              />
            )
          ) : null}

          {!isDesktop && (
            <DrawerOverlay
              showOverlay={isShowEditSearchOverlay}
              handleClose={() => {
                setEditOrigin?.(origin);
                setEditDestination?.(destination);
                setEditStartDate?.(startDate);
                setEditTravelDetails?.(travelDetails);
                setEditTravelType?.(travelType);
                setEditTravellerDetails?.(travellerDetails);
                setEditLeisureTravelDetails?.(leisureTravelDetails);

                handleEditClick && handleEditClick();
              }}
              title={en.booking.edit.title}
              component={MobileSearchCriteriaBox}
              data={{
                saveTo: SAVE_DATA_TO.context,
                handleSearchClick: () => {
                  handleEditClick && handleEditClick();
                },
                customStyles: {
                  mx: 2,
                },
              }}
            />
          )}
          {(isOverlayHeaderHidden || !isDesktop) && (
            <Box
              sx={
                isDesktop
                  ? {
                      width: isPopUp ? "0" : "100%",
                      height: UI_STYLES.overlayHeaderHeightSearchCriteria,
                      px: 22,
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "white",
                      boxShadow: theme.boxShadow.important,
                    }
                  : {
                      backgroundImage: `url(${
                        backgroundImgUrl || OverlayBackground
                      })`,
                      width: "100%",
                      height: backgroundHeight,
                      backgroundSize,
                      display: "flex",
                      color: "white",
                      backgroundPosition,
                      px: 2,
                      pt: 2,
                      ...customStyles,
                    }
              }
            >
              <Box sx={{ width: "100%" }}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {!isBookingCompleted && customHandleBackClick && (
                    <IconButton
                      onClick={() => {
                        customHandleBackClick && customHandleBackClick();
                        if (data && data.routeToStep !== 0) {
                          setShowTransitionIn(true);
                        }
                      }}
                      sx={{ mr: 2 }}
                    >
                      <BackIcon fill="white" />
                    </IconButton>
                  )}

                  <Box
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      mr: "auto",
                    }}
                  >
                    {title ? (
                      <Typography variant="title_2_bold">{title}</Typography>
                    ) : (
                      TitleComponent && (
                        <Box
                          sx={{ mt: -1 }}
                          component={TitleComponent}
                          {...data}
                        />
                      )
                    )}
                  </Box>

                  {handleEditClick && (
                    <Box>
                      <IconButton
                        sx={{
                          color: "white",
                          textAlign: "right",
                          width: "45px",
                          pr: 0,
                        }}
                        onClick={handleEditClick}
                      >
                        <Typography variant="subtitle2" fontWeight="bold">
                          {en.common.edit}
                        </Typography>
                      </IconButton>
                    </Box>
                  )}

                  {handleCloseClick && (
                    <Box>
                      <IconButton onClick={handleCloseClick}>
                        <CloseIcon
                          fill={
                            isDesktop
                              ? theme.color.utility.link.option_3
                              : "white"
                          }
                        />
                      </IconButton>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          )}
          <Box
            sx={{
              ...(isSearchCriteria
                ? {
                    background: "white",
                    position: "fixed",
                    display: "flex",
                    flexDirection: "column",
                    ...(isDesktop
                      ? {
                          width: "478px",
                          height: "715px",
                          boxShadow: theme.boxShadow.important,
                          borderRadius: 1,
                          maxHeight: `calc(${screenHeight}px - 32px)`,
                        }
                      : {
                          top: `calc(${
                            UI_STYLES.overlayHeaderHeightSearchCriteria
                          } + ${
                            isAdminDelegation
                              ? UI_STYLES.indicatorBarHeight
                              : "0px"
                          })`,
                          width: "100%",
                          left: "0",
                          bottom: "0",
                          borderTopLeftRadius: "24px",
                          borderTopRightRadius: "24px",
                        }),
                  }
                : isBookingCompleted
                ? {
                    position: "fixed",
                    bottom: "0",
                    left: "0",
                    top:
                      data?.isRebookFlow && isDesktop
                        ? "0px" // if from rebook, rebook overlay no header
                        : `${UI_STYLES.overlayHeaderHeightBookingCompleted}`,
                    width: "100%",
                  }
                : isDesktop
                ? {
                    height: `calc(100% - ${UI_STYLES.navBarHeight}  - ${
                      isAdminDelegation ? UI_STYLES.indicatorBarHeight : "0px"
                    })`,
                  }
                : { height: `calc(100% - ${backgroundHeight})` }),
            }}
          >
            {displayContent}
          </Box>
        </Div100vhView>
      </CSSTransition>
    );
  };

  const viewContainer = () => {
    return isDrawer ? (
      <Div100vhView sx={{ overflow: "hidden" }}>
        <Drawer
          anchor="bottom"
          open={open}
          sx={{
            ...(Object.values(FORM).includes(Component.displayName ?? "") && {
              "&.MuiDrawer-root": {
                "& .MuiDrawer-paper": {
                  background: "transparent",
                },
              },
            }),
          }}
        >
          {SwitchComponent()}
        </Drawer>
      </Div100vhView>
    ) : isDesktop ? (
      <>{displayContent}</>
    ) : (
      <Div100vhView sx={{ overflow: "hidden" }}>
        <>{SwitchComponent()}</>
      </Div100vhView>
    );
  };

  return isDesktop ? (
    <DesktopView>{viewContainer()}</DesktopView>
  ) : (
    <MobileView>{viewContainer()}</MobileView>
  );
};

export default BookingFlowOverlay;

BookingFlowOverlay.defaultProps = {
  isOverlayHeaderHidden: true,
};

export { OVERLAY_VARIANT };
