import React from 'react';
import { MenuList, MenuItem, Box, Typography, Divider, useTheme } from '@mui/material';

import { isArray } from 'lodash';

import { RightArrowIcon } from '../../assets/images';

import { IDelegationRole, IMenuProps } from '../../interfaces';

import { RoleItems } from '../../containers';

const Menu = ({ menuItemList }: IMenuProps) => {
  const theme = useTheme();

  const MenuSx = {
    borderRadius: 1,
    flexGrow: 1,
    mt: 5,
    mx: 2,
    maxWidth: 500,
    boxShadow: theme.palette.boxShadow.dark,
    bgcolor: theme.palette.primary.light,
  };

  const MenuItemSx = (hasSubList: boolean) => ({
    py: 2,
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    cursor: hasSubList ? 'auto' : 'pointer',
    '&:hover': {
      backgroundColor: 'unset',
    },
  });

  return (
    <MenuList sx={MenuSx}>
      {menuItemList.map((item, index) => {
        const { mainText, subList, targetOnClick, rightIcon, svgIcon } = item || {};
        return (
          <>
            <MenuItem key={mainText} href="" sx={MenuItemSx(!!subList?.length)} onClick={targetOnClick}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {svgIcon}
                <Typography
                  variant="body_1_medium"
                  sx={{
                    ml: 1,
                    color: theme.color.secondary.dark_grey.option_1,
                  }}
                >
                  {mainText}
                </Typography>
              </Box>
              {isArray(subList) && subList.length > 0
                ? subList.map((subItem: IDelegationRole, index: number) => {
                    return (
                      <RoleItems
                        key={subItem.employeeId}
                        text={subItem.name}
                        handleOnClick={subItem.handleOnClick}
                        customStyles={index === 0 ? { mt: 1.5 } : {}}
                      />
                    );
                  })
                : rightIcon && <RightArrowIcon fill={theme.color.utility.link.option_3} />}
            </MenuItem>
            {index !== menuItemList.length - 1 && (
              <Box sx={{ px: 2 }}>
                <Divider />
              </Box>
            )}
          </>
        );
      })}
    </MenuList>
  );
};

export default Menu;
