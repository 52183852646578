import { Box, SxProps, Theme, Typography, useTheme } from "@mui/material";
import React from "react";

const DetailsContainer = ({
  title,
  customStyles,
  children,
}: {
  title: string;
  customStyles?: SxProps<Theme>;
  children: React.ReactNode;
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        mt: 4,
        ...customStyles,
      }}
    >
      <Typography
        variant="title_2_bold"
        sx={{ color: theme.palette.text.secondary, mb: 3 }}
      >
        {title}
      </Typography>
      {children}
    </Box>
  );
};

export default DetailsContainer;
