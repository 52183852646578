import React, { useState } from "react";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { useHistory } from "react-router-dom";

import { TRAVELLER_TYPE } from "../../constants/constants";
import { ROOT_PATHS } from "../../constants/paths";
import { SeatClassIcon, DownArrowIcon, UpArrowIcon } from "../../assets/images";
import en from "../../translations/en";

import { getDisplayName } from "../../helpers";

import { useAppSelector } from "../../app/hooks";

import { selectBooking } from "../../slice/bookingSlice";

import { ShadowContent } from "../../components";

const AdminEmployeeTraveller = () => {
  const history = useHistory();
  const theme = useTheme();

  const { travellerDetails } = useAppSelector(selectBooking) || {};
  const { travellerInfo } = travellerDetails || {};
  const { type, salutation, firstName, lastName } = travellerInfo || {};

  const [isViewMore, setIsViewMore] = useState(true);
  const isEmployee = type === TRAVELLER_TYPE.employee;

  const employeeTravelerList: any[] = [];
  if (isEmployee) {
    travellerInfo?.employee &&
      employeeTravelerList.push(travellerInfo.employee);
  } else if (!isEmployee && salutation) {
    employeeTravelerList.push({
      name: getDisplayName({
        title: salutation,
        firstName,
        lastName,
      }),
    });
  }

  return (
    <Box
      component={ShadowContent}
      sx={{
        borderRadius: 2,
        p: 2,
        mb: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          height: "100%",
        }}
      >
        <Typography
          variant="title_2_bold"
          color={theme.color.secondary.dark_grey.option_3}
          sx={{ display: "flex", alignItems: "center", flex: 1 }}
        >
          <SeatClassIcon
            style={{
              marginRight: 11,
              height: "28px",
              width: "28px",
            }}
            fill={theme.color.secondary.dark_grey.option_5}
          />
          {isEmployee
            ? en.booking.travelType.employeeTraveller
            : en.booking.travelType.nonEmployeeTraveller}

          {/* R1.1 don't handle multiple travelers yet */}
          {/* TODO:  */}
          {/* {employeeTravelerList && (
            <Typography
              variant="navigation_bold"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "20px",
                height: "20px",
                borderRadius: "10px",
                background: theme.color.utility.link.option_3,
                ml: 0.5,
                color: "white",
              }}
            >
              {employeeTravelerList.length}
            </Typography>
          )} */}
        </Typography>

        <IconButton
          onClick={() => {
            setIsViewMore(!isViewMore);
          }}
        >
          {isViewMore ? <DownArrowIcon /> : <UpArrowIcon />}
        </IconButton>
      </Box>

      <Collapse in={isViewMore} unmountOnExit>
        <Box sx={{ mt: 1 }}>
          {employeeTravelerList &&
            employeeTravelerList.map((item, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    height: "auto",
                    minHeight: "32px",
                    alignItems: "center",
                    my: 1,
                  }}
                >
                  <Typography
                    component={Box}
                    variant="fine_print_medium"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "20px",
                      height: "20px",
                      borderRadius: "10px",
                      background: theme.color.secondary.light_slate.option_4,
                      ml: 1,
                      mr: 2,
                      color: theme.color.secondary.dark_grey.option_3,
                    }}
                  >
                    {(index + 1).toString()}
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="body_1_regular"
                      color={theme.color.secondary.dark_grey.option_1}
                    >
                      {item.name}
                    </Typography>
                    {isEmployee && (
                      <Typography
                        variant="body_2_regular"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          height: theme.spacing(3),
                          color: theme.color.secondary.dark_grey.option_1,
                        }}
                      >
                        {item.ern}
                      </Typography>
                    )}
                  </Box>
                </Box>
              );
            })}
          <Typography
            variant="body_1_bold"
            component={Button}
            color={theme.color.utility.link.option_3}
            sx={{ ml: "auto", mt: 2 }}
            onClick={() => {
              history.push({
                pathname: ROOT_PATHS.landing,
                state: {
                  isKeepSearchCriteria: true,
                  performShowPopup: true,
                },
              });
            }}
          >
            {en.booking.travelType.editTraveller}
          </Typography>
        </Box>
      </Collapse>
    </Box>
  );
};

export default AdminEmployeeTraveller;
