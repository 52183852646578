import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { showErrorAlert } from '../../services/api';

import en from '../../translations/en';

const CPPAuthFailureTransit = () => {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);

  useEffect(() => {
    if (searchParams) {
      showErrorAlert({
        title: en.errorAlert.prepaidAuth,
        message: searchParams.get('errorMessage') || '',
      });
    }
  }, []);

  return <></>;
};

export default CPPAuthFailureTransit;
