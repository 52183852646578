import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';

import { AddIcon, MinusIcon } from '../../../../assets/images';

const LtTravellerOptionItem = ({
  title,
  subTitle,
  isLeftDisabled,
  isRightDisabled,
  count,
  handleLeftClick,
  handleRightClick,
}: {
  title: string;
  subTitle: string;
  isLeftDisabled: boolean;
  isRightDisabled: boolean;
  count: number;
  handleLeftClick: () => void;
  handleRightClick: () => void;
}) => {
  const theme = useTheme();
  return (
    <Box
      className="traveller_option_item"
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: '76px',
        mt: 1,
        px: 2,
        background: 'white',
        borderRadius: 1,
        boxShadow: theme.boxShadow.important,
      }}
    >
      <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
        <Typography variant="body_1_regular" color={theme.color.secondary.dark_grey.option_1}>
          {title}
        </Typography>
        <Typography variant="navigation_regular" color={theme.color.secondary.dark_grey.option_2} sx={{ mt: 0.5 }}>
          {subTitle}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <MinusIcon
          cursor="pointer"
          style={{ opacity: isLeftDisabled ? 0.3 : 1 }}
          onClick={() => {
            !isLeftDisabled && handleLeftClick();
          }}
        />
        <Typography sx={{ mx: 2 }}>{count}</Typography>
        <AddIcon
          cursor="pointer"
          style={{ opacity: isRightDisabled ? 0.3 : 1, marginRight: '2px' }}
          onClick={() => {
            !isRightDisabled && handleRightClick();
          }}
        />
      </Box>
    </Box>
  );
};

export default LtTravellerOptionItem;
