import { styled, Theme } from '@mui/material/styles';
import { Button } from '@mui/material';

const SearchButton = styled(Button)(({ theme }: { theme: Theme }) => ({
  boxShadow: 'none',
  borderRadius: 4,
  textTransform: 'none',
  fontSize: theme.typography.subtitle1.fontSize,
  height: 40,
  px: 2,
  border: '1px solid',
  paddingTop: '12px',
  fontWeight: 'bold',
  backgroundColor: theme.status.selected,
  '&:hover': {
    backgroundColor: theme.palette.cathayJade.light,
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: theme.palette.cathayJade.light,
  },
}));

const WhiteButton = styled(Button)(({ theme }: { theme: Theme }) => ({
  boxShadow: 'none',
  borderRadius: 4,
  textTransform: 'none',
  fontSize: theme.typography.subtitle1.fontSize,
  height: 40,
  px: 2,
  border: '1px solid',
  paddingTop: '12px',
  color: '#7A7A7A',
  fontWeight: 'bold',
  borderColor: theme.palette.borderColor.dark,
  backgroundColor: theme.palette.primary.light,
  '&:hover': {
    backgroundColor: theme.palette.secondary.light,
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: theme.palette.secondary.light,
  },
}));

export { SearchButton, WhiteButton };
