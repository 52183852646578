import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const ShadowContent = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  background: "white",
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2.5),
  boxShadow: "0 4px 10px 0 #0000001A",
}));

export default ShadowContent;
