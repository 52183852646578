import React from "react";
import { Box, Typography } from "@mui/material";

import { DATE_FORMAT } from "../../../constants/constants";

import { compareDate } from "../../../helpers";

const FlightDateLabel = ({
  departureTime = "",
  arrivalTime = "",
  arrivalDate = "",
  departureDate = "",
  textColor,
  ...props
}: {
  departureTime: string | undefined;
  arrivalTime: string | undefined;
  arrivalDate: string | undefined;
  departureDate: string | undefined;
  textColor?: string;
  [key: string]: any;
}) => {
  // Time difference， will return 1 / -1
  const timeDiff = compareDate(
    arrivalDate,
    departureDate,
    DATE_FORMAT.date,
    DATE_FORMAT.date
  );
  return (
    <Box display="flex" alignItems="center">
      <Typography
        variant="headline_medium"
        color={textColor || "primary.main"}
        {...props}
      >
        {departureTime} - {arrivalTime}
      </Typography>
      {timeDiff !== 0 && (
        <Typography
          variant="fine_print_bold"
          color="borderColor.light"
          sx={{ ml: 0.5, mt: -0.5 }}
        >
          {`${timeDiff > 0 ? "+" : ""}${timeDiff}`}
        </Typography>
      )}
    </Box>
  );
};

export default FlightDateLabel;
