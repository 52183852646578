import React from 'react';
import { Grid, Box, Typography, useTheme } from '@mui/material';

import en from '../../../translations/en';

import { IFareDetailsProps } from '../../../interfaces';

const { title, baseFare, tax, total } = en.booking.flightConfirmation.fareDetails;
const priceLabel = [baseFare, tax, total];

const DtFareDetails = ({ currency, fareDetails }: { currency: string; fareDetails: IFareDetailsProps }) => {
  const theme = useTheme();

  return (
    <Box>
      <Typography variant="body_1_bold" sx={{ mt: 2, px: 1, color: theme.color.secondary.dark_grey.option_1 }}>
        {title}
      </Typography>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1 }} sx={{ px: 1, my: 2 }} justifyContent="space-between">
        {Object.entries(fareDetails).map(([key, fareItem]) => {
          return (
            <Grid item xs={6} key={key} px={1}>
              <Typography variant="navigation_regular" color={theme.color.secondary.grey.option_2}>
                {fareItem.key}
              </Typography>
              <Typography variant="body_1_medium" color={theme.color.secondary.dark_grey.option_3}>
                {fareItem.value}
                {priceLabel.includes(fareItem.key) && (
                  <Typography variant="navigation_medium" sx={{ display: 'inline' }}>
                    {currency || ''}
                  </Typography>
                )}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default DtFareDetails;
