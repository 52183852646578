import _ from 'lodash';
import { BookingDB } from 'database';
import { Pnr } from 'booking';
import { Configuration } from 'configuration';

import { NOMINATED_PASSENGER_AGE_TYPE, TRAVELLER_TYPE } from '../constants/constants';
import en from '../translations/en';

import { INominatedPassenger, IQuotaInfo } from '../interfaces';
import { constructTicketDetailsKeyValues } from '.';

const getTravellerByProperty = (
  travellers: INominatedPassenger[],
  propertys: { [Key: string]: (item: any) => any },
) => {
  return travellers.filter((item: INominatedPassenger) => {
    const propertyList = Object.entries(propertys);
    const res = propertyList.reduce((previous, current) => {
      const [newKey, newValueWithFunc] = current;
      const newSelectedProperty = _.get(item, newKey);
      return newValueWithFunc(newSelectedProperty) && previous;
    }, true);
    return res;
  });
};

const checkNominatedPassengers = (nominatedPassengers: INominatedPassenger[]) => {
  const selectedPassengers = getTravellerByProperty(nominatedPassengers, {
    isSelected: (value) => value === true,
    'validationInfo.restriction.length': (value) => value === 0,
  });

  const adultPassengers = getTravellerByProperty(selectedPassengers, {
    'validationInfo.ageType': (value) => value === NOMINATED_PASSENGER_AGE_TYPE.adult,
    'validationInfo.restriction.length': (value) => value === 0,
  });

  //  adult allowAccompany(true) passengers (age >= 18)
  const adultAllowAccompanyPassengers = getTravellerByProperty(adultPassengers, {
    'validationInfo.allowAccompany': (value) => value === true,
    'validationInfo.restriction.length': (value) => value === 0,
  });

  //  child passengers
  const childPassengers = getTravellerByProperty(selectedPassengers, {
    'validationInfo.ageType': (value) => value === NOMINATED_PASSENGER_AGE_TYPE.child,
    'validationInfo.restriction.length': (value) => value === 0,
  });

  //  infant passengers
  const infantPassengers = getTravellerByProperty(selectedPassengers, {
    'validationInfo.ageType': (value) => value === NOMINATED_PASSENGER_AGE_TYPE.infant,
    'validationInfo.restriction.length': (value) => value === 0,
  });

  //  infant with seat passengers
  const infantWithSeatPassengers = getTravellerByProperty(infantPassengers, {
    infantWithSeat: (value) => value === true,
    'validationInfo.restriction.length': (value) => value === 0,
  });

  // eligible Passengers
  const eligibleTravellers = getTravellerByProperty(nominatedPassengers, {
    'validationInfo.restriction.length': (value) => value === 0,
  });

  // non-Eligible Passengers
  const nonEligibleTravellers = getTravellerByProperty(nominatedPassengers, {
    'validationInfo.restriction.length': (value) => value > 0,
  });

  const checkFooterValid = () => {
    // check ageType -- ADT / CHD / INF
    // 1. ADT(age: above 12) -- age>18: allowAccompany, 12<age<18: not allow Accompany
    // 2. CHD(age: 2~12) -- not allow Accompany
    // 3. INF(age: <2) -- with seat: behavior like CHD, without seat: need age>18 adult Accompany.

    // # of infants (with or without seat) cannot > # of travellers age 18+
    // special handing:  only 1 infant with seat can proceed

    if (childPassengers.length > 0 || adultPassengers.length > 0) {
      if (infantPassengers.length <= adultAllowAccompanyPassengers.length) {
        return true;
      }
    } else if (
      // only have infant(s) scenario handing
      infantPassengers.length === 1 &&
      infantPassengers.length === infantWithSeatPassengers.length
    ) {
      return true;
    }

    return false;
  };

  return {
    canProceed: checkFooterValid(),
    eligibleTravellers,
    nonEligibleTravellers,
    selectedPassengers,
    adultPassengers,
  };
};

const getTicketDetailsDataList = ({
  ticketsBySegment,
  etpPassengerList,
  configurations,
}: {
  ticketsBySegment: Pnr.TicketBySegment[];
  etpPassengerList: BookingDB.EtpPassenger[];
  configurations: Configuration.ConfigurationsClient;
}) => {
  const tmpList = [];

  if (ticketsBySegment?.length) {
    for (let i = 0; i < ticketsBySegment.length; i++) {
      const travellerTickets = ticketsBySegment[i]?.tickets;

      for (let j = 0; j < travellerTickets.length; j++) {
        const currentTravellerTicketItem = travellerTickets[j];

        const latestTicket: Pnr.Ticket = currentTravellerTicketItem?.latestTicket || {};
        const flownTicket: Pnr.FlownTicket = currentTravellerTicketItem?.flownTicket as Pnr.FlownTicket;

        const { ticketDetails, flownDetails } = constructTicketDetailsKeyValues({
          configurations,
          flownTicket,
          latestTicket,
        });

        // relate on story defect 2476
        const isFlownCompleted = !!flownTicket?.flownProcessCompletedAt;

        tmpList.push({
          ticketDetails,
          flownDetails,
          isExpandMore: true,
          lounge: (isFlownCompleted && flownTicket?.lounge) || latestTicket?.lounge,
          traveler: (isFlownCompleted && flownTicket?.paxObj) || latestTicket?.paxObj,
          isViewMore: false,
          isFlownCompleted,
          // etp-3025 add
          isRemoved: false, // new field -> indicate passenger is removed or not
          isAllowRemove: true, //new field -> indicate remove passenger eligibility in passenger level
          age: 0,
          travelWith: undefined,
        });
      }
    }
  }

  // set isRemove data from etpPassengerList array
  // etp-3025 add
  tmpList.forEach((tmpObj: any) => {
    etpPassengerList.forEach((etpObj: BookingDB.EtpPassenger) => {
      if (tmpObj.traveler.dependentId === etpObj.dependentId) {
        tmpObj.isRemoved = !!etpObj.isRemoved;
        tmpObj.isAllowRemove = !!etpObj.isAllowRemove;
        tmpObj.age = etpObj.age;
        tmpObj.travelWith = etpObj.travelWith;
        tmpObj.relationship = etpObj.relationship;
      }
    });
  });

  return tmpList;
};

const removedPassengers = ({ passengers }: { passengers: any[] }) => {
  let isOnlyYoungChildLeft = false; // true: show form; false: not
  let isOnlyOneInfantLeft = false;

  if (passengers && passengers.length > 0) {
    // if array is empty, every() return true

    const onlyChildrenLeft = passengers.every((item: any) => item?.age >= 2 && item?.age < 18);
    const onlySeniorChildLeft = passengers.every((item: any) => item?.age >= 12 && item?.age < 18);

    isOnlyYoungChildLeft = onlyChildrenLeft && !onlySeniorChildLeft;

    isOnlyOneInfantLeft = passengers.every((item: any) => item?.age < 2) && passengers.length === 1;
  }

  return {
    isOnlyYoungChildLeft,
    isOnlyOneInfantLeft,
  };
};

const checkRemainingTravellers = ({ remainingTravellerList }: { remainingTravellerList: any }) => {
  //  child passengers
  const childPassengers = getTravellerByProperty(remainingTravellerList, {
    age: (value: any) => value < 12 && value >= 2,
  });

  // senior Child passengers
  const seniorChildPassengers = getTravellerByProperty(remainingTravellerList, {
    age: (value: any) => value < 18 && value >= 12,
  });

  // check allowCompany , age
  const adultPassengers = getTravellerByProperty(remainingTravellerList, {
    age: (value: any) => value >= 18 || value === null,
  });

  const infantPassengers = getTravellerByProperty(remainingTravellerList, {
    age: (value: any) => value < 2,
  });

  const canProceed = () => {
    if (childPassengers.length > 0 || adultPassengers.length > 0 || seniorChildPassengers.length > 0) {
      if (infantPassengers.length <= adultPassengers.length) {
        return true;
      }
    } else if (
      // only have infant(s) scenario handing
      infantPassengers.length === 1 &&
      remainingTravellerList.length === 1
    ) {
      return true;
    }

    return false;
  };

  return {
    remainingPaxCanProceed: canProceed(),
  };
};

const checkNominatedPassengersQuotaRestriction = ({
  originalRestriction,
  existingRestriction,
  quotaInfo,
}: {
  originalRestriction?: string[];
  existingRestriction?: string[];
  quotaInfo?: IQuotaInfo;
}) => {
  const enQuotaInfo = en.booking.travelType.leisureTravel.quotaElement;

  // handle quota restriction for empty case
  const isEmptyQuota = quotaInfo && 0 >= quotaInfo?.unused;
  const isAddedEmptyQuotaRestrictionBefore = originalRestriction?.includes(enQuotaInfo.emptyQuotaRestriction) || false;
  const quotaRestriction =
    isEmptyQuota && !isAddedEmptyQuotaRestrictionBefore ? [enQuotaInfo.emptyQuotaRestriction] : [];

  const updatedRestriction = [...(existingRestriction || []), ...quotaRestriction];

  return updatedRestriction;
};

const isPaxAgeUnderSixteen = (age: number): boolean => {
  return age >= 2 && age < 16;
};

// check if selected at least one adt pax
const isSelectedAdult = (travllerList: INominatedPassenger[]): boolean => {
  return travllerList.filter((item) => item.age >= 18 && item.isSelected).length > 0;
};

// check if selected emp traveller
const isSelectedEMP = (travllerList: INominatedPassenger[]): boolean => {
  return (
    travllerList.filter((item) => item.beneficiaryTypeCode === TRAVELLER_TYPE.employee && item.isSelected).length > 0
  );
};

export {
  getTravellerByProperty,
  checkNominatedPassengers,
  getTicketDetailsDataList,
  removedPassengers,
  checkRemainingTravellers,
  checkNominatedPassengersQuotaRestriction,
  isPaxAgeUnderSixteen,
  isSelectedAdult,
  isSelectedEMP,
};
