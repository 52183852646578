import { useContext, useEffect } from 'react';

import { AdminAccessEmployeeAction, AdminAccessEmployeeState } from '../../../context';
import { ConcessionList, ConcessionDetail } from '.';
import { getConcessions } from '../../../services/admin/employee';

const AdminConcession = () => {
  const { focusConcession, employeeDetail } = useContext(AdminAccessEmployeeState) || {};
  const { setConcessions, setConcessionName, setConcessionGrouping, setFocusConcession } =
    useContext(AdminAccessEmployeeAction) || {};

  const getAdminConcessionAction = async () => {
    const userId = employeeDetail.profile.employeeId;
    const profileRevision = employeeDetail.profile.revision;
    const result = await getConcessions({ userId, profileRevision });

    if (result) {
      setConcessions(result);
    }
  };

  useEffect(() => {
    getAdminConcessionAction();
    return () => {
      // TBC
      // will clear data, and route back to list page
      setFocusConcession(null);
      setConcessions(null);
      setConcessionName(null);
      setConcessionGrouping(null);
    };
  }, []);

  return <>{focusConcession ? <ConcessionDetail /> : <ConcessionList />}</>;
};

export default AdminConcession;
