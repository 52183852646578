import React from 'react';
import { Box, useTheme } from '@mui/material';

import { RADIO_BUTTON_GROUP_VARIANT } from '../../../../components/Button/RadioButtonGroup';

import { IButtonValue } from '../../../../interfaces';

import { RadioButtonGroup } from '../../../../components';

const TravelPurposeToggleGroup = ({
  list,
  itemOnChange,
  defaultVal,
}: {
  list: any;
  defaultVal: string;
  itemOnChange: (value: IButtonValue) => void;
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        flex: 0,
        px: 2,
        pt: 2,
        pb: 1.75,
      }}
    >
      {/* Travel Type Selection */}
      <RadioButtonGroup
        options={list}
        variant={RADIO_BUTTON_GROUP_VARIANT.CHIP}
        titleVariant="body_2_medium"
        defaultVal={defaultVal}
        isChip
        onChange={itemOnChange}
        customButtonStyle={{
          width: 'auto',
          height: '30px',
          border: 'none',
          py: 0,
          px: 1.5,
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          '&.MuiButtonBase-root': {
            '&.MuiToggleButtonGroup-grouped': {
              m: 0,
              mr: 1,
              background: theme.color.secondary.light_slate.option_5,
            },
            '&.MuiToggleButtonGroup-grouped:last-of-type': {
              mr: 0,
            },
            '.MuiTypography-root': {
              color: theme.color.secondary.dark_grey.option_3,
            },
            '&.Mui-selected, &.Mui-selected:hover': {
              background: theme.color.secondary.dark_grey.option_3,
              '.MuiTypography-root': {
                color: 'white',
              },
            },
          },
        }}
      />
    </Box>
  );
};

export default TravelPurposeToggleGroup;
