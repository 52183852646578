import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme, TextField, Autocomplete, SxProps, Theme } from '@mui/material';
import { debounce } from 'lodash';

import en from '../../translations/en';

import { selectApp } from '../../slice/appSlice';
import { useAppSelector } from '../../app/hooks';

import { FONT_WEIGHT } from '../../constants/font';
import { UI_STYLES } from '../../constants/constants';
import { BOOKING_PATHS } from '../../constants/paths';

const AutoCompleteBox = ({
  label,
  options,
  value,
  inputValue,
  noOptionsText,
  onChange,
  onInputChange,
  onFocus,
  onBlur,
  onKeyDown,
  onHighlightChange,
  onClose,
  dropDownListPop,
  renderOption,
  filterOptions,
  customStyles,
  paperStyles,
  textFieldStyle,
}: {
  label: string;
  options: any[];
  value: string;
  inputValue: string;
  noOptionsText?: string | null;
  onChange?: (newValue: any) => void;
  onInputChange?: (newInputValue: string) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onKeyDown?: () => void;
  onHighlightChange?: (event: any) => void;
  onClose?: () => void;
  dropDownListPop: (props: any) => JSX.Element | null;
  renderOption: undefined | ((props: any, option: any) => JSX.Element);
  filterOptions?: (props: any) => any[];
  customStyles?: SxProps<Theme>;
  paperStyles?: SxProps<Theme>;
  textFieldStyle?: SxProps<Theme>;
}) => {
  const theme = useTheme();
  const history = useHistory();

  const { searchBarInputWidth } = useAppSelector(selectApp) || {};
  const isSearchResultPage = history.location.pathname === BOOKING_PATHS.booking;
  const destinationPopLeft = isSearchResultPage ? searchBarInputWidth + 8 : searchBarInputWidth;

  return (
    <Autocomplete
      key={label}
      options={options}
      sx={{ width: '100%', pl: 2.5, ...customStyles }}
      componentsProps={{
        paper: {
          sx: paperStyles || {
            width: `${searchBarInputWidth * 2 - 24}px`,
            position: 'absolute',
            left: label !== en.booking.from ? `-${destinationPopLeft}px` : 0,
            overflowY: 'auto',
            flexDirection: 'row',
            maxHeight: UI_STYLES.searchBarInputOverlayHeight,
            zIndex: 1,
            backgroundColor: 'white',
            borderRadius: '4px',
            boxShadow: theme.boxShadow.important,
            mt: isSearchResultPage ? 2.5 : 3.5,
            ul: {
              height: '100%',
              '&::-webkit-scrollbar': {
                display: 'none', // safari and chrome
              },
              '-ms-overflow-style': 'none', // IE
              'scrollbar-width': 'none', // firefox
              li: {
                height: '64px',
              },
            },
            // "no options" container style
            '.MuiAutocomplete-noOptions': {
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'gray.dark',
              fontSize: theme.typography.body_2_regular.fontSize,
              fontWeight: theme.typography.body_2_regular.fontWeight,
            },
          },
        },
      }}
      value={value}
      inputValue={inputValue}
      onChange={debounce((_, newValue) => {
        onChange?.(newValue);
      })}
      onInputChange={debounce((_, newInputValue) => {
        onInputChange?.(newInputValue);
      })}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          // Prevent's default 'Enter' behavior.
          event.preventDefault();
          onKeyDown?.();
        }
      }}
      onHighlightChange={(event) => {
        onHighlightChange?.(event);
      }}
      onClose={() => {
        onClose?.();
      }}
      blurOnSelect={true}
      clearIcon={null}
      openOnFocus
      noOptionsText={noOptionsText || null}
      filterOptions={filterOptions || ((array) => array)}
      popupIcon={null}
      PopperComponent={dropDownListPop}
      renderInput={(params) => {
        return (
          <TextField
            label={label}
            variant="standard"
            {...params}
            sx={{
              whiteSpace: 'pre',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              cursor: 'pointer',
              background: theme.color.secondary.light_slate.option_6,
              borderRadius: 0.5,
              pl: 2,
              height: '48px',
              '.MuiFormLabel-root': {
                color: theme.color.secondary.grey.option_3,
                fontSize: theme.typography.body_1_medium.fontSize,
                fontWeight: FONT_WEIGHT.regular,
                mt: 0.5,
                pl: 2.5,
                top: '-12px',
                '&.Mui-focused': {
                  color: theme.color.utility.link.option_3,
                },
                '&.MuiInputLabel-shrink': {
                  top: '0px',
                },
              },
              '& label[data-shrink=false]+.MuiInputBase-formControl': {
                '& .MuiInputBase-input': {
                  mt: 3,
                },
              },
              '.MuiInput-root': {
                color: theme.color.secondary.dark_grey.option_3,
                fontWeight: FONT_WEIGHT.medium,
                width: 'auto',
                marginRight: '-56px',
              },
              ...textFieldStyle,
            }}
            InputProps={{ ...params.InputProps, disableUnderline: true }}
            onFocus={debounce((event) => {
              onFocus?.(event);
            })}
            onBlur={(event) => {
              onBlur?.(event);
            }}
          />
        );
      }}
      renderOption={renderOption}
    />
  );
};

export default memo(AutoCompleteBox);
