import React from "react";
import { Box, Typography, SxProps, Theme } from "@mui/material";

import en from "../../../translations/en";

import theme from "../../../style/theme";

import { ShadowContent } from "../../../components";

const BaseConcessionInfo = ({
  data,
  customStyles,
}: {
  data: string;
  customStyles?: SxProps<Theme>;
}) => {
  return (
    <Box component={ShadowContent} sx={{ mb: 2, ...customStyles }}>
      <Typography
        sx={{ mb: 2, color: theme.color.secondary.dark_grey.option_1 }}
        variant="body_1_bold"
      >
        {en.booking.travelType.concession}
      </Typography>
      <Typography
        variant="body_1_regular"
        color={theme.color.secondary.dark_grey.option_1}
        mt={0.375}
      >
        {data}
      </Typography>
    </Box>
  );
};

export default BaseConcessionInfo;
