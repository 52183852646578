import React from 'react';
import { Grid, Box, Typography, Card, CardActionArea, Divider, useTheme, Theme } from '@mui/material';

import { RightArrowIcon, ExclamationIcon } from '../../../assets/images';

import { IUserProfileList, IUserProfileListProps } from '../../../interfaces';

import { DesktopView, ETPLink, MobileView } from '../../../components';

const WarningIcon = ({ theme }: { theme: Theme }) => (
  <ExclamationIcon width="16" height="16" fill={theme.color.utility.error.option_3} />
);

const UserProfileNavBar = ({ list, header }: IUserProfileListProps) => {
  const theme = useTheme();
  const textColor = theme.color.secondary.dark_grey.option_1;

  return (
    <Grid container spacing={2} mt={1}>
      <Grid item xs={12}>
        <DesktopView>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            {list.map((item: IUserProfileList, index: number) => (
              <Box key={item.title}>
                <CardActionArea
                  component={ETPLink}
                  theme={theme}
                  to={item.url}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '309px',
                    ml: index % 3 !== 0 ? 2 : 0,
                    mb: 2,
                    borderRadius: 1,
                    '& .MuiCardActionArea-focusHighlight': {
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      width: '100%',
                      height: '96px',
                      bgcolor: 'white',
                      borderRadius: 1,
                      pl: 2,
                      pr: 2,
                      boxShadow: theme.boxShadow.important,
                    }}
                  >
                    {item.desktopIcon}
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        variant="headline_medium"
                        sx={{
                          color: textColor,
                          mt: 1.75,
                        }}
                      >
                        {item.title}
                      </Typography>
                      {item?.isShowWarning && (
                        <Box sx={{ display: 'flex', ml: 'auto', mt: 1.75 }}>
                          <WarningIcon theme={theme} />
                        </Box>
                      )}
                    </Box>
                  </Box>
                </CardActionArea>
              </Box>
            ))}
          </Box>
        </DesktopView>
        <MobileView>
          <Card sx={{ boxShadow: theme.boxShadow.important }}>
            <Typography
              variant="headline_medium"
              sx={{
                color: textColor,
                mt: 2,
                px: 2,
              }}
            >
              {header}
            </Typography>

            {list.map((item: IUserProfileList, index: number) => (
              <Box key={item.title}>
                <CardActionArea
                  component={ETPLink}
                  theme={theme}
                  to={item.url}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    p: 2,
                    '& .MuiCardActionArea-focusHighlight': {
                      backgroundColor: 'transparent',
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {item.icon}
                    <Typography
                      variant="body_1_regular"
                      sx={{
                        ml: 1,
                        color: textColor,
                      }}
                    >
                      {item.title}
                    </Typography>
                  </Box>

                  {item?.isShowWarning && (
                    <Box sx={{ display: 'flex', ml: 'auto', mr: 1.5 }}>
                      <WarningIcon theme={theme} />
                    </Box>
                  )}

                  <RightArrowIcon fill={theme.color.utility.link.option_3} />
                </CardActionArea>

                {index !== list.length - 1 && <Divider sx={{ mx: 2 }} />}
              </Box>
            ))}
          </Card>
        </MobileView>
      </Grid>
    </Grid>
  );
};

export default UserProfileNavBar;
