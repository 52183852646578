import { Box, IconButton, useTheme } from '@mui/material';
import { BookingReferenceHeader } from '..';
import { UI_STYLES } from '../../../constants/constants';
import { CloseIcon } from '../../../assets/images';

const DesktopHeaderContainer = ({
  bookingRecLoc,
  handleClose,
}: {
  bookingRecLoc: string | undefined;
  handleClose: () => void;
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: '100%',
        height: UI_STYLES.overlayHeaderHeightSearchCriteria,
        mt: -5,
        px: 22,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'white',
        boxShadow: theme.boxShadow.important,
      }}
    >
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              mr: 'auto',
            }}
          >
            <BookingReferenceHeader recLoc={bookingRecLoc || ''} />
          </Box>

          <IconButton onClick={handleClose}>
            <CloseIcon fill={theme.color.utility.link.option_3} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default DesktopHeaderContainer;
