import React from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, useTheme } from '@mui/material';

import { DownArrowIcon, InfoIcon, LaughIcon, SadIcon, SmileIcon } from '../../../../assets/images';

type Props = {
  title: string;
  dateList: any[];
  desc: DescDetails;
};

type DescDetails = {
  laugh: string;
  smile: string;
  sad: string;
};

const FlightSearchFooter = (props: Props) => {
  const theme = useTheme();
  return (
    <Accordion
      disableGutters
      elevation={0}
      sx={{
        borderRadius: '4px !important',
        minHeight: '36px',
        backgroundColor: theme.color.secondary.light_slate.option_6,
        '&:before': {
          display: 'none',
        },
        '.MuiAccordionDetails-root': {
          pt: 0,
          pb: 1,
          px: 4.125,
        },
      }}
    >
      <AccordionSummary
        expandIcon={<DownArrowIcon />}
        aria-controls="panel1a-content"
        sx={{
          minHeight: '36px',
          height: '36px',
          lineHeight: '36px',
          color: theme.color.utility.link.option_3,
          pl: 1.3125,
          pr: 2.5,
          '.MuiAccordionSummary-content': {
            display: 'flex',
            alignItems: 'center',
          },
        }}
      >
        <InfoIcon width="16" height="16" fill={theme.color.utility.link.option_3} />
        <Typography variant="navigation_medium" sx={{ ml: 0.8125 }}>
          {props.title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          variant="navigation_regular"
          sx={{
            lineHeight: '20px',
            color: '#595959',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Box sx={{ mr: 1 }}>
              <LaughIcon />
            </Box>
            {props.desc.laugh}
          </Box>
        </Typography>
        <Typography
          variant="navigation_regular"
          sx={{
            lineHeight: '20px',
            color: '#595959',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Box sx={{ mr: 1 }}>
              <SmileIcon />
            </Box>
            {props.desc.smile}
          </Box>
        </Typography>
        <Typography
          variant="navigation_regular"
          sx={{
            lineHeight: '20px',
            color: '#595959',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Box sx={{ mr: 1 }}>
              <SadIcon />
            </Box>
            {props.desc.sad}
          </Box>
        </Typography>
        {props.dateList.map((item, key) => (
          <Typography
            variant="navigation_regular"
            sx={{ mt: 1.5, color: '#595959' }}
            key={key}
            dangerouslySetInnerHTML={{ __html: item }}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

export default FlightSearchFooter;
