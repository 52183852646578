import React from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme } from '@mui/material';

import { useAppSelector } from '../../../app/hooks';
import { selectApp } from '../../../slice/appSlice';

import { FormButton } from '../../../components';
import { CancelBookingDetail } from '../..';

interface IDialogContent {
  title: string;
  yesTitle: string;
  noTitle?: string;
  bookingResult?: any;
}

const LtCancelBookingDialog = ({
  open,
  handleCancelBookingAction,
  handleCloseDialog,
  dialogContent,
}: {
  open: boolean;
  handleCancelBookingAction: () => void;
  handleCloseDialog: () => void;
  dialogContent: IDialogContent;
}) => {
  const theme = useTheme();
  const { isDesktop } = useAppSelector(selectApp) || {};

  const { title, bookingResult, yesTitle, noTitle } = dialogContent || {};

  return (
    <Dialog
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'baseline',
          '& .MuiPaper-root': {
            p: 4,
            m: 5,
            width: '100%',
            maxWidth: isDesktop ? '478px' : '343px',
            borderRadius: 2,
            overflowX: 'hidden',
            boxShadow: theme.boxShadow.important,
          },
        },
      }}
      open={open}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <DialogTitle
          sx={{
            textAlign: 'center',
            pb: 0,
            px: 0,
            mb: 1.125,
            width: isDesktop ? '312px' : '279px',
            lineHeight: '30px',
            fontWeight: '700',
            fontSize: '24px',
            color: theme.palette.text.secondary,
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent sx={{ py: 0, mt: isDesktop ? 2 : 0, px: isDesktop ? 4.5 : 4 }}>
          <DialogContentText
            sx={{
              textAlign: 'center',
              width: isDesktop ? '406px' : '279px',
              lineHeight: '24px',
              fontWeight: '400',
              fontSize: '16px',
              color: theme.palette.text.secondary,
            }}
          >
            <CancelBookingDetail bookingResult={bookingResult} />
          </DialogContentText>
        </DialogContent>
      </Box>
      <DialogActions
        sx={{
          p: 0,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <FormButton
          theme={theme}
          colour="outline"
          variant="contained"
          color="success"
          size="large"
          customStyles={{
            borderRadius: 8,
            width: isDesktop ? '195px' : '140px',
            height: '44px',
            '&:hover': {
              backgroundColor: 'white',
              boxShadow: 'none',
            },
          }}
          onClick={() => {
            handleCloseDialog?.();
          }}
          sx={{
            '&:not(:first-of-type), &:first-of-type': {
              ml: 0,
            },
          }}
        >
          {noTitle}
        </FormButton>

        <FormButton
          theme={theme}
          colour="green"
          variant="contained"
          color="success"
          size="large"
          customStyles={{
            borderRadius: 8,
            width: isDesktop ? '195px' : '140px',
            height: '44px',
          }}
          onClick={() => {
            handleCancelBookingAction?.();
          }}
          sx={{
            '&:not(:first-of-type), &:first-of-type': {
              ml: 0,
            },
          }}
        >
          {yesTitle}
        </FormButton>
      </DialogActions>
    </Dialog>
  );
};

export default LtCancelBookingDialog;
