import { Typography, Box, useTheme, Grid } from '@mui/material';

import en from '../../../../translations/en';
import { FONT_WEIGHT } from '../../../../constants/font';
import { FORM } from '../../../../constants/constants';

import { getDisplayVal, isEmptyOrUndefined } from '../../../../helpers';

import { FormButton, ShadowContent, WarningAlert } from '../../../../components';

interface IUSAddress {
  street: string;
  city: string;
  state: string;
  zipCode: string;
}

const ViewUsAddressDetails = ({
  handleEditClick,
  setIsRestrictBooking,
  data,
}: {
  handleEditClick: (type: string) => void;
  setIsRestrictBooking: (value: boolean) => void;
  data?: IUSAddress;
}) => {
  const theme = useTheme();
  const { title, street, city, state, zipCode } = en.booking.flightConfirmation.travelDetails.usAddress;
  const isUsAddressMandatory = Object.values(data || {}).filter((item) => isEmptyOrUndefined(item)).length > 0;

  setIsRestrictBooking(isUsAddressMandatory);
  return (
    <Box component={ShadowContent} sx={{ mb: 2, p: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography sx={{ mb: 2, color: theme.color.secondary.dark_grey.option_1 }} variant="body_1_bold">
          {title}
        </Typography>
        <FormButton
          theme={theme}
          customStyles={{
            height: '18px',
            width: '27px',
            minWidth: '27px',
            fontSize: '0.875rem',
            lineHeight: '18px',
            fontWeight: FONT_WEIGHT.bold,
            padding: 0,
          }}
          colour="transparent"
          onClick={() => handleEditClick(FORM.usAddress)}
        >
          {en.common.edit}
        </FormButton>
      </Box>

      <Box>
        {/* Warning Box */}
        {isUsAddressMandatory && <WarningAlert warningMsg={en.booking.confirmation.mandatory} />}

        <Grid container rowSpacing={0} columnSpacing={1} sx={{ my: 2 }}>
          <Grid xs={12} pl={1}>
            <Typography variant="navigation_regular" color={theme.color.secondary.grey.option_2}>
              {street}
            </Typography>
            <Typography variant="body_1_medium" color={theme.color.secondary.dark_grey.option_3}>
              {getDisplayVal(data?.street)}
            </Typography>
          </Grid>
        </Grid>

        <Grid container rowSpacing={0} columnSpacing={1} sx={{ my: 2 }}>
          <Grid xs={12} pl={1}>
            <Typography variant="navigation_regular" color={theme.color.secondary.grey.option_2}>
              {city}
            </Typography>
            <Typography variant="body_1_medium" color={theme.color.secondary.dark_grey.option_3}>
              {getDisplayVal(data?.city)}
            </Typography>
          </Grid>
        </Grid>

        <Grid container rowSpacing={0} columnSpacing={1} sx={{ my: 2 }}>
          <Grid xs={12} pl={1}>
            <Typography variant="navigation_regular" color={theme.color.secondary.grey.option_2}>
              {state}
            </Typography>
            <Typography variant="body_1_medium" color={theme.color.secondary.dark_grey.option_3}>
              {getDisplayVal(data?.state)}
            </Typography>
          </Grid>
        </Grid>

        <Grid container rowSpacing={0} columnSpacing={1} sx={{ my: 2 }}>
          <Grid xs={12} pl={1}>
            <Typography variant="navigation_regular" color={theme.color.secondary.grey.option_2}>
              {zipCode}
            </Typography>
            <Typography variant="body_1_medium" color={theme.color.secondary.dark_grey.option_3}>
              {getDisplayVal(data?.zipCode)}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ViewUsAddressDetails;
