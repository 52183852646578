import React, { useEffect, useState } from 'react';
import { Box, Typography, Divider, useTheme } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { FlightSearch } from 'flightSearch';
import _ from 'lodash';

import en from '../../../translations/en';
import { DATE_FORMAT, LABEL_CATEGORY, SEAT_CLASS_TYPE_MAPPING, TRAVEL_PURPOSE } from '../../../constants/constants';
import { TickIcon } from '../../../assets/images';

import { ISelectedFlight } from '../../../interfaces';

import {
  formatDateAsString,
  getColorOfSeatClassStatus,
  getDisplayLabel,
  getDisplayVal,
  getSeatClass,
  getSeatDisplayByAvailability,
} from '../../../helpers';

import { useAppSelector } from '../../../app/hooks';

import { selectApp } from '../../../slice/appSlice';
import { selectConfiguration } from '../../../slice/configurationSlice';
import { selectBooking } from '../../../slice/bookingSlice';

import { ShadowContent } from '../../../components';
import { FlightODLabel, FlightDateLabel } from '../../../containers';

export const getDtSeatClassForDisplay = (availability?: string, type?: string) => {
  return {
    status: getDisplayVal(getSeatDisplayByAvailability(availability)),
    title: getDisplayVal(type && SEAT_CLASS_TYPE_MAPPING[type]),
  };
};

const SegmentInfo = ({
  flightInfo,
  subcomponent,
  isShowSegmentStatus = true,
}: {
  flightInfo: ISelectedFlight;
  subcomponent?: {
    component: React.ComponentType<any>;
    props: any;
  };
  isShowSegmentStatus?: boolean;
}) => {
  const theme = useTheme();
  const { isDesktop } = useAppSelector(selectApp) || {};
  const { travelType, leisureTravelDetails } = useAppSelector(selectBooking) || {};
  const { configurations } = useAppSelector(selectConfiguration);

  const isLT = travelType === TRAVEL_PURPOSE.employeeLeisureTravel;
  const { seatOption: ltSeatClass, sub } = leisureTravelDetails || {};

  const { component: Subcomponent, props: subcomponentProps } = subcomponent || {};

  const {
    departurePort,
    arrivalPort,
    departureTime,
    arrivalTime,
    arrivalDate,
    callSign,
    departureDate,
    seatClass,
    availability: availabilityStatus,
    status,
  } = flightInfo || {};

  const { type, availability } = seatClass || {};
  const [selectedSeatClass, setSelectedSeatClass] = useState<{
    status: string;
    title: string;
  }>(getDtSeatClassForDisplay(availability, type));

  const segmentStatus = getDisplayLabel(configurations.labels, LABEL_CATEGORY.segmentStatus, status);

  useEffect(() => {
    if (isLT) {
      let defaultStatus = en.booking.flightClassStatus.standby;

      // handle NOSUB type for display confirm, waitlist status
      const isNoSub = sub === en.booking.travelType.noSubLoad;
      if (isNoSub && availabilityStatus && ltSeatClass) {
        defaultStatus =
          availabilityStatus?.[ltSeatClass as keyof FlightSearch.IntegratedFlightAvailability] || en.common.empty;
      }

      setSelectedSeatClass({
        status: status ? segmentStatus : _.capitalize(defaultStatus),
        title: getDisplayVal(getSeatClass(ltSeatClass)),
      });
    } else {
      setSelectedSeatClass(getDtSeatClassForDisplay(availability, type));
    }
  }, [type, availability, ltSeatClass, isLT]);

  return (
    <Box
      component={ShadowContent}
      px={1}
      sx={{
        maxWidth: isDesktop ? '518px' : 'none',
        margin: 'auto',
        mb: 2,
        pb: 0,
        pt: 2,
      }}
    >
      <Box
        component={Typography}
        variant="body_2_medium"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          px: 1,
        }}
        columnGap={1}
      >
        <Typography color={theme.color.utility.link.option_3} variant="inherit">
          <TickIcon style={{ marginRight: 4, width: 11, height: 11 }} />
          {callSign}
        </Typography>

        <CircleIcon sx={{ fontSize: 4, color: theme.color.secondary.dark_grey.option_6 }} />

        <Typography variant="inherit" color={theme.color.secondary.dark_grey.option_2}>
          {formatDateAsString(departureDate, DATE_FORMAT.ddmmmyyyy, DATE_FORMAT.date)}
        </Typography>
      </Box>
      <Box
        component={Typography}
        display="flex"
        alignItems={isShowSegmentStatus ? 'center' : 'end'}
        justifyContent="space-between"
        variant="body_1_regular"
        fontWeight="normal"
        my={2}
        px={1}
      >
        <Box>
          <FlightODLabel
            departurePort={departurePort}
            arrivalPort={arrivalPort}
            color={theme.color.secondary.dark_grey.option_3}
            variant="body_1_regular"
          />
          <FlightDateLabel
            departureTime={departureTime}
            arrivalTime={arrivalTime}
            arrivalDate={arrivalDate}
            departureDate={departureDate}
            variant="body_1_medium"
          />
        </Box>
        <Box
          variant="body_1_regular"
          component={Typography}
          align="right"
          color={theme.color.secondary.dark_grey.option_2}
        >
          {selectedSeatClass.title}

          {isShowSegmentStatus && (
            <Typography
              variant="body_1_medium"
              sx={{
                color: isLT
                  ? theme.color.secondary.dark_grey.option_5
                  : getColorOfSeatClassStatus(selectedSeatClass.status),
              }}
            >
              {selectedSeatClass.status}
            </Typography>
          )}
        </Box>
      </Box>
      {Subcomponent && (
        <>
          <Divider
            sx={{
              m: '0 8px',
              height: '0.5px',
            }}
          />
          <Subcomponent {...subcomponentProps} />
        </>
      )}
    </Box>
  );
};

export default SegmentInfo;
