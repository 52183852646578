import React, { useEffect, useRef, useState, useContext } from 'react';
import { Box, Grid, Popper, Typography, useTheme } from '@mui/material';
import { shallowEqual } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import en from '../../../../translations/en';
import { CompanyLogo } from '../../../../assets/images';
import { SAVE_DATA_TO, HKG } from '../../../../constants/constants';
import { BOOKING_PATHS } from '../../../../constants/paths';

import { Configuration } from 'configuration';

import { formatAirportValue, handleFilterList, setInputValue } from '../../../../helpers';

import { EditSearchBarState, EditSearchBarAction } from '../../../../context';

import { selectBooking, setOrigin, setDestination } from '../../../../slice/bookingSlice';
import { selectApp } from '../../../../slice/appSlice';
import { selectAuth } from '../../../../slice/authSlice';
import { selectConfiguration } from '../../../../slice/configurationSlice';
import { useAppSelector, useAppDispatch } from '../../../../app/hooks';

import { Footer, AutoCompleteBox, ReduxFormTextInput, MatchAndHighlightLabel } from '../../../../components';
import { ODInputField, ODExchange, MobileODFilterList } from '../../..';

const LocationSelection = ({
  handleFooterClick,
  saveTo = SAVE_DATA_TO.redux,
  isFromRebookFlow,
  isRebookFromMyBooking,
}: {
  handleFooterClick?: () => void;
  saveTo?: string;
  // etp-2976 add
  isFromRebookFlow?: boolean;
  isRebookFromMyBooking?: boolean;
}) => {
  const location = useLocation<{
    isFooterUpdate?: boolean;
  }>();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const theme = useTheme();

  const editSearchData = useContext(EditSearchBarState);
  const { setEditOrigin, setEditDestination } = useContext(EditSearchBarAction);

  const isSearchResultPage = history.location.pathname === BOOKING_PATHS.booking;

  const { ern } = useAppSelector(selectAuth) || {};
  const { origin, destination } = isSearchResultPage ? editSearchData : useAppSelector(selectBooking, shallowEqual);

  const [isFirstInit, setIsFirstInit] = useState(true);
  const { isDesktop } = useAppSelector(selectApp) || {};
  const { airports } = useAppSelector(selectConfiguration);
  const [previousFieldIsOrigin, setPreviousFieldIsOrigin] = useState(false);
  const [currentFieldIsOrigin, setCurrentFieldIsOrigin] = useState(false);
  const [filteredPortList, setFilteredPortList] = useState<Configuration.Airport[] | null>(null);

  const [currentOrigin, setCurrentOrigin] = useState<Configuration.Airport | null>(origin);
  const [currentDestination, setCurrentDestination] = useState<Configuration.Airport | null>(destination);
  const [keyword, setKeyword] = useState<string>('');

  const originRef = useRef<HTMLInputElement>(null);
  const destinationRef = useRef<HTMLInputElement>(null);
  const originDash = useRef<any>('');
  const destinationDash = useRef<any>('');

  const isFooterUpdate = location.state?.isFooterUpdate;
  const [originInputValue, setOriginInputValue] = useState('');
  const [destinationInputValue, setDestinationInputValue] = useState('');

  useEffect(() => {
    if (originRef.current && destinationRef.current) {
      setInputValue(originRef.current, formatAirportValue(origin));
      setInputValue(destinationRef.current, formatAirportValue(destination));
    }
  }, [origin, destination]);

  // fix react closure of fetch origin when originInput onBlur
  useEffect(() => {
    originDash.current = formatAirportValue(origin);
  }, [origin]);

  // fix react closure of fetch destination when destinationInput onBlur
  useEffect(() => {
    destinationDash.current = formatAirportValue(destination);
  }, [destination]);

  useEffect(() => {
    setIsFirstInit(true);
  }, [ern]);

  useEffect(() => {
    if (!origin && !destination && airports) {
      const defaultOrigin = airports.find((airport: any) => airport.airportCode && airport.airportCode === HKG);

      if (defaultOrigin && isFirstInit) {
        setIsFirstInit(false);
        dispatch(setOrigin(defaultOrigin));
        setCurrentOrigin(defaultOrigin);
      }

      if (isDesktop) {
        // set destination "null" & cleanup destination input value
        dispatch(setDestination(null));
      }

      // cleanup destination input value
      setDestinationInputValue('');
    }
  }, [airports, origin, destination, isFirstInit]);

  // cleanup local data in this component
  useEffect(() => {
    return () => {
      setCurrentDestination(null);
    };
  }, []);

  const filterODPortList = (keyword: string, isOriginField?: boolean) => {
    const filterResult = handleFilterList(origin, destination, airports, keyword, isOriginField);
    setFilteredPortList(filterResult);
  };

  const dropDownListPop = (props: any) => {
    if (
      // focus origin input & exist origin input value
      (currentFieldIsOrigin && originInputValue.length >= 2) ||
      // focus destination input & exits destination input value
      (!currentFieldIsOrigin && destinationInputValue.length >= 2)
    ) {
      return <Popper {...props} style={{ width: 0 }} placement="bottom-start" />;
    }
    return null;
  };

  const dropDownListPopOption = (props: any, option: any) => {
    return (
      <li key={option.airportCode} {...props}>
        <Box display="flex" alignItems="center">
          <Typography
            sx={{
              bgcolor: theme.color.secondary.light_slate.option_6,
              borderRadius: theme.spacing(0.5),
              width: '32px',
              height: '32px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            variant="navigation_bold"
          >
            {/* {option.cityCode} */}
            {option.airportCode}
          </Typography>
          {option.isOperatedByCX && <CompanyLogo width="24px" height="24px" style={{ paddingLeft: '12px' }} />}
        </Box>
        <Typography flex="1" variant="body_2_regular" sx={{ ml: 1 }}>
          <MatchAndHighlightLabel label={option.label} keyword={keyword} fontWeight={900} />
        </Typography>
      </li>
    );
  };

  const saveData = () => {
    if (saveTo === SAVE_DATA_TO.context) {
      setEditOrigin?.(currentOrigin);
      setEditDestination?.(currentDestination);
    } else {
      dispatch(setOrigin(currentOrigin));
      dispatch(setDestination(currentDestination));
    }
    handleFooterClick && handleFooterClick();
  };

  return isDesktop ? (
    <>
      <Box
        id="origin-input"
        sx={{
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          minWidth: 0,
        }}
      >
        {isFromRebookFlow ? (
          <ReduxFormTextInput
            input={{
              value: formatAirportValue(origin),
            }}
            title={en.booking.from}
            isReadOnly={true}
            customStyles={{
              mt: isRebookFromMyBooking ? 0.5 : -1,
              mx: 0.75,
              '& .MuiFilledInput-root': {
                border: 0,
              },
            }}
          />
        ) : (
          <AutoCompleteBox
            label={en.booking.from}
            options={filteredPortList || []}
            value={formatAirportValue(origin)}
            inputValue={originInputValue}
            noOptionsText={en.booking.searchFlight.noMatch}
            onChange={(newValue: any) => {
              if (isSearchResultPage) {
                setEditOrigin(newValue);
              } else {
                dispatch(setOrigin(newValue));
              }
              setOriginInputValue(formatAirportValue(newValue));
            }}
            onInputChange={(newInputValue: any) => {
              setKeyword(newInputValue);
              setOriginInputValue(newInputValue);
              filterODPortList(newInputValue, true);
            }}
            onFocus={(event: any) => {
              setKeyword(origin?.airportCode || '');
              setCurrentFieldIsOrigin(true);
              filterODPortList(formatAirportValue(origin, false), true);
              event.stopPropagation();
            }}
            onBlur={(event) => {
              setTimeout(() => {
                if (originDash.current !== event.target.value) {
                  setOriginInputValue('');
                }
              }, 0);
            }}
            dropDownListPop={dropDownListPop}
            renderOption={dropDownListPopOption}
            customStyles={{ mr: -1 }}
          ></AutoCompleteBox>
        )}
      </Box>

      {!isFromRebookFlow && (
        <ODExchange
          originRef={originRef}
          destinationRef={destinationRef}
          switchODData={() => {
            if (isSearchResultPage) {
              setEditOrigin(destination);
              setEditDestination(origin);
            } else {
              dispatch(setOrigin(destination));
              dispatch(setDestination(origin));
            }
            setOriginInputValue(formatAirportValue(destination));
            setDestinationInputValue(formatAirportValue(origin));
          }}
        />
      )}

      <Box
        id="destination-input"
        sx={{
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          minWidth: 0,
        }}
      >
        {isFromRebookFlow ? (
          <ReduxFormTextInput
            input={{
              value: formatAirportValue(destination),
            }}
            title={en.booking.to}
            isReadOnly={true}
            customStyles={{
              mt: isRebookFromMyBooking ? 0.5 : -1,
              mx: 0.75,
              '& .MuiFilledInput-root': {
                border: 0,
              },
            }}
          />
        ) : (
          <AutoCompleteBox
            label={en.booking.to}
            options={filteredPortList || []}
            value={formatAirportValue(destination)}
            inputValue={destinationInputValue}
            noOptionsText={en.booking.searchFlight.noMatch}
            onChange={(newValue: any) => {
              if (isSearchResultPage) {
                setEditDestination(newValue);
              } else {
                dispatch(setDestination(newValue));
              }
              setDestinationInputValue(formatAirportValue(newValue));
            }}
            onInputChange={(newInputValue: any) => {
              setKeyword(newInputValue);
              setDestinationInputValue(newInputValue);
              filterODPortList(newInputValue, false);
            }}
            onFocus={(event: any) => {
              setKeyword(destination?.airportCode || '');
              setCurrentFieldIsOrigin(false);
              filterODPortList(formatAirportValue(destination, false), false);
              event.stopPropagation();
            }}
            onBlur={(event) => {
              setKeyword('');
              setTimeout(() => {
                if (destinationDash.current !== event.target.value) {
                  setDestinationInputValue('');
                }
              }, 0);
            }}
            dropDownListPop={dropDownListPop}
            renderOption={dropDownListPopOption}
            customStyles={{ mr: 1, ml: -1 }}
          ></AutoCompleteBox>
        )}
      </Box>
    </>
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        bgcolor: '#FDFDFD',
        borderTopLeftRadius: '24px',
        borderTopRightRadius: '24px',
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          px: 2,
          pt: 2,
        }}
      >
        <Grid container direction="row" alignItems="center">
          <Grid container xs={10.5} direction="column">
            <ODInputField
              label={en.booking.from}
              inputRef={originRef}
              isOrigin
              value={formatAirportValue(currentOrigin)}
              shrink={{
                shrink: currentOrigin !== null || currentFieldIsOrigin === true,
              }}
              filterODPortList={(keyword, isOriginField) => {
                const filterResult = handleFilterList(
                  currentOrigin,
                  currentDestination,
                  airports,
                  keyword,
                  isOriginField,
                );
                setKeyword(keyword);
                setFilteredPortList(filterResult);
              }}
              currentFieldIsOrigin={currentFieldIsOrigin}
              setODCurrentFieldIsOrigin={(currentFieldIsOrigin) => {
                setCurrentFieldIsOrigin(currentFieldIsOrigin);
              }}
              setODPreviousFieldIsOrigin={(isPreviousFieldIsOrigin) => {
                setPreviousFieldIsOrigin(isPreviousFieldIsOrigin);
              }}
              currentOrigin={currentOrigin}
              currentDestination={currentDestination}
              setCurrentOrigin={setCurrentOrigin}
              setCurrentDestination={setCurrentDestination}
            />

            <ODInputField
              label={en.booking.to}
              inputRef={destinationRef}
              isOrigin={false}
              value={formatAirportValue(currentDestination)}
              shrink={{
                shrink: currentDestination !== null || currentFieldIsOrigin === false,
              }}
              filterODPortList={(keyword, isOriginField) => {
                const filterResult = handleFilterList(
                  currentOrigin,
                  currentDestination,
                  airports,
                  keyword,
                  isOriginField,
                );
                setKeyword(keyword);
                setFilteredPortList(filterResult);
              }}
              currentFieldIsOrigin={currentFieldIsOrigin}
              setODCurrentFieldIsOrigin={(currentFieldIsOrigin) => {
                setCurrentFieldIsOrigin(currentFieldIsOrigin);
              }}
              setODPreviousFieldIsOrigin={(isPreviousFieldIsOrigin) => {
                setPreviousFieldIsOrigin(isPreviousFieldIsOrigin);
              }}
              currentOrigin={currentOrigin}
              currentDestination={currentDestination}
              setCurrentOrigin={setCurrentOrigin}
              setCurrentDestination={setCurrentDestination}
            />
          </Grid>

          <Grid container xs={1.5} justifyContent="center" alignItems="center">
            <ODExchange
              originRef={originRef}
              destinationRef={destinationRef}
              saveTo={SAVE_DATA_TO.useState}
              currentOrigin={currentOrigin}
              currentDestination={currentDestination}
              setCurrentOrigin={setCurrentOrigin}
              setCurrentDestination={setCurrentDestination}
            />
          </Grid>
        </Grid>

        <MobileODFilterList
          keyword={keyword}
          originRef={originRef}
          destinationRef={destinationRef}
          filterODPortList={filteredPortList}
          previousFieldIsOrigin={previousFieldIsOrigin}
          withFooter={currentOrigin !== null && currentDestination !== null}
          setCurrentOrigin={setCurrentOrigin}
          setCurrentDestination={setCurrentDestination}
        />
      </Box>

      {currentOrigin !== null && currentDestination !== null && (
        <Footer
          primaryBtn={{
            isFullWidth: true,
            text: isFooterUpdate ? en.common.update : en.common.continue,
            route: isFooterUpdate ? BOOKING_PATHS.booking : BOOKING_PATHS.dateSelection,
            customOnClick: saveData,
          }}
        />
      )}
    </Box>
  );
};

LocationSelection.defaultProps = {
  isFromRebookFlow: false,
  isRebookFromMyBooking: false,
};

export default LocationSelection;
