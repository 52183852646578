import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';

import { OverlayBackground, BackIcon } from '../../../assets/images';

interface INavBarProps {
  handleBackClick?: () => void;
  title?: string;
  bottomComponent?: {
    component: React.ComponentType<any>;
    props: any;
  };
}
const PaymentHistoryNavBar = ({ handleBackClick, title, bottomComponent }: INavBarProps) => {
  const { component: BottomComponent, props: bottomComponentProps } = bottomComponent || {};

  return (
    <Box
      sx={{
        top: 0,
        zIndex: 2,
        position: 'sticky',
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${OverlayBackground})`,
          width: '100%',
          height: '72px',
          backgroundSize: '100%',
          display: 'flex',
          alignContent: 'start',
          color: 'white',
          backgroundPosition: '50% 20%',
          p: 2.125,
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {handleBackClick && (
              <IconButton
                onClick={() => {
                  handleBackClick?.();
                }}
                sx={{ mr: 2 }}
              >
                <BackIcon fill="white" />
              </IconButton>
            )}

            <Box
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                mr: 'auto',
              }}
            >
              {title && <Typography variant="title_2_bold">{title}</Typography>}
            </Box>
          </Box>

          {/* TODO will integrate */}
          {BottomComponent && <BottomComponent {...bottomComponentProps} />}
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentHistoryNavBar;
