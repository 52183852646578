import React from "react";
import { Grid, Typography, useTheme, Button } from "@mui/material";

import en from "../../../translations/en";

interface IDetailsProps {
  title?: string;
  titleVariant?: any;
  isDisabled?: boolean;
  handleEditClick?: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
  handleExpandClick?: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
  expandTitle?: string;
  titleColor?: string;
}

const DetailInfoTitle = ({
  title,
  titleVariant,
  isDisabled,
  handleEditClick,
  handleExpandClick,
  expandTitle,
  titleColor,
}: IDetailsProps) => {
  const theme = useTheme();

  return (
    <Grid container justifyContent="space-between">
      {title && (
        <Grid item>
          <Typography
            variant={titleVariant || "body_1_bold"}
            sx={{ mb: 2, color: titleColor || "text.secondary" }}
          >
            {title}
          </Typography>
        </Grid>
      )}
      {handleEditClick && (
        <Grid item>
          <Button
            sx={{
              p: 0.3,
              display: "flex",
              justifyContent: "flex-end",
            }}
            disabled={isDisabled}
          >
            <Typography
              sx={{
                color: isDisabled
                  ? theme.palette.gray.main
                  : theme.status.selected,
              }}
              variant="body_2_bold"
              onClick={handleEditClick}
            >
              {en.common.edit}
            </Typography>
          </Button>
        </Grid>
      )}

      {handleExpandClick && (
        <Grid item>
          <Button sx={{ p: 0.3 }}>
            <Typography
              sx={{
                color: theme.color.utility.link.option_3,
              }}
              variant="body_2_bold"
              onClick={handleExpandClick}
            >
              {expandTitle}
            </Typography>
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default DetailInfoTitle;
