import React from "react";
import { Box, SxProps, Theme, Typography, useTheme } from "@mui/material";

import { USER_ROLE } from "../../constants/constants";
import en from "../../translations/en";

import { ExclamationIcon } from "../../assets/images";

import { getShortDisplayName, getFullDisplayName } from "../../helpers";

import { useAppSelector } from "../../app/hooks";
import { selectUser } from "../../slice/userSlice";

const RoleIndicatorBar = ({
  customStyles,
}: {
  customStyles?: SxProps<Theme>;
}) => {
  const { profile, role } = useAppSelector(selectUser) || {};
  const { type: roleType, delegator = {} } = role || {};

  const { personalInfo } = profile || {};
  const { preferredFirstName, firstName, lastName, middleName } =
    personalInfo || {};
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        py: 1,
        display: "flex",
        alignItems: "center",
        ...customStyles,
      }}
    >
      <ExclamationIcon
        width="16"
        height="16"
        fill="#4C4C4C"
        style={{ flex: "none" }}
      />
      <Typography
        variant="body_1_medium"
        sx={{
          ml: 1.5,
          whiteSpace: "pre-line",
          color: theme.color.secondary.dark_grey.option_2,
        }}
      >
        {roleType === USER_ROLE.admin ? (
          <>
            {`${role.name || ""} `}
            {getShortDisplayName({
              preferredFirstName,
              firstName,
              lastName,
              middleName,
            })}
          </>
        ) : (
          <>
            <Typography
              variant="body_1_regular"
              sx={{ float: "left", mr: 0.5 }}
            >
              {en.userProfile.delegation.actingAs}
            </Typography>
            <Typography variant="body_1_medium" sx={{ float: "left" }}>
              {getFullDisplayName(delegator)}
            </Typography>
          </>
        )}
      </Typography>
    </Box>
  );
};

export default RoleIndicatorBar;
