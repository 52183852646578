import React, { useContext } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { Employee } from 'employee';

import en from '../../../translations/en';
import { UI_STYLES } from '../../../constants/constants';

import { getAdminPersonalDisplayName } from '../../../helpers';

import { AdminAccessEmployeeState } from '../../../context';

const AdminInformationBar = ({ employee }: { employee?: any }) => {
  const theme = useTheme();
  const { employeeDetail } = useContext(AdminAccessEmployeeState) || {};
  const { personalInfo, profile, passportDetail } = employee || employeeDetail || {};
  const { firstName, middleName, lastName } = personalInfo;
  const employeePassport = passportDetail.find((item: Employee.PassportInfo) => item.dependentId === null) || {};
  const fullName = getAdminPersonalDisplayName({
    firstName,
    middleName,
    lastName,
  });

  return (
    <Box
      sx={{
        width: UI_STYLES.adminMaintainWidth,
        height: '80px',
        bgcolor: theme.color.etp.option_3,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderRadius: 1,
      }}
    >
      <Box sx={{ pt: 2, pl: 2.5, width: '683px' }}>
        <Typography variant="body_2_regular" color={theme.color.secondary.dark_grey.option_3}>
          {en.admin.feature.maintainEmployeeDetails.fullNameLbl}
        </Typography>
        <Typography variant="title_1_bold" color={theme.palette.text.primary}>
          {employeePassport.passportName ?? fullName}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, pr: 6.375 }}>
        <Box sx={{ pr: 5 }}>
          <Typography variant="body_2_regular" color={theme.color.secondary.dark_grey.option_3}>
            {en.admin.feature.maintainEmployeeDetails.employeeIDLbl}
          </Typography>
          <Typography variant="title_1_bold" color={theme.palette.text.primary}>
            {personalInfo.employeeId}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body_2_regular" color={theme.color.secondary.dark_grey.option_3}>
            {en.admin.feature.maintainEmployeeDetails.companyLbl}
          </Typography>
          <Typography variant="title_1_bold" color={theme.palette.text.primary}>
            {profile.company}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default AdminInformationBar;
