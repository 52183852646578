import React, { useRef, useEffect } from 'react';
import { Box, Grid, useTheme } from '@mui/material';

import ScrollableView from './ScrollableView';

interface IComponentProps {
  component: React.ComponentType<any>;
  width?: number;
  props?: any;
}

const FlexibleLayout = ({ left, right }: { left: IComponentProps; right?: IComponentProps }) => {
  const theme = useTheme();
  const scrollViewRef = useRef<HTMLDivElement>(null);

  const LeftComponent = left.component;
  const { isFromRebookFlow, outwardFlight, isDesktop } = left.props || {};

  const RightComponent = right && right.component;

  useEffect(() => {
    if (scrollViewRef.current && isDesktop) {
      scrollViewRef.current.scroll({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [outwardFlight]);

  return (
    <Grid
      component={ScrollableView}
      container
      columnSpacing={5}
      sx={{
        bgcolor: theme.color.secondary.light_slate.option_7,
        overflowX: 'auto',
        pt: isFromRebookFlow ? 1 : 5,
      }}
      ref={scrollViewRef}
    >
      <Grid item xs={right ? 6 : 12}>
        <Box sx={{ float: 'right' }} width={left.width}>
          <LeftComponent {...left.props} />
        </Box>
      </Grid>
      {right && (
        <Grid item xs={6}>
          <Box component={ScrollableView} sx={{ float: 'left', pb: 2, px: 0.5 }} width={right.width}>
            {RightComponent && <RightComponent {...right.props} />}
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default FlexibleLayout;
