const AUTH_API = {
  init: '/etp-auth/init',
  getRole: '/etp-auth/getRole',
  changeRole: '/etp-auth/changeRole',
};

const BOOKING_API = {
  searchFlight: '/etp-api/v1/booking/searchFlight',
  fare: '/etp-api/v1/booking/fare',
  book: '/etp-api/v1/booking/book',
  upcomingBooking: '/etp-api/v1/booking/myUpcomingBooking',
  pnr: '/etp-api/v1/booking/pnr',
  docsDoca: '/etp-api/v1/booking/docsDoca',
  travellerName: '/etp-api/v1/booking/travellerName',
  cancel: '/etp-api/v1/booking/cancel',
};

const COMMON_API = {
  searchBooking: '/etp-api/v1/booking/common/search',
  myBooking: '/etp-api/v1/booking/common/myBooking',
};

const LT_API = {
  BOOKING_API: {
    searchFlight: '/etp-api/v1/booking/LT/searchFlight',
    nominatedPassengerList: '/etp-api/v1/booking/LT/nominatedPassengerList',
    concession: '/etp-api/v1/booking/LT/concession',
    fare: '/etp-api/v1/booking/LT/fare',
    book: '/etp-api/v1/booking/LT/book',
    pnr: '/etp-api/v1/booking/LT/pnr',
    cancel: '/etp-api/v1/booking/LT/cancel',
    removePassenger: '/etp-api/v1/booking/LT/removePassenger',
  },
};

const FLIGHTLOAD_API = {
  getFlightLoad: '/etp-api/v1/flightInfo/load',
};

const CONFIGURATION_API = {
  airports: '/etp-api/v1/configuration/airports',
  configuration: '/etp-api/v1/configuration',
};

const USER_API = {
  personalInfo: '/etp-api/v1/user/profile',
  passportInfo: '/etp-api/v1/user/passport',
  endorsingStaff: '/etp-api/v1/user/endorsingStaff',
  concession: '/etp-api/v1/user/concession',
  paymentHistory: '/etp-api/v1/booking/common/paymentHistory',
  dependent: '/etp-api/v1/booking/LT/dependentList',
  allotment: '/etp-api/v1/user/quota',
};

const ADMIN_API = {
  USER_API: {
    concession: '/etp-api/v1/admin/user/concession',
    concessionEmpNonEmp: '/etp-api/v1/admin/user/concessionEmpNonEmp',
  },
  EMPLOYEE_USER_API: {
    personalInfo: '/etp-api/v1/admin/bookingUser/profile',
    passportInfo: '/etp-api/v1/admin/bookingUser/passport',
    endorsingStaff: '/etp-api/v1/admin/bookingUser/endorsingStaff',
    employeeProfile: '/etp-api/v1/admin/maintain/employee/profile',
    suggestionList: '/etp-api/v1/admin/maintain/employee/suggestionList',
    notes: '/etp-api/v1/admin/maintain/employee/notes',
    noteUpsert: '/etp-api/v1/admin/maintain/employee/noteUpsert',
    concessions: '/etp-api/v1/admin/maintain/employee/concession',
  },
  BOOKING_API: {
    searchFlight: '/etp-api/v1/admin/booking/searchFlight',
    fare: '/etp-api/v1/admin/booking/fare',
    book: '/etp-api/v1/admin/booking/book',
    myBooking: '/etp-api/v1/admin/booking/myBooking',
    pnr: '/etp-api/v1/admin/booking/pnr',
    docsDoca: '/etp-api/v1/admin/booking/docsDoca',
    travellerName: '/etp-api/v1/admin/booking/travellerName',
    cancel: '/etp-api/v1/admin/booking/cancel',
    ernEmpTraveller: '/etp-api/v1/admin/booking/employee',
    searchBooking: '/etp-api/v1/admin/booking/search',
  },
  HISTORY_API: {
    report: '/etp-api/v1/admin/report/travelHistory',
  },
};

const DELEGATEE_API = {
  EMPLOYEE_USER_API: {
    personalInfo: '/etp-api/v1/delegatee/bookingUser/profile',
    passportInfo: '/etp-api/v1/delegatee/bookingUser/passport',
    endorsingStaff: '/etp-api/v1/delegatee/bookingUser/endorsingStaff',
    concession: '/etp-api/v1/delegatee/bookingUser/concession',
  },
  BOOKING_API: {
    searchFlight: '/etp-api/v1/delegatee/booking/searchFlight',
    fare: '/etp-api/v1/delegatee/booking/fare',
    book: '/etp-api/v1/delegatee/booking/book',
    myBooking: '/etp-api/v1/delegatee/booking/myBooking',
    upcomingBooking: '/etp-api/v1/delegatee/booking/myUpcomingBooking',
    pnr: '/etp-api/v1/delegatee/booking/pnr',
    docsDoca: '/etp-api/v1/delegatee/booking/docsDoca',
    travellerName: '/etp-api/v1/delegatee/booking/travellerName',
    cancel: '/etp-api/v1/delegatee/booking/cancel',
  },
};

const DELEGATOR_API = {
  delegatee: '/etp-api/v1/delegator/delegatee',
  delegateeList: '/etp-api/v1/delegator/delegateeList',
};

const PAYPAL_API = {
  billingAgreementToken: '/etp-api/v1/paypal/billingAgreementToken',
  billingAgreementId: '/etp-api/v1/paypal/billingAgreementId',
};

const FLOWN_SUSPENSION_API = {
  changeAcknowledged: '/etp-api/v1/user/acknowledge',
};

const PREPAID_API = {
  auth: '/payment',
  prepaidAuth: '/etp-api/v1/booking/LT/prepaidAuth',
  prepaidBook: '/etp-api/v1/booking/LT/prepaidBook',
  retrieveAndPayAuth: '/etp-api/v1/booking/LT/retrieveAndPayAuth',
};

const AXIOS_ERR_CODE_TIMEOUT = 'ECONNABORTED';

const HTTP_REQUEST_TYPE = {
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  delete: 'DELETE',
};

const API_ERROR_CODE = {
  olderClientVer: 'OLD_CLIENT_VER',
};

// cache
const API_CACHE = {
  dtFare: BOOKING_API.fare,
  dtDocsDoca: BOOKING_API.docsDoca,
  dtTravellerName: BOOKING_API.travellerName,
  adminDtFare: ADMIN_API.BOOKING_API.fare,
  adminDtDocsDoca: ADMIN_API.BOOKING_API.docsDoca,
  adminDtTravellerName: ADMIN_API.BOOKING_API.travellerName,
  delegateeDtFare: DELEGATEE_API.BOOKING_API.fare,
  delegateeDtDocsDoca: DELEGATEE_API.BOOKING_API.docsDoca,
  delegateeDtTravellerName: DELEGATEE_API.BOOKING_API.travellerName,
};

export {
  AXIOS_ERR_CODE_TIMEOUT,
  HTTP_REQUEST_TYPE,
  AUTH_API,
  BOOKING_API,
  CONFIGURATION_API,
  USER_API,
  ADMIN_API,
  DELEGATEE_API,
  DELEGATOR_API,
  API_ERROR_CODE,
  LT_API,
  PAYPAL_API,
  API_CACHE,
  FLIGHTLOAD_API,
  COMMON_API,
  FLOWN_SUSPENSION_API,
  PREPAID_API,
};
