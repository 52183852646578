import React from "react";
import { Box, Typography, useTheme } from "@mui/material";

import {
  DutyTravelBackgroundLessIcon,
  LeisureTravelBackgroundLessIcon,
} from "../../../assets/images";
import en from "../../../translations/en";

import { selectApp } from "../../../slice/appSlice";
import { useAppSelector } from "../../../app/hooks";

interface ITravelTypeWithIconProps {
  travelType: string;
  selected?: boolean;
}

const TravelTypeWithIcon = ({
  travelType,
  selected = false,
}: ITravelTypeWithIconProps) => {
  const theme = useTheme();
  const { isDesktop } = useAppSelector(selectApp) || {};

  const color =
    isDesktop || !selected
      ? theme.color.secondary.dark_grey.option_3
      : theme.palette.primary.light;

  return (
    <Box
      sx={{
        background: "transparent",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        height: "32px",
      }}
    >
      <Box sx={{ height: "18px", my: 0.0875, mx: 0.46125 }}>
        {travelType === en.booking.travelType.dutyTravel && (
          <DutyTravelBackgroundLessIcon fill={color} />
        )}
        {travelType === en.booking.travelType.leisureTravel.title && (
          <LeisureTravelBackgroundLessIcon fill={color} />
        )}
      </Box>
      <Typography
        variant={isDesktop ? "headline_medium" : "body_2_medium"}
        sx={{
          color,
        }}
      >
        {travelType}
      </Typography>
    </Box>
  );
};

export default TravelTypeWithIcon;
