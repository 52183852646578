import React, { FunctionComponent, SVGProps, useMemo } from 'react';
import { SxProps, Theme, Typography, SvgIcon, ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material';

import { RADIO_BUTTON_GROUP_VARIANT } from '../../components/Button/RadioButtonGroup';

interface IOptions {
  id?: string;
  val: any;
  title: string;
  expandedComponent?: JSX.Element;
  disabledSubtitle?: string;
  subtitle?: string;
  isDisabled?: boolean;
  imgSrc?: FunctionComponent<SVGProps<SVGSVGElement>>;
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  customSubtitleStyle?: SxProps<Theme>;
}

interface ITravelTypeToggleButtonGroupProps {
  value?: string[];
  options: IOptions[];
  onChange?: (value: any) => void;
  variant?: RADIO_BUTTON_GROUP_VARIANT;
  titleVariant?: any;
  customButtonStyle?: SxProps<Theme>;
}

const getVariantStyle = (variant: RADIO_BUTTON_GROUP_VARIANT, theme: Theme) => {
  switch (variant) {
    case RADIO_BUTTON_GROUP_VARIANT.REGULAR:
    case RADIO_BUTTON_GROUP_VARIANT.ICON: {
      const isIcon = variant === RADIO_BUTTON_GROUP_VARIANT.ICON;
      return {
        '&:not(:first-of-type), &:first-of-type': {
          borderRadius: 1,
          border: 0,
          borderTopColor: 'borderColor.main',
          boxShadow: theme.palette.boxShadow.dark,
          ...(isIcon && { mx: 1 }),
        },
        '&:not(:first-of-type):hover, &:first-of-type:hover': {
          background: 'transparent',
        },
        '&.Mui-selected, &.Mui-selected:hover': {
          color: theme.color.secondary.dark_grey.option_1,
          border: `2px solid ${theme.color.utility.link.option_3}`,
          background: theme.palette.bgColor.light,
          boxShadow: theme.boxShadow.selected,
        },
      };
    }
    case RADIO_BUTTON_GROUP_VARIANT.CHIP:
      return {
        '&:not(:first-of-type)': {
          borderRadius: 3,
          borderTopColor: 'borderColor.main',
        },
        '&:first-of-type': {
          borderRadius: 3,
          borderTopColor: 'borderColor.main',
        },
        '&.Mui-selected, &.Mui-selected:hover': {
          background: theme.palette.gray.dark,
          color: 'white',
        },
      };
  }
};

const TravelTypeToggleButtonGroup = ({
  value,
  options,
  variant = RADIO_BUTTON_GROUP_VARIANT.REGULAR,
  titleVariant,
  onChange,
  customButtonStyle,
}: ITravelTypeToggleButtonGroupProps) => {
  const theme = useTheme();
  const isIcon = variant === RADIO_BUTTON_GROUP_VARIANT.ICON;

  const handleUpdatedTravelType = (event: React.MouseEvent<HTMLElement>, updatedTravelTypes: string[]) => {
    onChange?.(updatedTravelTypes);
  };

  const toggleButtonGroupStyle = useMemo(() => {
    return {
      display: 'flex',
      flexWrap: 'wrap',
      '&.MuiToggleButtonGroup-root': {
        background: 'transparent',
        width: '100%',
        ...(isIcon && {
          display: 'grid',
          'grid-template-columns': 'repeat(2, 50%)',
        }),
      },
      '& .MuiToggleButtonGroup-grouped': {
        mx: 1,
        my: 2,
        color: theme.color.secondary.dark_grey.option_1,
        background: 'white',
        '&.Mui-disabled': {
          background: theme.color.utility.unselectable.option_7,
          color: theme.color.secondary.grey.option_2,
          boxShadow: 'none !important',
        },
        ...getVariantStyle(variant, theme),
      },
    };
  }, [variant, theme]);

  return (
    <ToggleButtonGroup
      sx={toggleButtonGroupStyle}
      value={value}
      orientation={variant === RADIO_BUTTON_GROUP_VARIANT.REGULAR ? 'vertical' : 'horizontal'}
      onChange={handleUpdatedTravelType}
    >
      {options.map((option) => {
        const { id, val, title, icon } = option || {};
        const isCurrentSelected = !!value?.includes(val);
        return (
          <ToggleButton
            key={id}
            sx={{
              my: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
              boxShadow: 'boxShadow.main',
              ...customButtonStyle,
            }}
            value={val}
          >
            {icon && (
              <SvgIcon
                component={icon}
                viewBox="0 0 18 18"
                sx={{
                  color: isCurrentSelected ? 'white' : '#505050',
                  width: '18px',
                  height: '18px',
                  mr: 0.5,
                }}
              />
            )}
            <Typography
              variant={titleVariant || 'body_1_regular'}
              color={isCurrentSelected && variant === RADIO_BUTTON_GROUP_VARIANT.CHIP ? 'white' : 'inherit'}
              component="div"
              align="left"
              sx={{ pt: 0.125, whiteSpace: 'pre-line' }}
            >
              {title}
            </Typography>
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};

TravelTypeToggleButtonGroup.defaultProps = {
  isChip: false,
  travelTypeButtonStyle: {},
};

export default TravelTypeToggleButtonGroup;
