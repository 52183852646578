/* eslint-disable react/jsx-props-no-spreading */
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import { styled } from '@mui/material/styles';

const TextInput = styled((props: TextFieldProps) => (
  <TextField
    InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>}
    onKeyPress={(e: any) => {
      if (e.key === 'Enter') {
        e.target.blur();
      }
    }}
    {...props}
  />
))(({ theme }) => ({
  margin: `${theme.spacing(1)} 0`,
  '& .MuiFilledInput-root': {
    width: '100%',
    overflow: 'hidden',
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.color.secondary.light_slate.option_6,
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: theme.color.secondary.light_slate.option_6,
      caretColor: theme.status.selected,
    },
    '&.Mui-focused': {
      backgroundColor: theme.color.secondary.light_slate.option_6,
    },
  },
}));

export default TextInput;
