import React, { useCallback, useEffect, useState } from 'react';
import { Box, useTheme, Typography } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { getFormValues, reset } from 'redux-form';
import { connect } from 'react-redux';
import { use100vh } from 'react-div-100vh';

import {
  UI_STYLES,
  FORM,
  NOMINATED_PASSENGER_AGE_TYPE,
  BOOKING_FLOW_PAGE_NO,
  PAYMENT_CONCEPT,
} from '../../../constants/constants';
import en from '../../../translations/en';

import { Configuration } from 'configuration';

import { constructFlightSectors, getDisplayVal, checkNominatedPassengers } from '../../../helpers';

import {
  IBookFlightLtParams,
  IBookingFlowOverlayData,
  ILtConcession,
  ILtEditContactDetails,
  ILtFare,
  INominatedPassenger,
  ITravellerDetailsItem,
} from '../../../interfaces';
import { OVERLAY_VARIANT } from '../../../containers/BookingFlow/Common/BookingFlowOverlay';
import { ROOT_PATHS } from '../../../constants/paths';

import { RootState } from '../../../app/store';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectBooking, setContactDetails, cleanUpLTFareDetail, setBookingStep } from '../../../slice/bookingSlice';
import { selectAuth } from '../../../slice/authSlice';
import { selectApp, setIsCreatingBooking } from '../../../slice/appSlice';
import { selectUser } from '../../../slice/userSlice';

import { bookFlightsLT } from '../../../services/booking';

import {
  EditTravellerDetails,
  EditPassportDetails,
  EditUsAddressDetails,
  EditLtContactDetails,
  ViewContactDetails,
  ViewLtPaymentDetails,
  ViewTicketCostDetails,
  ViewUsAddressDetails,
  ViewTravellerDetails,
  SegmentInfo,
  BaseConcessionInfo,
  BookingFlowOverlay,
} from '../../../containers';
import { Footer, Header, FormButton, ScrollableView, DesktopBreadcrumb } from '../../../components';
import { showErrorAlert } from '../../../services/api';
import { prepaidBook } from '../../../services/prepaid';
import { prepaidAuthAction } from '../../../helpers/prepaid';

const { travelDetails } = en.booking.flightConfirmation;
const { contactDetails: contactDetailsLbl } = travelDetails || {};

const LtConfirmation = ({ goToRouteStep, routeToStep, handleBackClick }: IBookingFlowOverlayData) => {
  /**
   * TODO:
   * 1. initialize docs, docsResidence, docaAddress
   * 2. Mandatory & Please edit warning (need to chat with David Li)
   * */

  const dispatch = useAppDispatch();
  const { isDesktop, isCreatingBooking } = useAppSelector(selectApp) || {};
  const screenHeight = use100vh();
  const theme = useTheme();
  const history = useHistory();

  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const tpAppRef = searchParams.get('tpAppRef');
  const errorMessage = searchParams.get('errorMessage');

  const [editForm, setEditForm] = useState('');
  const [isOpenOverlay, setIsOpenOverlay] = useState(false);
  const [isRestrictTravelDetails, setIsRestrictTravelDetails] = useState(false);
  const [isRestrictUsAddress, setIsRestrictUsAddress] = useState(false);
  const [isRestrictContactDetails, setIsRestrictContactDetails] = useState(false);
  const openOverlayOfType = (type: string) => {
    setEditForm(type);
    setIsOpenOverlay(true);
  };

  const { outwardFlight, contactDetails, docsDoca, leisureTravelDetails, usAddress } =
    useAppSelector(selectBooking) || {};
  const { ltSeatConcessionTravellerList, seatConcession, seatOption, ltConcessions, fareDetail } =
    leisureTravelDetails || {};

  const { profile } = useAppSelector(selectUser);
  const { paymentConcept, paymentMedium } = profile || {};

  const concessionRule = ltConcessions && ltConcessions?.find((item: ILtConcession) => item._id === seatConcession);

  const { ern } = useAppSelector(selectAuth) || {};
  const { emailList, phoneNumber } = (contactDetails as ILtEditContactDetails) || {};
  const {
    docaAddress: isUsAddressMandatory,
    docaResidence: isTravellerInfoMandatory,
    docs: isPassportInfoMandatory,
  } = docsDoca || {};

  const transformContact = () => {
    let updatedContactDetails: ILtEditContactDetails | null = null;

    if (leisureTravelDetails?.contactDetails) {
      // Prefill phoneNumber and email from get fare API
      const { mobilePhone, businessEmail, personalEmail } = leisureTravelDetails?.contactDetails || {};

      if (businessEmail) {
        // prefill the phoneNumber and businessEmail
        updatedContactDetails = {
          phoneNumber: mobilePhone,
          emailList: [
            {
              type: contactDetailsLbl.options[0],
              email: businessEmail,
            },
          ],
        };
      } else if (personalEmail) {
        // prefill the phoneNumber and personalEmail
        updatedContactDetails = {
          phoneNumber: mobilePhone,
          emailList: [
            {
              type: contactDetailsLbl.options[1],
              email: personalEmail,
            },
          ],
        };
      } else {
        //no business email/personal email
        updatedContactDetails = { phoneNumber: mobilePhone };
      }
    }

    return updatedContactDetails;
  };

  // set default contact detail data from Redux
  useEffect(() => {
    if (!contactDetails && leisureTravelDetails?.contactDetails) {
      const updatedContactDetails = transformContact();
      dispatch(setContactDetails(updatedContactDetails));
    }
  }, [contactDetails, leisureTravelDetails?.contactDetails]);

  const editComponents = {
    [FORM.travellerDetails]: EditTravellerDetails,
    [FORM.passportDetails]: EditPassportDetails,
    [FORM.contactDetails]: EditLtContactDetails,
    [FORM.usAddress]: EditUsAddressDetails,
  };

  const { selectedPassengers: selectedTraveller } = checkNominatedPassengers(ltSeatConcessionTravellerList) || {};

  const { adultPassengers: adultTravellers } = checkNominatedPassengers(selectedTraveller) || {};

  const travellerInfo = (forCreateBooking: boolean) =>
    selectedTraveller.map((item: INominatedPassenger) => {
      const { passportDetail } = item || {};
      const { dateOfBirth, passportExpirationDate, gender, passportCountry, passportNationality, passportName } =
        passportDetail || {};
      const isPassportDataExist =
        !!dateOfBirth &&
        !!passportExpirationDate &&
        !!gender &&
        !!passportCountry &&
        !!passportNationality &&
        !!passportName;
      const baseData: ITravellerDetailsItem = {
        dependentId: item.dependentId,
        beneficiaryTypeCode: item.beneficiaryTypeCode,
        firstName: item.firstName,
        lastName: item.lastName,
        ...(forCreateBooking && {
          title: item.passportDetail?.title,
          countryOfResidential: (item?.countryOfResidential as Configuration.DropdownOptionClient)?.code,
        }),
      };

      return {
        ...baseData,
        ...(isPassportDataExist && {
          passportInput: {
            dateOfBirth: item.passportDetail?.dateOfBirth,
            expiryDate: item.passportDetail?.passportExpirationDate,
            gender: (item.passportDetail?.gender as Configuration.DropdownOptionClient)?.code,
            issuingCountry: (item.passportDetail?.passportCountry as Configuration.DropdownOptionClient)?.code,
            nationality: (item.passportDetail?.passportNationality as Configuration.DropdownOptionClient)?.code,
            passportName: item.passportDetail?.passportName,
            passportNumber: item.passportDetail?.passportNumber,
          },
        }),
        ...(isUsAddressMandatory && forCreateBooking && { usAddress }),
        ...(item.infantWithSeat === false &&
          item.validationInfo.ageType === NOMINATED_PASSENGER_AGE_TYPE.infant && {
            attachedTo: {
              dependentId:
                adultTravellers.length >= 2 ? item.accompanyAdult?.dependentId || null : adultTravellers[0].dependentId,
              beneficiaryTypeCode:
                adultTravellers.length >= 2
                  ? item.accompanyAdult?.beneficiaryTypeCode || ''
                  : adultTravellers[0].beneficiaryTypeCode,
            },
          }),
        ...(forCreateBooking &&
          item.accompanyFormData && {
            travelWith: {
              firstName: item.accompanyFormData.firstName,
              lastName: item.accompanyFormData.lastName,
              title: item.accompanyFormData.salutation?.code,
              recLoc: item.accompanyFormData.bookingReference,
            },
          }),
      };
    });

  const constructBookFlightLTParams = (): IBookFlightLtParams => {
    const { departureTime, arrivalDate, arrivalTime } = outwardFlight || {};
    const flightSectors = constructFlightSectors(outwardFlight, {
      departureTime,
      arrivalDate,
      arrivalTime,
      bookingClass: seatOption || '',
    });

    const mainEmailData = emailList?.[0];
    const altEmailData = emailList?.[1];
    const baseParams: IBookFlightLtParams = {
      userId: ern,
      flightSectors,
      phoneNo: phoneNumber || '',
      concessionRuleId: concessionRule?._id || '',
      travellerInfo: travellerInfo(true),
      // [ETP-4316] - handle email & emailType
      email: mainEmailData?.email || '',
      emailType: mainEmailData?.type?.code?.toLowerCase(),
      // [ETP-4316] - handle altEmail & altEmailType
      ...(altEmailData?.email && {
        altEmail: altEmailData?.email,
        altEmailType: altEmailData?.type?.code?.toLowerCase(),
      }),
    };
    return baseParams;
  };

  const cleanUpCurrentStepData = () => {
    // including: `contactDetails`, `leisureTravelDetails.fareDetail`
    dispatch(cleanUpLTFareDetail());
    dispatch(setContactDetails(null));
  };

  const prepaidBookAction = useCallback(async () => {
    if (searchParams) {
      if (tpAppRef) {
        const response = await prepaidBook(ern, tpAppRef);

        // indicate end creating booking
        dispatch(setIsCreatingBooking(false));

        if (response) {
          // prepaid booking flow

          goToRouteStep?.({
            step: BOOKING_FLOW_PAGE_NO.completed,
            data: {
              bookingResult: response,
              recLoc: response.booking.recLoc,
            },
          });
        }
      } else {
        window.location.replace(ROOT_PATHS.landing);
      }
    }
  }, [isCreatingBooking, tpAppRef, errorMessage]);

  useEffect(() => {
    // useEffect to control the prepaid flowf logic
    if (tpAppRef && !errorMessage && !isCreatingBooking) {
      // isCreatingBooking flag is used to prevent api double call issue when resize screen

      // indicate start creating booking
      dispatch(setIsCreatingBooking(true));
      prepaidBookAction();
    }
  }, [tpAppRef, errorMessage]);

  useEffect(() => {
    if (errorMessage) {
      showErrorAlert({
        title: en.errorAlert.prepaidAuth,
        message: errorMessage || '',
      });
    }
  }, [errorMessage]);

  return (
    <Box
      sx={{
        background: isDesktop ? theme.color.secondary.light_slate.option_7 : 'transparent',
      }}
    >
      <Box
        component={ScrollableView}
        sx={{
          height: isDesktop
            ? `calc(${screenHeight}px - ${UI_STYLES.desktopHeaderHeight}  - ${UI_STYLES.desktopFooterHeight})`
            : `calc(${screenHeight}px - ${UI_STYLES.overlayHeaderHeightBookingFlow} - ${UI_STYLES.footerHeight} + ${UI_STYLES.mobileHeaderMarginTopBookingCompleted})`,
          mt: isDesktop ? 0 : `-${UI_STYLES.mobileHeaderMarginTopBookingCompleted}`,
          px: isDesktop ? 0 : 2,
        }}
      >
        <DesktopBreadcrumb
          step={routeToStep}
          customStyles={{ height: UI_STYLES.overlayHeaderHeightSearchCriteria }}
          customCrumbs={en.booking.ltBreadCrumbs}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
            background: isDesktop ? 'none' : theme.color.secondary.light_slate.option_7,
            width: isDesktop ? UI_STYLES.desktopHeaderNavWidth : '100%',
            mx: 'auto',
            borderRadius: isDesktop ? 0 : 1,
          }}
        >
          {isDesktop && (
            <Header
              leftChild={
                <Typography
                  color={theme.color.utility.link.option_3}
                  fontWeight="bold"
                  sx={{
                    pl: 1,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    handleBackClick?.();
                  }}
                >
                  {en.booking.flightClass.LTBackSeatOptions}
                </Typography>
              }
              customStyles={{
                mb: 2,
              }}
              handleOnBack={handleBackClick}
            />
          )}

          <Box
            sx={{
              mb: 1,
              borderRadius: 1,
              mx: isDesktop ? 1 : 0,
              backgroundColor: 'white',
            }}
          >
            {outwardFlight && <SegmentInfo flightInfo={outwardFlight} isShowSegmentStatus={false} />}

            <ViewTicketCostDetails
              currency={fareDetail ? Object.values(fareDetail as ILtFare)?.[0]?.currency : ''}
              fareDetails={(fareDetail as ILtFare) || {}}
            />
            <BaseConcessionInfo data={getDisplayVal(concessionRule?.displayName)} customStyles={{ p: 2 }} />

            <ViewTravellerDetails
              isTravellerInfoMandatory={isTravellerInfoMandatory}
              isPassportInfoMandatory={isPassportInfoMandatory}
              handleEditClick={openOverlayOfType}
              requestedTravellers={selectedTraveller || []}
              setIsRestrictBooking={(value: boolean) => {
                setIsRestrictTravelDetails(value);
              }}
            />

            {isUsAddressMandatory && (
              <ViewUsAddressDetails
                handleEditClick={openOverlayOfType}
                setIsRestrictBooking={(value: boolean) => {
                  setIsRestrictUsAddress(value);
                }}
                data={usAddress}
              />
            )}

            <ViewContactDetails
              handleEditClick={openOverlayOfType}
              setIsRestrictBooking={(value: boolean) => {
                setIsRestrictContactDetails(value);
              }}
              data={contactDetails as ILtEditContactDetails}
            />

            <ViewLtPaymentDetails paymentConcept={paymentConcept || ''} paymentMedium={paymentMedium || ''} />
          </Box>
        </Box>

        {editComponents[editForm] && (
          <BookingFlowOverlay
            component={editComponents[editForm]}
            title={en.booking.flightConfirmation.reviewBooking}
            open={isOpenOverlay}
            variant={OVERLAY_VARIANT.SEARCH_CRITERIA}
            isDrawer={true}
            handleBackClick={() => {
              setIsOpenOverlay(false);
              setTimeout(() => {
                dispatch(reset(editForm));
                setEditForm('');
              }, 500);
            }}
            data={{
              handleClose: () => {
                setIsOpenOverlay(false);
                setTimeout(() => {
                  dispatch(reset(editForm));
                  setEditForm('');
                }, 500);
              },
            }}
          />
        )}
      </Box>

      <Footer
        leftChild={
          <FormButton
            theme={theme}
            colour="transparent"
            size="large"
            sx={{
              height: '44px',
              display: 'flex',
              justifyContent: 'center',
              ...{ minWidth: 0 },
            }}
            onClick={async () => {
              await cleanUpCurrentStepData();
              dispatch(setBookingStep(null));
              await history.go(0);
            }}
          >
            {en.common.cancel}
          </FormButton>
        }
        primaryBtn={
          isRestrictTravelDetails || isRestrictUsAddress || isRestrictContactDetails
            ? undefined
            : {
                text:
                  paymentConcept === PAYMENT_CONCEPT.PREPAID // ETP-2431
                    ? en.common.confirmAndPay // ETP-2431
                    : en.common.createBooking, // ETP-2431
                customOnClick: async () => {
                  const bookFlightLTParams = constructBookFlightLTParams();
                  // ETP-2431
                  if (paymentConcept === PAYMENT_CONCEPT.PREPAID) {
                    // showErrorAlert({
                    //   title:
                    //     en.booking.flightConfirmation.travelDetails
                    //       .paymentDetails.paymentFailHeader,
                    //   message:
                    //     en.booking.flightConfirmation.travelDetails
                    //       .paymentDetails.PaymentFailBody,
                    //   noErrorAlert: true,
                    // });
                    dispatch(setBookingStep(BOOKING_FLOW_PAGE_NO?.confirmation));
                    await prepaidAuthAction(bookFlightLTParams);
                    return;
                  }
                  const result = await bookFlightsLT(bookFlightLTParams);
                  result &&
                    goToRouteStep?.({
                      step: BOOKING_FLOW_PAGE_NO.completed,
                      data: {
                        bookingResult: result,
                        recLoc: result.booking.recLoc,
                      },
                    });
                },
                customButtonStyles: {
                  marginRight: 0,
                },
              }
        }
        customStyles={
          isDesktop
            ? {
                height: UI_STYLES.desktopFooterHeight,
                px: 22,
                boxShadow: theme.palette.boxShadow.dark,
                borderTopLeftRadius: '24px',
                borderTopRightRadius: '24px',
              }
            : {
                px: 2,
              }
        }
      />
    </Box>
  );
};

export default connect((state: RootState) => ({
  travelDetailsFormValues: getFormValues(FORM.travelDetails)(state),
}))(LtConfirmation);
