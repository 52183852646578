import React, { createContext, useState } from 'react';
import { useAppSelector } from '../app/hooks';
import { selectBookingFilter } from '../slice/bookingFilterSlice';
import { IPeriod, IBookingFilterStateType } from '../interfaces';

interface IActionType {
  setEditTravelTypes: any;
  setEditPeriod: any;
  resetBookingFilter: any;
}

const EditBookingFilterState = createContext<IBookingFilterStateType>({
  editTravelTypes: [],
  editPeriod: {},
});

const EditBookingFilterAction = createContext<IActionType>({
  setEditTravelTypes: null,
  setEditPeriod: null,
  resetBookingFilter: null,
});

const EditBookingFilterContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { travelTypes, period } = useAppSelector(selectBookingFilter) || {};

  const [editTravelTypes, setEditTravelTypes] = useState<string[]>(travelTypes || []);
  const [editPeriod, setEditPeriod] = useState<IPeriod>(period || {});
  const resetBookingFilter = () => {
    setEditTravelTypes([]);
    setEditPeriod({});
  };

  return (
    <EditBookingFilterState.Provider
      value={{
        editTravelTypes,
        editPeriod,
      }}
    >
      <EditBookingFilterAction.Provider
        value={{
          setEditTravelTypes,
          setEditPeriod,
          resetBookingFilter,
        }}
      >
        {children}
      </EditBookingFilterAction.Provider>
    </EditBookingFilterState.Provider>
  );
};

export { EditBookingFilterContextProvider, EditBookingFilterState, EditBookingFilterAction };
