import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, useTheme } from '@mui/material';

import { MULTIPLE_WARNING_TYPE } from '../../../constants/constants';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { InfoIcon } from '../../../assets/images';
import en from '../../../translations/en';

import { IReplaceContent, IWarningBody } from '../../../interfaces';

import { useAppSelector } from '../../../app/hooks';
import { selectApp } from '../../../slice/appSlice';

type DataProps = {
  headingCode?: string | undefined;
  warningMessage?: string[] | string | undefined | React.ReactNode;
};

const WARNING_SUMMARY_HEIGHT = '36px';

const BodyView = (matchData: string[] | undefined, replaceContent: IReplaceContent[] | undefined) => {
  const theme = useTheme();
  return matchData?.map((item: string, key: number) => {
    let isBulletPointHide = false;

    const matchObjs = replaceContent?.filter((replaceContentItem: IReplaceContent) => {
      // Check if the content need to hide the bullet point
      if (item.includes(en.multipleWarning.noBp)) {
        isBulletPointHide = true;

        // Remove the bullet point string from the item
        item = item.replace(en.multipleWarning.noBp, '');
      }

      // Check if the replaceContentItem's matchText is included in the item
      return item.includes(replaceContentItem.matchText);
    });

    const splitStringWithPlaceholders = (str: string): string[] => {
      // Regular expression to match the placeholders
      const regex = /{[^}]+}/g;

      // Array to store the parts of the string
      const parts: string[] = [];
      let lastIndex = 0;

      // Match all placeholders
      let match;
      while ((match = regex.exec(str)) !== null) {
        // Add the text before the placeholder
        if (match.index > lastIndex) {
          parts.push(str.slice(lastIndex, match.index));
        }

        // Add the placeholder
        parts.push(match[0]);

        // Update the last index to the end of the current match
        lastIndex = match.index + match[0].length;
      }

      // Add any remaining text after the last placeholder
      if (lastIndex < str.length) {
        parts.push(str.slice(lastIndex));
      }

      return parts;
    };

    const splitedWarning = splitStringWithPlaceholders(item);

    const InsertContentPattern = () => {
      return splitedWarning?.map((parts: string, index: number) => {
        const specialContent = matchObjs?.find((matchObj: IReplaceContent) => matchObj.matchText === parts);
        if (specialContent) {
          return (
            <Typography
              key={index}
              variant="body_2_regular"
              sx={{
                display: 'inline',
                cursor: 'pointer',
                color: theme.color.utility.link.option_3,
              }}
              onClick={() => {
                if (specialContent.type === en.multipleWarning.link) {
                  window.open(specialContent.link);
                } else if (specialContent.type === en.multipleWarning.email) {
                  window.location.href = en.multipleWarning.mailto + specialContent.link;
                }
              }}
            >
              {specialContent.displayText}
            </Typography>
          );
        }

        return <span key={index}>{parts}</span>;
      });
    };

    return (
      <Box key={key} sx={{ ml: 1 }}>
        <Box
          component="li"
          sx={{
            position: 'relative',
            listStyle: 'none',
            pl: 0,
            '&::before': !isBulletPointHide
              ? {
                  content: '""',
                  display: 'inline-block',
                  verticalAlign: 'middle',
                  mr: 1,
                  left: '4px',
                  width: '4px',
                  height: '4px',
                  background: theme.color.secondary.dark_grey.option_3,
                  borderRadius: '50%',
                }
              : undefined,
          }}
        >
          <Typography
            variant="body_2_regular"
            sx={{
              color: theme.color.secondary.dark_grey.option_3,
              display: 'inline',
              lineHeight: 2,
              whiteSpace: 'pre-line',
            }}
          >
            {InsertContentPattern()}
          </Typography>
        </Box>
      </Box>
    );
  });
};

const MultipleWarning = ({
  data,
  type, // refer to `constant.ts` => MULTIPLE_WARNING_TYPE
  messageFormatter, // type please reference to `OALMessage.json`
  isExpand,
  onChange,
}: {
  data: DataProps[];
  type?: string;
  messageFormatter: any;
  isExpand: boolean;
  onChange: () => void;
}) => {
  const theme = useTheme();
  const { isDesktop } = useAppSelector(selectApp) || {};

  let replaceContent: IReplaceContent[] | undefined;
  let matchData: IWarningBody | undefined;
  let warningLength = 0;
  let warningSummary;
  let warningTitle;
  let warningMessage: any;

  // css styling
  const warningTitleVerticalMarginTop: string = isDesktop? '0px' : '8px';
  const warningTitleVerticalPadding = 0.75;
  const wholeWarningBoxVerticalPadding= 0.75;
  let wholeWarningBoxBorder: string;
  let wholeWarningBoxBorderRadius: string;
  
  data.forEach((item: DataProps) => {
    if (item?.headingCode) {
      ++warningLength;
      matchData = messageFormatter?.[item.headingCode];
      return;
    }
    if (item.warningMessage) {
      ++warningLength;
      if (Array.isArray(item.warningMessage)) {
        warningMessage = item.warningMessage.join('');
      } else {
        warningMessage = item.warningMessage;
      }
    }
  });

  switch (type) {
    case MULTIPLE_WARNING_TYPE.DTSecurityReminder:
      warningSummary = en.multipleWarning.DT.securityReminder;
      wholeWarningBoxBorder = `2px solid ${theme.color.secondary.sand.option_3}`;
      wholeWarningBoxBorderRadius = '2px !important';
      break;
    case MULTIPLE_WARNING_TYPE.otherAirline:
      warningTitle = `${matchData?.title} ${en.multipleWarning.OAL.listingInformation}`;
      warningSummary = `${en.multipleWarning.youHave} ${warningLength} ${en.multipleWarning.messageLength}`;
      wholeWarningBoxBorder = `1px solid ${theme.color.secondary.sand.option_3}`;
      wholeWarningBoxBorderRadius = '4px !important';
      break;
    default: // generic multiple warning summary
      warningSummary = `${en.multipleWarning.youHave} ${warningLength} ${en.multipleWarning.messageLength}`;
      wholeWarningBoxBorder = `1px solid ${theme.color.secondary.sand.option_3}`;
      wholeWarningBoxBorderRadius = '4px !important';
      break;
  }

  if (matchData) {
    replaceContent = matchData?.replaceContent;
  }

  return (
    (replaceContent || warningMessage) && (
      <Accordion
        className="multiple_warning_container"
        onChange={onChange}
        defaultExpanded={isExpand}
        elevation={0}
        sx={{
          borderRadius: wholeWarningBoxBorderRadius,
          minHeight: WARNING_SUMMARY_HEIGHT,
          py: wholeWarningBoxVerticalPadding,
          background: theme.color.secondary.light_sand.option_8,
          border: wholeWarningBoxBorder,
          '&:before': {
            display: 'none',
          },
          '.MuiAccordionDetails-root': {
            py: 0,
            px: 4.125,
          },
          '.MuiAccordionSummary-root': {
            minHeight: WARNING_SUMMARY_HEIGHT,
          },
          '.MuiPaper-root': {
            px: '0px',
          },
          '&.MuiButtonBase-root ': {
            minHeight: WARNING_SUMMARY_HEIGHT,
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            minHeight: WARNING_SUMMARY_HEIGHT,
            background: theme.color.secondary.light_sand.option_8,
            '.Mui-expanded': {
              my: 0,
            },

            '&.MuiButtonBase-root': {
              minHeight: WARNING_SUMMARY_HEIGHT,
              lineHeight: WARNING_SUMMARY_HEIGHT,
              '.MuiAccordionSummary-content': {
                my: 0,
                minHeight: WARNING_SUMMARY_HEIGHT,
              },
            },
            '.MuiAccordionSummary-root': {
              minHeight: WARNING_SUMMARY_HEIGHT,
            },
          }}
        >
          <Typography sx={{ py: warningTitleVerticalPadding }}>
            <InfoIcon style={{ marginTop: warningTitleVerticalMarginTop }} width="20px" height="20px" color={theme.color.secondary.dark_grey.option_3} />
          </Typography>
          <Typography
            variant="body_2_medium"
            sx={{
              ml: 0.8125,
              py: warningTitleVerticalPadding,
              marginTop: warningTitleVerticalMarginTop,
              lineHeight: '18px',
              color: theme.color.secondary.dark_grey.option_3,
            }}
          >
            {warningSummary}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            ...(isDesktop
              ? {
                  mt: 1,
                }
              : {
                  mb: 1,
                }),
          }}
        >
          {warningMessage && (
            <Typography
              variant="navigation_medium"
              sx={{
                mt: 0,
                color: theme.color.secondary.dark_grey.option_3,
                pb: matchData ? 1.5 : 0.5,
                ...(matchData && {
                  borderBottom: `1px solid ${theme.color.secondary.light_slate.option_3}`,
                }),
              }}
            >
              {warningMessage}
            </Typography>
          )}

          {warningTitle && (
            <Typography
              variant="body_2_medium"
              sx={{
                mt: warningMessage || isDesktop ? 1.5 : 0.5,
                color: theme.color.secondary.dark_grey.option_3,
                mb: 1.5,
                ml: 1,
              }}
            >
              {warningTitle}
            </Typography>
          )}
          {BodyView(matchData?.content, replaceContent)}
        </AccordionDetails>
      </Accordion>
    )
  );
};

export default MultipleWarning;
