import _ from 'lodash';
import { ADMIN_CONCESSION, FARE_TYPES, OWOAL_TYPE } from '../constants/constants';
import { IAdminConcession, IAdminQuotaList } from '../interfaces';

const adminCXCarrierGroupSort = (adminConcessionData: IAdminConcession[], filterName: any) => {
  // cx group (include foc, zone, id50)
  const CXCarrierGroupList =
    adminConcessionData?.filter((item: IAdminConcession) => item?.concessionDef?.carrierGroup === 'CX') || [];
  // ow group
  const OWCarrierGroupList =
    adminConcessionData?.filter((item: IAdminConcession) => item?.concessionDef?.carrierGroup === OWOAL_TYPE.OW) || [];
  // oal group
  const OALCarrierGroupList =
    adminConcessionData?.filter((item: IAdminConcession) => item?.concessionDef?.carrierGroup === OWOAL_TYPE.OAL) || [];

  const focGroupList: IAdminConcession[] = [];
  const zoneGroupList: IAdminConcession[] = [];
  const id50GroupList: IAdminConcession[] = [];

  // cx carrier spilt into foc, zone, id50 groups
  CXCarrierGroupList?.map((cxConcessions: IAdminConcession) => {
    if (cxConcessions?.numberOfAllotmentSectors === -1) {
      // if numberOfAllotmentSectors = -1,
      // check eligibilityDependent if contains filter user's beneficiaryTypeCode
      cxConcessions?.eligibilityDependent?.map((eligibility: any) => {
        if (eligibility?.beneficiaryType === filterName?.beneficiaryTypeCode) {
          if (
            cxConcessions?.concessionDef?.fareType === FARE_TYPES.ID &&
            cxConcessions?.concessionDef?.fareDiscount === 100
          ) {
            focGroupList.push(cxConcessions);
          } else if (
            cxConcessions?.concessionDef?.fareType === FARE_TYPES.ZED &&
            cxConcessions?.concessionDef?.fareDiscount === 0
          ) {
            zoneGroupList.push(cxConcessions);
          } else if (
            cxConcessions?.concessionDef?.fareType === FARE_TYPES.ID &&
            cxConcessions?.concessionDef?.fareDiscount === 50
          ) {
            id50GroupList.push(cxConcessions);
          }
        }
      });
    } else {
      // if numberOfAllotmentSectors != -1 which means quotaList is not empty,
      // check all quotaList if equals filter user's dependentId
      cxConcessions?.quotaList?.map((quota: IAdminQuotaList) => {
        if (quota?.dependentId === filterName?.dependentId) {
          if (
            cxConcessions?.concessionDef?.fareType === FARE_TYPES.ID &&
            cxConcessions?.concessionDef?.fareDiscount === 100
          ) {
            const item = {
              ...cxConcessions,
              focusQuota: quota,
            };
            focGroupList.push(item);
          } else if (
            cxConcessions?.concessionDef?.fareType === FARE_TYPES.ZED &&
            cxConcessions?.concessionDef?.fareDiscount === 0
          ) {
            const item = {
              ...cxConcessions,
              focusQuota: quota,
            };
            zoneGroupList.push(item);
          } else if (
            cxConcessions?.concessionDef?.fareType === FARE_TYPES.ID &&
            cxConcessions?.concessionDef?.fareDiscount === 50
          ) {
            const item = {
              ...cxConcessions,
              focusQuota: quota,
            };
            id50GroupList.push(item);
          }
        }
      });
    }
  });

  // step 1: all groupList orderBy displayName
  const sortedFoc = _.orderBy(focGroupList, [ADMIN_CONCESSION.DISPLAY_NAME]);
  const sortedZone = _.orderBy(zoneGroupList, [ADMIN_CONCESSION.DISPLAY_NAME]);
  const sortedID50 = _.orderBy(id50GroupList, [ADMIN_CONCESSION.DISPLAY_NAME]);
  const sortedOW = _.orderBy(OWCarrierGroupList, [ADMIN_CONCESSION.DISPLAY_NAME]);
  const sortedOAL = _.orderBy(OALCarrierGroupList, [ADMIN_CONCESSION.DISPLAY_NAME]);

  // step 2: re-group all data
  // oc, zone, id50 groups need to groupBy displayName
  const groupFoc = _.groupBy(sortedFoc, (item: IAdminConcession) => item?.concessionDef?.displayName);
  const groupZone = _.groupBy(sortedZone, (item: IAdminConcession) => item?.concessionDef?.displayName);
  const groupID50 = _.groupBy(sortedID50, (item: IAdminConcession) => item?.concessionDef?.displayName);
  const groupOW = _.groupBy(sortedOW, (item: IAdminConcession) => item?.concessionDef?.displayName);
  const groupOAL = _.groupBy(sortedOAL, (item: IAdminConcession) => item?.concessionDef?.displayName);

  // step 3: map data
  const foc: any = [];
  const zone: any = [];
  const id50: any = [];
  const ow: any = [];
  const oal: any = [];
  _.values(groupFoc)?.map((item: IAdminConcession[]) => {
    // use first index's displayName, coz they have same name
    foc.push({ title: item?.[0]?.concessionDef?.displayName, data: item });
  });
  _.values(groupZone)?.map((item: IAdminConcession[]) => {
    zone.push({ title: item?.[0]?.concessionDef?.displayName, data: item });
  });
  _.values(groupID50)?.map((item: IAdminConcession[]) => {
    id50.push({ title: item?.[0]?.concessionDef?.displayName, data: item });
  });
  _.values(groupOW)?.map((item: IAdminConcession[]) => {
    ow.push({ title: item?.[0]?.concessionDef?.displayName, data: item });
  });
  _.values(groupOAL)?.map((item: IAdminConcession[]) => {
    oal.push({ title: item?.[0]?.concessionDef?.displayName, data: item });
  });

  return {
    sortedList: [...foc, ...zone, ...id50, ...ow, ...oal],
  };
};

export { adminCXCarrierGroupSort };
