import { Divider, SxProps, Theme, useTheme } from '@mui/material';

const HorizontalDivider = ({ customStyles }: { customStyles?: SxProps<Theme> }) => {
  const theme = useTheme();
  return (
    <Divider
      sx={{
        mx: 1,
        mt: 2,
        color: theme.color.secondary.slate.option_3,
        height: '0.5px',
        mb: 2,
        ...customStyles,
      }}
    />
  );
};

export default HorizontalDivider;
