import React from 'react';
import { Typography, useTheme } from '@mui/material';

import { TakeOffIcon } from '../../../assets/images';

import { getDisplayVal } from '../../../helpers';

const FlightODLabel = ({
  departurePort,
  arrivalPort,
  ...props
}: {
  departurePort?: string | null;
  arrivalPort?: string | null;
  [key: string]: any;
}) => {
  const theme = useTheme();
  return (
    <Typography
      variant="title_2_bold"
      display="flex"
      flex="1"
      sx={{ color: theme.color.secondary.dark_grey.option_3 }}
      {...props}
    >
      {getDisplayVal(departurePort)}
      <TakeOffIcon fill={theme.color.primary.medium_jade.option_3} style={{ marginLeft: 8, marginRight: 8 }} />
      {getDisplayVal(arrivalPort)}
    </Typography>
  );
};

export default FlightODLabel;
