import React, { useEffect, useState } from 'react';
import { reset, initialize } from 'redux-form';
import { useLocation } from 'react-router-dom';
import { Box, Typography, useTheme } from '@mui/material';
import { use100vh } from 'react-div-100vh';

import { UI_STYLES, USER_ROLE, FORM } from '../../../../constants/constants';
import { ROOT_PATHS } from '../../../../constants/paths';

import { useAppSelector, useAppDispatch } from '../../../../app/hooks';
import { selectApp } from '../../../../slice/appSlice';
import { selectBooking, clearBookingForm } from '../../../../slice/bookingSlice';
import { selectUser } from '../../../../slice/userSlice';

import { FlexibleLayout, Header } from '../../../../components';
import { ResultRightLayout, SearchBar, FlightSearchResultContainer } from '../../..';
import en from '../../../../translations/en';

const SearchResult = ({
  isFromRebookFlow,
  isRebookFromMyBooking,
  handleFooterClick,
  handleBackClick,
  handleContinueClick,
}: {
  isFromRebookFlow?: boolean;
  isRebookFromMyBooking?: boolean;
  handleFooterClick: () => void;
  // TBC: etp-2915 -> need call api when click book one-way
  handleBackClick?: () => void;
  handleContinueClick?: () => void;
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const tpAppRefFromUrl = searchParams.get('tpAppRef');

  const { isDesktop } = useAppSelector(selectApp) || {};
  const { origin, destination, startDate, travelType, tpAppRef, outwardFlight } = useAppSelector(selectBooking) || {};

  const { role } = useAppSelector(selectUser) || {};
  const { type: roleType } = role || {};

  const isAdminDelegation = [USER_ROLE.admin, USER_ROLE.delegation].includes(roleType);

  const screenHeight = use100vh();

  const [curShowPop, setCurShowPop] = useState<string>('');

  const locationState = useLocation<{
    // isKeepSearchCriteria?: boolean;
    performShowPopup?: boolean;
  }>();

  // const isKeepSearchCriteria =
  //   locationState.state?.isKeepSearchCriteria || false;
  const performShowPopup = locationState.state?.performShowPopup || false;

  useEffect(() => {
    if ((!origin || !destination || !startDate || !travelType) && !tpAppRef && !tpAppRefFromUrl) {
      // prevent input url to link page
      location.replace(ROOT_PATHS.landing);
    } else {
      dispatch(clearBookingForm());

      dispatch(reset(FORM.travelDetails));
      dispatch(initialize(FORM.travelDetails, {}));
    }
  }, []);

  return isDesktop ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        height: `calc(${screenHeight}px - ${UI_STYLES.navBarHeight} - 
          ${isFromRebookFlow && !isRebookFromMyBooking ? '52px' : '0px'} -
          ${isAdminDelegation ? UI_STYLES.indicatorBarHeight : '0px'})`,
        background: theme.color.secondary.light_slate.option_7,
      }}
      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        setCurShowPop('');
      }}
    >

      <SearchBar
        curShowPop={curShowPop}
        setCurShowPop={setCurShowPop}
        performShowPopup={performShowPopup}
        // empOrNonEmpType={isKeepSearchCriteria ? TRAVELLER_TYPE.both : ""}
        isResultPage={true}
        isFromRebookFlow={isFromRebookFlow}
        isRebookFromMyBooking={isRebookFromMyBooking}
        barStyle={{
          py: 2,
          px: 22,
          width: '100%',
          height: UI_STYLES.searchBarResultPageHeight,
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          boxShadow: theme.boxShadow.important,
          backgroundColor: theme.palette.primary.light,
          '& > .MuiBox-root': {
            mr: 1,
            borderRadius: 0.5,
            height: '100%',
          },
        }}
      />

      {isFromRebookFlow && (
        <Box
          sx={{
            // 418px -> left component width, 24px left icon width & padding
            pl: 'calc(50% - 418px - 24px)',
            height: '44px',
            mt: 2,
          }}
        >
          <Header
            leftChild={
              <Typography
                color={theme.color.utility.link.option_3}
                variant="body_1_bold"
                sx={{
                  cursor: 'pointer',
                  ml: 1,
                }}
                onClick={handleBackClick}
              >
                {en.booking.confirmation.cancelActions.rebook.backToFlightDetail}
              </Typography>
            }
            handleOnBack={handleBackClick}
          />
        </Box>
      )}

      <FlexibleLayout
        left={{
          component: FlightSearchResultContainer,
          width: 418,
          props: {
            isFromRebookFlow,
            outwardFlight,
            isDesktop,
          },
        }}
        right={{
          component: ResultRightLayout,
          width: 418,
          props: {
            isFromRebookFlow,
            handleFooterClick: isFromRebookFlow ? handleContinueClick : handleFooterClick,
          },
        }}
      />
    </Box>
  ) : (
    <FlightSearchResultContainer
      handleFooterClick={isFromRebookFlow ? handleContinueClick : handleFooterClick}
      isFromRebookFlow={isFromRebookFlow}
    />
  );
};

SearchResult.defaultProps = {
  isFromRebookFlow: false,
  handleBackClick: undefined,
  handleContinueClick: undefined,
  isRebookFromMyBooking: false,
};

export default SearchResult;
