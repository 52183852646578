// import { IPublicClientApplication } from "@azure/msal-browser";
import { reset as resetAppSlice } from '../slice/appSlice';
import { reset as resetAuthSlice } from '../slice/authSlice';
import { reset as resetBookingSlice } from '../slice/bookingSlice';
import { reset as resetConfigurationSlice } from '../slice/configurationSlice';
import { reset as resetBookingUserSlice } from '../slice/bookingUserSlice';
import { reset as resetUserSlice } from '../slice/userSlice';
import { logoutRedirect } from '../config/authConfig';
import { ETP_CLIENT_ID_KEY } from '../constants/constants';

export function clearSliceData(dispatch: any) {
  dispatch(resetAppSlice());
  dispatch(resetAuthSlice());
  dispatch(resetBookingSlice());
  dispatch(resetConfigurationSlice());
  dispatch(resetBookingUserSlice());
  dispatch(resetUserSlice());
}

// export function logout(dispatch: any, instance: IPublicClientApplication) {
export function logout(dispatch: any) {
  clearSliceData(dispatch);
  sessionStorage.removeItem(ETP_CLIENT_ID_KEY);
  // instance.logoutRedirect({ postLogoutRedirectUri: "/" }); // use "/logout" url if need logout page
  logoutRedirect();
}

export function groupBy<T, K extends keyof any>(list: T[], getKey: (item: T) => K) {
  return list.reduce(
    (previous, currentItem) => {
      const group = getKey(currentItem);
      if (!previous[group]) previous[group] = [];
      previous[group].push(currentItem);
      return previous;
    },
    {} as Record<K, T[]>,
  );
}
