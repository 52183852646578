import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const ScrollableView = styled(Box)(() => ({
  overflowY: "auto",
  height: "inherit",
  "&::-webkit-scrollbar": {
    // safari and chrome
    display: "none",
  },
  "-ms-overflow-style": "none", // IE
  "scrollbar-width": "none", // firefox
}));

export default ScrollableView;
