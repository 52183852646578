import { useContext, useMemo, useState } from 'react';
import { Box, Button, Collapse, IconButton, Typography, useTheme } from '@mui/material';
import { shallowEqual } from 'react-redux';
import _ from 'lodash';

import { Frontend, Pnr } from 'booking';
import { Configuration } from 'configuration';
import {
  BOOKING_STATUS,
  BOOKING_UNUC_TYPE,
  DATE_FORMAT,
  LABEL_CATEGORY,
  MULTIPLE_WARNING_TYPE,
  NOMINATED_PASSENGER_AGE_TYPE,
  PAYMENT_CONCEPT,
  PREPAID_STATUS,
  TRAVEL_PURPOSE,
  UI_STYLES,
  USER_ROLE,
} from '../../../constants/constants';
import en from '../../../translations/en';

import { ExpandLessIcon, ExpandMoreIcon, SeatFlightIcon, SeatInfantClassIcon } from '../../../assets/images';

import { ILtFare } from '../../../interfaces';

import {
  checkInfantTypeIsINSOrINF,
  constructBookingDetailsKeyValues,
  formatDateAsString,
  getDisplayLabel,
  getDisplayVal,
  getPassengerDisplayName,
  getTicketsBySegment,
  getUnUcData,
  isFlightInfoGreyOut,
  getCancelWarningMessage,
  getMatchWarningMessage,
  getBookingStatus,
  getPaymentStatus,
} from '../../../helpers';

import { useAppSelector } from '../../../app/hooks';
import { selectApp } from '../../../slice/appSlice';
import { selectBooking } from '../../../slice/bookingSlice';
import { BookingSummaryAction, BookingSummaryState } from '../../../context';

import { ModifiedWarningMessage, ShadowContent, ScrollableView } from '../../../components';
import {
  BaseConcessionInfo,
  BaseCreatedByInfo,
  BaseDetailsInfo,
  DetailInfoTitle,
  DetailsInfo,
  FlightInfo,
  Lounge,
  RemovePaxTravellerDetail,
  SegmentInfo,
  ViewTicketCostDetails,
} from '..';
import { InfantLabel } from '../..';
import MultipleWarning from '../Common/MultipleWarning';

const BookingSummaryContent = ({
  bookingSummary,
  isLevelZUser = false,
  configurations,
  isAmadeusError,
  isCheckedIn,
  isOpenRemovePaxMode = false,
  isRetrieveToPayStatus = false,
}: {
  bookingSummary: Frontend.ParsedBooking;
  isLevelZUser: boolean;
  configurations: Configuration.ConfigurationsClient;
  isCheckedIn: boolean | null | undefined;
  isAmadeusError?: boolean | null;
  isOpenRemovePaxMode?: boolean;
  isRetrieveToPayStatus?: boolean;
}) => {
  const theme = useTheme();
  const { isDesktop } = useAppSelector(selectApp) || {};
  const isPrepaid = bookingSummary?.paymentConcept === PAYMENT_CONCEPT.PREPAID;

  // context data for traveller details list
  const {
    travellerList,
    routeTo: removePaxModeRoute,
    isFlightRebook,
    bookingEligibility,
    isRetrieveAndPayFlow,
    isExpandWarning,
  } = useContext(BookingSummaryState) || {};

  const { setTravellerList, setIsExpandWarning } = useContext(BookingSummaryAction) || {};

  // en translation
  const OALMessage = en.multipleWarning.OAL.OALWarning;
  const enModified = en.booking.modified;
  const {
    dutyTravelDetails: enDutyTravelDetails,
    paymentDetails: enPaymentDetails,
    flownDetails: enFlownDetails,
    travellerDetails: enTravellerDetails,
  } = en.booking.flightConfirmation.travelDetails;

  const {
    recLoc,
    type,
    ticketsBySegment,
    concession,
    contactInformation,
    dutyTravel,
    status = '',
    prepaidStatus,
    updatedBy,
    createdBy,
    etpPassengerList,
    paymentConcept,
    paymentMedium,
  } = bookingSummary || {};

  const [showOriginalFlightForUnUc, setShowOriginalFlightForUnUc] = useState(false);
  const bookingStatus = getBookingStatus({
    status,
    prepaidStatus,
    paymentConcept: paymentConcept,
  });
  const { role: updatedByRole, roleName: updatedByRoleName } = updatedBy || {};
  const { role: createdByRole, roleName: createdByRoleName } = createdBy || {};

  const firstSegmentTickets = (ticketsBySegment && ticketsBySegment[0] && ticketsBySegment[0].tickets) || {};
  const firstTicketInFirstSegment = (firstSegmentTickets && firstSegmentTickets[0]) || {};
  const { updatedAt } = (firstTicketInFirstSegment && firstTicketInFirstSegment.latestTicket) || {};

  const unUcData = getUnUcData(bookingStatus, ticketsBySegment);
  const isDT = type === TRAVEL_PURPOSE.employeeDutyTravel;
  const isDisabled = useMemo(
    () =>
      isFlightInfoGreyOut({
        bookingStatus: bookingStatus,
        unUcType: unUcData?.type,
        isViewBookingDetails: true,
      }),
    [bookingStatus, unUcData?.type],
  );

  const desktopBookingDetailsWidth = isDesktop ? { width: UI_STYLES.desktopBookingDetailsWidth } : null;

  // hardcode for 2480,integration api will remove
  const prepaidWarningMes = getMatchWarningMessage({
    bookingEligibility,
    prepaidStatus,
    isFlightRebook: isFlightRebook,
  });
  const isCancelOrNotRefund = bookingStatus === BOOKING_STATUS.cancelled || bookingStatus === BOOKING_STATUS.notRefund;

  const getUNUCWarningComponent = (unUcType: string, flightNumber: string): React.ReactNode => {
    const theme = useTheme();

    const { unUc } = en.booking;

    switch (unUcType) {
      case BOOKING_UNUC_TYPE.noProtection:
        return (
          <Typography variant="body_2_medium" color={theme.color.secondary.dark_grey.option_3} sx={{ ml: 1 }}>
            {flightNumber}
            {unUc.noProtection.message}
          </Typography>
        );
      case BOOKING_UNUC_TYPE.protected:
        return (
          <Box sx={{ ml: 1 }}>
            <Typography variant="body_2_medium" color={theme.color.secondary.dark_grey.option_3}>
              {flightNumber}
              {unUc.protected.message}
            </Typography>

            <Button
              onClick={() => {
                setShowOriginalFlightForUnUc((showOriginalFlightForUnUc) => !showOriginalFlightForUnUc);
              }}
              sx={{ p: 0, mt: 1 }}
            >
              <Typography variant="body_2_medium" color={theme.color.utility.link.option_3}>
                {showOriginalFlightForUnUc ? en.common.hide : en.common.view}
                {unUc.protected.originalFlight}
              </Typography>
            </Button>
          </Box>
        );
      case BOOKING_UNUC_TYPE.protectedDifferentCarrier:
        return (
          <Typography variant="body_2_medium" color={theme.color.secondary.dark_grey.option_3} sx={{ ml: 1 }}>
            {flightNumber}
            {unUc.protectedDifferentCarrier.message1}
            <Typography
              variant="body_2_medium"
              color={theme.color.utility.link.option_3}
              sx={{ display: 'inline', cursor: 'pointer' }}
              onClick={() => {
                window.open('https://cms.cathaypacific.com');
              }}
            >
              {unUc.protectedDifferentCarrier.message2}
            </Typography>
          </Typography>
        );

      default:
        return null;
    }
  };

  const getWarningComponent = () => {
    if (unUcData) {
      return getUNUCWarningComponent(unUcData.type, unUcData.originalFlightNumber);
    }

    if (isCheckedIn) {
      return (
        <Typography variant="body_2_regular" sx={{ ml: 1, color: theme.color.secondary.dark_grey.option_3 }}>
          {en.booking.confirmation.checkInStatusWarningMessage}
        </Typography>
      );
    }
    // Prepaid cancelled case
    if (isPrepaid && prepaidStatus !== PREPAID_STATUS.PAID) {
      return (
        <>
          {prepaidWarningMes && (
            <Typography variant="body_2_regular" sx={{ ml: 1, color: theme.color.secondary.dark_grey.option_3 }}>
              {isRetrieveAndPayFlow ? en.booking.retrieveToPay.confirmAndPayErrorMes : prepaidWarningMes}
            </Typography>
          )}
        </>
      );
    }

    if (isCancelOrNotRefund) {
      // Flown(Normal) canncled case
      return (
        <>
          {getCancelWarningMessage(updatedByRole, getDisplayVal(updatedByRoleName)) && (
            <Typography variant="body_2_regular" sx={{ ml: 1, color: theme.color.secondary.dark_grey.option_3 }}>
              {getCancelWarningMessage(
                updatedByRole,
                getDisplayVal(updatedByRoleName),
                paymentConcept === PAYMENT_CONCEPT.PREPAID,
                prepaidWarningMes,
              )}
            </Typography>
          )}
        </>
      );
    }

    if (bookingStatus === BOOKING_STATUS.modified || isAmadeusError) {
      return (
        <ModifiedWarningMessage
          title={isAmadeusError ? enModified.errorRetrieve : enModified.outside}
          info={enModified.details}
          updateTime={`${formatDateAsString(updatedAt, DATE_FORMAT.dateHourMin, DATE_FORMAT.dateTime)} ${
            enModified.hkt
          }`}
        />
      );
    }

    if (bookingStatus === BOOKING_STATUS.autoCancelBA) {
      return (
        <ModifiedWarningMessage
          title={en.booking.cancel.autoCancelBA}
          info={enModified.details}
          updateTime={`${formatDateAsString(updatedAt, DATE_FORMAT.dateHourMin, DATE_FORMAT.dateTime)} ${
            enModified.hkt
          }`}
        />
      );
    }
    if (bookingStatus === BOOKING_STATUS.retrieveToPay) {
      return (
        <Typography variant="body_2_regular" sx={{ ml: 1, color: theme.color.secondary.dark_grey.option_3 }}>
          {isRetrieveAndPayFlow
            ? en.booking.retrieveToPay.confirmAndPayErrorMes
            : en.booking.retrieveToPay.errorMessage}
        </Typography>
      );
    }

    if (isFlightRebook) {
      return (
        <Typography variant="body_2_regular" sx={{ ml: 1, color: theme.color.secondary.dark_grey.option_3 }}>
          {en.booking.confirmation.cancelActions.rebook.haveRebooked}
        </Typography>
      );
    }

    return null;
  };

  const warningComponent = getWarningComponent();

  // handle DT travel details, payment details (DT/LT)
  const { dutyTravelDetailsData, paymentDetailsData } = constructBookingDetailsKeyValues({
    configurations,
    contactInformation,
    dutyTravel,
    isLevelZUser,
  });

  const getDtDetail = () => {
    return (
      <>
        <BaseDetailsInfo
          data={dutyTravelDetailsData}
          title={enDutyTravelDetails.title}
          customStyles={desktopBookingDetailsWidth}
        />

        <BaseDetailsInfo
          data={paymentDetailsData}
          title={enPaymentDetails.title}
          customStyles={desktopBookingDetailsWidth}
          childStyles={{ display: 'flex', flexDirection: 'column' }}
        />
      </>
    );
  };

  const getLtDetail = () => {
    const paymentDetailsData = [
      {
        key: enPaymentDetails.paymentConcept,
        value: getDisplayLabel(configurations.labels, LABEL_CATEGORY.paymentConcept, paymentConcept),
      },
    ];

    if (paymentConcept !== PAYMENT_CONCEPT.PREPAID) {
      paymentDetailsData.push({
        key: enPaymentDetails.paymentMedium,
        value: getDisplayLabel(configurations.labels, LABEL_CATEGORY.paymentMedium, paymentMedium),
      });
    } else {
      paymentDetailsData.push({
        key: enPaymentDetails.paymentStatus,
        value: getPaymentStatus(prepaidStatus),
      });
    }

    return (
      <BaseDetailsInfo
        data={paymentDetailsData}
        title={enPaymentDetails.title}
        customStyles={desktopBookingDetailsWidth}
        childStyles={{ display: 'flex', flexDirection: 'column' }}
      />
    );
  };

  const updateStatusInTravellerList = (targetTravellerItem: any) => {
    const list = travellerList?.map((tempItem: any) =>
      tempItem.ticketNum === targetTravellerItem?.ticketNum ? tempItem : targetTravellerItem,
    );

    setTravellerList(list);
  };

  // handle expand more/less action
  const handleExpandStatus = (targetTravellerItem: any) => {
    targetTravellerItem.isExpandMore = !targetTravellerItem.isExpandMore;
    updateStatusInTravellerList(targetTravellerItem);
  };

  // handle view more/less action
  const handleViewMoreStatus = (targetTravellerItem: any) => {
    targetTravellerItem.isViewMore = !targetTravellerItem.isViewMore;
    updateStatusInTravellerList(targetTravellerItem);
  };

  const findAccompanyAdult = (targetTravellerItem: any) => {
    const accompanyAdult = travellerList?.find(
      (tempItem: any) =>
        targetTravellerItem.traveler?.type === NOMINATED_PASSENGER_AGE_TYPE.infant &&
        tempItem?.traveler?.paxRefNum === targetTravellerItem.traveler?.paxRefNum &&
        tempItem?.traveler?.type !== NOMINATED_PASSENGER_AGE_TYPE.infant,
    );

    return accompanyAdult;
  };

  // ------------ for 2915 start ------------
  const { outwardFlight, leisureTravelDetails } = useAppSelector(selectBooking, shallowEqual) || {};
  const {
    arrivalDate,
    arrivalTime,
    departureTime,
    departureDate,
    flightNo,
    marketingCompany,
    numberOfStops,
    aircraft,
  } = outwardFlight || {};
  const { fareDetail } = leisureTravelDetails || {};
  // for OAL constant
  const isOWOALFlight = ticketsBySegment[0]?.flightNum === en.multipleWarning.open;
  const OALCode = ticketsBySegment[0].carrier;

  const rebookTicketsBySegment: Pnr.TicketBySegment[] = _.cloneDeep(ticketsBySegment || {});
  if (rebookTicketsBySegment?.[0]) {
    rebookTicketsBySegment[0].arrivalDate = arrivalDate || '';
    rebookTicketsBySegment[0].arrivalTime = arrivalTime || '';
    rebookTicketsBySegment[0].departureDate = departureDate || '';
    rebookTicketsBySegment[0].departureTime = departureTime || '';
    rebookTicketsBySegment[0].flightNum = flightNo || '';
    rebookTicketsBySegment[0].carrier = marketingCompany || '';
    rebookTicketsBySegment[0].numOfStops = numberOfStops || 0;
    rebookTicketsBySegment[0].aircraftType = aircraft || '';
  }

  // ------------ for 2915 end ------------
  return (
    <Box
      sx={{
        overflow: 'auto',
        flex: 1,
        '&::-webkit-scrollbar': {
          // safari and chrome
          display: 'none',
        },
        '-ms-overflow-style': 'none', // IE
        'scrollbar-width': 'none', // firefox
      }}
    >
      <Box
        component={ScrollableView}
        sx={{
          flex: 1,
          minWidth: 0,
          minHeight: 0,
          width: isDesktop ? '854px' : '100%',
          mx: 'auto',
          mt: isDesktop ? 5 : 0,
          ...(isRetrieveAndPayFlow && {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
          }),
        }}
      >
        <Box
          sx={{
            width: !isDesktop ? '100%' : UI_STYLES.retrieveAndPayViewDetailsLeftWidth,
            ...(isDesktop &&
              (isRetrieveAndPayFlow
                ? {
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }
                : {
                    float: 'right',
                    '& > *:first-child': { mt: 0 },
                    width: UI_STYLES.retrieveAndPayViewDetailsLeftWidth,
                  })),
          }}
        >
          {(isOWOALFlight || warningComponent) && (
            <Box
              sx={{
                mb: 2,
                mt: isFlightRebook ? 0 : 1,
                ...(isRetrieveAndPayFlow && {
                  width: isDesktop ? UI_STYLES.viewDetailsLeftWidth : '100%',
                }),
              }}
            >
              <MultipleWarning
                type={isOWOALFlight ? MULTIPLE_WARNING_TYPE.otherAirline : undefined}
                isExpand={isExpandWarning}
                messageFormatter={OALMessage}
                onChange={() => {
                  setIsExpandWarning(!isExpandWarning);
                }}
                data={[
                  {
                    ...(isOWOALFlight && {
                      headingCode: OALCode,
                    }),
                  },
                  {
                    ...(warningComponent && {
                      warningMessage: warningComponent,
                    }),
                  },
                ]}
              />
            </Box>
          )}

          {isRetrieveAndPayFlow ? (
            <Box
              sx={{
                width: isDesktop ? UI_STYLES.viewDetailsLeftWidth : '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <SegmentInfo
                flightInfo={
                  {
                    ...ticketsBySegment[0],
                    callSign: ticketsBySegment[0].carrier + ticketsBySegment[0].flightNum,
                    status: ticketsBySegment?.[0]?.status,
                  } as any
                }
                isShowSegmentStatus={false}
              />

              <ViewTicketCostDetails
                currency={fareDetail ? Object.values(fareDetail as ILtFare)?.[0]?.currency : ''}
                fareDetails={(fareDetail as ILtFare) || {}}
              />
            </Box>
          ) : (
            <Box
              component={ShadowContent}
              sx={{
                mt: 1,
                mb: 2,
                background: isFlightRebook || !isDisabled ? undefined : theme.color.secondary.light_slate.option_6,
                p: 2,
              }}
            >
              <FlightInfo
                bookingStatus={bookingStatus}
                recLoc={recLoc}
                type={type}
                ticketsBySegment={getTicketsBySegment(bookingStatus, ticketsBySegment)}
                isViewBookingDetails={true}
                unUcType={unUcData?.type}
                etpPassengerList={etpPassengerList}
              />
            </Box>
          )}

          {showOriginalFlightForUnUc && unUcData && (
            <Box
              component={ShadowContent}
              sx={{
                mt: 1,
                mb: 2,
                background: theme.color.secondary.light_slate.option_6,
              }}
            >
              <FlightInfo
                bookingStatus={bookingStatus}
                recLoc={recLoc}
                type={type}
                ticketsBySegment={[{ ...unUcData.inactiveSegObj, tickets: [] }]}
                isViewBookingDetails={true}
                isOriginalFlightForUnUc={true}
                etpPassengerList={etpPassengerList}
              />
            </Box>
          )}
        </Box>

        {isOpenRemovePaxMode && removePaxModeRoute?.step !== 2 && <RemovePaxTravellerDetail />}

        {!isOpenRemovePaxMode && (
          <>
            {createdByRole === USER_ROLE.admin && createdByRoleName && (
              <BaseCreatedByInfo data={getDisplayVal(createdByRoleName)} customStyles={desktopBookingDetailsWidth} />
            )}
            {createdByRole === USER_ROLE.delegation && (
              <BaseCreatedByInfo data={en.booking.create.delegateeCreate} customStyles={desktopBookingDetailsWidth} />
            )}

            {!isRetrieveAndPayFlow && (
              <BaseConcessionInfo data={getDisplayVal(concession?.name)} customStyles={desktopBookingDetailsWidth} />
            )}

            <ShadowContent sx={{ mb: 2, ...desktopBookingDetailsWidth }}>
              <Typography color={theme.color.secondary.dark_grey.option_1} variant="body_1_bold">
                {enTravellerDetails.title}
              </Typography>

              {travellerList?.length > 0 &&
                travellerList.map((item: any, index: number) => {
                  const accompanyAdult = findAccompanyAdult(item) || null;
                  const isFlown = item?.isFlownCompleted || false;
                  const isRemoved = item?.isRemoved || false;

                  return (
                    <Box
                      key={index.toString()}
                      sx={{
                        bgcolor:
                          isFlown || isRemoved ? theme.color.secondary.light_slate.option_6 : theme.color.etp.option_1,
                        borderRadius: 1,
                        mt: 2,
                        p: 2,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          minHeight: '56px',
                        }}
                      >
                        <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                          {item.traveler?.type && checkInfantTypeIsINSOrINF(item.traveler?.type) ? (
                            <SeatInfantClassIcon
                              height={28}
                              width={28}
                              fill={!isRemoved ? theme.color.etp.option_2 : theme.color.secondary.dark_grey.option_4}
                              style={{ marginRight: '8px' }}
                            />
                          ) : (
                            <SeatFlightIcon
                              fill={!isRemoved ? theme.color.etp.option_2 : theme.color.secondary.dark_grey.option_4}
                              style={{ marginRight: '8px' }}
                            />
                          )}

                          <Typography
                            color={theme.color.secondary.dark_grey.option_3}
                            variant="body_1_medium"
                            sx={{ flex: 1, wordBreak: 'break-word' }}
                          >
                            {getPassengerDisplayName(item.traveler)}
                          </Typography>
                        </Box>

                        {!isRetrieveToPayStatus && !isRemoved && (
                          <IconButton
                            onClick={() => {
                              handleExpandStatus(item);
                            }}
                          >
                            {item.isExpandMore ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                          </IconButton>
                        )}
                      </Box>

                      {isRemoved && (
                        <Typography
                          variant="body_2_regular"
                          sx={{
                            overflow: 'hidden',
                            color: theme.color.secondary.dark_grey.option_3,
                          }}
                        >
                          {en.booking.confirmation.removePax.paxIsRemoved}
                        </Typography>
                      )}

                      {!isRemoved && (
                        <Collapse in={item.isExpandMore} unmountOnExit>
                          {/*  flown and partial flown label */}
                          {isFlown && (
                            <Typography
                              variant="navigation_regular"
                              sx={{
                                ml: 4.5,
                                mb: 1.875,
                                overflow: 'hidden',
                                color: theme.color.secondary.grey.option_2,
                              }}
                            >
                              {enFlownDetails.flown}
                            </Typography>
                          )}

                          {/* infant label */}
                          {item.traveler?.type && checkInfantTypeIsINSOrINF(item.traveler?.type) && (
                            <InfantLabel
                              sxProps={{ mt: -1, ml: 4.5, mb: 1.875 }}
                              isInfantWithSeat={!accompanyAdult}
                              accompanyAdultTitle={
                                accompanyAdult &&
                                `${en.booking.confirmation.associatedWith} ${getPassengerDisplayName({
                                  lastName: accompanyAdult?.traveler?.lastName,
                                  firstName: accompanyAdult?.traveler?.firstName,
                                })}`
                              }
                            />
                          )}

                          {item.lounge && (
                            <Box sx={{ mb: 1.5 }}>
                              <Lounge lounge={item.lounge} labels={configurations.labels} />
                            </Box>
                          )}

                          {isFlown ? (
                            <>
                              <Box sx={{ mt: 2 }}>
                                <DetailsInfo
                                  data={item.flownDetails}
                                  title={{
                                    title: enFlownDetails.title,
                                  }}
                                  titleColor={theme.color.secondary.dark_grey.option_3}
                                />
                              </Box>
                              <Box sx={{ mt: 1.5, height: '24px' }}>
                                <DetailInfoTitle
                                  title={en.booking.confirmation.ticketDetails.title}
                                  handleExpandClick={() => {
                                    handleViewMoreStatus(item);
                                  }}
                                  expandTitle={item.isViewMore ? enFlownDetails.viewLess : enFlownDetails.viewMore}
                                  titleColor={theme.color.secondary.dark_grey.option_3}
                                />
                              </Box>
                              <Collapse sx={{ mt: 1.5 }} in={item.isViewMore} unmountOnExit>
                                <DetailsInfo data={item.ticketDetails} title={{ title: '' }} />
                              </Collapse>
                            </>
                          ) : (
                            <DetailsInfo
                              data={item.ticketDetails}
                              title={{
                                title: en.booking.confirmation.ticketDetails.title,
                              }}
                              titleVariant={'body_2_bold'}
                              titleColor={theme.color.secondary.dark_grey.option_3}
                              isRetrieveToPayStatus={isRetrieveToPayStatus}
                            />
                          )}
                        </Collapse>
                      )}
                    </Box>
                  );
                })}
            </ShadowContent>

            <Box
              sx={{
                ...(isRetrieveAndPayFlow && !isDesktop && { width: '100%' }),
              }}
            >
              {isDT ? getDtDetail() : getLtDetail()}
            </Box>
            {/* {isRetrieveAndPayFlow &&
            isShowAnimation &&
            getFlightAnimation(en.booking.retrieveToPay.loadingText)} */}
          </>
        )}
      </Box>
    </Box>
  );
};

export default BookingSummaryContent;
