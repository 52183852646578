import React, { createContext, useState } from 'react';
import { shallowEqual } from 'react-redux';

import { useAppSelector } from '../app/hooks';
import { IMultipleEmail } from '../interfaces';
import { selectBooking } from '../slice/bookingSlice';

interface IStateType {
  contactDetails: any;
}

interface IActionType {
  setEditContactDetails: any;
}

const initialEmailObj: IMultipleEmail = {
  type: {},
  email: '',
};

const EditContactDetailsState = createContext<IStateType>({
  contactDetails: null,
});

const EditContactDetailsAction = createContext<IActionType>({
  setEditContactDetails: null,
});

const EditLtContactDetailContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { contactDetails } = useAppSelector(selectBooking, shallowEqual) || {};

  const [editContactDetails, setEditContactDetails] = useState(contactDetails);

  return (
    <EditContactDetailsState.Provider
      value={{
        contactDetails: editContactDetails,
      }}
    >
      <EditContactDetailsAction.Provider
        value={{
          setEditContactDetails,
        }}
      >
        {children}
      </EditContactDetailsAction.Provider>
    </EditContactDetailsState.Provider>
  );
};

export { EditLtContactDetailContextProvider, EditContactDetailsState, EditContactDetailsAction, initialEmailObj };
