import { ADMIN_PATHS } from '../../constants/paths';
import { IRouteConfig } from '../../interfaces';
import AdminGuide from './AdminGuide';
import AdminEmployee from './AdminEmployee';
import AdminTravelHistoryReport from './AdminTravelHistoryReport';

const adminRouteConfig: IRouteConfig[] = [
  {
    path: ADMIN_PATHS.adminGuide,
    component: AdminGuide,
    exact: false,
  },
  {
    path: ADMIN_PATHS.adminEmployee,
    component: AdminEmployee,
    exact: false,
  },
  {
    path: ADMIN_PATHS.adminTravelHistory,
    component: AdminTravelHistoryReport,
    exact: false,
  },
];

export default adminRouteConfig;
