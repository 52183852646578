import React, { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import { selectApp, setCancelBookingSuccessData } from '../../../slice/appSlice';

import { CheckMarkIcon } from '../../../assets/images';

import { SnackbarComponent } from '../../../containers';

const CancelBookingSnackbar = () => {
  const [open, setOpen] = useState(false);

  const dispatch = useAppDispatch();

  const { cancelBookingSuccessData } = useAppSelector(selectApp, shallowEqual) || {};

  useEffect(() => {
    if (cancelBookingSuccessData?.isShow) {
      setOpen(true);
    }
  }, [cancelBookingSuccessData]);

  const handleClose = () => {
    setOpen(false);
    dispatch(setCancelBookingSuccessData(null));
  };

  return (
    <SnackbarComponent
      open={open}
      flightNumber={cancelBookingSuccessData?.flightNumber || ''}
      IconComponent={<CheckMarkIcon />}
      handleClose={handleClose}
    />
  );
};

export default CancelBookingSnackbar;
