import { FormErrors } from 'redux-form';
import { validateRequiredFields } from '../../../../../helpers/validation';
import { IUsAddress } from '../../../../../interfaces';

export const validate = (values: IUsAddress | null): FormErrors<IUsAddress | null> => {
  const errors: FormErrors<IUsAddress | null> = {};

  if (values) {
    validateRequiredFields(values, ['street', 'city', 'state', 'zipCode'], errors);
  }

  return errors;
};
