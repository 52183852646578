import { HTTP_REQUEST_TYPE, ADMIN_API } from '../../constants/api';
import en from '../../translations/en';

import { sendHttpRequest } from '../api';

const getTravelHistoryReport = async ({
  employeeId,
  from,
  to,
  type,
}: {
  employeeId: string;
  from: string;
  to: string;
  type: string;
}) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: ADMIN_API.HISTORY_API.report,
    apiTitle: en.errorAlert.getHistoryReport,
    payload: { employeeId, from, to, type },
  });
  return resp;
};

export { getTravelHistoryReport };
