import React, { useEffect } from 'react';
import { Box, useTheme } from '@mui/material';

import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import {
  USER_PROFILE_DETAILS,
  LABEL_CATEGORY,
  PAYMENT_MEDIUM,
  PAYMENT_SETUP_STATUS,
  PAYMENT,
  PAYMENT_CONCEPT,
} from '../../../constants/constants';
import en from '../../../translations/en';

import { Employee } from 'employee';
import { IInformationListItem } from '../Common/InformationList';

import { getDisplayLabel } from '../../../helpers';

import { usePaymentMethodIndicator } from '../../../hooks';
import { selectApp } from '../../../slice/appSlice';
import { getProfileThunk } from '../../../slice/userSlice';
import { selectAuth } from '../../../slice/authSlice';
import { selectConfiguration } from '../../../slice/configurationSlice';
import { setIsOpenBillingAgreementDialog } from '../../../slice/paypalSlice';

import { InformationList } from '../..';
import { CommonWarning } from '../../../components';

const PaymentMethodDetailContainer = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { ern: ernFromLogin } = useAppSelector(selectAuth) || {};
  const { configurations } = useAppSelector(selectConfiguration) || {};
  const { isDesktop, isLoading } = useAppSelector(selectApp) || {};

  const {
    title,
    paymentConcept: labelPaymentConcept,
    paymentMedium: labelPaymentMedium,
    paymentSetUpStatus: labelPaymentSetUpStatus,
  } = en.userProfile.employmentDetail.paymentMethod;

  // Payment method indicator
  const [paymentMethodIndicator] = usePaymentMethodIndicator();
  const paymentSetUpStatusLbl = en.userProfile.employmentDetail.paymentSetUpStatus;

  // fetch profile API when access this page.
  useEffect(() => {
    dispatch(getProfileThunk(ernFromLogin));
    return () => {};
  }, [dispatch]);

  // display payment display label by mapping with Redux's configuration data
  const getDisplayPaymentVal = (key: keyof Employee.Profile, value: string) => {
    const tmpDisplayLabel = getDisplayLabel(
      configurations?.labels,
      LABEL_CATEGORY?.[key as keyof typeof LABEL_CATEGORY],
      value,
    );
    return tmpDisplayLabel;
  };
  {
    /* ETP-2431 */
  }
  const isPrepaid = paymentMethodIndicator.paymentConcept === PAYMENT_CONCEPT.PREPAID;
  const listData: IInformationListItem[] = [
    {
      key: labelPaymentConcept,
      value: getDisplayPaymentVal(
        PAYMENT.fieldKey.paymentConcept as keyof Employee.Profile,
        paymentMethodIndicator?.paymentConcept || '',
      ),
      isShowWarning: false,
      isShow: true,
    },
    {
      key: labelPaymentMedium,
      value: getDisplayPaymentVal(
        PAYMENT.fieldKey.paymentMedium as keyof Employee.Profile,
        paymentMethodIndicator?.paymentMedium || '',
      ),
      isShowWarning: false,
      isShow: !isPrepaid, // ETP-2431
    },
  ];

  if (paymentMethodIndicator?.paymentSetUpStatus !== '') {
    let defaultStatusLbl = paymentSetUpStatusLbl.notYetSetup;
    if (paymentMethodIndicator.paymentSetUpStatus === PAYMENT_SETUP_STATUS.COMPLETED) {
      defaultStatusLbl = paymentSetUpStatusLbl.completed;
    }

    // [ETP-4266]
    if (paymentMethodIndicator.paymentSetUpStatus === PAYMENT_SETUP_STATUS.UNABLE_RETRIEVE) {
      defaultStatusLbl = paymentSetUpStatusLbl.unableRetrieve;
    }

    listData.push({
      key: labelPaymentSetUpStatus,
      value: defaultStatusLbl,
      isShowWarning: paymentMethodIndicator?.isShowWarning,
      isShow: !isPrepaid,
    });
  }

  return (
    <Box sx={{ ...(isDesktop && { width: USER_PROFILE_DETAILS.width }) }}>
      <InformationList title={title} listData={listData} />
      {/*  ETP-2431 */}
      {!isLoading &&
        !isPrepaid &&
        paymentMethodIndicator?.isShowWarning &&
        paymentMethodIndicator?.paymentSetUpWarningMessage && (
          <Box
            sx={{
              mt: 3,
            }}
          >
            <CommonWarning
              isShowIcon={true}
              customIconFill={theme.color.utility.error.option_3}
              msgText={paymentMethodIndicator?.paymentSetUpWarningMessage}
              replaceTarget={paymentMethodIndicator.replaceTarget}
              actionFunc={() => {
                // Paypal flow - open billing agreement dialog
                if (paymentMethodIndicator?.paymentMedium === PAYMENT_MEDIUM.PAYPAL_PAYMENT) {
                  dispatch(setIsOpenBillingAgreementDialog(true));
                }

                // Direct debit flow - page redirect to ETP website
                if (
                  paymentMethodIndicator?.paymentMedium === PAYMENT_MEDIUM.DIRECT_DEBIT_PAYMENT &&
                  window.config?.employeeTravelPortalLink
                )
                  window.open(window.config.employeeTravelPortalLink);
              }}
              customStyles={{
                mr: 0,
              }}
            />
          </Box>
        )}
    </Box>
  );
};

export default PaymentMethodDetailContainer;
