import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, useLocation, useRouteMatch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { InteractionType } from '@azure/msal-browser';
import { MsalProvider, MsalAuthenticationTemplate, MsalAuthenticationResult } from '@azure/msal-react';
import ReactGA from 'react-ga4';
import { ErrorBoundary } from 'react-error-boundary';
import jwt_decode from 'jwt-decode';

import { CssBaseline, useMediaQuery, ThemeProvider, Box, Typography, useTheme } from '@mui/material';

import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';

import defaultTheme from './style/theme';

import { store } from './app/store';
import * as serviceWorker from './serviceWorker';
import { getAccessToken, loginRequest, msalInstance } from './config/authConfig';

import { NavBar, RouteSwitch, AlertDialog, AdminMobileView, FormButton } from './components';
import { CancelBookingSnackbar, BaseSnackbar } from './containers';
import { RoleIndicatorBar, BillingAgreementDialog, FlownSuspensionDialog } from './containers';
import { useAppSelector, useAppDispatch } from './app/hooks';

import { selectApp, setIsDesktop } from './slice/appSlice';
import { selectAuth } from './slice/authSlice';
import { selectUser } from './slice/userSlice';
import { setIsOpenBillingAgreementDialog } from './slice/paypalSlice';
import { setIsOpenFlownSuspensionDialog } from './slice/flownSuspensionSlice';

import './index.css';
import userRouteConfig from './pages/user/routes';
import bookingRouteConfig from './pages/booking/routes';
import rootRouterConfig from './pages/routes';
import infoRouteConfig from './pages/info/routes';
import adminRouteConfig from './pages/admin/routes';
import paypalRouteConfig from './pages/paypal/routes';
import prepaidRouteConfig from './pages/prepaid/routes';

import { ROOT_PATHS } from './constants/paths';
import { GOOGLE_ANALYTICS_EVENTS, LOGIN_MODE, USER_ROLE } from './constants/constants';
import { SAMPLE_USER_FLOWN_SUSPENSION_DATA } from './constants/sampleData';

import en from './translations/en';

import { initClientApi } from './services/api';

import { createBillingAgreementTokenAction, getFlightAnimation } from './helpers';

import {
  EditSearchBarContextProvider,
  ConcessionInProfileContextProvider,
  AdminAccessEmployeeContextProvider,
  TravelTypeListELTContextProvider,
  BookingSummaryContextProvider,
} from './context';
import MultiProvider from './context/MultiProvider';

import AssoSubsidLogin from './pages/login/asso-subsid-login';

const UserAuthorizedComponent = () => {
  const dispatch = useAppDispatch();

  const { isLoading, loadingMessage } = useAppSelector(selectApp);
  const { role, profile } = useAppSelector(selectUser) || {};

  const location = useLocation();

  const rootRoutes = [
    ...userRouteConfig,
    ...bookingRouteConfig,
    ...infoRouteConfig,
    ...adminRouteConfig,
    ...paypalRouteConfig,
    ...prepaidRouteConfig,
    rootRouterConfig,
  ];

  const isDesktop = useMediaQuery(defaultTheme.breakpoints.up('sm'));
  dispatch(setIsDesktop(isDesktop));

  const { type: roleType } = role || {};

  const isAdminDelegation = [USER_ROLE.admin, USER_ROLE.delegation].includes(roleType);

  const { ern: ernFromLogin } = useAppSelector(selectAuth) || {};

  return (
    <>
      {isLoading && getFlightAnimation(loadingMessage)}

      {isDesktop
        ? location.pathname !== ROOT_PATHS.landing && (
            <>
              <NavBar />
              {isAdminDelegation && (
                <RoleIndicatorBar
                  customStyles={{
                    pl: 22,
                    background: defaultTheme.color.secondary.light_slate.option_5,
                  }}
                />
              )}
            </>
          )
        : roleType === USER_ROLE.admin && <AdminMobileView />}

      <Box
        sx={{
          wordBreak: 'break-word',
        }}
      >
        <MultiProvider
          providers={[
            <EditSearchBarContextProvider key={0} />,
            <TravelTypeListELTContextProvider key={1} />,
            <ConcessionInProfileContextProvider key={2} />,
            <AdminAccessEmployeeContextProvider key={3} />,
            <BookingSummaryContextProvider key={4} />,
          ]}
        >
          <AlertDialog />
          <BillingAgreementDialog
            handleCloseClick={() => dispatch(setIsOpenBillingAgreementDialog(false))}
            handleConfirmClick={() =>
              createBillingAgreementTokenAction({
                ern: ernFromLogin,
                dispatch,
              })
            }
          />
          <FlownSuspensionDialog
            handleCloseClick={() => dispatch(setIsOpenFlownSuspensionDialog(false))}
            startDate={profile?.etpFlownSuspensionStartDate || SAMPLE_USER_FLOWN_SUSPENSION_DATA.startDate}
            endDate={profile?.etpFlownSuspensionEndDate || SAMPLE_USER_FLOWN_SUSPENSION_DATA.endDate}
          />

          <RouteSwitch routes={rootRoutes} />
        </MultiProvider>
      </Box>
      <CancelBookingSnackbar />
      <BaseSnackbar />
    </>
  );
};

const UserNotAuthorizedComponent = () => {
  const theme = useTheme();
  const loginMode = sessionStorage.getItem(LOGIN_MODE.key);

  return (
    <Box
      sx={{
        mx: {
          xs: 2,
          sm: 24.375,
        },
        my: {
          xs: 6.125,
          sm: 12,
        },
      }}
    >
      <Typography
        sx={{
          typography: { xs: 'large_title_bold', sm: 'large_title_1_bold' },
        }}
        color={theme.color.secondary.dark_grey.option_3}
      >
        {en.notAuthorized.title}
      </Typography>

      <Box sx={{ mt: 2, mb: 4 }}>
        <Typography
          sx={{
            typography: { xs: 'body_2_regular', sm: 'body_1_regular' },
          }}
          color={theme.color.secondary.dark_grey.option_3}
        >
          {en.notAuthorized.accessForbidden}
        </Typography>

        <Typography
          sx={{
            typography: { xs: 'body_2_regular', sm: 'body_1_regular' },
          }}
          color={theme.color.secondary.dark_grey.option_3}
        >
          {en.notAuthorized.message}
        </Typography>
        {/* This is for asso-subsid PoC usage only */}
        <Typography
          sx={{
            typography: { xs: 'body_2_regular', sm: 'body_1_regular' },
          }}
          color={theme.color.secondary.dark_grey.option_3}
        >
          {loginMode === LOGIN_MODE.values.assoSubsid
            ? 'You are login from the Travel Hub Asso-Subsid Login Page. Please use the Travel Hub Login Page to login.'
            : 'You are login from the Travel Hub Login Page. Please use the Travel Hub Asso Subsid Login Page to login.'}
        </Typography>
      </Box>

      {/* <FormButton
        theme={theme}
        colour="green"
        variant="contained"
        color="success"
        size="large"
        onClick={() => {
          history.go(-1);
        }}
      >
        {en.common.goBack}
      </FormButton> */}
    </Box>
  );
};

const SystemMaintenanceComponent = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        mx: {
          xs: 2,
          sm: 24.375,
        },
        my: {
          xs: 6.125,
          sm: 12,
        },
      }}
    >
      <Typography
        sx={{
          typography: { xs: 'large_title_bold', sm: 'large_title_1_bold' },
        }}
        color={theme.color.secondary.dark_grey.option_3}
      >
        {en.systemMaintenance.title}
      </Typography>

      <Box sx={{ mt: 2, mb: 4 }}>
        <Typography
          sx={{
            typography: { xs: 'body_2_regular', sm: 'body_1_regular' },
          }}
          color={theme.color.secondary.dark_grey.option_3}
        >
          {window.config.systemMaintenanceMessage}
        </Typography>
      </Box>
    </Box>
  );
};

const ErrorBoundaryComponent = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        mx: {
          xs: 2,
          sm: 24.375,
        },
        my: {
          xs: 6.125,
          sm: 12,
        },
      }}
    >
      <Typography
        sx={{
          typography: { xs: 'large_title_bold', sm: 'large_title_1_bold' },
        }}
        color={theme.color.secondary.dark_grey.option_3}
      >
        {en.notAuthorized.title}
      </Typography>

      <Box sx={{ mt: 2, mb: 4 }}>
        <Typography
          sx={{
            typography: { xs: 'body_2_regular', sm: 'body_1_regular' },
          }}
          color={theme.color.secondary.dark_grey.option_3}
        >
          {en.errorAlert.genericMessage}
        </Typography>
      </Box>

      <FormButton
        theme={theme}
        colour="green"
        variant="contained"
        color="success"
        size="large"
        onClick={() => {
          window.location.replace(ROOT_PATHS.landing);
        }}
      >
        {en.common.refresh}
      </FormButton>
    </Box>
  );
};

const ConnectComponent = () => {
  const theme = useTheme();
  const { initNetworkError } = useAppSelector(selectAuth);
  if (!initNetworkError) {
    return <div style={{ width: '600px', padding: '10px' }}>Loading...</div>;
  }
  return (
    <Box
      sx={{
        mx: {
          xs: 2,
          sm: 24.375,
        },
        my: {
          xs: 6.125,
          sm: 12,
        },
      }}
    >
      <Typography
        sx={{
          typography: { xs: 'large_title_bold', sm: 'large_title_1_bold' },
        }}
        color={theme.color.secondary.dark_grey.option_3}
      >
        {en.notAuthorized.title}
      </Typography>
      <Box sx={{ mt: 2, mb: 4 }}>
        <Typography
          sx={{
            typography: { xs: 'body_2_regular', sm: 'body_1_regular' },
          }}
          color={theme.color.secondary.dark_grey.option_3}
        >
          {en.error.networkError}
        </Typography>

        <Typography
          sx={{
            typography: { xs: 'body_2_regular', sm: 'body_1_regular' },
          }}
          color={theme.color.secondary.dark_grey.option_3}
        >
          {en.errorAlert.genericMessage}
        </Typography>
      </Box>
      <FormButton
        theme={theme}
        colour="green"
        variant="contained"
        color="success"
        size="large"
        onClick={() => {
          window.location.replace(ROOT_PATHS.landing);
        }}
      >
        {en.common.refresh}
      </FormButton>
    </Box>
  );
};

const App = () => {
  const { isLogin, haveAccess } = useAppSelector(selectAuth);

  const initClientApiAction = async () => {
    try {
      await initClientApi();
    } catch (err: any) {
      //
    }
  };

  useEffect(() => {
    if (haveAccess === undefined) {
      initClientApiAction();
    }
  }, [haveAccess]);

  const { ern } = useAppSelector(selectAuth) || {};

  // for asso-subsid PoC usage only
  const decodeJwtToken = async () => {
    const result = await getAccessToken();
    return jwt_decode(result);
  };

  // for asso-subsid PoC usage only
  decodeJwtToken()
    .then((decodedToken) => {
      console.log('Decoded Token for PoC usage only:', decodedToken);
    })
    .catch((error) => {
      console.error('Error decoding token:', error);
    });

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline enableColorScheme />

      <ErrorBoundary
        FallbackComponent={ErrorBoundaryComponent}
        onError={(error: Error, info: { componentStack: string }) => {
          ReactGA.event({
            category: GOOGLE_ANALYTICS_EVENTS.category.appError,
            action: GOOGLE_ANALYTICS_EVENTS.category.appError,
            label: `${ern.replace(/\w(?=\w{5})/g, '*')} ${JSON.stringify(error.message)} ${JSON.stringify(info)}`,
          });
        }}
      >
        {isLogin ? (
          haveAccess === true ? (
            <UserAuthorizedComponent />
          ) : haveAccess === false ? (
            <UserNotAuthorizedComponent />
          ) : (
            <ConnectComponent />
          )
        ) : null}
      </ErrorBoundary>
    </ThemeProvider>
  );
};

function ErrorComponent({ error }: MsalAuthenticationResult) {
  return (
    <div style={{ width: '600px', padding: '10px' }}>
      Login error
      <br />
      Please try refreshing the page.
      <br />
      If problem persists, please contact support.
      <br />
      <br />
      <p style={{ width: '500px', border: '1px solid', padding: '10px' }}>{error?.message}</p>
    </div>
  );
}

function LoadingComponent() {
  return <div style={{ width: '600px', padding: '10px' }}>Authentication check in progress...</div>;
}

const Main = () => {
  const match = useRouteMatch('/logout'); // currently not used, user are redirected to "/"
  const { isLogin } = useAppSelector(selectAuth);
  const [isAssoLogin, setIsAssoLogin] = useState<boolean>(false);

  if (match) {
    return (
      <div style={{ width: '600px', padding: '10px' }}>
        Goodbye. You are logout of the system.
        <br />
        If you wish to login to the system again, please the below login button.
        <br />
        <button
          style={{ width: '100px', border: '1px solid', padding: '10px' }}
          onClick={() => msalInstance.loginRedirect(loginRequest)}
        >
          Login
        </button>
      </div>
    );
  }

  // function to handle Asso Subsid Login
  const handleAssoSubsidLogin = () => {
    setIsAssoLogin(true);
    sessionStorage.setItem(LOGIN_MODE.key, LOGIN_MODE.values.assoSubsid);
  };

  const assoSubsidLogin = useRouteMatch('/asso-subsid');
  // Two condition will trigger the following logic
  // 1. User not yet login
  // 2. User enter the path match /asso-subsid
  if (!isLogin && assoSubsidLogin) {
    // use useState to control the UI render
    return isAssoLogin ? (
      <>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          errorComponent={ErrorComponent}
          loadingComponent={LoadingComponent}
          authenticationRequest={loginRequest}
        >
          <Router>
            <App />
          </Router>
        </MsalAuthenticationTemplate>
      </>
    ) : (
      <AssoSubsidLogin onBtnClick={handleAssoSubsidLogin} />
    );
  }

  if (window.config.isSystemMaintenance) {
    return (
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline enableColorScheme />

        <SystemMaintenanceComponent />
      </ThemeProvider>
    );
  }

  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        errorComponent={ErrorComponent}
        loadingComponent={LoadingComponent}
        authenticationRequest={loginRequest}
      >
        <Router>
          <App />
        </Router>
      </MsalAuthenticationTemplate>
    </>
  );
};

if (window.config.googleAnalyticsMeasurementId) {
  ReactGA.initialize(window.config.googleAnalyticsMeasurementId);
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistStore(store)}>
        <MsalProvider instance={msalInstance}>
          <Router>
            <Main />
          </Router>
        </MsalProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
