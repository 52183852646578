import CathaySansRegular from '../assets/fonts/CathaySans_WSyst_Rg.woff';
import CathaySansLight from '../assets/fonts/CathaySans_WSyst_It.woff';
import CathaySansBold from '../assets/fonts/CathaySans_WSyst_Bd.woff';
import CathaySansMedium from '../assets/fonts/CathaySans_WSyst_Md.woff';
import { CATHAY_FONT_NAME, FONT_WEIGHT, UNICODE_RANGE } from '../constants/font';

// mui theme style override does not support font face array:  https://github.com/mui-org/material-ui/issues/24966

const light = `{
  font-family: '${CATHAY_FONT_NAME}';
  font-style: 'normal';
  font-weight: ${FONT_WEIGHT.light};
  src: url(${CathaySansLight}) format('woff');
  unicodeRange: ${UNICODE_RANGE};
}`;

const regular = `{
  font-family: '${CATHAY_FONT_NAME}';
  font-style: 'normal';
  font-weight: ${FONT_WEIGHT.regular};
  src: url(${CathaySansRegular}) format('woff');
  unicodeRange: ${UNICODE_RANGE};
}`;

const medium = `{
  font-family: '${CATHAY_FONT_NAME}';
  font-style: 'normal';
  font-weight: ${FONT_WEIGHT.medium};
  src: url(${CathaySansMedium}) format('woff');
  unicodeRange: ${UNICODE_RANGE};
}`;

const bold = `{
  font-family: '${CATHAY_FONT_NAME}';
  font-style: 'normal';
  font-weight: ${FONT_WEIGHT.bold};
  src: url(${CathaySansBold}) format('woff');
  unicodeRange: ${UNICODE_RANGE};
}`;

export { light, regular, medium, bold };
