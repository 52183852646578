import { IconButton, useTheme, Box } from '@mui/material';

import { FilterIcon } from '../../assets/images';

import { getFilterCount } from '../../helpers';

import { IFilterData } from '../../interfaces';

import { FilterCount } from '../../components';

const SearchFilterIconWithCount = ({
  onClick,
  Icon = FilterIcon,
  data,
}: {
  onClick: () => void;
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  data: IFilterData;
}) => {
  const theme = useTheme();

  return (
    <IconButton
      sx={{
        height: '48px',
        width: '48px',
        backgroundColor: 'white',
        borderRadius: 1,
        position: 'relative',
        boxShadow: theme.boxShadow.important,
        '&:hover': {
          backgroundColor: 'white',
        },
      }}
      onClick={onClick}
    >
      <Icon fill={theme.color.utility.link.option_3} />
      <Box sx={{ position: 'absolute', top: '-10px', right: '-10px' }}>
        <FilterCount count={getFilterCount(data)} />
      </Box>
    </IconButton>
  );
};

export default SearchFilterIconWithCount;
