import React, { useContext, useState } from 'react';

import en from '../../../translations/en';
import { DATE_FORMAT } from '../../../constants/constants';
import { AlertIcon } from '../../../assets/images';

import { IRouteToProps } from '../../../interfaces';

import { formatDateAsString } from '../../../helpers';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setStartDate, setDestination } from '../../../slice/bookingSlice';
import { selectApp } from '../../../slice/appSlice';
import { BookingSummaryAction } from '../../../context';

import { SearchResult } from '..';
import { RebookFlowOverlay, RebookReviewFlight, RebookEditFlightDate, RebookConfirmation } from '.';
import { CancelDialog } from '../../../components';

const RebookFlowContainer = ({ isRebookFromMyBooking }: { isRebookFromMyBooking?: boolean }) => {
  const dispatch = useAppDispatch();
  const { isDesktop } = useAppSelector(selectApp) || {};

  const [routeTo, setRouteTo] = useState<IRouteToProps>({
    step: 0,
    data: {},
  });

  const rebookLabel = en.booking.confirmation.cancelActions.rebook;

  const routePages: any[] = [
    {
      title: isDesktop ? '' : rebookLabel.rebookWithTicket,
      component: RebookEditFlightDate,
      backTitle: rebookLabel.backToBookingDetail,
    },
    {
      title: isDesktop ? '' : 'Zone : CX', // TODO: hardcode now
      component: SearchResult,
      data: {
        isShowEditButton: true,
        isFromRebookFlow: true,
        isRebookFromMyBooking: isRebookFromMyBooking,
        customStyle: {
          titleVariant: 'body_1_medium',
          backIconMt: 0.75,
          titleMt: 0.5,
        },
        backTitle: rebookLabel.backToFlightDetail,
      },
    },
    {
      title: isDesktop ? '' : rebookLabel.rebookWithTicket,
      component: RebookReviewFlight,
      backTitle: rebookLabel.backToSearchResult,
    },
    {
      title: isDesktop ? '' : en.booking.flightConfirmation.reviewBooking,
      component: RebookConfirmation,
      backTitle: rebookLabel.backToRebookDetail,
    },
  ];

  const { step: routeToStep, data: routeToData } = routeTo || {};

  const {
    setOriginalFlightDate,
    setRebookFlightDate,
    setIsShowRebookSearchOverlay,
    setIsOpenRebookFlow,
    setIsOpenRebookCompleted,
  } = useContext(BookingSummaryAction) || {};

  const [showCancelDialog, setShowCancelDialog] = useState(false);

  const closeFlowOverlay = () => {
    dispatch(setStartDate(formatDateAsString(new Date(), DATE_FORMAT.date)));
    dispatch(setDestination(null));
    setOriginalFlightDate(null);
    setRebookFlightDate(null);
    setIsOpenRebookFlow(false);
  };

  const title = routePages[routeTo.step].title;
  const backTitle = routePages[routeTo.step].backTitle;

  return (
    <>
      <RebookFlowOverlay
        title={title}
        backTitle={backTitle}
        showOverlay={true}
        isShowBackArrow={true}
        handleClose={() => {
          if (routeToStep > 0) {
            setRouteTo((prev) => {
              return {
                step: prev.step - 1,
              };
            });
          } else {
            closeFlowOverlay();
          }
        }}
        {...routePages[routeToStep]}
        data={{
          gotoCompleted: () => {
            setIsOpenRebookFlow(false);
            setIsOpenRebookCompleted(true);
          },
          ...routePages[routeToStep].data,
          ...routeToData,
          routeToStep,
          handleContinueClick: () => {
            setRouteTo((prev) => {
              return {
                step: prev.step + 1,
              };
            });
          },
          handleCancelClick: () => {
            setShowCancelDialog(true);
          },
          handleEditClick: () => {
            setIsShowRebookSearchOverlay(true);
          },
          handleCloseClick: () => {
            closeFlowOverlay();
          },
          handleBackClick: () => {
            if (routeToStep > 0) {
              setRouteTo((prev) => {
                return {
                  step: prev.step - 1,
                };
              });
            } else {
              closeFlowOverlay();
            }
          },
        }}
      />

      {/* open cancel rebook confirmation popup */}
      <CancelDialog
        open={showCancelDialog}
        IconComponent={<AlertIcon />}
        handleConfirmAction={() => {
          closeFlowOverlay();
        }}
        handleCloseDialog={() => {
          setShowCancelDialog(false);
        }}
        dialogContent={{
          title: rebookLabel.cancelChanges,
          message: rebookLabel.confirmCancel,
          yesTitle: en.common.yesConfirm,
          noTitle: en.common.back,
        }}
      />
    </>
  );
};

export default RebookFlowContainer;
