import React, { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';

import { CheckMarkIcon } from '../../assets/images';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectApp, setSnackBarData } from '../../slice/appSlice';

import { SnackbarComponent } from '..';

const BaseSnackbar = () => {
  const [open, setOpen] = useState(false);

  const dispatch = useAppDispatch();

  const { snackBarData } = useAppSelector(selectApp, shallowEqual) || {};

  useEffect(() => {
    const { isShow, delay } = snackBarData || {};
    if (isShow) {
      if (delay) {
        setTimeout(() => {
          setOpen(true);
        }, delay);
      } else {
        setOpen(true);
      }
    }
  }, [snackBarData]);

  const handleClose = () => {
    setOpen(false);
    dispatch(setSnackBarData(null));
  };

  return (
    <SnackbarComponent
      open={open}
      toastNotification={snackBarData?.message}
      IconComponent={<CheckMarkIcon />}
      handleClose={handleClose}
    />
  );
};

export default BaseSnackbar;
