import { Dispatch } from 'redux';
import { createBillingAgreementToken } from '../services/paypal';
import { setCurrentUrl, setTokenId } from '../slice/paypalSlice';

import { getCurrentHostNameAndUrl } from './common';

const createBillingAgreementTokenAction = async ({ ern, dispatch }: { ern: string; dispatch: Dispatch }) => {
  const { currentHostName, currentUrl } = getCurrentHostNameAndUrl();

  dispatch(setCurrentUrl(currentUrl));

  const response = await createBillingAgreementToken(ern, currentHostName);

  if (response) {
    const { approvalUrl, tokenId } = response;

    if (approvalUrl && tokenId) {
      dispatch(setTokenId(tokenId));
      window.open(approvalUrl, '_self');
    }
  }
};

export { createBillingAgreementTokenAction };
