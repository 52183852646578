import React, { useEffect } from 'react';

import en from '../../../translations/en';
import { LABEL_CATEGORY, DATE_FORMAT } from '../../../constants/constants';

import { IInformationListItem } from '../../../containers/UserProfile/Common/InformationList';

import {
  getDisplayLabel,
  findDropdownOptionClient,
  formatDateAsString,
  getDisplayVal,
  getDisplayName,
} from '../../../helpers';

import { selectAuth } from '../../../slice/authSlice';
import { selectUser, getProfileThunk } from '../../../slice/userSlice';
import { selectConfiguration } from '../../../slice/configurationSlice';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';

import { InformationList } from '../../../containers';

const BasicDetailsContainer = () => {
  const dispatch = useAppDispatch();
  const { profile } = useAppSelector(selectUser);
  const { ern: ernFromLogin } = useAppSelector(selectAuth) || {};
  const { configurations } = useAppSelector(selectConfiguration);

  useEffect(() => {
    dispatch(getProfileThunk(ernFromLogin));
    return () => {};
  }, [dispatch]);

  const {
    employeeId,
    personalInfo,
    company,
    companySeniorityDate,
    activeBenefitDTJobLevel,
    activeBenefitLTJobLevel,
    jobLevel,
    costCentre,
  } = profile || {};

  const { firstName, lastName, middleName, countryOfResidential } = personalInfo || {};

  const listDataBasicInfo: IInformationListItem[] = [
    {
      key: en.userProfile.personal.basicInfo.firstName,
      value: getDisplayName({ firstName, middleName }),
    },
    {
      key: en.userProfile.personal.basicInfo.lastName,
      value: getDisplayVal(lastName),
    },
    {
      key: en.userProfile.personal.countryOfResidence,
      value: findDropdownOptionClient(configurations.countries, countryOfResidential || undefined)?.label,
    },
    {
      key: en.userProfile.personal.basicInfo.ern,
      value: getDisplayVal(employeeId),
    },
    {
      key: en.userProfile.personal.basicInfo.company,
      value: getDisplayLabel(configurations.labels, LABEL_CATEGORY.company, company),
    },
    {
      key: en.userProfile.personal.basicInfo.companySeniorityDate,
      value: formatDateAsString(getDisplayVal(companySeniorityDate), DATE_FORMAT.ddmmmyyyy, DATE_FORMAT.date),
    },
    {
      key: en.userProfile.personal.basicInfo.benefitDutyJobLevel,
      value: getDisplayVal(`${en.userProfile.personal.basicInfo.level} ${activeBenefitDTJobLevel || jobLevel}`),
    },
    {
      key: en.userProfile.personal.basicInfo.costCentre,
      value: getDisplayVal(costCentre),
    },
    {
      key: en.userProfile.personal.basicInfo.benefitLeisureJobLevel,
      value: `${en.userProfile.personal.basicInfo.level} ${activeBenefitLTJobLevel || jobLevel}`,
    },
    //TODO: Release 2 add back leisure travel job level
    // {
    //   key: en.userProfile.personal.basicInfo.benefitLeisureJobLevel,
    //   value: `${en.userProfile.personal.basicInfo.level} ${
    //     activeBenefitLTJobLevel || jobLevel
    //   }`,
    // },
    // {
    //   key: en.userProfile.personal.basicInfo.leisureTravelTicketingStatus,
    //   value: activeBenefitLTJobLevel ? en.common.active : en.common.inactive,
    // },
  ];

  //TODO: Relase 2 add back payment info as a new page
  // const PaymentInfo: IInformationListItem[] = [
  //   {
  //     key: en.userProfile.personal.paymentInfo.paymentConcept,
  //     value: getDisplayLabel(
  //       configurations.labels,
  //       LABEL_CATEGORY.paymentConcept,
  //       paymentConcept
  //     ),
  //   },
  //   {
  //     key: en.userProfile.personal.paymentInfo.paymentMedium,
  //     value: paymentMedium,
  //   },
  // ];

  return (
    <InformationList title={en.userProfile.personal.basicInfo.title} listData={listDataBasicInfo} />
    /* <InformationList
        title={en.userProfile.personal.paymentInfo.title}
        listData={PaymentInfo}
      /> */
  );
};

export default BasicDetailsContainer;
