import { Box, Typography, useTheme, SxProps, Theme, Checkbox } from '@mui/material';

import en from '../../../translations/en';
import {
  CheckBoxCheckIcon,
  CheckBoxIcon,
  CheckCircleIcon,
  CheckCircleOutlineIcon,
  SeatFlightIcon,
  SeatInfantClassIcon,
} from '../../../assets/images';

import { WithTextSwitch } from '../../../components';
import { InfantLabel, LtTravellerWarning } from '../..';
import LTAccompanyForm from '../SelectTraveller/LtAccompanyForm';

const BaseTravellerSelection = ({
  title,
  subTitle,
  isSelected,
  isInfant,
  isInfantWithSeat,
  canProceed, // unused currently. for integrate with LT select traveller page later.
  isOnlyInfantOrChild,
  handleSelected,
  checkStatus,
  handleOnChanged,
  handleToggled,
  isLTSelectTravellerBookingStep = false, // for LT booking flow > select traveller page used.
  isDisplayInfantOrChild = false,
  accompanyAdultTitle = '',
  customStyles,
}: {
  title: string;
  subTitle: string;
  isSelected: boolean;
  isInfant: boolean;
  isInfantWithSeat: boolean;
  canProceed: boolean;
  isOnlyInfantOrChild?: boolean;
  handleSelected: () => void;
  checkStatus: boolean;
  handleOnChanged: () => void;
  handleToggled?: () => void;
  isLTSelectTravellerBookingStep?: boolean;
  isDisplayInfantOrChild?: boolean;
  accompanyAdultTitle?: string;
  customStyles?: SxProps<Theme>;
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        minHeight: '76px',
        mt: 1,
        background: !isLTSelectTravellerBookingStep ? theme.color.etp.option_1 : theme.palette.text.primary,
        borderRadius: 1,
        p: 2,
        ...customStyles,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: isLTSelectTravellerBookingStep ? 'center' : 'flex-start',
          '&:active': {
            background: 'transparent',
          },
        }}
      >
        {!isLTSelectTravellerBookingStep && (
          <>
            {isInfant ? (
              <SeatInfantClassIcon
                height={28}
                width={28}
                fill={theme.color.etp.option_2}
                style={{ marginRight: '8px' }}
              />
            ) : (
              <SeatFlightIcon fill={theme.color.etp.option_2} style={{ marginRight: '8px' }} />
            )}
          </>
        )}

        <Box
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'center',
            minHeight: title || subTitle ? (isLTSelectTravellerBookingStep ? '44px' : '28px') : '',
            pr: 1,
          }}
        >
          {title && (
            <Typography
              variant={isLTSelectTravellerBookingStep ? 'body_1_regular' : 'body_1_medium'}
              color={theme.color.secondary.dark_grey.option_1}
            >
              {title}
            </Typography>
          )}

          {subTitle && (
            <Typography variant="navigation_regular" color={theme.color.secondary.dark_grey.option_2} sx={{ mt: 0.5 }}>
              {subTitle}
            </Typography>
          )}

          {isInfant && (
            <InfantLabel
              sxProps={{ mt: 1 }}
              isInfantWithSeat={isInfantWithSeat}
              accompanyAdultTitle={accompanyAdultTitle}
            />
          )}

          {/* show from: only left 1 chd/inf */}
          {isOnlyInfantOrChild && isDisplayInfantOrChild && !isSelected && (
            <>
              <LTAccompanyForm
                isKeepData={true}
                isFromCompleted={true}
                customStyles={{
                  textColor: theme.color.secondary.dark_grey.option_2,
                  strokeColor: theme.color.secondary.slate.option_3,
                }}
              />
              <Box sx={{ mt: 2, display: 'flex', flexDirection: 'row' }}>
                <Box sx={{ mt: -1, ml: -1 }}>
                  <Checkbox
                    sx={{
                      '&.MuiCheckbox-root': {
                        color: theme.status.selected,
                      },
                    }}
                    checkedIcon={<CheckBoxCheckIcon fill={theme.status.selected} />}
                    icon={<CheckBoxIcon stroke={theme.status.selected} />}
                    color="info"
                    checked={checkStatus}
                    onChange={handleOnChanged}
                  />
                </Box>

                <Typography
                  variant="body_2_regular"
                  sx={{
                    color: theme.color.secondary.dark_grey.option_1,
                  }}
                >
                  {en.booking.travelType.leisureTravel.consent}
                </Typography>
              </Box>
            </>
          )}
        </Box>

        <Box
          sx={{
            marginTop: isLTSelectTravellerBookingStep ? '' : '4px',
            cursor: 'pointer',
          }}
          {...(!isLTSelectTravellerBookingStep && {
            onClick: handleSelected,
          })}
        >
          {isSelected ? <CheckCircleIcon /> : <CheckCircleOutlineIcon />}
        </Box>
      </Box>

      {isLTSelectTravellerBookingStep && isInfant && isSelected && (
        <Box>
          <Box
            sx={{
              mt: 1.5,
              mb: 1,
              minHeight: '48px',
              borderRadius: 1,
              background: theme.color.secondary.light_slate.option_5,
              display: 'flex',
              alignItems: 'center',
              pr: 2,
            }}
          >
            <Box sx={{ flex: 1 }}>
              <Typography
                variant="body_2_regular"
                sx={{
                  pl: 1.5,
                  color: theme.color.secondary.dark_grey.option_1,
                }}
              >
                {en.booking.travelType.leisureTravel.infantWithSeat}
              </Typography>
            </Box>
            {handleToggled && (
              <WithTextSwitch
                disabled={!isSelected}
                checked={isInfantWithSeat}
                trackColor={theme.color.secondary.dark_grey.option_3}
                onChange={() => {
                  handleToggled && handleToggled();
                }}
              />
            )}
          </Box>

          {!canProceed && <LtTravellerWarning warningMessage={en.booking.travelType.leisureTravel.ensureSufficient} />}

          {!isInfantWithSeat && !canProceed && (
            <LtTravellerWarning warningMessage={en.booking.travelType.leisureTravel.infantWithoutSeatOption} />
          )}
        </Box>
      )}
    </Box>
  );
};

BaseTravellerSelection.defaultProps = {
  isOnlyInfantOrChild: false,
};

export default BaseTravellerSelection;
