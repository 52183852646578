import { Calendar } from 'react-date-range';
import { Box } from '@mui/material';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import '../../assets/css/datePicker.css';

import { DATE_FORMAT } from '../../constants/constants';
import { IDateSinglePickerProps } from '../../interfaces';
import { checkIsDateValid } from '../../helpers';

const DateSinglePicker = ({
  isDesktop,
  defaultDate,
  onChange,
  minDate,
  maxDate,
  months,
  calendarHeight,
  showMonthAndYearDropDown,
}: IDateSinglePickerProps) => {
  const handleSingleDateChange = (date: Date) => {
    onChange(date);
  };

  return (
    <Box id={showMonthAndYearDropDown ? '' : 'noDropDownPicker'} className={isDesktop ? 'desktop' : 'mobile'}>
      <Calendar
        date={defaultDate}
        onChange={handleSingleDateChange}
        minDate={checkIsDateValid(minDate) ? minDate : undefined}
        maxDate={checkIsDateValid(maxDate) ? maxDate : undefined}
        scroll={{
          enabled: !isDesktop,
          calendarHeight,
          monthHeight: 300,
          longMonthHeight: 350,
        }}
        monthDisplayFormat={DATE_FORMAT.mmmmyyyy}
        showDateDisplay={false}
        direction={isDesktop ? 'horizontal' : 'vertical'}
        months={months}
        weekStartsOn={0}
        showPreview={false}
        weekdayDisplayFormat="EEEEE"
        showMonthAndYearPickers={showMonthAndYearDropDown}
      />
    </Box>
  );
};

export default DateSinglePicker;
