import React, { useState, useEffect, useContext } from 'react';
import { Box, IconButton, Typography, useTheme, Popper } from '@mui/material';
import { use100vh } from 'react-div-100vh';

import { DATE_FORMAT, UI_STYLES } from '../../constants/constants';
import en from '../../translations/en';

import { IAdminToggleBarTabListProps, IDependentForAdmin } from '../../interfaces';

import { formatDateAsString, getAdminPersonalDisplayName, getDisplayVal } from '../../helpers';

import { AdminAccessEmployeeState, AdminAccessEmployeeAction } from '../../context';

import { getEmployee, getSuggestion, getNotes } from '../../services/admin/employee';

import { ScrollableView, AutoCompleteBox } from '../../components';
import {
  AdminNotes,
  AdminInformationBar,
  AdminPersonalInfoDetails,
  AdminToggleButtonGroup,
  AdminDependents,
  AdminEmployeeList,
  NominationHistory,
  AdminConcession,
} from '../../containers/AdminMaintainEmployeeDetail';

const AdminEmployee = () => {
  const screenHeight = use100vh();
  const theme = useTheme();
  const [adminMaintainId, setAdminMaintainId] = useState(0);
  const [ernValue, setErnValue] = useState('');
  const [galaCXyIdValue, setGalaCXyIdValue] = useState('');
  const [suggestionList, setSuggestionList] = useState<any[] | null>(null);
  const [searchResult, setSearchResult] = useState<any[] | null>(null);
  const [onSelect, setOnSelect] = useState<boolean>(false);
  const [previewProfile, setPreviewProfile] = useState(null);
  const [searchTips, setSearchTips] = useState('');
  const { employeeDetail } = useContext(AdminAccessEmployeeState) || {};
  const { setEmployeeDetail, setEmployeeNotes, setDependent, setConcessionGrouping } =
    useContext(AdminAccessEmployeeAction) || {};

  const { personalInformation, dependents, note, nominationHistory, concession, admin } = en.admin.adminToggleList;

  const paperStyles = {
    width: '240px',
    position: 'absolute',
    left: '-16px',
    mt: 0.5,
  };

  const textFieldStyle = {
    height: '50px',
    borderRadius: 0.5,
    background: 'white',
    border: `1px solid ${theme.color.secondary.slate.option_3}`,
  };

  // TODO The component property will use correct component replace
  const firstToggleBarTabList: IAdminToggleBarTabListProps[] = [
    {
      id: 0,
      title: personalInformation,
      component: <AdminPersonalInfoDetails defaultId={6} />,
    },
    {
      id: 1,
      title: dependents,
      component: <AdminDependents />,
    },
    {
      id: 2,
      title: note,
      component: <AdminNotes />,
    },
    {
      id: 3,
      title: nominationHistory,
      component: <NominationHistory />,
    },
    {
      id: 4,
      title: concession,
      component: <AdminConcession />,
    },
    {
      id: 5,
      title: admin,
      component: <></>,
    },
  ];

  const getSuggestionList = async (payload: { userId?: string; galaCXyId?: string }) => {
    const suggestionResult = (await getSuggestion(payload)) || [];
    const suggestionOption = suggestionResult.map((item: string) => {
      return { label: item, code: item };
    });
    setSuggestionList(suggestionOption);
  };

  const handleFetchEmployee = async (searchValue?: { userId?: string; galaCXyId?: string }) => {
    const { userId, galaCXyId } = searchValue || {
      userId: ernValue,
      galaCXyId: galaCXyIdValue,
    };

    if (userId) {
      if (userId.length < 5) {
        setSearchResult(null);
        setSearchTips(en.admin.feature.maintainEmployeeDetails.incompleteErn);
        return;
      }
      searchValue ??= { userId: ernValue };
    }
    if (galaCXyId) {
      if (galaCXyId.length < 5) {
        setSearchResult(null);
        setSearchTips(en.admin.feature.maintainEmployeeDetails.incompleteGalaCXyId);
        return;
      }
      searchValue ??= { galaCXyId: galaCXyIdValue };
    }

    if (searchValue) {
      const employeeList = await getEmployee(searchValue);
      setEmployeeDetail(null);
      setAdminMaintainId(0);
      setSearchResult(employeeList);
    }
  };

  const dropDownListPop = (props: any) => {
    if (suggestionList && suggestionList.length > 0) {
      return <Popper {...props} placement="bottom-start" />;
    }
    return null;
  };

  const updateNotes = async () => {
    const notesData = await getNotes({
      userId: employeeDetail.profile.employeeId,
      profileRevision: employeeDetail.profile.revision,
    });
    setEmployeeNotes(notesData);
  };

  useEffect(() => {
    setEmployeeDetail(null);
  }, []);

  useEffect(() => {
    if (searchResult) {
      if (searchResult?.length > 0) {
        setSearchTips('');
        const tempPreviewProfile = searchResult?.find((item) => item.profile.isAccessible);
        setPreviewProfile(tempPreviewProfile);
        if (searchResult?.length === 1) {
          // Directly display the profile detail if user searches for an ERN or GalaCXy ID and there is single revision of the profile returned
          const employee = searchResult?.[0];
          if (employee.profile.isAccessible) {
            setEmployeeDetail(employee);
          }

          // initialize the dependentList from employee.nominationList
          if (employee.nominationList) {
            const tmpDependentList = employee.nominationList.map((dependent: any) => {
              const {
                age,
                beneficiaryType,
                dateOfBirth,
                dependentId,
                firstName,
                gender,
                isActive,
                isNominated,
                lastName,
                middleName,
                nominationFrom,
                nominationTo,
                passportCountry,
                passportExpirationDate,
                passportName,
                passportNationality,
                passportNumber,
                relationship,
                title,
                validFrom,
                validTo,
              } = dependent || {};
              return {
                dependentId,
                name: getAdminPersonalDisplayName({
                  lastName,
                  firstName,
                  middleName,
                }),
                relationship,
                age,
                nominationFrom: formatDateAsString(nominationFrom, DATE_FORMAT.ddmmmyyyy),
                nominationTo: formatDateAsString(nominationTo, DATE_FORMAT.ddmmmyyyy),
                nominationStatus: isNominated ? en.admin.dependents.currentNominee : en.admin.dependents.notNominated,
                profileDetails: {
                  dependentType: beneficiaryType,
                  title,
                  firstName,
                  middleName,
                  surname: lastName,
                  relationship,
                  gender,
                  dateOfBirth: formatDateAsString(dateOfBirth, DATE_FORMAT.ddmmmyyyy),
                  validFrom: formatDateAsString(getDisplayVal(validFrom), DATE_FORMAT.ddmmmyyyy),
                  validTo: formatDateAsString(getDisplayVal(validTo), DATE_FORMAT.ddmmmyyyy),
                  dependentStatus: isActive,
                },
                passportDetails: {
                  passportNumber,
                  firstOtherNameAsInPassport: passportName?.firstName,
                  surnameAsInPassport: passportName?.lastName,
                  passportExpiryDate: formatDateAsString(passportExpirationDate, DATE_FORMAT.ddmmmyyyy),
                  nationalityInPassport: passportNationality,
                  issuedCountry: passportCountry,
                },
                isActive,
                isNominated,
              } as IDependentForAdmin;
            });

            setDependent(tmpDependentList);
          }
        }
      } else {
        setSearchTips(`${en.booking.travelType.noErnResult} ${en.booking.travelType.tryAgain}`);
      }
    }
  }, [searchResult]);

  useEffect(() => {
    if (ernValue.length > 4) {
      !suggestionList && getSuggestionList({ userId: ernValue });
    } else {
      suggestionList && setSuggestionList(null);
    }
  }, [ernValue]);

  useEffect(() => {
    if (galaCXyIdValue.length > 4) {
      !suggestionList && getSuggestionList({ galaCXyId: galaCXyIdValue });
    } else {
      suggestionList && setSuggestionList(null);
    }
  }, [galaCXyIdValue]);

  return (
    <Box
      component={ScrollableView}
      sx={{
        pt: 2,
        pb: 10,
        background: theme.color.secondary.light_slate.option_7,
        height: `calc(${screenHeight}px - ${UI_STYLES.desktopHeaderHeight} - ${UI_STYLES.indicatorBarHeight} )`,
      }}
    >
      {/* Page title & Search form */}
      <Box sx={{ width: UI_STYLES.adminMaintainWidth, mx: 'auto', mt: 2 }}>
        <Typography variant="large_title_1_bold" color={theme.color.secondary.dark_grey.option_1}>
          {en.admin.feature.employeeProfile}
        </Typography>
        <Box
          className={'adminSearchBox'}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            mt: 5,
          }}
        >
          <Box sx={{ mr: 2, width: '240px', height: '50px' }}>
            <AutoCompleteBox
              label={en.admin.feature.maintainEmployeeDetails.ern}
              options={suggestionList || []}
              value={ernValue}
              inputValue={ernValue}
              noOptionsText={en.common.noMatchResult}
              onChange={(newValue: { code: string } | null): void => {
                setGalaCXyIdValue('');
                const upperValue = newValue?.code ? newValue.code.toUpperCase() : '';
                setErnValue(upperValue);
                upperValue && handleFetchEmployee({ userId: upperValue });
              }}
              onInputChange={(newInputValue: any) => {
                setGalaCXyIdValue('');
                setErnValue(newInputValue.toUpperCase());
              }}
              onKeyDown={() => {
                if (!onSelect) {
                  ernValue && handleFetchEmployee();
                }
              }}
              onHighlightChange={(event) => {
                event && setOnSelect(true);
              }}
              onClose={() => {
                setOnSelect(false);
              }}
              dropDownListPop={(props: any) => (ernValue ? dropDownListPop(props) : null)}
              renderOption={undefined}
              filterOptions={(array) =>
                array.filter((item: { code: string; label: string }) => {
                  return item.label.includes(ernValue);
                })
              }
              customStyles={{
                pl: 0,
                '.MuiInputLabel-root': {
                  '&.Mui-focused': {
                    color: theme.color.secondary.grey.option_3,
                  },
                },
              }}
              paperStyles={paperStyles}
              textFieldStyle={textFieldStyle}
            ></AutoCompleteBox>
          </Box>
          <Box sx={{ mr: 2, width: '240px', height: '50px' }}>
            <AutoCompleteBox
              label={en.admin.feature.maintainEmployeeDetails.galaCXyId}
              options={suggestionList || []}
              value={galaCXyIdValue}
              inputValue={galaCXyIdValue}
              noOptionsText={en.common.noMatchResult}
              onChange={(newValue: { code: string } | null): void => {
                setErnValue('');
                const upperValue = newValue?.code ? newValue.code.toUpperCase() : '';
                setGalaCXyIdValue(upperValue);
                upperValue && handleFetchEmployee({ galaCXyId: upperValue });
              }}
              onInputChange={(newInputValue: any) => {
                setErnValue('');
                setGalaCXyIdValue(newInputValue.toUpperCase());
              }}
              onKeyDown={() => {
                if (!onSelect) {
                  galaCXyIdValue && handleFetchEmployee();
                }
              }}
              onHighlightChange={(event) => {
                event && setOnSelect(true);
              }}
              onClose={() => {
                setOnSelect(false);
              }}
              dropDownListPop={(props: any) => (galaCXyIdValue ? dropDownListPop(props) : null)}
              renderOption={undefined}
              filterOptions={(array) =>
                array.filter((item: { code: string; label: string }) => {
                  return item.label.includes(galaCXyIdValue);
                })
              }
              customStyles={{
                pl: 0,
                '.MuiInputLabel-root': {
                  '&.Mui-focused': {
                    color: theme.color.secondary.grey.option_3,
                  },
                },
              }}
              paperStyles={paperStyles}
              textFieldStyle={textFieldStyle}
            ></AutoCompleteBox>
          </Box>
          <IconButton
            sx={{ p: 0 }}
            onClick={() => {
              handleFetchEmployee();
            }}
          >
            <Typography
              variant="body_2_bold"
              sx={{
                width: '120px',
                height: '50px',
                lineHeight: '50px',
                background: theme.color.utility.link.option_3,
                borderRadius: 1,
                color: theme.palette.bgColor.main,
                textAlign: 'center',
              }}
            >
              {en.common.search}
            </Typography>
          </IconButton>
        </Box>
      </Box>
      {employeeDetail ? (
        <>
          <Box sx={{ width: UI_STYLES.adminMaintainWidth, mx: 'auto', mt: 4 }}>
            <AdminInformationBar />
          </Box>
          <Box sx={{ width: UI_STYLES.adminMaintainWidth, mx: 'auto', mt: 4 }}>
            <AdminToggleButtonGroup
              list={firstToggleBarTabList}
              onClick={(e: number) => {
                setAdminMaintainId(e);
                // TODO: extra the logic into select function(no useState)
                if (e === 2) updateNotes();
                if (e === 4) {
                  setConcessionGrouping(null);
                }
              }}
              defaultValue={0}
            />
          </Box>
          <Box sx={{ width: UI_STYLES.adminMaintainWidth, mx: 'auto', mt: 2 }}>
            {/* show tab match with component */}
            {firstToggleBarTabList[adminMaintainId].component}
          </Box>
        </>
      ) : searchResult && searchResult?.length > 0 ? (
        <Box sx={{ width: UI_STYLES.adminMaintainWidth, mx: 'auto', mt: 4 }}>
          {previewProfile && <AdminInformationBar employee={previewProfile} />}
          <AdminEmployeeList
            dataList={searchResult}
            handleRowDataOnClick={({ employee }) => {
              if (employee.profile.isAccessible) {
                setEmployeeDetail(employee);
              }
            }}
          />
        </Box>
      ) : (
        searchTips && (
          <Typography variant="body_2_regular" sx={{ width: UI_STYLES.adminMaintainWidth, mx: 'auto', mt: 4 }}>
            {searchTips}
          </Typography>
        )
      )}
    </Box>
  );
};

export default AdminEmployee;
