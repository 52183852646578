import { useContext } from 'react';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { use100vh } from 'react-div-100vh';

import en from '../../../translations/en';
import { UI_STYLES } from '../../../constants/constants';
import { CloseIcon } from '../../../assets/images';

import { getFilterCount, getInputDateAdding, getLocalDate } from '../../../helpers';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectApp } from '../../../slice/appSlice';
import { setIsShowPaymentHistoryDialog } from '../../../slice/userSlice';
import { EditPaymentHistoryAction, EditPaymentHistoryState } from '../../../context/PaymentHistoryContext';

import { ReduxFormDatePicker, ScrollableView, FilterCount } from '../../../components';

const PaymentHistorySearchDetails = () => {
  const theme = useTheme();
  const screenHeight = use100vh();
  const dispatch = useAppDispatch();

  const { isDesktop } = useAppSelector(selectApp) || {};

  const editPaymentHistorySearchData = useContext(EditPaymentHistoryState) || {};
  const { editPeriod } = editPaymentHistorySearchData || {};
  const { from: editFrom, to: editTo } = editPeriod || {};
  const { setEditPeriod } = useContext(EditPaymentHistoryAction) || {};

  return (
    <>
      <Box
        sx={{
          px: 2,
          pt: 3,
          bgcolor: '#FDFDFD',
          borderTopLeftRadius: '24px',
          borderTopRightRadius: '24px',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              mb: 1.5,
            }}
          >
            <Box>
              <Typography
                variant={isDesktop ? 'title_2_bold' : 'headline_medium'}
                sx={{
                  mr: 1,
                  color: theme.palette.gray.dark,
                }}
              >
                {en.common.search}
              </Typography>
            </Box>
            <FilterCount count={getFilterCount(editPaymentHistorySearchData)} />
          </Box>
          {isDesktop && (
            <Box>
              <IconButton onClick={() => dispatch(setIsShowPaymentHistoryDialog(false))} sx={{ p: 0 }}>
                <CloseIcon fill={theme.color.utility.link.option_3} />
              </IconButton>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        component={ScrollableView}
        sx={{
          // 26px: overlayHeaderHeightSearchCriteria's space | 50px: Back button height with space
          height: `calc(${screenHeight}px - ${UI_STYLES.overlayHeaderHeightBookingFlow} - ${UI_STYLES.footerHeight} - 32px)`,
          backgroundColor: 'transparent',
          px: 2,
        }}
      >
        <Box
          sx={{
            mb: 1,
            borderRadius: 1,
            backgroundColor: 'white',
          }}
        >
          <>
            <Typography
              sx={{ mb: 1, textAlign: 'start' }}
              variant="body_1_medium"
              color={theme.color.secondary.dark_grey.option_3}
            >
              {en.booking.filter.travelPeriod}
            </Typography>
            <ReduxFormDatePicker
              show={open}
              title={en.common.from}
              calendarFootButtonText={en.common.confirm}
              input={{
                name: 'from',
                value: editFrom || null,
              }}
              minDate={(editTo && getInputDateAdding(editTo, -1, 'year')) || null}
              maxDate={getLocalDate()}
              onChange={(date: Date) => {
                // handle date from range-picker
                if (setEditPeriod) {
                  if (!editTo) {
                    setEditPeriod({ from: date, to: date });
                  } else {
                    setEditPeriod({ ...editPeriod, ...{ from: date } });
                  }
                }
              }}
            />
            <ReduxFormDatePicker
              show={open}
              title={en.common.to}
              calendarFootButtonText={en.common.confirm}
              input={{
                name: 'to',
                value: editTo || null,
              }}
              minDate={(editTo && getInputDateAdding(editTo, -1, 'year')) || null} // minDate
              maxDate={getLocalDate()}
              onChange={(date: Date) => {
                // handle date from range-picker
                setEditPeriod && setEditPeriod({ ...editPeriod, ...{ to: date } });
              }}
            />
          </>
        </Box>
      </Box>
    </>
  );
};

export default PaymentHistorySearchDetails;
