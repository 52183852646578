import React, { useEffect, useRef } from 'react';
import { Grid, Box, useTheme, Popper, SxProps, Theme, TextField, Fade } from '@mui/material';
import { useAppSelector } from '../../app/hooks';
import { selectApp } from '../../slice/appSlice';
import { FONT_WEIGHT } from '../../constants/font';

interface IFormPopoverProps {
  label?: string;
  component?: JSX.Element | any;
  value?: string;
  isDisabled?: boolean;
  onChange?: (date: Date) => void;
  style?: SxProps<Theme>;
  performShowPopup?: boolean;
}

const FormPopover = ({ label, value, style, component, isDisabled, performShowPopup }: IFormPopoverProps) => {
  const popoverRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();

  const { isDesktop } = useAppSelector(selectApp) || {};
  const [isOpen, setIsOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  useEffect(() => {
    setIsOpen(false);
    setAnchorEl(null);
  }, [isDesktop]);

  useEffect(() => {
    !component && setIsOpen(false);
  }, [component]);

  useEffect(() => {
    performShowPopup && popoverRef.current?.click();
  }, [performShowPopup]);

  const anchorElBoundRect = anchorEl && anchorEl.getBoundingClientRect();
  const isAnchorElValid = Boolean(anchorElBoundRect && anchorElBoundRect.width !== 0 && anchorElBoundRect.height !== 0);

  return (
    <>
      <Box sx={{ justifyContent: 'center', flex: 1 }}>
        <TextField
          ref={popoverRef}
          label={label}
          variant="standard"
          value={value}
          sx={{
            width: '100%',
            whiteSpace: 'pre',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            color: isDisabled ? 'text.disabled' : theme.color.secondary.dark_grey.option_3,
            cursor: 'pointer',
            '& .MuiFormLabel-root': {
              color: isOpen ? theme.color.utility.link.option_3 : theme.color.secondary.grey.option_3,
              fontSize: theme.typography.body_1_medium.fontSize,
              fontWeight: FONT_WEIGHT.regular,
              mt: 0.5,
              top: '-12px',
              '&.MuiInputLabel-shrink': {
                top: '0px',
              },
            },
            '& label[data-shrink=false]+.MuiInputBase-formControl': {
              '& .MuiInputBase-input': {
                mt: 3,
              },
            },
          }}
          onClick={(event: React.MouseEvent<HTMLDivElement>) => {
            setAnchorEl(event.currentTarget);
            setIsOpen(true);
          }}
          InputLabelProps={{ shrink: !!value || isOpen }}
          InputProps={{
            disableUnderline: true,
            readOnly: true,
            sx: {
              typography: 'body_1_medium',
              color: theme.color.secondary.dark_grey.option_3,
            },
          }}
        />
      </Box>
      {isAnchorElValid && component && (
        <Popper
          open={isAnchorElValid}
          anchorEl={anchorEl}
          placement={'bottom-start'}
          transition
          // drawer z-index is 1200, need to greater than 1200
          sx={{ zIndex: 1300 }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Grid
                sx={{
                  mt: 2.5,
                  ml: -4,
                  zIndex: 50,
                  position: 'absolute',
                  background: 'white',
                  borderRadius: '8px',
                  boxShadow: theme.boxShadow.important,
                  ...style,
                }}
              >
                {component}
              </Grid>
            </Fade>
          )}
        </Popper>
      )}
    </>
  );
};

export default FormPopover;
