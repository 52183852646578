import { useEffect, useState } from 'react';
import { BottomNavigation, BottomNavigationAction, useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { NavBarFlightIcon, NavBarInfoIcon, NavBarProfileIcon } from '../../assets/images';

import en from '../../translations/en';
import { ETPLink } from '..';
import { ROOT_PATHS, USER_PROFILE_PATHS, INFO_PATHS } from '../../constants/paths';

const BottomNavBar = () => {
  const location = useLocation();

  const [value, setValue] = useState(location && location.pathname);

  useEffect(() => {
    const { pathname } = location || {};
    setValue(pathname);
  }, [location]);

  const theme = useTheme();

  const iconDefaultColor = theme.color.secondary.grey.option_3;

  const handleChange = (_: any, newValue: string) => setValue(newValue);

  return (
    <BottomNavigation showLabels onChange={handleChange} value={value} sx={{ height: '50px', minHeight: '50px' }}>
      <BottomNavigationAction
        label={en.menu.home}
        icon={<NavBarFlightIcon fill={iconDefaultColor} />}
        component={ETPLink}
        theme={theme}
        value={ROOT_PATHS.landing}
        to={ROOT_PATHS.landing}
      />
      <BottomNavigationAction
        label={en.menu.info}
        icon={<NavBarInfoIcon fill={iconDefaultColor} />}
        component={ETPLink}
        theme={theme}
        value={INFO_PATHS.info}
        to={INFO_PATHS.info}
      />
      <BottomNavigationAction
        label={en.menu.profile}
        icon={<NavBarProfileIcon fill={iconDefaultColor} />}
        component={ETPLink}
        theme={theme}
        value={USER_PROFILE_PATHS.root}
        to={USER_PROFILE_PATHS.root}
      />
    </BottomNavigation>
  );
};

export default BottomNavBar;
