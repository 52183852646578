import { AUTH_API, HTTP_REQUEST_TYPE, USER_API } from '../constants/api';
import en from '../translations/en';
import { prepaidPaymentHistoryCheckingFlag } from '../config/featureFlags';

import { sendHttpRequest } from './api';

import { SAMPLE_PAYMENT_HISTORY_LOAD_DATA } from '../constants/sampleData';

const getProfile = async (userId: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: USER_API.personalInfo,
    apiTitle: en.errorAlert.getProfile,
    payload: { userId },
  });
  return resp;
};

const getPassport = async (userId: string, expirationDate?: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: USER_API.passportInfo,
    apiTitle: en.errorAlert.getPassport,
    payload: { userId, expirationDate },
  });
  return resp;
};

const getEndorser = async (userId: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: USER_API.endorsingStaff,
    apiTitle: en.errorAlert.getEndorser,
    payload: { userId },
  });
  return resp;
};

const getConcession = async (userId: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: USER_API.concession,
    apiTitle: en.errorAlert.getConcession,
    payload: { userId },
  });
  return resp;
};

const validateEndorser = async (endorsingStaffGalaCXyId: string, userId: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: USER_API.endorsingStaff,
    apiTitle: en.errorAlert.validateEndorser,
    payload: { endorsingStaffGalaCXyId, userId },
  });
  return resp;
};

const changeRole = async (id: string, type: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: AUTH_API.changeRole,
    apiTitle: en.errorAlert.changeRole,
    payload: { id, type },
  });
  return resp;
};

const getRole = async () => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: AUTH_API.getRole,
    apiTitle: en.errorAlert.getRole,
    payload: {},
  });
  return resp;
};

const getPaymentHistory = async (payload: any) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: USER_API.paymentHistory,
    apiTitle: en.errorAlert.paymentHistory,
    payload,
  });

  //TMP
  if (prepaidPaymentHistoryCheckingFlag?.isActive) {
    return SAMPLE_PAYMENT_HISTORY_LOAD_DATA;
  }
  return resp;
};

const getDependent = async (userId: string, expirationDate?: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: USER_API.dependent,
    apiTitle: en.errorAlert.getDependent,
    payload: { userId, expirationDate },
  });
  return resp;
};

const getAllotmentDetails = async (userId: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: USER_API.allotment,
    apiTitle: en.errorAlert.getAllotmentDetails,
    payload: { userId },
  });
  return resp;
};

export {
  getProfile,
  getPassport,
  getEndorser,
  validateEndorser,
  getConcession,
  changeRole,
  getRole,
  getPaymentHistory,
  getDependent,
  getAllotmentDetails,
};
