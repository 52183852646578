import React, { useContext } from 'react';
import { Box, useTheme } from '@mui/material';

import en from '../../translations/en';
import { COMMON_API } from '../../constants/api';

import { checkValidDateRange } from '../../helpers';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectAuth } from '../../slice/authSlice';
import { reset, setPeriod, setTravelTypeFilter } from '../../slice/bookingFilterSlice';
import { EditBookingFilterAction, EditBookingFilterState } from '../../context/BookingFilterContext';

import { getMyBookings } from '../../services/booking';

import { Footer, FormButton } from '../../components';
import ViewBookingDetails from './ViewBookingDetails';

const ViewBookingFilter = ({
  setSearchResult,
  handleFooterClick,
}: {
  setSearchResult: (result: string[] | null | undefined) => void;
  handleFooterClick?: () => void;
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { ern } = useAppSelector(selectAuth) || {};

  const editBookingFilter = useContext(EditBookingFilterState) || {};
  const { editTravelTypes, editPeriod } = editBookingFilter || {};
  const { from, to } = editPeriod || {};

  const editBookingAction = useContext(EditBookingFilterAction) || {};
  const { resetBookingFilter } = editBookingAction || {};
  const isApplyButtonValid = !!from || !!to ? !!from && !!to && checkValidDateRange(from, to) : true;

  return (
    <Box>
      <ViewBookingDetails />

      <Footer
        leftChild={
          <FormButton
            theme={theme}
            colour="transparent"
            size="large"
            sx={{
              pl: 0,
              height: '44px',
              display: 'flex',
              justifyContent: 'center',
              ...{ minWidth: 0 },
            }}
            onClick={() => {
              resetBookingFilter();
              dispatch(reset());
            }}
          >
            {en.common.clear}
          </FormButton>
        }
        primaryBtn={
          isApplyButtonValid
            ? {
                text: en.common.apply,
                customButtonStyles: {
                  marginRight: 0,
                  width: '128px',
                  minWidth: 'none',
                },
                customOnClick: async () => {
                  dispatch(setTravelTypeFilter(editTravelTypes));
                  dispatch(setPeriod(editPeriod));

                  const myBookings = await getMyBookings({
                    userId: ern,
                    from: 0,
                    path: COMMON_API.myBooking,
                    startDate: editPeriod?.from,
                    endDate: editPeriod?.to,
                    type: JSON.stringify(editTravelTypes),
                    isSearchAndFilter: true,
                  });
                  setSearchResult(myBookings);

                  handleFooterClick?.();
                },
              }
            : undefined
        }
        customStyles={{
          py: 0,
        }}
      />
    </Box>
  );
};

export default ViewBookingFilter;
