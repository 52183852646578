import React from 'react';
import { Box, useTheme } from '@mui/material';

import { useAppSelector } from '../../app/hooks';
import { selectApp } from '../../slice/appSlice';
import { FormButton } from '..';
import DialogComponent from './DialogComponent';
import { CancelBookingIcon } from '../../assets/images';

import en from '../../translations/en';

interface IDialogContent {
  title: string;
  message: string | React.ReactNode;
  yesTitle: string;
  noTitle?: string;
}

const CancelDialog = ({
  open,
  handleConfirmAction,
  handleCloseDialog,
  dialogContent,
  showCancelIcon,
  IconComponent,
}: {
  open: boolean;
  handleConfirmAction: () => void;
  handleCloseDialog: () => void;
  dialogContent: IDialogContent;
  showCancelIcon?: boolean;
  IconComponent?: React.ReactNode;
}) => {
  const theme = useTheme();
  const { isDesktop } = useAppSelector(selectApp) || {};

  return (
    <DialogComponent
      open={open}
      title={dialogContent.title}
      message={dialogContent.message}
      IconComponent={IconComponent ? IconComponent : showCancelIcon ? <CancelBookingIcon /> : undefined}
      noErrorAlert={true}
      buttonComponent={
        <Box sx={{ mt: 6, mb: 4 }}>
          <FormButton
            theme={theme}
            colour="outline"
            variant="outlined"
            onClick={handleCloseDialog}
            customStyles={{
              height: '44px',
              width: isDesktop ? '195px' : '128px',
              borderRadius: '8px',
              marginRight: isDesktop ? '24px' : '39px',
            }}
          >
            {dialogContent.noTitle || en.common.no}
          </FormButton>
          <FormButton
            theme={theme}
            colour="blue"
            variant="contained"
            onClick={() => {
              handleConfirmAction();
              handleCloseDialog();
            }}
            customStyles={{
              height: '44px',
              width: isDesktop ? '195px' : '128px',
              borderRadius: '8px',
            }}
          >
            {dialogContent.yesTitle}
          </FormButton>
        </Box>
      }
    />
  );
};

CancelDialog.defaultProps = {
  showCancelIcon: true,
};

export default CancelDialog;
