import { Box, Typography, useTheme, SxProps, Theme } from '@mui/material';

import { SeatFlightIcon, SeatInfantClassIcon } from '../../../assets/images';
import { UI_STYLES } from '../../../constants/constants';

const CheckedInTravellerSection = ({
  title,
  subTitle,
  isInfant,
  customStyles,
}: {
  title: string;
  subTitle: string;
  isInfant: boolean;
  customStyles?: SxProps<Theme>;
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: UI_STYLES.eligibleTravellerItemHeight,
        mt: 1,
        background: theme.color.secondary.light_slate.option_6,
        borderRadius: 1,
        p: 2,
        ...customStyles,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          '&:active': {
            background: 'transparent',
          },
        }}
      >
        {isInfant ? (
          <SeatInfantClassIcon
            height={28}
            width={28}
            fill={theme.color.secondary.dark_grey.option_4}
            style={{ marginRight: '8px' }}
          />
        ) : (
          <SeatFlightIcon fill={theme.color.secondary.dark_grey.option_4} style={{ marginRight: '8px' }} />
        )}

        <Box
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'center',
            minHeight: title || subTitle ? '28px' : '',
            pr: 1,
          }}
        >
          {title && (
            <Typography variant="body_1_medium" color={theme.color.secondary.grey.option_3}>
              {title}
            </Typography>
          )}

          {subTitle && (
            <Typography variant="navigation_regular" color={theme.color.secondary.grey.option_2} sx={{ mt: 0.5 }}>
              {subTitle}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CheckedInTravellerSection;
