import React from 'react';
import { Typography, useTheme } from '@mui/material';

import en from '../../../translations/en';

const NoTravelConcessionComponent = () => {
  const theme = useTheme();
  return (
    <Typography
      variant="body_2_regular"
      color={theme.color.secondary.dark_grey.option_3}
      component="div"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        mt: 4,
      }}
    >
      {en.booking.travelType.noTravelConcession}
    </Typography>
  );
};

export default NoTravelConcessionComponent;
