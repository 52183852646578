import React, { useEffect, useState } from 'react';
import { Box, InputAdornment, SxProps, Theme } from '@mui/material';
import FormFieldTextInput from './FormFieldTextInput';
import { LockedIcon } from '../../assets/images';

import { FormFieldLabel } from '..';
import { REGEX } from '../../constants/constants';

const FormTextInput = ({
  title,
  subTitle,
  value,
  placeholder,
  maxLength,
  isNum,
  isPhoneNum,
  isNumOrAlphaOrSpace,
  isNumOrAlpha,
  isNumOrCapitalAlpha,
  isAlphaOrSpace,
  isReadOnly,
  isError,
  onChange,
  meta,
  customStyles,
  ...props
}: {
  title?: string;
  subTitle?: string;
  value?: string;
  placeholder?: string;
  maxLength?: number;
  isNum?: boolean;
  isPhoneNum?: boolean;
  isNumOrAlphaOrSpace?: boolean;
  isNumOrAlpha?: boolean;
  isNumOrCapitalAlpha?: boolean;
  isAlphaOrSpace?: boolean;
  isReadOnly?: boolean;
  isError?: boolean;
  onChange: (value: string) => void;
  meta?: any;
  customStyles?: SxProps<Theme>;
  [x: string]: any;
}) => {
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (!isError) {
      setIsChanged(false);
    }
  }, [isError]);

  const subTextFontSize = {
    fontSize: '11px',
    marginBottom: '6px',
    color: '#595959',
  };

  const isErrorSummarized = isError || (meta?.touched && meta?.error);

  return (
    <Box sx={{ flex: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <FormFieldTextInput
          label={title}
          sx={{ flex: 1, mb: 0.5, ...customStyles }}
          color="info"
          variant="filled"
          value={value}
          disabled={isReadOnly}
          error={isErrorSummarized}
          placeholder={placeholder}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            let value = event.target.value;

            if (isNum) {
              value = value.replace(REGEX.num, '');
            } else if (isPhoneNum) {
              value = value.replace(REGEX.phoneNum, '');
              value = value.replace('--', '-');
            } else if (isNumOrAlphaOrSpace) {
              value = value.replace(REGEX.numOrAlphaOrSpace, '');
            } else if (isNumOrAlpha) {
              value = value.replace(REGEX.numOrAlpha, '');
            } else if (isNumOrCapitalAlpha) {
              value = value.replace(REGEX.numOrAlpha, '');
              value = value.toUpperCase();
            } else if (isAlphaOrSpace) {
              value = value.replace(REGEX.alphaOrSpace, '');
            }
            if (!isChanged) {
              setIsChanged(true);
            }

            onChange(value);
          }}
          autoComplete="off"
          inputProps={{ maxLength }}
          InputProps={{
            endAdornment: isReadOnly && (
              <InputAdornment position="end">
                <LockedIcon style={{ marginRight: '4px' }} />
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
          {...props}
        />
      </Box>

      {subTitle && <FormFieldLabel style={subTextFontSize} title={subTitle} />}

      {isErrorSummarized && <FormFieldLabel style={subTextFontSize} title={isError || meta.error} isError />}
    </Box>
  );
};

const ReduxFormTextInput = ({ input: { name, value, onChange, onFocus, onBlur, customStyles }, ...props }: any) => {
  return (
    <FormTextInput
      name={name}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      customStyles={customStyles}
      {...props}
    />
  );
};

export default ReduxFormTextInput;
