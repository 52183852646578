import React from 'react';
import _ from 'lodash';
import { Box, Typography, useTheme } from '@mui/material';
import { reduxForm, Field, FormErrors, getFormSyncErrors, getFormValues } from 'redux-form';
import { connect } from 'react-redux';

import { FORM, TRAVEL_PURPOSE } from '../../../../../constants/constants';
import en from '../../../../../translations/en';

import { INominatedPassenger, ITravellerDetails } from '../../../../../interfaces';

import { isValid } from '../../../../../helpers/validation';
import { validate } from './validate';

import { RootState } from '../../../../../app/store';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import {
  selectBooking,
  setLtSeatConcessionTravellerList,
  setTravellerDetails,
} from '../../../../../slice/bookingSlice';
import { selectConfiguration } from '../../../../../slice/configurationSlice';
import { selectApp } from '../../../../../slice/appSlice';

import { Header, Footer, ReduxFormSelectInput, ScrollableView, FormAlert } from '../../../../../components';

const { travelDetails } = en.booking.flightConfirmation;
const { travellerDetails: travellerDetailsLbl } = travelDetails || {};

const EditTravellerDetails = (props: any) => {
  const {
    handleClose,
    formValues,
    formErrors,
  }: {
    handleClose?: () => void;
    formValues: ITravellerDetails | null;
    formErrors: FormErrors<ITravellerDetails | null>;
  } = props;

  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { configurations } = useAppSelector(selectConfiguration) || {};
  const { isDesktop } = useAppSelector(selectApp) || {};
  const { docsDoca, travelType, leisureTravelDetails } = useAppSelector(selectBooking) || {};
  const { docaResidence: isResidenceMandatory } = docsDoca || {};

  const isLT = travelType === TRAVEL_PURPOSE.employeeLeisureTravel;

  return (
    <>
      <Box sx={{ px: 2, flex: 1, minHeight: 0 }}>
        <Header
          isHideBackBtn={true}
          leftChild={
            <Typography variant={isDesktop ? 'headline_bold' : 'headline_medium'} color="gray.dark">
              {travellerDetailsLbl.title}
            </Typography>
          }
          handleOnClose={handleClose}
          customStyles={{ mt: 3 }}
        />
        <Box
          component={ScrollableView}
          sx={{
            py: 2,
          }}
        >
          {isResidenceMandatory && (
            <FormAlert
              severity="info"
              customStyles={{ mb: 1 }}
              component={
                <Typography
                  variant="body_2_regular"
                  sx={{
                    ml: 1,
                    color: theme.color.secondary.dark_grey.option_3,
                  }}
                >
                  {en.booking.confirmation.mandatory}
                </Typography>
              }
            />
          )}
          <Field
            component={ReduxFormSelectInput}
            name="countryOfResidential"
            title={travellerDetailsLbl.countryOfResidential}
            options={configurations?.countries}
            autocomplete={{
              matchFrom: 'start',
            }}
          />
        </Box>
      </Box>

      {isValid(formErrors) && (
        <Footer
          customStyles={{
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
          }}
          primaryBtn={{
            isFullWidth: true,
            text: en.common.update,
            customOnClick: () => {
              if (isLT) {
                // LT Flow
                // saved the traveller detail back to Traveller List
                const editTravellerKeyIndex = leisureTravelDetails.editTravellerKeyIndex;
                const clonedTravellerList = _.cloneDeep(leisureTravelDetails?.ltSeatConcessionTravellerList);
                const selectedTraveller = clonedTravellerList.find(
                  (traveller: INominatedPassenger) => traveller.keyIndex === editTravellerKeyIndex,
                );

                if (typeof editTravellerKeyIndex === 'number' && editTravellerKeyIndex >= 0 && selectedTraveller) {
                  selectedTraveller.countryOfResidential = formValues?.countryOfResidential;

                  // also update `isUpdated` flag
                  selectedTraveller.isUpdated = true;
                }
                //dispatch to ltTravellers
                dispatch(setLtSeatConcessionTravellerList(clonedTravellerList));
              } else {
                // DT Flow
                dispatch(setTravellerDetails(formValues));
              }

              handleClose && handleClose();
            },
          }}
        />
      )}
    </>
  );
};

const form = reduxForm({
  form: FORM.travellerDetails,
  validate,
  enableReinitialize: true,
  touchOnBlur: true,
  touchOnChange: true,
  destroyOnUnmount: true,
})(EditTravellerDetails);
const connectForm = connect((state: RootState) => {
  let ltTravelDetail = {};

  const isLT = state.bookingSearch.travelType === TRAVEL_PURPOSE.employeeLeisureTravel;
  const editTravellerKeyIndex = state.bookingSearch.leisureTravelDetails.editTravellerKeyIndex;

  if (isLT && typeof editTravellerKeyIndex === 'number' && editTravellerKeyIndex >= 0) {
    ltTravelDetail = {
      countryOfResidential:
        state?.bookingSearch?.leisureTravelDetails?.ltSeatConcessionTravellerList?.[editTravellerKeyIndex]
          ?.countryOfResidential || null,
    };
  }

  return {
    docaResidence: state.bookingSearch.docsDoca.docaResidence,
    initialValues: isLT ? ltTravelDetail : state.bookingSearch.travellerDetails,
    formValues: getFormValues(FORM.travellerDetails)(state),
    formErrors: getFormSyncErrors(FORM.travellerDetails)(state),
  };
})(form);

connectForm.displayName = FORM.travellerDetails;

export default connectForm;
