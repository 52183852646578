import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

interface IAuthSlice {
  isLogin: boolean;
  haveAccess: boolean | undefined;
  galacxyId: string;
  ern: string;
  initNetworkError: boolean;
  // accessToken?: string;
}

const initialState: IAuthSlice = {
  isLogin: false,
  haveAccess: undefined,
  galacxyId: '',
  ern: '',
  initNetworkError: false,
  // accessToken: "",
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, { payload }) => {
      // const { accessToken, galacxyId, ern } = payload || {};
      const { galacxyId, ern } = payload || {};

      state.galacxyId = galacxyId;
      // state.accessToken = accessToken;
      if (!state?.ern) {
        state.ern = ern;
      }
      state.isLogin = true;
    },
    setErn: (state, { payload }: PayloadAction<string>) => {
      state.ern = payload;
    },
    setHaveAccess: (state, { payload }: PayloadAction<boolean>) => {
      state.haveAccess = payload;
      state.initNetworkError = false;
    },
    setInitNetworkError: (state, { payload }: PayloadAction<boolean>) => {
      state.initNetworkError = payload;
    },
    reset: () => initialState,
  },
});

const selectAuth = (state: RootState) => state.auth;
const { login, setErn, setHaveAccess, reset, setInitNetworkError } = authSlice.actions;

export { authSlice, selectAuth, login, setErn, setHaveAccess, reset, setInitNetworkError };
export type { IAuthSlice };

export default authSlice.reducer;
