import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

const InfoContent = styled(Box)(({ theme }) => ({
  borderRadius: 4,
  backgroundColor: theme.palette.primary.light,
  boxShadow: theme.palette.boxShadow.light,
  padding: theme.spacing(2),
}));

export default InfoContent;
