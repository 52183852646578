import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { flownSuspensionChangeAcknowledged } from '../services/flownSuspension';

interface IFlownSuspensionSlice {
  isRefreshProfile: boolean;
  isUnderstandChecked: boolean;
  isOpenFlownSuspensionDialog: boolean;
}

const initialState: IFlownSuspensionSlice = {
  isRefreshProfile: false,
  isUnderstandChecked: false,
  isOpenFlownSuspensionDialog: false,
};

export const changeAcknowledgedThunk = createAsyncThunk(
  'flownSuspension/changeAcknowledgedThunk',
  async (params: { userId: string; startDate: string; endDate: string; type: string }) => {
    return await flownSuspensionChangeAcknowledged(params.userId, params.startDate, params.endDate, params.type);
  },
);

const FlownSuspensionSlice = createSlice({
  name: 'flownSuspension',
  initialState,
  reducers: {
    setIsUnderstandChecked: (state, { payload }: PayloadAction<boolean>) => {
      return {
        ...state,
        isUnderstandChecked: payload,
      };
    },
    setIsOpenFlownSuspensionDialog: (state, { payload }: PayloadAction<boolean>) => {
      return {
        ...state,
        isOpenFlownSuspensionDialog: payload,
      };
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(changeAcknowledgedThunk.pending, (state) => {
        state.isOpenFlownSuspensionDialog = true;
        state.isUnderstandChecked = true;
      })
      .addCase(changeAcknowledgedThunk.fulfilled, (state, { payload }) => {
        if (payload && payload.message) {
          state.isOpenFlownSuspensionDialog = false;
          state.isUnderstandChecked = false;
        } else {
          state.isUnderstandChecked = true;
        }
      });
  },
});

const selectFlownSuspension = (state: RootState) => state.flownSuspension;
const { setIsUnderstandChecked, setIsOpenFlownSuspensionDialog, reset } = FlownSuspensionSlice.actions;

export {
  FlownSuspensionSlice,
  selectFlownSuspension,
  setIsUnderstandChecked,
  setIsOpenFlownSuspensionDialog,
  reset,
  initialState,
};
export type { IFlownSuspensionSlice };

export default FlownSuspensionSlice.reducer;
