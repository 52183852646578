import React, { useState } from 'react';
import { SxProps, Theme, Grid, Typography, useTheme, Link } from '@mui/material';

import en from '../../../translations/en';
import { LABEL_VALUE_TYPE } from '../../../constants/constants';

import { findDropdownOptionClient, getDisplayLabel, getDisplayVal } from '../../../helpers';
import { useAppSelector } from '../../../app/hooks';
import { selectConfiguration } from '../../../slice/configurationSlice';

interface IDisplayDetails {
  label: string;
  value: string | number | boolean | Date | undefined;
  column: number;
  options?: string[];
  configurationField?: string;
  confidential?: boolean;
  type?: LABEL_VALUE_TYPE;
  labelVariant?: string | any;
  valueVariant?: string | any;
}

interface ILabelValueContainer extends IDisplayDetails {
  customStyles?: SxProps<Theme>;
}

const LabelValueContainer = ({
  label,
  value,
  type = LABEL_VALUE_TYPE.STRING,
  column = 4,
  options,
  configurationField,
  confidential = false,
  customStyles,
  labelVariant = 'body_2_regular',
  valueVariant = 'headline_medium',
}: ILabelValueContainer) => {
  const theme = useTheme();
  const [isHide, setIsHide] = useState(confidential);

  const { configurations } = useAppSelector(selectConfiguration) || {};

  const ValueComponent = () => {
    switch (type) {
      case LABEL_VALUE_TYPE.STRING:
        return (
          <Typography sx={{ color: theme.color.secondary.dark_grey.option_3 }} variant={valueVariant}>
            {getDisplayVal(value)}
          </Typography>
        );
      case LABEL_VALUE_TYPE.BOOLEAN:
        return (
          <Typography sx={{ color: theme.color.secondary.dark_grey.option_3 }} variant={valueVariant}>
            {value ? options?.[0] : options?.[1]}
          </Typography>
        );
      case LABEL_VALUE_TYPE.CODELABELS:
        return (
          <Typography sx={{ color: theme.color.secondary.dark_grey.option_3 }} variant={valueVariant}>
            {getDisplayLabel(configurations.labels, configurationField, value as string)}
          </Typography>
        );
      case LABEL_VALUE_TYPE.COUNTRIES:
        // This type is on hold, BU side said just use the Code directly from backend
        return (
          <Typography sx={{ color: theme.color.secondary.dark_grey.option_3 }} variant={valueVariant}>
            {findDropdownOptionClient(configurations.countries, value as string)?.label}
          </Typography>
        );
    }
  };

  return (
    <Grid item xs={column} sx={{ display: 'flex', flexDirection: 'column', ...customStyles }}>
      <Typography sx={{ color: theme.color.secondary.dark_grey.option_3 }} variant={labelVariant}>
        {label}
      </Typography>
      {isHide ? (
        <Link sx={{ cursor: 'pointer' }} onClick={() => setIsHide(false)}>
          <Typography variant="headline_medium">{en.common.view}</Typography>
        </Link>
      ) : (
        ValueComponent()
      )}
    </Grid>
  );
};

export default LabelValueContainer;

export type { IDisplayDetails };
