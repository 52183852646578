import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, Autocomplete, TextField, Button, useTheme } from '@mui/material';
import _ from 'lodash';

import en from '../../../translations/en';
import { UI_STYLES, LABEL_CATEGORY } from '../../../constants/constants';
import { FONT_WEIGHT } from '../../../constants/font';
import { CloseIcon, DownArrowInheritIcon } from '../../../assets/images';

import { IAdminNote } from '../../../interfaces';
import { Configuration } from 'configuration';

import { AdminAccessEmployeeState, AdminAccessEmployeeAction } from '../../../context';

import { useAppSelector } from '../../../app/hooks';

import { selectConfiguration } from '../../../slice/configurationSlice';

import { noteUpsert } from '../../../services/admin/employee';

const NotesPopup = ({ setIsPopupActive }: { setIsPopupActive: (value: boolean) => void }) => {
  const theme = useTheme();
  const fieldLabel = en.admin.notes.fields;

  const { configurations } = useAppSelector(selectConfiguration) || {};
  const [typeValue, setTypeValue] = useState<null | Configuration.CodeLabel>(null);
  const [subjectValue, setSubjectValue] = useState('');
  const [descriptionValue, setDescriptionValue] = useState('');
  const { employeeDetail, employeeNotes, focusNote } = useContext(AdminAccessEmployeeState) || {};
  const { setEmployeeNotes, setFocusNote } = useContext(AdminAccessEmployeeAction) || {};

  const typeOptions = configurations.labels.filter(
    (item: Configuration.CodeLabel) => item.category === LABEL_CATEGORY.adminNoteType,
  );

  const handleNoteUpsert = async () => {
    const note = await noteUpsert({
      noteId: focusNote?.noteId,
      userId: employeeDetail.profile.employeeId,
      profileRevision: employeeDetail.profile.revision,
      type: typeValue?.label || '',
      subject: subjectValue,
      description: descriptionValue,
    });

    if (note) {
      const cloneEmployeeNotes = _.cloneDeep(employeeNotes);
      const index = cloneEmployeeNotes.findIndex((item: IAdminNote) => item.noteId === note.noteId);
      index === -1 ? cloneEmployeeNotes.unshift(note) : (cloneEmployeeNotes[index] = note);

      setEmployeeNotes(cloneEmployeeNotes);
      focusNote && setFocusNote(note);
    }
  };

  useEffect(() => {
    if (focusNote) {
      const initialType = typeOptions.find((item: Configuration.CodeLabel) => item.label === focusNote.type);
      setTypeValue(initialType || null);
      setSubjectValue(focusNote.subject);
      setDescriptionValue(focusNote.description);
    }
  }, [focusNote]);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        position: 'fixed',
        zIndex: 800,
        background: theme.backgroundColor.popup,
      }}
    >
      <Box
        sx={{
          width: '1024px',
          height: '615px',
          background: 'white',
          borderRadius: '16px',
          mx: 'auto',
          mt: 7,
          p: 5,
          color: theme.color.secondary.dark_grey.option_3,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          className="note_popup_bar"
          sx={{
            width: '100%',
            height: '36px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="title_1_bold">
            {focusNote ? en.admin.notes.editNote : en.admin.notes.createNewNote}
          </Typography>
          <CloseIcon
            fill={theme.color.secondary.dark_grey.option_3}
            style={{ cursor: 'pointer' }}
            onClick={() => setIsPopupActive(false)}
          />
        </Box>
        <Box
          className="note_popup_type"
          sx={{
            py: 3,
            width: '100%',
            height: '96px',
            borderBottom: `1px solid #D8D8D8`,
          }}
        >
          <Typography variant="headline_medium" sx={{ float: 'left' }}>
            {fieldLabel.type}
          </Typography>
          <Autocomplete
            options={typeOptions}
            value={typeValue}
            popupIcon={<DownArrowInheritIcon fill={theme.color.secondary.dark_grey.option_3} />}
            clearIcon={null}
            disablePortal
            sx={{
              width: '464px',
              height: '48px',
              float: 'right',
              '.MuiFilledInput-root': {
                height: UI_STYLES.concessionTravellerHeight,
                borderRadius: 0.5,
                border: `2px solid ${theme.color.secondary.slate.option_3}`,
                background: theme.palette.primary.light,
                color: theme.color.secondary.dark_grey.option_3,
                paddingTop: '0px',
                '&.Mui-error': {
                  border: `2px solid ${theme.color.secondary.slate.option_3}`,
                },
                '&.Mui-selected, &:hover, &.Mui-filled': {
                  background: 'white',
                },
                '&.Mui-focused': {
                  background: theme.palette.primary.light,
                  border: `2px solid ${theme.color.secondary.slate.option_3}`,
                  '&.Mui-error': {
                    border: `2px solid ${theme.color.secondary.slate.option_3}`,
                  },
                },
                '&.Mui-disabled': {
                  background: theme.color.utility.unselectable.option_7,
                },
                '&: after, &: before': {
                  display: 'none',
                },
                // input style
                '.MuiFilledInput-input': {
                  mt: 1.5,
                  fontWeight: FONT_WEIGHT.medium,
                  fontSize: theme.typography.body_2_medium.fontSize,
                  color: theme.color.secondary.dark_grey.option_3,
                },
              },
              '.MuiInputLabel-root': {
                top: '-2px',
                color: theme.color.secondary.grey.option_3,
                fontSize: theme.typography.body_2_medium.fontSize,
                '&.Mui-focused, &.Mui-disabled, &.Mui-error': {
                  color: theme.color.secondary.grey.option_3,
                },
              },
            }}
            onChange={(_, newValue) => {
              setTypeValue(newValue);
            }}
            renderInput={(params) => (
              <TextField
                sx={{
                  height: UI_STYLES.concessionTravellerHeight,
                }}
                variant="filled"
                {...params}
                label={en.admin.notes.typePlaceholder}
              />
            )}
          />
        </Box>
        <Box
          className="note_popup_subject"
          sx={{
            py: 3,
            flex: 1,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignContent: 'flex-start',
            flexWrap: 'wrap',
            borderBottom: `1px solid #D8D8D8`,
          }}
        >
          <Typography variant="headline_medium" sx={{ float: 'left' }}>
            {fieldLabel.subject}
          </Typography>
          <TextField
            id="outlined-multiline-static"
            label="Multiline"
            multiline
            rows={2}
            value={subjectValue}
            placeholder={en.admin.notes.subjectPlaceholder}
            inputProps={{ maxLength: 100 }}
            InputLabelProps={{ shrink: true }}
            sx={{
              float: 'right',
              width: '464px',
              height: '62px',
              '.MuiOutlinedInput-root': {
                height: '62px',
                borderRadius: 0.5,
                border: `2px solid ${theme.color.secondary.slate.option_3}`,
                background: theme.palette.primary.light,
                color: theme.color.secondary.dark_grey.option_3,
                py: 1.875,
                px: 1.125,
                '.MuiInputBase-inputMultiline': {
                  fontWeight: FONT_WEIGHT.medium,
                  color: theme.color.secondary.dark_grey.option_3,
                  fontSize: theme.typography.body_2_medium.fontSize,
                },
              },
              'label, fieldset': { display: 'none' },
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSubjectValue(event.target.value);
            }}
          />
          <Typography
            variant="navigation_regular"
            sx={{
              mt: 0.5,
              width: '100%',
              textAlign: 'right',
              color: theme.color.secondary.grey.option_3,
            }}
          >
            {subjectValue.length}
            {en.admin.notes.subjectLimitCharacters}
          </Typography>
        </Box>
        <Box
          className="note_popup_description"
          sx={{
            py: 3,
            flex: 1,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignContent: 'flex-start',
            flexWrap: 'wrap',
          }}
        >
          <Typography variant="headline_medium" sx={{ float: 'left' }}>
            {fieldLabel.description}
          </Typography>
          <TextField
            id="outlined-multiline-static"
            label="Multiline"
            multiline
            rows={5}
            value={descriptionValue}
            placeholder={en.admin.notes.descriptionPlaceholder}
            inputProps={{ maxLength: 3000 }}
            InputLabelProps={{ shrink: true }}
            sx={{
              float: 'right',
              width: '464px',
              height: '140px',
              '.MuiOutlinedInput-root': {
                height: '140px',
                borderRadius: 0.5,
                border: `2px solid ${theme.color.secondary.slate.option_3}`,
                background: theme.palette.primary.light,
                color: theme.color.secondary.dark_grey.option_3,
                py: 1.875,
                px: 1.125,
                '.MuiInputBase-inputMultiline': {
                  fontWeight: FONT_WEIGHT.medium,
                  color: theme.color.secondary.dark_grey.option_3,
                  fontSize: theme.typography.body_2_medium.fontSize,
                },
              },
              'label, fieldset': { display: 'none' },
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setDescriptionValue(event.target.value);
            }}
          />
          <Typography
            variant="navigation_regular"
            sx={{
              mt: 0.5,
              width: '100%',
              textAlign: 'right',
              color: theme.color.secondary.grey.option_3,
            }}
          >
            {descriptionValue.length}
            {en.admin.notes.descriptionLimitCharacters}
          </Typography>
        </Box>
        <Box className="note_popup_button" sx={{ display: 'flex', justifyContent: 'right' }}>
          <Button
            sx={{
              width: '200px',
              height: '48px',
              color: theme.color.utility.link.option_3,
              border: `2px solid ${theme.color.utility.link.option_3}`,
              '&: hover': {
                background: 'none',
                border: `2px solid ${theme.color.utility.link.option_3}`,
              },
            }}
            variant="outlined"
            onClick={() => setIsPopupActive(false)}
          >
            <Typography variant="body_1_bold" sx={{ lineHeight: 'initial' }}>
              {en.common.cancel}
            </Typography>
          </Button>
          <Button
            sx={{
              ml: 2,
              width: '200px',
              height: '48px',
              color: 'white',
              background: theme.status.selected,
              '&: hover': {
                background: theme.status.selected,
              },
            }}
            variant="outlined"
            onClick={() => {
              handleNoteUpsert();
              setIsPopupActive(false);
            }}
          >
            <Typography variant="body_1_bold" sx={{ lineHeight: 'initial' }}>
              {en.common.save}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default NotesPopup;
