import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Configuration } from 'configuration';

import { RootState } from '../app/store';
import { getAirports, getConfigurations } from '../services/configuration';

export const getAirportsThunk = createAsyncThunk('configuration/getAirports', async () => {
  return await getAirports();
});

export const getConfigurationsThunk = createAsyncThunk('configuration/getConfigurations', async () => {
  return await getConfigurations();
});

export interface IConfigurationSlice {
  airports: Configuration.Airport[];
  configurations: Configuration.ConfigurationsClient;
}

const initialState: IConfigurationSlice = {
  airports: [],
  configurations: {
    countries: [],
    costCentres: [],
    generalLedgerAccounts: [],
    labels: [],
  },
};

export const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAirportsThunk.pending, (state) => {
        state.airports = initialState.airports;
      })
      .addCase(getAirportsThunk.fulfilled, (state, { payload }) => {
        if (payload) {
          state.airports = payload;
        }
      })
      .addCase(getConfigurationsThunk.pending, (state) => {
        state.configurations = initialState.configurations;
      })
      .addCase(getConfigurationsThunk.fulfilled, (state, { payload }) => {
        if (payload) {
          state.configurations = payload;
        }
      });
  },
});

const selectConfiguration = (state: RootState) => state.configuration;
const { reset } = configurationSlice.actions;

export { selectConfiguration, reset };

export default configurationSlice.reducer;
