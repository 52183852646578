import { Box, Typography, CardActionArea, useTheme } from '@mui/material';

import { IUserProfileList, IUserProfileListProps } from '../../interfaces';

import { ETPLink } from '../../components';

const AdminNavList = ({ list }: IUserProfileListProps) => {
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex', mt: 3 }}>
      {list.map((item: IUserProfileList, index: number) => (
        <Box key={item.title}>
          <CardActionArea
            component={ETPLink}
            theme={theme}
            to={item.url}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '334px',
              ml: index !== 0 ? 2 : 0,
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                width: '100%',
                height: '96px',
                bgcolor: 'white',
                borderRadius: 1,
                pl: 2,
                boxShadow: theme.boxShadow.important,
              }}
            >
              {item.icon}
              <Typography
                variant="headline_medium"
                sx={{
                  color: theme.color.secondary.dark_grey.option_1,
                  mt: 1.75,
                }}
              >
                {item.title}
              </Typography>
            </Box>
          </CardActionArea>
        </Box>
      ))}
    </Box>
  );
};

export default AdminNavList;
