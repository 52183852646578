import React from 'react';
import { Typography, Box } from '@mui/material';

import { UI_STYLES } from '../../../constants/constants';
import { DoneIcon, OutlinedDoneIcon } from '../../../assets/images';
import theme from '../../../style/theme';
import en from '../../../translations/en';

import { isOWOALBooking } from '../../../helpers';

import { useAppSelector } from '../../../app/hooks';
import { selectApp } from '../../../slice/appSlice';

import { BookingReferenceHeader } from '../..';

const BookingCompletedHeader = (props: any) => {
  const { isDesktop } = useAppSelector(selectApp) || {};

  const { recLoc: bookingRecLoc } = props || {};
  const { flightNum } = isDesktop
    ? props?.ticketsBySegment?.[0] || ''
    : props?.bookingResult?.booking.ticketsBySegment?.[0] || '';
  const isOWOALFlight = isOWOALBooking(flightNum || '');

  return (
    <Box width="100%">
      <BookingReferenceHeader
        recLoc={isOWOALFlight ? en.common.empty : bookingRecLoc}
        customStyles={{ justifyContent: isDesktop ? 'center' : 'flex-start' }}
      />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height={UI_STYLES.overlayHeaderHeightSearchCriteria}
        mt={2}
        mb={isDesktop ? 6 : 0}
        sx={{ width: isDesktop ? 'auto' : 'calc(100vw - 32px)' }}
      >
        <Box display="flex" alignItems="center" gap={1}>
          {isDesktop ? <DoneIcon /> : <OutlinedDoneIcon />}
          <Typography variant="title_2_bold" color={isDesktop ? theme.color.secondary.grey.option_1 : 'white'}>
            {en.booking.confirmation.done}
          </Typography>
        </Box>
        <Typography
          variant="body_1_regular"
          align="center"
          color={isDesktop ? theme.color.secondary.grey.option_1 : 'white'}
        >
          {en.booking.confirmation.ticketPurchaseCompleted}
        </Typography>
      </Box>
    </Box>
  );
};

export default BookingCompletedHeader;
