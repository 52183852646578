import en from '../../translations/en';
import { HTTP_REQUEST_TYPE, ADMIN_API } from '../../constants/api';

import { sendHttpRequest } from '../api';

import { IFlightSectors, IBookFlightParams } from '../../interfaces';

const searchFlight = async (
  departureDate: string,
  departurePort: string,
  arrivalDate: string,
  arrivalPort: string,
  concessionId: string,
  userId?: string,
) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: ADMIN_API.BOOKING_API.searchFlight,
    apiTitle: en.errorAlert.searchFlight,
    payload: {
      departureDate,
      departurePort,
      arrivalDate,
      arrivalPort,
      concessionId,
      userId,
    },
  });
  return resp;
};

const getFare = async (flightSectors: IFlightSectors[], concessionId: string, userId?: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: ADMIN_API.BOOKING_API.fare,
    apiTitle: en.errorAlert.getFare,
    payload: { flightSectors, concessionId, userId },
  });
  return resp;
};

const getDocsDoca = async (flightSectors: IFlightSectors[]) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: ADMIN_API.BOOKING_API.docsDoca,
    apiTitle: en.errorAlert.getDocsDoca,
    payload: { flightSectors },
  });
  return resp;
};

const getTravellerName = async (userId: string, flightSectors: IFlightSectors[]) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: ADMIN_API.BOOKING_API.travellerName,
    apiTitle: en.errorAlert.getTravellerName,
    payload: { userId, flightSectors },
  });
  return resp;
};

const getErnEmpTraveller = async (userId: string, concessionId: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: ADMIN_API.BOOKING_API.ernEmpTraveller,
    apiTitle: en.errorAlert.getErnEmpTraveller,
    payload: { userId, concessionId },
  });
  return resp;
};

const bookFlights = async (param: IBookFlightParams) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: ADMIN_API.BOOKING_API.book,
    apiTitle: en.errorAlert.bookFlights,
    payload: param,
  });
  return resp;
};

const getAdminMyBookings = async () => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: ADMIN_API.BOOKING_API.myBooking,
    apiTitle: en.errorAlert.getBooking,
  });
  return resp;
};

const getAdminBooking = async (applicationId: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: ADMIN_API.BOOKING_API.pnr,
    apiTitle: en.errorAlert.getBooking,
    payload: { applicationId },
  });
  return resp;
};

const cancelBooking = async (applicationId: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: ADMIN_API.BOOKING_API.cancel,
    apiTitle: en.errorAlert.cancelBooking,
    payload: { applicationId },
  });
  return resp;
};

const searchBookingByRecLoc = async (
  condition: {
    recLoc: string;
    userId?: string;
  },
  path?: string,
) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: path || ADMIN_API.BOOKING_API.searchBooking,
    apiTitle: en.errorAlert.getBooking,
    payload: condition,
  });
  return resp;
};

const searchBookingByErn = async (userId: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: ADMIN_API.BOOKING_API.searchBooking,
    apiTitle: en.errorAlert.getBooking,
    payload: { userId },
  });
  return resp;
};

export {
  searchFlight,
  getFare,
  getDocsDoca,
  getTravellerName,
  getErnEmpTraveller,
  bookFlights,
  getAdminMyBookings,
  getAdminBooking,
  cancelBooking,
  searchBookingByRecLoc,
  searchBookingByErn,
};
