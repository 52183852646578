import { Box, Typography, useTheme } from '@mui/material';

import en from '../../../translations/en';
import { FONT_WEIGHT } from '../../../constants/font';

import { ITravellerConcessionQuota } from '../../../interfaces';

const LtTravellerQuotaElements = ({ label = '', unused = 0, booked = 0 }: ITravellerConcessionQuota) => {
  const theme = useTheme();

  const enQuotaElement = en.booking.travelType.leisureTravel.quotaElement;
  const textColor = theme.color.secondary.grey.option_1;
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        fontSize: '12px',
        color: theme.color.secondary.grey.option_2,
        mt: 0.5,
      }}
    >
      <Typography
        variant="fine_print_bold"
        sx={{
          py: 0.125,
          px: 0.5,
          mr: 1,
          whiteSpace: 'nowrap',
          borderRadius: 0.5,
          color: textColor,
          background: theme.color.secondary.dark_sand.option_7,
        }}
      >
        {label}
      </Typography>

      <Box>
        {`${enQuotaElement.unused}: `}
        <span
          style={{
            color: textColor,
            fontWeight: FONT_WEIGHT.bold,
          }}
        >
          {unused}
        </span>
      </Box>

      <Box
        sx={{
          color: theme.color.secondary.slate.option_3,
          px: 0.5,
        }}
      >{`/`}</Box>

      <Box>
        {`${enQuotaElement.booked}: `}
        <span
          style={{
            color: textColor,
            fontWeight: FONT_WEIGHT.bold,
          }}
        >
          {booked}
        </span>
      </Box>
    </Box>
  );
};

export default LtTravellerQuotaElements;
