import { memo } from 'react';
import { Box, SxProps, useTheme, Typography, Breadcrumbs, Theme } from '@mui/material';
import { useAppSelector } from '../../app/hooks';
import { selectApp } from '../../slice/appSlice';
import { RightArrowIcon } from '../../assets/images';
import { UI_STYLES } from '../../constants/constants';

import en from '../../translations/en';

interface IStepProps {
  step?: number;
  customStyles?: SxProps<Theme>;
  customCrumbs?: object;
}

const DesktopBreadcrumb = ({ step, customStyles, customCrumbs }: IStepProps) => {
  const theme = useTheme();
  const { isDesktop } = useAppSelector(selectApp) || {};
  const crumbs = customCrumbs || en.booking.breadCrumbs;

  if (!isDesktop || !step) return null;

  return (
    <Breadcrumbs
      sx={{
        display: 'flex',
        height: UI_STYLES.overlayHeaderHeightSearchCriteria,
        backgroundColor: 'white',
        boxShadow: theme.boxShadow.important,
        px: 22,
        mb: 3.25,
        ...customStyles,
      }}
      separator={
        <Box sx={{ mx: 2, display: 'flex', alignItems: 'center' }}>
          <RightArrowIcon fill={theme.color.secondary.dark_grey.option_5} />
        </Box>
      }
      aria-label="breadcrumb"
    >
      {/* Object.values(crumbs) => ["Seat options", "Duty travel details", "Review and
        confirm"] */}
      {Object.values(crumbs).map((item, index) => (
        <Typography
          key={item}
          variant="body_1_regular"
          sx={{
            ...(index + 1 === step
              ? {
                  color: theme.color.secondary.dark_grey.option_3,
                  fontWeight: 'bold',
                }
              : { color: theme.color.secondary.dark_grey.option_5 }),
          }}
        >
          {item}
        </Typography>
      ))}
    </Breadcrumbs>
  );
};

const equal = (prev: IStepProps, next: IStepProps) => {
  return prev.step === next.step;
};

export default memo(DesktopBreadcrumb, equal);
