import React, { createContext, useState } from 'react';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from '../app/hooks';
import { selectBooking } from '../slice/bookingSlice';
import { IBookingSearchData } from '../interfaces';
import { Configuration } from 'configuration';

interface IStateType {
  origin: any;
  destination: any;
  startDate: any;
  isRefresh: any;
  travelDetails: any;
  leisureTravelDetails: any;
  travellerDetails: any;
  travelType: any;
  isShowEditSearchOverlay: any;
}

interface IActionType {
  setEditOrigin: any;
  setEditDestination: any;
  setEditStartDate: any;
  setEditIsRefresh: any;
  setEditTravelDetails: any;
  setEditLeisureTravelDetails: any;
  setEditTravellerDetails: any;
  setEditTravelType: any;
  setIsShowEditSearchOverlay: any;
}

const EditSearchBarState = createContext<IStateType>({
  origin: null,
  destination: null,
  startDate: null,
  isRefresh: null,
  travelType: null,
  travelDetails: null,
  leisureTravelDetails: null,
  travellerDetails: null,
  isShowEditSearchOverlay: null,
});

const EditSearchBarAction = createContext<IActionType>({
  setEditOrigin: null,
  setEditDestination: null,
  setEditStartDate: null,
  setEditIsRefresh: null,
  setEditTravelType: null,
  setEditLeisureTravelDetails: null,
  setEditTravelDetails: null,
  setEditTravellerDetails: null,
  setIsShowEditSearchOverlay: null,
});

const EditSearchBarContextProvider = ({ children }: { children?: React.ReactNode }) => {
  const {
    origin,
    destination,
    startDate,
    isRefresh,
    travelType,
    travelDetails,
    leisureTravelDetails,
    travellerDetails,
  } = useAppSelector(selectBooking, shallowEqual);

  const [editOrigin, setEditOrigin] = useState<Configuration.Airport | null>(origin);
  const [editDestination, setEditDestination] = useState<Configuration.Airport | null>(destination);
  const [editStartDate, setEditStartDate] = useState<string>(startDate);
  const [editIsRefresh, setEditIsRefresh] = useState<boolean>(isRefresh);
  const [editTravelDetails, setEditTravelDetails] = useState<IBookingSearchData['travelDetails']>(travelDetails);
  const [editLeisureTravelDetails, setEditLeisureTravelDetails] =
    useState<IBookingSearchData['leisureTravelDetails']>(leisureTravelDetails);
  const [editTravellerDetails, setEditTravellerDetails] =
    useState<IBookingSearchData['travellerDetails']>(travellerDetails);
  const [editTravelType, setEditTravelType] = useState<string>(travelType);
  const [isShowEditSearchOverlay, setIsShowEditSearchOverlay] = useState<boolean>(false);
  return (
    <EditSearchBarState.Provider
      value={{
        origin: editOrigin,
        destination: editDestination,
        startDate: editStartDate,
        isRefresh: editIsRefresh,
        travelDetails: editTravelDetails,
        leisureTravelDetails: editLeisureTravelDetails,
        travellerDetails: editTravellerDetails,
        travelType: editTravelType,
        isShowEditSearchOverlay,
      }}
    >
      <EditSearchBarAction.Provider
        value={{
          setEditOrigin,
          setEditDestination,
          setEditStartDate,
          setEditIsRefresh,
          setEditTravelDetails,
          setEditLeisureTravelDetails,
          setEditTravellerDetails,
          setEditTravelType,
          setIsShowEditSearchOverlay,
        }}
      >
        {children}
      </EditSearchBarAction.Provider>
    </EditSearchBarState.Provider>
  );
};

export { EditSearchBarContextProvider, EditSearchBarState, EditSearchBarAction };
