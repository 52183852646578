import React, { useMemo } from 'react';
import { Typography, useTheme, Box } from '@mui/material';
import { reduxForm, Field, FormErrors, getFormValues, initialize } from 'redux-form';
import { connect } from 'react-redux';
import _ from 'lodash';

import { FORM, LABEL_CATEGORY, TRAVEL_PURPOSE } from '../../../../../constants/constants';
import en from '../../../../../translations/en';

import { Configuration } from 'configuration';
import { INominatedPassenger, IPassportDetails } from '../../../../../interfaces';

import { getDisplayName, getLocalDate, isSomePassportDetailsMissing } from '../../../../../helpers';

import { validate } from './validate';
import { RootState } from '../../../../../app/store';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { selectBooking, setLtSeatConcessionTravellerList, setPassportDetails } from '../../../../../slice/bookingSlice';
import { selectConfiguration } from '../../../../../slice/configurationSlice';
import { selectApp } from '../../../../../slice/appSlice';

import {
  Header,
  Footer,
  ReduxFormSelectInput,
  ReduxFormTextInput,
  ScrollableView,
  FormAlert,
  ReduxFormDatePicker,
  FormButton,
} from '../../../../../components';

const { passport: passportLbl } = en.userProfile;
const { travelDetails: travelDetailsLbl } = en.booking.flightConfirmation;
const { passportDetails: passportDetailsLbl } = travelDetailsLbl || {};

const EditPassportDetails = (props: any) => {
  const theme = useTheme();
  const {
    handleClose,
    formValues,
    docs,
  }: {
    handleClose?: () => void;
    formValues: IPassportDetails | null;
    formErrors: FormErrors<IPassportDetails | null>;
    docs: boolean | null;
  } = props;

  const dispatch = useAppDispatch();

  const { travellerName, travelType, leisureTravelDetails } = useAppSelector(selectBooking) || {};
  const { configurations } = useAppSelector(selectConfiguration) || {};
  const { isDesktop } = useAppSelector(selectApp) || {};

  const isLT = travelType === TRAVEL_PURPOSE.employeeLeisureTravel;
  const editTravellerKeyIndex = leisureTravelDetails.editTravellerKeyIndex;
  const isFormValid = !isSomePassportDetailsMissing(formValues);

  const { youMayEither, clear, or, fillIn, allFields, toBeTrimmed, trimmed, dueTo } =
    en.booking.flightConfirmation.travelDetails.passportDetails.isClearOrFillIn;

  const warningMsg = useMemo(
    () => (
      <>
        <Typography variant="body_2_regular">
          {docs ? (
            en.booking.confirmation.mandatory
          ) : (
            <>
              {youMayEither}
              <Box display="inline" fontWeight="bold">
                {clear}
              </Box>
              {or}
              <Box display="inline" fontWeight="bold">
                {fillIn}
              </Box>
              {allFields}
            </>
          )}
        </Typography>
        <Typography variant="body_2_regular" fontWeight="normal" mt={1}>
          {toBeTrimmed}
          <Box display="inline" fontWeight="bold">
            {trimmed}
          </Box>
          {dueTo}
        </Typography>
      </>
    ),
    [docs],
  );

  return (
    <>
      <Box
        sx={{
          px: 2,
          flex: 1,
          minHeight: 0,
        }}
      >
        <Header
          isHideBackBtn={true}
          leftChild={
            <Typography variant={isDesktop ? 'headline_bold' : 'headline_medium'} color="gray.dark">
              {passportDetailsLbl.title}
            </Typography>
          }
          handleOnClose={handleClose}
          customStyles={{
            mt: 3,
          }}
        />

        <Box
          component={ScrollableView}
          sx={{
            py: 2,
            height: `calc(100% - 64px)`,
          }}
        >
          <FormAlert
            severity="info"
            customStyles={{ mb: 1 }}
            component={
              <Typography variant="body_2_regular" sx={{ ml: 1, color: theme.color.secondary.dark_grey.option_3 }}>
                {warningMsg}
              </Typography>
            }
          />

          <Field
            component={ReduxFormTextInput}
            name="passportName"
            title={passportDetailsLbl.passportName}
            isReadOnly={true}
          />

          <Field
            component={ReduxFormDatePicker}
            name="dateOfBirth"
            title={passportDetailsLbl.dateOfBirth}
            maxDate={getLocalDate()}
          />

          <Field
            component={ReduxFormSelectInput}
            name="gender"
            title={passportDetailsLbl.gender}
            options={configurations.labels.filter(
              (label: Configuration.CodeLabel) => label.category === LABEL_CATEGORY.gender,
            )}
          />

          <Field
            component={ReduxFormTextInput}
            name="passportNumber"
            isNumOrAlphaOrSpace
            title={passportDetailsLbl.passportNumber}
          />

          <Field
            component={ReduxFormDatePicker}
            name="expiryDate"
            title={passportLbl.expiryDate}
            minDate={getLocalDate()}
          />

          <Field
            component={ReduxFormSelectInput}
            name="nationality"
            title={passportDetailsLbl.nationality}
            options={configurations?.countries}
            autocomplete={{
              matchFrom: 'start',
            }}
          />

          <Field
            component={ReduxFormSelectInput}
            name="issuingCountry"
            title={passportDetailsLbl.issuingCountry}
            options={configurations?.countries}
            autocomplete={{
              matchFrom: 'start',
            }}
          />
        </Box>
      </Box>

      {(!docs || isFormValid) && (
        <Footer
          customStyles={{
            py: 2,
            minHeight: 0,
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
          }}
          primaryBtn={
            isFormValid
              ? {
                  text: en.common.update,
                  customOnClick: () => {
                    if (isLT) {
                      // LT Flow
                      // saved the traveller detail back to Traveller List
                      const clonedTravellerList = _.cloneDeep(leisureTravelDetails?.ltSeatConcessionTravellerList);

                      if (
                        typeof editTravellerKeyIndex === 'number' &&
                        editTravellerKeyIndex >= 0 &&
                        clonedTravellerList
                      ) {
                        const matchedCurrentTraveller = clonedTravellerList.find(
                          (item) => item.keyIndex === editTravellerKeyIndex,
                        );
                        if (matchedCurrentTraveller) {
                          let newPassportDetail = matchedCurrentTraveller?.passportDetail;

                          if (!newPassportDetail) {
                            newPassportDetail = {};
                          }

                          newPassportDetail.passportName = formValues?.passportName || '';
                          newPassportDetail.dateOfBirth = formValues?.dateOfBirth || '';
                          newPassportDetail.gender = formValues?.gender || '';
                          newPassportDetail.passportNumber = formValues?.passportNumber || '';
                          newPassportDetail.passportExpirationDate = formValues?.expiryDate || '';
                          newPassportDetail.passportNationality = formValues?.nationality || '';
                          newPassportDetail.passportCountry = formValues?.issuingCountry || '';

                          matchedCurrentTraveller.passportDetail = newPassportDetail;

                          // also update `isUpdated` flag
                          matchedCurrentTraveller.isUpdated = true;
                        }
                      }

                      // dispatch to ltTravellers
                      dispatch(setLtSeatConcessionTravellerList(clonedTravellerList));
                    } else {
                      dispatch(setPassportDetails(formValues));
                    }
                    handleClose && handleClose();
                  },
                }
              : undefined
          }
          leftChild={
            !docs && (
              <FormButton
                theme={theme}
                colour="transparent"
                size="large"
                onClick={() => {
                  let passportName = '';

                  if (isLT) {
                    const traveller = leisureTravelDetails?.ltSeatConcessionTravellerList?.find(
                      (item) => item.keyIndex === editTravellerKeyIndex,
                    );

                    const { firstName, lastName, passportDetail } = traveller || {};

                    if (passportDetail) {
                      passportName = getDisplayName({
                        firstName: passportDetail.passportFirstName,
                        lastName: passportDetail.passportLastName,
                      });
                    } else {
                      passportName = getDisplayName({
                        firstName,
                        lastName,
                      });
                    }
                  } else {
                    passportName = getDisplayName(travellerName);
                  }

                  dispatch(
                    initialize(FORM.passportDetails, {
                      passportName,
                    }),
                  );
                }}
                customStyles={{
                  justifyContent: 'start',
                  p: isDesktop ? 1 : 0,
                }}
              >
                {en.common.clear}
              </FormButton>
            )
          }
        />
      )}
    </>
  );
};

const form = reduxForm({
  form: FORM.passportDetails,
  validate,
  enableReinitialize: true,
  touchOnBlur: true,
  touchOnChange: true,
  destroyOnUnmount: true,
})(EditPassportDetails);

const connectForm = connect((state: RootState) => {
  let passportDetailInitialValues = {};
  const isLT = state.bookingSearch.travelType === TRAVEL_PURPOSE.employeeLeisureTravel;

  // noted that index is belong to Redux `ltSelectTravellerList`
  const editTravellerKeyIndex = state.bookingSearch.leisureTravelDetails.editTravellerKeyIndex;

  // Do when it is LT
  if (isLT && typeof editTravellerKeyIndex === 'number' && editTravellerKeyIndex >= 0) {
    const traveller = state?.bookingSearch?.leisureTravelDetails?.ltSeatConcessionTravellerList?.find(
      (traveller: INominatedPassenger) => traveller.keyIndex === editTravellerKeyIndex,
    );

    const { firstName, lastName, passportDetail } = traveller || {};

    if (passportDetail) {
      // Second and after entry
      passportDetailInitialValues = {
        passportName:
          getDisplayName({
            firstName: passportDetail.passportFirstName,
            lastName: passportDetail.passportLastName,
          }) || passportDetail.passportName,
        dateOfBirth: passportDetail.dateOfBirth,
        gender: passportDetail.gender,
        passportNumber: passportDetail.passportNumber,
        expiryDate: passportDetail.passportExpirationDate,
        nationality: passportDetail.passportNationality,
        issuingCountry: passportDetail.passportCountry,
      };
    } else {
      // First entry
      passportDetailInitialValues = {
        passportName: getDisplayName({
          firstName,
          lastName,
        }),
      };
    }
  }

  return {
    initialValues: isLT ? passportDetailInitialValues : state.bookingSearch.passportDetails,
    docs: state.bookingSearch.docsDoca.docs,
    formValues: getFormValues(FORM.passportDetails)(state),
  };
})(form);

connectForm.displayName = FORM.passportDetails;

export default connectForm;
