import React from "react";
import { Box, useTheme, SxProps, Theme } from "@mui/material";
import _ from "lodash";

import { bookingAction } from "../../interfaces";

import { FormButton } from "..";

const MoreActionContainer = ({
  footerActionList,
  customStyles,
  customButtonStyles,
}: {
  footerActionList: bookingAction;
  customStyles?: SxProps<Theme>;
  customButtonStyles?: SxProps<Theme>;
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        background: "white",
        ...customStyles,
      }}
    >
      {_.values(footerActionList).map((item: any) => (
        <FormButton
          theme={theme}
          key={item.title}
          color={item.color || "success"}
          colour={item.colour || "green"}
          variant={item.variant || "contained"}
          size={item.size || "large"}
          onClick={() => {
            item?.handleClick?.();
          }}
          disabled={!!item.status}
          customStyles={{
            marginLeft: "auto",
            borderRadius: 8,
            height: 44,
            ...customButtonStyles,
            ...item.buttonStyles,
          }}
        >
          {item.title}
        </FormButton>
      ))}
    </Box>
  );
};

MoreActionContainer.defaultProps = {
  customStyles: {},
  customButtonStyles: {},
};

export default MoreActionContainer;
