import { Typography, useTheme } from '@mui/material';
import { useHistory } from 'react-router-dom';

import {
  setRole,
  setAdminRoles,
  setAllowDelegation,
  setDelegatedBy,
  getConcessionThunk,
  getConcessionAdminThunk,
  getConcessionDelegationThunk,
} from '../slice/userSlice';
import { getRole } from '../services/user';

import { USER_ROLE } from '../constants/constants';
import { USER_PROFILE_PATHS } from '../constants/paths';
import { getDisplayVal } from './booking';
import en from '../translations/en';

async function retrieveUserModeStatus(userId: string, dispatch: any) {
  const { user } = (await getRole()) || {};

  const { role, adminRoles, allowDelegation, delegatedBy } = user || {};
  const { type: roleType, id: roleId } = role || {};

  dispatch(setRole({ ...role }));
  dispatch(setAdminRoles(adminRoles));
  dispatch(setAllowDelegation(allowDelegation));
  dispatch(setDelegatedBy(delegatedBy));

  if (roleType === USER_ROLE.admin) {
    dispatch(getConcessionAdminThunk());
  } else if (roleType === USER_ROLE.delegation) {
    dispatch(getConcessionDelegationThunk(roleId));
  } else {
    dispatch(getConcessionThunk(userId));
  }
}

const handleBackToUserProfile = (history: ReturnType<typeof useHistory>) => {
  /* Handle below pages go back to Profile menu page:
    - payment method
    - payment history
  */
  if ([USER_PROFILE_PATHS.paymentMethod, USER_PROFILE_PATHS.paymentHistory].includes(history?.location?.pathname)) {
    history?.push(USER_PROFILE_PATHS.menu);
  } else if (history?.location?.pathname === USER_PROFILE_PATHS.menu) {
    // handle Profile menu page go back to profile page (mobile view)
    history?.push(USER_PROFILE_PATHS.root);
  } else {
    history?.goBack();
  }
};
const flownSuspensionPopup = (
  startDate: string | Date,
  endDate: string | Date,
  content: string,
  fontWeight: number,
  link?: string,
) => {
  const theme = useTheme();
  const replaceStartDateContent = content.split(en.userProfile.flownSuspension.startDate);
  const replaceEndDateContent = replaceStartDateContent[1].split(en.userProfile.flownSuspension.endDate);
  const replaceLinkContent = replaceEndDateContent[1].split(link || '');
  const replaceEndDateContentTemp = (
    <Typography
      variant={link ? 'body_1_medium' : 'body_2_regular'}
      sx={{
        color: theme.color.secondary.dark_grey.option_1,
        display: 'inline',
      }}
    >
      {replaceStartDateContent[0]}
      <Typography
        variant={link ? 'body_1_medium' : 'body_2_regular'}
        sx={{
          color: theme.color.secondary.dark_grey.option_1,
          fontWeight: fontWeight,
          display: 'inline',
        }}
      >
        {getDisplayVal(startDate)}
      </Typography>
      {replaceEndDateContent[0]}
      <Typography
        variant={link ? 'body_1_medium' : 'body_2_regular'}
        sx={{
          color: theme.color.secondary.dark_grey.option_1,
          fontWeight: fontWeight,
          display: 'inline',
        }}
      >
        {getDisplayVal(endDate)}
      </Typography>
      {link ? (
        <>
          {replaceLinkContent[0]}
          <Typography
            variant={link ? 'body_1_medium' : 'body_2_regular'}
            sx={{
              display: 'inline',
              cursor: 'pointer',
              color: theme.color.utility.link.option_3,
            }}
            onClick={() => {
              // onclick action
            }}
          >
            {en.userProfile.flownSuspension.link}
          </Typography>
          {replaceLinkContent[1]}
        </>
      ) : (
        replaceEndDateContent[1]
      )}
    </Typography>
  );
  return replaceEndDateContentTemp;
};

export { retrieveUserModeStatus, handleBackToUserProfile, flownSuspensionPopup };
