import { INFO_PATHS } from '../../constants/paths';
import { IRouteConfig } from '../../interfaces';
import InfoUtility from './InfoUtility';

const infoRouteConfig: IRouteConfig[] = [
  {
    path: INFO_PATHS.info,
    component: InfoUtility,
    exact: false,
  },
];

export default infoRouteConfig;
