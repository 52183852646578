import React, { useMemo } from 'react';
import { ToggleButton, ToggleButtonGroup, Typography, Theme, useTheme, SxProps, SvgIcon } from '@mui/material';
import { TickIcon } from '../../assets/images';

import { IButtonDetails } from '../../interfaces';

enum RADIO_BUTTON_GROUP_VARIANT {
  CHIP = 'chip',
  REGULAR = 'regular',
  ICON = 'icon',
}

interface IRadioButtonGroupProps {
  defaultVal?: any;
  options: IButtonDetails[];
  onChange: (value: any) => void;
  variant?: RADIO_BUTTON_GROUP_VARIANT;
  titleVariant?: any;
  customButtonStyle?: SxProps<Theme>;
}

const getVariantStyle = (variant: RADIO_BUTTON_GROUP_VARIANT, theme: Theme) => {
  switch (variant) {
    case RADIO_BUTTON_GROUP_VARIANT.REGULAR:
    case RADIO_BUTTON_GROUP_VARIANT.ICON: {
      const isIcon = variant === RADIO_BUTTON_GROUP_VARIANT.ICON;
      return {
        '&:not(:first-of-type), &:first-of-type': {
          borderRadius: 1,
          border: 0,
          borderTopColor: 'borderColor.main',
          boxShadow: theme.palette.boxShadow.dark,
          ...(isIcon && { mx: 1 }),
        },
        '&:not(:first-of-type):hover, &:first-of-type:hover': {
          background: 'transparent',
        },
        '&.Mui-selected, &.Mui-selected:hover': {
          color: theme.color.secondary.dark_grey.option_1,
          border: `2px solid ${theme.color.utility.link.option_3}`,
          background: theme.palette.bgColor.light,
          boxShadow: theme.boxShadow.selected,
        },
      };
    }
    case RADIO_BUTTON_GROUP_VARIANT.CHIP:
      return {
        '&:not(:first-of-type)': {
          borderRadius: 3,
          borderTopColor: 'borderColor.main',
        },
        '&:first-of-type': {
          borderRadius: 3,
          borderTopColor: 'borderColor.main',
        },
        '&.Mui-selected, &.Mui-selected:hover': {
          background: theme.palette.gray.dark,
          color: 'white',
        },
      };
  }
};

const RadioButtonGroup = ({
  defaultVal: defaultId,
  options,
  variant = RADIO_BUTTON_GROUP_VARIANT.REGULAR,
  titleVariant,
  onChange,
  customButtonStyle,
}: IRadioButtonGroupProps) => {
  const handleChange = (event: React.MouseEvent<HTMLElement>, value: any) => {
    if (value !== null) {
      onChange(value);
    }
  };

  const theme = useTheme();
  const isIcon = variant === RADIO_BUTTON_GROUP_VARIANT.ICON;

  const toggleButtonGroupStyle = useMemo(() => {
    return {
      '&.MuiToggleButtonGroup-root': {
        background: 'transparent',
        width: '100%',
        ...(isIcon && {
          display: 'grid',
          'grid-template-columns': 'repeat(2, 50%)',
        }),
      },
      '& .MuiToggleButtonGroup-grouped': {
        mx: 1,
        my: 2,
        color: theme.color.secondary.dark_grey.option_1,
        background: 'white',
        '&.Mui-disabled': {
          background: theme.color.utility.unselectable.option_7,
          color: theme.color.secondary.grey.option_2,
          boxShadow: 'none !important',
        },
        ...getVariantStyle(variant, theme),
      },
    };
  }, [variant, theme]);

  return (
    <ToggleButtonGroup
      sx={toggleButtonGroupStyle}
      orientation={variant === RADIO_BUTTON_GROUP_VARIANT.REGULAR ? 'vertical' : 'horizontal'}
      exclusive
      onChange={handleChange}
    >
      {options.map((option) => {
        const {
          id,
          val,
          title,
          subtitle,
          isDisabled = false,
          disabledSubtitle,
          customSubtitleStyle,
          expandedComponent,
          imgSrc: ImgSrc,
          icon,
        } = option || {};

        const isCurrentSelected = id === defaultId;
        const isShowError = isDisabled && disabledSubtitle;
        const displaySubtitle = isShowError ? disabledSubtitle : subtitle;

        return (
          <ToggleButton
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
              boxShadow: 'boxShadow.main',
              ...customButtonStyle,
            }}
            key={id}
            value={{ id, val, displaySubtitle }}
            disabled={isDisabled}
            selected={isCurrentSelected}
          >
            {isCurrentSelected && ImgSrc && <TickIcon style={{ position: 'absolute', left: 10, top: 10 }} />}
            {ImgSrc && (isDisabled ? <ImgSrc fill={theme.color.secondary.grey.option_5} /> : <ImgSrc />)}
            {icon && (
              <SvgIcon
                component={icon}
                viewBox="0 0 18 18"
                sx={{
                  color: isCurrentSelected ? 'white' : '#505050',
                  width: '18px',
                  height: '18px',
                  mr: 0.5,
                }}
              />
            )}
            <Typography
              variant={titleVariant || 'body_1_regular'}
              color={isCurrentSelected && variant === RADIO_BUTTON_GROUP_VARIANT.CHIP ? 'white' : 'inherit'}
              component="div"
              align="left"
              sx={{ pt: 0.125, whiteSpace: 'pre-line' }}
            >
              {title}
            </Typography>

            {isCurrentSelected && expandedComponent
              ? expandedComponent
              : displaySubtitle && (
                  <Typography
                    variant="navigation_medium"
                    color={isShowError ? theme.color.utility.error.option_2 : theme.color.utility.link.option_2}
                    sx={{
                      ...(!isIcon && {
                        backgroundColor: theme.color.secondary.light_slate.option_4,
                        borderRadius: 2,
                      }),
                      py: 0.5,
                      px: 1.5,
                      mt: 0.75,
                      ...customSubtitleStyle,
                    }}
                  >
                    {displaySubtitle}
                  </Typography>
                )}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};

RadioButtonGroup.defaultProps = {
  isChip: false,
  travelTypeButtonStyle: {},
};

export default RadioButtonGroup;
export { RADIO_BUTTON_GROUP_VARIANT };
