import React from 'react';
import { Box, Typography, SxProps } from '@mui/material';

import en from '../../../../translations/en';

const NoMatchResult = ({ boxStyles }: { boxStyles: SxProps }) => (
  <Box sx={boxStyles}>
    <Typography sx={{ textAlign: 'center' }} variant="body2" color="gray.dark">
      {en.booking.searchFlight.noMatch}
    </Typography>
  </Box>
);

export default NoMatchResult;
