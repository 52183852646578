import { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { use100vh } from 'react-div-100vh';

import {
  DATE_FORMAT,
  LABEL_CATEGORY,
  SEAT_CLASS_TYPE_MAPPING,
  TRAVEL_PURPOSE,
  UI_STYLES,
} from '../../../constants/constants';
import en from '../../../translations/en';
import { BookingSummaryState } from '../../../context';

import { Frontend } from 'booking';

import {
  formatDateAsString,
  getDisplayLabel,
  getDisplayVal,
  getPassengerDisplayName,
  isOWOALBooking,
} from '../../../helpers';

import { useAppSelector } from '../../../app/hooks';

import { selectConfiguration } from '../../../slice/configurationSlice';
import { selectApp } from '../../../slice/appSlice';

import { HorizontalDivider, ScrollableView, ShadowContent } from '../../../components';

const InfoLayout = ({ data }: { data: { title: string; info?: string } }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        mx: 1,
        alignItems: 'center',
        textAlign: 'start',
      }}
    >
      <Typography sx={{ display: 'flex', flex: 1 }}>{data.title}</Typography>
      <Typography sx={{ display: 'flex', flex: 1 }}>{getDisplayVal(data.info)}</Typography>
    </Box>
  );
};

const CancelBookingDetail = ({ bookingResult }: { bookingResult: Frontend.Booking }) => {
  const screenHeight = use100vh();

  const { booking } = bookingResult || {};

  const { isDesktop } = useAppSelector(selectApp) || {};

  const { recLoc, type: travelType, concession, ticketsBySegment } = booking || {};
  const {
    carrier,
    flightNum,
    departureDate,
    departureTime,
    arrivalTime,
    departurePort,
    arrivalPort,
    bookingParentClass,
    status,
  } = (ticketsBySegment && ticketsBySegment[0]) || {};

  const { travellerList } = useContext(BookingSummaryState) || {};
  const remainingTravellerList = travellerList?.filter((item: any) => !item.isRemoved) || [];

  const { configurations } = useAppSelector(selectConfiguration) || {};
  const segmentStatus = getDisplayLabel(configurations.labels, LABEL_CATEGORY.segmentStatus, status);

  const isDT = travelType === TRAVEL_PURPOSE.employeeDutyTravel;
  const isOWOALFlight = isOWOALBooking(flightNum);

  const flightDateTimeDetails = [
    {
      title: en.booking.confirmation.confirmCancel.flightDate,
      info: `${formatDateAsString(departureDate, DATE_FORMAT.ddmmmyyyy, DATE_FORMAT.date)}`,
    },
    {
      title: en.booking.confirmation.confirmCancel.flightTime,
      info: `${getDisplayVal(departureTime)}-${getDisplayVal(arrivalTime)}`,
    },
  ];

  const bookingDetails = [
    {
      title: en.booking.confirmation.confirmCancel.flightNo,
      info: isOWOALFlight ? getDisplayVal(carrier) : `${getDisplayVal(carrier)}${getDisplayVal(flightNum)}`,
    },
    ...(isOWOALFlight ? [] : flightDateTimeDetails),
    {
      title: en.booking.confirmation.confirmCancel.tripType,
      info: isDT ? en.booking.travelType.dutyTravel : en.booking.travelType.leisureTravel.title,
    },
    {
      title: en.booking.confirmation.confirmCancel.od,
      info: `${getDisplayVal(departurePort)} ${en.booking.selectFlight.to} ${getDisplayVal(arrivalPort)}`,
    },
    {
      title: en.booking.confirmation.confirmCancel.cabinClass,
      info: SEAT_CLASS_TYPE_MAPPING[bookingParentClass],
    },
    {
      title: en.booking.confirmation.confirmCancel.status,
      info: segmentStatus,
    },
  ];

  const sortedTickets = remainingTravellerList?.slice().sort((a: any, b: any) => {
    return a.traveler.ticketNum - b.traveler.ticketNum;
  });

  const bookingTravellers =
    sortedTickets &&
    sortedTickets.map((ticket: any) => {
      return getPassengerDisplayName(ticket?.traveler);
    });

  return (
    <Box
      component={ScrollableView}
      sx={{
        pb: 2,
        px: 1,
        height: isDesktop
          ? `calc(${screenHeight}px - ${UI_STYLES.footerHeight}` // footer button + padding
          : `calc(${screenHeight}px - ${UI_STYLES.footerHeight} - ${UI_STYLES.overlayHeaderHeightBookingFlow} + 56px )`, // +56px -> mt: -7
        borderRadius: 1,
      }}
    >
      <Box component={isDesktop ? 'div' : ShadowContent}>
        <Box component={Typography} variant="headline_bold" sx={{ mx: 'auto', mt: 1 }}>
          {`${en.booking.confirmation.bookingReference}: ${isOWOALFlight ? en.common.empty : getDisplayVal(recLoc)}`}
        </Box>

        <HorizontalDivider />

        {bookingDetails.map((item, index) => {
          return <InfoLayout key={index} data={item} />;
        })}

        <HorizontalDivider />

        <InfoLayout
          data={{
            title: en.booking.confirmation.concession,
            info: concession?.name,
          }}
        />

        <HorizontalDivider />

        <Box component={Typography} variant="body_2_bold" sx={{ mx: 1, mb: 1, textAlign: 'start' }}>
          {en.booking.travelType.traveller}
        </Box>

        {bookingTravellers &&
          bookingTravellers.map((traveller: string, index: number) => {
            return (
              <Typography
                key={index}
                variant="body_2_regular"
                sx={{
                  mx: 1,
                  mb: 0.5,
                  textAlign: 'start',
                }}
              >
                {traveller}
              </Typography>
            );
          })}
      </Box>
    </Box>
  );
};

export default CancelBookingDetail;
