import React, { useContext } from 'react';
import { useTheme } from '@mui/material';

import en from '../../../translations/en';

import { checkValidDateRange } from '../../../helpers';

import { useAppDispatch } from '../../../app/hooks';
import { EditPaymentHistoryAction, EditPaymentHistoryState } from '../../../context/PaymentHistoryContext';
import {
  // resetPaymentHistorySearch,
  setSelectedPaymentHistoryPeriod,
} from '../../../slice/userSlice';

import { FormButton, Footer } from '../../../components';
import PaymentHistorySearchDetails from './PaymentHistorySearchDetails';

const PaymentHistorySearch = ({ handleFooterClick }: { handleFooterClick?: () => void }) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const editPaymentHistorySearch = useContext(EditPaymentHistoryState) || {};
  const { editPeriod } = editPaymentHistorySearch || {};
  const { from, to } = editPeriod || {};

  const editPaymentHistorySearchAction = useContext(EditPaymentHistoryAction) || {};
  const { resetPaymentHistory } = editPaymentHistorySearchAction || {};

  const isApplyButtonValid = !!from && !!to && checkValidDateRange(from, to);

  return (
    <>
      <PaymentHistorySearchDetails />

      <Footer
        leftChild={
          <FormButton
            theme={theme}
            colour="transparent"
            size="large"
            sx={{
              pl: 0,
              height: '44px',
              display: 'flex',
              justifyContent: 'center',
              ...{ minWidth: 0 },
            }}
            onClick={() => {
              resetPaymentHistory();
            }}
          >
            {en.common.clear}
          </FormButton>
        }
        primaryBtn={
          isApplyButtonValid
            ? {
                text: en.common.apply,
                customButtonStyles: {
                  marginRight: 0,
                  width: '128px',
                  minWidth: 'none',
                },
                customOnClick: () => {
                  dispatch(setSelectedPaymentHistoryPeriod(editPeriod));

                  handleFooterClick?.();
                },
              }
            : undefined
        }
        customStyles={{
          py: 0,
        }}
      />
    </>
  );
};

export default PaymentHistorySearch;
