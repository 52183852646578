import React from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme } from '@mui/material';

import { useAppSelector } from '../../app/hooks';
import { selectApp } from '../../slice/appSlice';

import en from '../../translations/en';

const DialogComponent = ({
  open,
  title,
  message,
  IconComponent,
  buttonComponent,
  customDialogActionStyles,
  noErrorAlert,
}: {
  open: boolean;
  title: string;
  message?: string | React.ReactNode;
  IconComponent?: React.ReactNode;
  buttonComponent: React.ReactNode;
  customDialogActionStyles?: any;
  noErrorAlert?: boolean;
}) => {
  const theme = useTheme();
  const { isDesktop } = useAppSelector(selectApp) || {};

  return (
    <Dialog
      sx={{
        '& .MuiDialog-container': {
          alignItems: 'baseline',
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: isDesktop ? '478px' : '343px',
            borderRadius: 2,
            m: 0,
            top: isDesktop ? '100px' : '55px',
            overflowX: 'hidden',
            boxShadow: theme.boxShadow.important,
          },
        },
      }}
      open={open}
    >
      {IconComponent && <Box sx={{ textAlign: 'center', mt: 6, mb: 4, height: '148px' }}>{IconComponent}</Box>}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          mt: IconComponent ? 0 : 3,
        }}
      >
        <DialogTitle
          sx={{
            textAlign: 'center',
            pb: 0,
            px: 0,
            mb: 1.125,
            width: isDesktop ? '312px' : '279px',
            lineHeight: '30px',
            fontWeight: '700',
            fontSize: '24px',
            color: theme.palette.text.secondary,
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent sx={{ py: 0, mt: isDesktop ? 2 : 0, px: isDesktop ? 4.5 : 4 }}>
          <DialogContentText
            sx={{
              textAlign: 'center',
              width: isDesktop ? '406px' : '279px',
              lineHeight: '24px',
              fontWeight: '400',
              fontSize: '16px',
              color: theme.palette.text.secondary,
              ...(!!IconComponent && {
                mx: 'auto',
              }),
            }}
          >
            {!noErrorAlert && <Box>{en.common.followingReason}</Box>}
            <Box style={{ whiteSpace: 'pre-wrap' }}>{message}</Box>
          </DialogContentText>
        </DialogContent>
      </Box>
      <DialogActions
        sx={{
          p: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          ...customDialogActionStyles,
        }}
      >
        {buttonComponent}
      </DialogActions>
    </Dialog>
  );
};

export default DialogComponent;
