import React, { useEffect, useState, useRef } from 'react';
import { Box, Typography, useTheme, Button, Tooltip, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { use100vh } from 'react-div-100vh';

import en from '../../translations/en';
import { ADMIN_BOOKING_SEARCH_TYPE, UI_STYLES } from '../../constants/constants';

import { Frontend } from 'booking';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { ICancelBookingSuccessData, selectApp, setCancelBookingSuccessData } from '../../slice/appSlice';

import { getAdminMyBookings } from '../../services/admin/booking';

import { BOOKING_LAYOUT_VARIANT } from '../MyBooking/BookingLayout';
import { BookingSummaryOverlay, BookingLayout } from '..';
import { ScrollableView, ShadowContent } from '../../components';

const AdminBookings = ({
  ernOrBookingReference,
  searchResult,
  isShowDefaultView,
  setIsShowDefaultView,
  refreshAfterCancelBooking,
  resetToDefaultView,
  searchedBooking,
  headerComponent,
}: {
  ernOrBookingReference: string;
  searchResult: any;
  isShowDefaultView: boolean;
  setIsShowDefaultView: any;
  refreshAfterCancelBooking: any;
  resetToDefaultView?: () => void;
  searchedBooking?: Frontend.ParsedBooking | null;
  headerComponent?: React.ReactNode;
}) => {
  const dispatch = useAppDispatch();
  const { cancelBookingSuccessData } = useAppSelector(selectApp) || {};

  const [myBookings, setMyBookings] = useState<Frontend.ParsedBooking[]>([]);
  const [bookingSelected, setBookingSelected] = useState<Frontend.ParsedBooking | null>(null);
  const [callingGetMyBooking, setCallingGetMyBooking] = useState(false);
  const [isLoadApi, setIsLoadApi] = useState(false);

  const scrollViewRef = useRef<HTMLDivElement>(null);

  const getMyBookingAction = async (cancelBookingSuccessData?: ICancelBookingSuccessData | null) => {
    const results = await getAdminMyBookings();

    setCallingGetMyBooking(false);
    setIsLoadApi(true);

    if (cancelBookingSuccessData && !cancelBookingSuccessData.isShow) {
      if (scrollViewRef.current) {
        scrollViewRef.current.scrollTop = 0;
      }

      if (ernOrBookingReference.length === ADMIN_BOOKING_SEARCH_TYPE.default) {
        if (results) {
          setMyBookings(results);
        }
      } else if (ernOrBookingReference.length === ADMIN_BOOKING_SEARCH_TYPE.searchByRecLoc) {
        // cancel from search by recLoc data
        setIsLoadApi(false);
        setIsShowDefaultView();
      } else if (ernOrBookingReference.length === ADMIN_BOOKING_SEARCH_TYPE.searchByErn) {
        // cancel from search by userId list
        refreshAfterCancelBooking();
      }

      dispatch(
        setCancelBookingSuccessData({
          ...cancelBookingSuccessData,
          isShow: cancelBookingSuccessData?.removePaxLabel ? false : true,
          removePaxLabel: undefined,
        }),
      );
    } else {
      if (results) {
        setMyBookings(results);
      }
    }
  };

  useEffect(() => {
    if (callingGetMyBooking) {
      getMyBookingAction(cancelBookingSuccessData);
    }
  }, [callingGetMyBooking]);

  useEffect(() => {
    if (cancelBookingSuccessData?.flightNumber || cancelBookingSuccessData?.removePaxLabel) {
      setCallingGetMyBooking(true);
    }
  }, [cancelBookingSuccessData?.flightNumber, cancelBookingSuccessData?.removePaxLabel]);

  useEffect(() => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTop = 0;
    }

    setMyBookings(searchResult);
    setIsLoadApi(false);
  }, [searchResult]);

  // set selected booking data when searched some booking data
  useEffect(() => {
    if (searchedBooking) {
      setBookingSelected(searchedBooking);
    }
  }, [searchedBooking]);

  const screenHeight = use100vh();
  const theme = useTheme();

  const errorMessage =
    isLoadApi || !!(searchResult && searchResult.length === 0)
      ? ernOrBookingReference.length !== ADMIN_BOOKING_SEARCH_TYPE.searchByErn
        ? en.admin.feature.adminBooking.noBookingsFound
        : `${en.booking.travelType.noErnResult} ${en.booking.travelType.tryAgain}`
      : en.admin.feature.adminBooking.adminBookingPlaceHolder;

  return (
    <>
      <Box
        component={ScrollableView}
        ref={scrollViewRef}
        sx={{
          height: `calc(${screenHeight}px - ${UI_STYLES.desktopHeaderHeight} - ${UI_STYLES.indicatorBarHeight} )`,
          background: theme.color.secondary.light_slate.option_7,
        }}
      >
        {headerComponent}

        <Box sx={{ display: 'flex', pl: 'calc(50vw - 480px)', mt: 4.5 }}>
          <Typography variant="title_1_medium" color={theme.color.secondary.dark_grey.option_3}>
            {en.home.adminBooking}
          </Typography>

          {isShowDefaultView ? (
            <Tooltip title={en.admin.feature.adminBooking.adminBookingTooltip}>
              <Typography
                component={Button}
                variant="body_1_bold"
                sx={{
                  ml: 3,
                  color: theme.color.utility.link.option_3,
                }}
                onClick={() => {
                  setCallingGetMyBooking(true);
                }}
              >
                {en.home.viewAll}
              </Typography>
            </Tooltip>
          ) : (
            <Box
              component={ShadowContent}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                ml: 3,
                bgcolor: 'white',
                height: '40px',
                width: '148px',
                borderRadius: '20px',
                pl: 2,
                pr: 1,
              }}
            >
              <Typography
                variant="body_1_medium"
                sx={{
                  color: theme.color.secondary.dark_grey.option_1,
                }}
              >
                {ernOrBookingReference}
              </Typography>

              <IconButton
                onClick={() => {
                  setIsShowDefaultView();
                }}
              >
                <CloseIcon
                  sx={{
                    p: '1px',
                    width: '16px',
                    height: '16px',
                    borderRadius: '50%',
                    bgcolor: theme.color.secondary.grey.option_3,
                    color: 'white',
                  }}
                />
              </IconButton>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            py: 2,
            mb: 6,
            width: '960px',
            mx: 'auto',
          }}
        >
          {myBookings && myBookings.length > 0 ? (
            <BookingLayout
              bookings={myBookings}
              emptyTitle={en.home.noBooking}
              handleClick={(selectedItem: Frontend.ParsedBooking) => {
                setBookingSelected(selectedItem);
              }}
              variant={BOOKING_LAYOUT_VARIANT.ADMIN_BOOKING_PAGE}
            />
          ) : (
            <Typography variant="body_1_regular" color={theme.color.secondary.dark_grey.option_3}>
              {errorMessage}
            </Typography>
          )}
        </Box>
      </Box>

      <BookingSummaryOverlay
        bookingSelected={bookingSelected}
        handleClose={() => {
          if (ernOrBookingReference && ernOrBookingReference.length === ADMIN_BOOKING_SEARCH_TYPE.searchByRecLoc) {
            resetToDefaultView?.();
          }

          setBookingSelected(null);
        }}
        data={{
          applicationId: bookingSelected?.applicationId,
          type: bookingSelected?.type,
        }}
      />
    </>
  );
};

export default AdminBookings;
