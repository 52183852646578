import React, { useState, useContext } from "react";
import { Grid } from "@mui/material";
import { Employee } from "employee";
import _ from "lodash";

import {
  LABEL_VALUE_TYPE,
  LABEL_CATEGORY,
  DATE_FORMAT,
} from "../../../constants/constants";
import en from "../../../translations/en";

import { IAdminToggleBarTabListProps } from "../../../interfaces";

import { formatDateAsString } from "../../../helpers";

import { AdminAccessEmployeeState } from "../../../context";

import AdminToggleButtonGroup from "./AdminToggleButtonGroup";
import DetailsContainer from "./DetailsContainer";
import LabelValueContainer, { IDisplayDetails } from "./LabelValueContainer";

interface IMassagedPersonalInfoData {
  profileDetails: IDisplayDetails[];
  employmentDetails: IDisplayDetails[];
  paymentDetails: IDisplayDetails[];
  benefitDetails: IDisplayDetails[];
  contactDetails: IDisplayDetails[];
  passportDetails: IDisplayDetails[];
}
interface IAdminPersonalInfoDetailsProps {
  defaultId: number;
}

const AdminPersonalInfoDetails = (props: IAdminPersonalInfoDetailsProps) => {
  const [adminMaintainId, setAdminMaintainId] = useState(props.defaultId);
  const { employeeDetail } = useContext(AdminAccessEmployeeState) || {};
  const {
    personalInfo: personalInfoData,
    profile: profileData,
    passportDetail: passportData,
  } = employeeDetail || {};

  const employeePassport =
    passportData.find(
      (item: Employee.PassportInfo) => item.dependentId === null,
    ) || {};

  // 1. Call api to get api from the backend
  // Since api not ready, now just use Sample data.
  const { all, profile, employment, payment, benefit, contact, passport } =
    en.admin.adminToggleList;

  const { personalInformation } = en.admin.feature;
  const {
    profileDetails: profileDetailsLbl,
    employmentDetails: employmentDetailsLbl,
    paymentDetails: paymentDetailsLbl,
    benefitDetails: benefitDetailsLbl,
    contactDetails: contactDetailsLbl,
    passportDetails: passportDetailsLbl,
  } = personalInformation;

  // 2. Massage data for display
  const MassagedPersonalInfoData: IMassagedPersonalInfoData = {
    profileDetails: [
      {
        label: profileDetailsLbl.title,
        value: personalInfoData.title,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: profileDetailsLbl.firstName,
        value: personalInfoData.firstName,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: profileDetailsLbl.middleName,
        value: personalInfoData.middleName,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: profileDetailsLbl.surname,
        value: personalInfoData.lastName,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: profileDetailsLbl.preferredFirstName,
        value: personalInfoData.firstNamePreferred,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: profileDetailsLbl.preferredLastName,
        value: personalInfoData.lastNamePreferred,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: profileDetailsLbl.gender,
        value: personalInfoData.gender,
        type: LABEL_VALUE_TYPE.CODELABELS,
        configurationField: LABEL_CATEGORY.gender,
        confidential: true,
        column: 4,
      },
      {
        label: profileDetailsLbl.dateOfBirth,
        value: formatDateAsString(
          personalInfoData.dateOfBirth,
          DATE_FORMAT.ddmmmyyyy,
          DATE_FORMAT.date,
        ),
        type: LABEL_VALUE_TYPE.STRING,
        confidential: true,
        column: 4,
      },
      {
        label: profileDetailsLbl.employeeID,
        value: personalInfoData.employeeId,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: profileDetailsLbl.company,
        value: profileData.hiringCompany,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: profileDetailsLbl.countryOfResidence,
        value: personalInfoData.countryOfResidential,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: profileDetailsLbl.maritalStatus,
        value: personalInfoData.maritalStatus,
        type: LABEL_VALUE_TYPE.STRING,
        options: en.labels.maritalStatusOptions, // Need to get from configuration
        column: 4,
      },
      {
        label: profileDetailsLbl.maritalStatusAsOfDate,
        value: formatDateAsString(
          personalInfoData.maritalStatusEffectiveDate,
          DATE_FORMAT.ddmmmyyyy,
          DATE_FORMAT.date,
        ),
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
    ],
    employmentDetails: [
      {
        label: employmentDetailsLbl.jobLevel,
        value: profileData.jobLevel,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.jobTitle,
        value: profileData.jobTitle,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.department,
        value: profileData.department,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.contractType,
        value: profileData.contractType,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.employmentType,
        value: profileData.employmentType,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.poiType,
        value: profileData.personOfInterestType,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.officerCode,
        value: profileData.officerCode,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.hrStatus,
        value: profileData.hrStatus,
        type: LABEL_VALUE_TYPE.STRING,
        options: en.labels.employmentStatusOptions,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.serviceStartDate,
        value: formatDateAsString(
          profileData.lastHireDate,
          DATE_FORMAT.ddmmmyyyy,
          DATE_FORMAT.date,
        ),
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.serviceEndDate,
        value: formatDateAsString(
          profileData.terminationDate,
          DATE_FORMAT.ddmmmyyyy,
          DATE_FORMAT.date,
        ),
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.travelEndDate,
        value: formatDateAsString(
          profileData.travelEndDate,
          DATE_FORMAT.ddmmmyyyy,
          DATE_FORMAT.date,
        ),
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.resignationCode,
        value: profileData.terminationActionReason,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.employeeCategory,
        value: profileData.employeeClassification,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.fullOrPartTime,
        value: profileData.fullPartTime,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.regularOrTemp,
        value: profileData.regularTemporary,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.domicilePort,
        value: profileData.domicilePort,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.recruitmentPort,
        value: profileData.recruitmentPort,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.countryOfEmployment,
        value: profileData.regulatoryRegion,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.workingLocation,
        value: profileData.location,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: employmentDetailsLbl.homePort,
        value: profileData.homePort,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
    ],
    paymentDetails: [
      {
        label: paymentDetailsLbl.paymentConcept,
        value: profileData.paymentConcept,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: paymentDetailsLbl.paymentMedium,
        value: profileData.paymentMedium,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: paymentDetailsLbl.directDebitIndicator,
        value: profileData.directDebitIndicator,
        options: [en.common.yes, en.common.no],
        type: _.isNull(profileData.directDebitIndicator)
          ? LABEL_VALUE_TYPE.STRING
          : LABEL_VALUE_TYPE.BOOLEAN,
        confidential: false,
        column: 4,
      },
      {
        label: paymentDetailsLbl.billingAagreementSetup,
        value: profileData.billingAgreements,
        options: [en.common.yes, en.common.no],
        type: _.isNull(profileData.billingAgreements)
          ? LABEL_VALUE_TYPE.STRING
          : LABEL_VALUE_TYPE.BOOLEAN,
        confidential: false,
        column: 4,
      },
      // hide it base on 1548
      // {
      //   label: paymentDetailsLbl.flownSuspensionStartDate,
      //   value: profileData.flownSuspensionFromDate,
      //   type: LABEL_VALUE_TYPE.STRING,
      //   confidential: false,
      //   column: 4,
      // },
      // {
      //   label: profileData.flownSuspensionEndDate,
      //   value: profileData.flownSuspensionToDate,
      //   type: LABEL_VALUE_TYPE.STRING,
      //   confidential: false,
      //   column: 4,
      // },
    ],
    benefitDetails: [
      {
        label: benefitDetailsLbl.benefitJobLevelDuty,
        value: profileData.benefitDTJobLevel.jobLevel,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: benefitDetailsLbl.effectiveDateDuty,
        value: formatDateAsString(
          profileData.benefitDTJobLevel.from,
          DATE_FORMAT.ddmmmyyyy,
          DATE_FORMAT.date,
        ),
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: benefitDetailsLbl.profileStatus,
        value: profileData.isActive,
        options: [en.common.active, en.common.inactive],
        type: LABEL_VALUE_TYPE.BOOLEAN,
        confidential: false,
        column: 4,
      },
      {
        label: benefitDetailsLbl.benefitJobLevelLeisure,
        value: profileData.benefitLTJobLevel.jobLevel,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: benefitDetailsLbl.effectiveDateLeisure,
        value: formatDateAsString(
          profileData.benefitLTJobLevel.from,
          DATE_FORMAT.ddmmmyyyy,
          DATE_FORMAT.date,
        ),
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: benefitDetailsLbl.profileType,
        value: profileData.type,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: benefitDetailsLbl.age,
        value: personalInfoData.age,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: benefitDetailsLbl.yearOfService,
        value: `${profileData.yearOfService.years} ${en.dateUnit.years} ${profileData.yearOfService.months} ${en.dateUnit.months} ${profileData.yearOfService.days} ${en.dateUnit.days}`,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: benefitDetailsLbl.companySeniorityDate,
        value: formatDateAsString(
          profileData.companySeniorityDate,
          DATE_FORMAT.ddmmmyyyy,
          DATE_FORMAT.date,
        ),
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
    ],
    contactDetails: [
      {
        label: contactDetailsLbl.personalEmail,
        value: personalInfoData.personalEmail,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: contactDetailsLbl.officeEmail,
        value: personalInfoData.businessEmail,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      // hide it base on 1548
      // {
      //   label: contactDetailsLbl.notificationEmail,
      //   value: contactDetails.notificationEmail,
      //   type: LABEL_VALUE_TYPE.STRING,
      //   confidential: false,
      //   column: 4,
      // },
      {
        label: contactDetailsLbl.phoneNumberMobile,
        value: personalInfoData.mobilePhone,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: contactDetailsLbl.phoneNumberOffice,
        value: personalInfoData.workPhone,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
    ],
    passportDetails: [
      {
        label: passportDetailsLbl.passportNumber,
        value: employeePassport.passportNumber,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: passportDetailsLbl.firstOtherNameAsInPassport,
        value:
          employeePassport.passportFirstName ||
          employeePassport.passportLastName,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: passportDetailsLbl.surnameAsInPassport,
        value: employeePassport.passportLastName,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: passportDetailsLbl.passportExpiryDate,
        value: formatDateAsString(
          employeePassport.passportExpirationDate,
          DATE_FORMAT.ddmmmyyyy,
          DATE_FORMAT.date,
        ),
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: passportDetailsLbl.nationalityInPassport,
        value: employeePassport.passportNationality,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: passportDetailsLbl.issuedCountry,
        value: employeePassport.passportCountry,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: passportDetailsLbl.countryOfResidence,
        value: personalInfoData.countryOfResidential,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
    ],
  };

  // The second toggle bar tab array
  const secondToggleBarTabList: IAdminToggleBarTabListProps[] = [
    {
      id: 6,
      title: all,
      component: <></>,
    },
    {
      id: 7,
      title: profile,
      component: <></>,
    },
    {
      id: 8,
      title: employment,
      component: <></>,
    },
    {
      id: 9,
      title: payment,
      component: <></>,
    },
    {
      id: 10,
      title: benefit,
      component: <></>,
    },
    {
      id: 11,
      title: contact,
      component: <></>,
    },
    {
      id: 12,
      title: passport,
      component: <></>,
    },
  ];

  return (
    <>
      <AdminToggleButtonGroup
        list={secondToggleBarTabList}
        onClick={(e: number) => {
          setAdminMaintainId(e);
        }}
        defaultValue={6}
      />

      {/* Profile Detail */}
      {Object.entries(MassagedPersonalInfoData).map(
        ([key, value]: [string, IDisplayDetails[]]) => {
          const matchItem = secondToggleBarTabList.find(
            (item) => item.id === adminMaintainId,
          );
          if (
            matchItem?.title === all ||
            key.toUpperCase().includes(matchItem?.title?.toUpperCase() || "")
          ) {
            return (
              <DetailsContainer
                key={key}
                title={
                  personalInformation[key as keyof typeof personalInformation]
                    .heading
                }
              >
                <Grid container spacing={2}>
                  {value.map((data: IDisplayDetails, index: number) => {
                    return <LabelValueContainer key={index} {...data} />;
                  })}
                </Grid>
              </DetailsContainer>
            );
          }
        },
      )}
    </>
  );
};

export default AdminPersonalInfoDetails;
