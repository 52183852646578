import en from '../../translations/en';
import { HTTP_REQUEST_TYPE, ADMIN_API } from '../../constants/api';

import { sendHttpRequest } from '../api';

const getProfile = async (userId: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: ADMIN_API.EMPLOYEE_USER_API.personalInfo,
    apiTitle: en.errorAlert.getProfile,
    payload: { userId },
  });
  return resp;
};

const getPassport = async (userId: string, expirationDate?: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: ADMIN_API.EMPLOYEE_USER_API.passportInfo,
    apiTitle: en.errorAlert.getPassport,
    payload: { userId, expirationDate },
  });
  return resp;
};

const getEndorser = async (userId?: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: ADMIN_API.EMPLOYEE_USER_API.endorsingStaff,
    apiTitle: en.errorAlert.getEndorser,
    payload: { userId },
  });
  return resp;
};

const validateEndorser = async (endorsingStaffGalaCXyId: string, userId?: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: ADMIN_API.EMPLOYEE_USER_API.endorsingStaff,
    apiTitle: en.errorAlert.validateEndorser,
    payload: { endorsingStaffGalaCXyId, userId },
  });
  return resp;
};

export { getProfile, getPassport, getEndorser, validateEndorser };
