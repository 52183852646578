import React, { useContext, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import _ from 'lodash';

import { UI_STYLES, NOMINATED_PASSENGER_AGE_TYPE } from '../../../constants/constants';
import en from '../../../translations/en';

import { getPassengerDisplayName, checkInfantTypeIsINSOrINF, removedPassengers } from '../../../helpers';

import { useAppSelector } from '../../../app/hooks';
import { selectApp } from '../../../slice/appSlice';
import { BookingSummaryState, BookingSummaryAction } from '../../../context';

import { ShadowContent, CommonWarning } from '../../../components';
import { BaseTravellerSelection, CheckedInTravellerSection } from '../..';

const WarningMessageComponent = ({ message }: { message: string }) => {
  const theme = useTheme();
  return (
    <CommonWarning
      isShowIcon={true}
      msgText={message}
      customStyles={{
        border: '1px solid',
        borderRadius: '4px',
        borderColor: theme.color.secondary.sand.option_3,
        background: theme.color.secondary.sand.option_8,
        p: 1.5,
        mt: 1.5,
      }}
    />
  );
};

const getPairSelectionByTravellerItem = (list: any, targetTravellerItem: any) => {
  // INF - infant without seat
  // if selected one of INF, then found the paired ADT item;
  // else if selected one of ADT, then found its paired one of INF.
  const travellerInfo = targetTravellerItem?.traveler;
  const isInfantWithoutSeat = travellerInfo?.type === NOMINATED_PASSENGER_AGE_TYPE.infant;
  const isAdult = travellerInfo?.type === NOMINATED_PASSENGER_AGE_TYPE.adult;

  // find paired infant data
  if (isAdult) {
    let infantDataIndex = 0;

    const infantData = list?.find((item: any, index: number) => {
      const isMatched =
        item.traveler.type === NOMINATED_PASSENGER_AGE_TYPE.infant &&
        travellerInfo.type !== NOMINATED_PASSENGER_AGE_TYPE.infant &&
        item.traveler.paxRefNum === travellerInfo.paxRefNum;

      if (isMatched) infantDataIndex = index;
      return isMatched;
    });

    return (
      infantData && {
        ...infantData,
        index: infantDataIndex,
      }
    );
  }

  // find paired adult data
  if (isInfantWithoutSeat) {
    let adultDataIndex = 0;
    const adultData = list?.find((item: any, index: number) => {
      const isMatched =
        item.traveler.type === NOMINATED_PASSENGER_AGE_TYPE.adult &&
        travellerInfo.type !== NOMINATED_PASSENGER_AGE_TYPE.adult &&
        item.traveler.paxRefNum === travellerInfo.paxRefNum;
      if (isMatched) adultDataIndex = index;
      return isMatched;
    });

    return (
      adultData && {
        ...adultData,
        index: adultDataIndex,
      }
    );
  }
};

const checkIfINFInTravellerList = (list: any) => {
  const isFound = !!list?.find((item: any) => {
    return item?.traveler?.type === NOMINATED_PASSENGER_AGE_TYPE.infant && !!item?.isRemoved;
  });

  return isFound;
};

const getWarningMessageByTravellerList = (list: any, isOnlyInfantOrChildPax: boolean) => {
  let msg = '';
  const enWarningMessageList = en.booking.confirmation.removePax.warningMessage;

  // handle case 1: more 2ADT + 1INF (infant without seat)
  // check if included INF in list
  const isDisplayInfantAssociatedMessage = checkIfINFInTravellerList(list);
  if (isDisplayInfantAssociatedMessage) {
    msg = enWarningMessageList.infantAssociated;
  }

  // handle case 2: only remaining 1INS  or CHDs
  if (isOnlyInfantOrChildPax) {
    msg = enWarningMessageList.updateInfo;
  }

  return msg;
};

const RemovePaxTravellerDetail = () => {
  const theme = useTheme();

  const { isDesktop } = useAppSelector(selectApp) || {};

  // remove pax flow
  const { removalTravellerList, removalFormData, travellerList } = useContext(BookingSummaryState) || {};
  const { setRemovalTravellerList, setRemovalFormData } = useContext(BookingSummaryAction) || {};

  const [isFormChecked, setIsFormChecked] = useState<boolean>(removalFormData?.isFormChecked);

  const remainingTravellerList = removalTravellerList?.filter((item: any) => !item.isRemoved) || [];

  // checkIn travellers
  const checkedInTravellerList = travellerList?.filter((item: any) => !item.isRemoved && !item.isAllowRemove) || [];

  const { isOnlyYoungChildLeft, isOnlyOneInfantLeft } =
    removedPassengers({
      passengers: _.concat(_.cloneDeep(remainingTravellerList), _.cloneDeep(checkedInTravellerList)),
    }) || {};

  // check if selected pax
  const isSelectedPax = removalTravellerList.some((item: any) => item.isRemoved);

  const hasReduxFormData = (): boolean => {
    return !!removalFormData?.hasTravelWithData;
  };

  /* Show warning for 2 cases:
    case 1: more 2ADT + 1INF (infant without seat)
    case 2: CHDs / 1INS (infant with seat) 
  */
  const warningMessage =
    // if select any pax && have not redux data
    isSelectedPax && !hasReduxFormData()
      ? getWarningMessageByTravellerList(removalTravellerList, isOnlyYoungChildLeft || isOnlyOneInfantLeft)
      : undefined;

  const handleTravellerSelection = (targetTravellerIndex: number) => {
    // set the selected traveller's `isRemoved` in context data `removalTravellerList`
    const targetTravellerItem = removalTravellerList?.[targetTravellerIndex];
    if (targetTravellerItem) {
      targetTravellerItem.isRemoved = !targetTravellerItem.isRemoved;
    }

    // handle pair selection case for more ADT + 1INF (infant without seat)
    const pairedTravellerItem = getPairSelectionByTravellerItem(removalTravellerList, targetTravellerItem);

    // revise traveller list data
    const tmpList = removalTravellerList.map((item: any, index: number) => {
      // handle selected traveller
      const isMatchedTargetSelectedTraveller = index === targetTravellerIndex;
      if (isMatchedTargetSelectedTraveller) return targetTravellerItem;

      // handle paired traveller
      const isMatchedPairedTraveller = pairedTravellerItem && index === pairedTravellerItem?.index;
      if (isMatchedPairedTraveller) {
        item.isRemoved = targetTravellerItem.isRemoved;
      }

      return item;
    });

    setRemovalTravellerList(tmpList);
  };

  const findAccompanyAdult = (targetTravellerItem: any) => {
    const accompanyAdult = removalTravellerList?.find(
      (tempItem: any) =>
        tempItem?.traveler?.paxRefNum === targetTravellerItem.traveler?.paxRefNum &&
        tempItem?.traveler?.type !== NOMINATED_PASSENGER_AGE_TYPE.infant,
    );
    return accompanyAdult;
  };

  const getTravellerTitle = (traveller: any) => {
    const { title, firstName, lastName } = traveller || {};
    return getPassengerDisplayName({ title, firstName, lastName });
  };

  return (
    <ShadowContent
      sx={{
        mb: 2,
        ...(isDesktop && {
          width: UI_STYLES.desktopBookingDetailsWidth,
        }),
      }}
    >
      <Typography color={theme.color.secondary.dark_grey.option_1} variant="body_1_bold">
        {en.booking.flightConfirmation.travelDetails.travellerDetails.title}
      </Typography>

      <Typography
        variant="body_2_regular"
        color={theme.color.secondary.dark_grey.option_1}
        sx={{
          mt: 0.75,
        }}
      >
        {en.booking.confirmation.removePax.selectToRemove}
      </Typography>

      {warningMessage && <WarningMessageComponent message={warningMessage} />}

      {removalTravellerList?.length > 0 && (
        <Box>
          {removalTravellerList.map((travellerItem: any, index: number) => {
            const travellerInfo = travellerItem?.traveler;

            // infant
            const isInfantType = checkInfantTypeIsINSOrINF(travellerInfo?.type);
            const isInfantWithSeat =
              isInfantType && travellerInfo?.type === NOMINATED_PASSENGER_AGE_TYPE.infantWithSeat;

            // accompany adult
            const accompanyAdult =
              isInfantType &&
              travellerItem.traveler?.type === NOMINATED_PASSENGER_AGE_TYPE.infant &&
              findAccompanyAdult(travellerItem);
            const accompanyAdultTitle =
              accompanyAdult &&
              `${en.booking.confirmation.associatedWith} ${getPassengerDisplayName({
                lastName: accompanyAdult?.traveler?.lastName,
                firstName: accompanyAdult?.traveler?.firstName,
              })}`;

            // only show one form if multiChd
            // and show below last chd
            const lastIndex = _.findLastIndex(removalTravellerList, (traveller: any) => {
              return traveller.isRemoved === false && (isOnlyYoungChildLeft || isOnlyOneInfantLeft);
            });

            return (
              <BaseTravellerSelection
                key={index}
                isLTSelectTravellerBookingStep={false}
                title={getTravellerTitle(travellerInfo)}
                subTitle={travellerItem?.relationship || ''}
                isSelected={travellerItem.isRemoved}
                isInfant={isInfantType}
                isDisplayInfantOrChild={travellerItem?.age < 12}
                isInfantWithSeat={isInfantWithSeat}
                accompanyAdultTitle={accompanyAdultTitle || ''}
                canProceed={false}
                isOnlyInfantOrChild={
                  // if not select any pax, prevent auto show form
                  // if have redux form data, no need to show form
                  isSelectedPax && !hasReduxFormData()
                    ? remainingTravellerList.length === 1
                      ? isOnlyYoungChildLeft || isOnlyOneInfantLeft
                      : lastIndex === index
                    : false
                }
                handleSelected={() => {
                  handleTravellerSelection(index);
                }}
                checkStatus={isFormChecked}
                handleOnChanged={() => {
                  setIsFormChecked((prev: boolean) => !prev);
                  setRemovalFormData({
                    ...removalFormData,
                    isFormChecked: !isFormChecked,
                  });
                }}
              />
            );
          })}
        </Box>
      )}

      {checkedInTravellerList?.length > 0 && (
        <Box>
          <Typography
            variant="body_2_regular"
            color={theme.color.secondary.dark_grey.option_1}
            sx={{
              mt: 1,
            }}
          >
            {en.booking.confirmation.removePax.nonEligibleTravellers}
          </Typography>
          {checkedInTravellerList.map((travellerItem: any, index: number) => {
            const travellerInfo = travellerItem?.traveler;
            // infant
            const isInfantType = checkInfantTypeIsINSOrINF(travellerInfo?.type);

            return (
              <CheckedInTravellerSection
                key={index}
                title={getTravellerTitle(travellerInfo)}
                subTitle={travellerItem?.relationship || ''}
                isInfant={isInfantType}
              />
            );
          })}
        </Box>
      )}
    </ShadowContent>
  );
};

RemovePaxTravellerDetail.defaultProps = {
  applicationId: '',
  isFromCompleted: false,
};

export default RemovePaxTravellerDetail;
