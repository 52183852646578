import { Typography, useTheme } from '@mui/material';
import { shallowEqual } from 'react-redux';

import {
  CARRIER_OPTION,
  ID_TICKET_TYPE,
  NOMINATED_PASSENGER_AGE_TYPE,
  TRAVEL_TYPES,
} from '../../../constants/constants';
import en from '../../../translations/en';

import { INominatedPassenger } from '../../../interfaces';
import { useAppSelector } from '../../../app/hooks';
import { selectBooking } from '../../../slice/bookingSlice';

import { getDisplayName } from '../../../helpers';

import { LtEligibleTravellerItem, LtTravellers } from '../..';

const LtMasterTravellerList = ({
  list = [],
  label = '',
  isFooterValid = false,
  isDesktop = false,
  isEligibleList = false,
  handleTravellerSelect,
  handleInfantsWithoutSeat,
}: {
  list: INominatedPassenger[]; // eligible, non-eligible list
  label: string;
  isFooterValid: boolean;
  isDesktop: boolean;
  isEligibleList: boolean;
  handleTravellerSelect?: (index: number) => void;
  handleInfantsWithoutSeat?: (index: number) => void;
}) => {
  const theme = useTheme();

  const { leisureTravelDetails, outwardFlight } = useAppSelector(selectBooking, shallowEqual) || {};

  const { allowSeatForInfant, type, sub } = leisureTravelDetails || {};

  const checkSeatIsAllowForInfant = () => {
    if (type === TRAVEL_TYPES.FOC && sub === ID_TICKET_TYPE.NOSUB) {
      return !!allowSeatForInfant;
    }

    if (type === TRAVEL_TYPES.ZONE && outwardFlight?.marketingCompany !== CARRIER_OPTION.CX) {
      return false;
    }

    return true;
  };

  return (
    <>
      {list?.length > 0 && (
        <>
          <Typography
            variant="body_1_medium"
            sx={{
              mt: isEligibleList ? (isDesktop ? 1.5 : 0) : 2,
              mb: 1.5,
              color: theme.color.secondary.dark_grey.option_3,
            }}
          >
            {label}
          </Typography>

          {isEligibleList &&
            list.map((traveller: INominatedPassenger, index: number) => {
              const { title, firstName, lastName, isSelected, validationInfo, infantWithSeat } = traveller || {};
              const { ageType } = validationInfo || {};
              const isInfant = ageType === NOMINATED_PASSENGER_AGE_TYPE.infant;

              return (
                <LtEligibleTravellerItem
                  key={index}
                  travellerName={getDisplayName({ title, firstName, lastName })}
                  traveller={traveller}
                  isSelected={isSelected}
                  eligibleList={list}
                  isInfant={isInfant}
                  infantWithSeat={infantWithSeat}
                  canProceed={isFooterValid}
                  isAllowSeatForInfant={checkSeatIsAllowForInfant()}
                  handleSelected={() => {
                    handleTravellerSelect?.(index);
                  }}
                  handleToggled={() => {
                    handleInfantsWithoutSeat?.(index);
                  }}
                  {...(traveller?.quotaInfo
                    ? {
                        concessionQuotaProps: {
                          ...traveller?.quotaInfo,
                          label: en.booking.travelType.leisureTravel.quotaElement.label,
                        },
                      }
                    : {})}
                />
              );
            })}

          {!isEligibleList && (
            <LtTravellers
              ltTravellers={list}
              unSelectTravellers={[]}
              setIsAllAccompanySelected={() => {}}
              isKeepData={false}
              isShowForm={false}
              isInfantOrChildTraveller={false}
              isShowAccompanyButton={false}
              adultTravellers={[]}
              infantWithoutSeatTravellers={[]}
            />
          )}
        </>
      )}
    </>
  );
};

export default LtMasterTravellerList;
