import React from 'react';
import { Box, Typography } from '@mui/material';

import { FLIGHT_AVAILABILITY_STATUS } from '../../../../constants/constants';

import { getSeatDisplayByAvailability } from '../../../../helpers';

const containerSx = {
  width: 52,
  height: 40,
  borderRadius: 0.5,
  mr: 0.5,
};

const FlightClass = ({
  title,
  availability,
  status,
  isRestricted, // single flight card restricted
  isDisabled, // flight is booked or all display flight classes restricted
}: {
  title: React.ReactNode;
  availability?: string;
  status?: string;
  isRestricted?: boolean;
  isDisabled?: boolean;
}) => {
  // availability: undefined - not show the class
  const hasFlightClass = availability || status;
  const availabilityText = status || getSeatDisplayByAvailability(availability);

  const stylesObj = status
    ? {
        color: 'cathayJade.contrastText',
        fontWeight: 'bold',
        bgColor: 'flightCard.light',
      }
    : availability === FLIGHT_AVAILABILITY_STATUS.notAvailable //'notAvailable' - show 'Not avali.'
    ? {
        color: 'cathayJade.dark',
        fontWeight: 'normal',
        bgColor: 'flightCard.light',
      }
    : availability === FLIGHT_AVAILABILITY_STATUS.others // 'others' - show '-'
    ? {
        color: 'cathayJade.dark',
        fontWeight: 'normal',
        bgColor: 'flightCard.light',
      }
    : availability === FLIGHT_AVAILABILITY_STATUS.waitlist // show 'Waitlist'
    ? {
        color: 'datePicker.dark',
        fontWeight: 'normal',
        bgColor: 'flightCard.dark',
      }
    : {
        // availability ===  FLIGHT_AVAILABILITY_STATUS.confirm // TODO: cannot just allow confirm to be the fallback value
        color: 'bgColor.main',
        fontWeight: 'medium',
        bgColor: 'flightCard.main',
      };

  return hasFlightClass ? (
    <Box
      sx={{
        ...containerSx,
        bgcolor: isDisabled ? 'gray.contrastText' : isRestricted ? 'flightCard.light' : stylesObj.bgColor,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <>
        <Typography
          variant="body_1_bold"
          lineHeight="15px"
          color={isDisabled || isRestricted ? 'cathayJade.dark' : stylesObj.color}
        >
          {title}
        </Typography>
        <Typography
          variant="fine_print_medium"
          fontWeight={stylesObj.fontWeight || 'regular'}
          color={isDisabled || isRestricted ? 'cathayJade.dark' : stylesObj.color}
        >
          {availabilityText}
        </Typography>
      </>
    </Box>
  ) : null;
};

export default FlightClass;
