import React, { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography, IconButton, useTheme } from '@mui/material';
import { use100vh } from 'react-div-100vh';

import en from '../../translations/en';
import { USER_PROFILE_PATHS } from '../../constants/paths';

import { IUserProfileList } from '../../interfaces';

import { useAppSelector } from '../../app/hooks';
import { selectApp } from '../../slice/appSlice';
import { selectUser } from '../../slice/userSlice';

import { Div100vhView, ScrollableView } from '../../components';
import { UserProfileNavList } from '../../containers';

import {
  ProfileIcon,
  BasicInfoIcon,
  ContactDetailsIcon,
  PassportIcon,
  DelegationIcon,
  PaymentMethodIcon,
  PaymentHistoryIcon,
  ConcessionIcon,
  BackIcon,
  TravellerIcon,
} from '../../assets/images';

import { PAYMENT_CONCEPT, UI_STYLES } from '../../constants/constants';
import { getShortDisplayName, handleBackToUserProfile } from '../../helpers';

import { usePaymentMethodIndicator } from '../../hooks';

const UserProfile = () => {
  const theme = useTheme();
  const history = useHistory();
  const screenHeight = use100vh();
  const { isDesktop } = useAppSelector(selectApp) || {};

  const { profile, allowDelegation } = useAppSelector(selectUser) || {};
  const { personalInfo } = profile || {};
  const { preferredFirstName, firstName, lastName, middleName } = personalInfo || {};

  const textColor = theme.color.secondary.dark_grey.option_1;

  const userProfileItems: IUserProfileList[] = [
    {
      title: en.userProfile.personal.basicInfo.title,
      url: USER_PROFILE_PATHS.basicInformation,
      icon: <BasicInfoIcon />,
      desktopIcon: <BasicInfoIcon width={22} height={21} />,
      isShowWarning: false,
    },
    {
      title: en.userProfile.personal.contactInfo.title,
      url: USER_PROFILE_PATHS.contactDetails,
      icon: <ContactDetailsIcon />,
      desktopIcon: <ContactDetailsIcon width={20} height={20} />,
      isShowWarning: false,
    },
    // {
    //   ...en.userProfile.dependent,
    //   url: USER_PROFILE_PATHS.dependent,
    // },
  ];
  const defaultEmploymentDetailItems: IUserProfileList[] = [
    {
      title: en.userProfile.employmentDetail.paymentHistory.title,
      url: USER_PROFILE_PATHS.paymentHistory,
      icon: <PaymentHistoryIcon />,
      desktopIcon: <PaymentHistoryIcon width={22} height={22} />,
      isShowWarning: false,
    },
    {
      title: en.userProfile.employmentDetail.paymentMethod.title,
      url: USER_PROFILE_PATHS.paymentMethod,
      icon: <PaymentMethodIcon />,
      desktopIcon: <PaymentMethodIcon width={22} height={16} />,
      isShowWarning: false,
    },
  ];
  const defaultDependentDetailItems: IUserProfileList[] = [
    {
      title: en.userProfile.dependent.dependentTitle,
      url: USER_PROFILE_PATHS.dependentDetails,
      icon: <TravellerIcon fill={theme.palette.gray.dark} />,
      desktopIcon: <TravellerIcon fill={theme.palette.gray.dark} />,
      isShowWarning: false,
    },
  ];

  const passportItems: IUserProfileList[] = [
    {
      title: en.userProfile.passport.title,
      url: USER_PROFILE_PATHS.passport,
      icon: <PassportIcon />,
      desktopIcon: <PassportIcon width={16} height={22} />,
      isShowWarning: false,
    },
  ];

  const delegationItem: IUserProfileList[] = [
    {
      title: en.userProfile.delegation.title,
      url: USER_PROFILE_PATHS.delegation,
      icon: <DelegationIcon />,
      desktopIcon: <DelegationIcon width={23} height={16} />,
      isShowWarning: false,
    },
  ];

  const concessionItem: IUserProfileList[] = [
    {
      title: en.userProfile.concession.title,
      url: USER_PROFILE_PATHS.concessionDetails,
      icon: <ConcessionIcon />,
      desktopIcon: <ConcessionIcon width={20} height={20} />,
      isShowWarning: false,
    },
  ];

  // Payment method indicator
  const [paymentMethodIndicator] = usePaymentMethodIndicator();

  // set default employment detail items
  const [employmentDetailItems, setEmploymentDetailItems] = useState(defaultEmploymentDetailItems || []);

  // desktop list
  const defaultDesktopNavList = useMemo(() => {
    return [
      ...userProfileItems,
      ...employmentDetailItems,
      ...passportItems,
      ...defaultDependentDetailItems,
      ...(allowDelegation ? delegationItem : []),
      ...concessionItem,
    ];
  }, [employmentDetailItems]);

  // mobile list
  const defaultMobileMegaNavList = useMemo(() => {
    const tmpMegaNavList = [
      {
        list: userProfileItems,
        header: en.userProfile.personal.title,
      },
      {
        list: defaultDependentDetailItems,
        header: en.userProfile.dependent.dependentDetails,
      },
      {
        list: employmentDetailItems,
        header: en.userProfile.employmentDetail.header,
      },
      {
        list: passportItems,
        header: en.userProfile.passport.header,
      },
      { list: concessionItem, header: en.userProfile.concession.header },
    ];
    return allowDelegation
      ? tmpMegaNavList.concat([
          {
            list: delegationItem,
            header: en.userProfile.delegation.header,
          },
        ])
      : tmpMegaNavList;
  }, [employmentDetailItems]);

  //TODO integrate the validation function later
  useEffect(() => {
    // ETP-2431
    const isPrepaid = paymentMethodIndicator.paymentConcept === PAYMENT_CONCEPT.PREPAID;
    // check if feature flag tune on also
    if (employmentDetailItems?.length) {
      setEmploymentDetailItems((prev) => {
        const tmpArr = prev?.map((prevItem) => {
          return {
            ...prevItem,
            ...(prevItem?.url.includes('paymentMethod') && {
              isShowWarning: !isPrepaid && paymentMethodIndicator?.isShowWarning,
            }),
          };
        });
        return [...tmpArr];
      });
    }
  }, [profile?.paymentConcept, profile?.paymentValidated, profile?.paymentMedium, paymentMethodIndicator]);

  return (
    <Div100vhView sx={{ bgcolor: theme.color.secondary.light_slate.option_7 }}>
      {!isDesktop && (
        <IconButton onClick={() => handleBackToUserProfile(history)} sx={{ py: 3.625, pl: 2, position: 'absolute' }}>
          <BackIcon fill={theme.color.utility.link.option_3} />
        </IconButton>
      )}
      <Box
        component={ScrollableView}
        sx={{
          mt: 9.875,
          pb: 2,
          px: {
            sm: `calc(50vw - 480px)`,
            xs: 2,
            height: `calc(${screenHeight}px - ${UI_STYLES.navBarHeight} - ${isDesktop ? theme.spacing(9.875) : 0})`,
          },
        }}
      >
        <ProfileIcon fill={textColor} />
        <Typography variant="title_1_bold" sx={{ color: textColor, mb: isDesktop ? 6 : 1 }}>
          {en.home.hello}
          {getShortDisplayName({
            preferredFirstName,
            firstName,
            lastName,
            middleName,
          })}
        </Typography>

        {isDesktop ? (
          <UserProfileNavList list={defaultDesktopNavList} />
        ) : (
          defaultMobileMegaNavList?.length > 0 &&
          defaultMobileMegaNavList.map((navListItems, index) => (
            <UserProfileNavList key={index} list={navListItems?.list} header={navListItems?.header} />
          ))
        )}
      </Box>
    </Div100vhView>
  );
};

export default UserProfile;
