import React from 'react';
import { Box, SxProps, Theme, Typography, useTheme } from '@mui/material';

type Props = {
  field: { label: string; value: string | number };
  index: number;
  customStyles?: SxProps<Theme>;
  customTextStyles?: React.CSSProperties;
};

const FieldContainer = (props: Props) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        mt: 2,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        color: theme.color.secondary.dark_grey.option_3,
        ...props.customStyles,
      }}
    >
      <Typography variant="body_2_regular">{props.field.label}</Typography>
      <Typography variant="headline_medium" sx={{ mt: 0.5, wordBreak: 'break-all', wordWrap: 'break-word' }}>
        <pre style={{ fontFamily: 'inherit', margin: 0, ...props.customTextStyles }}>{props.field.value}</pre>
      </Typography>
    </Box>
  );
};

export default FieldContainer;
