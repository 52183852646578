import React from 'react';
import { Box, Typography, useTheme, Theme, SxProps } from '@mui/material';

import { FARE_TYPES } from '../../../../constants/constants';
import en from '../../../../translations/en';

import { IButtonValue, ITravellersOptions, ILtSelectedConcessionDetailType } from '../../../../interfaces';

import { LtTravellerOptionItem, LtTravelTypeContainer } from '../../..';

const RenderTravellerOptions = ({ list }: { list: ITravellersOptions[] }) => {
  return (
    <>
      {list &&
        list.map((item: ITravellersOptions, index: number) => {
          return <LtTravellerOptionItem key={index} {...item} />;
        })}
    </>
  );
};

const TravellerSection = ({
  boxSXProps,
  titleSXProps,
  list,
  isDesktop,
}: {
  list: ITravellersOptions[];
  isDesktop: boolean;
  boxSXProps: SxProps<Theme>;
  titleSXProps: SxProps<Theme>;
}) => {
  return (
    <>
      <Box
        className="traveller_section"
        sx={{
          ...boxSXProps,
          ...(isDesktop && {
            position: 'relative',
            paddingLeft: 4.5,
            '&::before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '1px',
              height: 364,
              background: '#D8D8D8', // TODO will define as variable in theme
            },
          }),
        }}
      >
        <Typography variant="headline_medium" sx={titleSXProps}>
          {en.booking.travelType.leisureTravel.travellers}
        </Typography>

        <RenderTravellerOptions list={list} />
      </Box>
    </>
  );
};

const LtConcessionSection = ({
  isDesktop,
  ltFareType,
  concessionOnChange,
  selectedTypeId,
  travellersOptions,
  ltCurrentDetails,
  isShowConcessionOverlay = false,
}: {
  isDesktop: boolean;
  ltFareType: string;
  selectedTypeId: string;
  travellersOptions: ITravellersOptions[];
  concessionOnChange: (value: IButtonValue) => void;
  ltCurrentDetails: ILtSelectedConcessionDetailType;
  isShowConcessionOverlay: boolean;
}) => {
  const theme = useTheme();

  // display for fare type `ID`
  const isFullWidthTravelTypeSection = ltFareType === FARE_TYPES.ZED;

  return (
    <Box
      sx={{
        ...(isDesktop && {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: 4.5625,
        }),
      }}
    >
      <Box
        sx={{
          ...(isDesktop && {
            //  Zone or not Empty Type won't show Traveller Section
            width: isFullWidthTravelTypeSection ? '100%' : '50%',
          }),
        }}
      >
        <LtTravelTypeContainer
          selectedTypeId={selectedTypeId}
          concessionOnChange={concessionOnChange}
          ltCurrentDetails={ltCurrentDetails}
          isShowConcessionOverlay={isShowConcessionOverlay}
        />
      </Box>

      {/* display for LT + non Zone */}
      {ltFareType && ltFareType !== FARE_TYPES.ZED && (
        <TravellerSection
          list={travellersOptions}
          boxSXProps={{
            ...(isDesktop
              ? {
                  width: '50%',
                  pb: 1,
                  px: 1,
                }
              : {
                  mb: 1,
                  px: 1,
                }),
          }}
          titleSXProps={{
            ...(isDesktop
              ? {
                  color: theme.color.secondary.dark_grey.option_3,
                  mt: 1,
                  mb: 1.5,
                }
              : {
                  mt: 3,
                  mb: 1.5,
                }),
          }}
          isDesktop={isDesktop}
        />
      )}
    </Box>
  );
};

export default LtConcessionSection;
