import TextInput from './TextInput';
import { TextFieldProps, OutlinedInputProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import { FONT_WEIGHT } from '../../constants/font';
import theme from '../../style/theme';

const defaultInputStyle = {
  fontSize: theme.typography.body_1_medium.fontSize,
  fontWeight: FONT_WEIGHT.medium,
};

const FormFieldTextInput = styled((props: TextFieldProps) => (
  <TextInput InputProps={{ disableUnderline: true } as Partial<OutlinedInputProps>} {...props} />
))(({ theme }) => ({
  '& .MuiFilledInput-root': {
    ...defaultInputStyle,
    height: '48px',
    background: theme.palette.primary.light,
    border: `1px solid ${theme.palette.borderColor.contrastText}`,
    color: theme.color.secondary.dark_grey.option_3,
    paddingTop: '0px',
    '&.Mui-error': {
      border: `1px solid ${theme.color.utility.error.option_3}`,
    },
    '&.Mui-selected, &:hover, &.Mui-filled': {
      background: 'white',
    },
    '&.Mui-focused': {
      background: theme.palette.primary.light,
      border: `1px solid ${theme.color.utility.link.option_3}`,
      '&.Mui-error': {
        border: `1px solid ${theme.color.utility.error.option_3}`,
      },
    },
    '&.Mui-disabled': {
      background: theme.color.utility.unselectable.option_7,
    },
  },
  '& .MuiFormLabel-root': {
    ...defaultInputStyle,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.color.secondary.grey.option_3,
    fontWeight: FONT_WEIGHT.regular,
    marginTop: theme.spacing(-0.375),
    '&.Mui-focused, &.Mui-disabled, &.Mui-error': {
      color: theme.color.secondary.grey.option_3,
    },
  },
  '& .MuiInputBase-inputAdornedEnd': {
    WebkitTextFillColor: `${theme.color.secondary.dark_grey.option_3} !important`,
  },
}));

export default FormFieldTextInput;
