import React, { useState } from "react";

import {
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme,
} from "@mui/material";

type Props = {
  defaultValue?: number;
  list?: any[];
  onClick?: (e: number) => void;
};

const AdminToggleButtonGroup = (props: Props) => {
  const theme = useTheme();
  const [toggleValue, setToggleValue] = useState(props.defaultValue || 0);

  return (
    <ToggleButtonGroup>
      {props?.list?.map((item, index) => {
        const isCurrentSelected = toggleValue === item.id;
        return (
          <ToggleButton
            key={index}
            sx={{
              my: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              mr: 1,
              "&.MuiButtonBase-root": {
                background: isCurrentSelected
                  ? theme.color.secondary.dark_grey.option_3
                  : theme.color.etp.grey,
                borderWidth: 0,
                height: "32px",
                "&.MuiToggleButton-root": {
                  borderBottomLeftRadius: 4,
                  borderTopLeftRadius: 4,
                  borderBottomRightRadius: 4,
                  borderTopRightRadius: 4,
                },
              },
            }}
            value={item.id}
            onClick={() => {
              props?.onClick?.(item?.id);
              setToggleValue(item.id);
            }}
          >
            <Typography
              variant="body_1_regular"
              color={
                isCurrentSelected
                  ? theme.palette.bgColor.main
                  : theme.color.secondary.dark_grey.option_3
              }
              component="div"
              align="left"
              sx={{ pt: 0.125, whiteSpace: "nowrap" }}
            >
              {item.title}
            </Typography>
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};

export default AdminToggleButtonGroup;
