import { useState } from 'react';
import { Box } from '@mui/material';
import { DateRange, RangeKeyDict } from 'react-date-range';

import { IDateRangePickerProps } from '../../interfaces';
import { DATE_FORMAT } from '../../constants/constants';
import { useAppSelector } from '../../app/hooks';
import { selectApp } from '../../slice/appSlice';
import { checkIsDateValid, formatDateAsString } from '../../helpers';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import '../../assets/css/dateRangePicker.css';

const DateRangePicker = ({
  onChange,
  rangeStartDate,
  rangeEndDate,
  minDate,
  maxDate,
  isDesktop,
  calendarHeight,
  showMonthAndYearDropDown,
  shownDate,
}: IDateRangePickerProps) => {
  const { isDesktop: isFixedWidth } = useAppSelector(selectApp) || {};

  const [pickerRanges, setPickerRanges] = useState([
    {
      startDate: new Date(formatDateAsString(rangeStartDate, DATE_FORMAT.date)),
      endDate: new Date(formatDateAsString(rangeEndDate, DATE_FORMAT.date)),
      key: 'rangePicker',
    },
  ]);

  const handleSingleDateChange = (date: RangeKeyDict) => {
    setPickerRanges([
      {
        startDate: date.rangePicker.startDate || new Date(formatDateAsString(rangeStartDate, DATE_FORMAT.date)),
        endDate: date.rangePicker.endDate || new Date(formatDateAsString(rangeEndDate, DATE_FORMAT.date)),
        key: 'rangePicker',
      },
    ]);
    onChange(date);
  };

  return (
    <Box
      id={showMonthAndYearDropDown ? '' : 'noDropDownPicker'}
      className={isDesktop ? 'desktop' : isFixedWidth ? 'fixed' : 'mobile'}
    >
      <DateRange
        onChange={handleSingleDateChange}
        moveRangeOnFirstSelection={false}
        scroll={{
          enabled: !isDesktop,
          calendarHeight,
          monthHeight: 300,
          longMonthHeight: 350,
        }}
        shownDate={shownDate}
        monthDisplayFormat={DATE_FORMAT.mmmmyyyy}
        showDateDisplay={false}
        direction={isDesktop ? 'horizontal' : 'vertical'}
        weekStartsOn={0}
        showPreview={false}
        weekdayDisplayFormat="EEEEE"
        showMonthAndYearPickers={showMonthAndYearDropDown}
        ranges={pickerRanges}
        minDate={checkIsDateValid(minDate) ? minDate : undefined}
        maxDate={checkIsDateValid(maxDate) ? maxDate : undefined}
      />
    </Box>
  );
};

export default DateRangePicker;
