import { HTTP_REQUEST_TYPE, DELEGATOR_API } from '../../constants/api';
import en from '../../translations/en';

import { sendHttpRequest } from '../api';

const addDelegatee = async (userId: string, delegateeId: string, from: string, to?: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: DELEGATOR_API.delegatee,
    apiTitle: en.errorAlert.delegationUnsuccessful,
    payload: { userId, delegateeId, from, to },
  });
  return resp;
};

const getDelegatee = async (userId: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: DELEGATOR_API.delegatee,
    apiTitle: en.errorAlert.genericMessage, //tbc
    payload: { userId },
  });
  return resp;
};

const delegateeList = async (userId: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: DELEGATOR_API.delegateeList,
    apiTitle: en.errorAlert.genericMessage, //tbc
    payload: { userId },
  });
  return resp;
};

const removeDelegatee = async (userId: string, delegateeId: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.delete,
    path: DELEGATOR_API.delegatee,
    apiTitle: en.errorAlert.genericMessage, //tbc
    payload: { userId, delegateeId },
  });
  return resp;
};

export { addDelegatee, getDelegatee, delegateeList, removeDelegatee };
