import React from "react";
import { Box, SxProps, Typography, useTheme, Theme } from "@mui/material";

import { NoBookingIcon } from "../../assets/images";

const NoSearchResult = ({
  desc,
  isShowIcon = true,
  customStyles,
}: {
  desc: string;
  isShowIcon?: boolean;
  customStyles?: SxProps<Theme>;
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        ...customStyles,
      }}
    >
      {isShowIcon && (
        <Box
          sx={{
            my: 3,
          }}
        >
          <NoBookingIcon />
        </Box>
      )}
      <Typography
        variant="body_2_regular"
        align="center"
        color={theme.color.secondary.dark_grey.option_3}
        sx={{ mt: isShowIcon ? 0 : 2 }}
      >
        {desc}
      </Typography>
    </Box>
  );
};

export default NoSearchResult;
