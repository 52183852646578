import React from "react";
import { Box, Typography, useTheme, SxProps, Theme } from "@mui/material";

import en from "../../translations/en";

interface IInfantLabelProps {
  sxProps?: SxProps<Theme>;
  isInfantWithSeat: boolean;
  accompanyAdultTitle?: string;
}
const InfantLabel = ({
  sxProps,
  isInfantWithSeat,
  accompanyAdultTitle,
}: IInfantLabelProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        overflow: "hidden",
        color: theme.color.secondary.grey.option_2,
        ...sxProps,
      }}
    >
      <Typography variant="navigation_regular">
        {isInfantWithSeat
          ? en.booking.travelType.leisureTravel.infantWithSeat
          : en.booking.travelType.leisureTravel.infantWithoutSeat}
      </Typography>

      {accompanyAdultTitle && (
        <Typography sx={{ mt: 0.5 }} variant="navigation_regular">
          {accompanyAdultTitle}
        </Typography>
      )}
    </Box>
  );
};

export default InfantLabel;
