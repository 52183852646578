import { Configuration } from 'configuration';

import { getDisplayVal } from './booking';

export function getDisplayLabel(
  labels: Configuration.CodeLabel[],
  category?: string | null,
  code?: string | null,
): string {
  if (!code || !category) {
    return getDisplayVal();
  }

  return getDisplayVal(labels.find((label) => label.category === category && label.code === code)?.label);
}

export function findDropdownOptionClient(
  options: Configuration.DropdownOptionClient[],
  code?: string,
): Configuration.DropdownOptionClient | undefined {
  return options.find((option) => option.code === code);
}
