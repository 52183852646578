import { useEffect, useState } from 'react';
import { Box, Collapse, Typography, useTheme } from '@mui/material';
import { use100vh } from 'react-div-100vh';

import en from '../../translations/en';
import { UI_STYLES, USER_ROLE } from '../../constants/constants';
import { ROOT_PATHS } from '../../constants/paths';

import { useAppSelector } from '../../app/hooks';
import { selectUser } from '../../slice/userSlice';

import { FormButton, ScrollableView } from '../../components';

const ListItemContent = ({ itemText }: { itemText: string }) => {
  const theme = useTheme();
  return (
    <Box
      component="li"
      sx={{
        position: 'relative',
        listStyle: 'none',
        pl: 2,
        '&::before': {
          content: '""',
          position: 'absolute',
          mt: 0.75,
          left: '4px',
          width: '4px',
          height: '4px',
          background: theme.color.secondary.dark_grey.option_3,
          borderRadius: '50%',
        },
      }}
    >
      <Typography variant="navigation_regular" dangerouslySetInnerHTML={{ __html: itemText }} />
    </Box>
  );
};

const AdminGuide = () => {
  const theme = useTheme();
  const screenHeight = use100vh();
  const { role } = useAppSelector(selectUser) || {};
  const [isExpand, setIsExpand] = useState(false);
  const isAdminRole = role.type === USER_ROLE.admin;

  const articleData = en.admin.guide.adminGuide.content;
  const serviceNotes = en.admin.guide.termsOfUse.content1List;

  useEffect(() => {
    if (!isAdminRole) {
      // prevent input url to link page
      location.replace(ROOT_PATHS.landing);
    }
  }, []);

  return (
    <Box
      component={ScrollableView}
      sx={{
        height: `calc(${screenHeight}px - ${UI_STYLES.desktopHeaderHeight} - ${UI_STYLES.indicatorBarHeight} )`,
      }}
    >
      <Box
        sx={{
          my: 2,
          mx: 'auto',
          width: '838px',
        }}
      >
        <Typography
          variant="navigation_regular"
          sx={{
            color: theme.color.secondary.dark_grey.option_3,
          }}
        >
          {en.admin.guide.noticeMessage1}
        </Typography>

        <Typography
          variant="navigation_regular"
          sx={{
            color: theme.color.secondary.dark_grey.option_3,
          }}
        >
          {en.admin.guide.noticeMessage2}
        </Typography>

        <Box
          sx={{
            my: 2,
            mx: 'auto',
            height: 'auto',
            minHeight: '178px',
            padding: '20px 16px 16px 16px',
            borderRadius: 1,
            background: theme.color.etp.option_1,
          }}
        >
          <Typography
            variant="body_1_medium"
            sx={{
              color: theme.color.secondary.dark_grey.option_3,
            }}
          >
            {en.admin.guide.termsOfUse.title}
          </Typography>

          <Collapse
            in={isExpand}
            sx={{
              my: 1,
              width: '806px',
            }}
            collapsedSize={`${18 * 4}px`}
          >
            <Typography
              variant="navigation_regular"
              sx={{
                color: theme.color.secondary.dark_grey.option_3,
              }}
            >
              {en.admin.guide.termsOfUse.content1}
            </Typography>

            <Box sx={{ mt: 1 }}>
              {serviceNotes.map((item, index) => (
                <Typography
                  key={index}
                  variant="navigation_regular"
                  sx={{
                    color: theme.color.secondary.dark_grey.option_3,
                    ml: 1,
                    mt: 0.25,
                  }}
                >
                  <ListItemContent itemText={item} />
                </Typography>
              ))}
            </Box>
            <Typography
              variant="navigation_regular"
              sx={{
                color: theme.color.secondary.dark_grey.option_3,
                mt: 1,
              }}
            >
              {en.admin.guide.termsOfUse.content2}
            </Typography>
          </Collapse>

          <FormButton
            theme={theme}
            colour="transparent"
            onClick={() => setIsExpand(!isExpand)}
            customStyles={{
              padding: '0px',
              margin: '0px',
              marginTop: '16px',
              height: '16px',
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'center',
            }}
          >
            <Typography variant="navigation_medium" mt={1}>
              {isExpand ? en.admin.guide.termsOfUse.readLess : en.admin.guide.termsOfUse.readMore}
            </Typography>
          </FormButton>
        </Box>

        <Box
          sx={{
            my: 2,
          }}
        >
          <Typography
            variant="title_1_medium"
            sx={{
              fontSize: '20px',
              mx: 'auto',
              mt: 4,
              mb: 1.5,
              color: theme.color.secondary.dark_grey.option_1,
            }}
          >
            {en.admin.guide.adminGuide.title}
          </Typography>

          {articleData.map((section, index) => {
            return (
              <Box
                key={index}
                sx={{
                  mb: 2,
                  mx: 'auto',
                }}
              >
                <Typography
                  variant="navigation_bold"
                  sx={{
                    color: theme.color.secondary.dark_grey.option_3,
                  }}
                >
                  {section.title}
                </Typography>
                <Typography
                  variant="navigation_regular"
                  sx={{
                    color: theme.color.secondary.dark_grey.option_3,
                    ml: 1,
                    mt: 0.25,
                  }}
                >
                  <>
                    {section.list.map((listItem, index) => (
                      <ListItemContent key={index} itemText={listItem} />
                    ))}
                  </>
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default AdminGuide;
