import React from 'react';
import _ from 'lodash';
import { Box, IconButton, Typography, styled, useTheme, SxProps, Theme } from '@mui/material';
import en from '../../translations/en';
import { CloseIcon, RightArrowIcon } from '../../assets/images';

import { useAppSelector } from '../../app/hooks';
import { selectApp } from '../../slice/appSlice';

interface IMoreActionsItemProps {
  icon?: React.ReactNode;
  title?: string;
  handleClick?: () => void;
  [key: string]: any;
}

interface IMoreActionsProps {
  moreActionsObjs: { [key: string]: IMoreActionsItemProps };
  handleClose: () => void;
  customStyles?: SxProps<Theme>;
}

const Puller = styled(Box)(({ theme }) => ({
  width: '32px',
  height: '4px',
  backgroundColor: theme.color.secondary.slate.option_5,
  borderRadius: '100px',
  position: 'absolute',
  top: '8px',
  left: 'calc(50% - 16px)',
}));

const MoreActionsItem = ({ icon, title, handleClick }: IMoreActionsItemProps) => {
  const theme = useTheme();
  const { isDesktop } = useAppSelector(selectApp) || {};

  return (
    <Box
      sx={{
        width: isDesktop ? '375px' : '100%',
        mx: 'auto',
        height: '48px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        ...(isDesktop ? { px: 2 } : { pr: 0.75 }),
      }}
      onClick={handleClick}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>{icon}</Box>

      <Typography variant="body_1_medium" sx={{ color: theme.color.secondary.dark_grey.option_3, ml: 1, flex: 1 }}>
        {title}
      </Typography>

      <Box>
        <RightArrowIcon fill={theme.color.secondary.dark_grey.option_3} />
      </Box>
    </Box>
  );
};

const MoreActionsPopover = ({ moreActionsObjs, handleClose, customStyles }: IMoreActionsProps) => {
  const theme = useTheme();
  const { isDesktop } = useAppSelector(selectApp) || {};

  return (
    <Box
      sx={{
        background: theme.palette.bgColor.main,
        ...customStyles,
      }}
    >
      {!isDesktop && <Puller />}
      <Box
        sx={{
          display: 'flex',
          height: '48px',
          mb: 1,
          justifyContent: 'center',
          alignItems: 'center',
          borderBottom: isDesktop ? 'none' : '1px solid #E8E8E8',
          position: 'relative',
        }}
      >
        <Typography variant="body_1_medium" color={theme.palette.text.primary}>
          {en.booking.confirmation.cancelActions.moreActions}
        </Typography>

        <IconButton onClick={handleClose} sx={{ position: 'absolute', right: isDesktop ? 0 : '-4px' }}>
          <CloseIcon fill={theme.color.utility.link.option_3} />
        </IconButton>
      </Box>

      <Typography
        variant="body_1_regular"
        sx={{
          mb: 1,
          color: theme.color.secondary.dark_grey.option_1,
        }}
      >
        {en.booking.confirmation.cancelActions.selectOptions}
      </Typography>

      <Box>
        {_.values(moreActionsObjs).map((item: IMoreActionsItemProps) => {
          return (
            <MoreActionsItem key={item.title} icon={item.icon} title={item.title} handleClick={item.handleClick} />
          );
        })}
      </Box>
    </Box>
  );
};

export default MoreActionsPopover;
