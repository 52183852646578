import { Box, SxProps, Theme, useTheme } from '@mui/material';

import en from '../../../translations/en';

import { FormButton } from '../../../components';
import { TravelTypeWithIcon } from '..';
import { RebookDateFieldContainer } from '.';

const RebookEditSearchDate = ({
  handleSearchClick,
  customStyles,
}: {
  handleSearchClick: () => void;
  customStyles?: SxProps<Theme>;
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        px: 2,
        py: 1.5,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        bgcolor: theme.backgroundColor.wheat,
        borderRadius: 1.5,
        boxShadow: theme.boxShadow.important,
        ...customStyles,
      }}
    >
      <TravelTypeWithIcon travelType={en.booking.travelType.leisureTravel.title} />

      <RebookDateFieldContainer
        isEditPopUp={true}
        customStyles={{
          '& .MuiFilledInput-root': {
            border: 0,
          },
        }}
      />

      <FormButton
        theme={theme}
        colour="blue"
        variant="contained"
        onClick={() => {
          handleSearchClick();
        }}
        customStyles={{
          marginTop: '24px',
          height: '44px',
          width: '100%',
          borderRadius: '8px',
        }}
      >
        {en.common.search}
      </FormButton>
    </Box>
  );
};

export default RebookEditSearchDate;
