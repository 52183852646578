import React from 'react';
import {
  Theme,
  useTheme,
  SxProps,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from '@mui/material';

import { FONT_WEIGHT } from '../../constants/font';

import { SortIcon } from '../../assets/images';

interface ITableHeadersProps {
  id?: string;
  sxProps?: SxProps<Theme>;
  label: string;
  isHiddenField: boolean;
  sortingFunc?: ((value: string) => void) | null;
}

interface ITableContentCellProps {
  id?: string;
  sxProps?: SxProps<Theme>;
  cellTextDecoration?: SxProps<Theme>;
  value: string;
  isHiddenField: boolean;
  columnOnClick?: (selectedData: ITableContentsProps) => void;
}

interface ITableContentsProps {
  id?: string;
  columns: ITableContentCellProps[];
  sxProps?: SxProps<Theme>;
  hover?: boolean;
  rowOnClick?: (selectedRowData: ITableContentsProps) => void;
}

interface IBaseTableProps {
  tableHeadersSXProps?: SxProps<Theme>;
  tableContainerSXProps?: SxProps<Theme>;
  tableSXProps?: SxProps<Theme>;
  tableHeaders: ITableHeadersProps[];
  tableContents: ITableContentsProps[];
  isFixedTableWidth?: boolean;
  isStickyHeader?: boolean;
}
const BaseTable = ({
  tableHeaders,
  tableContents,
  tableSXProps,
  tableContainerSXProps,
  tableHeadersSXProps,
  isFixedTableWidth = false,
  isStickyHeader = false,
}: IBaseTableProps) => {
  const theme = useTheme();

  const cellTextStyle: React.CSSProperties = {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
  };
  const baseCellStyle: React.CSSProperties = {
    width: '165px',
    color: theme.color.secondary.dark_grey.option_3,
    fontSize: theme.typography.body_1_medium.fontSize,
  };

  const defaultTableHeaderCellStyle = {
    ...baseCellStyle,
    fontWeight: FONT_WEIGHT.regular,
    borderBottomColor: 'transparent',
  };

  const defaultTableContentCellStyle = {
    ...baseCellStyle,
    fontWeight: FONT_WEIGHT.medium,
    verticalAlign: 'top',
  };

  return (
    <TableContainer sx={tableContainerSXProps}>
      <Table
        stickyHeader={isStickyHeader}
        sx={{
          ...tableSXProps,
          ...(isFixedTableWidth && {
            borderCollapse: 'separate',
            tableLayout: 'fixed',
          }),
        }}
      >
        <TableHead>
          <TableRow sx={tableHeadersSXProps}>
            {tableHeaders.map((header: ITableHeadersProps, index: number) => {
              if (!header?.isHiddenField) {
                return (
                  <TableCell
                    key={header?.id || index}
                    sx={{
                      ...defaultTableHeaderCellStyle,
                      ...header?.sxProps,
                    }}
                    style={cellTextStyle}
                  >
                    {header?.label}
                    {header?.sortingFunc && (
                      <SortIcon
                        fill={theme.color.utility.link.option_3}
                        style={{ cursor: 'pointer' }}
                        onClick={() => header?.sortingFunc?.(header?.label)}
                      />
                    )}
                  </TableCell>
                );
              }
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {tableContents.map((row: ITableContentsProps, rowIndex: number) => (
            <TableRow
              key={row?.id || rowIndex}
              sx={row?.sxProps}
              hover={row?.hover ?? true}
              onClick={() => {
                row?.rowOnClick?.(row);
              }}
            >
              {row?.columns?.map((column: ITableContentCellProps, index: number) => {
                if (!column?.isHiddenField) {
                  return (
                    <TableCell
                      key={column?.id || index}
                      sx={{
                        ...defaultTableContentCellStyle,
                        ...column?.sxProps,
                      }}
                      style={cellTextStyle}
                      {...(column?.columnOnClick && {
                        onClick: (event: React.MouseEvent<HTMLElement>) => {
                          event.stopPropagation();
                          column?.columnOnClick?.(row);
                        },
                      })}
                    >
                      <Box sx={{ ...column?.cellTextDecoration }}>{column?.value}</Box>
                    </TableCell>
                  );
                }
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BaseTable;
