import { PREPAID_PATHS } from '../../constants/paths';
import { IRouteConfig } from '../../interfaces';

import PrepaidAuthTransit from './PrepaidAuthTransit';
import PrepaidAuthFailureTransit from './PrepaidAuthFailureTransit';

const cppRouteConfig: IRouteConfig[] = [
  {
    path: PREPAID_PATHS.authTransit,
    component: PrepaidAuthTransit,
    exact: true,
  },
  {
    path: PREPAID_PATHS.authFailureTransit,
    component: PrepaidAuthFailureTransit,
  },
];

export default cppRouteConfig;
