import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

import { IPeriod, IBookingFilterSlice } from '../interfaces';

const initialState: IBookingFilterSlice = {
  travelTypes: [],
  period: {},
};

const bookingFilterSlice = createSlice({
  name: 'bookingFilter',
  initialState,
  reducers: {
    setTravelTypeFilter: (state, { payload }: PayloadAction<string[]>) => {
      state.travelTypes = payload;
    },
    setPeriod: (state, { payload }: PayloadAction<IPeriod>) => {
      state.period = payload;
    },
    reset: () => initialState,
  },
});

const selectBookingFilter = (state: RootState) => state.bookingFilter;
const { setTravelTypeFilter, setPeriod, reset } = bookingFilterSlice.actions;

export { bookingFilterSlice, selectBookingFilter, setTravelTypeFilter, setPeriod, reset, initialState };

export default bookingFilterSlice.reducer;
