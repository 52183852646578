import { Route, Switch } from "react-router-dom";
import { IRouteConfig } from "../interfaces";

interface IRouteSwtichProps {
  routes: IRouteConfig[];
}

const RouteWithSubRoutes = ({
  path,
  routes,
  component: Component,
  exact = true,
}: IRouteConfig) => (
  <Route
    path={path}
    render={(props) => <Component {...props} routes={routes} exact={exact} />}
  />
);

const RouteSwitch = ({ routes }: IRouteSwtichProps) => (
  <Switch>
    {routes.map((route) => (
      <RouteWithSubRoutes key={route.path} {...route} />
    ))}
  </Switch>
);

export default RouteSwitch;
