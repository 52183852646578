import { FormErrors } from 'redux-form';
import { validateRequiredFields } from '../../../../../helpers/validation';
import { ITravellerDetails } from '../../../../../interfaces';

export const validate = (values: ITravellerDetails, props: any): FormErrors<ITravellerDetails> => {
  const errors: FormErrors<ITravellerDetails | null> = {};
  if (values) {
    validateRequiredFields(values, props.docaResidence ? ['countryOfResidential'] : [], errors);
  }
  return errors;
};
