import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  Box,
  Typography,
  useTheme,
  InputAdornment,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectUser } from "../../slice/userSlice";

import { getConcessionEmpNonEmp } from "../../services/admin/user";

import { TRAVEL_PURPOSE } from "../../constants/constants";

import { EditSearchBarAction, EditSearchBarState } from "../../context";
import { setDetail, selectBooking } from "../../slice/bookingSlice";

import {
  Header,
  Footer,
  ScrollableView,
  StyledOutlinedInput,
} from "../../components";
import { ConcessionListContainer } from "..";

import en from "../../translations/en";

import { NavBarSearchIcon } from "../../assets/images";

import { BOOKING_PATHS } from "../../constants/paths";
import { getConcessionForRadioButtonGroup } from "../../helpers/booking";

import { IButtonValue } from "../../interfaces";
const AdminRoleConcession = ({
  handleBackClick,
}: {
  handleBackClick?: (empOrNonEmpType?: string) => void;
}) => {
  const theme = useTheme();

  const dispatch = useAppDispatch();
  const history = useHistory();

  const { setEditTravelDetails } = useContext(EditSearchBarAction);

  const editSearchData = useContext(EditSearchBarState);
  const isSearchResultPage =
    history.location.pathname === BOOKING_PATHS.booking;
  const { travelType, travelDetails, startDate } = isSearchResultPage
    ? editSearchData
    : useAppSelector(selectBooking) || {};

  const { concession } = useAppSelector(selectUser) || {};
  const { dutyTravel, leisureTravel } = concession || {};

  const [filterKeyword, setFilterKeyword] = useState("");
  const [selectedValue, setSelectedValue] = useState<
    IButtonValue | undefined
  >();

  const concessionOptions =
    travelType === TRAVEL_PURPOSE.employeeDutyTravel
      ? dutyTravel
      : leisureTravel;

  const selectedConcession = selectedValue && selectedValue.id !== "";

  const filteredConcessionOptions = filterKeyword
    ? concessionOptions?.filter((concessionOption) =>
        concessionOption.name
          .toUpperCase()
          .includes(filterKeyword.toUpperCase())
      )
    : concessionOptions;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        bgcolor: "#FDFDFD",
        borderRadius: "4px",
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          px: 1,
          pt: 2,
          overflow: "hidden",
        }}
      >
        <Header handleOnBack={handleBackClick} />

        <Grid
          item
          component={ScrollableView}
          sx={{
            flex: 1,
          }}
        >
          <Typography
            variant="headline_medium"
            gutterBottom
            component="div"
            sx={{ mt: 2, pl: 1 }}
          >
            {en.booking.travelType.travelConcession}
          </Typography>

          <StyledOutlinedInput
            autoFocus
            notched={false}
            placeholder={en.booking.travelType.searchByConcession}
            value={filterKeyword}
            onChange={(event: any) => {
              setFilterKeyword(event.target.value);
            }}
            sx={{
              borderRadius: "20px",
              height: "40px",
              width: "calc(100% - 16px)",
              pl: 2.5,
              mt: 1,
              mx: 1,
              background: theme.color.secondary.light_slate.option_5,
              "& .MuiInputBase-input": {
                color: theme.color.secondary.grey.option_3,
              },
            }}
            startAdornment={
              <InputAdornment
                position="start"
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <NavBarSearchIcon fill={theme.color.utility.link.option_3} />
              </InputAdornment>
            }
            endAdornment={
              filterKeyword && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setFilterKeyword("")}
                    onMouseDown={(event) => {
                      event.preventDefault();
                    }}
                    // TBC: if set -1, press tab will not focus close Button
                    tabIndex={-1}
                  >
                    <CloseIcon
                      sx={{
                        p: "1px",
                        width: "16px",
                        height: "16px",
                        bgcolor: theme.color.secondary.grey.option_3,
                        borderRadius: "50%",
                        color: "white",
                      }}
                    />
                  </IconButton>
                </InputAdornment>
              )
            }
          />

          <Grid>
            <Grid item>
              {filteredConcessionOptions &&
              filteredConcessionOptions.length > 0 ? (
                <ConcessionListContainer
                  options={getConcessionForRadioButtonGroup(
                    filteredConcessionOptions,
                    startDate
                  )}
                  defaultVal={travelDetails.id}
                  onChange={(value: IButtonValue) => setSelectedValue(value)}
                />
              ) : (
                <Typography
                  variant="body_2_regular"
                  color={theme.color.secondary.dark_grey.option_3}
                  component="div"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mt: 4,
                  }}
                >
                  {en.booking.travelType.noTravelConcession}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {selectedConcession && (
        <Footer
          customStyles={{
            borderTop: `0.5px solid ${theme.color.secondary.slate.option_3}`,
            height: "69px",
            minHeight: "70px",
            paddingTop: "16px",
            zIndex: 1,
            tabIndex: 0,
          }}
          primaryBtn={{
            isFullWidth: false,
            text: en.common.continue,
            customOnClick: async () => {
              if (selectedValue) {
                const empOrNonEmp = await getConcessionEmpNonEmp(
                  selectedValue.id
                );

                if (isSearchResultPage) {
                  setEditTravelDetails({
                    id: selectedValue.id,
                    ...selectedValue.val,
                    subTitle: selectedValue.displaySubtitle,
                  });
                } else {
                  dispatch(
                    setDetail({
                      id: selectedValue.id,
                      ...selectedValue.val,
                      subTitle: selectedValue.displaySubtitle,
                      empOrNonEmpType: empOrNonEmp.type,
                    })
                  );
                }

                handleBackClick && handleBackClick(empOrNonEmp.type);
              }
            },
            customButtonStyles: {
              width: "104px",
              minWidth: "104px",
              height: "38px",
            },
          }}
        />
      )}
    </Box>
  );
};

export default AdminRoleConcession;
