import { FormErrors } from 'redux-form';
import { MANDATORY_BOOKING_FIELDS } from '../../../../../constants/constants';
import { validateRequiredFields } from '../../../../../helpers/validation';
import { IPassportDetails } from '../../../../../interfaces';

export const validate = (values: IPassportDetails | null): FormErrors<IPassportDetails | null> => {
  const errors: FormErrors<IPassportDetails | null> = {};

  if (values) {
    validateRequiredFields(values, MANDATORY_BOOKING_FIELDS.passport, errors);
  }

  return errors;
};
