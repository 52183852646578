import { Box, Typography, useTheme } from '@mui/material';

const ModifiedWarningMessage = ({ title, info, updateTime }: { title: string; info: string; updateTime: string }) => {
  const theme = useTheme();

  return (
    <Box sx={{ ml: 1 }}>
      <Typography variant="body_2_medium" color={theme.color.secondary.dark_grey.option_3}>
        {title}
      </Typography>
      <Typography display="flex" variant="navigation_regular" color={theme.color.secondary.dark_grey.option_3}>
        {info}
        <Typography sx={{ ml: 0.5 }} variant="navigation_regular" color={theme.color.utility.link.option_3}>
          {updateTime}
        </Typography>
      </Typography>
    </Box>
  );
};

export default ModifiedWarningMessage;
