import React from "react";
import { Box, IconButton, SxProps, Theme } from "@mui/material";

import { BackIcon, CloseIcon } from "../../assets/images";

const Header = ({
  leftChild,
  rightChild,
  bottomBorder,
  isHideBackBtn,
  handleOnBack,
  handleOnClose,
  customStyles,
  iconColor = "#116F9A",
}: {
  leftChild?: React.ReactNode;
  rightChild?: React.ReactNode;
  bottomBorder?: boolean;
  isHideBackBtn?: boolean;
  handleOnBack?: () => void;
  handleOnClose?: () => void;
  customStyles?: SxProps<Theme>;
  iconColor?: string;
}) => {
  const defaultHeaderStyle = {
    width: "100%",
    justifyContent: "space-between",
  };

  const bottomBorderStyle = bottomBorder
    ? {
        mt: 0.25,
        borderBottom: 1,
        borderColor: "secondary.main",
        background: "white",
      }
    : {};

  const headerStyle = {
    ...defaultHeaderStyle,
    ...bottomBorderStyle,
    ...customStyles,
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="row"
      sx={headerStyle}
    >
      <Box display="flex" alignItems="center" flexGrow={1}>
        {!isHideBackBtn && (
          <IconButton
            size="medium"
            onClick={() => {
              handleOnBack?.();
            }}
          >
            <BackIcon fill={iconColor} />
          </IconButton>
        )}

        {leftChild}
      </Box>

      {handleOnClose ? (
        <IconButton onClick={handleOnClose}>
          <CloseIcon fill={iconColor} />
        </IconButton>
      ) : (
        <Box>{rightChild}</Box>
      )}
    </Box>
  );
};

Header.defaultProps = {};
export default Header;
