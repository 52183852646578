import React from 'react';
import { Box, Grid, useTheme } from '@mui/material';

import en from '../../../translations/en';
import { LABEL_CATEGORY, LABEL_VALUE_TYPE } from '../../../constants/constants';

import { IDependentForAdmin } from '../../../interfaces/admin';

import { IDisplayDetails, DetailsContainer, LabelValueContainer } from '../PersonalInfo';
import { FieldContainer, GoBackButton } from '../../Common';

interface IMassagedPersonalInfoData {
  profileDetails: IDisplayDetails[];
  passportDetails: IDisplayDetails[];
}

const DependentsDetail = ({
  name,
  age,
  nominationFrom,
  nominationTo,
  nominationStatus,
  profileDetails,
  passportDetails,
  setSelectDependent,
}: IDependentForAdmin & {
  setSelectDependent: (value: IDependentForAdmin | null) => void;
}) => {
  const theme = useTheme();
  const fieldLabel = en.admin.feature.dependent;
  const { profileDetails: profileDetailsLbl, passportDetails: passportDetailsLbl } = fieldLabel;

  const tableData = [
    { label: fieldLabel.name, value: name, length: 2 },
    { label: fieldLabel.relationship, value: profileDetails?.relationship },
    { label: fieldLabel.age, value: age },
    { label: fieldLabel.nominationFrom, value: nominationFrom },
    { label: fieldLabel.nominationTo, value: nominationTo },
    { label: fieldLabel.nominationStatus, value: nominationStatus },
  ];

  const MassagedPersonalInfoData: IMassagedPersonalInfoData = {
    profileDetails: [
      {
        label: profileDetailsLbl.dependentType,
        value: profileDetails.dependentType,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: profileDetailsLbl.title,
        value: profileDetails.title,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: profileDetailsLbl.firstName,
        value: profileDetails.firstName,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: profileDetailsLbl.middleName,
        value: profileDetails.middleName,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: profileDetailsLbl.surname,
        value: profileDetails.surname,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: profileDetailsLbl.relationship,
        value: profileDetails.relationship,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: profileDetailsLbl.gender,
        value: profileDetails.gender,
        type: LABEL_VALUE_TYPE.CODELABELS,
        configurationField: LABEL_CATEGORY.gender,
        confidential: true,
        column: 4,
      },
      {
        label: profileDetailsLbl.dateOfBirth,
        value: profileDetails.dateOfBirth,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: true,
        column: 8,
      },
      {
        label: profileDetailsLbl.validFrom,
        value: profileDetails.validFrom,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: profileDetailsLbl.validTo,
        value: profileDetails.validTo,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: profileDetailsLbl.dependentStatus,
        value: profileDetails.dependentStatus,
        type: LABEL_VALUE_TYPE.BOOLEAN,
        options: en.labels.employmentStatusOptions,
        confidential: false,
        column: 4,
      },
    ],
    passportDetails: [
      {
        label: passportDetailsLbl.passportNumber,
        value: passportDetails.passportNumber,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: passportDetailsLbl.firstOtherNameAsInPassport,
        value: passportDetails.firstOtherNameAsInPassport,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: passportDetailsLbl.surnameAsInPassport,
        value: passportDetails.surnameAsInPassport,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: passportDetailsLbl.passportExpiryDate,
        value: passportDetails.passportExpiryDate,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: passportDetailsLbl.nationalityInPassport,
        value: passportDetails.nationalityInPassport,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
      {
        label: passportDetailsLbl.issuedCountry,
        value: passportDetails.issuedCountry,
        type: LABEL_VALUE_TYPE.STRING,
        confidential: false,
        column: 4,
      },
    ],
  };

  return (
    <>
      <GoBackButton buttonText={en.admin.dependents.backToDependentList} onClick={() => setSelectDependent(null)} />
      <Box
        className="dependent_field"
        sx={{
          height: '77px',
          px: 2,
          display: 'flex',
          margin: '19px auto 0',
          border: `2px solid ${theme.color.secondary.dark_grey.option_7}`,
          borderRadius: 1,
        }}
      >
        {tableData.map((item, index) => {
          return (
            <FieldContainer
              key={index}
              field={item}
              index={index}
              customStyles={{
                flex: index === 0 ? 2 : 1,
              }}
            />
          );
        })}
      </Box>
      <Box
        className="dependent_detail"
        sx={{
          margin: '24px auto 0',
          position: 'relative',
        }}
      >
        {Object.entries(MassagedPersonalInfoData).map(([key, value]: [string, IDisplayDetails[]]) => {
          return (
            <DetailsContainer
              key={key}
              title={
                (
                  fieldLabel[key as keyof typeof fieldLabel] as {
                    heading: string;
                  }
                )?.heading
              }
            >
              <Grid container spacing={2}>
                {value.map((data: IDisplayDetails, index: number) => {
                  return <LabelValueContainer key={index} {...data} />;
                })}
              </Grid>
            </DetailsContainer>
          );
        })}
      </Box>
    </>
  );
};

export default DependentsDetail;
