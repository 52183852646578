import en from '../../translations/en';
import { HTTP_REQUEST_TYPE, DELEGATEE_API } from '../../constants/api';

import { sendHttpRequest } from '../api';

import { IBookFlightParams, IFlightSectors } from '../../interfaces';

const searchFlight = async (
  departureDate: string,
  departurePort: string,
  arrivalDate: string,
  arrivalPort: string,
  concessionId: string,
  userId: string,
) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: DELEGATEE_API.BOOKING_API.searchFlight,
    apiTitle: en.errorAlert.searchFlight,
    payload: {
      departureDate,
      departurePort,
      arrivalDate,
      arrivalPort,
      concessionId,
      userId,
    },
  });
  return resp;
};

const getFare = async (flightSectors: IFlightSectors[], concessionId: string, userId: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: DELEGATEE_API.BOOKING_API.fare,
    apiTitle: en.errorAlert.getFare,
    payload: { flightSectors, concessionId, userId },
  });
  return resp;
};

const bookFlights = async (param: IBookFlightParams) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: DELEGATEE_API.BOOKING_API.book,
    apiTitle: en.errorAlert.bookFlights,
    payload: param,
  });
  return resp;
};

const getBooking = async (userId: string, applicationId: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: DELEGATEE_API.BOOKING_API.pnr,
    apiTitle: en.errorAlert.getBooking,
    payload: { userId, applicationId },
  });
  return resp;
};

const getUpcomingBooking = async (userId: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: DELEGATEE_API.BOOKING_API.upcomingBooking,
    apiTitle: en.errorAlert.getUpcomingBooking,
    payload: { userId },
  });
  return resp;
};

const getMyBookings = async (userId: string, from: number) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: DELEGATEE_API.BOOKING_API.myBooking,
    apiTitle: en.errorAlert.getMyBookings,
    payload: { userId, from },
  });
  return resp;
};

const getDocsDoca = async (flightSectors: IFlightSectors[]) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: DELEGATEE_API.BOOKING_API.docsDoca,
    apiTitle: en.errorAlert.getDocsDoca,
    payload: { flightSectors },
  });
  return resp;
};

const getTravellerName = async (userId: string, flightSectors: IFlightSectors[]) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.post,
    path: DELEGATEE_API.BOOKING_API.travellerName,
    apiTitle: en.errorAlert.getTravellerName,
    payload: { userId, flightSectors },
  });
  return resp;
};

const cancelBooking = async (userId: string, applicationId: string) => {
  const [, resp] = await sendHttpRequest({
    method: HTTP_REQUEST_TYPE.get,
    path: DELEGATEE_API.BOOKING_API.cancel,
    apiTitle: en.errorAlert.cancelBooking,
    payload: { userId, applicationId },
  });
  return resp;
};

export {
  searchFlight,
  getFare,
  bookFlights,
  getBooking,
  getUpcomingBooking,
  getMyBookings,
  getDocsDoca,
  getTravellerName,
  cancelBooking,
};
