const ROOT_PATHS = {
  landing: '/',
};

const LOGIN_PATHS = {
  assoSubsid: '/asso-subsid',
};

const USER_PROFILE_PATHS = {
  root: '/profile',
  menu: '/profile/menu',
  details: '/profile/details',
  basicInformation: '/profile/details/basicInformation',
  contactDetails: '/profile/details/contactDetails',
  passport: '/profile/details/passport',
  delegation: '/profile/details/delegation',
  addDelegatee: '/profile/details/delegation/addDelegatee',
  dependent: '/profile/details/dependent',
  paymentMethod: '/profile/details/paymentMethod',
  paymentHistory: '/profile/details/paymentHistory',
  dependentDetails: '/profile/details/dependentDetails',
  concessionDetails: '/profile/details/concessionDetails',
};

const BOOKING_PATHS = {
  booking: '/booking',
  locationSelection: '/booking/selection/locationSelection',
  dateSelection: '/booking/selection/dateSelection',
  travelTypeSelection: '/booking/selection/travelTypeSelection',
  seatClass: '/booking/selection/seatClass',
  travelDetails: '/booking/travelDetails',
  confirmation: '/booking/confirmation',
  myBookings: '/booking/myBookings',
};

const INFO_PATHS = {
  info: '/info',
};

const ADMIN_PATHS = {
  adminGuide: '/admin/guide',
  adminEmployee: '/admin/employee',
  adminTravelHistory: '/admin/travelHistory',
};

const PAYPAL_PATHS = {
  subscription: '/paypal/subscription',
  subscriptionCancelled: '/paypal/subscriptionCancelled',
};

const PREPAID_PATHS = {
  authTransit: '/cpp/auth-transit',
  authFailureTransit: '/cpp/auth/failure-transit',
};

export {
  ROOT_PATHS,
  LOGIN_PATHS,
  USER_PROFILE_PATHS,
  BOOKING_PATHS,
  INFO_PATHS,
  ADMIN_PATHS,
  PAYPAL_PATHS,
  PREPAID_PATHS,
};
