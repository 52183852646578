import React, { useEffect } from 'react';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { USER_PROFILE_PATHS } from '../../../constants/paths';
import { BackIcon, NominatedIcon } from '../../../assets/images';
import en from '../../../translations/en';

import { IDependent } from '../../../interfaces';

import { selectApp } from '../../../slice/appSlice';
import { selectAuth } from '../../../slice/authSlice';
import { getDependentThunk, selectUser } from '../../../slice/userSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import DependentDetailsCard from './DependentDetailsCard';

const DependentDetailsContainer = () => {
  const { isDesktop } = useAppSelector(selectApp) || {};
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { ern } = useAppSelector(selectAuth) || {};
  const { dependent } = useAppSelector(selectUser) || [];

  useEffect(() => {
    dispatch(getDependentThunk({ userId: ern }));
    return () => {};
  }, [dispatch]);

  const nominatedList = dependent?.filter((item: IDependent) => item?.isNominated);
  const otherDependentsList = dependent?.filter((item: IDependent) => !item?.isNominated);

  const RenderDependentView = ({
    list,
    name,
    isShowIcon,
  }: {
    list: IDependent[];
    name: string;
    isShowIcon: boolean;
  }) => {
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mt: isShowIcon ? 0 : 2,
          }}
        >
          {isShowIcon && <NominatedIcon width={20} height={20} fill={theme.color.secondary.dark_grey.option_3} />}
          <Typography
            variant="body_1_medium"
            sx={{
              color: theme.color.secondary.dark_grey.option_3,
              ml: 0.5,
            }}
          >
            {name}
          </Typography>
        </Box>
        {list?.map((item: IDependent, index: number) => {
          return <DependentDetailsCard key={index} data={item} isShowIcon={isShowIcon} />;
        })}
      </>
    );
  };
  // dependent = [];
  return (
    <Box sx={{ ...(isDesktop && { width: '488px' }) }}>
      <>
        {!isDesktop && (
          <IconButton onClick={() => history.push(USER_PROFILE_PATHS.menu)} sx={{ py: 1.625, pl: 0 }}>
            <BackIcon fill={theme.color.utility.link.option_3} />
          </IconButton>
        )}
        <Box>
          <Typography
            variant="headline_medium"
            sx={{
              color: theme.color.secondary.dark_grey.option_1,
              mb: 3,
              mt: 2,
            }}
          >
            {en.userProfile.dependent.dependentDetails}
          </Typography>
        </Box>
        {dependent?.length === 0 && (
          <Typography
            variant="body_1_medium"
            sx={{
              color: theme.palette.gray.main,
              ml: 0.5,
              mt: 3,
              textAlign: 'center',
            }}
          >
            {en.userProfile.dependent.empty}
          </Typography>
        )}
      </>
      {dependent?.length > 0 && (
        <>
          {nominatedList?.length > 0 && (
            <RenderDependentView
              list={nominatedList || []}
              name={en.userProfile.dependent.nominated}
              isShowIcon={true}
            />
          )}
          {otherDependentsList?.length > 0 && (
            <RenderDependentView
              list={otherDependentsList || []}
              name={en.userProfile.dependent.otherDependents}
              isShowIcon={false}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default DependentDetailsContainer;
